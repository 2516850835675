import qmmsgSelectCompany,
       { qmmsgSelectCompanyActions,
         qmmsgSelectCompanySelectors,  
         getCompaniesAsync                                   } from 'src/store/qmmsg/select-company';

import qmmsgList,
       { qmmsgListActions,
         qmmsgListSelectors,  
         prepareInfoAsync as prepareListInfoAsync, 
         filterClaimsAsync,
         filterClaimsWithResetAsync,
         filterClaimsFavoriteAsync,
         reportClaimsAsync                                   } from 'src/store/qmmsg/list';

import qmmsgDetail,
       { qmmsgDetailActions,
         qmmsgDetailSelectors,
         prepareInfoAsync as prepareDetailInfoAsync, 
         changeFavoriteAsync,
         addQuestionAsync,
         doActionAsync,
         addRatingAsync
                                                             } from 'src/store/qmmsg/detail';

import qmmsgQuestionHistory,
       { qmmsgQuestionHistoryActions,
         qmmsgQuestionHistorySelectors,
         prepareInfoAsync as prepareQuestionHistoryInfoAsync } from 'src/store/qmmsg/question-history';



export const qmmsg = {
  selectCompany: {
    actions: qmmsgSelectCompanyActions,
    selectors: qmmsgSelectCompanySelectors,
    async: {
      getCompaniesAsync,
    }
  },
  list: {
    actions: qmmsgListActions,
    selectors: qmmsgListSelectors,
    async: {
      prepareInfoAsync: prepareListInfoAsync,
      filterClaimsAsync,
      filterClaimsWithResetAsync,
      filterClaimsFavoriteAsync,
      reportClaimsAsync,
    }
  },
  detail: {
    actions: qmmsgDetailActions,
    selectors: qmmsgDetailSelectors,
    async: {
      prepareInfoAsync: prepareDetailInfoAsync,
      changeFavoriteAsync,
      addQuestionAsync,
      doActionAsync,
      addRatingAsync,
    }
  },
  questionHistory: {
    actions: qmmsgQuestionHistoryActions,
    selectors: qmmsgQuestionHistorySelectors,
    async: {
      prepareInfoAsync: prepareQuestionHistoryInfoAsync,
    }
  },
};


export const qmmsgReducers = {
  qmmsgSelectCompany,
  qmmsgList,
  qmmsgDetail,
  qmmsgQuestionHistory,
}