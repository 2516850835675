import styled             from 'styled-components';

import { themeColors,
         Typo,
         themeTypos,
         typoAsCSS      } from 'src/providers/theme/shared';



type BlockCaptionLookAndFeel = {
  paddings: number[];

  typo: Typo;
  color: string;
}

const blockcaption_light0t0b: BlockCaptionLookAndFeel = {
  paddings: [0, 0, 0, 0],

  typo: themeTypos.GRTSKREG_W600_S18_LH24,
  color: themeColors.PrimaryBlack,
}

const blockcaption_light40t12b: BlockCaptionLookAndFeel = {
  ...blockcaption_light0t0b,
  paddings: [40, 0, 12, 0],
}

const blockcaption_light20t20b: BlockCaptionLookAndFeel = {
  ...blockcaption_light0t0b,
  paddings: [20, 0, 20, 0],
}

export type BlockCaptionVariants = 'light0t0b' | 'light40t12b' | 'light20t20b';

export const blockcaption_variants: Map<BlockCaptionVariants, BlockCaptionLookAndFeel> = 
  new Map<BlockCaptionVariants, BlockCaptionLookAndFeel>([
    ['light0t0b', blockcaption_light0t0b],
    ['light40t12b', blockcaption_light40t12b],
    ['light20t20b', blockcaption_light20t20b],
  ]);

export const Container = styled.div<{
  lookAndFeel: BlockCaptionLookAndFeel
}>`
  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)};
  color: ${(args) => args.lookAndFeel.color};
`
