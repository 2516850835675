import React from 'react';



type Props = {
  className?: string;
}
export const BellVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M20 17L4 17L4 14.5L6 13L8.4 3L15.6 3L18 13' stroke = 'currentColor' strokeWidth = '2' strokeMiterlimit = '10'/>
      <path d = 'M10 19V21H14.5V19' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
