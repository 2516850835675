import * as React                  from 'react';
 
import { useAlert                } from 'react-alert';

import { useTheme                } from 'styled-components';
 
import { ArrowBackVector         } from 'src/components/vector/arrowback';
import { LoupeVector             } from 'src/components/vector/loupe';

import { BlockUI                 } from 'src/components/common/block-ui';
import { InputTextbox            } from 'src/components/common/input/input-textbox';
import { EmptyCaseLoupe          } from 'src/components/common/empty-case';
import { EmptyCaseUser           } from 'src/components/common/empty-case';
import { TwoActionDialog         } from 'src/components/common/two-action-dialog';

import { NavBar                  } from 'src/components/features/common/nav-bar';
import { AdminUser               } from 'src/components/features/main/admin/admin-user';

import { AdminLayout,
         Screen,
         ScreenBody,
         UsersContainer,
         layout_light,
         layout_dark             } from 'src/containers/main/admin/layout';

import { Urls                    } from 'src/providers/routing';

import Api                         from 'src/services/api';
import * as types                  from 'src/services/api/types';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                } from 'src/store';
import { GetAdminUserListReject, 
         BlockAdminUserReject    } from 'src/store/main/admin';

import { AsyncOpStatus           } from 'src/common';



type AdminScreenElement = { layout: AdminLayout; dispatch: AppDispatch; }

const Navigation: React.FC<AdminScreenElement> = ({ layout, dispatch }) => {
  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Администрирование'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainProfile.build().navigate()
          }
        ]}
      />
    </React.Fragment>
  )
}

const Search: React.FC<AdminScreenElement> = ({ layout, dispatch }) => {
  const searchString = useAppSelector(storeApi.main.admin.selectors.selectSearchString);

  return (
    <InputTextbox
      variant = { layout.inputTextboxVariant }
      iconPrefix = { <LoupeVector /> }
      value = { searchString }
      onChange = { (value) => dispatch(storeApi.main.admin.actions.searchStringChanged(value)) }
    />
  )
}

const AdminList: React.FC<AdminScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const [blockUser, setBlockUser] = React.useState<types.admin.AdminElement | null>(null);
  const [blockConfirmOpened, setBlockConfirmOpened] = React.useState<boolean>(false);

  const count = useAppSelector(storeApi.main.admin.selectors.selectRawListCount);
  const list = useAppSelector(storeApi.main.admin.selectors.selectFilteredList);
  const searchString = useAppSelector(storeApi.main.admin.selectors.selectSearchString);
  const opStatus = useAppSelector(storeApi.main.admin.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.admin.selectors.selectOpStatusLabel);

  const getListTaskExecuted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (getListTaskExecuted.current)
    {
      return;
    }

    getListTaskExecuted.current = true;
    dispatch(storeApi.main.admin.async.getAdminUserListAsync())
      .unwrap()
      .then((result) => {
        getListTaskExecuted.current = false;
      })
      .catch((rawError) => {
        getListTaskExecuted.current = false;
        const error = rawError as GetAdminUserListReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }, []);

  const onAdminUserBlockHandler = (user: types.admin.AdminElement) => {
    setBlockConfirmOpened(true);
    setBlockUser(user);
  }

  const onBlockCancelHandler = () => {
    setBlockConfirmOpened(false);
    setBlockUser(null);
  }

  const onBlockAcceptHandler = () => {
    if (blockUser !== null)
    {
      dispatch(storeApi.main.admin.async.blockAdminUserAsync(blockUser.id))
        .unwrap()
        .then((result) => {
          alert.success('Пользователь успешно заблокирован');
          setBlockConfirmOpened(false);
          setBlockUser(null);
        })
        .catch((rawError) => {
          const error = rawError as BlockAdminUserReject;
          if (!Api.isCommonAuthError(error.statusCode ?? 0))
          {
            alert.error(error.message);
            setBlockConfirmOpened(false);
            setBlockUser(null);
          }
        });
    }
  }

  return (
    <React.Fragment>
      {count === 0 && (
        <EmptyCaseUser
          variant = { layout.emptyCaseVariant }
          header = 'Пользователи отсутствуют'
        />
      )}
      {count > 0 && list.length === 0 && (
        <EmptyCaseLoupe
          variant = { layout.emptyCaseVariant }
          header = 'Ничего не найдено'
          message = 'Попробуйте изменить поисковый запрос'
        />
      )}
      {list.length > 0 &&
        <UsersContainer layout = { layout }>
          {list.map((item) => (
            <AdminUser
              key = { item.id }
              variant = { layout.adminUserVariant }
              adminUser = { item }
              searchString = { searchString }
              onBlock = { onAdminUserBlockHandler }
            />
          ))}
        </UsersContainer>
      }
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
      <TwoActionDialog 
        variant = { layout.blockConfirmVariant }
        opened = { blockConfirmOpened }
        caption = 'Заблокировать пользователя?'
        message = 'После блокировки, пользователю доступ в программу будет невозможен. При необходимости восстановления доступа обратитесь в техподдержку ukpp@severstal.com'
        isDanger
        cancelLabel = 'Отмена'
        actionLabel = 'Блокировать'
        onCancel = { onBlockCancelHandler }
        onAction = { onBlockAcceptHandler }
      />
    </React.Fragment>
  )
}

export const AdminScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Search layout = { layout } dispatch = { dispatch } />
        <AdminList layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
