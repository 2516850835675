import styled                      from 'styled-components';

import { ButtonVariants          } from 'src/components/common/button';
import { TypographyVariants      } from 'src/components/common/typography';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { DefectItemVariants      } from 'src/components/features/inspection/complaint/defect-item';
import { ComplaintEditVariants   } from 'src/components/features/inspection/complaint/complaint-edit-dialog';

import { cssFlexColumn           } from 'src/providers/theme/shared';



type DefectsListLookAndFeel = {
  gap: number;
  actionsGap: number;
  listGap: number;
  padding: number[];

  headerTypographyVariant: TypographyVariants;
  defectItemVariant: DefectItemVariants;
  completeButtonVariant: ButtonVariants;
  complaintEditVariant: ComplaintEditVariants;
  errorDialog: TwoActionDialogVariants;
}

const defectslist_light: DefectsListLookAndFeel = {
  gap: 20,
  actionsGap: 20,
  listGap: 20,
  padding: [0, 16, 0, 0],
  
  headerTypographyVariant: 'blackw600s18lh24',
  defectItemVariant: 'light',
  completeButtonVariant: 'h48bluefill',
  complaintEditVariant: 'light',
  errorDialog: 'light',
}

export type DefectsListVariants = 'light';

export const defectslist_variants: Map<DefectsListVariants, DefectsListLookAndFeel> = 
  new Map<DefectsListVariants, DefectsListLookAndFeel>([
    ['light', defectslist_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const List = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.listGap}px;
  overflow-y: auto;
  padding-right: ${(args) => args.lookAndFeel.padding[1]}px;
  flex-grow: 2;
`

export const Actions = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
  padding-right: ${(args) => args.lookAndFeel.padding[1]}px;
  padding-top: ${(args) => args.lookAndFeel.padding[0]}px;
`