import * as React                from 'react';
 
import { useSearchParams       } from 'react-router-dom';

import { useAlert              } from 'react-alert';

import { useTheme              } from 'styled-components';
 
import { ArrowBackVector       } from 'src/components/vector/arrowback';
import { FloppyVector          } from 'src/components/vector/floppy';

import { BlockUI               } from 'src/components/common/block-ui';
import { TwoActionDialog       } from 'src/components/common/two-action-dialog';

import { NavBar                } from 'src/components/features/common/nav-bar';

import { DefectsList           } from 'src/components/features/inspection/edit/defects-list';
import { DuplicateDialog       } from 'src/components/features/inspection/edit/duplicate-dialog';
import { EndingDialog          } from 'src/components/features/inspection/edit/ending-dialog';

import { InspectionEditLayout, 
         Screen,
         ScreenBody,
         layout_light,
         layout_dark           } from 'src/containers/inspection/edit/layout';

import { InspectionViewDialog  } from 'src/containers/inspection/view';

import { Urls                  } from 'src/providers/routing';

import { InspectionState       } from 'src/services/api/types/inspection';

import { AppDispatch,
         useAppDispatch,
         useAppSelector,
         storeApi              } from 'src/store';

import { AsyncOpStatus         } from 'src/common';



type InspectionEditScreenElement = { layout: InspectionEditLayout; dispatch: AppDispatch; }

const Navigation: React.FC<InspectionEditScreenElement> = ({ layout, dispatch }) => {
  const navbarText = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralMainNavbarText);
  const isSaveDraftVisible = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsDraftSaveVisible);
  
  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = { navbarText }
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => dispatch(storeApi.inspection.edit.actions.endingOpened())
          }
        ]}
        endButtons={isSaveDraftVisible ? [
          {
            id: 'save',
            vector: <FloppyVector />,
            action: () => dispatch(storeApi.inspection.edit.actions.endingOpened())
          }
        ] : []}
      />
    </React.Fragment>
  )
}

export const InspectionEditScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const alert = useAlert();

  const opStatus = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralOpStatus);
  const opStatusLabel = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralOpStatusLabel);
  const isDefectFilledError = useAppSelector(storeApi.inspection.edit.selectors.selectDefectIsFilledError);

  const bootOpStarted = React.useRef<boolean>(false);

  const [errorOpened, setErrorOpened] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');

  const [searchParams] = useSearchParams();
  const guid = searchParams.get('guid');
  const state = searchParams.get('state') as InspectionState;
  const withDefectRaw = searchParams.get('withDefect');
  const withDefect = withDefectRaw === 'Y';
  const isTransferOnlyRaw = searchParams.get('isTransferOnly');
  const isTransferOnly = isTransferOnlyRaw === 'Y';
  const isChangeToComplaintRaw = searchParams.get('isChangeToComplaint');
  const isChangeToComplaint = isChangeToComplaintRaw === 'Y';
  const isChangeToComplaintFinanceRaw = searchParams.get('isChangeToComplaintFinance');
  const isChangeToComplaintFinance = isChangeToComplaintFinanceRaw === 'Y';
  const isSendRaw = searchParams.get('isSend');
  const isSend = isSendRaw === 'Y';

  React.useEffect(
    () => {
      if (bootOpStarted.current)
      {
        return;
      }

      bootOpStarted.current = true;

      if (isSend)
      {
        if (isDefectFilledError)
        {
          setErrorOpened(true);
          setErrorText('Не заполнены дефекты. Перед отправкой необходимо откорректировать осмотр!');
        }
        else
        {
          dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: true, isTransferOnly: false }))
            .unwrap()
            .then(() => {
              alert.success('Осмотр успешно сохранен');
              Urls.MainInspections.build().navigate();
            })
            .catch((error) => {
              if (error.redirectToList)
              {
                alert.error(error.error);
                Urls.MainInspections.build().navigate();
              }
              else
              {
                setErrorOpened(true);
                setErrorText(error.error);
              }
            });
        }
      }
      else
      {
        if (
          guid === null
          ||
          state === null
          ||
          withDefectRaw === null
          ||
          isTransferOnlyRaw === null
          ||
          isChangeToComplaintRaw === null
          ||
          isChangeToComplaintFinanceRaw === null
        )
        {
          return;
        }

        dispatch(storeApi.inspection.edit.async.buildForEditAsync({
          guid,
          state,
          withDefect,
          isTransferOnly,
          isChangeToComplaint,
          isChangeToComplaintFinance
        }))
          .unwrap()
          .then((result) => {
            switch (result.operationResult)
            {
              case 'get_error':
                alert.error('Не удалось открыть осмотр.');
                return Urls.MainInspections.build().navigate();
              case 'error_fixed':
                alert.info(result.operationResultMessage);
                return Urls.MainInspections.build().navigate();
              case 'data_updated':
                alert.info(result.operationResultMessage);
                setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
                break;
            }

            if (isTransferOnly)
            {
              if (isDefectFilledError)
              {
                setErrorOpened(true);
                setErrorText('Не заполнены дефекты. Перед отправкой необходимо откорректировать осмотр!');
              }
              else
              {
                dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: true, isTransferOnly: true }))
                  .unwrap()
                  .then(() => {
                    alert.success('Осмотр успешно сохранен');
                    Urls.MainInspections.build().navigate();
                  })
                  .catch((error) => {
                    if (error.redirectToList)
                    {
                      alert.error(error.error);
                      Urls.MainInspections.build().navigate();
                    }
                    else
                    {
                      setErrorOpened(true);
                      setErrorText(error.error);
                    }
                  });
              }
            }
          });
      }
    }
  );

  const onSaveModeSelect = (saveMode: 'complete' | 'draft' | 'exit' | 'cancel') => {
    dispatch(storeApi.inspection.edit.actions.endingClosed());

    switch (saveMode)
    {
      case 'complete':
        dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: true }))
          .unwrap()
          .then(() => {
            alert.success('Осмотр успешно сохранен');
            Urls.MainInspections.build().navigate();
          })
          .catch((error) => {
            if (error.redirectToList)
            {
              alert.error(error.error);
              Urls.MainInspections.build().navigate();
            }
            else
            {
              setErrorOpened(true);
              setErrorText(error.error);
            }
          });
        break;
      case 'draft':
        dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: false }))
          .unwrap()
          .then(() => {
            alert.success('Черновик сохранен');
            Urls.MainInspections.build().navigate();
          })
          .catch((error) => {
            if (error.redirectToList)
            {
              alert.error(error.error);
              Urls.MainInspections.build().navigate();
            }
            else
            {
              setErrorOpened(true);
              setErrorText(error.error);
            }
          });
        break;
      case 'exit':
        dispatch(storeApi.inspection.edit.async.deleteImmediatelyAsync());
        Urls.MainInspections.build().navigate();
        break;
      case 'cancel':
        break;
    }
  }

  const onActionErrorHandler = () => {
    setErrorOpened(false);
    setErrorText('');
  }

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <DefectsList variant = { layout.defectsListVariant } />
      </ScreenBody>
      <DuplicateDialog variant = { layout.duplicateDialogVariant } />
      <EndingDialog variant = { layout.endingDialogVariant } onSaveModeSelect = { onSaveModeSelect } />
      <BlockUI variant = { layout.blockUiVariant } isOpened = { opStatus === AsyncOpStatus.BUSY } message = { opStatusLabel }  />
      <InspectionViewDialog variant = { layout.inspectionViewVariant } />
      <TwoActionDialog 
        variant = { layout.errorDialog }
        opened = { errorOpened }
        caption = 'Ошибка'
        message = { errorText }
        isDanger
        withoutCancel
        onAction = { onActionErrorHandler }
      />
    </Screen>
  );
};
