import React from 'react';



type Props = {
  className?: string;
}
export const ProfileVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M21 21H4V17L7 14.5H17L20 17V18' stroke = 'currentColor' strokeWidth = '2'/>
      <circle cx = '12' cy = '7' r = '4' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
