import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';



type HelpDialogLookAndFeel = {
  dialogVariant: DialogVariants;
  oneActionVarint: OneActionDialogVariants;
}

const helpdialog_light: HelpDialogLookAndFeel = {
  dialogVariant: 'light',
  oneActionVarint: 'light',
}

export type HelpDialogVariants = 'light';

export const helpdialog_variants: Map<HelpDialogVariants, HelpDialogLookAndFeel> = 
  new Map<HelpDialogVariants, HelpDialogLookAndFeel>([
    ['light', helpdialog_light],
  ]);
