import { AlertContainer      } from 'react-alert';

import axios,
       { isCancel,
         isAxiosError,
         AxiosError,
         AxiosInstance,
         AxiosRequestConfig,
         AxiosResponse       } from 'axios';

import { Urls                } from 'src/providers/routing'

import Constants               from 'src/services/constants';
import Preferences             from 'src/services/preferences';
import Util                    from 'src/services/util';
import * as types              from 'src/services/api/types';

import { Nullable            } from 'src/common';



export enum HTTPStatusCodes {
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PayloadTooLarge = 413,
  UnsupportedMediaType = 415,
  MisdirectedRequest = 421,
  Locked = 423,
  FailedDependency = 424,
  TooEarly = 425,
  UpgradeRequired = 426,
  RetryWith = 449,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}

export enum AppStatusCodes {
  UnknownError = 900,
  TokenUnauthorized = 901,
  NetworkError = 902,
}

export class ApiError extends Error {
  constructor(
    private readonly _statusCode: HTTPStatusCodes | AppStatusCodes,
    private readonly _message: string,
  )
  {
    super(_message);
    Object.setPrototypeOf(this, new.target.prototype);
  }

  get statusCode()
  {
    return this._statusCode;
  }

  get message()
  {
    return this._message;
  }
}

export type PrepareCall<In, Out> = {
  // Конфигурация запроса
  config: AxiosRequestConfig<In>;
  // Владелец вызова - для отмены старых вызовов
  caller?: string;
  // Значение, которое вернется для отмененного вызова
  abortDefault?: Out;
}

export type PrepareCallHandler<In, Out> = (arg: In) => PrepareCall<In, Out>;

class Api {
  private appPlatform: string = 'PWA';
  private appDevice: string = Preferences.instanceId;
  private appVersion: string = Constants.APP_VERSION;
  private appModel: string = Util.appModel;

  private abortSignals: Map<string, AbortController> = new Map<string, AbortController>();

  private accessJwt: string = '';
  private refreshJwt: string = '';

  private client: AxiosInstance = axios.create({
    baseURL: Constants.API_HOST,
    pwaLocked: 'none',
    pwaRetry: false,
  });

  private alert: Nullable<AlertContainer> = null;

  constructor()
  {
    this.client.interceptors.request.use(
      (config) => {
        config.headers.set('x-app-platform', this.appPlatform);
        config.headers.set('x-app-device', this.appDevice);
        config.headers.set('x-app-version', this.appVersion);
        config.headers.set('x-app-model', this.appModel);

        config.headers.setAccept('application/json');

        if (config.pwaLocked !== 'none')
        {
          config.headers.setAuthorization(`Bearer ${config.pwaLocked === 'access' ? this.accessJwt : this.refreshJwt}`);
        }

        if ((config.method ?? '').toUpperCase() === 'POST' && config.data instanceof FormData)
        {
          config.headers.setContentType('multipart/form-data');
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (rawError) => {
        console.log('intercaptor:error', rawError);
        // Если данный запрос был прерван - нужно просто пробросить ошибку дальше.
        // Она будет обработана в genericCall и вернется результат по умолчанию.
        if (isCancel(rawError))
        {
          console.log('intercaptor:error isCancelError reject');
          return Promise.reject(rawError);
        }

        // Если это не axios-ошибка, значит что-то пошло не так.
        // Мне кажется, что эта ситуация возможно невозможная ), но на всякий случай.
        if (!isAxiosError(rawError))
        {
          console.log('intercaptor:error !isAxiosError reject');
          return Promise.reject(rawError);
        }

        const error = rawError as AxiosError;
        const originalConfig = error.config!;

        // Если это ошибка метода обновления токена, значит ошибку нужно просто
        // спустить дальше, она будет обрабатываться в try/catch вызова refresh
        if (originalConfig.url?.endsWith('auth/refresh'))
        {
          console.log('intercaptor:error isRefresh reject');
          return Promise.reject(rawError);
        }

        // Обрабатываем кейс, когда запрос прошел и получен результат
        if (error.response && error.response.status === 401 && !originalConfig.pwaRetry)
        {
          originalConfig.pwaRetry = true;
          originalConfig.pwaBeforeRetryError = error;

          try
          {
            const rs = await this.getAuthRefresh({ jwtRefresh: this.refreshJwt });
            const { jwtAccess } = rs;
            this.accessJwt = jwtAccess;

            originalConfig.headers.setAuthorization(`Bearer ${this.accessJwt}`, true);

            console.log('intercaptor:error update jwt and repeat request');
            return this.client(originalConfig);
          }
          catch (rawRefreshError)
          {
            console.log('intercaptor:error refresherror', rawRefreshError);
            return Promise.reject(originalConfig.pwaBeforeRetryError);
          }
        }

        // Похоже запрос не прошел, результат не был возвращен, возможно сетевая ошибка какая-то
        console.log('intercaptor:error finishError', rawError);
        return Promise.reject(rawError);
      }
    );
  }

  private async genericCall<In, Out>(prepareCall: PrepareCall<In, Out>): Promise<Out>
  {
    const { config, caller, abortDefault } = prepareCall;

    if (caller !== undefined)
    {
      if (this.abortSignals.has(caller))
      {
        this.abortSignals.get(caller)!.abort();
      }

      const abortController = new AbortController();

      this.abortSignals.set(caller, abortController);
      config.signal = abortController.signal;
    }

    try
    {
      const response = await this.client.request<Out, AxiosResponse<Out>, In>(config);

      return response.data;
    }
    catch(error)
    {
      console.log('genericCall', error);
      // Если запрос был отменен, значит нужно вернуть значение по-умолчанию
      if (isCancel(error))
      {
        return abortDefault!;
      }

      // Если это ошибка refresh, то нужно просунуть ее дальше, она будет обрабатываться в 
      // interceptor.error 
      if (isAxiosError(error) && error.config?.url && error.config.url.endsWith('auth/refresh'))
      {
        return Promise.reject(error);
      }

      // Если это axios-ошибка, то необходимо сначала обработать общие ошибки авторизации,
      // а если это ошибки другого характера, то преобразовать их в ApiError с соответствующими
      // статусами и сообщениями из оригинальной ошибки
      if (isAxiosError(error) && error.response)
      {
        if (error.config?.url && error.config.url.includes('/auth/'))
        {
          if (error.response.data)
          {
            return Promise.reject(new ApiError(
              error.response.data.statusCode,
              error.response.data.message,
            ));
          }
          else
          {
            return Promise.reject(new ApiError(
              error.response.status,
              error.response.statusText,
            ));
          }
        }
        switch (error.response.status)
        {
          // Пользователь не зарегистрирован 
          case HTTPStatusCodes.Unauthorized:
            // Preferences.removeStoredAccount();
            this.alert?.error('Не удалось обновить токен, необходимо пройти повторную авторизацию');
            Urls.Login.build().navigate();
            return Promise.reject(new ApiError(
              HTTPStatusCodes.Unauthorized,
              'Не удалось обновить токен, необходимо пройти повторную авторизацию',
            ));
          default:
            if (error.response.data)
            {
              return Promise.reject(new ApiError(
                error.response.data.statusCode,
                error.response.data.message,
              ));
            }
            else
            {
              return Promise.reject(new ApiError(
                error.response.status,
                error.response.statusText,
              ));
            }
        }
      }
      else
      {
        if (isAxiosError(error) && error.code === 'ERR_NETWORK')
        {
          return Promise.reject(new ApiError(
            AppStatusCodes.NetworkError,
            'Ошибка сети'
          ));
        }

        return Promise.reject(new ApiError(
          AppStatusCodes.UnknownError,
          'Неизвестная ошибка'
        ));
      }
    }
  }

  isCommonAuthError(httpCode: number): boolean {
    return [
      HTTPStatusCodes.Unauthorized,
    ].includes(httpCode);
  }

  setupJwt(refreshJwt: string, accessJwt: string): void {
    this.refreshJwt = refreshJwt;
    this.accessJwt = accessJwt;
  }

  setupAlert(alert: AlertContainer): void {
    this.alert = alert;
  }

  // #region 8d

  /** Повтор проблемы */
  async put8dRepeatById(arg: types.octod.Put8dRepeatByIdIn): Promise<void> {
    return this.genericCall(types.octod.preparePut8dRepeatById(arg));
  }

  /** Добавление оценки */
  async post8dRating(arg: types.octod.Post8dRatingIn): Promise<void> {
    return this.genericCall(types.octod.preparePost8dRating(arg));
  }

  /** Загрузка списка вопросов по проблеме */
  async get8dQuestionById(arg: types.octod.Get8dQuestionByIdIn): Promise<types.octod.Get8dQuestionByIdOut> {
    return this.genericCall(types.octod.prepareGet8dQuestionById(arg));
  }

  /** Создание вопроса по проблеме */
  async post8dQuestion(arg: types.octod.Post8dQuestionIn): Promise<void> {
    return this.genericCall(types.octod.preparePost8dQuestion(arg));
  }

  /** Управление избранными проблемами */
  async put8dFavorite(arg: types.octod.Put8dFavoriteIn): Promise<void> {
    return this.genericCall(types.octod.preparePut8dFavorite(arg));
  }

  /** Управление избранными проблемами */
  async get8dEsk(arg: types.octod.Get8dEskIn): Promise<types.octod.Get8dEskOut> {
    return this.genericCall(types.octod.prepareGet8dEsk(arg));
  }

  /** Загрузка данных 8D */
  async post8dData(arg: types.octod.Post8dDataIn): Promise<types.octod.Problem8dData> {
    return this.genericCall(types.octod.preparePost8dData(arg));
  }

  /** Загрузка данных 8D по избранным проблемам */
  async get8dDataFavorite(arg: types.octod.Get8dDataFavoriteIn): Promise<types.octod.Problem8dData> {
    return this.genericCall(types.octod.prepareGet8dDataFavorite(arg));
  }

  /** Добавление действий пользователя */
  async put8dAction(arg: types.octod.Put8dActionIn): Promise<void> {
    return this.genericCall(types.octod.preparePut8dAction(arg));
  }

  // #endregion


  // #region admin

  /** Получение списка пользователей */
  async getAdminList(arg: types.admin.GetAdminListIn): Promise<types.admin.GetAdminListOut> {
    return this.genericCall(types.admin.prepareGetAdminList(arg));
  }

  /** Блокировка пользователя (не себя самого) администратором */
  async postAdminBlockUserById(arg: types.admin.PostAdminBlockUserByIdIn): Promise<void> {
    return this.genericCall(types.admin.preparePostAdminBlockUserById(arg));
  }

  // #endregion


  // #region adv

  /** Получение hash-суммы по рекламе */
  async getAdvHash(arg: types.adv.GetAdvHashIn): Promise<types.adv.GetAdvHashOut> {
    return this.genericCall(types.adv.prepareGetAdvHash(arg));
  }

  /** Получение рекламы */
  async getAdv(arg: types.adv.GetAdvIn): Promise<types.adv.GetAdvOut> {
    return this.genericCall(types.adv.prepareGetAdv(arg));
  }

  /** Получение файла рекламы */
  async getAdvFileById(arg: types.adv.GetAdvFileByIdIn): Promise<types.adv.GetAdvFileByIdOut> {
    return this.genericCall(types.adv.prepareGetAdvFileById(arg));
  }

  /** Получение статистики */
  async getAdvStatistics(arg: types.adv.GetAdvStatisticsIn): Promise<types.adv.GetAdvStatisticsOut> {
    return this.genericCall(types.adv.prepareGetAdvStatistics(arg));
  }

  // #endregion


  // #region auth

  /** Запрос пригласительного кода */
  async postAuthRequestInvite(arg: types.auth.PostAuthRequestInviteIn): Promise<void> {
    return this.genericCall(types.auth.preparePostAuthRequestInvite(arg));
  }

  /** Авторизация, проверка пригласительного кода */
  async postAuthKeyReg(arg: types.auth.PostAuthKeyRegIn): Promise<types.auth.PostAuthKeyRegOut> {
    return this.genericCall(types.auth.preparePostAuthKeyReg(arg));
  }

  /** Регистрация пользователя */
  async postAuthRegistration(arg: types.auth.PostAuthRegistrationIn): Promise<types.auth.AccountWithTokens> {
    return this.genericCall(types.auth.preparePostAuthRegistration(arg));
  }

  /** Запрос ключа авторизации при авторизации пользователя */
  async postAuth2fa(arg: types.auth.PostAuth2faIn): Promise<void> {
    return this.genericCall(types.auth.preparePostAuth2fa(arg));
  }

  /** Проверка второго фактора */
  async postAuth2faControl(arg: types.auth.PostAuth2faControlIn): Promise<types.auth.PostAuth2faControlOut> {
    return this.genericCall(types.auth.preparePostAuth2faControl(arg));
  }

  /** Запрос ключа авторизации при авторизации пользователя */
  async postAuthKeyLogin(arg: types.auth.PostAuthKeyLoginIn): Promise<types.auth.PostAuthKeyLoginOut> {
    return this.genericCall(types.auth.preparePostAuthKeyLogin(arg));
  }

  /** Авторизация пользователя */
  async postAuthLogin(arg: types.auth.PostAuthLoginIn): Promise<types.auth.AccountWithTokens> {
    return this.genericCall(types.auth.preparePostAuthLogin(arg));
  }

  /** Запрос JWT токена доступа */
  async getAuthRefresh(arg: types.auth.GetAuthRefreshIn): Promise<types.auth.GetAuthRefreshOut> {
    return this.genericCall(types.auth.prepareGetAuthRefresh(arg));
  }

  /** Запрос информации по текущему пользователю, проверка валидности JWT токена */
  async getAuthCheck(arg: types.auth.GetAuthCheckIn): Promise<types.auth.Account> {
    return this.genericCall(types.auth.prepareGetAuthCheck(arg));
  }

  /** Авторизация пользователя */
  async postAuthKeyResetPassword(arg: types.auth.PostAuthKeyResetPasswordIn): Promise<types.auth.PostAuthKeyResetPasswordOut> {
    return this.genericCall(types.auth.preparePostAuthKeyResetPassword(arg));
  }

  /** Изменение пароля */
  async postAuthChangePassword(arg: types.auth.PostAuthChangePasswordIn): Promise<void> {
    return this.genericCall(types.auth.preparePostAuthChangePassword(arg));
  }

  // #endregion


  // #region general

  /** Получение настроек мобильного приложения */
  async getGeneralConfig(arg: types.general.GetGeneralConfigIn): Promise<types.general.GetGeneralConfigOut> {
    return this.genericCall(types.general.prepareGetGeneralConfig(arg));
  }

  // #endregion

  
  // #region info

  /** Получение информационных материалов */
  async getInfo(arg: types.info.GetInfoIn): Promise<types.info.GetInfoOut> {
    return this.genericCall(types.info.prepareGetInfo(arg));
  }

  /** Получение конкретного информационного материала в виде data url */
  async getInfoFileById(arg: types.info.GetInfoFileByIdIn): Promise<types.info.GetInfoFileByIdOut> {
    return this.genericCall(types.info.prepareGetInfoFileById(arg));
  }

  /** Получение конкретного информационного материала в виде сырого blob */
  async getRawInfoFileById(arg: types.info.GetRawInfoFileByIdIn): Promise<types.info.GetRawInfoFileByIdOut> {
    return this.genericCall(types.info.prepareGetRawInfoFileById(arg));
  }

  // #endregion


  //#region message

  /** Получить список сообщений */
  async getMessageList(arg: types.message.GetMessageListIn): Promise<types.message.GetMessageListOut> {
    return this.genericCall(types.message.prepareGetMessageList(arg));
  }

  /** Отправить сообщение */
  async postMessage(arg: types.message.PostMessageIn): Promise<void> {
    return this.genericCall(types.message.preparePostMessage(arg));
  }

  /** Удалить сообщение */
  async deleteMessage(arg: types.message.DeleteMessageIn): Promise<void> {
    return this.genericCall(types.message.prepareDeleteMessage(arg));
  }

  //#endregion


  //#region notification

  /** Получение уведомлений */
  async getNotificationNotifications(arg: types.notification.GetNotificationNotificationsIn): Promise<types.notification.GetNotificationNotificationsOut> {
    return this.genericCall(types.notification.prepareGetNotificationNotifications(arg));
  }

  /** Удаление всех прочитанных уведомлений */
  async postNotificationDelNotifications(arg: types.notification.PostNotificationDelNotificationsIn): Promise<void> {
    return this.genericCall(types.notification.preparePostNotificationDelNotifications(arg));
  }

  /** Чтение всех не прочитанных уведомлений */
  async postNotificationReadNotifications(arg: types.notification.PostNotificationReadNotificationsIn): Promise<void> {
    return this.genericCall(types.notification.preparePostNotificationReadNotifications(arg));
  }

  //#endregion


  //#region inspection

  /** Получение списка осмотров по заданным параметрам */
  async getInspectionList(arg: types.inspection.GetInspectionListIn): Promise<types.inspection.GetInspectionListOut | types.inspection.GetInspectionListAsReportLinkOut> {
    return this.genericCall(types.inspection.prepareGetInspectionList(arg));
  }

  /** Получение осмотра по GUID */
  async getInspectionByGuid(arg: types.inspection.GetInspectionByGuidIn): Promise<types.inspection.Inspection> {
    return this.genericCall(types.inspection.prepareGetInspectionByGuid(arg));
  }

  /** Удаление конкретного черновика осмотра */
  async deleteInspectionByGuid(arg: types.inspection.DeleteInspectionByGuidIn): Promise<void> {
    return this.genericCall(types.inspection.prepareDeleteInspectionByGuid(arg));
  }

  /** Изменение статуса осмотра */
  async postInspectionStatus(arg: types.inspection.PostInspectionStatusIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionStatus(arg));
  }

  /** Подтверждение выполнения обращения */
  async postInspectionComplete(arg: types.inspection.PostInspectionCompleteIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionComplete(arg));
  }

  /** Возврат обращения на доработку */
  async postInspectionReturnForRevision(arg: types.inspection.PostInspectionReturnForRevisionIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionReturnForRevision(arg));
  }

  /** Передача обращения на Северсталь */
  async postInspectionTransferToSeverstal(arg: types.inspection.PostInspectionTransferToSeverstalIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionTransferToSeverstal(arg));
  }

  /** Получение файла по GUID */
  async getInspectionFileByGuid(arg: types.inspection.GetInspectionFileByGuidIn): Promise<types.inspection.GetInspectionFileByGuidOut> {
    return this.genericCall(types.inspection.prepareGetInspectionFileByGuid(arg));
  }

  /** Получение PDF по GUID */
  async getInspectionFileAsPDFByGuid(arg: types.inspection.GetInspectionFileByGuidIn): Promise<types.inspection.GetInspectionFileAsPDFByGuidOut> {
    return this.genericCall(types.inspection.prepareGetInspectionFileAsPDFByGuid(arg));
  }

  /** Предварительное сохранение файла для создания/корректировки осмотра */
  async postInspectionFileByGuid(arg: types.inspection.PostInspectionFileByGuidIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionFileByGuid(arg));
  }

  /** Создание/корректировка осмотра */
  async postInspection(arg: types.inspection.PostInspectionIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspection(arg));
  }

  /** Проверка на дублирование УЕ */
  async postInspectionDuplicateByGuid(arg: types.inspection.PostInspectionDuplicateByGuidIn): Promise<types.inspection.PostInspectionDuplicateByGuidOut> {
    return this.genericCall(types.inspection.preparePostInspectionDuplicateByGuid(arg));
  }

  /** Запрос формирования автоматического отчета */
  async postInspectionReportByGuid(arg: types.inspection.PostInspectionReportByGuidIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionReportByGuid(arg));
  }

  /** Запрос на получение сслыки на отчет по одному осмотру */
  async getInspectionRequestReportAsExcelByGuid(arg: types.inspection.GetInspectionRequestReportAsExcelByGuidIn): Promise<types.inspection.GetInspectionRequestReportAsExcelByGuidOut> {
    return this.genericCall(types.inspection.prepareGetInspectionRequestReportAsExcelByGuid(arg));
  }

  /** Запрос получения отчета по одному осмотру по ссылке */
  async getInspectionReportAsExcelByGuid(arg: types.inspection.GetInspectionReportAsExcelByGuidIn): Promise<void> {
    return this.genericCall(types.inspection.prepareGetInspectionReportAsExcelByGuid(arg));
  }

  /** Запрос получения отчета по списку осмотров по ссылке */
  async getInspectionListAsExcelByGuid(arg: types.inspection.GetInspectionListAsExcelByGuidIn): Promise<void> {
    return this.genericCall(types.inspection.prepareGetInspectionListAsExcelByGuid(arg));
  }

  /** Заказать формирование отчета в формате excel на отдельном сервисе и отправку его на почту */
  async getInspectionHistoryRequestAsExcelToEmail(arg: types.inspection.GetInspectionHistoryRequestAsExcelToEmailIn): Promise<void> {
    return this.genericCall(types.inspection.prepareGetInspectionHistoryRequestAsExcelToEmail(arg));
  }

  /** Заказать формирование отчета в формате excel на отдельном сервисе */
  async getInspectionHistoryRequestAsExcel(arg: types.inspection.GetInspectionHistoryRequestAsExcelIn): Promise<types.inspection.GetInspectionHistoryRequestAsExcelOut> {
    return this.genericCall(types.inspection.prepareGetInspectionHistoryRequestAsExcel(arg));
  }

  /** Запрос получения отчета по истории изменения осмотра по ссылке */
  async getInspectionHistoryReportAsExcel(arg: types.inspection.GetInspectionHistoryReportAsExcelIn): Promise<void> {
    return this.genericCall(types.inspection.prepareGetInspectionHistoryReportAsExcel(arg));
  }

  /** Отзыв осмотра */
  async postInspectionRevocation(arg: types.inspection.PostInspectionRevocationIn): Promise<void> {
    return this.genericCall(types.inspection.preparePostInspectionRevocation(arg));
  }

  //#endregion


  //#region profile

  /** Запрос ключа авторизации при смене номера телефона */
  async postProfileKeyChangePhone(arg: types.profile.PostProfileKeyChangePhoneIn): Promise<types.profile.PostProfileKeyChangePhoneOut> {
    return this.genericCall(types.profile.preparePostProfileKeyChangePhone(arg));
  }

  /** Изменение личных данных пользователя */
  async postProfileChangeProfile(arg: types.profile.PostProfileChangeProfileIn): Promise<void> {
    return this.genericCall(types.profile.preparePostProfileChangeProfile(arg));
  }

  /** Подтверждение email */
  async postProfileConfirmEmail(arg: types.profile.PostProfileConfirmEmailIn): Promise<void> {
    return this.genericCall(types.profile.preparePostProfileConfirmEmail(arg));
  }

  //#endregion


  //#region rating
  
  /** Сохранение оценки приложения пользователем */
  async postRating(arg: types.rating.PostRatingIn): Promise<void> {
    return this.genericCall(types.rating.preparePostRating(arg));
  }

  //#endregion


  //#region refers

  /** Получение hash-суммы по справочникам */
  async getRefersHash(arg: types.refers.GetRefersHashIn): Promise<types.refers.GetRefersHashOut> {
    return this.genericCall(types.refers.prepareGetRefersHash(arg));
  }

  /** Получение справочников */
  async getRefers(arg: types.refers.GetRefersIn): Promise<types.refers.GetRefersOut> {
    return this.genericCall(types.refers.prepareGetRefers(arg));
  }

  /** Изменение признака избранного на дефекте */
  async postRefersDefectFavorite(arg: types.refers.PostRefersDefectFavoriteIn): Promise<void> {
    return this.genericCall(types.refers.preparePostRefersDefectFavorite(arg));
  }

  //#endregion


  //#region token

  /** Получение активных refresh токенов. */
  async getRefreshTokens(arg: types.token.GetRefreshTokensIn): Promise<types.token.GetRefreshTokensOut> {
    return this.genericCall(types.token.prepareGetRefreshTokens(arg));
  }

  /** Выпуск нового токена */
  async postEmmitRefreshToken(arg: types.token.PostEmmitRefreshTokenIn): Promise<types.token.PostEmmitRefreshTokenOut> {
    return this.genericCall(types.token.preparePostEmmitRefreshToken(arg));
  }

  /** Отзыв токена */
  async deleteRefreshToken(arg: types.token.DeleteRefreshTokenIn): Promise<void> {
    return this.genericCall(types.token.prepareDeleteRefreshToken(arg));
  }

  //#endregion


  //#region qc

  /** Постановка в очередь запроса на скачивание сертификата. */
  async postQCCheck(arg: types.qc.PostQCCheckIn): Promise<types.qc.PostQCCheckOut> {
    return this.genericCall(types.qc.preparePostQCCheck(arg));
  }

  /** Получение конкретного файла по сертификату качества. */
  async getQCFile(arg: types.qc.GetQCFileIn): Promise<types.qc.GetQCFileOut> {
    return this.genericCall(types.qc.prepareGetQCFile(arg));
  }

  /** Получение конкретного файла по сертификату качества. */
  async getRawQCFile(arg: types.qc.GetRawQCFileIn): Promise<types.qc.GetRawQCFileOut> {
    return this.genericCall(types.qc.prepareGetRawQCFile(arg));
  }

  //#endregion


  //#region qmmsg

  /** Получение списка кодов ЕСК для сотрудников СТПК */
  async getQMMsgEsk(arg: types.qmmsg.GetQMMsgEskIn): Promise<types.qmmsg.GetQMMsgEskOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgEsk(arg));
  }

  /** Получение списка претензий */
  async getQMMsgClaims(arg: types.qmmsg.GetQMMsgClaimsIn): Promise<types.qmmsg.GetQMMsgClaimsOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgClaims(arg));
  }

  /** Отчет по претензиям/уведомлениям(постановка в очередь) */
  async getQMMsgClaimsReport(arg: types.qmmsg.GetQMMsgClaimsReportIn): Promise<types.qmmsg.GetQMMsgClaimsReportOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgClaimsReport(arg));
  }

  /** Получение списка претензий (фавориты). */
  async getQMMsgClaimsFavorite(arg: types.qmmsg.GetQMMsgClaimsFavoriteIn): Promise<types.qmmsg.GetQMMsgClaimsFavoriteOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgClaimsFavorite(arg));
  }

  /** Получение конкретной претензии */
  async getQMMsgClaimById(arg: types.qmmsg.GetQMMsgClaimByIdIn): Promise<types.qmmsg.GetQMMsgClaimByIdOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgClaimById(arg));
  }

  /** Получение списка вопросов по претензии */
  async getQMMsgClaimQuestionsById(arg: types.qmmsg.GetQMMsgClaimQuestionsByIdIn): Promise<types.qmmsg.GetQMMsgClaimQuestionsByIdOut> {
    return this.genericCall(types.qmmsg.prepareGetQMMsgClaimQuestionsById(arg));
  }

  /** Отправка действия пользователя по претензии */
  async postQMMsgAction(arg: types.qmmsg.PostQMMsgActionIn): Promise<types.qmmsg.PostQMMsgActionOut> {
    return this.genericCall(types.qmmsg.preparePostQMMsgAction(arg));
  }

  /** Управление избранным по претензии */
  async putQMMsgFavorite(arg: types.qmmsg.PutQMMsgFavoriteIn): Promise<types.qmmsg.PutQMMsgFavoriteOut> {
    return this.genericCall(types.qmmsg.preparePutQMMsgFavorite(arg));
  }

  /** Отправка вопроса по претензии */
  async postQMMsgQuestion(arg: types.qmmsg.PostQMMsgQuestionIn): Promise<types.qmmsg.PostQMMsgQuestionOut> {
    return this.genericCall(types.qmmsg.preparePostQMMsgQuestion(arg));
  }

  /** Отправка оценки по претензии */
  async postQMMsgRating(arg: types.qmmsg.PostQMMsgRatingIn): Promise<types.qmmsg.PostQMMsgRatingOut> {
    return this.genericCall(types.qmmsg.preparePostQMMsgRating(arg));
  }

  //#endregion


  //#region service

  /** Подбор рулонов по оттенку. */
  async postShadeSelections(arg: types.service.PostShadeSelectionsIn): Promise<types.service.PostShadeSelectionsOut> {
    return this.genericCall(types.service.preparePostShadeSelections(arg));
  }

  //#endregion

}

export default new Api();