import * as React                   from 'react';

import { EmailStatusDangerVector  } from 'src/components/vector/email-status/danger';
import { EmailStatusSuccessVector } from 'src/components/vector/email-status/success';

import { Container,
         EmailStatusVariants,
         emailstatus_variants     } from 'src/components/features/main/admin/email-status/ui';



type Props = {
  variant: EmailStatusVariants;
  label: string;
  isSuccess?: boolean;
}
export const EmailStatus: React.FC<Props> = ({
  variant,
  label,
  isSuccess = false,
}) => {
  const lookAndFeel = emailstatus_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { isSuccess ? <EmailStatusSuccessVector /> : <EmailStatusDangerVector /> }
      { label.toUpperCase() }
    </Container>
  );
};
