import * as React             from 'react';

import { Container,
         InputWrapper,
         InputContent,
         Hint,
         InputBaseVariants,
         inputbase_variants } from 'src/components/common/input/input-base/ui';



type Props = {
  variant: InputBaseVariants;
  isError?: boolean;
  isDisabled?: boolean;
  hint?: string;
  children: React.ReactNode;
}
export const InputBase: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  hint,
  children,
}) => {
  const lookAndFeel = inputbase_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <InputWrapper lookAndFeel = { lookAndFeel } isError = { isError } isDisabled = { isDisabled }>
          <InputContent lookAndFeel = { lookAndFeel }>{ children }</InputContent>
      </InputWrapper>
      { hint !== undefined && hint.length > 0 && (
        <Hint lookAndFeel = { lookAndFeel } isError = { isError }>
          { hint }
        </Hint>
      )}
    </Container>
  );
};
