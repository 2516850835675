import * as React          from 'react';

import cc                  from 'classcat';

import { Root,
         Container,
         DialogVariants,
         dialog_variants } from 'src/components/common/dialog/ui';



type Props = {
  variant: DialogVariants;
  isOpened: boolean;
  children: React.ReactNode;
}
export const Dialog: React.FC<Props> = ({
  variant,
  isOpened,
  children
}) => {
  const lookAndFeel = dialog_variants.get(variant)!;

  if (!isOpened)
  {
    return null;
  }

  return (
    <Root lookAndFeel = { lookAndFeel } className = { cc({ opened: isOpened }) }>
      <Container lookAndFeel = { lookAndFeel }>
        { children }
      </Container>
    </Root>
  );
};
