import styled             from 'styled-components';

import { ButtonVariants } from 'src/components/common/button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors    } from 'src/providers/theme/shared';



type OneActionDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  captionMessageGap: number;

  messageTypo: Typo;
  messageColor: string;

  messageActionGap: number;

  actionButtonVariant: ButtonVariants;
}

const oneactiondialog_light: OneActionDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  captionMessageGap: 20,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  messageActionGap: 24,

  actionButtonVariant: 'h48bluefill'
}

export type OneActionDialogVariants = 'light';

export const oneactiondialog_variants: Map<OneActionDialogVariants, OneActionDialogLookAndFeel> = 
  new Map<OneActionDialogVariants, OneActionDialogLookAndFeel>([
    ['light', oneactiondialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: OneActionDialogLookAndFeel
}>`
  ${cssFlexColumn};
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px
`

export const Caption = styled.div<{
  lookAndFeel: OneActionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: OneActionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`