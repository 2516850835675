import * as React          from 'react';

import { Ripple          } from 'src/components/common/ripple';
import { Container,
         Label,
         ButtonVariants,
         button_variants } from 'src/components/common/button/ui';



type Props = {
  variant: ButtonVariants;
  label?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  onTap: () => void;
}
export const Button: React.FC<Props> = ({
  variant,
  label,
  icon,
  isDisabled = false,
  onTap
}) => {
  const lookAndFeel = button_variants.get(variant)!;

  return (
    <Ripple isTapAllowed = { !isDisabled } onTap = { onTap }>
      <Container lookAndFeel = { lookAndFeel } disabled = { isDisabled } isIcon = { icon !== undefined }>
        {label !== undefined && 
          <Label lookAndFeel = { lookAndFeel }>{ label }</Label>
        }
        {icon !== undefined && 
          icon
        }
      </Container>
    </Ripple>
  );
};
