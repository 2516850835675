import * as React                 from 'react';

import Drawer                     from '@mui/material/Drawer';

import { isDesktop              } from 'react-device-detect';

import { ArrowDownVector        } from 'src/components/vector/arrow-down';

import { IconButton             } from 'src/components/common/icon-button';
import { Typography             } from 'src/components/common/typography';
import { Switch                 } from 'src/components/common/switch';
import { RadioGroup,
         RadioGroupItem         } from 'src/components/common/radio-group';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { Button                 } from 'src/components/common/button';
import { InputCalendar          } from 'src/components/common/input/input-calendar';
import { TagItem, Tags          } from 'src/components/common/tags';
import { LabeledContent         } from 'src/components/common/labeled-content';

import { ReferSelector,
         ReferSelectorItem      } from 'src/components/features/common/refer-selector';

import { FilterLookAndFeel,
         Container,
         HeaderContainer,
         HeaderLabel,
         FormContainer,
         ActionsContainer,
         SwitchContainer,
         DateContainer,
         CloseOutlineVectorBig,
         FilterVariants,
         filter_variants        } from 'src/components/features/main/inspection-list/filter/ui';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';

import { Nullable               } from 'src/common';



type FilterElement = { lookAndFeel: FilterLookAndFeel; dispatch: AppDispatch; }

const FilterIsSendEmail: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const isSendEmail = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterIsSendEmail);

  return (
    <SwitchContainer  lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.switchTypographyVariant }>
        Отправить на e-mail
      </Typography>
      <Switch
        variant = { lookAndFeel.switchVariant }
        value = { isSendEmail }
        onChange = { (checked) => dispatch(storeApi.main.inspectionList.actions.wcFilterIsSendEmailChanged(checked)) }
      />
    </SwitchContainer>
  )
}

const FilterPeriod: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const dateBeg = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterDateBeg);
  const dateEnd = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterDateEnd);

  return (
    <DateContainer  lookAndFeel = { lookAndFeel }>
      <InputCalendar 
        variant = { lookAndFeel.dateInputVariant }
        value = { dateBeg }
        placeholder = 'с'
        onChange = { (date: Nullable<Date>) => dispatch(storeApi.main.inspectionList.actions.wcFilterDateBegChanged(date)) } 
      />
      <InputCalendar
        variant = { lookAndFeel.dateInputVariant }
        value = { dateEnd }
        placeholder = 'по'
        onChange = { (date: Nullable<Date>) => dispatch(storeApi.main.inspectionList.actions.wcFilterDateEndChanged(date)) }
      />
    </DateContainer>
  )
}

const FilterStatuses: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const statuses = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterStatuses);

  return (
    <Tags
      variant = { lookAndFeel.tagsVariant }
      value = { statuses }
      onChange = { (tags: TagItem[]) => dispatch(storeApi.main.inspectionList.actions.wcFilterStatusesChanged(tags)) }
      allId = '-2'
      items = { [
        { id: '-2', label: 'Все' },
        { id: '-1', label: 'Не отправлено' },
        { id: '0', label: 'Черновик' },
        { id: '5', label: 'Ожидает подтверждения' },
        { id: '1', label: 'Ожидает проверки' },
        { id: '3', label: 'Ожидает рассмотрения' },
        { id: '2', label: 'Внутренняя проработка' },
        { id: '6', label: 'Обработка завершена' },
        { id: '4', label: 'Взят в работу' },
        { id: '8', label: 'Ошибка' },
        { id: '9', label: 'Отозван' },
      ] }
    />
  )
}

const FilterEntryAccounting: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const entryAccounting = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount);

  const items: RadioGroupItem[] = account!.role === 'contractor'
    ?
    [
      { id: '1', label: 'Без дефекта' },
      { id: '2', label: 'С дефектом' },
      { id: '0', label: 'Все' },
    ]
    :
    [
      { id: '1', label: 'Входной учет' },
      { id: '2', label: 'Без входного учета' },
      { id: '0', label: 'Все' },
    ];
  
  const selectedItem = items.find((item) => item.id === `${entryAccounting}`)!;

  return (
    <RadioGroup
      variant = { lookAndFeel.radioVariant }
      items = { items }
      value = { selectedItem }
      onChange = { (value) => dispatch(storeApi.main.inspectionList.actions.wcFilterEntryAccountingChanged(parseInt(value.id, 10))) }
    />
  )
}

const FilterUserFio: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const userFio = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterUserFio);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'ФИО инициатора'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { userFio }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterUserFioChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterInspectionNum: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const inspectionNum = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterInspectionNum);

  const valueTransformer = (value: string): string => {
    if (value.length === 1)
    {
      if (value.toLowerCase() !== 'г' && !'0123456789'.includes(value))
      {
        return '';
      }

      return value.toUpperCase();
    }

    if (!'0123456789'.includes(value.charAt(value.length - 1)))
    {
      return value.substring(0, value.length - 1);
    }

    return value;
  }

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Номер осмотра/обращения'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { inspectionNum }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterInspectionNumChanged(value)) }
        placeholder = 'Введите значение'
        valueTransformer = { valueTransformer }
      />
    </LabeledContent>
  )
}

const FilterDefectName: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const defectName = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterDefectName);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Вид дефекта'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { defectName }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterDefectNameChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterQcNum: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const qcNum = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterQcNum);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Номер сертификата качества'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { qcNum }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterQcNumChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterDecision: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const decision = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterDecision);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Номер решения'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { decision }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterDecisionChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterPiece: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterPiece);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Партия'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { piece }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterPieceChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterHeat: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const heat = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterHeat);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Плавка'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { heat }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterHeatChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterProduct: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const product = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterProduct);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Название изделия'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { product }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterProductChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterPlaceOfFixationId: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const placeId = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterPlaceOfFixationId);
  const placeAll = useAppSelector(storeApi.main.inspectionList.selectors.selectPlaceOfFixations);
  const placeStr = placeAll.find((item) => item.id === placeId)?.title ?? '';

  const items = placeAll.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
     dispatch(storeApi.main.inspectionList.actions.wcFilterPlaceOfFixationIdChanged(parseInt(item.id, 10))); 
  }

  return (
    <React.Fragment>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Место фиксации'>
        <InputTextbox
          variant = { lookAndFeel.inputVariant }
          value = { placeStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = 'Введите значение'
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </LabeledContent>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Место фиксации'
        selectedId = { placeId > -1 ? `${placeId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const FilterDynamic: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const dynamic = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterDynamic);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount);

  const filters = account!.filters.filter((filter) => filter.fieldCode !== '');

  return (
    <React.Fragment>
      {filters.map((filter) => (
        <LabeledContent key = { filter.code } variant = { lookAndFeel.labeledVariant } label = { filter.title }>
          <InputTextbox
            variant = { lookAndFeel.inputVariant }
            value = { dynamic[filter.code] ?? '' }
            onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterDynamicChanged({ ...dynamic, [filter.code]: value })) }
            placeholder = 'Введите значение'
          />
        </LabeledContent>
      ))}
    </React.Fragment>
  )
}

const FilterOrder: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const order = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterOrder);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Номер заказа'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { order }
        onChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.wcFilterOrderChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const FilterFilial: React.FC<FilterElement> = ({ lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const filialId = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterFilialId);
  const filialsAll = useAppSelector(storeApi.main.inspectionList.selectors.selectFilials);
  const filialStr = filialsAll.find((item) => item.id === filialId)?.title ?? '';

  const items = filialsAll.map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.main.inspectionList.actions.wcFilterFilialIdChanged(parseInt(item.id, 10))); 
  }

  if (!isDesktop || !account.configs.find((item) => item.code === 'HAS_FILIALS'))
  {
    return null;
  }

  return (
    <React.Fragment>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Филиал'>
        <InputTextbox
          variant = { lookAndFeel.inputVariant }
          value = { filialStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = 'Введите значение'
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </LabeledContent>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Филиал'
        selectedId = { filialId ? `${filialId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}



type Props = {
  variant: FilterVariants;
  isOpened: boolean;
  isReport: boolean;
  onClose: () => void;
  onAccept: () => void;
}
export const Filter: React.FC<Props> = ({
  variant,
  isOpened,
  isReport,
  onClose,
  onAccept,
}) => {
  const lookAndFeel = filter_variants.get(variant)!;
  const dispatch = useAppDispatch();

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { () => onClose() }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          <HeaderLabel lookAndFeel = { lookAndFeel }>
            { isReport ? 'Отчет' : 'Фильтр' }
          </HeaderLabel>
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => onClose() }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        <FormContainer lookAndFeel = { lookAndFeel }>
          {isReport && (
            <FilterIsSendEmail lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          )}
          <FilterPeriod lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterStatuses lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterEntryAccounting lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterUserFio lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterInspectionNum lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterDynamic lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterDefectName lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterQcNum lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterDecision lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterOrder lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterPiece lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterHeat lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterProduct lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterPlaceOfFixationId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <FilterFilial lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        </FormContainer>
        <ActionsContainer lookAndFeel = { lookAndFeel }>
          <Button
            variant = { lookAndFeel.actionCancelVariant }
            label = 'Сбросить'
            onTap = { () => dispatch(storeApi.main.inspectionList.actions.filterReseted())  }
          />
          <Button
            variant = { lookAndFeel.actionAcceptVariant }
            label = 'Применить'
            onTap = { onAccept }
          />
        </ActionsContainer>
      </Container>
    </Drawer>
  );
};
