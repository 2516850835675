import * as React                from 'react';

import cc                        from 'classcat';

import { RequirementVector     } from 'src/components/vector/requirement';

import { Container,
         RequirementElement,
         Control,
         Label,
         RequirementsVariants,
         requirements_variants } from 'src/components/common/requirements/ui';



export type RequirementItem = {
  completed: boolean;
  label: string;
}

type Props = {
  variant: RequirementsVariants;
  items: RequirementItem[];
}
export const Requirements: React.FC<Props> = ({
  variant,
  items,
}) => {
  const lookAndFeel = requirements_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { items.map((item) => (
        <RequirementElement
          key = { item.label }
          lookAndFeel = { lookAndFeel }
        >
          <Control
            lookAndFeel = { lookAndFeel }
            className = { cc({ completed: item.completed }) }
          >
            <RequirementVector />
          </Control>
          <Label lookAndFeel = { lookAndFeel }>{ item.label }</Label>
        </RequirementElement>
      ))}
    </Container>
  );
};
