import React from 'react';



type Props = {
  className?: string;
}
export const RefreshVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M23 3.9979V8.99921L22.5 9.5L22 9.99947C20.2358 9.99947 18.9886 9.99947 17 9.99947M1 20.0021C1 18.0127 1 16.7651 1 15L1.5 14.5005L2 14L7 14.0005M3.51 8.99921C4.01717 7.56562 4.87913 6.2839 6.01547 5.27365C7.1518 4.2634 8.52546 3.55755 10.0083 3.22195C11.4911 2.88636 13.0348 2.93196 14.4952 3.3545C15.9556 3.77704 17.2853 4.56276 18.36 5.63833L19.395 6.60375L19.9125 7.08645M20.49 15.0008C19.9828 16.4344 19.1209 17.7161 17.9845 18.7263C16.8482 19.7366 15.4745 20.4425 13.9917 20.778C12.5089 21.1136 10.9652 21.068 9.50481 20.6455C8.04437 20.223 6.71475 19.4372 5.64 18.3617L4.605 17.3964L4.0875 16.9137' />
    </svg>
  );
};
