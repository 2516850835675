import * as React                     from 'react';

import { Button                     } from 'src/components/common/button';

import { Container, 
         Caption,
         Warning,
         Actions,
         RevokeTokenDialogVariants,
         revoketokendialog_variants } from 'src/components/features/main/token/revoke-token-dialog/ui';



type Props = {
  variant: RevokeTokenDialogVariants;
  onAccept: () => void;
  onCancel: () => void;
}
export const RevokeTokenDialog: React.FC<Props> = ({
  variant,
  onAccept,
  onCancel,
}) => {
  const lookAndFeel = revoketokendialog_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Отозвать токен?
      </Caption>
      <Warning lookAndFeel = { lookAndFeel }>
        Приложение, использующее данный токен, перестанет работать
      </Warning>
      <Actions lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.cancelButtonVariant }
          label = 'Отмена'
          onTap = { onCancel }
        />
        <Button
          variant = { lookAndFeel.acceptButtonVariant }
          label = 'Отозвать'
          onTap = { onAccept }
        />
      </Actions>
    </Container>
  );
};
