import * as React              from 'react';

import { CloseVector         } from 'src/components/vector/close';

import { Container,
         Tag,
         Icon,
         FilterTagsVariants,
         filtertags_variants } from 'src/components/features/qmmsg/list/filter-tags/ui';



export type FilterTagItem = {
  id: string;
  label: string;
}

type Props = {
  variant: FilterTagsVariants;
  items: FilterTagItem[];
  onClose: (tag: FilterTagItem) => void;
}
export const FilterTags: React.FC<Props> = ({
  variant,
  items,
  onClose,
}) => {
  const lookAndFeel = filtertags_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { items.map((item) => (
        <Tag
          key = { item.id }
          lookAndFeel = { lookAndFeel }
          onClick = { onClose.bind(null, item) }
        >
          { item.label }
          <Icon lookAndFeel = { lookAndFeel }>
            <CloseVector />
          </Icon>
        </Tag>
      ))}
    </Container>
  );
};
