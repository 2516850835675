import * as React                      from 'react';

import { ArrowUpVector               } from 'src/components/vector/arrow-up';
import { ArrowDownVector             } from 'src/components/vector/arrow-down';
import { MessageActionApprovedVector } from 'src/components/vector/message-action/message-action-approved';
import { MessageActionComebackVector } from 'src/components/vector/message-action/message-action-comeback';

import { IconButton                  } from 'src/components/common/icon-button';

import { Status,
         StatusVariants              } from 'src/components/features/main/inspection-list/status';

import { Container,
         List,
         Item,
         ItemNumber,
         ItemNumberLabel,
         ItemNumberValue,
         Expand,
         QMMsgListVariants,
         qmmsglist_variants          } from 'src/components/features/main/inspection-list/qmmsg-list/ui';

import * as types                      from 'src/services/api/types';
import { QMMsgAction                 } from 'src/services/api/types/inspection';



type Props = {
  variant: QMMsgListVariants;
  list: types.inspection.QMMsgItem[];
  onJump: (item: types.inspection.QMMsgItem) => void;
}
export const QMMsgList: React.FC<Props> = ({
  variant,
  list,
  onJump,
}) => {
  const lookAndFeel = qmmsglist_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const workList = expanded ? list : [list[0]];

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <List lookAndFeel = { lookAndFeel }>
        {workList.map((item) => (
          <Item lookAndFeel = { lookAndFeel } key = { item.id }>
            <ItemNumber lookAndFeel = { lookAndFeel }>
              <ItemNumberLabel lookAndFeel = { lookAndFeel }>
                { item.type === 'claim' ? '№ претензии' : '№ уведомления' }
              </ItemNumberLabel>
              <ItemNumberValue lookAndFeel = { lookAndFeel }>
                <span>{ item.num }</span>
                { list.length > 1 && item.action === QMMsgAction.APPROVED && (
                  <MessageActionApprovedVector />
                )}
                { list.length > 1 && item.action === QMMsgAction.COMEBACK && (
                  <MessageActionComebackVector />
                )}
              </ItemNumberValue>
            </ItemNumber>
            <Status
              variant = { getQMMsgStatusVariant(item.code) }
              label = { `${item.title} →` }
              onTap = { () => onJump(item) }
            />
          </Item>
        ))}
      </List>
      <Expand lookAndFeel = { lookAndFeel }>
        {list.length > 1 &&
          <IconButton variant = { lookAndFeel.expandButtonVariant } isTapAllowed onTap = { () => { setExpanded(!expanded) } }>
            { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
          </IconButton>
        }
      </Expand>
    </Container>
  );
};

const getQMMsgStatusVariant = (status: types.inspection.QMMsgStatusCode): StatusVariants => {
  switch (status)
  {
    case types.inspection.QMMsgStatusCode.ONE:
    case types.inspection.QMMsgStatusCode.FIVE:
    case types.inspection.QMMsgStatusCode.ACCEPTED:
    case types.inspection.QMMsgStatusCode.CONSIDERATION:
      return 'steelfill';
    case types.inspection.QMMsgStatusCode.ZERO:
    case types.inspection.QMMsgStatusCode.TWO:
    case types.inspection.QMMsgStatusCode.NONE:
      return 'redoutline';
  }
  
  return 'blueoutline';
}