import styled           from 'styled-components';

import { cssFlexRow,
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type AdsLookAndFeel = {
  cardWidth: number;
  cardHeight: number;

  labelTypo: Typo;
  labelColor: string;
  labelPaddings: number[];

  gap: number;
}

const ads_w124h90_light: AdsLookAndFeel = {
  cardWidth: 124,
  cardHeight: 90,

  labelTypo: themeTypos.GRTSKREG_W400_S11_LH14,
  labelColor: themeColors.PrimaryWhite,
  labelPaddings: [0, 12, 10, 12],

  gap: 10,
}

export type AdsVariants = 'w124h90_light';

export const ads_variants: Map<AdsVariants, AdsLookAndFeel> = 
  new Map<AdsVariants, AdsLookAndFeel>([
    ['w124h90_light', ads_w124h90_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AdsLookAndFeel;
}>`
  ${cssFlexRow};

  gap: ${(args) => args.lookAndFeel.gap}px;

  width: 100%;
  flex-shrink: 0;
  overflow-x: auto;
  font-decoration: none;
`

export const Ad = styled.div<{
  lookAndFeel: AdsLookAndFeel;
}>`
  ${cssFlexRow};
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
  align-items: flex-end;
  width: ${(args) => args.lookAndFeel.cardWidth}px;
  height: ${(args) => args.lookAndFeel.cardHeight}px;

  padding-right: ${(args) => args.lookAndFeel.labelPaddings[1]}px;
  padding-left: ${(args) => args.lookAndFeel.labelPaddings[3]}px;
  padding-bottom: ${(args) => args.lookAndFeel.labelPaddings[2]}px;
`;

export const AdLabel = styled.span<{
  lookAndFeel: AdsLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor };
`;

export const Padding = styled.div<{
  lookAndFeel: AdsLookAndFeel;
  size: number;
}>`
  width: ${(args) => args.size - args.lookAndFeel.gap}px;
  flex-shrink: 0;
`

export const Full = styled.div<{
  lookAndFeel: AdsLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  height: 100dvh;

  background-size: cover;
  background-position: center center;
  position: relative;
`;
