import styled             from 'styled-components';

import { cssFlexColumn, 
         cssFlexRow, 
         themeColors,
         Typo,
         typoAsCSS,
         themeTypos     } from 'src/providers/theme/shared';



type LabeledContentLookAndFeel = {
  labelTypo: Typo
  labelColor: string;

  gap: number;
  labelGap: number;
}

const labeledcontent_gray5w400s16lh22gap4: LabeledContentLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,
  labelColor: themeColors.SecondaryGray5,

  gap: 4,
  labelGap: 16,
}

const labeledcontent_blue1w400s14lh17gap4: LabeledContentLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  labelColor: themeColors.AccentBlue1,

  gap: 4,
  labelGap: 16,
}

const labeledcontent_blue100w400s13lh16gap4: LabeledContentLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S13_LH16,
  labelColor: themeColors.PrimaryBlue100,

  gap: 4,
  labelGap: 16,
}

const labeledcontent_gray5w400s12lh14gap4: LabeledContentLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S12_LH14,
  labelColor: themeColors.SecondaryGray5,

  gap: 4,
  labelGap: 16,
}

export type LabeledContentVariants = 'gray5w400s16lh22gap4' | 
                                     'blue1w400s14lh17gap4' | 
                                     'gray5w400s12lh14gap4' | 
                                     'blue100w400s13lh16gap4';

export const labeledcontent_variants: Map<LabeledContentVariants, LabeledContentLookAndFeel> = 
  new Map<LabeledContentVariants, LabeledContentLookAndFeel>([
    ['gray5w400s16lh22gap4', labeledcontent_gray5w400s16lh22gap4],
    ['blue1w400s14lh17gap4', labeledcontent_blue1w400s14lh17gap4],
    ['gray5w400s12lh14gap4', labeledcontent_gray5w400s12lh14gap4],
    ['blue100w400s13lh16gap4', labeledcontent_blue100w400s13lh16gap4],
  ]);

export const Container = styled.div<{
  lookAndFeel: LabeledContentLookAndFeel
}>`
  ${cssFlexColumn};

  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const Label = styled.span<{
  lookAndFeel: LabeledContentLookAndFeel,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.labelGap}px;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
`

export const Content = styled.div<{
  lookAndFeel: LabeledContentLookAndFeel,
}>`
  width: 100%;
`
