
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceRestAPIVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M15 3C18.3137 3 21 5.68629 21 9C21 12.3137 18.3137 15 15 15C14.3938 15 13.8086 14.9101 13.2571 14.7429L11 17H9V19H7V21H3V17L9.25707 10.7429C9.08989 10.1914 9 9.60617 9 9C9 6.77915 10.2066 4.84012 12 3.80269' />
    </svg>
  );
};
