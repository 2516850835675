import styled            from 'styled-components';

import { LinkVariants  } from 'src/components/common/link';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,    
         cssFlexColumn } from 'src/providers/theme/shared';



type ContentLookAndFeel = {
  gap: number;
  paddings: number[];

  linkVariant: LinkVariants;

  labelColor: string;
  borderColor: string;

  labelTypo: Typo;
}

const content_light: ContentLookAndFeel = {
  gap: 8,
  paddings: [20, 0],

  linkVariant: 'blue16',

  labelColor: themeColors.PrimaryBlack,
  borderColor: themeColors.SecondaryGray3,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH24,
}

export type ContentVariants = 'light';

export const content_variants: Map<ContentVariants, ContentLookAndFeel> = 
  new Map<ContentVariants, ContentLookAndFeel>([
    ['light', content_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ContentLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;

  border-bottom: 1px solid ${(args) => args.lookAndFeel.borderColor};

  & a {
    text-decoration: underline;
  }
`

export const Text = styled.div<{
  lookAndFeel: ContentLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
