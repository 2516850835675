import styled             from 'styled-components';

import { ButtonVariants } from 'src/components/common/button';
import { DialogVariants } from 'src/components/common/dialog';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,    
         cssFlexRow     } from 'src/providers/theme/shared';



type TwoActionDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;
  captionDangerColor: string;

  captionMessageGap: number;

  messageTypo: Typo;
  messageColor: string;

  messageActionGap: number;

  actionsGap: number;

  dialogVariant: DialogVariants;
  actionButtonVariant: ButtonVariants;
  cancelButtonVariant: ButtonVariants;
}

const twoactiondialog_light: TwoActionDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,
  captionDangerColor: themeColors.AccentRed1,

  captionMessageGap: 20,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  messageActionGap: 24,

  actionsGap: 16,

  dialogVariant: 'light',
  actionButtonVariant: 'h48bluefill',
  cancelButtonVariant: 'h48blueoutline',
}

export type TwoActionDialogVariants = 'light';

export const twoactiondialog_variants: Map<TwoActionDialogVariants, TwoActionDialogLookAndFeel> = 
  new Map<TwoActionDialogVariants, TwoActionDialogLookAndFeel>([
    ['light', twoactiondialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: TwoActionDialogLookAndFeel
}>`
  ${cssFlexColumn};
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px
`

export const Caption = styled.div<{
  lookAndFeel: TwoActionDialogLookAndFeel;
  isDanger: boolean;
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.isDanger ? args.lookAndFeel.captionDangerColor : args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: TwoActionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`

export const Actions = styled.div<{
  lookAndFeel: TwoActionDialogLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
  
  & > *:first-child {
    width: 40%;
    flex-shrink: 0;
  }

  & > *:last-child {
    flex-grow: 2;
  }
`