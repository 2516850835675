import styled              from 'styled-components';

import { LoadingVariants } from 'src/components/common/loading';

import { cssFlexRow,
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS,          
         cssFlexColumn   } from 'src/providers/theme/shared';



type IconListLookAndFeel = {
  itemHeight: number;

  paddings: number[];
  gap: number;

  loadingVariant: LoadingVariants;

  bgColor: string;
  busyBgColor: string;
  iconColor: string;
  labelColor: string;
  busyLabelColor: string;
  borderColor: string;

  labelTypo: Typo;
}

const iconlist_light: IconListLookAndFeel = {
  itemHeight: 76,

  paddings: [0, 0, 0, 20],
  gap: 20,

  loadingVariant: 'lightblue24',

  bgColor: 'transparent',
  busyBgColor: themeColors.SecondaryGray1,
  iconColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryBlack,
  busyLabelColor: themeColors.SecondaryGray4,
  borderColor: themeColors.SecondaryGray3,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,
}

export type IconListVariants = 'light';

export const iconlist_variants: Map<IconListVariants, IconListLookAndFeel> = 
  new Map<IconListVariants, IconListLookAndFeel>([
    ['light', iconlist_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: IconListLookAndFeel;
}>`
  ${cssFlexColumn};
`

export const ListItem = styled.div<{
  lookAndFeel: IconListLookAndFeel;
  isBusy: boolean;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.gap}px;
  align-items: center;

  height: ${(args) => args.lookAndFeel.itemHeight}px;
  cursor: pointer;

  background-color: ${(args) => args.isBusy ? args.lookAndFeel.busyBgColor : args.lookAndFeel.bgColor};
`

export const ListItemIcon = styled.div<{
  lookAndFeel: IconListLookAndFeel;
  isBusy: boolean;
}>`
  ${cssFlexRow};
  align-items: center;

  height: ${(args) => args.lookAndFeel.itemHeight}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  color: ${(args) => args.lookAndFeel.iconColor};
`

export const ListItemLabel = styled.div<{
  lookAndFeel: IconListLookAndFeel;
  isBusy: boolean;
}>`
  ${cssFlexRow};
  align-items: center;
  flex-grow: 2;

  height: ${(args) => args.lookAndFeel.itemHeight}px;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.isBusy ? args.lookAndFeel.busyLabelColor : args.lookAndFeel.labelColor};

  border-bottom: 1px solid ${(args) => args.lookAndFeel.borderColor};
`
