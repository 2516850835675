import * as React                      from 'react';

import { Dialog                      } from 'src/components/common/dialog';
import { IconButton                  } from 'src/components/common/icon-button';
import { DateLabel                   } from 'src/components/common/date-label';

import { Status,
         StatusVariants              } from 'src/components/features/main/inspection-list/status';
import { Container,
         Caption,
         DateBlock,
         ContentBlock,
         Label,
         Content,
         Close,
         CloseOutlineVectorBig,
         RemarkStatusDialogVariants,
         remarkstatusdialog_variants } from 'src/components/features/main/inspection-list/remark-status-dialog/ui';


import * as types                      from 'src/services/api/types';

import { useAppSelector, 
         useAppDispatch,
         storeApi                    } from 'src/store';



type Props = {
  variant: RemarkStatusDialogVariants;
}
export const RemarkStatusDialog: React.FC<Props> = ({ variant }) => {
  const lookAndFeel = remarkstatusdialog_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(storeApi.main.inspectionList.selectors.selectRemarkStatusOpened);
  const inspection = useAppSelector(storeApi.main.inspectionList.selectors.selectBottomSheetContext);

  if (inspection === null)
  {
    return null;
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened={isOpened}>
      <Container lookAndFeel = { lookAndFeel }>
        <Close lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.closeButtonVariant }
            isTapAllowed
            onTap = { () => dispatch(storeApi.main.inspectionList.actions.remarkStatusOpenedChanged(false)) }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </Close>
        <Caption lookAndFeel = { lookAndFeel }>
          Статус осмотра
        </Caption>
        <DateBlock lookAndFeel = { lookAndFeel }>
          <DateLabel variant = { lookAndFeel.datelabelVariant } date = { new Date(inspection.status.timestamp) } />
          <Status variant = { getStatusVariant(inspection.status.code) } label = { inspection.status.title } />
        </DateBlock>
        {inspection.status.fio !== null && inspection.status.fio !== '' && (
          <ContentBlock lookAndFeel = { lookAndFeel }>
            <Label lookAndFeel = { lookAndFeel }>
              Инициатор
            </Label>
            <Content lookAndFeel = { lookAndFeel }>
              {inspection.status.fio}
            </Content>
          </ContentBlock>
        )}
        {inspection.status.note !== null && inspection.status.note !== '' && (
          <ContentBlock lookAndFeel = { lookAndFeel }>
            <Label lookAndFeel = { lookAndFeel }>
              Примечание
            </Label>
            <Content lookAndFeel = { lookAndFeel }>
              {inspection.status.note}
            </Content>
          </ContentBlock>
        )}
       </Container>
    </Dialog>
  );
};

const getStatusVariant = (status: types.inspection.InspectionStatusCode): StatusVariants => {
  switch (status)
  {
    case types.inspection.InspectionStatusCode.AWAITING_CONFIRMATION:
    case types.inspection.InspectionStatusCode.AWAITING_REVIEW:
    case types.inspection.InspectionStatusCode.AWAITING_VERIFICATION:
      return 'bluefill';
    case types.inspection.InspectionStatusCode.TAKE_TO_WORK:
    case types.inspection.InspectionStatusCode.COMPLETED:
    case types.inspection.InspectionStatusCode.INTERNAL:
      return 'blueoutline';
    case types.inspection.InspectionStatusCode.RETURN_FOR_REVISION:
      return 'redoutline';
    case types.inspection.InspectionStatusCode.ERROR:
      return 'redfill';
    case types.inspection.InspectionStatusCode.DRAFT:
      return 'gray';
    default:
      return 'gray';
  }
}
