import * as React                         from 'react';

import { Button                         } from 'src/components/common/button';

import { Container, 
         Caption,
         Warning,
         Actions,
         ClearDataAcceptDialogVariants,
         cleardataacceptdialog_variants } from 'src/components/features/main/profile/clear-data-accept-dialog/ui';



type Props = {
  variant: ClearDataAcceptDialogVariants;
  onAccept: () => void;
  onCancel: () => void;
}
export const ClearDataAcceptDialog: React.FC<Props> = ({
  variant,
  onAccept,
  onCancel,
}) => {
  const lookAndFeel = cleardataacceptdialog_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Очистить данные?
      </Caption>
      <Warning lookAndFeel = { lookAndFeel }>
        Будут удалены все внутренние данные приложения, включая неотправленные на сервер.
        После завершения очистки будет вновь закачена справочная информация и данные об осмотрах с сервера. Вы согласны?
      </Warning>
      <Actions lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.cancelButtonVariant }
          label = 'Отмена'
          onTap = { onCancel }
        />
        <Button
          variant = { lookAndFeel.acceptButtonVariant }
          label = 'Очистить'
          onTap = { onAccept }
        />
      </Actions>
    </Container>
  );
};
