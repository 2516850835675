import styled             from 'styled-components';

import { cssFlexColumn,
         cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type RadioGroupLookAndFeel = {
  fullSize: number;
  radioSize: number;
  shadowSize: number;

  unselectedBackgroundColor: string;
  selectedBackgroundColor: string;
  borderColor: string;
  insetShadowColor: string;
  unselectedShadowColor: string;

  labelGap: number;

  typo: Typo;

  color: string;

  itemsGap: number;
}

const radiogroup_light: RadioGroupLookAndFeel = {
  fullSize: 24,
  radioSize: 20,
  shadowSize: 2,

  unselectedBackgroundColor: themeColors.PrimaryWhite,
  selectedBackgroundColor: themeColors.AccentBlue2,
  borderColor: themeColors.PrimaryWhite,
  insetShadowColor: themeColors.PrimaryWhite,
  unselectedShadowColor: themeColors.SecondaryGray4,

  labelGap: 20,

  typo: themeTypos.GRTSKREG_W400_S16_LH21,

  color: themeColors.PrimaryBlack,

  itemsGap: 28,
}

export type RadioGroupVariants = 'light';

export const radiogroup_variants: Map<RadioGroupVariants, RadioGroupLookAndFeel> = 
  new Map<RadioGroupVariants, RadioGroupLookAndFeel>([
    ['light', radiogroup_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: RadioGroupLookAndFeel;
  layout: 'vertical' | 'horizontal-left' | 'horizontal-center';
}>`
  ${({ layout }) => (layout === 'vertical') && `
    ${cssFlexColumn};
    width: 100%;
  `}
  ${({ layout }) => (layout === 'horizontal-left') && `
    ${cssFlexRow};
    justify-content: flex-start;
    width: fit-content;
  `}
  ${({ layout }) => (layout === 'horizontal-center') && `
    ${cssFlexRow};
    margin: 0 auto;
    width: fit-content;
  `}

  gap: ${(args) => args.lookAndFeel.itemsGap}px;
`

export const RadioItem = styled.div<{
  lookAndFeel: RadioGroupLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.labelGap}px;
  align-items: center;

  width: 100%;
  height: ${(args) => args.lookAndFeel.fullSize}px;

  padding-left: ${(args) => args.lookAndFeel.shadowSize}px;

  cursor: pointer;
`

export const Control = styled.div<{
  lookAndFeel: RadioGroupLookAndFeel
}>`
  ${cssFlexRow};

  width: ${(args) => args.lookAndFeel.radioSize}px;
  height: ${(args) => args.lookAndFeel.radioSize}px;
  background-color: ${(args) => args.lookAndFeel.unselectedBackgroundColor};
  box-shadow: 
    inset 0px 0px 0px ${(args) => args.lookAndFeel.shadowSize}px ${(args) => args.lookAndFeel.insetShadowColor},
    0px 0px 0px ${(args) => args.lookAndFeel.shadowSize}px ${(args) => args.lookAndFeel.unselectedShadowColor};
  border-radius: 50%;

  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;

  &.selected {
    background-color: ${(args) => args.lookAndFeel.selectedBackgroundColor};
    box-shadow: 
      inset 0px 0px 0px ${(args) => args.lookAndFeel.shadowSize}px ${(args) => args.lookAndFeel.insetShadowColor},
      0px 0px 0px ${(args) => args.lookAndFeel.shadowSize}px ${(args) => args.lookAndFeel.selectedBackgroundColor};
  }
`

export const Label = styled.span<{
  lookAndFeel: RadioGroupLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.color};
`
