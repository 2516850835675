import styled             from 'styled-components';

import { MailVector     } from 'src/components/vector/mail';
import { CalendarVector } from 'src/components/vector/calendar';
import { EditVector     } from 'src/components/vector/edit';

import { cssFlexRow,
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type DateLabelLookAndFeel = {
  height: number;
  typo: Typo;
  color: string;
  icon: React.ReactNode;
  iconColor: string;
  labelGap: number;
}

const datelabel_h24bluecalendar: DateLabelLookAndFeel = {
  height: 24,
  typo: themeTypos.GRTSKREG_W400_S10_LH16,
  color: themeColors.AccentBlue1,
  icon: <CalendarVector />,
  iconColor: themeColors.AccentBlue1,
  labelGap: 8,
}

const datelabel_h24blue2calendar: DateLabelLookAndFeel = {
  height: 24,
  typo: themeTypos.GRTSKREG_W400_S15_LH22,
  color: themeColors.AccentBlue2,
  icon: <CalendarVector />,
  iconColor: themeColors.AccentBlue2,
  labelGap: 8,
}

const datelabel_h16blueredmail: DateLabelLookAndFeel = {
  height: 16,
  typo: themeTypos.GRTSKREG_W400_S10_LH16,
  color: themeColors.AccentBlue1,
  icon: <MailVector />,
  iconColor: themeColors.AccentRed1,
  labelGap: 8,
}

const datelabel_h24grayedit: DateLabelLookAndFeel = {
  height: 24,
  typo: themeTypos.GRTSKREG_W400_S10_LH16,
  color: themeColors.SecondaryGray5,
  icon: <EditVector />,
  iconColor: themeColors.SecondaryGray5,
  labelGap: 8,
}

export type DateLabelVariants = 'h24bluecalendar' | 'h24blue2calendar' | 'h16blueredmail' | 'h24grayedit';

export const datelabel_variants: Map<DateLabelVariants, DateLabelLookAndFeel> = 
  new Map<DateLabelVariants, DateLabelLookAndFeel>([
    ['h24bluecalendar', datelabel_h24bluecalendar],
    ['h24blue2calendar', datelabel_h24blue2calendar],
    ['h16blueredmail', datelabel_h16blueredmail],
    ['h24grayedit', datelabel_h24grayedit],
  ]);

export const Container = styled.div<{
  lookAndFeel: DateLabelLookAndFeel
}>`
  ${cssFlexRow};
  align-items: center;

  color: ${(args) => args.lookAndFeel.color};

  width: 100%;
  gap: ${(args) => args.lookAndFeel.labelGap}px;

  & > svg {
    color: ${(args) => args.lookAndFeel.iconColor};
  }
`

export const Label = styled.span<{
  lookAndFeel: DateLabelLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
`
