export type Nullable<T> = T | null;

export const voidFunction = () => { };

export type PatternFormatValues = {
  formattedValue: string;
  value: string;
  floatValue: number | undefined;
}

export enum AsyncOpStatus {
  IDLE = 'idle',
  BUSY = 'busy',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum AsyncOpStatusWithTerminate {
  IDLE = 'idle',
  BUSY = 'busy',
  SUCCESS = 'success',
  ERROR = 'error',
  TERMINATE = 'terminate'
}