import React from 'react';



type Props = {
  className?: string;
}
export const ReloadVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = '#0041A0' strokeWidth = '2' d = 'M22 4.88702V9.33263L21.5455 9.77778L21.0909 10.2218C19.4871 10.2218 18.3533 10.2218 16.5455 10.2218M2 19.113C2 17.3446 2 16.2357 2 14.6667L2.45455 14.2227L2.90909 13.7778L7.45455 13.7782M4.28182 9.33263C4.74288 8.05833 5.52648 6.91902 6.55952 6.02102C7.59255 5.12302 8.84133 4.4956 10.1894 4.19729C11.5374 3.89898 12.9407 3.93952 14.2684 4.31511C15.596 4.69071 16.8048 5.38912 17.7818 6.34518L18.7227 7.20333L19.1932 7.6324M19.7182 14.6674C19.2571 15.9417 18.4735 17.081 17.4405 17.979C16.4075 18.877 15.1587 19.5044 13.8106 19.8027C12.4626 20.101 11.0593 20.0605 9.73164 19.6849C8.40398 19.3093 7.19523 18.6109 6.21818 17.6548L5.27727 16.7968L4.80682 16.3678' />
    </svg>
  );
};
