import * as React        from 'react';

import cc                from 'classcat';

import { Container,
         Tab,
         TabsVariants,
         tabs_variants } from 'src/components/common/tabs/ui';



export type TabItem = {
  id: string;
  label: string;
}

type Props = {
  variant: TabsVariants;
  items: TabItem[];
  value: TabItem;
  onChange: (value: TabItem) => void;
}
export const Tabs: React.FC<Props> = ({
  variant,
  items,
  value,
  onChange,
}) => {
  const lookAndFeel = tabs_variants.get(variant)!;

  const onTabClickHandler = (item: TabItem) => {
    onChange(item);
  }

  return (
    <Container lookAndFeel={lookAndFeel}>
      {items.map((item) => (
        <Tab
          key={item.id}
          lookAndFeel={lookAndFeel}
          className={cc({ active: item.id === value.id })}
          onClick={onTabClickHandler.bind(null, item)}
        >
          {item.label}
        </Tab>
      ))}
    </Container>
  );
};
