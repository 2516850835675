import styled                     from 'styled-components';

import { TypographyVariants     } from 'src/components/common/typography';
import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { cssFlexColumn, 
         themeColors            } from 'src/providers/theme/shared';



type DetailInfoLookAndFeel = {
  paddings: number[];
  borderColor: string;
  backgroundColor: string;
  gap: number;

  labeledContentVariant: LabeledContentVariants;
  labeledContentTypographyVariant: TypographyVariants;
}

const detailinfo_light: DetailInfoLookAndFeel = {
  paddings: [12, 16, 16, 16],
  borderColor: themeColors.CardBorderColor,
  backgroundColor: themeColors.PrimaryWhite,
  gap: 12,

  labeledContentVariant: 'gray5w400s12lh14gap4',
  labeledContentTypographyVariant: 'blackw400s14lh17',
}

export type DetailInfoVariants = 'light';

export const detailinfo_variants: Map<DetailInfoVariants, DetailInfoLookAndFeel> = 
  new Map<DetailInfoVariants, DetailInfoLookAndFeel>([
    ['light', detailinfo_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DetailInfoLookAndFeel;
}>`
  ${cssFlexColumn}
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`
