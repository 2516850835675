import * as React                from 'react';

import { Container, 
         BlockCaptionVariants,
         blockcaption_variants } from 'src/components/features/inspection/common/block-caption/ui';



type Props = {
  variant: BlockCaptionVariants;
  label: string;
  isRequired?: boolean;
}
export const BlockCaption: React.FC<Props> = ({
  variant,
  label,
  isRequired = false,
}) => {
  const lookAndFeel = blockcaption_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } isRequired = { isRequired }>
      { label }
    </Container>
  );
};
