import * as React              from 'react';

import { Typography          } from 'src/components/common/typography';
import { LabeledContent      } from 'src/components/common/labeled-content'; 

import { Container,
         DetailInfoVariants,
         detailinfo_variants } from 'src/components/features/qmmsg/detail/detail-info/ui';

import { Nullable            } from 'src/common';



type Props = {
  variant: DetailInfoVariants;
  timestampReg: number;
  outgoingNum: Nullable<string>;
  fioExecutor: Nullable<string>;
}
export const DetailInfo: React.FC<Props> = ({
  variant,
  timestampReg,
  outgoingNum,
  fioExecutor,
}) => {
  const lookAndFeel = detailinfo_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Дата регистрации'>
        <Typography variant = { lookAndFeel.labeledContentTypographyVariant }>
          { timestampReg === null ? 'не присвоено' : new Date(timestampReg).toLocaleDateString() }
        </Typography>
      </LabeledContent>
      <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Номер исходящего'>
        <Typography variant = { lookAndFeel.labeledContentTypographyVariant }>
          { outgoingNum === null ? 'не присвоено' : outgoingNum }
        </Typography>
      </LabeledContent>
      <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Ответственный'>
        <Typography variant = { lookAndFeel.labeledContentTypographyVariant }>
          { fioExecutor === null ? 'не присвоено' : fioExecutor }
        </Typography>
      </LabeledContent>
    </Container>
  );
};
