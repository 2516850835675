import styled             from 'styled-components';

import { cssFlexColumn,
         cssFlexRow,
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS      } from 'src/providers/theme/shared';



type RequirementsLookAndFeel = {
  completeColor: string;
  incompleteColor: string;

  innerGap: number;

  labelTypo: Typo;
  labelColor: string;

  itemsGap: number;
}

const requirements_light: RequirementsLookAndFeel = {
  completeColor: themeColors.AccentGreen1,
  incompleteColor: themeColors.SecondaryGray4,

  innerGap: 8,

  labelTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  labelColor: themeColors.PrimaryBlack,

  itemsGap: 8,
}

export type RequirementsVariants = 'light';

export const requirements_variants: Map<RequirementsVariants, RequirementsLookAndFeel> = 
  new Map<RequirementsVariants, RequirementsLookAndFeel>([
    ['light', requirements_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: RequirementsLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.itemsGap}px;

  width: 100%;
`

export const RequirementElement = styled.div<{
  lookAndFeel: RequirementsLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.innerGap}px;
  align-items: center;

  width: 100%;
`

export const Control = styled.div<{
  lookAndFeel: RequirementsLookAndFeel
}>`
  ${cssFlexRow};

  color: ${(args) => args.lookAndFeel.incompleteColor};

  &.completed {
    color: ${(args) => args.lookAndFeel.completeColor};
  }
`

export const Label = styled.span<{
  lookAndFeel: RequirementsLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.lookAndFeel.labelColor};
`
