import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuEditVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M9 4H3.75L2 5.75L2.00006 16.25L3.75 18H14.25L16 16.25V11' />
      <path fill = 'currentColor' d = 'M9.55556 13.1111L9.73746 13.8387L9.93899 13.7883L10.0859 13.6414L9.55556 13.1111ZM6 14L5.27239 13.8181L6.1819 14.7276L6 14ZM6.88889 10.4444L6.35856 9.91411L6.21167 10.061L6.16128 10.2625L6.88889 10.4444ZM17.4697 4.13634L9.02523 12.5808L10.0859 13.6414L18.5303 5.197L17.4697 4.13634ZM9.37365 12.3835L5.8181 13.2724L6.1819 14.7276L9.73746 13.8387L9.37365 12.3835ZM6.72761 14.1819L7.6165 10.6263L6.16128 10.2625L5.27239 13.8181L6.72761 14.1819ZM7.41922 10.9748L15.8637 2.53033L14.803 1.46967L6.35856 9.91411L7.41922 10.9748Z' />
    </svg>
  );
};
