import * as React                 from 'react';

import Drawer                     from '@mui/material/Drawer';

import { LoupeVector            } from 'src/components/vector/loupe';
import { EmptyCaseLoupeVector   } from 'src/components/vector/empty-case/loupe';

import { IconButton             } from 'src/components/common/icon-button';
import { EmptyCase              } from 'src/components/common/empty-case';
import { Typography             } from 'src/components/common/typography';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { Grow                   } from 'src/components/common/flex/grow';

import { Container,
         HeaderContainer,
         SearchContainer,
         ReferItemsContainer,
         ReferItem,
         ReferItemLabel,
         CloseOutlineVectorBig,
         ReferSelectorVariants,
         referselector_variants } from 'src/components/features/common/refer-selector/ui';

import Constants                  from 'src/services/constants';

import { Nullable               } from 'src/common';



export type ReferSelectorItem = {
  id: string;
  label: string;
}

type Props = {
  variant: ReferSelectorVariants;
  referItemsList: ReferSelectorItem[];
  isOpened: boolean;
  isSortByLabel?: boolean;
  isSearchable?: boolean;
  label: string;
  selectedId: Nullable<string>;
  onClose: () => void;
  onSelect: (referItem: ReferSelectorItem) => void;
}
export const ReferSelector: React.FC<Props> = ({
  variant,
  referItemsList,
  isOpened,
  isSortByLabel = false,
  isSearchable = false,
  label,
  selectedId,
  onClose,
  onSelect,
}) => {
  const lookAndFeel = referselector_variants.get(variant)!;

  const [searchPhrase, setSearchPhrase] = React.useState<string>('');
  const [listData, setListData] = React.useState<ReferSelectorItem[]>([]);

  const fullHeight = isSearchable || (referItemsList.length >= Constants.REFER_FULL_HEIGHT_COUNT);

  React.useEffect(() => {
    if (referItemsList.length > 0)
    {
      setListData(
        [
          ...(isSortByLabel ? referItemsList.sort((a, b) => (a.label > b.label ? 1 : -1)) : referItemsList)
        ]
      );
    }
  }, [referItemsList, isSortByLabel]);

  React.useEffect(() => {
    const lcSearch = searchPhrase.toLowerCase();

    if (referItemsList.length > 0)
    {
      const workItems = [
        ...(isSortByLabel ? referItemsList.sort((a, b) => (a.label > b.label ? 1 : -1)) : referItemsList)
      ];
      
      if (searchPhrase.length > 0)
      {
        setListData(
          workItems
            .map((item) => {
              const lcLabel = item.label.toLowerCase();
              if (lcLabel.includes(lcSearch))
              {
                const searchIndex = lcLabel.indexOf(lcSearch);
                const startStr = item.label.substring(0, searchIndex);
                const middleStr = item.label.substring(searchIndex, searchIndex + searchPhrase.length);
                const endStr = item.label.substring(searchIndex + searchPhrase.length);

                return ({
                  id: item.id,
                  label: `${startStr}<span>${middleStr}</span>${endStr}`,
                })
              }
              
              return null;
            })
            .filter((item) => item !== null) as ReferSelectorItem[]
        );
      }
      else
      {
        setListData(workItems);
      }
    }
  }, [searchPhrase, referItemsList, isSortByLabel]);

  const onSelectFromListHandler = (referItem: ReferSelectorItem) => {
    onSelect(referItem);
    setSearchPhrase('');
    onClose();
  }

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { () => { setSearchPhrase(''); onClose(); } }
      PaperProps={{ style: { borderRadius: fullHeight ? 0 : '15px 15px 0 0' } }}
    >
      <Container lookAndFeel = { lookAndFeel } fullHeight = { fullHeight }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          <Typography variant = { lookAndFeel.headerTypographyVariant }>
            { label }
          </Typography>
          <Grow size = { 2 } />
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => { setSearchPhrase(''); onClose(); } }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        {fullHeight && (
          <SearchContainer lookAndFeel = { lookAndFeel }>
            <InputTextbox
              variant = { lookAndFeel.searchInputVariant }
              iconPrefix = { <LoupeVector /> }
              value = { searchPhrase }
              onChange = { setSearchPhrase }
            />
          </SearchContainer>
        )}
        {referItemsList.length > 0 && listData.length === 0 && (
          <ReferItemsContainer lookAndFeel = { lookAndFeel }>
            <EmptyCase
              variant = { lookAndFeel.referEmptyCaseVariant }
              icon = { <EmptyCaseLoupeVector /> }
              header = 'Ничего не найдено'
              message = 'Попробуйте изменить поисковый запрос'
            />
          </ReferItemsContainer>
        )}
        {listData.length > 0 && (
          <ReferItemsContainer lookAndFeel = { lookAndFeel }>
            {listData.map((referItem) => (
              <ReferItem isSelected = { referItem.id === selectedId } lookAndFeel = { lookAndFeel } key = { referItem.id }>
                <ReferItemLabel
                  lookAndFeel = { lookAndFeel }
                  dangerouslySetInnerHTML = { { __html: referItem.label } }
                  onClick = { () => onSelectFromListHandler(referItem) }
                />
              </ReferItem>
            ))}
          </ReferItemsContainer>
        )}
      </Container>
    </Drawer>
  );
};
