import styled            from 'styled-components';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow,   
         cssFlexColumn } from 'src/providers/theme/shared';



type InfoItemsListLookAndFeel = {
  paddings: number[];
  innerGap: number;
  listGap: number;
  iconSize: number;
  iconMargin: number;

  labelTypo: Typo;

  backgroundColor: string;
  borderColor: string;
  iconBorderColor: string;
  iconColor: string;
  labelColor: string;
  linkColor: string;
}

const infoitemlist_light: InfoItemsListLookAndFeel = {
  paddings: [14, 12, 14, 15],
  innerGap: 8,
  listGap: 8,
  iconSize: 40,
  iconMargin: 2,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,
  iconBorderColor: themeColors.CardBorderColor,
  iconColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryBlack,
  linkColor: themeColors.AccentBlue2,
}

export type InfoItemsListVariants = 'light';

export const infoitemslist_variants: Map<InfoItemsListVariants, InfoItemsListLookAndFeel> = 
  new Map<InfoItemsListVariants, InfoItemsListLookAndFeel>([
    ['light', infoitemlist_light],
  ]);

export const ItemList = styled.div<{
  lookAndFeel: InfoItemsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.listGap}px;
`

export const Container = styled.div<{
  lookAndFeel: InfoItemsListLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.innerGap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px
           ${(args) => args.lookAndFeel.paddings[1]}px
           ${(args) => args.lookAndFeel.paddings[2]}px
           ${(args) => args.lookAndFeel.paddings[3]}px;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
`

export const Label = styled.span<{
  lookAndFeel: InfoItemsListLookAndFeel,
  isLink: boolean,
}>`
  flex-grow: 2;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.isLink ? args.lookAndFeel.linkColor : args.lookAndFeel.labelColor};
`;

export const Icon = styled.span<{
  lookAndFeel: InfoItemsListLookAndFeel,
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.iconSize}px;
  height: ${(args) => args.lookAndFeel.iconSize}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: ${(args) => args.lookAndFeel.iconMargin}px;

  border: 1px solid ${(args) => args.lookAndFeel.iconBorderColor};
  color: ${(args) => args.lookAndFeel.iconColor};
`;