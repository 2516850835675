import * as React                        from 'react';

import { PhotoProvider                 } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { Checkbox                      } from 'src/components/common/checkbox';
import { RadioGroup,
         RadioGroupItem                } from 'src/components/common/radio-group';
import { Typography                    } from 'src/components/common/typography';
import { Labeled                       } from 'src/components/common/labeled';

import { StaticValue                   } from 'src/components/features/inspection/view/static-value';

import { AttachmentPDF                 } from 'src/components/features/inspection/edit/attachment-pdf';

import { ComplaintFragmentLookAndFeel, 
         Container,
         FormContainer,
         PhotoList,
         Hr,
         ComplaintFragmentVariants,
         complaintfragment_variants    } from 'src/components/features/inspection/view/complaint-fragment/ui';

import * as types                        from 'src/services/api/types';
import { ComplaintType                 } from 'src/services/api/types/inspection';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                      } from 'src/store';



const MODE_DEL = types.inspection.DefectFileMode.DEL;
const ADDITIONAL_COSTS = types.inspection.DefectFileType.ADDITIONAL_COSTS;

type ComplaintFragmentElement = { lookAndFeel: ComplaintFragmentLookAndFeel; dispatch: AppDispatch; };

const Type: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);

  const items: RadioGroupItem[] = [{ id: 'remark', label: 'Замечание' }, { id: 'complaint', label: 'Рекламация' }];
  const item = complaintType === ComplaintType.REMARK ? items[0] : items[1];

  return (
    <RadioGroup
      variant = 'light'
      items = { items }
      value = { item }
      onChange = { (_) => { } }
    />
  )
}

const IsToScrap: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isToScrap = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsToScrap);

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Уценить металлопрокат до цены лома'
      value = { isToScrap ?? false }
      onChange = { (_) => { } }
    />
  )
}

const ScrapPrice: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isToScrap = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsToScrap);
  const scrapPrice = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedScrapPrice);

  if (complaintType === ComplaintType.REMARK || !isToScrap)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Цена лома, руб/т'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ scrapPrice }</StaticValue>
    </Labeled>
  )
}

const IsEvaluateRolledMetal: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isEvaluateRolledMetal = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsEvaluateRolledMetal);

  console.log(complaintType, isEvaluateRolledMetal);

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Уценить металлопрокат'
      value = { isEvaluateRolledMetal ?? false }
      onChange = { (_) => { } }
    />
  )
}

const RequirementRolledMetall: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isEvaluateRolledMetal = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsEvaluateRolledMetal);
  const requirementRolledMetall = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedRequirementRolledMetall);

  if (complaintType === ComplaintType.REMARK || !isEvaluateRolledMetal)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Укажите требование к уценке'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>
        { requirementRolledMetall !== null ? `${requirementRolledMetall}` : '' }
      </StaticValue>
    </Labeled>
  )
}

const IsToOffsetCosts: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isToOffsetCosts = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsToOffsetCosts);

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Компенсировать дополнительные затраты'
      value = { isToOffsetCosts ?? false }
      onChange = { (_) => { } }
    />
  )
}

const AmountOfCompensation: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const defectGuid = useAppSelector(storeApi.inspection.view.selectors.selectDefectExpandedGuid);

  const files = useAppSelector((store) => storeApi.inspection.view.selectors.selectDefectFiles(store, defectGuid)) ?? [];
  const pdfs = files.filter((file) => file.type === ADDITIONAL_COSTS && file.mode !== MODE_DEL);
  
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isToOffsetCosts = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsToOffsetCosts);
  const amountOfCompensation = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedAmountOfCompensation);
  
  if (complaintType === ComplaintType.REMARK || !isToOffsetCosts)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } isRequired label = 'Сумма компенсации без НДС'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ amountOfCompensation ?? '' }</StaticValue>
      <PhotoList lookAndFeel = { lookAndFeel }>
        <PhotoProvider >
          {pdfs.map((pdf) => (
            <AttachmentPDF
              key = { pdf.guid }
              variant = { lookAndFeel.attachmentPDFVariants }
              isAllowDelete = { false }
              pdf = { pdf }
              source = 'inspection'
            />
          ))}
        </PhotoProvider>
      </PhotoList>
    </Labeled>
  )
}

const IsReturnOfRolledMetal: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isReturnOfRolledMetal = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsReturnOfRolledMetal);

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Осуществить возврат металлопроката'
      value = { isReturnOfRolledMetal ?? false }
      onChange = { (_) => { } }
    />
  )
}

const IsSale: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isSale = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsSale);

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить скидку на забракованную партию проката в размере'
      value = { isSale ?? false }
      onChange = { (_) => { } }
    />
  )
}

const Sale: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isSale = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsSale);
  const sale = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedSale);

  if (complaintType === ComplaintType.REMARK || !isSale)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Размер скидки (%)'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ sale }</StaticValue>
    </Labeled>
  )
}

const IsRepresentative: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isRepresentative = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsRepresentative);

  if (complaintType === ComplaintType.REMARK && !isRepresentative)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Направить представителя'
      value = { isRepresentative ?? false }
      onChange = { (_) => { } }
    />
  )
}

const IsProvideResponse: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isProvideResponse = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsProvideResponse);

  if (complaintType === ComplaintType.REMARK && !isProvideResponse)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить оперативный ответ по действиям с забракованным металлопрокатом или возможности переработки проката'
      value = { isProvideResponse ?? false }
      onChange = { (_) => { } }
    />
  )
}

const IsIdentifyDangerous: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isIdentifyDangerous = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsIdentifyDangerous);

  if (complaintType === ComplaintType.REMARK && !isIdentifyDangerous)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Определить потенциально опасные партии проката по указанному несоответствию'
      value = { isIdentifyDangerous ?? false }
      onChange = { (_) => { } }
    />
  )
}

const IsAnalizProblem: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const isAnalizProblem = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIsAnalizProblem);

  if (complaintType === ComplaintType.REMARK && !isAnalizProblem)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Провести анализ проблем с целью выяснения причины дефекта'
      value = { isAnalizProblem ?? false }
      onChange = { (_) => { } }
    />
  )
}

const Is8d: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const complaintType = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedTypeComplaint);
  const is8d = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedIs8d);

  if (complaintType === ComplaintType.REMARK && !is8d)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить отчет в формате 8D'
      value = { is8d ?? false }
      onChange = { (_) => { } }
    />
  )
}

const OtherReq: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const otherReq = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedOtherReq);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Другие требования'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ otherReq ?? '' }</StaticValue>
    </Labeled>
  )
}

const IsContactDataExpanded: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const fio = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedFIO);
  const enterprise = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEnterprise);
  const phone = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedPhone);
  const email = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEmail);

  const contactDataExpanded = fio !== null || enterprise !== null || phone !== null || email !== null;

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Данные контактного лица представителя'
      value = { contactDataExpanded ?? false }
      onChange = { (_) => { } }
    />
  )
}

const FIO: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const fio = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedFIO);
  const enterprise = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEnterprise);
  const phone = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedPhone);
  const email = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEmail);

  const contactDataExpanded = fio !== null || enterprise !== null || phone !== null || email !== null;

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'ФИО'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ fio ?? '' }</StaticValue>
    </Labeled>
  )
}

const Enterprise: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const fio = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedFIO);
  const enterprise = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEnterprise);
  const phone = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedPhone);
  const email = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEmail);

  const contactDataExpanded = fio !== null || enterprise !== null || phone !== null || email !== null;

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Организация'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ enterprise ?? '' }</StaticValue>
    </Labeled>
  )
}

const Phone: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const fio = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedFIO);
  const enterprise = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEnterprise);
  const phone = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedPhone);
  const email = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEmail);

  const contactDataExpanded = fio !== null || enterprise !== null || phone !== null || email !== null;

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Телефон'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ phone ?? '' }</StaticValue>
    </Labeled>
  )
}

const Email: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const fio = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedFIO);
  const enterprise = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEnterprise);
  const phone = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedPhone);
  const email = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedEmail);

  const contactDataExpanded = fio !== null || enterprise !== null || phone !== null || email !== null;

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'E-mail'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ email ?? '' }</StaticValue>
    </Labeled>
  )
}

const GroupRevised: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const groupRevised = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedGroupRevised);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Переработанные партии без дефектов'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ groupRevised ?? '' }</StaticValue>
    </Labeled>
  )
}

const GroupRevisedValue: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const groupRevisedValue = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedGroupRevisedValue);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Объем (т)'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ groupRevisedValue ?? '' }</StaticValue>
    </Labeled>
  )
}

const GroupUnrevised: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const groupUnrevised = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedGroupUnrevised);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Не переработанные партии на складе'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ groupUnrevised ?? '' }</StaticValue>
    </Labeled>
  )
}

const GroupUnrevisedValue: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const groupUnrevisedValue = useAppSelector(storeApi.inspection.view.selectors.selectComplaintSelectedGroupUnrevisedValue);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Объем (т)'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ groupUnrevisedValue ?? '' }</StaticValue>
    </Labeled>
  )
}

const Decision: React.FC<ComplaintFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const decision = useAppSelector(storeApi.inspection.view.selectors.selectComplaintEditedDecision);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Решение по забракованной продукции'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ decision ?? '' }</StaticValue>
    </Labeled>
  )
}

type Props = {
  variant: ComplaintFragmentVariants;
}
export const ComplaintFragment: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = complaintfragment_variants.get(variant)!;
  const dispatch = useAppDispatch();

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <FormContainer lookAndFeel = { lookAndFeel }>
        <Type lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Typography variant = 'blackw600s18lh24'>Требования и запрашиваемые действия к поставщику</Typography>
        <IsToScrap lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <ScrapPrice lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsEvaluateRolledMetal lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <RequirementRolledMetall lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsToOffsetCosts lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <AmountOfCompensation lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsReturnOfRolledMetal lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsSale lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Sale lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsRepresentative lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsProvideResponse lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsIdentifyDangerous lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsAnalizProblem lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Is8d lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <OtherReq lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Hr lookAndFeel = { lookAndFeel } />
        <IsContactDataExpanded lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <FIO lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Enterprise lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Phone lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Email lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Hr lookAndFeel = { lookAndFeel } />
        <Typography variant = 'blackw600s18lh24'>Информация</Typography>
        <GroupRevised lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <GroupRevisedValue lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <GroupUnrevised lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <GroupUnrevisedValue lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Decision lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
      </FormContainer>
    </Container>
  );
};
