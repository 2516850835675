import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region POST profile/key_change_phone

export type PostProfileKeyChangePhoneIn = {
  /** Новый № телефона */
  phone: number;
}

export type PostProfileKeyChangePhoneOut = {
  /** Ключ авторизации */
  authKey: string;
}

export const preparePostProfileKeyChangePhone
  : PrepareCallHandler<PostProfileKeyChangePhoneIn, PostProfileKeyChangePhoneOut> = (arg: PostProfileKeyChangePhoneIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/profile/key_change_phone`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST profile/change_profile

export type PostProfileChangeProfileIn = {
  /** Ключ авторизации из auth/key_change_phone. Если номер телефона не менялся, то null */
  authKey: Nullable<string>;
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество м.б. null */
  middleName: Nullable<string>;
  /** Должность */
  post: string;
  /** Email */
  email: string;
  /** Номер телефона */
  phone: string;
  /** Виды пушей, на которые пользователь подписан */
  pushes: Array<string>;
}

export const preparePostProfileChangeProfile
  : PrepareCallHandler<PostProfileChangeProfileIn, void> = (arg: PostProfileChangeProfileIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/profile/change_profile`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST profile/confirm_email

export type PostProfileConfirmEmailIn = void

export const preparePostProfileConfirmEmail
  : PrepareCallHandler<PostProfileConfirmEmailIn, void> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/profile/confirm_email`,
      method: 'POST',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
