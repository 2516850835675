import * as React               from 'react';

import { ArrowDownVector      } from 'src/components/vector/arrow-down';
import { ArrowUpVector        } from 'src/components/vector/arrow-up';

import { IconButton           } from 'src/components/common/icon-button';
import { Typography           } from 'src/components/common/typography';

import { Wrapper,
         Container,
         ExpandButtonWrapper, 
         DetailNoteVariants,
         detailnote_variants  } from 'src/components/features/qmmsg/detail/detail-note/ui';



type Props = {
  variant: DetailNoteVariants;
  header: string;
  note: string;
}
export const DetailNote: React.FC<Props> = ({
  variant,
  header,
  note,
}) => {
  const lookAndFeel = detailnote_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Wrapper lookAndFeel = { lookAndFeel }>
      <Container lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.headerTypographyVariant }>{ header }</Typography>
        {expanded &&
          <Typography variant = { lookAndFeel.noteTypographyVariant }>{ note }</Typography>
        }
      </Container>
      <ExpandButtonWrapper lookAndFeel = { lookAndFeel }>
        <IconButton
          variant = { lookAndFeel.expandButtonVariant }
          isTapAllowed
          onTap = { () => setExpanded(!expanded) }
        >
          { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
        </IconButton>
      </ExpandButtonWrapper>
    </Wrapper>
  );
};
