import * as React                      from 'react';

import { useTheme                    } from 'styled-components';

import { useAlert                    } from 'react-alert';

import { useParams,
         useNavigate                 } from 'react-router-dom';

import { ArrowBackVector             } from 'src/components/vector/arrowback';
import { FavoriteVector              } from 'src/components/vector/favorite';
import { ReportVector                } from 'src/components/vector/report';
import { FilterVector                } from 'src/components/vector/filter';

import { Typography                  } from 'src/components/common/typography';
import { BlockUI                     } from 'src/components/common/block-ui';
import { Loading                     } from 'src/components/common/loading';
import { EmptyCaseEnvelope, 
         EmptyCaseLoupe              } from 'src/components/common/empty-case';

import { NavBar                      } from 'src/components/features/common/nav-bar';

import { FilterTagItem,
         FilterTags                  } from 'src/components/features/qmmsg/list/filter-tags';
import { Item                        } from 'src/components/features/qmmsg/list/item';
import { Filter                      } from 'src/components/features/qmmsg/list/filter';
import { Report                      } from 'src/components/features/qmmsg/list/report';

import { Screen,
         ScreenBody,
         CompanyList,
         PageLoadingContainer,
         PageLoading,
         ListLayout,
         layout_light,
         layout_dark                 } from 'src/containers/qmmsg/list/layout';

import { Urls                        } from 'src/providers/routing';

import * as types                      from 'src/services/api/types';
import Api                             from 'src/services/api';
import { AccountRole                 } from 'src/services/api/types/auth';
import { StepQMReferElement          } from 'src/services/api/types/refers';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                    } from 'src/store';

import { ReportClaimsReject,
         FilterClaimsReject,
         FilterClaimsFavoriteReject, 
         FilterClaimsWithResetReject } from 'src/store/qmmsg/list';

import { AsyncOpStatus               } from 'src/common';



type ClaimsListScreenElement = { layout: ListLayout; dispatch: AppDispatch; }

const Navigation: React.FC<ClaimsListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const isFilterFilled = useAppSelector(storeApi.qmmsg.list.selectors.selectFiltersFilled);
  const filterOpened = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterOpened);
  const reportOpened = useAppSelector(storeApi.qmmsg.list.selectors.selectReportOpened);
  const opStatus = useAppSelector(storeApi.qmmsg.list.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.qmmsg.list.selectors.selectOpStatusLabel);
  const isFavorite = useAppSelector(storeApi.qmmsg.list.selectors.selectIsFavorite);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const onReportHandler = () => {
    dispatch(storeApi.qmmsg.list.actions.reportOpened());
  }

  const onFilterHandler = () => {
    dispatch(storeApi.qmmsg.list.actions.filterOpened());
  }

  const onCloseFilterHandler = () => {
    dispatch(storeApi.qmmsg.list.actions.filterClosed());
  }

  const onCloseReportHandler = () => {
    dispatch(storeApi.qmmsg.list.actions.reportClosed());
  }

  const onAcceptFilterFavoriteHandler = () => {
    sessionStorage.setItem('qmmsgListPosition', '0');
    if (!isFavorite)
    {
      dispatch(storeApi.qmmsg.list.async.filterClaimsFavoriteAsync())
        .unwrap()
        .catch((rawError) => {
          const error = rawError as FilterClaimsFavoriteReject;
          if (!Api.isCommonAuthError(error.statusCode ?? 0))
          {
            alert.error(error.message);
          }
        });
    }
    else
    {
      onAcceptFilterHandler();
    }
  }

  const onAcceptFilterHandler = () => {
    dispatch(storeApi.qmmsg.list.async.filterClaimsAsync({ isStart: true }))
      .unwrap()
      .catch((rawError) => {
        const error = rawError as FilterClaimsReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onAcceptReportHandler = () => {
    dispatch(storeApi.qmmsg.list.async.reportClaimsAsync())
      .unwrap()
      .then(() => {
        alert.success(`Отчет поставлен в очередь на отправку на email ${account.email}. Ожидайте результат в течение 5-10 минут.`)
      })
      .catch((rawError) => {
        const error = rawError as ReportClaimsReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onBackClick = () => {
    navigate(-1);
  }


  return (
    <React.Fragment>
      <NavBar 
        variant = { layout.navbarVariant }
        label = 'Статусы по претензиям'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: onBackClick
          }
        ]}
        endButtons={[
          {
            id: 'favorite',
            vector: <FavoriteVector />,
            isSelected: isFavorite,
            action: onAcceptFilterFavoriteHandler
          },
          {
            id: 'report',
            vector: <ReportVector />,
            action: onReportHandler
          },
          {
            id: 'filter',
            vector: <FilterVector />,
            action: onFilterHandler,
            isDot: isFilterFilled,
            isDisabled: isFavorite
          }
        ]}
      />
      <Filter
        isOpened = { filterOpened }
        variant = { layout.filterVariant }
        onClose = { onCloseFilterHandler }
        onAccept = { onAcceptFilterHandler }
      />
      <Report
        isOpened = { reportOpened }
        variant = { layout.reportVariant }
        onClose = { onCloseReportHandler }
        onAccept = { onAcceptReportHandler }
      />
      <BlockUI
        variant = { layout.blockUIVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel } 
      />
    </React.Fragment>
  )
}

const Claims: React.FC<ClaimsListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const isFilterFilled = useAppSelector(storeApi.qmmsg.list.selectors.selectFiltersFilled);
  const isFavorite = useAppSelector(storeApi.qmmsg.list.selectors.selectIsFavorite);
  const opStatus = useAppSelector(storeApi.qmmsg.list.selectors.selectOpStatus);
  const claims = useAppSelector(storeApi.qmmsg.list.selectors.selectClaims);
  const viewed = useAppSelector(storeApi.qmmsg.list.selectors.selectViewed);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const timestampBeg = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterTimestampBeg);
  const timestampEnd = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterTimestampEnd);
  const statusId = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterStatusId);
  const statusQM = useAppSelector(storeApi.qmmsg.list.selectors.selectStatusQM);
  const stepQM = useAppSelector(storeApi.qmmsg.list.selectors.selectStepQM);
  const stepCode = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterStepCode);
  const msgNumber = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterQMMsgNumber);
  const outgoingNum = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterOutgoingNum);
  const typeCode = useAppSelector(storeApi.qmmsg.list.selectors.selectFilterTypeCode);
  const typeQM = useAppSelector(storeApi.qmmsg.list.selectors.selectTypeQM);

  const filterTagItems = React.useMemo<FilterTagItem[]>(() => {
    const items: FilterTagItem[] = [];

    if (timestampBeg !== null)
    {
      items.push({
        id: 'timestampBeg',
        label: `Начало периода: ${timestampBeg.toLocaleDateString()}`
      })
    }

    if (timestampEnd !== null)
    {
      items.push({
        id: 'timestampEnd',
        label: `Конец периода: ${timestampEnd.toLocaleDateString()}`
      })
    }

    if (statusId !== null)
    {
      items.push({
        id: 'statusId',
        label: `Статус: ${statusQM.find((item) => item.id === statusId)?.title}`
      })
    }

    if (stepCode !== null)
    {
      items.push({
        id: 'stepCode',
        label: `Код шага: ${stepQM.reduce<StepQMReferElement[]>((acc, v) => acc.find((item) => item.code === v.code) === undefined ? [...acc, v] : acc, []).find((item) => item.code === stepCode)?.title}`
      })
    }

    if (msgNumber !== '')
    {
      items.push({
        id: 'msgNumber',
        label: `№ ув./пр.: ${msgNumber}`
      })
    }

    if (outgoingNum !== '')
    {
      items.push({
        id: 'outgoingNum',
        label: `Исх. №: ${outgoingNum}`
      })
    }

    if (typeCode !== null)
    {
      items.push({
        id: 'typeCode',
        label: `Тип: ${typeQM.find((item) => item.code === typeCode)?.title}`
      })
    }

    return items;
  }, [timestampBeg, timestampEnd, statusId, statusQM, stepCode, msgNumber, outgoingNum, typeCode, typeQM, stepQM]);

  const onResetFilterTagHandler = (tag: FilterTagItem) => {
    dispatch(storeApi.qmmsg.list.async.filterClaimsWithResetAsync({ resetFilter: tag.id }))
      .unwrap()
      .catch((rawError) => {
        const error = rawError as FilterClaimsWithResetReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  return (
    <React.Fragment>
      {isFilterFilled && !isFavorite &&
        <FilterTags
          variant = { layout.tagsVariant }
          items = { filterTagItems }
          onClose = { onResetFilterTagHandler }
        />
      }
      {opStatus === AsyncOpStatus.IDLE && claims.length === 0 && !isFilterFilled &&
        <EmptyCaseEnvelope variant = { layout.emptyCaseVariant } header = 'Уведомления/претензии отсутствуют' />
      }
      {opStatus === AsyncOpStatus.IDLE && claims.length === 0 && isFilterFilled &&
        <EmptyCaseLoupe variant = { layout.emptyCaseVariant } header = 'Ничего не найдено' message = 'В работе нет ни одного уведомления/претензии' />
      }
      {opStatus === AsyncOpStatus.IDLE && claims.length > 0 && claims.map((company) => (
        <CompanyList layout = { layout } key = { company.eskCode }>
          {account.role === AccountRole.Stpk &&
            <Typography variant='blackw400s15lh20'>{ company.title }</Typography>
          }
          {company.qmMsgs.map((item) => 
            <Item
              key = { item.qmMsgId }
              variant = { layout.itemVariant }
              item = { item }
              isViewed = { viewed.includes(`${item.qmMsgId}_${item.step.id}_${item.status.id}_${item.condition.id}`) }
              onClick = { (item: types.qmmsg.QMMsg) => Urls.QMMsgDetail.build('qmmsglist', item.qmMsgId).navigate() }
            />
          )}
        </CompanyList>
      ))}
    </React.Fragment>
  )
}

export const ListScreen = () => {
  const theme = useTheme();
  const alert = useAlert();
  const { eskCodes, guidInspection } = useParams();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  const isEndPage = useAppSelector(storeApi.qmmsg.list.selectors.selectIsEndPage);
  const pageLoading = useAppSelector(storeApi.qmmsg.list.selectors.selectPageLoading);
  const opStatus = useAppSelector(storeApi.qmmsg.list.selectors.selectOpStatus);

  const isFavorite = useAppSelector(storeApi.qmmsg.list.selectors.selectIsFavorite);

  const scrollHandler = React.useCallback(() => {
    const screenBody = document.getElementById('qmmsg-screen-body');

    if (screenBody !== null && opStatus === AsyncOpStatus.IDLE)
    {
      const scrollTop = screenBody.scrollTop;
      const offsetHeight = screenBody.offsetHeight;
      const scrollHeight = screenBody.scrollHeight;
      sessionStorage.setItem('qmmsgListPosition', `${scrollTop}`);

      if (Math.abs(scrollHeight - offsetHeight - scrollTop) < 10)
      {
        if (!pageLoading && !isEndPage && !isFavorite)
        {
          dispatch(storeApi.qmmsg.list.async.filterClaimsAsync({ isStart: false }))
            .unwrap()
            .catch((rawError) => {
              const error = rawError as FilterClaimsReject;
              if (!Api.isCommonAuthError(error.statusCode ?? 0))
              {
                alert.error(error.message);
              }
            });
        }
      }
    }
  }, [pageLoading, isEndPage, isFavorite, opStatus, dispatch, alert]);

  React.useEffect(() => {

    const screenBody = document.getElementById('qmmsg-screen-body');

    if (screenBody !== null)
    {
      const qmmsgListPosition = sessionStorage.getItem('qmmsgListPosition');

      if (qmmsgListPosition !== null)
      {
        
        screenBody.scrollTo({ top: parseInt(qmmsgListPosition, 10) })
      }
      screenBody.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (screenBody !== null)
      {
        screenBody.removeEventListener('scroll', scrollHandler);
      }
    }
  }, [scrollHandler]);

  React.useEffect(
    () => {
      if (bootOpStarted.current || (eskCodes === undefined && guidInspection === undefined))
      {
        return;
      }

      bootOpStarted.current = true;
      if (isFavorite)
      {
        dispatch(storeApi.qmmsg.list.async.filterClaimsFavoriteAsync())
          .unwrap()
          .catch((rawError) => {
            const error = rawError as FilterClaimsFavoriteReject;
            if (!Api.isCommonAuthError(error.statusCode ?? 0))
            {
              alert.error(error.message);
            }
          });
      }
      else
      {
        dispatch(storeApi.qmmsg.list.async.prepareInfoAsync({
          eskCodes,
          guidInspection,
        }));
      }
      
    },
    [dispatch, alert, eskCodes, guidInspection, isFavorite]
  );

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout } id = 'qmmsg-screen-body'>
        <Claims layout = { layout } dispatch = { dispatch } />
        {pageLoading && (
          <PageLoadingContainer layout = { layout }>
            <PageLoading layout = { layout }>
              <Loading variant = { layout.pageLoadingVariant } />
            </PageLoading>
          </PageLoadingContainer>
        )}
      </ScreenBody>
    </Screen>
  );
};
