import React from 'react';



type Props = {
  className?: string;
}
export const TrashVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path
        d = 'M4 7H20M18 10V19L16 21H8L6 19V10M7 7V4.5105L8.5 3H15.5L17 4.5105V7M10 10V18.0921M14 10V18.0921'
        stroke = 'currentColor'
        strokeWidth = '1.5'
      />
    </svg>
  );
};
