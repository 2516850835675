import * as React       from 'react';

import { useTheme     } from 'styled-components';

import Ripples          from 'react-ripples';

import { voidFunction } from 'src/common'



type Props = {
  isTapAllowed: boolean;
  children: React.ReactNode;
  onTap: () => void;
}
export const Ripple: React.FC<Props> = ({
  isTapAllowed,
  children,
  onTap
}) => {
  const theme = useTheme();

  const onTapHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isTapAllowed)
    {
      setTimeout(() => onTap(), theme.rippleDuration);
    }
  }

  return (
    <Ripples
      during = { isTapAllowed ? theme.rippleDuration : 0 }
      onClick = { onTapHandler }
      onPointerEnterCapture = { voidFunction }
      onPointerLeaveCapture = { voidFunction }
      placeholder = ''
    >
      { children }
    </Ripples>
  );
};
