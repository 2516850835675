import * as React                  from 'react';

import { IconButton              } from 'src/components/common/icon-button';

import { QMMsgList               } from 'src/components/features/main/inspection-list/qmmsg-list';
import { Container,
         HalfCircle,
         Backdrop,
         Content,
         Header,
         HeaderClose,
         CloseOutlineVectorBig,
         NoMoreShowButton,
         HeaderLabel,
         Guide,
         NoMoreShow,
         QMMsgJumpGuideVariants,
         qmmsgjumpguide_variants } from 'src/components/features/main/inspection-list/qmmsg-jump-guide/ui';

import { QMMsgAction,
         QMMsgStatusCode         } from 'src/services/api/types/inspection';

import { voidFunction            } from 'src/common';



type Props = {
  variant: QMMsgJumpGuideVariants;
  onClose: (noMoreShow: boolean) => void;
}
export const QMMsgJumpGuide: React.FC<Props> = ({
  variant,
  onClose,
}) => {
  const lookAndFeel = qmmsgjumpguide_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <HalfCircle lookAndFeel = { lookAndFeel } />
      <Backdrop lookAndFeel = { lookAndFeel } />
      <Content lookAndFeel = { lookAndFeel }>
        <Header lookAndFeel = { lookAndFeel }>
          <HeaderClose lookAndFeel = { lookAndFeel }>
            <IconButton variant = { lookAndFeel.iconButtonVariant } isTapAllowed onTap = { () => onClose(false) }>
              <CloseOutlineVectorBig />
            </IconButton>
          </HeaderClose>
          <HeaderLabel lookAndFeel = { lookAndFeel }>
            Перейдите<br/>
            к ходу<br/>
            рассмотрения<br/>
            претензии по<br/>
            кнопке
          </HeaderLabel>
        </Header>
        <Guide lookAndFeel = { lookAndFeel }>
          <QMMsgList
            variant = { lookAndFeel.qmmsgListVariant }
            list = {[
              {
                code: QMMsgStatusCode.THREE,
                id: 8203,
                num: "005654",
                timestampReg: 1648587600000,
                title: "На рассмотрении",
                type: "notification",
                action: QMMsgAction.APPROVED,
              },
            ]}
            onJump = { voidFunction }
          />
        </Guide>
        <NoMoreShow lookAndFeel = { lookAndFeel }>
          <NoMoreShowButton lookAndFeel = { lookAndFeel } onClick = { () => onClose(true) }>
            Больше не показывать
          </NoMoreShowButton>
        </NoMoreShow>
      </Content>
    </Container>
  );
};
