import * as React                        from 'react';

import { Checkbox                      } from 'src/components/common/checkbox';
import { Typography                    } from 'src/components/common/typography';
import { Button                        } from 'src/components/common/button';
import { TwoActionDialog               } from 'src/components/common/two-action-dialog';

import { BlockCaption                  } from 'src/components/features/inspection/common/block-caption';

import { PieceItem                     } from 'src/components/features/inspection/edit/piece-item';

import { Container, 
         List,
         AllSelector,
         AllSelectorCheckboxContainer, 
         AllSelectorAllContainer,
         AllSelectorDeleteContainer,
         PieceListVariants,
         piecelist_variants            } from 'src/components/features/inspection/edit/piece-list/ui';

import { InspectionState               } from 'src/services/api/types/inspection';
import { AccountRole                   } from 'src/services/api/types/auth';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch                } from 'src/store';



type Props = {
  variant: PieceListVariants;
  defectGuid: string;
}
export const PieceList: React.FC<Props> = ({
  variant,
  defectGuid,
}) => {
  const lookAndFeel = piecelist_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const pieceGuidsStr = useAppSelector(storeApi.inspection.edit.selectors.selectPieceGuidsForExpandedDefect);
  const markedForDeleteStr = useAppSelector(storeApi.inspection.edit.selectors.selectPieceMarkedForDeleteGuids);

  const isMultiDelete = inspectionState === InspectionState.CREATE;

  const pieceGuids = pieceGuidsStr === '' ? [] : pieceGuidsStr.split(';');
  const markedForDelete = markedForDeleteStr === ''? [] : markedForDeleteStr.split(';');

  const isContractor = account.role === AccountRole.Contractor;
  // const isAddPieceAllowed = !((inspectionState === InspectionState.EDIT) || (account.role === AccountRole.Contractor && pieceGuids.length >= 1));
  const isAddPieceAllowed = isContractor && (inspectionState !== InspectionState.EDIT) && (pieceGuids.length === 0);

  const [isConfirmOpened, changeConfirmOpened] = React.useState<boolean>(false);

  const onDeleteClickHandler = () => {
    if (markedForDelete.length > 0)
    {
      changeConfirmOpened(true);
    }
  }

  const onAddPositionTapHandler = () => {
    dispatch(storeApi.inspection.edit.actions.pieceForEditSelected(null));
  }

  const onAllDeleteChangeHandler = (checked: boolean) => {
    if (checked)
    {
      dispatch(storeApi.inspection.edit.actions.pieceForDeleteAllMarked(pieceGuids));
    }
    else
    {
      dispatch(storeApi.inspection.edit.actions.pieceForDeleteAllUnmarked());
    }
  }

  const onConfirmDeleteCancelHandler = () => {
    changeConfirmOpened(false);
  }

  const onConfirmDeleteAcceptHandler = () => {
    changeConfirmOpened(false);
    dispatch(storeApi.inspection.edit.actions.pieceForDeleteMarkedDeleted());
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <BlockCaption
        variant = { lookAndFeel.blockCaptionVariant }
        isRequired
        label = 'Информация о продукции'
      />
      {isMultiDelete && pieceGuids.length > 0 && 
        <AllSelector lookAndFeel = { lookAndFeel }>
          <AllSelectorCheckboxContainer lookAndFeel = { lookAndFeel }>
            <Checkbox
              variant = { lookAndFeel.checkboxVariant }
              value = { markedForDelete.length === pieceGuids.length }
              onChange = { onAllDeleteChangeHandler } 
            />
          </AllSelectorCheckboxContainer>
          <AllSelectorAllContainer lookAndFeel = { lookAndFeel }>
            <Typography variant = { lookAndFeel.allVariant }>Все</Typography>
          </AllSelectorAllContainer>
          <AllSelectorDeleteContainer lookAndFeel = { lookAndFeel } onClick = { onDeleteClickHandler }>
            <Typography variant = { markedForDelete.length > 0 ? lookAndFeel.deleteSelectVariant : lookAndFeel.deleteUnselectVariant }>Удалить</Typography>
          </AllSelectorDeleteContainer>
        </AllSelector>
      }
      <List lookAndFeel = { lookAndFeel }>
        {pieceGuids.map((pieceGuid) => (
          <PieceItem
            variant = { lookAndFeel.positionItemVariant }
            pieceGuid = { pieceGuid }
            key = { pieceGuid }
          />
        ))}
      </List>
      {isAddPieceAllowed && (
        <Button
          variant = { lookAndFeel.addPositionButtonVariant }
          label = 'Добавить позицию'
          onTap = { onAddPositionTapHandler }
        />
      )}
      <TwoActionDialog
        variant = { lookAndFeel.deleteConfirmVariant }
        opened = { isConfirmOpened }
        caption = { isMultiDelete && markedForDelete.length > 1 ? 'Удалить выбранные позиции?' : 'Удалить выбранную позицию?' }
        isDanger
        cancelLabel = 'Отмена'
        actionLabel = 'Удалить'
        onCancel = { onConfirmDeleteCancelHandler }
        onAction = { onConfirmDeleteAcceptHandler }
      />
    </Container>
  );
};
