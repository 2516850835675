import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type ButtonLookAndFeel = {
  typo: Typo;

  activeBgColor: string;
  disableBgColor: string;
  activeTextColor: string;
  disableTextColor: string;
  activeBorderColor: string;
  disableBorderColor: string;

  height: number;
  borderSize: number;
};

const baseButtonLookAndFeel = {
  borderSize: 1,
};

const baseBlueFillButtonLookAndFeel = {
  ...baseButtonLookAndFeel,
  activeBgColor: themeColors.AccentBlue2,
  disableBgColor: themeColors.SecondaryGray3,
  activeTextColor: themeColors.PrimaryWhite,
  disableTextColor: themeColors.PrimaryWhite,
  activeBorderColor: themeColors.AccentBlue2,
  disableBorderColor: themeColors.SecondaryGray3,
};

const baseBlueOutlineButtonLookAndFeel = {
  ...baseButtonLookAndFeel,
  activeBgColor: themeColors.PrimaryWhite,
  disableBgColor: themeColors.PrimaryWhite,
  activeTextColor: themeColors.AccentBlue2,
  disableTextColor: themeColors.SecondaryGray3,
  activeBorderColor: themeColors.AccentBlue2,
  disableBorderColor: themeColors.SecondaryGray3,
}

const baseRedFillButtonLookAndFeel = {
  ...baseButtonLookAndFeel,
  activeBgColor: themeColors.AccentRed1,
  disableBgColor: themeColors.SecondaryGray3,
  activeTextColor: themeColors.PrimaryWhite,
  disableTextColor: themeColors.PrimaryWhite,
  activeBorderColor: themeColors.AccentRed1,
  disableBorderColor: themeColors.SecondaryGray3,
}

const baseRedOutlineButtonLookAndFeel = {
  ...baseButtonLookAndFeel,
  activeBgColor: themeColors.PrimaryWhite,
  disableBgColor: themeColors.PrimaryWhite,
  activeTextColor: themeColors.AccentRed1,
  disableTextColor: themeColors.SecondaryGray3,
  activeBorderColor: themeColors.AccentRed1,
  disableBorderColor: themeColors.SecondaryGray3,
}

const h48 = {
  typo: themeTypos.GRTSKREG_W400_S13_LH20,
  height: 48,
}

const h36 = {
  typo: themeTypos.GRTSKREG_W400_S11_LH18,
  height: 36,
}

const h28 = {
  typo: themeTypos.GRTSKREG_W400_S11_LH18,
  height: 28,
}

const button_h48bluefill: ButtonLookAndFeel = {
  ...baseBlueFillButtonLookAndFeel,
  ...h48,
};

const button_h36bluefill: ButtonLookAndFeel = {
  ...baseBlueFillButtonLookAndFeel,
  ...h36,
}

const button_h48blueoutline: ButtonLookAndFeel = {
  ...baseBlueOutlineButtonLookAndFeel,
  ...h48,
};

const button_h36blueoutline: ButtonLookAndFeel = {
  ...baseBlueOutlineButtonLookAndFeel,
  ...h36,
};

const button_h28redfill: ButtonLookAndFeel = {
  ...baseRedFillButtonLookAndFeel,
  ...h28,
};

const button_h36redoutline: ButtonLookAndFeel = {
  ...baseRedOutlineButtonLookAndFeel,
  ...h36,
};

export type ButtonVariants = 'h48bluefill' | 'h36bluefill' | 'h48blueoutline' | 'h36blueoutline' | 'h28redfill' | 'h36redoutline';

export const button_variants: Map<ButtonVariants, ButtonLookAndFeel> = new Map<ButtonVariants, ButtonLookAndFeel>([
  ['h48bluefill', button_h48bluefill],
  ['h36bluefill', button_h36bluefill],
  ['h48blueoutline', button_h48blueoutline],
  ['h36blueoutline', button_h36blueoutline],
  ['h28redfill', button_h28redfill],
  ['h36redoutline', button_h36redoutline],
]);

export const Container = styled.button<{
  lookAndFeel: ButtonLookAndFeel;
  isIcon: boolean;
}>`
  width: ${(args) => args.isIcon ? `${args.lookAndFeel.height}px` : '100%'};
  height: ${(args) => args.lookAndFeel.height}px;

  ${cssFlexRow};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background-color: ${(args) => args.lookAndFeel.activeBgColor};
  border: ${(args) => `${args.lookAndFeel.borderSize}px solid ${args.lookAndFeel.activeBorderColor}`};

  &:disabled {
    background-color: ${(args) => args.lookAndFeel.disableBgColor};
    border: ${(args) => `${args.lookAndFeel.borderSize}px solid ${args.lookAndFeel.disableBorderColor}`};
  }

  &:disabled span {
    color: ${(args) => args.lookAndFeel.disableTextColor};
  }
`;

export const Label = styled.span<{
  lookAndFeel: ButtonLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.activeTextColor};
`;
