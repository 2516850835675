import styled           from 'styled-components';

import { themeColors, 
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type InputCalendarLookAndFeel = {
  typo: Typo;
  normalColor: string;
  disabledColor: string;

  calendarSize: number;
  calendarColor: string;
}

const inputcalendar_h48light: InputCalendarLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH20,
  normalColor: themeColors.PrimaryBlack,
  disabledColor: themeColors.SecondaryGray4,

  calendarSize: 46,
  calendarColor: themeColors.AccentBlue2,
};

export type InputCalendarVariants = 'h48light';

export const inputcalendar_variants: Map<InputCalendarVariants, InputCalendarLookAndFeel> = 
  new Map<InputCalendarVariants, InputCalendarLookAndFeel>([
    ['h48light', inputcalendar_h48light],
  ]);

export const Input = styled.input<{
  isDisabled: boolean;
  lookAndFeel: InputCalendarLookAndFeel;
}>`
  width: 1px;
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}

  color: ${(args) => args.isDisabled ? args.lookAndFeel.disabledColor : args.lookAndFeel.normalColor};
`;
