import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuRevocationVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M2 5V8.99763L2.5 9.49763L3.00001 9.99763H7M21.4902 8.99763C20.9831 7.56442 20.1211 6.28304 18.9847 5.27305C17.8484 4.26307 16.4747 3.5574 14.9919 3.22189C13.509 2.88639 11.9654 2.93198 10.5049 3.35441C9.04446 3.77684 7.71481 4.56235 6.64005 5.63763L5.64004 6.63763L5.13004 7.12763M21.4879 9C21.8195 9.93834 22 10.9481 22 12C22 16.9706 17.9705 21 12.9999 21C9.06831 21 5.72552 18.4791 4.49979 14.9655' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M13 12L15.5 9.5M13 12L15.5 14.5M13 12L10.5 9.5M13 12L10.5 14.5' />
    </svg>
  );
};
