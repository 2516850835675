import styled                          from 'styled-components';

import { BlockUIVariants             } from 'src/components/common/block-ui';
import { DialogVariants              } from 'src/components/common/dialog';
import { EmptyCaseVariants           } from 'src/components/common/empty-case';
import { TagsVariants                } from 'src/components/common/tags';

import { NavBarVariants              } from 'src/components/features/common/nav-bar';

import { DateLabelVariants           } from 'src/components/features/main/notifications/date-label';
import { DeleteConfirmDialogVariants } from 'src/components/features/main/notifications/delete-confirm-dialog';
import { NotificationVariants        } from 'src/components/features/main/notifications/notification';

import { InspectionViewVariants      } from 'src/containers/inspection/view';

import { themeColors, 
         cssFlexColumn,   
         cssFlexRow                  } from 'src/providers/theme/shared';



export type NotificationsLayout = {
  backgroundColor: string;

  paddings: number[];
  tagsPaddings: number[];
  notificationsGap: number;

  navbarVariant: NavBarVariants;
  tagsVariant: TagsVariants;

  dateLabelVariant: DateLabelVariants;
  notificationVariant: NotificationVariants;
  emptyCaseVariant: EmptyCaseVariants;
  blockuiVariant: BlockUIVariants;
  deleteConfirmDialogVariant: DeleteConfirmDialogVariants;
  dialogVariant: DialogVariants;
  inspectionViewVariant: InspectionViewVariants;
}

export const layout_light: NotificationsLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [0, 16],
  tagsPaddings: [12, 0],
  notificationsGap: 8,

  navbarVariant: 'lightblue',
  tagsVariant: 'h36r75',

  dateLabelVariant: 'light',
  notificationVariant: 'light',
  emptyCaseVariant: 'light',
  blockuiVariant: 'light',
  deleteConfirmDialogVariant: 'light',
  dialogVariant: 'light',
  inspectionViewVariant: 'light',
}

export const layout_dark: NotificationsLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: NotificationsLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const TagsContainer = styled.div<{
  layout: NotificationsLayout,
}>`
  ${cssFlexRow};
  padding: ${(args) => args.layout.tagsPaddings[0]}px ${(args) => args.layout.tagsPaddings[1]}px;
`

export const ScreenBody = styled.div<{
  layout: NotificationsLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding: ${(args) => args.layout.paddings[0]}px ${(args) => args.layout.paddings[1]}px;
  
  overflow: scroll;
`

export const NotificationsContainer = styled.div<{
  layout: NotificationsLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.notificationsGap}px;
  padding-bottom: ${(args) => args.layout.paddings[1]}px;

`