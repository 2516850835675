import * as React                   from 'react';

import { useTheme                 } from 'styled-components';

import { useParams,
         useNavigate              } from 'react-router-dom';

import { useAlert                 } from 'react-alert';

import { BlockUI                  } from 'src/components/common/block-ui';
import { Labeled                  } from 'src/components/common/labeled';
import { InputPhone               } from 'src/components/common/input/input-phone';
import { InputPassword            } from 'src/components/common/input/input-password';
import { Button                   } from 'src/components/common/button';
import { Dialog                   } from 'src/components/common/dialog';
import { OneActionDialog          } from 'src/components/common/one-action-dialog';
import { VGap                     } from 'src/components/common/flex/vgap';
import { Grow                     } from 'src/components/common/flex/grow';
import { Requirements             } from 'src/components/common/requirements';

import { NavBar                   } from 'src/components/features/auth/common/nav-bar';
import { PwdResetLayout,
         Screen,
         ScreenBody,
         layout_light,
         layout_dark              } from 'src/containers/auth/pwd-reset/layout';

import { Urls                     } from 'src/providers/routing';

import Util                         from 'src/services/util';
import Api                          from 'src/services/api';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                 } from 'src/store';
import { KeyChangePasswordReject, 
         ChangePasswordReject     } from 'src/store/auth/pwd-reset';

import { AsyncOpStatus            } from 'src/common';



type PwdResetScreenElement = { layout: PwdResetLayout; dispatch: AppDispatch; }

const Navigation: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const navigate = useNavigate()

  return (
    <NavBar
      variant = { layout.navbarVariant }
      label = 'Сброс пароля'
      onBackTap = { () => navigate(-1) }
    />
  )
}

const Phone: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const phone = useAppSelector(storeApi.auth.pwdReset.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.auth.pwdReset.selectors.selectPhoneError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapForm } />
      <Labeled variant = { layout.labeledVariant } label = 'Ваш номер телефона' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          onChange = { (value) => dispatch(storeApi.auth.pwdReset.actions.phoneChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.pwdReset.actions.phoneErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Password: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const password = useAppSelector(storeApi.auth.pwdReset.selectors.selectPassword);
  const passwordError = useAppSelector(storeApi.auth.pwdReset.selectors.selectPasswordError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Новый пароль' isRequired>
        <InputPassword
          variant = { layout.inputPasswordVariant }
          value = { password }
          isError = { passwordError !== '' }
          hint = { passwordError }
          onChange = { (value) => dispatch(storeApi.auth.pwdReset.actions.passwordChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.pwdReset.actions.passwordErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const PasswordRepeat: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const passwordRepeat = useAppSelector(storeApi.auth.pwdReset.selectors.selectPasswordRepeat);
  const passwordRepeatError = useAppSelector(storeApi.auth.pwdReset.selectors.selectPasswordRepeatError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Повторите пароль' isRequired>
        <InputPassword
          variant = { layout.inputPasswordVariant }
          value = { passwordRepeat }
          isError = { passwordRepeatError !== '' }
          hint = { passwordRepeatError }
          onChange = { (value) => dispatch(storeApi.auth.pwdReset.actions.passwordRepeatChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.pwdReset.actions.passwordRepeatErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const RequirementsList: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const password = useAppSelector(storeApi.auth.pwdReset.selectors.selectPassword);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Requirements
        variant = { layout.requirementsVariant }
        items = {[
          {
            completed: password.trim().length > 7,
            label: 'Минимальная длина пароля 8 символов (латиница и цифры)'
          },
          { 
            completed: Util.stringContainsDigit(password.trim()),
            label: 'Пароль должен содержать хотя бы одну цифру'
          },
          {
            completed: Util.stringContainsUpperCaseChar(password.trim()),
            label: 'Пароль должен содержать хотя бы одну букву в верхнем регистре'
          }
        ]}
      />
      <Grow size = { 2 } />
    </React.Fragment>
  )
}

const Actions: React.FC<PwdResetScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const { stage } = useParams();
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const formFilled = useAppSelector(storeApi.auth.pwdReset.selectors.selectFormFilled);

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (stage === 'continue' && !bootOpStarted.current)
    {
      bootOpStarted.current = true;
      setOpStatus(AsyncOpStatus.BUSY);
      dispatch(storeApi.auth.pwdReset.async.changePasswordAsync())
        .unwrap()
        .then((result) => {
          setOpStatus(AsyncOpStatus.IDLE);
          setCompleted(true);
        })
        .catch((rawError) => {
          const error = rawError as ChangePasswordReject;
          if (error.reason === 'api')
          {
            if (!Api.isCommonAuthError(error.apiError?.statusCode ?? 0))
            {
              alert.error(error.apiError?.message);
            }
          }
          setOpStatus(AsyncOpStatus.IDLE);
        })
    }
  }, [stage, dispatch, setOpStatus, alert]);

  const nextTapHandler = async () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.auth.pwdReset.async.keyChangePasswordAsync())
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        dispatch(storeApi.auth.common.actions.authKeyChanged(result.authKey));
        Urls.SmsCode.build('reset').navigateWithReplace({ backTo: 'pwdReset' });
      })
      .catch((rawError) => {
        const error = rawError as KeyChangePasswordReject;
        if (error.reason === 'api')
        {
          if (!Api.isCommonAuthError(error.apiError?.statusCode ?? 0))
          {
            alert.error(error.apiError?.message);
          }
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  return (
    <React.Fragment>
      <Button
        variant = { layout.submitVariant }
        label = 'Далее'
        isDisabled = { !formFilled }
        onTap = { nextTapHandler }
      />
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { stage === 'start' ? 'Проверка пароля и получение токена' : 'Завершение смены пароля' }
      />
      <Dialog variant = { layout.dialogVariant } isOpened = { completed }>
        <OneActionDialog
          variant = { layout.completeDialogVariant }
          caption = 'Новый пароль установлен'
          actionLabel = 'Продолжить работу'
          onAction = { () => Urls.Login.build('start').navigateWithReplace() }
        />
      </Dialog>
    </React.Fragment>
  )
}


export const PwdResetScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      Urls.ResetPassword.pushState({});
    }

    bootOpStarted.current = true;

    const handler = (ev: PopStateEvent) => {
      switch (ev.state.usr?.backTo)
      {
        case 'login':
          Urls.Login.build().navigateWithReplace();
          break;
        default:

      }
    }
    window.addEventListener('popstate', handler);

    return () => window.removeEventListener('popstate', handler);
  });

  console.log(window.history);

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Phone layout = { layout } dispatch = { dispatch } />
        <Password layout = { layout } dispatch = { dispatch } />
        <PasswordRepeat layout = { layout } dispatch = { dispatch } />
        <RequirementsList layout = { layout } dispatch = { dispatch } />
        <Actions layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
