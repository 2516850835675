import * as React                  from 'react';

import TextareaAutosize            from 'react-autosize-textarea';

import { CloseVector             } from 'src/components/vector/close';


import { IconButton,
         IconButtonVariants      } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants       } from 'src/components/common/input/input-base';
import { TextareaContainer,
         InputMultilineVariants,
         inputmultiline_variants } from 'src/components/common/input/input-multiline/ui';

import { voidFunction            } from 'src/common';



const inputBaseVariantMap: Map<InputMultilineVariants, InputBaseVariants> = 
  new Map<InputMultilineVariants, InputBaseVariants>([
    ['light', 'autolight']
  ]);

const iconButtonVariantMap: Map<InputMultilineVariants, IconButtonVariants> = 
  new Map<InputMultilineVariants, IconButtonVariants>([
    ['light', 's46bluered']
  ]);

type Props = {
  variant: InputMultilineVariants;
  isError?: boolean;
  isDisabled?: boolean;
  isClearAllowed?: boolean;
  hint?: string;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  value: string;
  valueTransformer?: (value: string) => string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
}
export const InputMultiline: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  isClearAllowed = true,
  hint,
  placeholder,
  maxLength,
  rows = 3,
  value,
  valueTransformer = (value: string) => value,
  onChange,
  onFocus = voidFunction,
}) => {
  const lookAndFeel = inputmultiline_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  const onChangeHandler = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(valueTransformer(evt.target.value));
  }

  const onClearHandler = () => {
    onChange('');
  }

  return (
    <InputBase variant = { inputBaseVariant } isError = { isError } isDisabled = { isDisabled } hint = { hint }>
      <TextareaContainer lookAndFeel = { lookAndFeel } isDisabled = { isDisabled }>
        <TextareaAutosize 
          rows = { rows }
          maxLength = { maxLength }
          disabled = { isDisabled }
          value = { value }
          placeholder = { placeholder }
          onChange = { onChangeHandler }
          onFocus = { onFocus }
          onPointerEnterCapture = { undefined }
          onPointerLeaveCapture = { undefined }
        />
      </TextareaContainer>
      { value.trim().length > 0 && !isDisabled && isClearAllowed && (
        <IconButton variant = { iconButtonVariant } isDot = { false } isTapAllowed onTap = { onClearHandler }>
          <CloseVector />
        </IconButton>
      )}
    </InputBase>
  );
};
