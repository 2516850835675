import { createSlice,
         createAsyncThunk,
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';



const SLICE_NAME = 'auth:pin-enter';

export interface AuthPinEnterState {
  code: string;
  attempt: number;
}

const initialState: AuthPinEnterState = {
  code: '',
  attempt: 0,
}

// #region checkAsync
type CheckArgs = void;
type CheckResolve = types.auth.Account;
export type CheckReject = ApiError;
export const checkAsync = createAsyncThunk<
  CheckResolve,
  CheckArgs,
  {
    state: RootState,
    rejectValue: CheckReject,
  }
>(
  `${SLICE_NAME}/checkAsync`,
  async (args, thunkAPI) => {
    try
    {
      return  await Api.getAuthCheck();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type CodeChangedAction = PayloadAction<string>;

export const authPinEnterSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    codeChanged: (state, action: CodeChangedAction) => {
      state.code = action.payload;
      state.attempt += 1;
    },
    reset: (state) => {
      state.code = '';
      state.attempt = 0;
    }
  },
})

const selectCode = (state: RootState) => state.authPinEnter.code;
const selectAttempt = (state: RootState) => state.authPinEnter.attempt;

export const authPinEnterSelectors = {
  selectCode,
  selectAttempt,
}

export const authPinEnterActions = authPinEnterSlice.actions;

export default authPinEnterSlice.reducer;