import * as React         from 'react';

import { Ripple         } from 'src/components/common/ripple';
import { Container,
         RippleWrapper,
         FABVariants,
         fab_variants   } from 'src/components/common/fab/ui';



type Props = {
  variant: FABVariants;
  isTapAllowed?: boolean;
  children: React.ReactNode;
  onTap: () => void;
}
export const FAB: React.FC<Props> = ({
  variant,
  isTapAllowed = false,
  children,
  onTap
}) => {
  const lookAndFeel = fab_variants.get(variant)!;

  return (
    <RippleWrapper lookAndFeel = { lookAndFeel }>
      <Ripple isTapAllowed = { isTapAllowed } onTap = { onTap }>
        <Container lookAndFeel = { lookAndFeel }>
          { children }
        </Container>
      </Ripple>
    </RippleWrapper>
  );
};
