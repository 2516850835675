import styled                 from 'styled-components';

import { DialogVariants     } from 'src/components/common/dialog';
import { ButtonVariants     } from 'src/components/common/button';
import { LinkVariants       } from 'src/components/common/link';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors        } from 'src/providers/theme/shared';



type EndingDialogDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  gap: number;

  dialogVariant: DialogVariants;

  endButtonVariant: ButtonVariants;
  draftButtonVariant: ButtonVariants;
  closeButtonVariant: ButtonVariants;
  
  cancelLinkVariant: LinkVariants;
}

const endingdialog_light: EndingDialogDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  gap: 24,

  dialogVariant: 'light',

  endButtonVariant: 'h48bluefill',
  draftButtonVariant: 'h48blueoutline',
  closeButtonVariant: 'h48blueoutline',
  
  cancelLinkVariant: 'blue13',
}

export type EndingDialogVariants = 'light';

export const endingdialog_variants: Map<EndingDialogVariants, EndingDialogDialogLookAndFeel> = 
  new Map<EndingDialogVariants, EndingDialogDialogLookAndFeel>([
    ['light', endingdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: EndingDialogDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  position: relative;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: EndingDialogDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`
