import React                      from 'react';

import { CloseVector            } from 'src/components/vector/close';

import { Container,
         PDFArea,
         Label,
         Close,
         AttachmentPDFVariants,
         attachmentpdf_variants } from 'src/components/features/inspection/edit/attachment-pdf/ui';

import * as types                 from 'src/services/api/types';

import { useAppDispatch,
         storeApi               } from 'src/store';



type Props = {
  variant: AttachmentPDFVariants;
  pdf: types.inspection.PieceFile | types.inspection.DefectFile;
  source: 'piece' | 'inspection';
  isAllowDelete: boolean;
  onDelete?: (guid: string) => void;
}
export const AttachmentPDF: React.FC<Props> = ({ variant, pdf, source, isAllowDelete, onDelete }) => {
  const lookAndFeel = attachmentpdf_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const onDeleteHandler = () => {
    if (onDelete !== undefined)
    {
      onDelete(pdf.guid);
    }
  }

  const onDownloadPDF = () => {
    if (pdf.__dataUrl !== undefined)
    {
      var a = document.createElement("a");
      a.href = pdf.__dataUrl;
      a.download = pdf.name;
      a.click();
    }
    else
    {
      dispatch(storeApi.inspection.edit.async.viewPDFAsync({
        fileName: pdf.name,
        guid: pdf.guid,
        source
      }))
        .unwrap()
        .then((result) => {
          
        })
        .catch((error) => { });
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <PDFArea lookAndFeel = { lookAndFeel } onClick = { onDownloadPDF } />
      {isAllowDelete && 
        <Close lookAndFeel = { lookAndFeel } onClick = { onDeleteHandler }>
          <CloseVector />
        </Close>
      }
      <Label lookAndFeel = { lookAndFeel }>
        <div>
          { pdf.name }
        </div>
      </Label>
    </Container>
  );
}
