import * as React                   from 'react';

import { ArrowRightVector         } from 'src/components/vector/arrow-right';
import { CalendarVector           } from 'src/components/vector/calendar';

import { Button                   } from 'src/components/common/button';
import { Dialog                   } from 'src/components/common/dialog';

import { Status,
         StatusVariants           } from 'src/components/features/main/inspection-list/status';

import { Container, 
         Caption,
         Message,
         Number,
         Actions,
         Items,
         ItemContainer,
         ItemDateRow,
         ItemDateContainer,
         ItemArgCaption,
         ItemArgContent,
         ItemArg,
         ItemArgs,
         DuplicateDialogVariants,
         duplicatedialog_variants } from 'src/components/features/inspection/edit/duplicate-dialog/ui';

import * as types                   from 'src/services/api/types';
import Util                         from 'src/services/util';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch           } from 'src/store';

import { Nullable                 } from 'src/common';



type DefectItem = {
  inspectionGuid: string;
  timestamp: number;
  num: string;
  defect: Nullable<string>;
  status: types.inspection.InspectionStatus;
}

type Props = {
  variant: DuplicateDialogVariants;
}
export const DuplicateDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = duplicatedialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const opened = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedExternalDoublesOpened);
  const list = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedExternalDoublesList);
  const pieceText = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedText);

  const viewList = list.map((inspection) => inspection.inspectionDefects.map((defect) => ({
    inspectionGuid: inspection.guid,
    timestamp: inspection.timestampCreated,
    num: inspection.inspectionNum,
    defect: defect.defectTitle,
    status: inspection.status
  }))).flat() as DefectItem[];

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Обнаружен дубль
        </Caption>
        <Message lookAndFeel = { lookAndFeel }>
          Данная позиция существует в другом осмотре
        </Message>
        <Number lookAndFeel = { lookAndFeel }>
          { pieceText }
        </Number>
        <Items lookAndFeel = { lookAndFeel }>
        {viewList.map((item) => (
          <ItemContainer
            lookAndFeel = { lookAndFeel } 
            onClick = {
              () => {
                dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesHidden());
                dispatch(storeApi.inspection.view.async.openForViewAsync({ viewGuid: item.inspectionGuid }));
              }
            }
          >
            <ItemDateRow lookAndFeel = { lookAndFeel }>
              <ItemDateContainer lookAndFeel = { lookAndFeel }>
                <CalendarVector />
                <span>{ `${new Date(item.timestamp).toLocaleDateString()}, ${Util.timeHHMM(new Date(item.timestamp))}` }</span>
              </ItemDateContainer>
              <ArrowRightVector />
            </ItemDateRow>
            <ItemArgs lookAndFeel = { lookAndFeel }>
              <ItemArg lookAndFeel = { lookAndFeel }>
                <ItemArgCaption lookAndFeel = { lookAndFeel }>
                  №
                </ItemArgCaption>
                <ItemArgContent lookAndFeel = { lookAndFeel }>
                  { item.num }
                </ItemArgContent>
              </ItemArg>
              <ItemArg lookAndFeel = { lookAndFeel }>
                <ItemArgCaption lookAndFeel = { lookAndFeel }>
                  Дефект
                </ItemArgCaption>
                <ItemArgContent lookAndFeel = { lookAndFeel }>
                  { item.defect ?? '-' }
                </ItemArgContent>
              </ItemArg>
            </ItemArgs>
            <Status variant = { getStatusVariant(item.status.code) } label = { item.status.title } />
          </ItemContainer>
        ))}
        </Items>
        <Actions lookAndFeel = { lookAndFeel }>
          <Button
            variant = { lookAndFeel.buttonCancelVariant }
            label = 'Отмена'
            onTap = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesClosed()) }
          />
          <Button
            variant = { lookAndFeel.buttonOkVariant }
            label = 'Продолжить'
            onTap = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesCompleted()) }
          />
        </Actions>
      </Container>
    </Dialog>
  );
};


const getStatusVariant = (status: types.inspection.InspectionStatusCode): StatusVariants => {
  switch (status)
  {
    case types.inspection.InspectionStatusCode.AWAITING_CONFIRMATION:
    case types.inspection.InspectionStatusCode.AWAITING_REVIEW:
    case types.inspection.InspectionStatusCode.AWAITING_VERIFICATION:
      return 'bluefill';
    case types.inspection.InspectionStatusCode.TAKE_TO_WORK:
    case types.inspection.InspectionStatusCode.COMPLETED:
    case types.inspection.InspectionStatusCode.INTERNAL:
      return 'blueoutline';
    case types.inspection.InspectionStatusCode.RETURN_FOR_REVISION:
      return 'redoutline';
    case types.inspection.InspectionStatusCode.ERROR:
      return 'redfill';
    case types.inspection.InspectionStatusCode.DRAFT:
      return 'gray';
    default:
      return 'gray';
  }
}