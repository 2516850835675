import styled           from 'styled-components';

import { themeColors, 
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type InputDigitCodeLookAndFeel = {
  typo: Typo;
  normalColor: string;
  disabledColor: string;
}

const inputdigitcode_h48light: InputDigitCodeLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S20_LH20,
  normalColor: themeColors.PrimaryBlack,
  disabledColor: themeColors.SecondaryGray4,
}

export type InputDigitCodeVariants = 'h48light';

export const inputdigitcode_variants: Map<InputDigitCodeVariants, InputDigitCodeLookAndFeel> = 
  new Map<InputDigitCodeVariants, InputDigitCodeLookAndFeel>([
    ['h48light', inputdigitcode_h48light],
  ]);

export const Input = styled.input<{
  isDisabled: boolean;
  lookAndFeel: InputDigitCodeLookAndFeel;
}>`
  width: 1px;
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  letter-spacing: 0.2em;

  color: ${(args) => args.isDisabled ? args.lookAndFeel.disabledColor : args.lookAndFeel.normalColor};
`;
