import styled                      from 'styled-components';

import { LabeledVariants         } from 'src/components/common/labeled';
import { InputPhoneVariants      } from 'src/components/common/input/input-phone';
import { InputPasswordVariants   } from 'src/components/common/input/input-password';
import { RequirementsVariants    } from 'src/components/common/requirements';
import { ButtonVariants          } from 'src/components/common/button';
import { BlockUIVariants         } from 'src/components/common/block-ui';
import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';

import { NavBarVariants          } from 'src/components/features/auth/common/nav-bar';

import { cssFlexColumn,
         themeColors             } from 'src/providers/theme/shared';



export type PwdResetLayout = {
  paddings: number[];

  backgroundColor: string;

  blockuiVariant: BlockUIVariants;
  navbarVariant: NavBarVariants;
  labeledVariant: LabeledVariants;
  inputPhoneVariant: InputPhoneVariants;
  inputPasswordVariant: InputPasswordVariants;
  requirementsVariant: RequirementsVariants;
  submitVariant: ButtonVariants;
  dialogVariant: DialogVariants;
  completeDialogVariant: OneActionDialogVariants;

  gapForm: number;
  gapFormElements: number;
}

export const layout_light: PwdResetLayout = {
  paddings: [30, 36, 47, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  blockuiVariant: 'light',
  navbarVariant: 'light',
  labeledVariant: 'light',
  inputPhoneVariant: 'h48light',
  inputPasswordVariant: 'h48light',
  requirementsVariant: 'light',
  submitVariant: 'h48bluefill',
  dialogVariant: 'light',
  completeDialogVariant: 'light',

  gapForm: 48,
  gapFormElements: 20,
}

export const layout_dark: PwdResetLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: PwdResetLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: PwdResetLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`
