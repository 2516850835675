import React from 'react';



type Props = {
  className?: string;
}
export const InputStatusWarningVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M20.4806 14.6471C17.9721 10.3237 16.1252 7.60844 13.5 3H10.5L1.5 18.5L3 21H21L22.5 18.5L21.5306 16.7647'/>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 9L12 14'/>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 16L12 18'/>
    </svg>
  );
};
