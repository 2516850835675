import * as React              from 'react';

import cc                      from 'classcat';

import { Container,
         RadioItem,
         Control,
         Label,
         RadioGroupVariants,
         radiogroup_variants } from 'src/components/common/radio-group/ui';



export type RadioGroupItem = {
  id: string;
  label: string;
}

type Props = {
  variant: RadioGroupVariants;
  layout?: 'vertical' | 'horizontal-left' | 'horizontal-center';
  items: RadioGroupItem[];
  value: RadioGroupItem;
  onChange: (value: RadioGroupItem) => void;
}
export const RadioGroup: React.FC<Props> = ({
  variant,
  layout = 'vertical',
  items,
  value,
  onChange,
}) => {
  const lookAndFeel = radiogroup_variants.get(variant)!;

  const onRadioClickHandler = (item: RadioGroupItem) => {
    onChange(item);
  }

  return (
    <Container lookAndFeel = { lookAndFeel } layout = { layout }>
      { items.map((item) => (
        <RadioItem
          key = { item.id }
          lookAndFeel = { lookAndFeel }
          onClick = { onRadioClickHandler.bind(null, item) }
        >
          <Control
            lookAndFeel = { lookAndFeel }
            className = { cc({ selected: item.id === value.id }) }
          />
          <Label lookAndFeel = { lookAndFeel }>{ item.label }</Label>
        </RadioItem>
      ))}
    </Container>
  );
};
