import Preferences from 'src/services/preferences';



class WebCrypto {
  async getKeyMaterial(pin: string): Promise<CryptoKey> {
    const enc = new TextEncoder();
    return window.crypto.subtle.importKey(
      'raw',
      enc.encode(pin),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey'],
    );
  }

  async getKey(keyMaterial: CryptoKey): Promise<CryptoKey> {
    return await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: Preferences.pinSalt,
        iterations: 100000,
        hash: 'SHA-256',
      },
      keyMaterial,
      { 'name': 'AES-GCM', 'length': 256},
      true,
      ['encrypt', 'decrypt'],
    );
  }

  async encryptWithPin(pin: string, content: string): Promise<ArrayBuffer> {
    const keyMaterial = await this.getKeyMaterial(pin);
    const key = await this.getKey(keyMaterial);
    const encoder = new TextEncoder();
    const encoded = encoder.encode(content);

    return await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: Preferences.pinIv
      },
      key,
      encoded
    );
  }

  async decryptWithPin(pin: string, content: ArrayBuffer): Promise<ArrayBuffer> {
    const keyMaterial = await this.getKeyMaterial(pin);
    const key = await this.getKey(keyMaterial);

    try {
      return await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: Preferences.pinIv
        },
        key,
        content
      );
    }
    catch (error)
    {
      console.log(error);
      throw error;
    }
  }
}

export default new WebCrypto();