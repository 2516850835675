import React from 'react';



type Props = {
  className?: string;
}
export const CleanVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path fillRule = 'evenodd' clipRule = 'evenodd' fill = 'currentColor' d = 'M8 1H9H12.5739H12.9329L13.21 1.22838L15.6361 3.22838L16 3.52838V4V9H18H18.6594L18.9191 9.60608L21.9191 16.6061L22 16.7947V17L22 23L22 24H21H3H2V23V22H3H4H7V15H9V22H11V18.9375H13V22H15V16.75H17V22H20L20 17.2053L17.3406 11H16H15H9H8H6.6594L4 17.2053V20H2V17V16.7947L2.08085 16.6061L5.08085 9.60608L5.3406 9H6H8V2V1ZM14 4.47162V9H10V3H12.2148L14 4.47162Z' />
    </svg>
  );
};
