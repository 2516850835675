import * as React                 from 'react';

import { useTheme               } from 'styled-components';

import { useParams,
         useNavigate            } from 'react-router-dom';

import { ArrowBackVector        } from 'src/components/vector/arrowback';
import { BlockUI                } from 'src/components/common/block-ui';
import { EmptyCaseEnvelope      } from 'src/components/common/empty-case';

import { NavBar                 } from 'src/components/features/common/nav-bar';

import { HistoryItem            } from 'src/components/features/qmmsg/question-history/history-item';

import { QuestionHistoryLayout, 
         Screen,
         ScreenBody,
         ItemsList,
         layout_light,
         layout_dark            } from 'src/containers/qmmsg/question-history/layout';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';

import { AsyncOpStatus          } from 'src/common';



type QuestionHistoryScreenElement = { layout: QuestionHistoryLayout; dispatch: AppDispatch; }

const Navigation: React.FC<QuestionHistoryScreenElement> = ({ layout, dispatch }) => {
  const navigate = useNavigate();

  const opStatus = useAppSelector(storeApi.qmmsg.questionHistory.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.qmmsg.questionHistory.selectors.selectOpStatusLabel);

  return (
    <React.Fragment>
      <NavBar 
        variant = { layout.navbarVariant }
        label = 'История запросов'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => navigate(-1)
          }
        ]}
      />
      <BlockUI
        variant = { layout.blockUiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
    </React.Fragment>
  )
}


export const QuestionHistoryScreen = () => {
  const theme = useTheme();
  const { qmMsgId } = useParams();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  const items = useAppSelector(storeApi.qmmsg.questionHistory.selectors.selectItems);
  const opStatus = useAppSelector(storeApi.qmmsg.questionHistory.selectors.selectOpStatus);

  React.useEffect(
    () => {
      if (bootOpStarted.current || qmMsgId === undefined)
      {
        return;
      }

      bootOpStarted.current = true;
      dispatch(storeApi.qmmsg.questionHistory.async.prepareInfoAsync(parseInt(qmMsgId, 10)));
    },
    [dispatch, qmMsgId]
  );

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        {opStatus === AsyncOpStatus.IDLE && items.length === 0 &&
          <EmptyCaseEnvelope variant = { layout.emptyCaseVariant } header = 'Запросов по претензии/уведомлению нет' />
        }
        {opStatus === AsyncOpStatus.IDLE && items.length > 0 &&
          <ItemsList layout = { layout }>
            {items.map((item) => 
              <HistoryItem variant = { layout.questionHistoryItemVariant } key = { item.guid } item = { item } />
            )}
          </ItemsList>
        }
      </ScreenBody>
    </Screen>
  );
};
