import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS          } from 'src/providers/theme/shared';



type NavBarLookAndFeel = {
  paddings: number[];

  labelTypo: Typo;
  labelColor: string;

  buttonVariant: IconButtonVariants;

  backColor: string;
  backSize: number;

  gap: number;
}

const navbar_light: NavBarLookAndFeel = {
  paddings: [0, 0, 0, 6],

  labelTypo: themeTypos.GRTSKREG_W400_S17_LH19,
  labelColor:  themeColors.AccentBlue2,

  buttonVariant: 's48bluered',

  backColor: themeColors.PrimaryBlue100,
  backSize: 48,

  gap: 10,
}

export type NavBarVariants = 'light';

export const navbar_variants: Map<NavBarVariants, NavBarLookAndFeel> = 
  new Map<NavBarVariants, NavBarLookAndFeel>([
    ['light', navbar_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: NavBarLookAndFeel
}>`
  ${cssFlexRow};
  align-items: center;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const Back = styled.span<{
  lookAndFeel: NavBarLookAndFeel,
}>`
  color: ${(args) => args.lookAndFeel.backColor};
`

export const Label = styled.span<{
  lookAndFeel: NavBarLookAndFeel,
}>`
  ${cssFlexRow};
  flex-grow: 2;
  justify-content: center;
  text-align: center;

  padding-right: ${(args) => (args.lookAndFeel.backSize + args.lookAndFeel.gap + args.lookAndFeel.paddings[3])}px;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
