import styled                    from 'styled-components';

import { InputPhoneVariants    } from 'src/components/common/input/input-phone';
import { InputPasswordVariants } from 'src/components/common/input/input-password';
import { ButtonVariants        } from 'src/components/common/button';
import { LinkVariants          } from 'src/components/common/link';
import { LabeledVariants       } from 'src/components/common/labeled';
import { BlockUIVariants       } from 'src/components/common/block-ui';

import { WelcomeVariants       } from 'src/components/features/auth/phone-enter/welcome';
import { AppCaptionVariants    } from 'src/components/features/auth/common/app-caption';  

import { cssFlexColumn,
         themeColors           } from 'src/providers/theme/shared';



export type PhoneEnterLayout = {
  paddings: number[];

  backgroundColor: string;
  
  blockuiVariant: BlockUIVariants;
  labeledVariant: LabeledVariants;
  welcomeVariant: WelcomeVariants;
  appCaptionVariant: AppCaptionVariants;
  inputPhoneVariant: InputPhoneVariants;
  inputPasswordVariant: InputPasswordVariants;
  submitVariant: ButtonVariants;
  linkVariant: LinkVariants;

  gapWelcomeCaption: number;
  gapCaptionForm: number;
  gapFormElement: number;
  gapFormSubmit: number;
  gapSubmitForget: number;
}

export const layout_light: PhoneEnterLayout = {
  paddings: [30, 36, 47, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  blockuiVariant: 'light',
  labeledVariant: 'light',
  welcomeVariant: 'light',
  appCaptionVariant: 'light',
  inputPhoneVariant: 'h48light',
  inputPasswordVariant: 'h48light',
  submitVariant: 'h48bluefill',
  linkVariant: 'blue15',

  gapWelcomeCaption: 12,
  gapCaptionForm: 48,
  gapFormElement: 20,
  gapFormSubmit: 36,
  gapSubmitForget: 30,
}

export const layout_dark: PhoneEnterLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: PhoneEnterLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`
