import { createSlice,
         createAsyncThunk,  
         PayloadAction,
         ActionReducerMapBuilder,
         createSelector           } from '@reduxjs/toolkit';
import { WritableDraft            } from '@reduxjs/toolkit/node_modules/immer/dist/internal';

import Api,
       { ApiError                 } from 'src/services/api';
import * as types                   from 'src/services/api/types';
import Db                           from 'src/services/db';
import Util                         from 'src/services/util';
import { QCState                  } from 'src/services/api/types/inspection';

import { RootState                } from 'src/store';

import { AsyncOpStatus,
         Nullable                 } from 'src/common';



const SLICE_NAME = 'inspection:view';

type ExtraReducer = (builder: ActionReducerMapBuilder<InspectionViewState>) => void;
const extraReducers: ExtraReducer[] = [];

export interface InspectionViewState {
  loadOpStatus: AsyncOpStatus;
  loadOpStatusLabel: string;
  inspection: Nullable<types.inspection.Inspection>;

  viewOpened: boolean;
  viewFragment: 'defectlist' | 'piece' | 'complaintlist' | 'complaint';
  is404: boolean;

  defectExpandedGuid: string;
  pieceSelected: Nullable<types.inspection.Piece>;
  complaintSelected: Nullable<types.inspection.Complaint>;

  certDownloadOpened: boolean;
}

const initialState: InspectionViewState = {
  loadOpStatus: AsyncOpStatus.IDLE,
  loadOpStatusLabel: '',
  inspection: null,

  viewOpened: false,
  viewFragment: 'defectlist',
  is404: false,

  defectExpandedGuid: '',
  pieceSelected: null,
  complaintSelected: null,

  certDownloadOpened: false,
}

// #region ОБЩЕЕ

// #region openForViewAsync
type OpenForViewArgs = {
  viewGuid: string;
};
type OpenForViewResolve = {
  inspection: Nullable<types.inspection.Inspection>;
  defectExpandedGuid: string;
  is404: boolean;
};
export type OpenForViewReject = ApiError;
export const openForViewAsync = createAsyncThunk<
  OpenForViewResolve,
  OpenForViewArgs,
  {
    state: RootState,
    rejectValue: OpenForViewReject,
  }
>(
  `${SLICE_NAME}/openForViewAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.viewOpened());
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Получение осмотра'));

      let remoteInspection: Nullable<types.inspection.Inspection> = null;
      let is404: boolean = false;

      try
      {
        remoteInspection = await Api.getInspectionByGuid({ guid: args.viewGuid });
      }
      catch (apiError)
      {
        if ((apiError as ApiError).statusCode === 404)
        {
          is404 = true;
        }
      }

      let localInspection: Nullable<types.inspection.Inspection> = null;

      try
      {
        localInspection = (await Db.getInspections()).find((item) => item.guid === args.viewGuid) ?? null;
      }
      catch (dbError) { }

      let resultInspection: Nullable<types.inspection.Inspection> = null;

      if (remoteInspection === null && localInspection === null)
      {
        resultInspection = null;
      }
      else if (remoteInspection !== null && localInspection === null)
      {
        resultInspection = JSON.parse(JSON.stringify(remoteInspection));
      }
      else if (localInspection !== null && (remoteInspection === null || remoteInspection.timestampModified <= localInspection.timestampModified))
      {
        resultInspection = JSON.parse(JSON.stringify(localInspection));
      }
      else if (localInspection != null && remoteInspection !== null && remoteInspection.timestampModified > localInspection.timestampModified) {
        resultInspection = JSON.parse(JSON.stringify(localInspection));
      }

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));

      return {
        inspection: resultInspection,
        defectExpandedGuid: resultInspection?.inspectionDefects[0]?.guid ?? '',
        is404: resultInspection === null && is404,
      }
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(openForViewAsync.fulfilled, (state, action) => {
      state.loadOpStatusLabel = '';
      state.inspection = action.payload.inspection;

      state.viewFragment = 'defectlist';
      state.is404 = action.payload.is404;

      state.defectExpandedGuid = action.payload.defectExpandedGuid;
      state.pieceSelected = null;
    })
    .addCase(openForViewAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region downloadPhotoAsync
type DownloadPhotoArgs = {
  fileGuid: string;
};
type DownloadPhotoResolve = {
  fileGuid: string;
  dataUrl: string;
  binaryStr: string;
  name: string;
  mime: string;
};
export type DownloadPhotoReject = ApiError;
export const downloadPhotoAsync = createAsyncThunk<
  DownloadPhotoResolve,
  DownloadPhotoArgs,
  {
    state: RootState,
    rejectValue: DownloadPhotoReject,
  }
>(
  `${SLICE_NAME}/downloadPhotoAsync`,
  async (args, thunkAPI) => {
    const pE = thunkAPI.getState().inspectionView.pieceSelected;

    if (pE === null)
    {
      return {
        fileGuid: args.fileGuid,
        dataUrl: '',
        binaryStr: '',
        name: '',
        mime: '',
      }
    }
    else
    {
      let updatedFile: Nullable<types.inspection.PieceFile> = null;

      for (const file of pE.files)
      {
        if (file.guid === args.fileGuid)
        {
          updatedFile = file;
        }
      }

      if (updatedFile === null)
      {
        return {
          fileGuid: args.fileGuid,
          dataUrl: '',
          binaryStr: '',
          name: '',
          mime: '',
        }
      }
      else
      {
        try
        {
          const remoteFile = await Api.getInspectionFileByGuid({ guid: args.fileGuid, source: 'piece' });
          const fileFromBlob = await Util.blobToFile(remoteFile.blob, updatedFile.name, 'image/jpeg');
          const dataUrlResult = await Util.imageToDataURL(fileFromBlob);
          const binaryStr = await Util.fileToBinaryString(fileFromBlob);

          if (dataUrlResult.ok)
          {
            return {
              fileGuid: args.fileGuid,
              dataUrl: dataUrlResult.result,
              binaryStr: binaryStr,
              name: updatedFile.name,
              mime: 'image/jpeg',
            }
          }
          else
          {
            return {
              fileGuid: args.fileGuid,
              dataUrl: Util.noPhoto,
              binaryStr: '',
              name: updatedFile.name,
              mime: 'image/jpeg',
            }
          }
        }
        catch(error)
        {
          return {
            fileGuid: args.fileGuid,
            dataUrl: Util.noPhoto,
            binaryStr: '',
            name: updatedFile.name,
            mime: 'image/jpeg',
          }
        }
      }
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(downloadPhotoAsync.fulfilled, (state, action) => {
      if (state.pieceSelected !== null)
      {
        for (const file of state.pieceSelected.files)
        {
          if (file.guid === action.payload.fileGuid)
          {
            file.__dataUrl = action.payload.dataUrl;
            file.__binaryStr = action.payload.binaryStr;
            file.__name = action.payload.name;
            file.__mime = action.payload.mime;
          }
        }
      }

      if (state.inspection !== null)
      {
        for (const defect of state.inspection.inspectionDefects)
        {
          for (const piece of (defect.pieces || []))
          {
            for (const file of piece.files)
            {
              if (file.guid === action.payload.fileGuid)
              {
                file.__dataUrl = action.payload.dataUrl;
                file.__binaryStr = action.payload.binaryStr;
                file.__name = action.payload.name;
                file.__mime = action.payload.mime;
              }
            }
          }
        }
      }
    })
    .addCase(downloadPhotoAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region downloadCertAsync
type DownloadCertArgs = {
  qcNum: string;
};
type DownloadCertResolve = void;
export type DownloadCertReject = ApiError;
export const downloadCertAsync = createAsyncThunk<
  DownloadCertResolve,
  DownloadCertArgs,
  {
    state: RootState,
    rejectValue: DownloadCertReject,
  }
>(
  `${SLICE_NAME}/downloadCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Постановка в очередь задачи на скачивание сертификата'));
      
      await Api.postQCCheck({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(downloadCertAsync.fulfilled, (state, action) => {
      state.loadOpStatusLabel = '';
      state.certDownloadOpened = true;
      state.pieceSelected!.qmetData.qcState = QCState.LOADING;
    })
    .addCase(downloadCertAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region checkCertAsync
type CheckCertArgs = {
  inspectionGuid: string;
};
type CheckCertResolve = QCState;
export type CheckCertReject = ApiError;
export const checkCertAsync = createAsyncThunk<
  CheckCertResolve,
  CheckCertArgs,
  {
    state: RootState,
    rejectValue: CheckCertReject,
  }
>(
  `${SLICE_NAME}/checkCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Проверка статуса загрузки сертификата'));
      
      const inspection = await Api.getInspectionByGuid({ guid: args.inspectionGuid });
      const selectedPiece = selectors.selectPieceSelected(thunkAPI.getState());

      const updatedDefect = inspection.inspectionDefects.find((defect) => (defect.pieces || []).find((piece) => piece.guid === selectedPiece!.guid) !== undefined)!;
      const updatedPiece = (updatedDefect.pieces || []).find((piece) => piece.guid === selectedPiece!.guid)!;

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));

      return updatedPiece.qmetData.qcState;
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(checkCertAsync.fulfilled, (state, action) => {
      state.loadOpStatusLabel = '';
      state.pieceSelected!.qmetData.qcState = action.payload;
    })
    .addCase(checkCertAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region viewCertAsync
type ViewCertArgs = {
  qcNum: string;
};
type ViewCertResolve = void;
export type ViewCertReject = ApiError;
export const viewCertAsync = createAsyncThunk<
  ViewCertResolve,
  ViewCertArgs,
  {
    state: RootState,
    rejectValue: ViewCertReject,
  }
>(
  `${SLICE_NAME}/viewCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Получение файла сертификата'));
      
      const result = await Api.getQCFile({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));

      //window.open(result.dataUrl, '_blank')?.focus();
      var a = document.createElement("a");
      a.href = result.dataUrl;
      a.download = `cert_${args.qcNum}.pdf`;
      a.click();
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region shareCertAsync
type ShareCertArgs = {
  qcNum: string;
};
type ShareCertResolve = void;
export type ShareCertReject = ApiError;
export const shareCertAsync = createAsyncThunk<
  ShareCertResolve,
  ShareCertArgs,
  {
    state: RootState,
    rejectValue: ShareCertReject,
  }
>(
  `${SLICE_NAME}/shareCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Получение файла сертификата'));
      
      const result = await Api.getRawQCFile({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));

      const file = new File([result.rawData], `Сертификат №${args.qcNum}.pdf`, { type: 'application/pdf' });
      navigator.share({
        title: `Сертификат №${args.qcNum}.pdf`,
        text: `Файл сертификата из приложения Checksteel`,
        files: [file]
      });
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region viewPDFAsync
type ViewPDFArgs = {
  fileName: string;
  guid: string;
};
type ViewPDFResolve = void;
export type ViewPDFReject = ApiError;
export const viewPDFAsync = createAsyncThunk<
  ViewPDFResolve,
  ViewPDFArgs,
  {
    state: RootState,
    rejectValue: ViewPDFReject,
  }
>(
  `${SLICE_NAME}/viewPDFAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionViewActions.laodOpStatusLabelChanged('Получение PDF-файла'));
      
      const result = await Api.getInspectionFileAsPDFByGuid({ guid: args.guid, source: 'piece' });

      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.SUCCESS));

      var a = document.createElement("a");
      a.href = result.dataUrl;
      a.download = args.fileName;
      a.click();
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionViewActions.loadOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion


const reducers = {
  loadOpStatusChanged: (state: WritableDraft<InspectionViewState>, action: OpStatusChangedAction) => {
    state.loadOpStatus = action.payload;
  },

  laodOpStatusLabelChanged: (state: WritableDraft<InspectionViewState>, action: OpStatusLabelChangedAction) => {
    state.loadOpStatusLabel = action.payload;
  },

  viewOpened: (state: WritableDraft<InspectionViewState>) => {
    state.viewOpened = true;
  },

  viewClosed: (state: WritableDraft<InspectionViewState>) => {
    state.viewOpened = false;
  },

  changeViewFragment: (state: WritableDraft<InspectionViewState>, action: FragmentChangedAction) => {
    state.viewFragment = action.payload;
  },

  changeDefectExpandedGuid: (state: WritableDraft<InspectionViewState>, action: StringChangedAction) => {
    state.defectExpandedGuid = action.payload;
  },

  changePieceSelected: (state: WritableDraft<InspectionViewState>, action: PieceChangedAction) => {
    state.pieceSelected = action.payload;
    state.viewFragment = 'piece';
  },

  changeComplaintSelected: (state: WritableDraft<InspectionViewState>, action: ComplaintChangedAction) => {
    state.complaintSelected = action.payload;
    state.viewFragment = 'complaint';
  },

  defectExpandedGuidChanged: (state: WritableDraft<InspectionViewState>, action: StringChangedAction) => {
    state.defectExpandedGuid = action.payload;
  },

  certDownloadOpened: (state: WritableDraft<InspectionViewState>) => {
    state.certDownloadOpened = true;
  },

  certDownloadClosed: (state: WritableDraft<InspectionViewState>) => {
    state.certDownloadOpened = false;
  },
}

const selectors = {
  selectViewOpened: (state: RootState) => state.inspectionView.viewOpened,
  selectIs404: (state: RootState) => state.inspectionView.is404,
  selectViewFragment: (state: RootState) => state.inspectionView.viewFragment,
  selectInspectionNumber: (state: RootState) => state.inspectionView.inspection?.inspectionNum ?? '',
  selectInspectionGuid: (state: RootState) => state.inspectionView.inspection?.guid ?? '',
  selectLoadOpStatus: (state: RootState) => state.inspectionView.loadOpStatus,
  selectLoadOpStatusLabel: (state: RootState) => state.inspectionView.loadOpStatusLabel,
  selectDefectExpandedGuid: (state: RootState) => state.inspectionView.defectExpandedGuid,
  selectPieceSelected: (state: RootState) => state.inspectionView.pieceSelected,
  selectIsEntryAccounting: (state: RootState) => state.inspectionView.inspection?.isEntryAccounting ?? false,
  selectDefectAllCount: (state: RootState) => state.inspectionView.inspection?.inspectionDefects?.length ?? 0,
  selectDefects: (state: RootState) => state.inspectionView.inspection?.inspectionDefects ?? [],

  selectDefectFiles: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionView.inspection?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null)
      {
        return null;
      }

      return defect.files;
    }
  ),

  selectComplaintSelectedTypeComplaint: (state: RootState) => state.inspectionView.complaintSelected?.typeComplaint ?? null,
  selectComplaintSelectedIsToScrap: (state: RootState) => state.inspectionView.complaintSelected?.isToScrap ?? null,
  selectComplaintSelectedScrapPrice: (state: RootState) => state.inspectionView.complaintSelected?.scrapPrice ?? null,
  selectComplaintSelectedIsEvaluateRolledMetal: (state: RootState) => state.inspectionView.complaintSelected?.isEvaluateRolledMetal ?? null,
  selectComplaintSelectedRequirementRolledMetall: (state: RootState) => state.inspectionView.complaintSelected?.requirementRolledMetall ?? null,
  selectComplaintSelectedIsToOffsetCosts: (state: RootState) => state.inspectionView.complaintSelected?.isToOffsetCosts ?? null,
  selectComplaintSelectedAmountOfCompensation: (state: RootState) => state.inspectionView.complaintSelected?.amountOfCompensation ?? null,
  selectComplaintSelectedIsReturnOfRolledMetal: (state: RootState) => state.inspectionView.complaintSelected?.isReturnOfRolledMetal ?? null,
  selectComplaintSelectedIsSale: (state: RootState) => state.inspectionView.complaintSelected?.isSale ?? null,
  selectComplaintSelectedSale: (state: RootState) => state.inspectionView.complaintSelected?.sale ?? null,
  selectComplaintSelectedIsRepresentative: (state: RootState) => state.inspectionView.complaintSelected?.isRepresentative ?? null,
  selectComplaintSelectedIsProvideResponse: (state: RootState) => state.inspectionView.complaintSelected?.isProvideResponse ?? null,
  selectComplaintSelectedIsIdentifyDangerous: (state: RootState) => state.inspectionView.complaintSelected?.isIdentifyDangerous ?? null,
  selectComplaintSelectedIsAnalizProblem: (state: RootState) => state.inspectionView.complaintSelected?.isAnalizProblem ?? null,
  selectComplaintSelectedIs8d: (state: RootState) => state.inspectionView.complaintSelected?.is8d ?? null,
  selectComplaintSelectedOtherReq: (state: RootState) => state.inspectionView.complaintSelected?.otherReq ?? null,
  selectComplaintSelectedFIO: (state: RootState) => state.inspectionView.complaintSelected?.fio ?? null,
  selectComplaintSelectedEnterprise: (state: RootState) => state.inspectionView.complaintSelected?.enterprise ?? null,
  selectComplaintSelectedPhone: (state: RootState) => state.inspectionView.complaintSelected?.phone ?? null,
  selectComplaintSelectedEmail: (state: RootState) => state.inspectionView.complaintSelected?.email ?? null,
  selectComplaintSelectedGroupRevised: (state: RootState) => state.inspectionView.complaintSelected?.groupRevised ?? null,
  selectComplaintSelectedGroupRevisedValue: (state: RootState) => state.inspectionView.complaintSelected?.groupRevisedValue ?? null,
  selectComplaintSelectedGroupUnrevised: (state: RootState) => state.inspectionView.complaintSelected?.groupUnrevised ?? null,
  selectComplaintSelectedGroupUnrevisedValue: (state: RootState) => state.inspectionView.complaintSelected?.groupUnrevisedValue ?? null,
  selectComplaintEditedDecision: (state: RootState) => state.inspectionView.complaintSelected?.decision ?? null,

  selectCertDownloadOpened: (state: RootState) => state.inspectionView.certDownloadOpened,
}

// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;
type FragmentChangedAction = PayloadAction<'defectlist' | 'piece' | 'complaintlist' | 'complaint'>;
type StringChangedAction = PayloadAction<string>;
type PieceChangedAction = PayloadAction<types.inspection.Piece>;
type ComplaintChangedAction = PayloadAction<types.inspection.Complaint>;

export const inspectionViewSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    ...reducers,
  },
  extraReducers: (builder) => extraReducers.forEach((creator) => creator(builder)),
})

export const inspectionViewSelectors = {
  ...selectors,
}

export const inspectionViewActions = inspectionViewSlice.actions;

export default inspectionViewSlice.reducer;
