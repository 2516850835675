import authCommon                       from 'src/store/auth/common';
import { authCommonActions,
         authCommonSelectors          } from 'src/store/auth/common';

import authPhoneEnter                   from 'src/store/auth/phone-enter';
import { authPhoneEnterActions, 
         authPhoneEnterSelectors,
         keyLoginAsync,
         loginAsync                   } from 'src/store/auth/phone-enter';

import authSMSCode                      from 'src/store/auth/sms-code';
import { authSMSCodeActions,
         authSMSCodeSelectors,
         checkSMSAsync,
         sendSMSAsync                 } from 'src/store/auth/sms-code';

import authPinCreate                    from 'src/store/auth/pin-create';
import { authPinCreateActions,
         authPinCreateSelectors       } from 'src/store/auth/pin-create';

import authPinEnter                     from 'src/store/auth/pin-enter';
import { authPinEnterActions,
         authPinEnterSelectors,
         checkAsync                   } from 'src/store/auth/pin-enter';

import authPwdReset                     from 'src/store/auth/pwd-reset';
import { authPwdResetActions, 
         authPwdResetSelectors,
         keyChangePasswordAsync,
         changePasswordAsync          } from 'src/store/auth/pwd-reset';

import authRegStart                     from 'src/store/auth/reg-start';
import { authRegStartActions, 
         authRegStartSelectors,
         checkInviteCodeAsync         } from 'src/store/auth/reg-start';

import authRegRequestCode               from 'src/store/auth/reg-request-code';
import { authRegRequestCodeActions, 
         authRegRequestCodeSelectors, 
         requestInviteAsync           } from 'src/store/auth/reg-request-code';

import authRegEnterData                 from 'src/store/auth/reg-enter-data';
import { authRegEnterDataActions, 
         authRegEnterDataSelectors, 
         checkRegisterDataAsync,
         copyPhoneFromRegStartAsync,
         registerCompleteAsync        } from 'src/store/auth/reg-enter-data';


export const auth = {
  common: {
    actions: authCommonActions,
    selectors: authCommonSelectors,
  },
  phoneEnter: {
    actions: authPhoneEnterActions,
    selectors: authPhoneEnterSelectors,
    async: {
      keyLoginAsync,
      loginAsync,
    }
  },
  pwdReset: {
    actions: authPwdResetActions,
    selectors: authPwdResetSelectors,
    async: {
      keyChangePasswordAsync,
      changePasswordAsync,
    }
  },
  regStart: {
    actions: authRegStartActions,
    selectors: authRegStartSelectors,
    async: {
      checkInviteCodeAsync,
    }
  },
  regRequestCode: {
    actions: authRegRequestCodeActions,
    selectors: authRegRequestCodeSelectors,
    async: {
      requestInviteAsync,
    }
  },
  regEnterData: {
    actions: authRegEnterDataActions,
    selectors: authRegEnterDataSelectors,
    async: {
      checkRegisterDataAsync,
      registerCompleteAsync,
      copyPhoneFromRegStartAsync,
    }
  },
  smsCode: {
    actions: authSMSCodeActions,
    selectors: authSMSCodeSelectors,
    async: {
      sendSMSAsync,
      checkSMSAsync,
    }
  },
  pinCreate: {
    actions: authPinCreateActions,
    selectors: authPinCreateSelectors,
  },
  pinEnter: {
    actions: authPinEnterActions,
    selectors: authPinEnterSelectors,
    async: {
      checkAsync,
    }
  },
};


export const authReducers = {
  authCommon,
  authPhoneEnter,
  authSMSCode,
  authPinCreate,
  authPinEnter,
  authPwdReset,
  authRegStart,
  authRegRequestCode,
  authRegEnterData,
}