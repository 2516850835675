import styled             from 'styled-components';

import splash             from 'src/assets/images/splash.png';

import { cssFlexColumn,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type AppSplashLookAndFeel = {
  typo: Typo;
  color: string;
}

const appsplash_light: AppSplashLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S32_LH43,
  color: themeColors.PrimaryWhite,
}

export type AppSplashVariants = 'light';

export const appsplash_variants: Map<AppSplashVariants, AppSplashLookAndFeel> = 
  new Map<AppSplashVariants, AppSplashLookAndFeel>([
    ['light', appsplash_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AppSplashLookAndFeel,
}>`
${cssFlexColumn};

  justify-content: center;
  align-items: center;

  background-image: url(${splash});
  background-repeat: no-repeat;
  background-size: cover;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.color};

  width: 100vw;
  height: 100dvh;
`
