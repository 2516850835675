import * as React            from 'react';

import { useTheme          } from 'styled-components';

import { useParams         } from 'react-router-dom';

import { useAlert          } from 'react-alert';

import { BlockUI           } from 'src/components/common/block-ui';
import { Labeled           } from 'src/components/common/labeled';
import { InputPhone        } from 'src/components/common/input/input-phone';
import { InputPassword     } from 'src/components/common/input/input-password';
import { Button            } from 'src/components/common/button';
import { VGap              } from 'src/components/common/flex/vgap';
import { Grow              } from 'src/components/common/flex/grow';
import { Link              } from 'src/components/common/link';

import { AppCaption        } from 'src/components/features/auth/common/app-caption';
import { Welcome           } from 'src/components/features/auth/phone-enter/welcome';

import { PhoneEnterLayout, 
         Screen,
         layout_light,
         layout_dark       } from 'src/containers/auth/phone-enter/layout';

import { Urls              } from 'src/providers/routing';

import Constants             from 'src/services/constants';
import Api                   from 'src/services/api';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi          } from 'src/store';
import { KeyLoginReject,
         LoginReject       } from 'src/store/auth/phone-enter';
         
import { AsyncOpStatus     } from 'src/common';



type PhoneEnterScreenElement = { layout: PhoneEnterLayout; dispatch: AppDispatch; }

const Caption: React.FC<PhoneEnterScreenElement> = ({ layout }) => {
  return (
    <React.Fragment>
      <Welcome variant = { layout.welcomeVariant } />
      <VGap size = { layout.gapWelcomeCaption } />
      <AppCaption
        variant = { layout.appCaptionVariant }
        caption = 'Checksteel'
        version = { `v.${Constants.APP_VERSION}` }
      />
    </React.Fragment>
  )
}

const Phone: React.FC<PhoneEnterScreenElement> = ({ layout, dispatch }) => {
  const phone = useAppSelector(storeApi.auth.phoneEnter.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.auth.phoneEnter.selectors.selectPhoneError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapCaptionForm } />
      <Labeled variant = { layout.labeledVariant } label = 'Номер телефона' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          onChange = { (value) => dispatch(storeApi.auth.phoneEnter.actions.phoneChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.phoneEnter.actions.phoneErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Password: React.FC<PhoneEnterScreenElement> = ({ layout, dispatch }) => {
  const password = useAppSelector(storeApi.auth.phoneEnter.selectors.selectPassword);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElement } />
      <Labeled variant = { layout.labeledVariant } label = 'Пароль' isRequired>
        <InputPassword
          variant = { layout.inputPasswordVariant }
          value = { password }
          onChange = { (value) => dispatch(storeApi.auth.phoneEnter.actions.passwordChanged(value)) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Actions: React.FC<PhoneEnterScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const { stage } = useParams();
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const formFilled = useAppSelector(storeApi.auth.phoneEnter.selectors.selectFormFilled);
  const phone = useAppSelector(storeApi.auth.phoneEnter.selectors.selectPhone);

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (stage === 'continue' && !bootOpStarted.current)
    {
      bootOpStarted.current = true;
      setOpStatus(AsyncOpStatus.BUSY);
      dispatch(storeApi.auth.phoneEnter.async.loginAsync())
        .unwrap() 
        .then((result) => {
          setOpStatus(AsyncOpStatus.IDLE);
          dispatch(storeApi.auth.common.actions.accountChanged(result));
          Urls.PinCreate.build().navigateWithReplace();
        })
        .catch((rawError) => {
          const error = rawError as LoginReject;
          if (!Api.isCommonAuthError(error.apiError?.statusCode ?? 0))
          {
            alert.error(error.apiError?.message);
          }
          setOpStatus(AsyncOpStatus.IDLE);
        })
    }
  }, [stage, dispatch, setOpStatus, alert]);

  const nextTapHandler = async () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.auth.phoneEnter.async.keyLoginAsync())
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        dispatch(storeApi.auth.common.actions.authKeyChanged(result.authKey));
        Urls.SmsCode.build('login').navigateWithReplace({ backTo: 'login' });
      })
      .catch((rawError) => {
        const error = rawError as KeyLoginReject;
        if (error.reason === 'api')
        {
          alert.error(error.apiError?.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  const onRegisterTapHandler = () => {
    dispatch(storeApi.auth.regStart.actions.formCleared());
    dispatch(storeApi.auth.regStart.actions.phoneChanged(phone));
    Urls.RegisterStart.build().navigateWithReplace({ backTo: 'login' })
  }

  const onResetTapHandler = () => {
    dispatch(storeApi.auth.pwdReset.actions.reset());
    dispatch(storeApi.auth.pwdReset.actions.phoneChanged(phone));
    Urls.ResetPassword.build().navigateWithReplace({ backTo: 'login' });
  }

  return (
    <React.Fragment>
      <VGap size={layout.gapFormSubmit} />
      <Button
        variant={layout.submitVariant}
        label='Далее'
        isDisabled={!formFilled}
        onTap={nextTapHandler}
      />
      <VGap size={layout.gapSubmitForget} />
      <Link
        variant={layout.linkVariant}
        label='Забыли пароль?'
        isCentered
        onTap={onResetTapHandler}
      />
      <Grow size={2} />
      <Link
        variant={layout.linkVariant}
        label='Регистрация'
        isCentered
        onTap={onRegisterTapHandler}
      />
      <BlockUI
        variant={layout.blockuiVariant}
        isOpened={opStatus === AsyncOpStatus.BUSY}
        message={stage === 'start' ? 'Проверка логина/пароля и получение токена' : 'Завершение входа'}
      />
    </React.Fragment>
  )
}

export const PhoneEnterScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  console.log(window.history);

  return (
    <Screen layout = { layout }>
      <Caption layout = { layout } dispatch = { dispatch } />
      <Phone layout = { layout } dispatch = { dispatch } />
      <Password layout = { layout } dispatch = { dispatch } />
      <Actions layout = { layout } dispatch = { dispatch } />
    </Screen>
  );
};
