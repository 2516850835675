import styled                     from 'styled-components';

import { CloseVector            } from 'src/components/vector/close';

import { IconButtonVariants     } from 'src/components/common/icon-button';
import { InputTextboxVariants   } from 'src/components/common/input/input-textbox';
import { ButtonVariants         } from 'src/components/common/button';
import { InputCalendarVariants  } from 'src/components/common/input/input-calendar';
import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { ReferSelectorVariants  } from 'src/components/features/common/refer-selector';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn          } from 'src/providers/theme/shared';



export type ReportLookAndFeel = {
  headerHeight: number;
  headerTextColor: string;
  headerBackgroundColor: string;
  headerTextTypo: Typo;
  headerIconButtonVariant: IconButtonVariants;

  formContainerPaddings: number[];
  formContainerBackgroundColor: string;
  formContainerGap: number;

  actionGap: number;
  actionCancelVariant: ButtonVariants;
  actionCancelWidth: number;
  actionAcceptVariant: ButtonVariants;

  dateGap: number;
  dateInputVariant: InputCalendarVariants;

  labeledVariant: LabeledContentVariants;
  inputVariant: InputTextboxVariants;

  referSelectorVariant: ReferSelectorVariants;
}

const report_light: ReportLookAndFeel = {
  headerHeight: 60,
  headerTextColor: themeColors.PrimaryBlack,
  headerBackgroundColor: themeColors.PrimaryWhite,
  headerTextTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  headerIconButtonVariant: 's46bluered',

  formContainerPaddings: [20],
  formContainerBackgroundColor: themeColors.BackgroundFillLight,
  formContainerGap: 24,

  actionGap: 16,
  actionCancelVariant: 'h48blueoutline',
  actionCancelWidth: 120,
  actionAcceptVariant: 'h48bluefill',

  dateGap: 16,
  dateInputVariant: 'h48light',

  labeledVariant: 'blue100w400s13lh16gap4',
  inputVariant: 'h48light',

  referSelectorVariant: 'light',
}

export type ReportVariants = 'light';

export const report_variants: Map<ReportVariants, ReportLookAndFeel> = 
  new Map<ReportVariants, ReportLookAndFeel>([
    ['light', report_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100dvh;
`

export const HeaderContainer = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;

  background-color: ${(args) => args.lookAndFeel.headerBackgroundColor};

  & > *:first-child {
    flex-grow: 2;
    text-align: center;
  }
`

export const HeaderLabel = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexRow};
  flex-grow: 2;
  justify-content: center;

  color: ${(args) => args.lookAndFeel.headerTextColor};
  ${(args) => typoAsCSS(args.lookAndFeel.headerTextTypo)}
`

export const FormContainer = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
  gap: ${(args) => args.lookAndFeel.formContainerGap}px;
  overflow-y: scroll;

  background-color: ${(args) => args.lookAndFeel.formContainerBackgroundColor};
  padding: ${(args) => args.lookAndFeel.formContainerPaddings[0]}px;

  & > * {
    flex-shrink: 0;
  }
`

export const ActionsContainer = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.actionGap}px;
  justify-content: space-between;

  background-color: ${(args) => args.lookAndFeel.formContainerBackgroundColor};
  padding: ${(args) => args.lookAndFeel.formContainerPaddings[0]}px;
`

export const DateContainer = styled.div<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.dateGap}px;

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;