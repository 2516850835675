import * as React                  from 'react';

import Drawer                      from '@mui/material/Drawer';

import { useNavigate             } from 'react-router-dom';

import { ArrowBackVector         } from 'src/components/vector/arrowback';

import { IconButton              } from 'src/components/common/icon-button';
import { Typography              } from 'src/components/common/typography';
import { BlockUI                 } from 'src/components/common/block-ui';

import { DefectListFragment      } from 'src/components/features/inspection/view/defectlist-fragment';
import { PieceFragment           } from 'src/components/features/inspection/view/piece-fragment';
import { ComplaintListFragment   } from 'src/components/features/inspection/view/complaintlist-fragment';
import { ComplaintFragment       } from 'src/components/features/inspection/view/complaint-fragment';
import { CertDownloadDialog      } from 'src/components/features/inspection/view/cert-download-dialog';

import { Container,
         CloseOutlineVectorBig,
         HeaderContainer,
         InspectionViewVariants,
         inspectionview_variants } from 'src/containers/inspection/view/ui';


import { useAppSelector, 
         useAppDispatch,
         storeApi                } from 'src/store';

import { AsyncOpStatus           } from 'src/common';



type Props = {
  variant: InspectionViewVariants;
}
export const InspectionViewDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = inspectionview_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const viewOpened = useAppSelector(storeApi.inspection.view.selectors.selectViewOpened);
  const viewFragment = useAppSelector(storeApi.inspection.view.selectors.selectViewFragment);
  const inspectionNumber = useAppSelector(storeApi.inspection.view.selectors.selectInspectionNumber);
  const loadOpStatus = useAppSelector(storeApi.inspection.view.selectors.selectLoadOpStatus);
  const loadOpStatusLabel = useAppSelector(storeApi.inspection.view.selectors.selectLoadOpStatusLabel);

  const changeFragmentFromBack = React.useRef(false);

  let title = '';

  switch (viewFragment)
  {
    case 'defectlist':
      title = `Осмотр ${inspectionNumber}`;
      break;
    case 'piece':
      title = 'Просмотр УЕ';
      break;
    case 'complaintlist':
      title = 'Требования';
      break;
    case 'complaint':
      title = 'Просмотр требования';
      break;
  }

  const onBackHandler = React.useCallback(() => {
    changeFragmentFromBack.current = true;
    switch (viewFragment)
    {
      case 'defectlist':
        dispatch(storeApi.inspection.view.actions.viewClosed());
        /** Если просмотр открывался из списка дублей, то окно с дублями нужно открыть вновь */
        dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesShow());
        dispatch(storeApi.main.inspectionList.async.prepareInfoAsync());
        break;
      case 'piece':
        dispatch(storeApi.inspection.view.actions.changeViewFragment('defectlist'));
        break;
      case 'complaintlist':
        dispatch(storeApi.inspection.view.actions.changeViewFragment('defectlist'));
        break;
      case 'complaint':
        dispatch(storeApi.inspection.view.actions.changeViewFragment('complaintlist'));
        break;
    }
  }, [dispatch, viewFragment]);


  React.useEffect(() => {
    if (viewOpened)
    {
      if (!changeFragmentFromBack.current)
      {
        navigate('', { state: { isPopup: true } });
      }
    }
    changeFragmentFromBack.current = false;
  }, [viewOpened, navigate, viewFragment])

  React.useEffect(() => {
    const backListener = (ev: PopStateEvent) => {
      onBackHandler();
    }
    window.addEventListener('popstate', backListener);

    return () => {
      window.removeEventListener('popstate', backListener);
    }
  }, [onBackHandler])

  return (
    <React.Fragment>
      <Drawer
        anchor = 'bottom'
        open = { viewOpened }
        onClose = {
          () => {
            dispatch(storeApi.inspection.view.actions.viewClosed());
            /** Если просмотр открывался из списка дублей, то окно с дублями нужно открыть вновь */
            dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesShow());
            dispatch(storeApi.main.inspectionList.async.prepareInfoAsync());
          }
        }
      >
        <Container lookAndFeel = { lookAndFeel }>
          <HeaderContainer lookAndFeel = { lookAndFeel }>
            <IconButton
              variant = { lookAndFeel.headerIconButtonVariant }
              isTapAllowed
              onTap = { () => navigate(-1) }
            >
              <ArrowBackVector />
            </IconButton>
            <Typography variant = { lookAndFeel.headerTypographyVariant }>
              { title }
            </Typography>
            <IconButton
              variant = { lookAndFeel.headerIconButtonVariant }
              isTapAllowed
              onTap = {
                () => {
                  dispatch(storeApi.inspection.view.actions.viewClosed());
                  /** Если просмотр открывался из списка дублей, то окно с дублями нужно открыть вновь */
                  dispatch(storeApi.inspection.edit.actions.pieceEditedExternalDoublesShow());
                  dispatch(storeApi.main.inspectionList.async.prepareInfoAsync());
                } 
              }
            >
              <CloseOutlineVectorBig />
            </IconButton>
          </HeaderContainer>
          {viewFragment === 'defectlist' && (
            <DefectListFragment variant = { lookAndFeel.defectListFragmentVariant } />
          )}
          {viewFragment === 'piece' && (
            <PieceFragment variant = { lookAndFeel.pieceFragmentVariant } />
          )}
          {viewFragment === 'complaintlist' && (
            <ComplaintListFragment variant = { lookAndFeel.complaintListFragmentVariant } />
          )}
          {viewFragment === 'complaint' && (
            <ComplaintFragment variant = { lookAndFeel.complaintFragmentVariant } />
          )}
        </Container>
      </Drawer>
      <CertDownloadDialog variant = { lookAndFeel.certDownloadVariant } />
      <BlockUI
        variant = { lookAndFeel.blockUIVariant }
        isOpened = { loadOpStatus === AsyncOpStatus.BUSY }
        message = { loadOpStatusLabel }
      />
    </React.Fragment>
  );
};
