import * as React               from 'react';

import { Container,
         LoadingVariants,
         loading_variants } from 'src/components/common/loading/ui';



type Props = {
  variant: LoadingVariants;
}
export const Loading: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = loading_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } />
  );
};
