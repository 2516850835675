import { createSlice,
         createAsyncThunk,
         PayloadAction,
         createSelector          } from '@reduxjs/toolkit';

import Api,
       { ApiError                } from 'src/services/api';
import Util                        from 'src/services/util';

import { RootState               } from 'src/store';
import { authRegEnterDataActions } from 'src/store/auth/reg-enter-data';
import { authRegStartActions     } from 'src/store/auth/reg-start';

import { isPhone,
         isCompany,
         isEmail,
         isInn,
         isName,
         isPatronymic,
         isSurname               } from 'src/validators';



const SLICE_NAME = 'auth:reg-request-code';

export interface AuthRegRequestCodeState {
  company: string;
  companyError: string;
  inn: string;
  innError: string;
  surname: string;
  surnameError: string;
  name: string;
  nameError: string;
  patronymic: string;
  patronymicError: string;
  phone: string;
  phoneError: string;
  email: string;
  emailError: string;
}

const initialState: AuthRegRequestCodeState = {
  company: '',
  companyError: '',
  inn: '',
  innError: '',
  surname: '',
  surnameError: '',
  name: '',
  nameError: '',
  patronymic: '',
  patronymicError: '',
  phone: '',
  phoneError: '',
  email: '',
  emailError: '',
}

// #region requestInviteAsync
type RequestInviteArgs = void;
type RequestInviteResolve = void;
export type RequestInviteReject = {
  reason: 'validation' | 'api';
  validationErrors?: {
    companyError?: string;
    innError?: string;
    surnameError?: string;
    nameError?: string;
    patronymicError?: string;
    phoneError?: string;
    emailError?: string;
  };
  apiError?: ApiError;
}
export const requestInviteAsync = createAsyncThunk<
  RequestInviteResolve,
  RequestInviteArgs,
  {
    state: RootState,
    rejectValue: RequestInviteReject,
  }
>(
  `${SLICE_NAME}/requestInviteAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const company = selectCompany(state);
    const inn = selectInn(state);
    const surname = selectSurname(state);
    const name = selectName(state);
    const patronymic = selectPatronymic(state);
    const phone = selectPhone(state);
    const email = selectEmail(state);
    const companyError = isCompany(company).error ?? '';
    const innError = isInn(inn, false).error ?? '';
    const surnameError = isSurname(surname).error ?? '';
    const nameError = isName(name).error ?? '';
    const patronymicError = isPatronymic(patronymic, true).error ?? '';
    const phoneError = isPhone(phone).error ?? '';
    const emailError = isEmail(email).error ?? '';
    const hasErrors = [companyError, innError, surnameError, nameError, patronymicError, phoneError, emailError]
      .some((value) => value.length > 0);

    if (hasErrors)
    {
      return thunkAPI.rejectWithValue({
        reason: 'validation',
        validationErrors: {
          ...(companyError.length > 0 ? { companyError } : {}),
          ...(innError.length > 0 ? { innError } : {}),
          ...(surnameError.length > 0 ? { surnameError } : {}),
          ...(nameError.length > 0 ? { nameError } : {}),
          ...(patronymicError.length > 0 ? { patronymicError } : {}),
          ...(phoneError.length > 0 ? { phoneError } : {}),
          ...(emailError.length > 0 ? { emailError } : {}),
        }
      });
    }

    try
    {
      const result = await Api.postAuthRequestInvite({
        client: `${surname} ${name} ${patronymic}`.trim(),
        company: company.trim(),
        inn: inn.trim(),
        phone: Util.selectDigitsFromString(phone),
        email: email.trim(),
      });

      thunkAPI.dispatch(authRegEnterDataActions.surnameChanged(surname));
      thunkAPI.dispatch(authRegEnterDataActions.nameChanged(name));
      thunkAPI.dispatch(authRegEnterDataActions.patronymicChanged(patronymic));
      thunkAPI.dispatch(authRegStartActions.phoneChanged(phone));
      thunkAPI.dispatch(authRegEnterDataActions.emailChanged(email));

      return result;
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue({
        reason: 'api',
        apiError: (error as ApiError),
      })
    }
  }
);
// #endregion

type CompanyChangedAction = PayloadAction<string>;
type InnChangedAction = PayloadAction<string>;
type SurnameChangedAction = PayloadAction<string>;
type NameChangedAction = PayloadAction<string>;
type PatronymicChangedAction = PayloadAction<string>;
type PhoneChangedAction = PayloadAction<string>;
type EmailChangedAction = PayloadAction<string>;

export const authRegRequestCodeSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    companyChanged: (state, action: CompanyChangedAction) => {
      state.company = action.payload;
    },
    companyErrorFixed: (state) => {
      state.companyError = '';
    },
    innChanged: (state, action: InnChangedAction) => {
      state.inn = action.payload;
    },
    innErrorFixed: (state) => {
      state.innError = '';
    },
    surnameChanged: (state, action: SurnameChangedAction) => {
      state.surname = action.payload;
    },
    surnameErrorFixed: (state) => {
      state.surnameError = '';
    },
    nameChanged: (state, action: NameChangedAction) => {
      state.name = action.payload;
    },
    nameErrorFixed: (state) => {
      state.nameError = '';
    },
    patronymicChanged: (state, action: PatronymicChangedAction) => {
      state.patronymic = action.payload;
    },
    patronymicErrorFixed: (state) => {
      state.patronymicError = '';
    },
    phoneChanged: (state, action: PhoneChangedAction) => {
      state.phone = action.payload;
    },
    phoneErrorFixed: (state) => {
      state.phoneError = '';
    },
    emailChanged: (state, action: EmailChangedAction) => {
      state.email = action.payload;
    },
    emailErrorFixed: (state) => {
      state.emailError = '';
    },
    formCleared: (state) => {
      state.company = '';
      state.companyError = '';
      state.inn = '';
      state.innError = '';
      state.surname = '';
      state.surnameError = '';
      state.name = '';
      state.nameError = '';
      state.patronymic = '';
      state.patronymicError = '';
      state.phone = '';
      state.phoneError = '';
      state.email = '';
      state.emailError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestInviteAsync.rejected, (state, action) => {
        switch (action.payload?.reason)
        {
          case 'validation':
            state.companyError = action.payload.validationErrors?.companyError ?? '';
            state.innError = action.payload.validationErrors?.innError ?? '';
            state.surnameError = action.payload.validationErrors?.surnameError ?? '';
            state.nameError = action.payload.validationErrors?.nameError ?? '';
            state.patronymicError = action.payload.validationErrors?.patronymicError ?? '';
            state.phoneError = action.payload.validationErrors?.phoneError ?? '';
            state.emailError = action.payload.validationErrors?.emailError ?? '';
            break;
        }
      })
  },
})

const selectCompany = (state: RootState) => state.authRegRequestCode.company;
const selectCompanyError = (state: RootState) => state.authRegRequestCode.companyError;
const selectInn = (state: RootState) => state.authRegRequestCode.inn;
const selectInnError = (state: RootState) => state.authRegRequestCode.innError;
const selectSurname = (state: RootState) => state.authRegRequestCode.surname;
const selectSurnameError = (state: RootState) => state.authRegRequestCode.surnameError;
const selectName = (state: RootState) => state.authRegRequestCode.name;
const selectNameError = (state: RootState) => state.authRegRequestCode.nameError;
const selectPatronymic = (state: RootState) => state.authRegRequestCode.patronymic;
const selectPatronymicError = (state: RootState) => state.authRegRequestCode.patronymicError;
const selectPhone = (state: RootState) => state.authRegRequestCode.phone;
const selectPhoneError = (state: RootState) => state.authRegRequestCode.phoneError;
const selectEmail = (state: RootState) => state.authRegRequestCode.email;
const selectEmailError = (state: RootState) => state.authRegRequestCode.emailError;
const selectFormFilled = createSelector(
  [selectCompany, selectSurname, selectInn, selectName, selectPhone, selectEmail],
  (company, surname, name, inn, phone, email) => {
    return (
      company.trim().length > 0
      && 
      inn.trim().length > 0
      && 
      surname.trim().length > 0
      && 
      name.trim().length > 0
      && 
      Util.selectDigitsFromString(phone).length > 1
      &&
      email.trim().length > 0
    )
  }
);

export const authRegRequestCodeSelectors = {
  selectCompany,
  selectCompanyError,
  selectInn,
  selectInnError,
  selectSurname,
  selectSurnameError,
  selectName,
  selectNameError,
  selectPatronymic,
  selectPatronymicError,
  selectPhone,
  selectPhoneError,
  selectEmail,
  selectEmailError,
  selectFormFilled,
}

export const authRegRequestCodeActions = authRegRequestCodeSlice.actions;

export default authRegRequestCodeSlice.reducer;