import styled           from 'styled-components';

import { cssFlexRow,
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type StatusLookAndFeel = {
  paddings: number[];
  height: number;
  labelTypo: Typo;

  backgroundColor: string;
  borderColor: string;
  labelColor: string;
}

const status_base: StatusLookAndFeel = {
  paddings: [0, 6],
  height: 16,
  labelTypo: themeTypos.GRTSKREG_W400_S08_LH10,

  backgroundColor: 'transparent',
  borderColor: 'transparent',
  labelColor: 'transparent',
}

const status_redfill: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.AccentRed1,
  borderColor: themeColors.AccentRed1,
  labelColor: themeColors.PrimaryWhite,
}

const status_redoutline: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.AccentRed1,
  labelColor: themeColors.AccentRed1,
}

const status_bluefill: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.AccentBlue2,
  borderColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryWhite,
}

const status_blueoutline: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.AccentBlue2,
  labelColor: themeColors.AccentBlue2,
}

const status_gray: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.SecondaryGray1,
  borderColor: themeColors.SecondaryGray1,
  labelColor: themeColors.SecondaryGray9,
}

const status_steelfill: StatusLookAndFeel = {
  ...status_base,

  backgroundColor: themeColors.PrimarySteel,
  borderColor: themeColors.PrimarySteel,
  labelColor: themeColors.PrimaryWhite,
}

export type StatusVariants = 'redfill' | 'redoutline' | 'bluefill' | 'blueoutline' | 'gray' | 'steelfill';

export const status_variants: Map<StatusVariants, StatusLookAndFeel> = 
  new Map<StatusVariants, StatusLookAndFeel>([
    ['redfill', status_redfill],
    ['redoutline', status_redoutline],
    ['bluefill', status_bluefill],
    ['blueoutline', status_blueoutline],
    ['gray', status_gray],
    ['steelfill', status_steelfill],
  ]);

export const Container = styled.span<{
  lookAndFeel: StatusLookAndFeel;
  isPointer: boolean;
}>`
  ${cssFlexRow};
  height: ${(args) => args.lookAndFeel.height}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
  align-items: center;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  color: ${(args) => args.lookAndFeel.labelColor};
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};

  white-space: nowrap;
  cursor: ${(args) => args.isPointer ? 'pointer' : 'default'};
`
