import styled           from 'styled-components';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS    } from 'src/providers/theme/shared';



type LinkLookAndFeel = {
  labelTypo: Typo;

  labelColor: string;
  labelDisabledColor: string;
}

const link_blue15: LinkLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S15_LH22,

  labelColor: themeColors.AccentBlue2,
  labelDisabledColor: themeColors.SecondaryGray4,
}

const link_blue13: LinkLookAndFeel = {
  ...link_blue15,
  labelTypo: themeTypos.GRTSKREG_W400_S13_LH16,
}

const link_blue10: LinkLookAndFeel = {
  ...link_blue15,
  labelTypo: themeTypos.GRTSKREG_W400_S10_LH16,
}

const link_blue16: LinkLookAndFeel = {
  ...link_blue15,
  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,
}

export type LinkVariants = 'blue10' | 'blue13' | 'blue15' | 'blue16';

export const link_variants: Map<LinkVariants, LinkLookAndFeel> = 
  new Map<LinkVariants, LinkLookAndFeel>([
    ['blue10', link_blue10],
    ['blue13', link_blue13],
    ['blue15', link_blue15],
    ['blue16', link_blue16],
  ]);

export const Container = styled.a<{
  lookAndFeel: LinkLookAndFeel,
  isDisabled: boolean,
  isCentered: boolean,
}>`
  width: 100%;
  display: block;
  text-align: ${(arg) => arg.isCentered ? 'center' : 'left' };

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.isDisabled ? args.lookAndFeel.labelDisabledColor : args.lookAndFeel.labelColor};

  text-decoration: none;
`
