import React from 'react';



type Props  =  {
  className?: string;
}
export const EmptyCaseDangerVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '88' height = '88' viewBox = '0 0 88 88' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M77 55.6471C67.4441 39.7946 59.7149 27.8976 49.7143 11H38.2857L4 67.8333L9.71429 77H78.2857L84 67.8333L80.3069 61.4706' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M44 28L44 55' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M44 61L44 66' />
    </svg>
  );
};
