import React from 'react';



type Props = {
  className?: string;
}
export const ToolbarServicesVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M3 10H10V4H3.875V7.42857'/>
      <rect stroke = 'currentColor' strokeWidth = '2' x = '14' y = '4' width = '6' height = '6'/>
      <rect stroke = 'currentColor' strokeWidth = '2' x = '4' y = '14' width = '6' height = '6'/>
      <rect stroke = 'currentColor' strokeWidth = '2' x = '14' y = '14' width = '6' height = '6'/>
    </svg>
  );
};
