import React from 'react';



type Props = {
  className?: string;
}
export const ArrowDownVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '14' height = '14' viewBox = '0 0 14 14' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M13 4L8 10L6 10L1 4' />
    </svg>
  );
};
