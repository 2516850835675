import * as React              from 'react';

import { useAlert            } from 'react-alert';

import { useTheme            } from 'styled-components';

import { ArrowBackVector     } from 'src/components/vector/arrowback';
import { FaceIDVector        } from 'src/components/vector/faceid';
import { FingerPrintVector   } from 'src/components/vector/fingerprint';

import { BlockUI             } from 'src/components/common/block-ui';
import { LabeledContent      } from 'src/components/common/labeled-content';
import { Typography          } from 'src/components/common/typography';
import { Dialog              } from 'src/components/common/dialog';
import { VGap                } from 'src/components/common/flex/vgap';
import { Labeled             } from 'src/components/common/labeled';
import { InputTextbox        } from 'src/components/common/input/input-textbox';
import { InputPhone          } from 'src/components/common/input/input-phone';
import { Button              } from 'src/components/common/button';

import { NavBar              } from 'src/components/features/common/nav-bar';
import { BlockCaption        } from 'src/components/features/main/common/block-caption';

import { BooleanFlagsList    } from 'src/components/features/main/personal/boolean-flags-list';
import { EmailConfirmDialog  } from 'src/components/features/main/personal/email-confirm-dialog';
import { PhoneConfirmDialog  } from 'src/components/features/main/personal/phone-confirm-dialog';
import { BooleanFlag         } from 'src/components/features/main/personal/boolean-flags-list/component';

import { PersonalLayout,
         Screen,
         ScreenBody,
         Form,
         StatusOk,
         StatusWarning,
         layout_light,
         layout_dark,   
         ActionButtons       } from 'src/containers/main/personal/layout';

import { Urls                } from 'src/providers/routing';

import Api                     from 'src/services/api';
import * as types              from 'src/services/api/types';
import Util                    from 'src/services/util';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi            } from 'src/store';
import { CheckReject, 
         CheckSMSCodeReject, 
         SavePersonalReject,
         SendEmailReject,
         SendSMSCodeReject   } from 'src/store/main/personal';

import { isPhone             } from 'src/validators';

import { AsyncOpStatus       } from 'src/common';



type PersonalScreenElement = { layout: PersonalLayout; dispatch: AppDispatch; }

const Navigation: React.FC<PersonalScreenElement> = ({ layout }) => {
  const onBackTapHandler = () => {
    Urls.MainProfile.build().navigate();
  }

  return (
  <NavBar
      variant = { layout.navbarVariant }
      label = 'Личные данные'
      startButtons={[
        {
          id: 'back',
          vector: <ArrowBackVector />,
          action: onBackTapHandler
        }
      ]}
    />
  )
}

const Org: React.FC<PersonalScreenElement> = ({ layout }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  return (
    <LabeledContent variant = { layout.labeledContentVariant } label = 'Компания'>
      <Typography variant = { layout.typoCompanyVariant }>
        { account.enterpriseName }
      </Typography>
    </LabeledContent>
  )
}

const Surname: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const surname = useAppSelector(storeApi.main.personal.selectors.selectSurname);
  const surnameError = useAppSelector(storeApi.main.personal.selectors.selectSurnameError);

  return (
    <Labeled variant = { layout.labeledVariant } label = 'Фамилия' isRequired>
      <InputTextbox
        variant = { layout.inputTextboxVariant }
        value = { surname }
        isError = { surnameError !== '' }
        hint = { surnameError }
        onChange = { (value) => dispatch(storeApi.main.personal.actions.surnameChanged(value)) }
        onFocus = { () => dispatch(storeApi.main.personal.actions.surnameErrorFixed()) }
      />
    </Labeled>
  )
}

const Name: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const name = useAppSelector(storeApi.main.personal.selectors.selectName);
  const nameError = useAppSelector(storeApi.main.personal.selectors.selectNameError);

  return (
    <Labeled variant = { layout.labeledVariant } label = 'Имя' isRequired>
      <InputTextbox
        variant = { layout.inputTextboxVariant }
        value = { name }
        isError = { nameError !== '' }
        hint = { nameError }
        onChange = { (value) => dispatch(storeApi.main.personal.actions.nameChanged(value)) }
        onFocus = { () => dispatch(storeApi.main.personal.actions.nameErrorFixed()) }
      />
    </Labeled>
  )
}

const Patronymic: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const patronymic = useAppSelector(storeApi.main.personal.selectors.selectPatronymic);
  const patronymicError = useAppSelector(storeApi.main.personal.selectors.selectPatronymicError);

  return (
    <Labeled variant = { layout.labeledVariant } label = 'Отчество' isOptional>
      <InputTextbox
        variant = { layout.inputTextboxVariant }
        value = { patronymic }
        isError = { patronymicError !== '' }
        hint = { patronymicError }
        onChange = { (value) => dispatch(storeApi.main.personal.actions.patronymicChanged(value)) }
        onFocus = { () => dispatch(storeApi.main.personal.actions.patronymicErrorFixed()) }
      />
    </Labeled>
  )
}

const Post: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const post = useAppSelector(storeApi.main.personal.selectors.selectPost);
  const postError = useAppSelector(storeApi.main.personal.selectors.selectPostError);

  return (
    <Labeled variant = { layout.labeledVariant } label = 'Должность' isRequired>
      <InputTextbox
        variant = { layout.inputTextboxVariant }
        value = { post }
        isError = { postError !== '' }
        hint = { postError }
        onChange = { (value) => dispatch(storeApi.main.personal.actions.postChanged(value)) }
        onFocus = { () => dispatch(storeApi.main.personal.actions.postErrorFixed()) }
      />
    </Labeled>
  )
}

const Phone: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const phone = useAppSelector(storeApi.main.personal.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.main.personal.selectors.selectPhoneError);
  const phoneConfirmed = useAppSelector(storeApi.main.personal.selectors.selectPhoneConfirmed);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [dialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const [opLabel, setOpLabel] = React.useState<string>('');

  const customEmailIcon = phoneConfirmed ?
    <StatusOk color={layout.statusOkColor} />
    :
    <StatusWarning color={layout.statusWarningColor} />;

  const sendSMSCode = React.useCallback(() => {
    setOpLabel('Отправка СМС-кода для подтверждения номера телефона');
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.main.personal.async.sendSMSCodeAsync(Util.normalizePhone(phone)))
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        if (!dialogOpened)
        {
          setDialogOpened(true);
        }
      })
      .catch((rawError) => {
        const error = rawError as SendSMSCodeReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }, [dialogOpened, alert, dispatch, phone]);

  const onCustomIconTapHandler = () => {
    const phoneCheckResult = isPhone(phone);
    if (phoneCheckResult.ok)
    {
      sendSMSCode();
    }
    else
    {
      alert.error(phoneCheckResult.error);
    }
  }

  const onDialogSendHandler = () => {
    sendSMSCode()
  }

  const onDialogCancelHandler = () => {
    setDialogOpened(false);
  }

  const onDialogAcceptHandler = (code: string) => {
    setOpLabel('Проверка СМС-кода для подтверждения номера телефона');
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.main.personal.async.checkSMSCodeAsync(parseInt(Util.selectDigitsFromString(code), 10)))
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        setDialogOpened(false);
        // Не проставляем здесь флаг phoneConfirmed явно, т.к. это за на сделает
        // редюсер checkSMSCodeAsync.fulfilled
      })
      .catch((rawError) => {
        const error = rawError as CheckSMSCodeReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  const onPhoneChangeHandler = (value: string) => {
    dispatch(storeApi.main.personal.actions.phoneChanged(value));
    if (account.phone !== Util.normalizePhone(phone))
    {
      dispatch(storeApi.main.personal.actions.phoneConfirmedChanged(false));
    }
    else
    {
      dispatch(storeApi.main.personal.actions.phoneConfirmedChanged(true));
    }
  }

  return (
    <React.Fragment>
      <Labeled variant = { layout.labeledVariant } label = 'Телефон' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          customIcon = { customEmailIcon }
          onChange = { onPhoneChangeHandler }
          onFocus = { () => dispatch(storeApi.main.personal.actions.phoneErrorFixed()) }
          onCustomIconTap = { phoneConfirmed ? undefined : onCustomIconTapHandler }
        />
      </Labeled>
      <Dialog variant = { layout.dialogVariant } isOpened = { dialogOpened }>
        <PhoneConfirmDialog
          variant = { layout.emailConfirmDialogVariant }
          onSend = { onDialogSendHandler }
          onCancel = { onDialogCancelHandler }
          onAccept = { onDialogAcceptHandler }
        />
      </Dialog>
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opLabel }
      />
    </React.Fragment>
  )
}

const Email: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const email = useAppSelector(storeApi.main.personal.selectors.selectEmail);
  const emailError = useAppSelector(storeApi.main.personal.selectors.selectEmailError);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [dialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);

  const isStatusOk = account.email === email && account.isEmailConfirmed;
  const customEmailIcon = isStatusOk ?
    <StatusOk color={layout.statusOkColor} />
    :
    <StatusWarning color={layout.statusWarningColor} />;

  const onCustomIconTapHandler = () => {
    setDialogOpened(true);
  }

  const onCancelHandler = () => {
    setDialogOpened(false);
  }

  const onAcceptHandler = () => {
    setDialogOpened(false);
    if (account.email === email)
    {
      setOpStatus(AsyncOpStatus.BUSY);
      dispatch(storeApi.main.personal.async.sendEmailAsync())
        .unwrap()
        .then((result) => {
          setOpStatus(AsyncOpStatus.IDLE);
        })
        .catch((rawError) => {
          const error = rawError as SendEmailReject;
          if (!Api.isCommonAuthError(error.statusCode ?? 0))
          {
            alert.error(error.message);
          }
          setOpStatus(AsyncOpStatus.IDLE);
        });
    }
  }

  return (
    <React.Fragment>
      <Labeled variant = { layout.labeledVariant } label = 'Е-mail' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { email }
          isError = { emailError !== '' || !isStatusOk }
          hint = { isStatusOk ? emailError : 'Email не подтвержден' }
          customIcon = { customEmailIcon }
          onChange = { (value) => dispatch(storeApi.main.personal.actions.emailChanged(value)) }
          onFocus = { () => dispatch(storeApi.main.personal.actions.emailErrorFixed()) }
          onCustomIconTap = { isStatusOk ? undefined : onCustomIconTapHandler }
        />
      </Labeled>
      <Dialog variant = { layout.dialogVariant } isOpened = { dialogOpened }>
        <EmailConfirmDialog
          variant = { layout.emailConfirmDialogVariant }
          email = { email }
          isChanged = { account.email !== email }
          onCancel = { onCancelHandler }
          onAccept = { onAcceptHandler }
        />
      </Dialog>
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = 'Отправка письма для подтверждения e-mail'
      />
    </React.Fragment>
  )
}

const Pushes: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const pushNewInspection = useAppSelector(storeApi.main.personal.selectors.selectPushNewInspection);
  const pushChangeStatusInspection = useAppSelector(storeApi.main.personal.selectors.selectPushChangeStatusInspection);
  const pushErrorInspection = useAppSelector(storeApi.main.personal.selectors.selectPushErrorInspection);
  const pushChangeStep8D = useAppSelector(storeApi.main.personal.selectors.selectPushChangeStep8D);
  const pushReadMessage = useAppSelector(storeApi.main.personal.selectors.selectPushReadMessage);
  const pushChangeStepQMMSG = useAppSelector(storeApi.main.personal.selectors.selectPushChangeStepQMMSG);

  const { NEW_INSPECTION, 
          CHANGE_STATUS_INSPECTION, 
          ERROR_INSPECTION,
          CHANGE_STEP_8D,
          READ_MESSAGE,
          CHANGE_STEP_QMMSG         } = types.notification.NotificationCode;

  const onFlagToggleHandler = (flag: BooleanFlag) => {
    switch (flag.id)
    {
      case NEW_INSPECTION:
        dispatch(storeApi.main.personal.actions.pushNewInspectionChanged(!pushNewInspection));
        break;
      case CHANGE_STATUS_INSPECTION:
        dispatch(storeApi.main.personal.actions.pushChangeStatusInspectionChanged(!pushChangeStatusInspection));
        break;
      case ERROR_INSPECTION:
        dispatch(storeApi.main.personal.actions.pushErrorInspectionChanged(!pushErrorInspection));
        break;
      case CHANGE_STEP_8D:
        dispatch(storeApi.main.personal.actions.pushChangeStep8DChanged(!pushChangeStep8D));
        break;
      case READ_MESSAGE:
        dispatch(storeApi.main.personal.actions.pushReadMessageChanged(!pushReadMessage));
        break;
      case CHANGE_STEP_QMMSG:
        dispatch(storeApi.main.personal.actions.pushChangeStepQMMSGChanged(!pushChangeStepQMMSG));
        break;
    }
  }

  return (
    <BooleanFlagsList
      variant = { layout.booleanFlagsListVariant }
      items = { [
        { id: NEW_INSPECTION, label: 'Новый осмотр', value: pushNewInspection },
        { id: CHANGE_STATUS_INSPECTION, label: 'Новый статус', value: pushChangeStatusInspection },
        { id: ERROR_INSPECTION, label: 'Ошибка', value: pushChangeStatusInspection },
        { id: CHANGE_STEP_8D, label: 'Новый шаг 8D', value: pushChangeStep8D },
        { id: READ_MESSAGE, label: 'Сообщение', value: pushReadMessage },
        { id: CHANGE_STEP_QMMSG, label: 'Статус по претензии', value: pushChangeStepQMMSG },
      ] }
      onToggle = { onFlagToggleHandler }
    />
  )
}

const Actions: React.FC<PersonalScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const formFilled = useAppSelector(storeApi.main.personal.selectors.selectFormFilled);
  const phoneConfirmed = useAppSelector(storeApi.main.personal.selectors.selectPhoneConfirmed);
  const phone = useAppSelector(storeApi.main.personal.selectors.selectPhone);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const [opLabel, setOpLabel] = React.useState<string>('');

  const onCancelHandler = () => {
    Urls.MainProfile.build().navigate();
  }

  const onSaveHandler = () => {
    setOpLabel('Сохранение персональных данных');
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.main.personal.async.savePersonalAsync())
      .unwrap()
      .then((result) => {
        if (Util.selectDigitsFromString(phone) !== `${account.phone}` && phoneConfirmed)
        {
          Urls.Login.build().navigate();
          alert.success('Персональные данные сохранены. Необходимо выполнить вход с новым телефоном.');
        }
        else
        {
          dispatch(storeApi.main.personal.async.checkAsync())
            .unwrap() 
            .then((result) => {
              setOpStatus(AsyncOpStatus.IDLE);
              dispatch(storeApi.auth.common.actions.accountChanged({
                ...result,
                expRefresh: account.expRefresh,
                jwtAccess: account.jwtAccess,
                jwtRefresh: account.jwtRefresh,
              }));
              alert.success('Персональные данные сохранены');
            })
            .catch((rawError) => {
              const error = rawError as CheckReject;
              if (!Api.isCommonAuthError(error.statusCode ?? 0))
              {
                alert.error(error.message);
              }
              setOpStatus(AsyncOpStatus.IDLE);
            })
        }
      })
      .catch((rawError) => {
        const error = rawError as SavePersonalReject;
        if (error.reason === 'api' && !Api.isCommonAuthError(error.apiError?.statusCode ?? 0))
        {
          alert.error(error.apiError?.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }


  return (
    <React.Fragment>
      <ActionButtons layout={layout}>
        <Button
          variant = { layout.cancelButtonVariant }
          label = 'Отмена'
          isDisabled = { false }
          onTap = { onCancelHandler }
        />
        <Button
          variant = { layout.submitButtonVariant }
          label = 'Сохранить'
          isDisabled = { !formFilled || !phoneConfirmed }
          onTap = { onSaveHandler }
        />
      </ActionButtons>
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opLabel }
      />
    </React.Fragment>
  );
}

export const PersonalScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Org layout = { layout } dispatch = { dispatch } />
        <VGap size = { layout.formPaddings[0] } />
        <Form layout = { layout }>
          <Surname layout = { layout } dispatch = { dispatch } />
          <Name layout = { layout } dispatch = { dispatch } />
          <Patronymic layout = { layout } dispatch = { dispatch } />
          <Post layout = { layout } dispatch = { dispatch } />
          <Phone layout = { layout } dispatch = { dispatch } />
          <Email layout = { layout } dispatch = { dispatch } />
        </Form>
        <BlockCaption variant = { layout.blockCaptionVariant }>Биометрия</BlockCaption>
        <BooleanFlagsList
          variant = { layout.booleanFlagsListVariant }
          items = { [ 
            { id: 'faceid', icon: <FaceIDVector />, label: 'Face ID', value: true },
            { id: 'fingerprint', icon: <FingerPrintVector />, label: 'Отпечаток', value: false },
          ] }
          onToggle = { (item) => {} }
        />
        <BlockCaption variant = { layout.blockCaptionVariant }>Подписка на пуши</BlockCaption>
        <Pushes layout = { layout } dispatch = { dispatch } />
        <Actions layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
