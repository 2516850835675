import React from 'react';



type Props = {
  className?: string;
}
export const ScannerVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '40' height = '40' viewBox = '0 0 40 40' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M6 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M14 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M10 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M40 19L9.53675e-07 19' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M18 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M22 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M26 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M30 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M6 23V32M14 23V28M10 23V28M18 23V32M22 23V28M26 23V28M30 23V28M34 23V32' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M34 9V15' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9 1H1V9' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9 39H1V31' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M31 1H39V9' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M31 39H39V31' />
    </svg>
  );
};
