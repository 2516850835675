import styled                      from 'styled-components';

import { BlockUIVariants         } from 'src/components/common/block-ui';
import { ButtonVariants          } from 'src/components/common/button';
import { DateLabelVariants       } from 'src/components/common/date-label';
import { TabSwitchVariants       } from 'src/components/common/tab-switch';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';
import { DialogVariants          } from 'src/components/common/dialog';

import { NavBarVariants          } from 'src/components/features/common/nav-bar';
import { PieceHelpDialogVariants } from 'src/components/features/inspection/edit/piece-help-dialog';
import { RollVariants            } from 'src/components/features/main/shade-selection/roll';
import { ResultVariants          } from 'src/components/features/main/shade-selection/result';
import { ReportVariants          } from 'src/components/features/main/shade-selection/report';
import { SelectionRatingVariants } from 'src/components/features/main/shade-selection/selection-rating';

import { themeColors, 
         cssFlexColumn           } from 'src/providers/theme/shared';



export type ShadeSelectionLayout = {
  backgroundColor: string;

  paddings: number[];
  scrollableGap: number;
  addRollPadding: number;
  actionButtonPadding: number;

  errorsIconColor: string;
  dividerColor: string;

  navbarVariant: NavBarVariants;

  blockuiVariant: BlockUIVariants;
  dateLabelVariant: DateLabelVariants;
  tabSwitchVariant: TabSwitchVariants;

  rollVariant: RollVariants;
  pieceHelpVariant: PieceHelpDialogVariants;
  addRollButtonVariant: ButtonVariants;
  deleteRollVariant: TwoActionDialogVariants;

  resultVariant: ResultVariants;
  reportVariant: ReportVariants;
  ratingVariant: SelectionRatingVariants;
  ratingDialogVariant: DialogVariants;

  generateButtonVariant: ButtonVariants;
  repeatButtonVariant: ButtonVariants;
  ratingButtonVariant: ButtonVariants;
}

export const layout_light: ShadeSelectionLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],
  scrollableGap: 8,
  addRollPadding: 20,
  actionButtonPadding: 20,

  errorsIconColor: themeColors.AccentRed1,
  dividerColor: themeColors.SecondaryGray3,

  navbarVariant: 'lightblue',

  blockuiVariant: 'light',
  dateLabelVariant: 'h24blue2calendar',
  tabSwitchVariant: 'h32',

  rollVariant: 'light',
  pieceHelpVariant: 'light',
  addRollButtonVariant: 'h36blueoutline',
  deleteRollVariant: 'light',

  resultVariant: 'light',
  reportVariant: 'light',
  ratingVariant: 'light',
  ratingDialogVariant: 'light',

  generateButtonVariant: 'h48bluefill',
  repeatButtonVariant: 'h48blueoutline',
  ratingButtonVariant: 'h36blueoutline',
}

export const layout_dark: ShadeSelectionLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  padding: ${(args) => args.layout.paddings[0]}px;
`

export const ScrollableContainer = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.scrollableGap}px;
  flex-grow: 2;
  overflow: scroll;
`

export const AddRoll = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  padding: ${(args) => args.layout.addRollPadding}px 0;
`

export const RollDivider = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  border-bottom: 1px solid ${(args) => args.layout.dividerColor};
`

export const ErrorIconWrapper =styled.div<{
  layout: ShadeSelectionLayout,
}>`
  display: flex;
  align-items: center;
  color: ${(args) => args.layout.errorsIconColor};
`;

export const ActionsContainer = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  ${cssFlexColumn};
  flex-shrink: 0;
`

export const ActionButton = styled.div<{
  layout: ShadeSelectionLayout,
}>`
  padding-top: ${(args) => args.layout.actionButtonPadding}px;
`
