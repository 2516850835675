import React from 'react';



type Props = {
  className?: string;
}
export const MessageStatusErrorVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '18' height = '10' viewBox = '0 0 18 10' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M9 1.5L16 8.5M9 8.5L16 1.5' />
    </svg>
  );
};
