import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';



type FeedbackThanksDialogLookAndFeel = {
  dialogVariant: DialogVariants;
  oneActionVarint: OneActionDialogVariants;
}

const feedbackthanksdialog_light: FeedbackThanksDialogLookAndFeel = {
  dialogVariant: 'light',
  oneActionVarint: 'light',
}

export type FeedbackThanksDialogVariants = 'light';

export const feedbackthanksdialog_variants: Map<FeedbackThanksDialogVariants, FeedbackThanksDialogLookAndFeel> = 
  new Map<FeedbackThanksDialogVariants, FeedbackThanksDialogLookAndFeel>([
    ['light', feedbackthanksdialog_light],
  ]);
