import styled                          from 'styled-components';

import { ButtonVariants              } from 'src/components/common/button';
import { TypographyVariants          } from 'src/components/common/typography';
 
import { ComplaintDefectItemVariants } from 'src/components/features/inspection/view/complaint-defect-item';

import { cssFlexColumn               } from 'src/providers/theme/shared';



type ComplaintListFragmentLookAndFeel = {
  gap: number;
  listGap: number;
  padding: number[];

  headerTypographyVariant: TypographyVariants;
  defectItemVariant: ComplaintDefectItemVariants;
  completeButtonVariant: ButtonVariants;
}

const complaintlistfragment_light: ComplaintListFragmentLookAndFeel = {
  gap: 20,
  listGap: 20,
  padding: [0, 16, 0, 0],
  
  headerTypographyVariant: 'blackw600s18lh24',
  defectItemVariant: 'light',
  completeButtonVariant: 'h48bluefill',
}

export type ComplaintListFragmentVariants = 'light';

export const complaintlistfragment_variants: Map<ComplaintListFragmentVariants, ComplaintListFragmentLookAndFeel> = 
  new Map<ComplaintListFragmentVariants, ComplaintListFragmentLookAndFeel>([
    ['light', complaintlistfragment_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ComplaintListFragmentLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
`

export const List = styled.div<{
  lookAndFeel: ComplaintListFragmentLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.listGap}px;
  overflow-y: auto;
  padding-top: ${(args) => args.lookAndFeel.padding[1]}px;
  flex-grow: 2;
`
