import styled               from 'styled-components';

import { NavBarVariants   } from 'src/components/features/common/nav-bar';
import { ToolbarVariants  } from 'src/components/features/main/common/toolbar';
import { IconListVariants } from 'src/components/features/main/common/icon-list';

import { themeColors, 
         cssFlexColumn    } from 'src/providers/theme/shared';



export type ServicesLayout = {
  backgroundColor: string;

  navbarVariant: NavBarVariants;
  iconlistVariant: IconListVariants;
  toolbarVariant: ToolbarVariants;
}

export const layout_light: ServicesLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  navbarVariant: 'lightblue',
  iconlistVariant: 'light',
  toolbarVariant: 'light',
}

export const layout_dark: ServicesLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: ServicesLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`
export const ScreenBody = styled.div<{
  layout: ServicesLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  overflow: scroll;
`
