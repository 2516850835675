import * as React               from 'react';

import { Container,
         StaticValueVariants,
         staticvalue_variants } from 'src/components/features/inspection/view/static-value/ui';



type Props = {
  variant: StaticValueVariants;
  children: React.ReactNode;
}
export const StaticValue: React.FC<Props> = ({
  variant,
  children,
}) => {
  const lookAndFeel = staticvalue_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { children }
    </Container>
  );
};
