import * as React                    from 'react';

import { PhotoProvider             } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { DownloadVector            } from 'src/components/vector/download';
import { ReloadVector              } from 'src/components/vector/reload';
import { EyeShowVector             } from 'src/components/vector/eye-show';
import { SharingVector             } from 'src/components/vector/sharing';

import { Typography                } from 'src/components/common/typography';
import { Labeled                   } from 'src/components/common/labeled';
import { TabSwitch                 } from 'src/components/common/tab-switch';
import { Switch                    } from 'src/components/common/switch';
import { IconButton                } from 'src/components/common/icon-button';

import { AttachmentPhoto           } from 'src/components/features/inspection/view/attachment-photo';
import { AttachmentPDF             } from 'src/components/features/inspection/view/attachment-pdf';
import { StaticValue               } from 'src/components/features/inspection/view/static-value';

import { PieceFragmentLookAndFeel, 
         Container,
         FormContainer,
         FormRowElement,
         PhotoList,
         PieceFragmentVariants,
         piecefragment_variants    } from 'src/components/features/inspection/view/piece-fragment/ui';

import * as types                    from 'src/services/api/types';
import { QCState                   } from 'src/services/api/types/inspection';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                  } from 'src/store';

import { Nullable                  } from 'src/common';




const TYPE_GENERAL = types.inspection.PieceFileType.GENERAL;
const TYPE_PHOTO = types.inspection.PieceFileType.PHOTO;
const TYPE_PDF = types.inspection.PieceFileType.PDF;
const MODE_DEL = types.inspection.PieceFileMode.DEL;

const isTopographyExpandedCalc = (piece: Nullable<types.inspection.Piece>): boolean => {
  return piece !== null && (
    piece.topography.defectDistanceFromEdge !== 0
    ||
    piece.topography.defectSize !== 0
    ||
    piece.topography.defectStep !== 0
    ||
    piece.topography.defectTpgIdDistance !== null
    ||
    piece.topography.defectTpgIdSize !== null
    ||
    piece.topography.defectTpgIdStep !== null
    ||
    piece.topography.defectTpgTitleDistance !== null
    ||
    piece.topography.defectTpgTitleSize !== null
    ||
    piece.topography.defectTpgTitleStep !== null
  );
}

type PieceFragmentElement = { lookAndFeel: PieceFragmentLookAndFeel; dispatch: AppDispatch; };

const InputUEModeSwitcher: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isPiece = piece?.isPiece ?? false;
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const items = [
    { id: 'id', label: 'Идентификац. №' },
    { id: 'piece', label: account.isWireRod ? '№ партии/плавки' : '№ плавки' }
  ];
  const item = isPiece ? items[1] : items[0];

  return (
    <React.Fragment>
      <TabSwitch
        variant = { lookAndFeel.modeSwitcherVariant }
        items = { items }
        value = { item }
        onChange = { (_) => { } }
      />
    </React.Fragment>
  )
}

const QmetId: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Идентификационный №'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.qmetId ?? '' }</StaticValue>
    </Labeled>
  )
}

const Heat: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ плавки'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.heat ?? '' }</StaticValue>
    </Labeled>
  )
}

const Piece: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ рулона/листа/трубы'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.piece ?? '' }</StaticValue>
    </Labeled>
  )
}

const GroupAtt: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isWireRod = account.isWireRod || (piece?.groupAtt ?? null) !== null;

  if (!isWireRod)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ партии аттестации'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.groupAtt ?? '' }</StaticValue>
    </Labeled>
  )
}

const QCNum: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const inspectionGuid = useAppSelector(storeApi.inspection.view.selectors.selectInspectionGuid);
  const qcState = piece?.qmetData?.qcState ?? QCState.NONE;
  const qcNum = (piece?.qcNum ?? '') || (piece?.qmetData?.qcNum ?? '');
  const isSmc = piece?.isSmc ?? false;
  const isWireRod = account.isWireRod || (piece?.groupAtt ?? null) !== null;

  const downloadHandler = () => {
    dispatch(storeApi.inspection.view.async.downloadCertAsync({ qcNum }));
  }

  const reloadHandler = () => {
    dispatch(storeApi.inspection.view.async.checkCertAsync({ inspectionGuid }));
  }

  const showHandler = () => {
    dispatch(storeApi.inspection.view.async.viewCertAsync({ qcNum }));
  }

  const sharingHandler = () => {
    dispatch(storeApi.inspection.view.async.shareCertAsync({ qcNum }));
  }

  if (!isWireRod && qcNum === '')
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ сертификата качества'>
      <FormRowElement lookAndFeel = { lookAndFeel }>
        <StaticValue variant = { lookAndFeel.staticValueVariant }>{ qcNum }</StaticValue>
        { !isSmc && qcNum !== '' && qcState === QCState.NONE && 
          <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { downloadHandler }>
            <DownloadVector />
          </IconButton>
        }
        { !isSmc && qcNum !== '' && qcState === QCState.LOADING && 
          <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { reloadHandler }>
            <ReloadVector />
          </IconButton>
        }
        { !isSmc && qcNum !== '' && qcState === QCState.LOADED && 
          <React.Fragment>
            <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { showHandler }>
              <EyeShowVector />
            </IconButton>
            <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { sharingHandler }>
              <SharingVector />
            </IconButton>
          </React.Fragment>
        }
      </FormRowElement>
    </Labeled>
  )
}

const CustomFields: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const customFields = piece?.fields ?? [];

  const fields = [...account.fields].sort((a, b) => a.id - b.id);

  return (
    <React.Fragment>
      {fields.map((field) => (
        <Labeled 
          key = { field.code }
          variant = { lookAndFeel.labeledVariant }
          label = { field.title }
        >
          <StaticValue variant = { lookAndFeel.staticValueVariant }>{ customFields.find((item) => item.code === field.code)?.value ?? '' }</StaticValue>
        </Labeled>
      ))}
    </React.Fragment>
  )
}

const Weight: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const weight = piece?.weight ?? '';
  const unit = piece?.unit?.title ?? 'кг';

  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = { isEntryAccounting ? 'Фактический вес' : 'Количество брака' }
    >
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ `${weight} ${weight === '' ? '' : unit}` }</StaticValue>
    </Labeled>
  )
}

const Note: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Детальное описание по позиции (прочие комментарии)'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.note ?? '' }</StaticValue>
    </Labeled>
  )
}

const Photo: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  const files = piece?.files ?? [];
  
  const general = files.find((file) => file.type === TYPE_GENERAL) ?? null;
  const isGeneralExists = general !== null && general.mode !== MODE_DEL;
  const photos = files.filter((file) => file.type === TYPE_PHOTO && file.mode !== MODE_DEL);
  const pdfs = files.filter((file) => file.type === TYPE_PDF && file.mode !== MODE_DEL);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Фотографии дефекта'>
      <PhotoList lookAndFeel = { lookAndFeel }>
        <PhotoProvider >
          {isGeneralExists && (
            <AttachmentPhoto
              variant = { lookAndFeel.attachmentPhotoVariant }
              photo = { general }
            />
          )}
          {photos.map((photo) => (
           <AttachmentPhoto
              variant = { lookAndFeel.attachmentPhotoVariant }
              photo = { photo }
            />
          ))}
          {pdfs.map((pdf) => (
           <AttachmentPDF
              variant = { lookAndFeel.attachmentPhotoVariant }
              pdf = { pdf }
            />
          ))}
        </PhotoProvider>
      </PhotoList>
    </Labeled>
  )
}

const IsTopography: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <FormRowElement lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.topoSwitchLabelTypo }>Топография дефекта</Typography>
      <Switch
        variant = { lookAndFeel.topoSwitchVariant }
        value = { isTopographyExpanded }
        onChange = { (_) => { } }
      />
    </FormRowElement>
  )
}

const TopographyNote: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Typography variant = { lookAndFeel.topoNoteTypo }>
      Топографию дефекта заполнить<br /> ниже или сделать фото<br /> с рулеткой
    </Typography>
  )
}

const TopographySize: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Размер дефекта'
    >
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectSize ?? '' }</StaticValue>
    </Labeled>
  )
}

const TopographySizeId: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectTpgTitleSize ?? '' }</StaticValue>
    </React.Fragment>
  )
}

const TopographyStep: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Шаг дефекта'
    >
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectStep ?? '' }</StaticValue>
    </Labeled>
  )
}

const TopographyStepId: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectTpgTitleStep ?? '' }</StaticValue>
    </React.Fragment>
  )
}

const TopographyDistance: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Расстояние от кромки'
    >
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectDistanceFromEdge ?? '' }</StaticValue>
    </Labeled>
  )
}

const TopographyDistanceId: React.FC<PieceFragmentElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isTopographyExpanded = React.useMemo(() => {
    return isTopographyExpandedCalc(piece);
  }, [piece]);

  if (isEntryAccounting || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ piece?.topography?.defectTpgTitleDistance ?? '' }</StaticValue>
    </React.Fragment>
  )
}


type Props = {
  variant: PieceFragmentVariants;
}
export const PieceFragment: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = piecefragment_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const piece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);

  const isPiece = piece?.isPiece ?? false;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <FormContainer lookAndFeel = { lookAndFeel }>
        <InputUEModeSwitcher lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        {!isPiece && (
          <React.Fragment>
            <QmetId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
            <Heat lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
            <Piece lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          </React.Fragment>
        )}
        {isPiece && (
          <React.Fragment>
            <Heat lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
            <Piece lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          </React.Fragment>
        )}
        <GroupAtt lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <QCNum lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <CustomFields lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Weight lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Note lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <Photo lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <IsTopography lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographyNote lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographySize lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographySizeId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographyStep lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographyStepId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographyDistance lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        <TopographyDistanceId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
      </FormContainer>
    </Container>
  );
};
