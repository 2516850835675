import styled                      from 'styled-components';

import { IconButtonVariants      } from 'src/components/common/icon-button';
import { TypographyVariants      } from 'src/components/common/typography';
import { DateLabelVariants       } from 'src/components/common/date-label';
import { LabeledContentVariants  } from 'src/components/common/labeled-content';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { cssFlexColumn,
         cssFlexRow,
         themeColors             } from 'src/providers/theme/shared';



type MessageLookAndFeel = {
  paddings: number[];
  headerHeight: number;

  backgroundColor: string;
  borderColor: string;

  expandButtonVariant: IconButtonVariants;
  dateLabelVariant: DateLabelVariants;

  labeledVariant: LabeledContentVariants;
  labeledTypographyVariant: TypographyVariants;

  ownerTypographyVariant: TypographyVariants;

  contentGap: number;

  deleteBackgroundColor: string;
  deleteColor: string;

  deleteConfirmVariant: TwoActionDialogVariants;
}

const message_light: MessageLookAndFeel = {
  paddings: [16],
  headerHeight: 16,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expandButtonVariant: 's48bluered',
  dateLabelVariant: 'h16blueredmail',

  labeledVariant: 'gray5w400s12lh14gap4',
  labeledTypographyVariant: 'blackw400s14lh20',

  ownerTypographyVariant: 'steelw400s14lh17',

  contentGap: 16,

  deleteBackgroundColor: themeColors.AccentRed1,
  deleteColor: themeColors.PrimaryWhite,

  deleteConfirmVariant: 'light',
}

export type MessageVariants = 'light';

export const message_variants: Map<MessageVariants, MessageLookAndFeel> = 
  new Map<MessageVariants, MessageLookAndFeel>([
    ['light', message_light],
  ]);

export const Wrapper = styled.div<{
  lookAndFeel: MessageLookAndFeel;
}>`
  width: 100%;
  position: relative;
  min-height: 100px;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor} !important;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
`;

export const Container = styled.div<{
  lookAndFeel: MessageLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.contentGap}px;
`

export const ExpandButtonWrapper = styled.div<{
  lookAndFeel: MessageLookAndFeel;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const MessageHeaderContainer = styled.div<{
  lookAndFeel: MessageLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight + args.lookAndFeel.contentGap}px;
  align-items: center;
  flex-shrink: 0;
`

export const MessageContentContainer = styled.div<{
  lookAndFeel: MessageLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.contentGap}px;
`
