import React from 'react';



type Props  =  {
  className?: string;
}
export const EmptyCaseUserVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '88' height = '88' viewBox = '0 0 88 88' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M10.3958 78.6732H64V71.8691L54.2105 64.5208H42.7895V53.09M42.7895 53.09L36.2632 56.356H29.7368L23.2105 53.09M42.7895 53.09L49.3158 49.8241V13.8989L41.1579 9H24.8421L16.6842 13.8989V49.8241L23.2105 53.09M2 80L2 71.8691L11.7895 64.5208H23.2105V53.09' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M64 14L86 36M64 36L72 28M86 14L75 25' />
    </svg>
  );
};
