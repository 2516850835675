import * as React                     from 'react';

import { ArrowDownVector            } from 'src/components/vector/arrow-down';
import { ArrowUpVector              } from 'src/components/vector/arrow-up';

import { IconButton                 } from 'src/components/common/icon-button';
import { Button                     } from 'src/components/common/button';
import { Typography                 } from 'src/components/common/typography';
import { LabeledContent             } from 'src/components/common/labeled-content';

import { EmailStatus                } from 'src/components/features/main/admin/email-status';
import { Container,
         ExpandButtonWrapper,
         AdminUserHeaderContainer,
         AdminUserContentContainer,
         AdminUserVariants,
         adminuser_variants         } from 'src/components/features/main/admin/admin-user/ui';

import * as types                     from 'src/services/api/types';
import Util                           from 'src/services/util';



type Props = {
  variant: AdminUserVariants;
  adminUser: types.admin.AdminElement;
  searchString: string;
  onBlock: (adminUser: types.admin.AdminElement) => void;
}
export const AdminUser: React.FC<Props> = ({
  variant,
  adminUser,
  searchString,
  onBlock,
}) => {
  const lookAndFeel = adminuser_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  let userFIO = `${adminUser.lastName} ${adminUser.firstName} ${adminUser.middleName}`;

  if (searchString.length > 0)
  {
    const lcLabel = userFIO.toLowerCase();
    const lcSearch = searchString.toLowerCase();
    if (lcLabel.includes(lcSearch))
    {
      let cursor = 0;
      let result = '';
      while (cursor <= lcLabel.length - lcSearch.length)
      {
        const windowStr = lcLabel.substring(cursor, cursor + lcSearch.length);

        if (windowStr === lcSearch)
        {
          result += `<span>${userFIO.substring(cursor, cursor + lcSearch.length)}</span>`;
          cursor += lcSearch.length;
        }
        else
        {
          result += userFIO[cursor];
          cursor += 1;
        }
      }

      result += userFIO.substring(cursor);
      userFIO = result;
    }
  }

  const emailStatus = adminUser.isEmailConfirmed ? 
    <EmailStatus variant = { lookAndFeel.emailStatusSuccessVariant } label = 'подтвержден' isSuccess />
    :
    <EmailStatus variant = { lookAndFeel.emailStatusDangerVariant } label = 'не подтвержден' />;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <AdminUserHeaderContainer
        lookAndFeel = { lookAndFeel }
        isExpanded = { expanded }
        dangerouslySetInnerHTML = { { __html: userFIO } }
      />
      {expanded && (
        <AdminUserContentContainer lookAndFeel = {lookAndFeel}>
          <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Должность'>
            <Typography variant = { lookAndFeel.labeledTypographyVariant }>
              { adminUser.post }
            </Typography>
          </LabeledContent>
          <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Телефон'>
            <Typography variant = { lookAndFeel.labeledTypographyVariant }>
              { Util.formatPhone(adminUser.phone) }
            </Typography>
          </LabeledContent>
          <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'E-mail' additionalLabelNode = { emailStatus }>
            <Typography variant = { lookAndFeel.labeledTypographyVariant }>
              { adminUser.email }
            </Typography>
          </LabeledContent>
          <Button variant = { lookAndFeel.blockButtonVariant } label = 'Заблокировать' onTap = { () => onBlock(adminUser) } />
        </AdminUserContentContainer>
      )}
      <ExpandButtonWrapper lookAndFeel = { lookAndFeel }>
        <IconButton
          variant = { lookAndFeel.expandButtonVariant }
          isTapAllowed
          onTap = { () => setExpanded(!expanded) }
        >
          { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
        </IconButton>
      </ExpandButtonWrapper>
    </Container>
  );
};
