import { createSlice,
         createAsyncThunk, 
         createSelector,    
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'main:notifications';

export interface MainNotificationsState {
  filter: types.notification.NotificationCode[];
  list: types.notification.Notification[];

  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  surveyOpened: boolean;
  surveyCaption: string;
  surveyImageUrl: string;
  surveyUrl: string;
}

const initialState: MainNotificationsState = {
  filter: [types.notification.NotificationCode.ALL],
  list: [],

  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  surveyOpened: false,
  surveyCaption: '',
  surveyImageUrl: '',
  surveyUrl: '',
}

// #region getNotificationsAsync
type GetNotificationsArgs = void;
type GetNotificationsResolve = types.notification.GetNotificationNotificationsOut;
export type GetNotificationsReject = ApiError;
export const getNotificationsAsync = createAsyncThunk<
  GetNotificationsResolve,
  GetNotificationsArgs,
  {
    state: RootState,
    rejectValue: GetNotificationsReject
  }
>(
  `${SLICE_NAME}/getNotificationsAsync`,
  async (_, thunkAPI) => {
    try
    {
      return await Api.getNotificationNotifications();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region readNotificationsAsync
type ReadNotificationsArgs = void;
type ReadNotificationsResolve = void;
export type ReadNotificationsReject = ApiError;
export const readNotificationsAsync = createAsyncThunk<
  ReadNotificationsResolve,
  ReadNotificationsArgs,
  {
    state: RootState,
    rejectValue: ReadNotificationsReject
  }
>(
  `${SLICE_NAME}/readNotificationsAsync`,
  async (_, thunkAPI) => {
    try
    {
      return await Api.postNotificationReadNotifications({
        timestamp: Date.now()
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

// #region deleteNotificationsAsync
type DeleteNotificationsArgs = void;
type DeleteNotificationsResolve = void;
export type DeleteNotificationsReject = ApiError;
export const deleteNotificationsAsync = createAsyncThunk<
  DeleteNotificationsResolve,
  DeleteNotificationsArgs,
  {
    state: RootState,
    rejectValue: DeleteNotificationsReject
  }
>(
  `${SLICE_NAME}/deleteNotificationsAsync`,
  async (_, thunkAPI) => {
    try
    {
      return await Api.postNotificationDelNotifications();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

// #region openSurveyAsync
type OpenSurveyArgs = number;
type OpenSurveyResolve = {
  caption: string;
  url: string;
  imageUrl: string;
};
export type OpenSurveyReject = string;
export const openSurveyAsync = createAsyncThunk<
  OpenSurveyResolve,
  OpenSurveyArgs,
  {
    state: RootState,
    rejectValue: OpenSurveyReject
  }
>(
  `${SLICE_NAME}/openSurveyAsync`,
  async (surveyId, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainNotificationsActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainNotificationsActions.opStatusLabelChanged('Получение опроса'));

      const advs = await Api.getAdv();
      const survey = advs.find((item) => item.isSurvey && item.id === -surveyId) ?? null;

      if (survey === null)
      {
        thunkAPI.dispatch(mainNotificationsActions.opStatusChanged(AsyncOpStatus.ERROR));
        return thunkAPI.rejectWithValue('Опрос или уже пройден или завершен');
      }

      thunkAPI.dispatch(mainNotificationsActions.opStatusLabelChanged('Получение изображения опроса'));

      const surveyFile = await Api.getAdvFileById({ id: survey.idFile });

      thunkAPI.dispatch(mainNotificationsActions.opStatusChanged(AsyncOpStatus.SUCCESS));

      return {
        caption: survey.title,
        url: survey.advLink,
        imageUrl: surveyFile.dataUrl
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue('При обращении к серверу произошла ошибка');
    }
  }
);
// #endregion

type FilterChangedAction = PayloadAction<types.notification.NotificationCode[]>;
type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

export const mainNotificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    filterChanged: (state, action: FilterChangedAction) => {
      state.filter = action.payload;
    },
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
    surveyClosed: (state) => {
      state.surveyCaption = '';
      state.surveyImageUrl = '';
      state.surveyUrl = '';
      state.surveyOpened = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        state.list = action.payload.sort((a, b) => a.timestamp === b.timestamp ? 0 : (a.timestamp > b.timestamp ? -1 : 1));
      })
      .addCase(readNotificationsAsync.fulfilled, (state) => {
        state.list = state.list.map((item) => ({ ...item, isRead: true }));
      })
      .addCase(deleteNotificationsAsync.fulfilled, (state) => {
        state.list = state.list.filter((item) => !item.isRead);
      })
      .addCase(openSurveyAsync.fulfilled, (state, action) => {
        state.surveyOpened = true;
        state.surveyCaption = action.payload.caption;
        state.surveyImageUrl = action.payload.imageUrl;
        state.surveyUrl = action.payload.url;
      });
  },
})

const selectFilter = (state: RootState) => state.mainNotifications.filter;
const selectRawList = (state: RootState) => state.mainNotifications.list;
const selectOpStatus = (state: RootState) => state.mainNotifications.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainNotifications.opStatusLabel;
const selectSurveyOpened = (state: RootState) => state.mainNotifications.surveyOpened;
const selectSurveyCaption = (state: RootState) => state.mainNotifications.surveyCaption;
const selectSurveyImageUrl = (state: RootState) => state.mainNotifications.surveyImageUrl;
const selectSurveyUrl = (state: RootState) => state.mainNotifications.surveyUrl;
const selectFilteredList = createSelector(
  [selectFilter, selectRawList],
  (filter, rawList) => {
    return rawList.filter((item) => filter.includes(item.code) || filter.includes(types.notification.NotificationCode.ALL));
  }
);
const selectHasUnreadNotifications = createSelector(
  [selectRawList],
  (rawList) => {
    return rawList.some((item) => !item.isRead)
  }
);
const selectHasReadNotifications = createSelector(
  [selectRawList],
  (rawList) => {
    return rawList.some((item) => item.isRead)
  }
);

export const mainNotificationsSelectors = {
  selectFilter,
  selectRawList,
  selectOpStatus,
  selectOpStatusLabel,
  selectSurveyOpened,
  selectSurveyCaption,
  selectSurveyImageUrl,
  selectSurveyUrl,
  selectFilteredList,
  selectHasUnreadNotifications,
  selectHasReadNotifications,
}

export const mainNotificationsActions = mainNotificationsSlice.actions;

export default mainNotificationsSlice.reducer;