import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export type Problem8dQuestion = {
  /** Идентификатор вопроса */
  problem8d_questions_id: number;
  /** Идентификатор проблемы */
  problem8d_id: number;
  /** GUID */
  guid_question: string;
  /** Идентификатор пользователя (клиента) */
  ca_register_id: number;
  /** Текст вопроса */
  message: string;
  /** Дата создания вопроса, временная метка (timestamp) UTC+0 в миллисекундах */
  date_message: number;
  /** Флаг отправки email уведомления */
  ind_send: boolean;
  /** ФИО клиента (по одному предприятию вопросы могут задавать разные клиенты) */
  fio: string;
  /** Должность клиента */
  post: string;
}

export type Problem8dEskCode = {
  /** Код ESK */
  eskCode: number;
  /** Название организации */
  title: string;
}

export type Problem8dDataCompany = {
  /** Код ESK */
  eskCode: number;
  /** Название организации */
  title: string;
  /** Список проблем 8D */
  problems: Array<{
    /** Идентификатор проблемы */
    problem8dId: number;
    /** Номер CRM проблемы */
    problemNumber: string;
    /** Список номеров уведомлений из CRM */
    notifyNumbers: Array<{
      /** Идентификатор уведомления */
      notifyId: number;
      /** Номер уведомления */
      notifyNumber: string;
    }>;
    /** Идентификатор осмотра, если изначально данная проблема пришла из Checksteel */
    auditsId: Nullable<number>;
    /** Идентификатор дефекта */
    refDefectSapId: number;
    /** Идентификатор марки стали */
    refSteelBrandSapId: number;
    /** Идентификатор продукта */
    refProductSapId: number;
    /** Идентификатор шага 8D (null - не отображаем) */
    refStep8dId: Nullable<number>;
    /** Идентификатор статуса */
    refStatus8dId: number;
    /** Текущее состояние 6D */
    action6d: 'awaiting' | 'approved' | 'comeback';
    /** Флаг повторения проблемы */
    isRepeat: boolean;
    /** Информация об оценке */
    rating8d: {
      /** Оценка, 0 - не оценено */
      rating: number;
      /** Примечание к оценке */
      noteRating: Nullable<string>;
    };
    /** Список мероприятий */
    events: Array<{
      /** Тип сообщения: ZM01 - быстрые мероприятия, ZM02 - системные мероприятия */
      type: string;
      /** Текст мероприятия */
      text: string;
    }>;
    /** Флаг избранного */
    isFavorite: boolean;
    /** Дата последнего QM-сообщения, добавленного к проблеме (вычисляется на сервере), временная метка (timestamp) UTC+0 в миллисекундах */
    lastQMTimestamp: number;
    /** Код ESK */
    eskCode: number;
    /** workgroup */
    workgroup: string;
  }>
}

export type RefDefectsSap = {
  /** Идентификатор дефекта */
  refDefectSapId: number;
  /** Название дефекта */
  defectName: string;
}

export type RefSteelBrandSap = {
  /** Идентификатор марки стали */
  refSteelBrandSapId: number;
  /** Название марки стали */
  steelBrandName: string;
}

export type RefProductSap = {
  /** Идентификатор продукта */
  refProductSapId: number;
  /** Название продукта */
  productName: string;
}

export type RefStep8D = {
  /** Идентификатор шага */
  refStep8dId: number;
  /** Код шага */
  code: string;
  /** Название шага */
  title: string;
  /** Описание шага */
  description: string;
  /** История */
  historyAction: string;
}

export type RefStatus8D	= {
  /** Идентификатор статуса */
  refStatus8dId: number;
  /** Название статуса */
  title: string;
  /** Сообщение по статусу */
  message: Nullable<string>;
}

export type Problem8dData = {
  /** Список проблем по компаниям */
  company: Array<Problem8dDataCompany>;
  /** Список дефектов */
  refDefectsSap: Array<RefDefectsSap>;
  /** Список марки стали */
  refSteelBrandSap: Array<RefSteelBrandSap>;
  /** Список продукции */
  refProductSap: Array<RefProductSap>;
  /** Список шагов 8D */
  refStep8D: Array<RefStep8D>;
  /** Список статусов */
  refStatus8D: Array<RefStatus8D>;
  /** Флаг наличия незагруженных данных */
  isMoreDataAvailable: boolean;
}

// #endregion


// #region PUT 8d/repeat/{problem8d_id}

export type Put8dRepeatByIdIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
}

export const preparePut8dRepeatById
  : PrepareCallHandler<Put8dRepeatByIdIn, void> = (arg: Put8dRepeatByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/repeat/${arg.problem8d_id}`,
      method: 'PUT',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST 8d/rating

export type Post8dRatingIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
  /** Оценка от 1 до 5 */
  rating: number;
  /** Комментарий к оценке */
  note_rating: string;
}

export const preparePost8dRating
  : PrepareCallHandler<Post8dRatingIn, void> = (arg: Post8dRatingIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/rating`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET 8d/question/{problem8d_id}

export type Get8dQuestionByIdIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
}

export type Get8dQuestionByIdOut = Array<Problem8dQuestion>;

export const prepareGet8dQuestionById
  : PrepareCallHandler<Get8dQuestionByIdIn, Get8dQuestionByIdOut> = (arg: Get8dQuestionByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/question/${arg.problem8d_id}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST 8d/question

export type Post8dQuestionIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
  /** GUID */
  guid_question: string;
  /** Текст вопроса */
  message: string;
  /** Дата создания вопроса, временная метка (timestamp) UTC+0 в миллисекундах */
  date_message: number;
}

export const preparePost8dQuestion
  : PrepareCallHandler<Post8dQuestionIn, void> = (arg: Post8dQuestionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/question`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region PUT 8d/favorite

export type Put8dFavoriteIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
  /** Флаг избранной проблемы */
  ind_favorite: boolean;
  /** Дата добавления проблемы в избранное, временная метка (timestamp) UTC+0 в миллисекундах */
  timestamp: number;
}

export const preparePut8dFavorite
  : PrepareCallHandler<Put8dFavoriteIn, void> = (arg: Put8dFavoriteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/favorite`,
      method: 'PUT',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET 8d/esk

export type Get8dEskIn = void;

export type Get8dEskOut = Array<Problem8dEskCode>;

export const prepareGet8dEsk
  : PrepareCallHandler<Get8dEskIn, Get8dEskOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/esk`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST 8d/data

export type Post8dDataIn = {
  /** Список кодов ESK через разделитель ',' */
  eskCode: Nullable<string>
  /** Идентификатор проблемы */
  problem8dId: Nullable<number>;
  /** Идентификатор уведомления */
  notifyNumber: Nullable<string>;
  /** Идентификатор дефекта, SAP */
  refDefectSapId: Nullable<number>;
  /** Идентификатор марки стали, SAP */
  refSteelBrandSapId: Nullable<number>;
  /** Идентификатор продукта, SAP */
  refProductSapId: Nullable<number>;
  /** Список статусов, разделитель ',' */
  status: Nullable<string>;
  /** Дата последнего QM-сообщения, добавленного к проблеме, временная метка (timestamp) UTC+0 в миллисекундах */
  lastQMTimestamp: Nullable<number>;
}

export const preparePost8dData
  : PrepareCallHandler<Post8dDataIn, Problem8dData> = (arg: Post8dDataIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/data`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET 8d/data/favorite

export type Get8dDataFavoriteIn = {
  /** Список кодов ESK через разделитель ',' */
  eskCode: string
}

export const prepareGet8dDataFavorite
  : PrepareCallHandler<Get8dDataFavoriteIn, Problem8dData> = (arg: Get8dDataFavoriteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/data/favorite?eskCode=${encodeURIComponent(arg.eskCode)}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region PUT 8d/action

export type Put8dActionIn = {
  /** Идентификатор проблемы */
  problem8d_id: number;
  /** Текущее состояние */
  action: 'awaiting' | 'approved' | 'comeback';
}

export const preparePut8dAction
  : PrepareCallHandler<Put8dActionIn, void> = (arg: Put8dActionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/8d/action`,
      method: 'PUT',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
