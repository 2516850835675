import * as React                from 'react';

import { CloseVector           } from 'src/components/vector/close';

import { IconButton,
         IconButtonVariants    } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants     } from 'src/components/common/input/input-base';
import { Input,
         Prefix,
         IconPrefix,
         InputTextboxVariants,
         inputtextbox_variants } from 'src/components/common/input/input-textbox/ui';

import Util                      from 'src/services/util';

import { voidFunction          } from 'src/common';



const inputBaseVariantMap: Map<InputTextboxVariants, InputBaseVariants> = 
  new Map<InputTextboxVariants, InputBaseVariants>([
    ['h48light', 'h48light']
  ]);

const iconButtonVariantMap: Map<InputTextboxVariants, IconButtonVariants> = 
  new Map<InputTextboxVariants, IconButtonVariants>([
    ['h48light', 's46bluered']
  ]);

type Props = {
  variant: InputTextboxVariants;
  isError?: boolean;
  isDisabled?: boolean;
  isClearHidden?: boolean;
  hint?: string;
  maxLength?: number;
  prefix?: string;
  iconPrefix?: React.ReactNode;
  value: string;
  customIcon?: React.ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: "email" | "search" | "tel" | "text" | "url" | "none" | "numeric" | "decimal";
  valueTransformer?: (value: string) => string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  onCustomIconTap?: () => void;
}
export const InputTextbox: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  isClearHidden = false,
  hint,
  maxLength,
  prefix,
  iconPrefix,
  value,
  customIcon,
  placeholder = '',
  readOnly = false,
  type = 'text',
  inputMode = 'text',
  valueTransformer = (value: string) => value,
  onChange,
  onFocus = voidFunction,
  onCustomIconTap = voidFunction,
}) => {
  const lookAndFeel = inputtextbox_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(valueTransformer(evt.target.value));
  }

  const onClearHandler = () => {
    onChange('');
  }

  return (
    <InputBase variant = { inputBaseVariant } isError = { isError } isDisabled = { isDisabled } hint = { hint }>
      { prefix !== undefined && (
        <Prefix lookAndFeel = { lookAndFeel } isDisabled = { isDisabled }>{ prefix }</Prefix>
      )}
      { iconPrefix !== undefined && (
        <IconPrefix className = 'prefix-icon' lookAndFeel = { lookAndFeel } isDisabled = { isDisabled }>{ iconPrefix }</IconPrefix>
      )}
      <Input
        lookAndFeel = { lookAndFeel }
        isDisabled = { isDisabled }
        disabled = { isDisabled }
        maxLength = { maxLength }
        value = { value }
        placeholder = { placeholder }
        readOnly = { readOnly }
        type = { type }
        inputMode = { inputMode }
        onChange = { onChangeHandler }
        onFocus = { onFocus }
      />
      { customIcon === undefined && value.trim().length > 0 && !isDisabled && !isClearHidden && (
        <IconButton variant = { iconButtonVariant } isDot = { false } isTapAllowed onTap = { onClearHandler }>
          <CloseVector />
        </IconButton>
      )}
      { customIcon !== undefined && !isDisabled && (
        <IconButton variant = { iconButtonVariant } isTapAllowed = { !isDisabled && onCustomIconTap !== voidFunction } isDot = { false } onTap = { onCustomIconTap }>
          { customIcon }
        </IconButton>
      )}
    </InputBase>
  );
};

export const OnlyDigits = (value: string): string => {
  return Util.selectDigitsFromString(value);
}

export const LatinAlphaNumericUpperCase = (value: string): string => {
  return Util.selectLatinAlphaNumbericFromString(value).toUpperCase();
}