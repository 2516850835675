import * as React           from 'react';

import { useTheme         } from 'styled-components';

import { Grow             } from 'src/components/common/flex/grow';
import { Dialog           } from 'src/components/common/dialog';
import { OneActionDialog  } from 'src/components/common/one-action-dialog';

import { AppCaption       } from 'src/components/features/auth/common/app-caption';
import { Pin              } from 'src/components/features/auth/common/pin';

import { PINCreateLayout,
         Screen,
         layout_light,
         layout_dark      } from 'src/containers/auth/pin-create/layout';

import { Urls             } from 'src/providers/routing';

import Constants            from 'src/services/constants';
import Preferences          from 'src/services/preferences';
import Api                  from 'src/services/api';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi         } from 'src/store';



type PinCreateScreenElement = { layout: PINCreateLayout; dispatch: AppDispatch; }

const Caption: React.FC<PinCreateScreenElement> = ({ layout }) => {
  return (
    <AppCaption 
      variant = { layout.appCaptionVariant }
      caption = 'Checksteel'
      version = { `v.${Constants.APP_VERSION}` }
    />
  )
}

const PinInput: React.FC<PinCreateScreenElement> = ({ layout, dispatch }) => {
  const [matched, setMatched] = React.useState<boolean>(false);
  const [mismatched, setMismatched] = React.useState<boolean>(false);

  const label = useAppSelector(storeApi.auth.pinCreate.selectors.selectLabel);
  const step = useAppSelector(storeApi.auth.pinCreate.selectors.selectStep);
  const code = useAppSelector(storeApi.auth.pinCreate.selectors.selectCode);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount);

  const onPinCompleteHandler = async (pin: string) => {
    if (step === 1)
    {
      dispatch(storeApi.auth.pinCreate.actions.codeChanged(pin));
    }
    else
    {
      if (pin === code)
      {
        Preferences.encryptAndStoreAccount(account!, pin);
        Api.setupJwt(account!.jwtRefresh, account!.jwtAccess);
        setMatched(true);
      }
      else
      {
        setMismatched(true);
      }
    }
  }

  const onMatchTapHandler = () => {
    dispatch(storeApi.auth.phoneEnter.actions.reset());
    dispatch(storeApi.auth.pinCreate.actions.reset());
    dispatch(storeApi.auth.pinEnter.actions.reset());
    dispatch(storeApi.auth.pwdReset.actions.reset());
    dispatch(storeApi.auth.regEnterData.actions.reset());
    dispatch(storeApi.auth.regRequestCode.actions.formCleared());
    dispatch(storeApi.auth.regStart.actions.formCleared());
    dispatch(storeApi.auth.smsCode.actions.reset());
    setMatched(false);
    Preferences.setAppAdvPriorityShow();
    Urls.MainInspections.build().navigateWithReplace();
  }

  const onMismatchTapHandler = () => {
    setMismatched(false);
  }

  return (
    <React.Fragment>
      <Grow size = { 2 } />
      <Pin
        variant = { layout.pinVariant }
        label = { label }
        onPinComplete = { onPinCompleteHandler }
      />
      <Grow size = { 2 } />
      <Dialog variant = { layout.dialogVariant } isOpened = { matched }>
        <OneActionDialog
          variant = { layout.pinCheckDialogVariant }
          caption = 'ПИН-код успешно создан'
          actionLabel = 'Продолжить'
          onAction = { onMatchTapHandler }
        />
      </Dialog>
      <Dialog variant = { layout.dialogVariant } isOpened = { mismatched }>
        <OneActionDialog
          variant = { layout.pinCheckDialogVariant }
          caption = 'ПИН-код не совпадает'
          actionLabel = 'Попробовать снова'
          onAction = { onMismatchTapHandler }
        />
      </Dialog>
    </React.Fragment>
  )
}

export const PINCreateScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  console.log(window.history);

  return (
    <Screen layout = { layout }>
      <Caption layout = { layout } dispatch = { dispatch } />
      <PinInput layout = { layout } dispatch = { dispatch } />
    </Screen>
  );
};
