import * as React                 from 'react';
 
import { useAlert               } from 'react-alert';

import { useTheme               } from 'styled-components';

import { useParams              } from 'react-router-dom';
 
import { ArrowBackVector        } from 'src/components/vector/arrowback';
import { PlusVector             } from 'src/components/vector/plus';

import { BlockUI                } from 'src/components/common/block-ui';
import { InputCalendar          } from 'src/components/common/input/input-calendar';
import { EmptyCaseLoupe         } from 'src/components/common/empty-case';
import { EmptyCaseEnvelope      } from 'src/components/common/empty-case';
import { FAB                    } from 'src/components/common/fab';
import { Dialog                 } from 'src/components/common/dialog';
import { OneActionDialog        } from 'src/components/common/one-action-dialog';

import { NavBar                 } from 'src/components/features/common/nav-bar';
import { Toolbar                } from 'src/components/features/main/common/toolbar';

import { Message                } from 'src/components/features/main/messages/message';
import { AddMessage             } from 'src/components/features/main/messages/add-message';
import { MessageDeleteGuide     } from 'src/components/features/main/messages/message-delete-guide';

import { MessagesLayout,
         Screen,
         ScreenBody,
         FilterContainer,
         MessagesContainer,
         FABContainer,
         layout_light,
         layout_dark            } from 'src/containers/main/messages/layout';

import { Urls                   } from 'src/providers/routing';

import Api                        from 'src/services/api';
import * as types                 from 'src/services/api/types';
import Util                       from 'src/services/util';
import Preferences                from 'src/services/preferences';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';
import { GetMessagesListReject, 
         AddMessageReject,
         DeleteMessageReject    } from 'src/store/main/messages';

import { AsyncOpStatus          } from 'src/common';



type MessagesScreenElement = { layout: MessagesLayout; dispatch: AppDispatch; }

const Navigation: React.FC<MessagesScreenElement> = ({ layout, dispatch }) => {
  const inspectionNum = useAppSelector(storeApi.main.messages.selectors.selectInspectionNum);

  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = { inspectionNum === '' ? 'Сообщения' : `Сообщения по осмотру №${inspectionNum}`}
        startButtons={
          inspectionNum === '' ?
            undefined
            :
            [
              {
                id: 'back',
                vector: <ArrowBackVector />,
                action: () => Urls.MainInspections.build().navigate()
              }
            ]
        }
      />
    </React.Fragment>
  )
}

const Filter: React.FC<MessagesScreenElement> = ({ layout, dispatch }) => {
  const start = useAppSelector(storeApi.main.messages.selectors.selectTimestampBeg);
  const end = useAppSelector(storeApi.main.messages.selectors.selectTimestampEnd);

  return (
    <FilterContainer layout={layout}>
      <InputCalendar
        variant = { layout.inputCalendarVariant }
        value = { start ? new Date(start) : null }
        onChange = { (value) => dispatch(storeApi.main.messages.actions.timestampBegChanged(value ? Util.beginDay(value).valueOf() : null)) }
      />
      <InputCalendar
        variant = { layout.inputCalendarVariant }
        value = { end ? new Date(end) : null }
        onChange = { (value) => dispatch(storeApi.main.messages.actions.timestampEndChanged(value ? Util.endDay(value).valueOf() : null)) }
      />
    </FilterContainer>
  )
}

const AddMessageBottomSheet: React.FC<MessagesScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const addOpened = useAppSelector(storeApi.main.messages.selectors.selectAddOpened);
  const inspectionNum = useAppSelector(storeApi.main.messages.selectors.selectInspectionNum);
  const topic = useAppSelector(storeApi.main.messages.selectors.selectTopic);
  const content = useAppSelector(storeApi.main.messages.selectors.selectContent);

  const onAddHandler = () => {
    dispatch(storeApi.main.messages.async.addMessageAsync())
      .unwrap()
      .then((result) => {
        onCloseHandler();
        dispatch(storeApi.main.messages.actions.addConfirmOpenedChanged(true));
      })
      .catch((rawError) => {
        const error = rawError as AddMessageReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onCloseHandler = () => {
    dispatch(storeApi.main.messages.actions.topicChanged(''));
    dispatch(storeApi.main.messages.actions.contentChanged(''));
    dispatch(storeApi.main.messages.actions.addOpenedChanged(false));
  }

  return (
    <AddMessage
      variant = { layout.addMessageVariant }
      isOpened = { addOpened }
      topic = { inspectionNum === '' ? topic : `Сообщение по осмотру №${inspectionNum}` }
      isTopicFreeze = { inspectionNum !== '' }
      content = { content }
      onTopicChange = { (value: string) => dispatch(storeApi.main.messages.actions.topicChanged(value)) }
      onContentChange = { (value: string) => dispatch(storeApi.main.messages.actions.contentChanged(value)) }
      onAdd = { onAddHandler }
      onClose = { onCloseHandler }
    />
  )
}

const MessagesList: React.FC<MessagesScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const { inspectionNum } = useParams();

  const count = useAppSelector(storeApi.main.messages.selectors.selectListCount);
  const list = useAppSelector(storeApi.main.messages.selectors.selectFilteredList);
  const opStatus = useAppSelector(storeApi.main.messages.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.messages.selectors.selectOpStatusLabel);

  const getListTaskExecuted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (inspectionNum !== undefined)
    {
      dispatch(storeApi.main.messages.actions.inspectionNumChanged(inspectionNum === 'all' ? '' : inspectionNum));
    }
  }, [inspectionNum]);

  React.useEffect(() => {
    if (getListTaskExecuted.current)
    {
      return;
    }

    getListTaskExecuted.current = true;
    dispatch(storeApi.main.messages.async.getMessagesListAsync())
      .unwrap()
      .then((result) => {
        getListTaskExecuted.current = false;
      })
      .catch((rawError) => {
        getListTaskExecuted.current = false;
        const error = rawError as GetMessagesListReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }, []);

  const onMessageDeleteHandler = (message: types.message.Message) => {
    dispatch(storeApi.main.messages.async.deleteMessageAsync(message.guid))
      .unwrap()
      .then((result) => {
        alert.success('Сообщение успешно удалено');
      })
      .catch((rawError) => {
        const error = rawError as DeleteMessageReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  return (
    <React.Fragment>
      {count === 0 && (
        <EmptyCaseEnvelope
          variant = { layout.emptyCaseVariant }
          header = 'Сообщения не созданы'
        />
      )}
      {count > 0 && list.length === 0 && (
        <EmptyCaseLoupe
          variant = { layout.emptyCaseVariant }
          header = 'Ничего не найдено'
          message = 'Попробуйте изменить поисковый запрос'
        />
      )}
      {list.length > 0 &&
        <MessagesContainer layout = { layout }>
          {list.map((item) => (
            <Message
              key = { item.guid }
              variant = { layout.messageVariant }
              message = { item }
              onDelete = { onMessageDeleteHandler }
            />
          ))}
        </MessagesContainer>
      }
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
    </React.Fragment>
  )
}

export const MessagesScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const [messageDeleteGuideShow, setMessageDeleteGuideShow] = React.useState<boolean>(
    (Preferences.messageDeleteGuideShowCount + 1) === 5
    ||
    (
      (Preferences.messageDeleteGuideShowCount + 1) > 5
      &&
      (Preferences.messageDeleteGuideShowCount + 1) % 10 === 0
      &&
      (Preferences.messageDeleteGuideShowCount + 1) <= 30
    )
  );

  const inspectionNum = useAppSelector(storeApi.main.messages.selectors.selectInspectionNum);
  const addConfirmOpened = useAppSelector(storeApi.main.messages.selectors.selectAddConfirmOpened);
  const isAllMessages = inspectionNum === '';

  const onFABTapHandler = () => {
    dispatch(storeApi.main.messages.actions.addOpenedChanged(true))
  }

  React.useEffect(() => {
    const deffer = setTimeout(
      () => {
        if (Preferences.messageDeleteGuideShowCount < 31)
        {
          Preferences.setMessageDeleteGuideShowCount(Preferences.messageDeleteGuideShowCount + 1);
        }
      },
      500
    );

    return () => clearTimeout(deffer);
  }, []);

  const onMessageDeleteGuideCloseHandler = (noMoreShow: boolean) => {
    setMessageDeleteGuideShow(false);

    if (noMoreShow)
    {
      Preferences.setMessageDeleteGuideShowCount(31);
    }
  }

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        {isAllMessages && 
          <Filter layout = { layout } dispatch = { dispatch } />
        }
        <MessagesList layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
      {isAllMessages &&
        <Toolbar variant = { layout.toolbarVariant } activeItem = 'message' />
      }
      <FABContainer layout = { layout } withToolbar = { isAllMessages }>
        <FAB variant = { layout.fabVariant } isTapAllowed onTap = { onFABTapHandler }>
          <PlusVector />
        </FAB>
      </FABContainer>
      <AddMessageBottomSheet layout = { layout } dispatch = { dispatch } />
      <Dialog variant = { layout.dialogVariant } isOpened = { addConfirmOpened }>
        <OneActionDialog
          variant = { layout.oneActionDialogVariant }
          caption = 'Спасибо за обращение!'
          message = 'Сообщение взято в работу. Ожидайте ответ на ваш e-mail в течение 24 часов'
          actionLabel = 'Продолжить'
          onAction = { () => dispatch(storeApi.main.messages.actions.addConfirmOpenedChanged(false)) }
        />
      </Dialog>
      {messageDeleteGuideShow && 
        <MessageDeleteGuide variant = { layout.messageDeleteGuideVariant } onClose = { onMessageDeleteGuideCloseHandler } />
      }
    </Screen> 
  );
};
