import * as React              from 'react';

import { Button              } from 'src/components/common/button';
import { Rating              } from 'src/components/common/rating';
import { VGap                } from 'src/components/common/flex/vgap';

import { Container, 
         Caption,
         Note,
         RateInlineVariants,
         rateinline_variants } from 'src/components/features/qmmsg/detail/rate-inline/ui';



type Props = {
  variant: RateInlineVariants;
  rating: number;
  onChangeRating: (rating: number) => void;
  onSend: () => void;
}
export const RateInline: React.FC<Props> = ({
  variant,
  rating,
  onChangeRating,
  onSend,
}) => {
  const lookAndFeel = rateinline_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Оценка процесса
      </Caption>
      <Rating
        variant = { lookAndFeel.ratingVariant }
        isHideLabel
        value = { rating }
        onRating = { onChangeRating } 
      />
      <Note lookAndFeel = { lookAndFeel }>
        Пожалуйста, оцените насколько в целом вы остались довольны всем процессом — от момента подачи уведомления до получения решения или оплаты от СВС 
      </Note>
      <VGap size = { lookAndFeel.sendButtonTopPadding } />
      <Button
        variant = { lookAndFeel.sendButtonVariant }
        label = 'Отправить оценку'
        isDisabled = { rating === -1 }
        onTap = { onSend }
      />
    </Container>
  );
};
