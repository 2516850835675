import * as React          from 'react';

import { BackspaceVector } from 'src/components/vector/backspace';

import { Container, 
         Header, 
         CodeContainer, 
         CodeCell, 
         CodeCellBall, 
         CodeCellDigit,
         DigitContainer,
         DigitRow,
         DigitCell,
         ActionCell,
         PinVariants,
         pin_variants    } from 'src/components/features/auth/common/pin/ui';



type Props = {
  variant: PinVariants;
  label: string;
  onPinComplete: (code: string) => Promise<void>;
}
export const Pin: React.FC<Props> = ({
  variant,
  label,
  onPinComplete,
}) => {
  const [active, changeActive] = React.useState<boolean>(true);
  const [code, changeCode] = React.useState<string>('');
  const [template, changeTemplate] = React.useState<string>('----');
  const [pressed, changePressed] = React.useState<number[]>([0,0,0,0,0,0,0,0,0,0,0]);

  const lookAndFeel = pin_variants.get(variant)!;

  const onDigitSelect = async (digit: number) => {
    if (active) 
    {
      const newCode = `${code}${digit}`;
      const newTemplate = template
        .split('')
        .map((char, index) => index === newCode.length - 1 ? `${digit}` : char)
        .join('');

      changeCode(newCode);
      changeTemplate(newTemplate);

      changePressed(pressed.map((item, index) => index === digit ? 1 : item));
      changeActive(false);

      setTimeout(async () => {
        if (newCode.length < 4) 
        {
          changeActive(true);
        }
        
        changePressed(pressed.map((item, index) => index === digit ? 0 : item));
        if (newTemplate[newCode.length - 1] !== '-')
        {
          const newTemplate2 = template
            .split('')
            .map((char, index) => index === newCode.length - 1 ? '*' : char)
            .join('');
          changeTemplate(newTemplate2);
        }

        if (newCode.length === 4)
        {
          changeActive(false);
          await onPinComplete(newCode);
          changeActive(true);
          changeCode('')
          changeTemplate('----');
        }
      }, 200)
    }
  }

  const onBackspace = () => {
    if (active && code.length > 0) {
      const newCode = code.substring(0, code.length - 1);
      changeCode(newCode);
      changeTemplate(
        template
          .split('')
          .map((char, index) => index < newCode.length ? char : '-')
          .join('')
      );
      changePressed(pressed.map((item, index) => index === 10 ? 1 : item));
      setTimeout(() => {
        changePressed(pressed.map((item, index) => index === 10 ? 0 : item));
      }, 200)
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Header lookAndFeel = { lookAndFeel }>{ label }</Header>
      <CodeContainer lookAndFeel = { lookAndFeel }>
        { template.split('').map((char, index) => (
          <CodeCell key = { index } lookAndFeel = { lookAndFeel }>
            { char === '-' && <CodeCellBall lookAndFeel = { lookAndFeel } /> }
            { char === '*' && <CodeCellBall lookAndFeel = { lookAndFeel } className='active' /> }
            { !['-','*'].includes(char) && <CodeCellDigit lookAndFeel = { lookAndFeel }>{ char }</CodeCellDigit> }
          </CodeCell>
        ))}
      </CodeContainer>
      <DigitContainer lookAndFeel = { lookAndFeel }>
        <DigitRow lookAndFeel = { lookAndFeel }>
          <DigitCell 
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(1) }
            className = { pressed[1] === 1 ? 'active' : '' }
          >1</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(2) }
            className = { pressed[2] === 1 ? 'active' : '' }
          >2</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(3) }
            className = { pressed[3] === 1 ? 'active' : '' }
          >3</DigitCell>
        </DigitRow>
        <DigitRow lookAndFeel = { lookAndFeel }>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(4) }
            className = { pressed[4] === 1 ? 'active' : '' }
          >4</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(5) }
            className = { pressed[5] === 1 ? 'active' : '' }
          >5</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(6) }
            className = { pressed[6] === 1 ? 'active' : '' }
          >6</DigitCell>
        </DigitRow>
        <DigitRow lookAndFeel = { lookAndFeel }>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(7) }
            className = { pressed[7] === 1 ? 'active' : '' }
          >7</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(8) }
            className = { pressed[8] === 1 ? 'active' : '' }
          >8</DigitCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(9) }
            className = { pressed[9] === 1 ? 'active' : '' }
          >9</DigitCell>
        </DigitRow>
        <DigitRow lookAndFeel = { lookAndFeel }>
          <ActionCell lookAndFeel = { lookAndFeel }></ActionCell>
          <DigitCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onDigitSelect(0) }
            className = { pressed[0] === 1 ? 'active' : '' }
          >0</DigitCell>
          <ActionCell
            lookAndFeel = { lookAndFeel }
            onClick = { () => onBackspace() }
            className = { `backspace${pressed[10] === 1 ? ' active' : ''}` }
          ><BackspaceVector /></ActionCell>
        </DigitRow>
      </DigitContainer>
    </Container>
  );
};
