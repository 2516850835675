import React from 'react';



type Props = {
  className?: string;
}
export const DefectErrorVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '16' viewBox = '0 0 16 16' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M8 2L8 10'/>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M8 12L8 14'/>
    </svg>
  );
};
