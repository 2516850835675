import React                    from 'react';

import { createBrowserRouter, 
         RouterProvider       } from 'react-router-dom';

import { AuthUrls, 
         AuthRoutes           } from 'src/providers/routing/auth';
import { MainUrls, 
         MainRoutes           } from 'src/providers/routing/main';
import { QMMsgUrls, 
         QMMsgRoutes          } from 'src/providers/routing/qmmsg';
import { InspectionUrls, 
         InspectionRoutes     } from 'src/providers/routing/inspection';



export const Urls = {
  ...AuthUrls,
  ...MainUrls,
  ...QMMsgUrls,
  ...InspectionUrls,
}

const router = createBrowserRouter([
  ...AuthRoutes,
  ...MainRoutes,
  ...QMMsgRoutes,
  ...InspectionRoutes,
]);

export const AppRouterProvider = () => {
  return <RouterProvider router={router} />;
}