import { createSlice,
         createAsyncThunk,  
         PayloadAction,
         ActionReducerMapBuilder,
         createSelector           } from '@reduxjs/toolkit';
import { WritableDraft            } from 'immer';

import Preferences                  from 'src/services/preferences';
import Api,
       { ApiError                 } from 'src/services/api';
import * as types                   from 'src/services/api/types';
import Db                           from 'src/services/db';
import Util                         from 'src/services/util';
import Constants                    from 'src/services/constants';
import { InspectionState,
         ComplaintType,
         ComplaintMode,            
         QCState,                     
         InspectionStatusCode,        
         PieceMode,                   
         PieceFileMode,
         DefectFileMode,
         DefectFileType,          
         DefectMode,               
         PieceFileType            } from 'src/services/api/types/inspection';
import { AccountRole              } from 'src/services/api/types/auth';

import { RootState                } from 'src/store';
import { authCommonSelectors      } from 'src/store/auth/common';

import { AsyncOpStatus,
         Nullable                 } from 'src/common';



const SLICE_NAME = 'inspection:edit';

type ExtraReducer = (builder: ActionReducerMapBuilder<InspectionEditState>) => void;
const extraReducers: ExtraReducer[] = [];

export interface InspectionEditState {
  generalOpStatus: AsyncOpStatus;
  generalOpStatusLabel: string;
  generalSelectModeOpened: boolean;

  generalAccount: Nullable<types.auth.Account>;
  generalInspectionState: InspectionState;
  generalOriginal: Nullable<types.inspection.Inspection>;
  generalEdited: Nullable<types.inspection.Inspection>;
  generalIsEntryAccounting: boolean;
  generalIsTransferOnly: boolean;
  generalIsChangeToComplaint: boolean;
  generalIsChangeToComplaintFinance: boolean;
  generalIsGroup: boolean;
  generalIsExistComplaint: boolean;
  generalIsNoTrans: boolean;

  generalPlaceOfFixationRefer: types.refers.PlaceOfFixationReferElement[];
  generalDefectRefer: types.refers.DefectReferElement[];
  generalFavoriteDefects: number[];
  generalWaysToUseRegectedRefer: types.refers.WaysToUseRegectedReferElement[];
  generalTopographyRefer: types.refers.DefectTopographyReferElement[];
  generalProductRefer: types.refers.ProductReferElement[];
  generalUnitRefer: types.refers.UnitReferElement[];
  generalPriorityRefer: types.refers.PriorityReferElement[];

  defectExpandedGuid: string;

  pieceMarkedForDeleteGuids: string[];
  pieceSelectedGuid: string;

  pieceEditOpened: boolean;
  pieceEdited: Nullable<types.inspection.Piece>;
  pieceEditedWeight: string;
  pieceEditedWeightBeforeProcessing: string;
  pieceEditedWeightAfterProcessing: string;
  pieceEditedWeightAfterProcessingError: string;
  pieceEditedIsDouble: boolean;
  pieceEditedQmetIdError: string;
  pieceEditedHeatError: string;
  pieceEditedPieceError: string;
  pieceEditedGroupAttError: string;
  pieceEditedQCNumError: string;
  pieceEditedCustomFieldsErrors: Record<string, string>;
  pieceEditedIsMarriageShareExpanded: boolean;
  pieceEditedWeightError: string;
  pieceEditedNoteError: string;
  pieceEditedIsTopographyExpanded: boolean;
  pieceEditedTopoSizeError: string;
  pieceEditedTopoSize: string;
  pieceEditedTopoStepError: string;
  pieceEditedTopoStep: string;
  pieceEditedTopoDistanceError: string;
  pieceEditedTopoDistance: string;
  pieceEditedExternalDoublesOpened: boolean;
  pieceEditedExternalDoublesList: types.inspection.Inspection[];
  pieceCertDownloadOpened: boolean;

  complaintEditOpened: boolean;
  complaintDefectGuid: string;
  complaintEdited: Nullable<types.inspection.Complaint>;
  complaintEditedScrapPrice: string;
  complaintEditedAmountOfCompensation: string;
  complaintEditedMode: 'new' | 'edit' | 'tocomplaint' | 'tocomplaintfinance' | 'view';
  complaintContactDataExpanded: boolean;

  endingOpened: boolean;
  endingEndCaption: string;
  endingDraftVisible: boolean;
  endingDraftCaption: string;
  endingCloseCaption: string;
  endingCancelCaption: string;
}

const initialState: InspectionEditState = {
  generalOpStatus: AsyncOpStatus.IDLE,
  generalOpStatusLabel: '',
  generalSelectModeOpened: false,

  generalAccount: null,
  generalInspectionState: InspectionState.NULL,
  generalOriginal: null,
  generalEdited: null,
  generalIsEntryAccounting: false,
  generalIsTransferOnly: false,
  generalIsChangeToComplaint: false,
  generalIsChangeToComplaintFinance: false,
  generalIsGroup: false,
  generalIsExistComplaint: false,
  generalIsNoTrans: false,

  generalPlaceOfFixationRefer: [],
  generalDefectRefer: [],
  generalFavoriteDefects: [],
  generalWaysToUseRegectedRefer: [],
  generalTopographyRefer: [],
  generalProductRefer: [],
  generalUnitRefer: [],
  generalPriorityRefer: [],

  defectExpandedGuid: '',

  pieceMarkedForDeleteGuids: [],
  pieceSelectedGuid: '',
  
  pieceEditOpened: false,
  pieceEdited: null,
  pieceEditedWeight: '',
  pieceEditedWeightBeforeProcessing: '',
  pieceEditedWeightAfterProcessing: '',
  pieceEditedWeightAfterProcessingError: '',
  pieceEditedIsDouble: false,
  pieceEditedQmetIdError: '',
  pieceEditedHeatError: '',
  pieceEditedPieceError: '',
  pieceEditedGroupAttError: '',
  pieceEditedQCNumError: '',
  pieceEditedCustomFieldsErrors: {},
  pieceEditedIsMarriageShareExpanded: false,
  pieceEditedWeightError: '',
  pieceEditedNoteError: '',
  pieceEditedIsTopographyExpanded: false,
  pieceEditedTopoSize: '',
  pieceEditedTopoSizeError: '',
  pieceEditedTopoStep: '',
  pieceEditedTopoStepError: '',
  pieceEditedTopoDistance: '',
  pieceEditedTopoDistanceError: '',
  pieceEditedExternalDoublesOpened: false,
  pieceEditedExternalDoublesList: [],
  pieceCertDownloadOpened: false,

  complaintEditOpened: false,
  complaintDefectGuid: '',
  complaintEdited: null,
  complaintEditedScrapPrice: '',
  complaintEditedAmountOfCompensation: '',
  complaintEditedMode: 'view',
  complaintContactDataExpanded: false,

  endingOpened: false,
  endingEndCaption: 'Завершить осмотр',
  endingDraftVisible: true,
  endingDraftCaption: 'Сохранить как черновик',
  endingCloseCaption: 'Выйти без сохранения',
  endingCancelCaption: 'Отмена',
}

// #region ОБЩЕЕ

// #region buildForEditAsync
type BuildForEditArgs = {
  guid: string;
  state: InspectionState;
  withDefect: boolean;
  isTransferOnly: boolean;
  isChangeToComplaint: boolean;
  isChangeToComplaintFinance: boolean;
};
type BuildForEditResolve = {
  operationResult: 'get_error' | 'error_fixed' | 'data_updated' | 'success';
  operationResultMessage: string;

  generalAccount: Nullable<types.auth.Account>;
  generalInspectionState: InspectionState;
  generalEdited: Nullable<types.inspection.Inspection>;
  generalIsEntryAccounting: boolean;
  generalIsTransferOnly: boolean;
  generalIsChangeToComplaint: boolean;
  generalIsChangeToComplaintFinance: boolean;
  generalIsGroup: boolean;
  generalIsExistComplaint: boolean;
  
  generalPlaceOfFixationRefer: types.refers.PlaceOfFixationReferElement[];
  generalDefectRefer: types.refers.DefectReferElement[];
  generalWaysToUseRegectedRefer: types.refers.WaysToUseRegectedReferElement[];
  generalTopographyRefer: types.refers.DefectTopographyReferElement[];
  generalProductRefer: types.refers.ProductReferElement[];
  generalUnitRefer:types.refers.UnitReferElement[];
  generalFavoriteDefects: number[];
  generalPriorityRefer: types.refers.PriorityReferElement[];

  defectExpandedGuid: string;
};
export type BuildForEditReject = string;
export const buildForEditAsync = createAsyncThunk<
  BuildForEditResolve,
  BuildForEditArgs,
  {
    state: RootState,
    rejectValue: BuildForEditReject,
  }
>(
  `${SLICE_NAME}/buildForEditAsync`,
  async (args, thunkAPI) => {
    try
    {
      const account = authCommonSelectors.selectAccount(thunkAPI.getState())!;
      const generalPlaceOfFixationRefer = await Db.getPlaceOfFixations();
      const generalWaysToUseRegectedRefer = await Db.getWaysToUseRegected();
      const generalTopographyRefer = await Db.getDefectTopographies();
      const generalProductRefer = await Db.getProducts();
      const generalUnitRefer = await Db.getUnits();
      const generalPriorityRefer = await Db.getPriorities();
      const defects = await Db.getDefects();
      const generalDefectRefer = defects.map((defect) => ({
        ...defect,
        isGroup: defects.some((item) => item.parentId === defect.id)
      }));
      const generalFavoriteDefects = generalDefectRefer.filter((item) => item.isFavorite).map((item) => item.id);

      const createFilesMap: Record<string, string> = {};

      const result: BuildForEditResolve = {
        operationResult: 'success',
        operationResultMessage: '',

        generalAccount: account,
        generalInspectionState: args.state,
        generalEdited: null,
        generalIsEntryAccounting: false,
        generalIsTransferOnly: args.isTransferOnly,
        generalIsChangeToComplaint: args.isChangeToComplaint,
        generalIsChangeToComplaintFinance: args.isChangeToComplaintFinance,
        generalIsGroup: false,
        generalIsExistComplaint: false,
        
        generalPlaceOfFixationRefer,
        generalDefectRefer,
        generalWaysToUseRegectedRefer,
        generalTopographyRefer,
        generalProductRefer,
        generalUnitRefer,
        generalFavoriteDefects,
        generalPriorityRefer,

        defectExpandedGuid: '',
      }

      switch (args.state)
      {
        case InspectionState.NEW:
          const inspectionGuid = Util.guid(true);
          const defectGuid = Util.guid(true);

          result.generalIsEntryAccounting = args.withDefect ? false : true;
          result.generalIsGroup = true;
          result.generalIsExistComplaint = false;
          result.defectExpandedGuid = defectGuid;

          result.generalEdited = {
            guid: inspectionGuid,
            inspectionNum: '',
            inspectionType: null,
            isGroup: true,
            isEntryAccounting: args.withDefect ? false : true,
            timestampCreated: Date.now(),
            timestampInspection: Date.now(),
            userId: account.id,
            userFio: `${account.lastName} ${account.firstName} ${account.middleName}`,
            timestampModified: Date.now(),
            status: {
              id: 0,
              code: types.inspection.InspectionStatusCode.DRAFT,
              title: 'Черновик',
              timestamp: Date.now(),
              fio: `${account.lastName} ${account.firstName} ${account.middleName}`,
              note: null,
            },
            inspectionDefects: [{
              guid: defectGuid,
              timestampDefect: Date.now(),
              defectId: args.withDefect ? -1 : null,
              defectTitle: args.withDefect ? '' : null,
              priorityId: null,
              priorityTitle: null,
              placeOfFixationId: (!args.withDefect && account.role !== AccountRole.Contractor) ? 1 : null,
              placeOfFixationTitle: (!args.withDefect && account.role !== AccountRole.Contractor) ? 'Входной контроль' : null,
              product: null,
              note: null,
              numExternal: null,
              isViolationOfRequirements: false,
              violatedRequirements: null,
              isPossibilityUsingRejected: false,
              wayToUseRegectedId: null,
              wayToUseRegectedTitle: null,
              cntMarriage: 0,
              actJoinInsp: null,
              timestampJoinInsp: null,
              files: [],
              isSend: false,
              pieces: [],
              complaint: null,
              mode: types.inspection.DefectMode.NEW,
              __isJoinInspection: false,
            }],
            qmmsgs: [],
            __isTrans: false,
            __isCompleted: false,
          }
          break;
        default:
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Получение осмотра'));

          let remoteInspection: Nullable<types.inspection.Inspection> = null;

          try
          {
            remoteInspection = await Api.getInspectionByGuid({ guid: args.guid });
          }
          catch (apiError) { }

          let localInspection: Nullable<types.inspection.Inspection> = null;

          try
          {
            localInspection = (await Db.getInspections()).find((item) => item.guid === args.guid) ?? null;
          }
          catch (dbError) { }

          if (remoteInspection === null && localInspection === null)
          {
            result.operationResult = 'get_error';
          }
          else if (remoteInspection !== null && localInspection === null)
          {
            if (args.state === InspectionState.ERROR && remoteInspection.status.code !== InspectionStatusCode.ERROR)
            {
              result.operationResult = 'error_fixed';
              result.operationResultMessage = `Ошибка исправлена. Осмотр в статусе ${remoteInspection.status.code}`;
            }
            else
            {
              result.generalEdited = JSON.parse(JSON.stringify(remoteInspection));
              result.generalEdited!.__isTrans = true;
              result.generalEdited!.__isCompleted = remoteInspection.status != null && remoteInspection.status.code !== InspectionStatusCode.DRAFT;
            }
          }
          else if (localInspection !== null && (remoteInspection === null || remoteInspection.timestampModified <= localInspection.timestampModified))
          {
            result.generalEdited = JSON.parse(JSON.stringify(localInspection));
            result.generalEdited!.__isTrans = false;
            result.generalEdited!.__isCompleted = localInspection.status != null && localInspection.status.code !== InspectionStatusCode.DRAFT && localInspection.status.code !== InspectionStatusCode.NO_TRANS_DRAFT;

            if (args.state === InspectionState.ERROR && localInspection.status.code !== InspectionStatusCode.ERROR && localInspection.__isTrans)
            {
              result.operationResult = 'error_fixed';
              result.operationResultMessage = `Ошибка исправлена. Осмотр в статусе ${localInspection.status.code}`;
            }
          }
          else if (localInspection != null && remoteInspection !== null && remoteInspection.timestampModified > localInspection.timestampModified) {
            result.generalEdited = JSON.parse(JSON.stringify(remoteInspection));
            result.generalIsTransferOnly = false;
            result.operationResult = 'data_updated';
            result.operationResultMessage = 'Осмотр обновлен на более новую версию. При необходимости скорректируйте и сохраните заново.'
          }

          if (result.generalEdited !== null)
          {
            result.generalIsGroup = result.generalEdited.isGroup;
          }

          if (result.generalEdited !== null && args.state === InspectionState.CREATE)
          {
            result.generalEdited.inspectionNum = '';
            result.generalEdited.guid = Util.guid(true);
            result.generalEdited.timestampInspection = Date.now();
            result.generalEdited.isGroup = true;
            result.generalEdited.isEntryAccounting = false;
            result.generalEdited.userId = account.id;
            result.generalEdited.userFio = `${account.lastName} ${account.firstName} ${account.middleName}`;
            result.generalEdited.__isTrans = false;
            result.generalEdited.__isCompleted = false;
            result.generalEdited.status = {
              id: 0,
              code: types.inspection.InspectionStatusCode.DRAFT,
              title: 'Черновик',
              timestamp: Date.now(),
              fio: null,
              note: null,
            };

            result.generalEdited.inspectionDefects.forEach((defect) => {
              defect.guid = Util.guid(true);
              defect.mode = types.inspection.DefectMode.NEW;
              defect.defectId = -1;
              defect.defectTitle = null;

              for (let file of defect.files)
              {
                const newGuid = Util.guid(true);
                createFilesMap[newGuid] = file.guid;
                file.guid = newGuid;
                file.mode = types.inspection.DefectFileMode.NEW;
              }

              (defect.pieces || []).forEach((piece) => {
                piece.guid = Util.guid(true);
                piece.mode = types.inspection.PieceMode.NEW;

                piece.weight = null;
                piece.weightBeforeProcessing = null;
                piece.weightAfterProcessing = null;

                piece.pieceNumId = null;

                if (piece.qmetData)
                {
                  piece.qmetData.qcNum = null;
                  piece.qmetData.group = null;
                  piece.qmetData.qcState = QCState.NONE;
                }
                
                if (piece.isPiece)
                {
                  piece.qmetId = null;
                  if (piece.groupAtt == null)
                  {
                    piece.qcNum = null;
                  }
                  else
                  {
                    piece.piece = null;
                  }
                }
                else
                {
                  piece.heat = null;
                  piece.piece = null;
                  piece.groupAtt = null;
                  piece.qcNum = null;
                }

                const unitDetail = generalUnitRefer
                  .find((item) => item.id === piece.unit.id)?.details?.find((detail) => !detail.isEntryAccounting) ?? null;

                if (unitDetail !== null)
                {
                  piece.unit.fieldTitle = unitDetail.fieldTitle;
                  piece.unit.fieldHint = unitDetail.fieldHint;
                }

                for (let file of piece.files)
                {
                  const newGuid = Util.guid(true);
                  createFilesMap[newGuid] = file.guid;
                  file.guid = newGuid;
                  file.mode = types.inspection.PieceFileMode.NEW;
                }
              })
            });

            thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Получение фото осмотра'));

            for await (const defect of result.generalEdited.inspectionDefects)
            {
              for await (const piece of (defect.pieces || []))
              {
                for await (const file of piece.files)
                {
                  if (file.__name === undefined)
                  {
                    try
                    {
                      const remoteFile = await Api.getInspectionFileByGuid({
                        guid: createFilesMap[file.guid] ?? file.guid,
                        source: 'piece'
                      });

                      if (file.type === PieceFileType.PDF)
                      {
                        const fileFromBlob = await Util.blobToFile(remoteFile.blob, file.name, 'application/pdf');
                        const binaryStr = await Util.fileToBinaryString(fileFromBlob);
                        const dataUrlResult = await Util.pdfToDataURL(fileFromBlob);

                        if (dataUrlResult.ok)
                        {
                          file.__dataUrl = dataUrlResult.result;
                          file.__binaryStr = binaryStr;
                          file.__name = Util.buildAttachmentName(file.type, file.name);
                          file.__mime = 'application/pdf';
                        }
                        else
                        {
                          file.__dataUrl = "";
                        }
                      }
                      else
                      {
                        const fileFromBlob = await Util.blobToFile(remoteFile.blob, file.name, 'image/jpeg');
                        const dataUrlResult = await Util.imageToDataURL(fileFromBlob);
                        const binaryStr = await Util.fileToBinaryString(fileFromBlob);
                        if (dataUrlResult.ok)
                        {
                          file.__dataUrl = dataUrlResult.result;
                          file.__binaryStr = binaryStr;
                          file.__name = Util.buildAttachmentName(file.type, file.name);
                          file.__mime = 'image/jpeg';
                        }
                        else
                        {
                          file.__dataUrl = Util.noPhoto;
                        }
                      }
                    }
                    catch(error)
                    {
                      file.__dataUrl = Util.noPhoto;
                    }
                  }
                }
              }
            }
          }

          if (result.generalEdited !== null)
          {
            result.generalEdited.inspectionDefects.forEach((defect) => {
              defect.__isJoinInspection = defect.actJoinInsp !== null && defect.actJoinInsp !== '';
            })
            result.defectExpandedGuid = result.generalEdited.inspectionDefects[0].guid;
          }

          /** Если isChangeToComplaint = true  */
          if (args.isChangeToComplaint)
          {
            if (result.generalEdited !== null)
            {
              if (result.generalEdited.inspectionDefects.length > 0)
              {
                const defect = result.generalEdited.inspectionDefects[0];

                if (defect.complaint !== null)
                {
                  defect.complaint.typeComplaint = ComplaintType.COMPLAINT;
                }
              }
            }
          }

          /** Если isChangeToComplaint = true  */
          if (args.isChangeToComplaintFinance)
          {
            if (result.generalEdited !== null)
            {
              if (result.generalEdited.inspectionDefects.length > 0)
              {
                const defect = result.generalEdited.inspectionDefects[0];

                if (defect.complaint !== null)
                {
                  defect.complaint.typeComplaint = ComplaintType.COMPLAINT;
                }
              }
            }
          }

          if (result.generalEdited !== null)
          {
            result.generalIsEntryAccounting = result.generalEdited.isEntryAccounting;

            result.generalEdited.inspectionDefects.forEach((defect) => {
              if (
                defect.complaint !== null
                &&
                (defect.complaint.typeComplaint === null || defect.complaint.typeComplaint === types.inspection.ComplaintType.NONE))
              {
                defect.complaint = null;
              }
            })
          }

          thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));
      }

      return result;
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));
      return thunkAPI.rejectWithValue('Ошибка при открытии осмотра');
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(buildForEditAsync.fulfilled, (state, action) => {
      state.generalAccount = action.payload.generalAccount;
      state.generalInspectionState = action.payload.generalInspectionState;
      state.generalOriginal = action.payload.generalInspectionState === InspectionState.NEW ? null : JSON.parse(JSON.stringify(action.payload.generalEdited));
      state.generalEdited = JSON.parse(JSON.stringify(action.payload.generalEdited));
      state.generalIsEntryAccounting = action.payload.generalIsEntryAccounting;
      state.generalIsTransferOnly = action.payload.generalIsTransferOnly;
      state.generalIsChangeToComplaint = action.payload.generalIsChangeToComplaint;
      state.generalIsChangeToComplaintFinance = action.payload.generalIsChangeToComplaintFinance;
      state.generalIsGroup = action.payload.generalIsGroup;
      state.generalIsExistComplaint = action.payload.generalIsExistComplaint;
      state.generalIsNoTrans = [InspectionState.NEW, InspectionState.CREATE].includes(action.payload.generalInspectionState) ? false : !(action.payload.generalEdited?.__isTrans ?? false);
      state.generalPlaceOfFixationRefer = action.payload.generalPlaceOfFixationRefer;
      state.generalWaysToUseRegectedRefer = action.payload.generalWaysToUseRegectedRefer;
      state.generalTopographyRefer = action.payload.generalTopographyRefer;
      state.generalDefectRefer = action.payload.generalDefectRefer;
      state.generalFavoriteDefects = action.payload.generalFavoriteDefects;
      state.defectExpandedGuid = action.payload.defectExpandedGuid;
      state.generalProductRefer = action.payload.generalProductRefer;
      state.generalUnitRefer = action.payload.generalUnitRefer;
      state.generalPriorityRefer = action.payload.generalPriorityRefer;

      const inspectionState = action.payload.generalInspectionState;
      const isEntryAccounting = action.payload.generalIsEntryAccounting;

      if (inspectionState === InspectionState.EDIT || inspectionState === InspectionState.ERROR)
      {
        state.endingDraftVisible = false;
        state.endingEndCaption = isEntryAccounting ? 'Завершить изменения' : 'Завершить изменения';
      }
      else
      {
        state.endingEndCaption = isEntryAccounting ? 'Завершить' : 'Завершить осмотр';
        if (inspectionState !== InspectionState.NEW && inspectionState !== InspectionState.CREATE)
        {
          state.endingDraftCaption = 'Сохранить черновик'
        }
      }
    })
    .addCase(buildForEditAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region saveImmediatelyAsync
type SaveImmediatelyArgs = {
  isSaveEditedPiece: boolean;
  isApplyEditedPiece?: boolean;
};
type SaveImmediatelyResolve = 'saved' | 'savedwithapply' | 'skiped';
export type SaveImmediatelyReject = void;
export const saveImmediatelyAsync = createAsyncThunk<
  SaveImmediatelyResolve,
  SaveImmediatelyArgs,
  {
    state: RootState,
    rejectValue: SaveImmediatelyReject,
  }
>(
  `${SLICE_NAME}/saveImmediatelyAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const inspection = JSON.parse(JSON.stringify(state.inspectionEdit.generalEdited)) as Nullable<types.inspection.Inspection>;

      if (inspection !== null)
      {
        if (args.isSaveEditedPiece)
        {
          const pieceEdited = JSON.parse(JSON.stringify(state.inspectionEdit.pieceEdited)) as Nullable<types.inspection.Piece>;
          const expandedDefect = state.inspectionEdit.defectExpandedGuid;

          if (pieceEdited !== null)
          {
            inspection.inspectionDefects.forEach((defect) => {
              if (defect.guid === expandedDefect)
              {
                let isFound = false;
                if (defect.pieces !== null)
                {
                  defect.pieces = defect.pieces.map((piece) => {
                    if (piece.guid === pieceEdited.guid)
                    {
                      isFound = true;
                      return pieceEdited;
                    }
                    return piece;
                  });

                  if (!isFound)
                  {
                    defect.pieces.push(pieceEdited);
                  }
                }
                else
                {
                  defect.pieces = [pieceEdited];
                }
              }
            })
          }
        }
        
        await Db.saveInspection(inspection);
        return args.isApplyEditedPiece ? 'savedwithapply' : 'saved';
      }

      return 'skiped';
    }
    catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(saveImmediatelyAsync.fulfilled, (state, action) => {
      if (state.generalEdited !== null && (action.payload === 'saved' || action.payload === 'savedwithapply'))
      {
        state.generalEdited.__isTrans = false;
        state.generalEdited.status.id = -1;

        if ([InspectionState.NEW, InspectionState.CREATE, InspectionState.DRAFT].includes(state.generalInspectionState))
        {
          state.generalEdited.status.code = InspectionStatusCode.NO_TRANS_DRAFT;
        }
        else if (state.generalInspectionState === InspectionState.ERROR)
        {
          state.generalEdited.status.code = InspectionStatusCode.NO_TRANS_ERROR;
        }
        else
        {
          state.generalEdited.status.code = InspectionStatusCode.NO_TRANS_COMPLETED;
        }
        state.generalEdited.status.title = 'Не отправлен';

        if (action.payload === 'savedwithapply')
        {
          const pieceEdited = JSON.parse(JSON.stringify(state.pieceEdited)) as Nullable<types.inspection.Piece>;
          const expandedDefect = state.defectExpandedGuid;

          if (pieceEdited !== null)
          {
            state.generalEdited.inspectionDefects.forEach((defect) => {
              if (defect.guid === expandedDefect)
              {
                let isFound = false;
                if (defect.pieces !== null)
                {
                  defect.pieces = defect.pieces.map((piece) => {
                    if (piece.guid === pieceEdited.guid)
                    {
                      isFound = true;
                      return pieceEdited;
                    }
                    return piece;
                  });

                  if (!isFound)
                  {
                    defect.pieces.push(pieceEdited);
                  }
                }
                else
                {
                  defect.pieces = [pieceEdited];
                }
              }
            })
          }
        }
      }
    })
    .addCase(saveImmediatelyAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region deleteImmediatelyAsync
type DeleteImmediatelyArgs = void;
type DeleteImmediatelyResolve = void;
export type DeleteImmediatelyReject = void;
export const deleteImmediatelyAsync = createAsyncThunk<
  DeleteImmediatelyResolve,
  DeleteImmediatelyArgs,
  {
    state: RootState,
    rejectValue: DeleteImmediatelyReject,
  }
>(
  `${SLICE_NAME}/deleteImmediatelyAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const inspection = state.inspectionEdit.generalEdited;

      if (inspection !== null)
      {
        await Db.removeInspection(inspection.guid);
      }
    }
    catch (error) { }
  }
);
// #endregion

// #region changeDefectFavoriteAsync
type ChangeDefectFavoriteArgs = {
  defectId: number;
  isFavorite: boolean;
};
type ChangeDefectFavoriteResolve = {
  generalFavoriteDefects: number[],
};
export type ChangeDefectFavoriteReject = ApiError;
export const changeDefectFavoriteAsync = createAsyncThunk<
  ChangeDefectFavoriteResolve,
  ChangeDefectFavoriteArgs,
  {
    state: RootState,
    rejectValue: ChangeDefectFavoriteReject,
  }
>(
  `${SLICE_NAME}/changeDefectFavoriteAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.postRefersDefectFavorite({ defectId: args.defectId, isFavorite: args.isFavorite });

      const state = thunkAPI.getState();

      if (args.isFavorite)
      {
        return {
          generalFavoriteDefects: [...state.inspectionEdit.generalFavoriteDefects, args.defectId]
        }
      }
      else
      {
        return {
          generalFavoriteDefects: state.inspectionEdit.generalFavoriteDefects.filter((item) => item !== args.defectId)
        }
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(changeDefectFavoriteAsync.fulfilled, (state, action) => {
      state.generalFavoriteDefects = action.payload.generalFavoriteDefects;
    })
    .addCase(changeDefectFavoriteAsync.rejected, (state, action) => {
    });
});
// #endregion

const generalReducers = {
  generalOpStatusChanged: (state: WritableDraft<InspectionEditState>, action: OpStatusChangedAction) => {
    state.generalOpStatus = action.payload;
  },

  generalOpStatusLabelChanged: (state: WritableDraft<InspectionEditState>, action: OpStatusLabelChangedAction) => {
    state.generalOpStatusLabel = action.payload;
  },

  generalSelectModeOpened: (state: WritableDraft<InspectionEditState>) => {
    state.generalSelectModeOpened = true;
  },

  generalSelectModeClosed: (state: WritableDraft<InspectionEditState>) => {
    state.generalSelectModeOpened = false;
  },
}

const generalSelectors = {
  selectGeneralOpStatus: (state: RootState) => state.inspectionEdit.generalOpStatus,
  selectGeneralOpStatusLabel: (state: RootState) => state.inspectionEdit.generalOpStatusLabel,
  selectGeneralSelectModeOpened: (state: RootState) => state.inspectionEdit.generalSelectModeOpened,

  selectGeneralInspectionState: (state: RootState) => state.inspectionEdit.generalInspectionState,
  selectGeneralInspectionStatusCode: (state: RootState) => state.inspectionEdit.generalEdited?.status.code ?? null,
  selectGeneralIsTransferOnly: (state: RootState) => state.inspectionEdit.generalIsTransferOnly,
  selectGeneralIsChangeToComplaint: (state: RootState) => state.inspectionEdit.generalIsChangeToComplaint,
  selectGeneralIsChangeToComplaintFinance: (state: RootState) => state.inspectionEdit.generalIsChangeToComplaintFinance,
  selectGeneralIsGroup: (state: RootState) => state.inspectionEdit.generalIsGroup,
  selectGeneralIsExistComplaint: (state: RootState) => state.inspectionEdit.generalIsExistComplaint,
  selectGeneralIsNoTrans: (state: RootState) => state.inspectionEdit.generalIsNoTrans,

  selectGeneralIsEntryAccounting: (state: RootState) => state.inspectionEdit.generalIsEntryAccounting,
  selectGeneralPlaceOfFixationRefer: (state: RootState) => state.inspectionEdit.generalPlaceOfFixationRefer,
  selectGeneralWaysToUseRegectedRefer: (state: RootState) => state.inspectionEdit.generalWaysToUseRegectedRefer,
  selectGeneralTopographyRefer: (state: RootState) => state.inspectionEdit.generalTopographyRefer,
  selectGeneralDefectRefer: (state: RootState) => state.inspectionEdit.generalDefectRefer,
  selectGeneralProductRefer: (state: RootState) => state.inspectionEdit.generalProductRefer,
  selectGeneralUnitRefer: (state: RootState) => state.inspectionEdit.generalUnitRefer,
  selectGeneralPriorityRefer: (state: RootState) => state.inspectionEdit.generalPriorityRefer,
  selectGeneralFavoriteDefects: (state: RootState) => state.inspectionEdit.generalFavoriteDefects,
  selectGeneralIsEditMode: (state: RootState) => state.inspectionEdit.generalOriginal !== null,
  selectGeneralEditedGuid: (state: RootState) => state.inspectionEdit.generalEdited?.guid ?? '',
  selectGeneralEditedNum: (state: RootState) => state.inspectionEdit.generalEdited?.inspectionNum ?? '',

  selectGeneralMainNavbarText: (state: RootState) => {
    const isEditMode = state.inspectionEdit.generalOriginal !== null;
    const isEntryAccounting = state.inspectionEdit.generalIsEntryAccounting;
    const isGroup = state.inspectionEdit.generalIsGroup;
    const inspectionNum = state.inspectionEdit.generalEdited?.inspectionNum ?? '';
    const account = state.authCommon.account!;

    if (!isEditMode)
    {
      return isEntryAccounting ?
        (account.role === AccountRole.Contractor ? 'Без дефекта' : 'Входной учет')
        :
        'Добавление дефектов';
    }
    else
    {
      if (isGroup)
      {
        return isEntryAccounting ?
          (account.role === AccountRole.Contractor ? 'Без дефекта' : 'Входной учет')
          :
          'Редактирование дефектов';
      }
      else
      {
        return isEntryAccounting ?
          (account.role === AccountRole.Contractor ? `Без дефекта №${inspectionNum}` : `Входной учет №${inspectionNum}`) 
          :
          `Осмотр №${inspectionNum}`;
      } 
    }
  },

  selectGeneralIsDraftSaveVisible: (state: RootState) => {
    const inspectionState = state.inspectionEdit.generalInspectionState;

    return [InspectionState.NEW, InspectionState.CREATE, InspectionState.DRAFT, InspectionState.ERROR].includes(inspectionState);
  },

  selectGeneralIsEquals: createSelector(
    [
      (state: RootState, isComplete: boolean) => ({ state, isComplete })
    ],
    ({ state, isComplete }) => {
      const inspectionState = state.inspectionEdit.generalInspectionState;
      const original = state.inspectionEdit.generalOriginal;
      const edited = state.inspectionEdit.generalEdited;

      let isEquals = true;
      if ([InspectionState.NEW, InspectionState.CREATE].includes(inspectionState))
      {
        isEquals = false;
      }
      else
      {
        if (original === null || edited === null)
        {
          isEquals = false;
        }
        else
        {
          edited.inspectionDefects.forEach((defect) => {
            if ([DefectMode.NEW, DefectMode.EDIT, DefectMode.DEL].includes(defect.mode))
            {
              isEquals = false;
            }
            else
            {
              original.inspectionDefects.forEach((defectOld) => {
                if (defectOld.guid === defect.guid)
                {
                  /**
                   * Проверка совместной инспекции. У нас switch совместной инспекции управляется переменной для 
                   * внутреннего пользования __isJoinInspection. Если она true, то в редактируемой версии все поля
                   * заполнены - акт, дата и документ. Если она false, то поля при сохранении будут очищены, даже
                   * если они сейчас заполнены. Поскольку все поля обязательны при включенном switch-е, то для 
                   * определенных проверок достаточно заполненного акта.
                   * Какие варианты возможны:
                   * 1. В оригинальном дефекте акт заполнен, а в редактируемом переменная __isJoinInspection равна
                   *    false. Это означает, что сначала данные были заполнены, а сейчас мы их очищаем.
                   * 2. В оригинальном дефекте акт не заполнен, а в редактируемом переменная __isJoinInspection равна
                   *    true. Это означает, что сначала данные не были заполнены, а сейчас мы их заполнили.
                   * 3. В оригинальном дефекте акт заполнен и в редактируемом переменная __isJoinInspection равна
                   *    true. Это означает, что сначала данные были заполнены и сейчас мы их не очищаем. А значит нужно
                   *    сравнивать уже сами поля. Если акт изменился, или дата изменилась или есть документы совместной
                   *    инспекции на редактируемом дефекте в состоянии DEL или NEW (а точнее будет и DEL и NEW, т.к.
                   *    мы меняем файл) - значит есть изменения
                   */

                  const oldJoinInspectionAct = Util.nullOrEmptyStringTo(defectOld.actJoinInsp, 'X');
                  const newJoinInspectionAct = Util.nullOrEmptyStringTo(defect.actJoinInsp, 'X');
                  const oldJoinInspectionTime = defectOld.timestampJoinInsp ?? 0;
                  const newJoinInspectionTime = defect.timestampJoinInsp ?? 0;
                  const ACT_JOIN_INSP = types.inspection.DefectFileType.ACT_JOIN_INSP;
                  const DEL = types.inspection.DefectFileMode.DEL;
                  const NEW = types.inspection.DefectFileMode.NEW;
                  const isJoinInspectionFilesChanged = defect.files.some(
                    (file) => file.type === ACT_JOIN_INSP && [DEL, NEW].includes(file.mode)
                  );
                  const isJoinInspectionExpanded = defect.__isJoinInspection;

                  const isJoinInspectionClear = (oldJoinInspectionAct !== 'X' && !isJoinInspectionExpanded);
                  const isJoinInspectionFill = (oldJoinInspectionAct === 'X' && isJoinInspectionExpanded);
                  const isJoinInspectionFieldsChanged = (oldJoinInspectionAct !== newJoinInspectionAct)
                                                        || (oldJoinInspectionTime !== newJoinInspectionTime)
                                                        || isJoinInspectionFilesChanged;
                  const isJoinInspectionChanged = isJoinInspectionClear || isJoinInspectionFill || isJoinInspectionFieldsChanged;

                  const newDefectId = defect.defectId ?? 0;
                  const oldDefectId = defectOld.defectId ?? 0;
                  const newPriorityId = defect.priorityId ?? 0;
                  const oldPriorityId = defectOld.priorityId ?? 0;
                  const newPlaceOfFixationId = defect.placeOfFixationId ?? 0;
                  const oldPlaceOfFixationId = defectOld.placeOfFixationId ?? 0;
                  const newProduct = Util.nullOrEmptyStringTo(defect.product, 'X');
                  const oldProduct = Util.nullOrEmptyStringTo(defectOld.product, 'X');
                  const newNote = Util.nullOrEmptyStringTo(defect.note, 'X');
                  const oldNote = Util.nullOrEmptyStringTo(defectOld.note, 'X');
                  const newExternalNumber = Util.nullOrEmptyStringTo(defect.numExternal, 'X');
                  const oldExternalNumber = Util.nullOrEmptyStringTo(defectOld.numExternal, 'X');
                  const newViolatedRequirements = Util.nullOrEmptyStringTo(defect.violatedRequirements, 'X');
                  const oldViolatedRequirements = Util.nullOrEmptyStringTo(defectOld.violatedRequirements, 'X');
                  const newWayToUseRegectedId = defect.wayToUseRegectedId ?? 0;
                  const oldWayToUseRegectedId = defectOld.wayToUseRegectedId ?? 0;
                  if (
                    (newDefectId !== oldDefectId)
                    ||
                    (newPriorityId !== oldPriorityId)
                    ||
                    (newPlaceOfFixationId !== oldPlaceOfFixationId)
                    ||
                    (newProduct !== oldProduct)
                    ||
                    (newNote !== oldNote)
                    ||
                    (newExternalNumber !== oldExternalNumber)
                    ||
                    (defect.isViolationOfRequirements !== defectOld.isViolationOfRequirements)
                    ||
                    (newViolatedRequirements !== oldViolatedRequirements)
                    ||
                    (defect.isPossibilityUsingRejected !== defectOld.isPossibilityUsingRejected)
                    ||
                    (newWayToUseRegectedId !== oldWayToUseRegectedId)
                    ||
                    isJoinInspectionChanged
                  ) 
                  {
                    isEquals = false;
                  }
                }
              });

              if (isEquals)
              {
                (defect.pieces ?? []).forEach((piece) => {
                  if (piece.mode !== PieceMode.EXIST)
                  {
                    isEquals = false;
                  }
                });

                if (isEquals)
                {
                  if (defect.complaint !== null && defect.complaint.mode !== ComplaintMode.EXIST)
                  {
                    isEquals = false;
                  }
                }

                if (isEquals)
                {
                  if (isComplete !== original.__isCompleted)
                  {
                    isEquals = false;
                  }
                }
              }
            }
          });
        }
      }

      return isEquals;
    }
  )
}

// #endregion

// #region ДЕФЕКТЫ

// #region addDefectAsync
type AddDefectAsyncArgs = void;
type AddDefectAsyncResolve = {
  defect: types.inspection.Defect;
  defectExpandedGuid: string;
};
export type AddDefectAsyncReject = {
  error: string;
  defectExpandedGuid: string;
}
export const addDefectAsync = createAsyncThunk<
  AddDefectAsyncResolve,
  AddDefectAsyncArgs,
  {
    state: RootState,
    rejectValue: AddDefectAsyncReject,
  }
>(
  `${SLICE_NAME}/addDefectAsync`,
  async (args, thunkAPI) => {
    const defects = thunkAPI.getState().inspectionEdit.generalEdited!.inspectionDefects;
    const account = thunkAPI.getState().authCommon.account!;

    for (let i = 0; i < defects.length; i++)
    {
      const defect = defects[i];
      const pieces = defect.pieces || [];
      const emptyRequired: string[] = [];

      if (defect.defectId === null || defect.defectId === -1)
      {
        emptyRequired.push('Вид дефекта');
      }

      if (defect.isViolationOfRequirements === true && defect.violatedRequirements === null)
      {
        emptyRequired.push('Укажите какое требование нарушено');
      }

      if (defect.isPossibilityUsingRejected === true && defect.wayToUseRegectedId === null)
      {
        emptyRequired.push('Способ использования забракованного проката');
      }

      if (pieces.length === 0)
      {
        emptyRequired.push('Информация о продукции');
      }
      /**
       * mobi-1541 Для сварного шва (id = 99) место фиксации обязательно для клиента, клиента-админа и стпк
       */
      if (
        [AccountRole.Client, AccountRole.ClientAdmin, AccountRole.Stpk].includes(account.role)
        &&
        defect.defectId === 99
        &&
        defect.placeOfFixationId === null
      )
      {
        emptyRequired.push('Место фиксации');
      }

      if (emptyRequired.length > 0)
      {
        return thunkAPI.rejectWithValue({
          error: `Заполните обязательные поля: ${emptyRequired.join(', ')}`,
          defectExpandedGuid: defect.guid,
        })
      }

      const state = thunkAPI.getState();

      for (let j = 0; j < pieces.length; j++)
      {
        const pieceError = getPieceErrorMessage(
          pieces[j],
          state.inspectionEdit.generalIsEntryAccounting,
          state.authCommon.account!.isWireRod,
          true,
          state.authCommon.account!
        );

        if (pieceError !== '')
        {
          return thunkAPI.rejectWithValue({
            error: `Ошибки в позиции: ${pieceError}`,
            defectExpandedGuid: defect.guid,
          })
        }
      }

    }

    const guid = Util.guid(true);

    return {
      defect: {
        guid: guid,
        timestampDefect: Date.now(),
        defectId: null,
        defectTitle: null,
        priorityId: null,
        priorityTitle: null,
        placeOfFixationId: null,
        placeOfFixationTitle: null,
        product: null,
        note: null,
        numExternal: null,
        isViolationOfRequirements: false,
        violatedRequirements: null,
        isPossibilityUsingRejected: false,
        wayToUseRegectedId: null,
        wayToUseRegectedTitle: null,
        cntMarriage: 0,
        actJoinInsp: null,
        timestampJoinInsp: null,
        files: [],
        isSend: false,
        pieces: [],
        complaint: null,
        mode: types.inspection.DefectMode.NEW,
        __isJoinInspection: false,
      },
      defectExpandedGuid: guid,
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(addDefectAsync.fulfilled, (state, action) => {
      state.generalEdited!.inspectionDefects.push(action.payload.defect);
      state.defectExpandedGuid = action.payload.defectExpandedGuid;
    })
    .addCase(addDefectAsync.rejected, (state, action) => {
      if (action.payload)
      {
        state.defectExpandedGuid = action.payload?.defectExpandedGuid;
      }
    });
});
// #endregion

// #region gotoComplaintAsync
type GotoComplaintAsyncArgs = void;
type GotoComplaintAsyncResolve = 'gotolist' | 'gotoitem' | 'none';
export type GotoComplaintAsyncReject = void;
export const gotoComplaintAsync = createAsyncThunk<
  GotoComplaintAsyncResolve,
  GotoComplaintAsyncArgs,
  {
    state: RootState,
    rejectValue: GotoComplaintAsyncReject,
  }
>(
  `${SLICE_NAME}/gotoComplaintAsync`,
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const defects = state.inspectionEdit.generalEdited!.inspectionDefects;

    if (defects.length > 0)
    {
      if (state.inspectionEdit.generalIsGroup)
      {
        return 'gotolist';
      }
      else
      {
        return 'gotoitem';
      }
    }
    else
    {
      return 'none';
    }
  }
);
// #endregion

// #region checkDefectsFilledAsync
type CheckDefectsFilledAsyncArgs = void;
type CheckDefectsFilledAsyncResolve = void;
export type CheckDefectsFilledAsyncReject = {
  error: string;
  defectExpandedGuid: string;
};
export const checkDefectsFilledAsync = createAsyncThunk<
  CheckDefectsFilledAsyncResolve,
  CheckDefectsFilledAsyncArgs,
  {
    state: RootState,
    rejectValue: CheckDefectsFilledAsyncReject,
  }
>(
  `${SLICE_NAME}/checkDefectsFilledAsync`,
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const defects = state.inspectionEdit.generalEdited!.inspectionDefects;
    const account = state.authCommon.account!;

    if (defects.length > 0)
    {
      for (let i = 0; i < defects.length; i++)
      {
        const errorResult = getDefectErrorMessage(
          defects[i],
          false,
          account.role === AccountRole.Client || account.role === AccountRole.Contractor,
          defects[i].mode,
          state.inspectionEdit.generalIsEntryAccounting,
          account,
          false,
        );

        if (errorResult !== '')
        {
          return thunkAPI.rejectWithValue({
            error: errorResult,
            defectExpandedGuid: defects[i].guid,
          });
        }
      }
    }
    else
    {
      return thunkAPI.rejectWithValue({
        error: 'Не заполнены дефекты',
        defectExpandedGuid: '',
      });
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(checkDefectsFilledAsync.rejected, (state, action) => {
      if (action.payload)
      {
        state.defectExpandedGuid = action.payload?.defectExpandedGuid;
      }
    });
});
// #endregion

const defectReducers = {
  defectAdded: (state: WritableDraft<InspectionEditState>) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      gE.inspectionDefects.push({
        guid: Util.guid(true),
        timestampDefect: Date.now(),
        defectId: null,
        defectTitle: null,
        priorityId: null,
        priorityTitle: null,
        placeOfFixationId: null,
        placeOfFixationTitle: null,
        product: null,
        note: null,
        numExternal: null,
        isViolationOfRequirements: false,
        violatedRequirements: null,
        isPossibilityUsingRejected: false,
        wayToUseRegectedId: null,
        wayToUseRegectedTitle: null,
        cntMarriage: 0,
        isSend: false,
        actJoinInsp: null,
        timestampJoinInsp: null,
        files: [],
        pieces: [],
        complaint: null,
        mode: types.inspection.DefectMode.NEW,
        __isJoinInspection: false,
      });
    }
  },

  defectDeleted: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const defectForDelete = gE.inspectionDefects.find((item) => item.guid === action.payload)!;

      if (defectForDelete.mode === types.inspection.DefectMode.NEW)
      {
        gE.inspectionDefects = gE.inspectionDefects.filter((defect) => defect.guid !== action.payload);
      }
      else
      {
        gE.inspectionDefects = gE.inspectionDefects.map((defect) => defect.guid === action.payload ? { ...defect, mode: types.inspection.DefectMode.DEL } : defect);
      }

      const defectList = gE.inspectionDefects.filter((item) => item.mode !== types.inspection.DefectMode.DEL);

      if (defectList.length === 1)
      {
        state.defectExpandedGuid = defectList[0].guid;
      }
    }
  },

  defectExpandedGuidChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    state.defectExpandedGuid = action.payload;
  },

  defectPlaceOfFixationChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.placeOfFixationId = action.payload;
        eD.placeOfFixationTitle = state.generalPlaceOfFixationRefer.find((item) => item.id === action.payload)!.title;

        if (eD.placeOfFixationId !== 3)
        {
          eD.product = '';
        }

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectPriorityChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.priorityId = action.payload;
        eD.priorityTitle = state.generalPriorityRefer.find((item) => item.id === action.payload)!.title;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectProductChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.product = action.payload !== '' ? action.payload : null;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectDefectChanged: (state: WritableDraft<InspectionEditState>, action: NullableNumberChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.defectId = action.payload;
        eD.defectTitle = action.payload !== null ? state.generalDefectRefer.find((item) => item.id === action.payload)!.title : null;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectNoteChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.note = action.payload !== '' ? action.payload : null;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectExternalNumberChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.numExternal = action.payload !== '' ? action.payload : null;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectIsViolationOfRequirementsChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.isViolationOfRequirements = action.payload;

        if (!action.payload)
        {
          eD.violatedRequirements = null;
        }

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectViolatedRequirementsChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.violatedRequirements = action.payload === '' ? null : action.payload;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectPossibilityUsingRejectedChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.isPossibilityUsingRejected = action.payload;

        if (!action.payload)
        {
          eD.wayToUseRegectedId = null;
          eD.wayToUseRegectedTitle = null;
        }

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectWayToUseRegectedChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.wayToUseRegectedId = action.payload;
        eD.wayToUseRegectedTitle = state.generalWaysToUseRegectedRefer.find((item) => item.id === action.payload)!.title;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectJoinInspectionChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.__isJoinInspection = action.payload;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectJoinInspectionActChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.actJoinInsp = action.payload === '' ? null : action.payload;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectJoinInspectionTimestampChanged: (state: WritableDraft<InspectionEditState>, action: NullableNumberChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.timestampJoinInsp = action.payload;

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectDocumentDeleted: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.files = eD.files
          .map((file) => {
            if (file.guid !== action.payload)
            {
              return file;
            }

            if (file.mode === types.inspection.DefectFileMode.EXIST)
            {
              return { ...file, mode: types.inspection.PieceFileMode.DEL };
            }
            
            return null;
          })
          .filter((file) => file !== null) as types.inspection.DefectFile[];

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

  defectDocumentAdded: (state: WritableDraft<InspectionEditState>, action: DefectDocumentAddedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.files.push(action.payload);

        if (eD.mode !== types.inspection.DefectMode.NEW)
        {
          eD.mode = types.inspection.DefectMode.EDIT;
        }
      }
    }
  },

}

const defectSelectors = {
  selectDefectExpandedGuid: (state: RootState) => state.inspectionEdit.defectExpandedGuid,

  selectDefectFirstGuid: (state: RootState) => {
    const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
    
    return allDefects.length > 0 ? allDefects[0].guid : null;
  },

  selectDefectAllGuidsList: (state: RootState) => {
    const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
    
    return allDefects.filter((defect) => defect.mode !== types.inspection.DefectMode.DEL).map((defect) => defect.guid).join(';');
  },

  selectDefectAllCount: (state: RootState) => {
    const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
    
    return allDefects.filter((defect) => defect.mode !== types.inspection.DefectMode.DEL).length;
  },

  selectDefectComplaintsIsOk: (state: RootState) => {
    const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
    
    return allDefects.every((defect) =>
      defect.complaint !== null && defect.complaint.typeComplaint !== null
    )
  },

  selectDefectTitle: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.defectTitle ?? null;
    }
  ),

  selectDefectDateTime: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.timestampDefect ?? null;
    }
  ),

  selectDefectQmetIds: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null)
      {
        return '';
      }

      const qmetIds: string[] = [];

      (defect.pieces || []).forEach((piece) => {
        if (!piece.isPiece && piece.qmetId != null && piece.qmetId > 0)
        {
          qmetIds.push(`${piece.qmetId}`);
        }
      })

      return qmetIds.join(', ');
    }
  ),

  selectDefectPieceIds: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null)
      {
        return '';
      }

      const pieceIds: string[] = [];

      (defect.pieces || []).forEach((piece) => {
        if (piece.isPiece && piece.heat != null && piece.piece != null && piece.heat !== '' && piece.piece !== '' && (piece.groupAtt === null || piece.groupAtt === ''))
        {
          pieceIds.push(`${piece.heat}/${piece.piece}`);
        }
      })

      return pieceIds.join(', ');
    }
  ),

  selectDefectGroupAttIds: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null)
      {
        return '';
      }

      const groupAttIds: string[] = [];

      (defect.pieces || []).forEach((piece) => {
        if (piece.isPiece && piece.groupAtt != null && piece.groupAtt !== '')
        {
          if (groupAttIds.length === 0)
          {
            if (piece.heat != null && piece.heat !== '') 
            {
              groupAttIds.push(`${piece.heat}/`);
            }
            if (piece.groupAtt != null && piece.groupAtt !== '' && piece.qcNum != null && piece.qcNum !== '')
            {
              groupAttIds.push(`${piece.groupAtt}/${piece.qcNum}`);
            }
            else
            {
              if (piece.groupAtt != null && piece.groupAtt !== '')
              {
                groupAttIds.push(`${piece.groupAtt}`);
              }
              else
              {
                groupAttIds.push(`${piece.qcNum}`);
              }
            }
          }
        }
      })

      return groupAttIds.join(', ');
    }
  ),

  selectDefectPlaceOfFixationId: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.placeOfFixationId ?? null;
    }
  ),

  selectDefectPriorityId: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.priorityId ?? null;
    }
  ),

  selectDefectProduct: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.product ?? null;
    }
  ),

  selectDefectDefectId: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.defectId ?? null;
    }
  ),

  selectDefectExternalNumber: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.numExternal ?? null;
    }
  ),

  selectDefectNote: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.note ?? null;
    }
  ),

  selectDefectIsViolationOfRequirements: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.isViolationOfRequirements ?? false;
    }
  ),

  selectDefectViolatedRequirements: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.violatedRequirements ?? null;
    }
  ),

  selectDefectIsPossibilityUsingRejected: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.isPossibilityUsingRejected ?? false;
    }
  ),

  selectDefectWayToUseRegectedId: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.wayToUseRegectedId ?? null;
    }
  ),

  selectDefectIsJoinInspectionExpanded: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.__isJoinInspection ?? false;
    }
  ),

  selectDefectJoinInspectionAct: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.actJoinInsp ?? null;
    }
  ),

  selectDefectJoinInspectionTimestamp: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];

      return allDefects.find((defect) => defect.guid === defectGuid)?.timestampJoinInsp ?? null;
    }
  ),

  selectDefectIsOk: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
      const defect = allDefects.find((defect) => defect.guid === defectGuid) ?? null

      if (defect === null)
      {
        return true;
      }

      const defectPass= defect.defectId !== null && defect.defectId !== -1;
      const piecesPass = (defect.pieces || []).length > 0;
      const violationPass = defect.isViolationOfRequirements === false || defect.violatedRequirements !== null;
      const rejectPass = defect.isPossibilityUsingRejected === false || defect.wayToUseRegectedId !== null;
      const joinInspectionPass = defect.__isJoinInspection === false ||
        (
          defect.actJoinInsp !== null
          &&
          defect.timestampJoinInsp !== null
          &&
          defect.files.some((file) => file.type === types.inspection.DefectFileType.ACT_JOIN_INSP && file.mode !== types.inspection.DefectFileMode.DEL)
        );

      const piecesCorrectPass = (defect.pieces || []).every((piece) => 
        getPieceErrorMessage(
          piece,
          state.inspectionEdit.generalIsEntryAccounting,
          state.authCommon.account!.isWireRod,
          false,
          state.authCommon.account!
        ) === ''
      )

      return defectPass && piecesPass && piecesCorrectPass && violationPass && rejectPass && joinInspectionPass;
    }
  ),

  selectDefectComplaintType: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null || defect.complaint === null || defect.complaint.typeComplaint === null)
      {
        return null;
      }

      return defect.complaint.typeComplaint;
    }
  ),

  selectDefectComplaintTypeStr: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null || defect.complaint === null || defect.complaint.typeComplaint === null)
      {
        return null;
      }

      return defect.complaint.typeComplaint === ComplaintType.COMPLAINT ? 'Рекламация': 'Замечание'
    }
  ),

  selectDefectControlButtons: (state: RootState):
    Record<
      'adddefect' | 'addcomplaint' | 'gotocomplaint' | 'gotocomplaints' | 'next',
      { visible: boolean, label: string }
    >=> {

    const IS_NEW = InspectionState.NEW;
    const IS_DRAFT = InspectionState.DRAFT;
    const IS_CREATE = InspectionState.CREATE;
    
    const isEntryAccounting = state.inspectionEdit.generalIsEntryAccounting;
    const inspectionState = state.inspectionEdit.generalInspectionState;
    const isGroup = state.inspectionEdit.generalIsGroup;
    const isExistComplaint = (state.inspectionEdit.generalEdited?.inspectionDefects ?? []).some((defect) => defect.complaint !== null);
    const account = state.authCommon.account!;

    const result = {
      adddefect: {
        visible: true,
        label: 'Добавить дефект'
      },
      addcomplaint: {
        visible: false,
        label: 'Добавить требование'
      },
      gotocomplaint: {
        visible: false,
        label: 'Перейти к требованию'
      },
      gotocomplaints: {
        visible: false,
        label: 'Перейти к требованиям'
      },
      next: {
        visible: true,
        label: 'Далее'
      },
    };

    if (isEntryAccounting)
    {
      result.adddefect.visible = false;
      result.next.label = [IS_NEW, IS_DRAFT, IS_CREATE].includes(inspectionState) ? 'Завершить' : 'Изменить';
    }
    else
    {
      if (!isGroup)
      {
			  result.adddefect.visible = false;
			  if (account.role === AccountRole.ClientAdmin)
        {
				  if (isExistComplaint)
          {
            result.gotocomplaint.visible = true;
				  }
          else
          {
            result.addcomplaint.visible = true;
				  }
			  }
        else if (account.role === AccountRole.Stpk)
        {
				  result.gotocomplaint.visible = true;
			  }
		  }
      else if (inspectionState === InspectionState.ERROR)
      {
			  if ((account.role === AccountRole.ClientAdmin && isExistComplaint) || account.role === AccountRole.Stpk)
        {
				  result.gotocomplaint.visible = true;
			  }
		  }
		  if (account.role === AccountRole.Client || account.role === AccountRole.Contractor || !isGroup || inspectionState === InspectionState.ERROR)
      {
        result.next.label = [IS_NEW, IS_DRAFT, IS_CREATE].includes(inspectionState) ? 'Завершить осмотр' : 'Завершить изменения';
		  }
      else
      {
        result.gotocomplaints.visible = true;
        result.next.visible = false;
      }
    }

    return result;
  },

  selectDefectControlButtonsV2: (state: RootState):
    Record<
      'adddefect' | 'addcomplaint' | 'gotocomplaint' | 'next',
      { visible: boolean, label: string, action: string }
    >=> {

    const IS_NEW = InspectionState.NEW;
    const IS_DRAFT = InspectionState.DRAFT;
    const IS_CREATE = InspectionState.CREATE;
    
    const isEntryAccounting = state.inspectionEdit.generalIsEntryAccounting;
    const inspectionState = state.inspectionEdit.generalInspectionState;
    const inspection = state.inspectionEdit.generalEdited;
    const isGroup = state.inspectionEdit.generalIsGroup;
    const isExistComplaint = (state.inspectionEdit.generalEdited?.inspectionDefects ?? []).some((defect) => defect.complaint !== null);
    const isChangeToComplaint = state.inspectionEdit.generalIsChangeToComplaint;
    const isChangeToComplaintFinance = state.inspectionEdit.generalIsChangeToComplaintFinance;

    const account = state.authCommon.account!;

    const result = {
      adddefect: {
        visible: true,
        label: 'Добавить дефект',
        action: 'adddefect'
      },
      addcomplaint: {
        visible: false,
        label: 'Добавить требование',
        action: 'addcomplaint',
      },
      gotocomplaint: {
        visible: false,
        label: 'Перейти к требованию',
        action: '',
      },
      next: {
        visible: true,
        label: 'Далее',
        action: '',
      },
    };

    if (isEntryAccounting)
    {
      result.adddefect.visible = false;
      result.next.label = [IS_NEW, IS_DRAFT, IS_CREATE].includes(inspectionState) ? 'Завершить' : 'Изменить';
    }
    else
    {
      if (!isGroup)
      {
			  result.adddefect.visible = false;
			  if (account.role === AccountRole.ClientAdmin)
        {
				  if (isExistComplaint)
          {
            result.gotocomplaint.visible = true;
				  }
          else
          {
            result.addcomplaint.visible = true;
				  }
			  }
        else if (account.role === AccountRole.Stpk)
        {
				  result.gotocomplaint.visible = true;
			  }
		  }
      else if (inspectionState === InspectionState.ERROR)
      {
        result.adddefect.visible = false;
			  if ((account.role === AccountRole.ClientAdmin && isExistComplaint) || account.role === AccountRole.Stpk)
        {
				  result.gotocomplaint.visible = true;
			  }
		  }
		  if (account.role === AccountRole.Client || account.role === AccountRole.Contractor || !isGroup || inspectionState === InspectionState.ERROR)
      {
        result.next.label = [IS_NEW, IS_DRAFT, IS_CREATE].includes(inspectionState) ? 'Завершить осмотр' : 'Завершить изменения';
		  }
    }

    if (isGroup)
    {
      result.gotocomplaint.action = 'goto_complaintlist';
    }
    else
    {
      if (isChangeToComplaint)
      {
        result.gotocomplaint.action = 'goto_complaintitem_changetocomplaint';
      }
      else if (isChangeToComplaintFinance)
      {
        result.gotocomplaint.action = 'goto_complaintitem_changetocomplaintfinance';
      }
      else if (inspectionState === InspectionState.EDIT && inspection!.status.code === InspectionStatusCode.INTERNAL)
      {
        result.gotocomplaint.action = 'goto_complaintitem_edit';
      }
      else
      {
        result.gotocomplaint.action = 'goto_complaintitem_view';
      }
    }

    
    if (
      [AccountRole.Client, AccountRole.Contractor].includes(account.role)
      ||
      isEntryAccounting
      ||
      !isGroup
      ||
      inspectionState === InspectionState.ERROR
    )
    {
      result.next.action = 'complete';
    }
    else
    {
      result.next.action = 'goto_complaintlist';
    }

    return result;
  },

  selectDefectIsFilledError: (state: RootState) => {
    const allDefects = state.inspectionEdit.generalEdited?.inspectionDefects ?? [];
    const isEntryAccounting = state.inspectionEdit.generalIsEntryAccounting;
    
    return allDefects.some((defect) => {
      return !isEntryAccounting && (defect.defectTitle === null || defect.defectTitle === '')
    })
  },

  selectDefectFiles: createSelector(
    [
      (state: RootState, defectGuid: string) => ({ state, defectGuid })
    ],
    ({ state, defectGuid }) => {
      const defect = (state.inspectionEdit.generalEdited?.inspectionDefects ?? [])
        .find((item) => item.guid === defectGuid) ?? null;

      if (defect === null)
      {
        return null;
      }

      return defect.files;
    }
  ),
}

// #endregion

// #region ПОЗИЦИИ ПРОДУКЦИИ

const isPiecesEquals = (edited: types.inspection.Piece, original:types.inspection.Piece, account: types.auth.Account): boolean => {
  let isEquals = true;

  if (edited.isPiece !== original.isPiece)
  {
    isEquals = false;
  }
  else
  {
    if (edited.isPiece)
    {
      if (account.isWireRod)
      {
        if (
          Util.nullOrEmptyStringTo(edited.heat, 'X') !== Util.nullOrEmptyStringTo(original.heat, 'X')
          ||
          Util.nullOrEmptyStringTo(edited.piece, 'X') !== Util.nullOrEmptyStringTo(original.piece, 'X')
          ||
          Util.nullOrEmptyStringTo(edited.groupAtt, 'X') !== Util.nullOrEmptyStringTo(original.groupAtt, 'X')
          ||
          Util.nullOrEmptyStringTo(edited.qcNum, 'X') !== Util.nullOrEmptyStringTo(original.qcNum, 'X')
        )
        {
          isEquals = false;
        }
      }
      else
      {
        if (
          Util.nullOrEmptyStringTo(edited.heat, 'X') !== Util.nullOrEmptyStringTo(original.heat, 'X')
          ||
          Util.nullOrEmptyStringTo(edited.piece, 'X') !== Util.nullOrEmptyStringTo(original.piece, 'X')
        )
        {
          isEquals = false;
        }
      }
    }
    else
    {
      if ((edited.qmetId ?? 0) !== (original.qmetId ?? 0))
      {
        isEquals = false;
      }
    }
  }

  if (isEquals)
  {
    if (
      (edited.weightBeforeProcessing ?? 0) !== (original.weightBeforeProcessing ?? 0)
      ||
      (edited.weightAfterProcessing ?? 0) !== (original.weightAfterProcessing ?? 0)
      ||
      (edited.weight ?? 0) !== (original.weight ?? 0)
      ||
      (edited.unit.id ?? 0) !== (original.unit.id ?? 0)
      ||
      Util.nullOrEmptyStringTo(edited.note, 'X') !== Util.nullOrEmptyStringTo(original.note, 'X')
    )
    {
      isEquals = false;
    }
  }

  if (isEquals)
  {
    if (
      (edited.topography.defectTpgIdSize ?? 0) !== (original.topography.defectTpgIdSize ?? 0)
      ||
      (edited.topography.defectSize ?? 0) !== (original.topography.defectSize ?? 0)
      ||
      (edited.topography.defectTpgIdStep ?? 0) !== (original.topography.defectTpgIdStep ?? 0)
      ||
      (edited.topography.defectStep ?? 0) !== (original.topography.defectStep ?? 0)
      ||
      (edited.topography.defectTpgIdDistance ?? 0) !== (original.topography.defectTpgIdDistance ?? 0)
      ||
      (edited.topography.defectDistanceFromEdge ?? 0) !== (original.topography.defectDistanceFromEdge ?? 0)
    )
    {
      isEquals = false;
    }
  }

  if (isEquals)
  {
    if (edited.fields !== null && original.fields !== null)
    {
      if (edited.fields.length !== original.fields.length)
      {
        isEquals = false;
      }
      else
      {
        for (const field of edited.fields)
        {
          const fieldO = original.fields.find((item) => item.id === field.id) ?? null;
          if (fieldO === null)
          {
            isEquals = false;
            break;
          }
          else
          {
            if (Util.nullOrEmptyStringTo(fieldO.value, 'X') !== Util.nullOrEmptyStringTo(field.value, 'X'))
            {
              isEquals = false;
              break;
            }
          }
        }
      }
    }
  }

  if (isEquals)
  {
    if (edited.mode === PieceMode.NEW)
    {
      if (edited.files.length !== original.files.length)
      {
        isEquals = false
      }
      else
      {
        isEquals = edited.files.every((file) => original.files.find((fileO) => fileO.guid === file.guid) !== undefined);
      }
    }
    else
    {
      for (const file of edited.files)
      {
        if (file.mode !== PieceFileMode.EXIST)
        {
          isEquals = false;
          break;
        }
      }
    }
  }

  return isEquals;
}

// #region addPieceAsync
type AddPieceArgs = void;
type AddPieceResolve = {
  pieceEditedQmetIdError: string;
  pieceEditedIsDouble: boolean;
  externalDoubles: types.inspection.Inspection[];
};
export type AddPieceReject = string;
export const addPieceAsync = createAsyncThunk<
  AddPieceResolve,
  AddPieceArgs,
  {
    state: RootState,
    rejectValue: AddPieceReject,
  }
>(
  `${SLICE_NAME}/addPieceAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const account = authCommonSelectors.selectAccount(state)!;
    const isEntryAccounting = generalSelectors.selectGeneralIsEntryAccounting(state);
    const isPiece = pieceSelectors.selectPieceEditedIsPiece(state);
    const isWireRod = account.isWireRod;

    // ПРОВЕРКА НА НАЛИЧИЕ ИЗМЕНЕНИЙ
    const edited = pieceSelectors.selectPieceEdited(state);

    if (edited !== null)
    {
      let isEquals = true;
      
      const original = pieceSelectors.selectPieceEditedOriginal(state)!;

      if ((edited.mode === PieceMode.NEW) && (original === null))
      {
        isEquals = false;
      }
      else
      {
        isEquals = isPiecesEquals(edited, original, account);
      }

      if (isEquals)
      {
        return thunkAPI.rejectWithValue('Изменения отсутствуют');
      }
    }
    
    // ПРОВЕРКА ВВОДА ВСЕХ ОБЯЗАТЕЛЬНЫХ ПОЛЕЙ
    const pieceErrors = getPieceErrorMessage(state.inspectionEdit.pieceEdited!, isEntryAccounting, isWireRod, false, account);

    if (pieceErrors.length > 0)
    {
      return thunkAPI.rejectWithValue(pieceErrors);
    }

    // ПРОВЕРКА ПРАВИЛЬНОСТИ ВВОДА НЕКОТОРЫХ ПОЛЕЙ (ИДЕНТИФИКАЦИОННЫЙ НОМЕР)
    if (!isPiece)
    {
      const qmetId = pieceSelectors.selectPieceEditedQmetId(state);

      if (qmetId !== null && `${qmetId}`.length < Constants.PIECE_NUM_ID_LENGTH_MIN)
      {
        return {
          pieceEditedQmetIdError: `Длина идентификационного номера должна быть от ${Constants.PIECE_NUM_ID_LENGTH_MIN} до ${Constants.PIECE_NUM_ID_LENGTH_MAX}`,
          pieceEditedIsDouble: false,
          externalDoubles: [],
        }
      }
    }

    // ПРОВЕРКА НА ДУБЛИ ВНУТРЕННИЕ
    const qmetId = pieceSelectors.selectPieceEditedQmetId(state);
    const heat = pieceSelectors.selectPieceEditedHeat(state);
    const piece = pieceSelectors.selectPieceEditedPiece(state);
    const groupAtt = pieceSelectors.selectPieceEditedGroupAtt(state);
    const qcNum = pieceSelectors.selectPieceEditedQCNum(state);

    const pieceEditedGuid = pieceSelectors.selectPieceEditedGuid(state);
    const pieces = pieceSelectors.selectPiecePiecesForExpandedDefect(state);

    const isDouble = (pieces || []).find(
      (p) => p.guid !== pieceEditedGuid && (
        (qmetId !== null && p.qmetId === qmetId)
        ||
        (piece !== null && p.heat === heat && p.piece === piece)
      )
    ) !== undefined;

    if (isDouble)
    {
      return {
        pieceEditedQmetIdError: '',
        pieceEditedIsDouble: true,
        externalDoubles: [],
      }
    }

    // ПРОВЕРКА НА ДУБЛИ ВНЕШНЯЯ
    thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Проверка на дубли'));

    try
    {
      const externalDoubles = await Api.postInspectionDuplicateByGuid({
        guid: generalSelectors.selectGeneralEditedGuid(state),
        heat,
        piece,
        groupAtt,
        qcNum,
        qmetId,
        isEntryAccounting
      });

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      return {
        pieceEditedQmetIdError: '',
        pieceEditedIsDouble: false,
        externalDoubles,
      }
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      return {
        pieceEditedQmetIdError: '',
        pieceEditedIsDouble: false,
        externalDoubles: [],
      }
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(addPieceAsync.fulfilled, (state, action) => {
      if (action.payload.pieceEditedQmetIdError !== '')
      {
        state.pieceEditedQmetIdError = action.payload.pieceEditedQmetIdError;
      }
      else if (action.payload.pieceEditedIsDouble)
      {
        state.pieceEditedIsDouble = true;
      }
      else if (action.payload.externalDoubles.length > 0)
      {
        state.pieceEditedExternalDoublesOpened = true;
        state.pieceEditedExternalDoublesList = [...action.payload.externalDoubles];
      }
      else
      {
        const gE = state.generalEdited;
        const pE = state.pieceEdited;

        if (gE !== null && pE !== null)
        {
          const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

          if (eD !== null)
          {
            const piece = (eD.pieces || []).find((item) => item.guid === pE.guid) ?? null;

            if (piece === null)
            {
              if (eD.pieces === null)
              {
                eD.pieces = [pE];
              }
              else
              {
                eD.pieces.push(pE);
              }
            }
            else
            {
              eD.pieces = (eD.pieces || []).map((item) => item.guid === pE.guid ? { ...pE } : item);
            }
          }
        }

        state.pieceEdited = null;
      }
    })
    .addCase(addPieceAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region closePieceAsync
type ClosePieceArgs = void;
type ClosePieceResolve = {
  hasChanges: boolean;
};
export type ClosePieceReject = void;
export const closePieceAsync = createAsyncThunk<
  ClosePieceResolve,
  ClosePieceArgs,
  {
    state: RootState,
    rejectValue: ClosePieceReject,
  }
>(
  `${SLICE_NAME}/closePieceAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const account = authCommonSelectors.selectAccount(state)!;

    // ПРОВЕРКА НА НАЛИЧИЕ ИЗМЕНЕНИЙ
    let isEquals = true;
    const edited = pieceSelectors.selectPieceEdited(state);

    if (edited !== null)
    {
      const original = pieceSelectors.selectPieceEditedOriginal(state)!;

      if ((edited.mode === PieceMode.NEW) && (original === null))
      {
        isEquals = false;
      }
      else
      {
        isEquals = isPiecesEquals(edited, original, account);
      }
    }

    return {
      hasChanges: !isEquals
    }
  }
);
// #endregion

// #region downloadPhotoAsync
type DownloadPhotoArgs = {
  fileGuid: string;
};
type DownloadPhotoResolve = {
  fileGuid: string;
  dataUrl: string;
  binaryStr: string;
  name: string;
  mime: string;
};
export type DownloadPhotoReject = ApiError;
export const downloadPhotoAsync = createAsyncThunk<
  DownloadPhotoResolve,
  DownloadPhotoArgs,
  {
    state: RootState,
    rejectValue: DownloadPhotoReject,
  }
>(
  `${SLICE_NAME}/downloadPhotoAsync`,
  async (args, thunkAPI) => {
    const pE = thunkAPI.getState().inspectionEdit.pieceEdited;

    if (pE === null)
    {
      return {
        fileGuid: args.fileGuid,
        dataUrl: '',
        binaryStr: '',
        name: '',
        mime: '',
      }
    }
    else
    {
      let updatedFile: Nullable<types.inspection.PieceFile> = null;

      for (const file of pE.files)
      {
        if (file.guid === args.fileGuid)
        {
          updatedFile = file;
        }
      }

      if (updatedFile === null)
      {
        return {
          fileGuid: args.fileGuid,
          dataUrl: '',
          binaryStr: '',
          name: '',
          mime: '',
        }
      }
      else
      {
        try
        {
          const remoteFile = await Api.getInspectionFileByGuid({ guid: args.fileGuid, source: 'piece' });
          const fileFromBlob = await Util.blobToFile(remoteFile.blob, updatedFile.name, 'image/jpeg');
          const dataUrlResult = await Util.imageToDataURL(fileFromBlob);
          const binaryStr = await Util.fileToBinaryString(fileFromBlob);

          if (dataUrlResult.ok)
          {
            return {
              fileGuid: args.fileGuid,
              dataUrl: dataUrlResult.result,
              binaryStr: binaryStr,
              name: updatedFile.name,
              mime: 'image/jpeg',
            }
          }
          else
          {
            return {
              fileGuid: args.fileGuid,
              dataUrl: Util.noPhoto,
              binaryStr: '',
              name: updatedFile.name,
              mime: 'image/jpeg',
            }
          }
        }
        catch(error)
        {
          return {
            fileGuid: args.fileGuid,
            dataUrl: Util.noPhoto,
            binaryStr: '',
            name: updatedFile.name,
            mime: 'image/jpeg',
          }
        }
      }
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(downloadPhotoAsync.fulfilled, (state, action) => {
      if (state.pieceEdited !== null)
      {
        for (const file of state.pieceEdited.files)
        {
          if (file.guid === action.payload.fileGuid)
          {
            file.__dataUrl = action.payload.dataUrl;
            file.__binaryStr = action.payload.binaryStr;
            file.__name = action.payload.name;
            file.__mime = action.payload.mime;
          }
        }
      }

      if (state.generalEdited !== null)
      {
        for (const defect of state.generalEdited.inspectionDefects)
        {
          for (const piece of (defect.pieces || []))
          {
            for (const file of piece.files)
            {
              if (file.guid === action.payload.fileGuid)
              {
                file.__dataUrl = action.payload.dataUrl;
                file.__binaryStr = action.payload.binaryStr;
                file.__name = action.payload.name;
                file.__mime = action.payload.mime;
              }
            }
          }
        }
      }
    })
    .addCase(downloadPhotoAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region downloadCertAsync
type DownloadCertArgs = {
  qcNum: string;
};
type DownloadCertResolve = void;
export type DownloadCertReject = ApiError;
export const downloadCertAsync = createAsyncThunk<
  DownloadCertResolve,
  DownloadCertArgs,
  {
    state: RootState,
    rejectValue: DownloadCertReject,
  }
>(
  `${SLICE_NAME}/downloadCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Постановка в очередь задачи на скачивание сертификата'));
      
      await Api.postQCCheck({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(downloadCertAsync.fulfilled, (state, action) => {
      state.generalOpStatusLabel = '';
      state.pieceCertDownloadOpened = true;
      state.pieceEdited!.qmetData.qcState = QCState.LOADING;
    })
    .addCase(downloadCertAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region checkCertAsync
type CheckCertArgs = {
  inspectionGuid: string;
};
type CheckCertResolve = QCState;
export type CheckCertReject = ApiError;
export const checkCertAsync = createAsyncThunk<
  CheckCertResolve,
  CheckCertArgs,
  {
    state: RootState,
    rejectValue: CheckCertReject,
  }
>(
  `${SLICE_NAME}/checkCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Проверка статуса загрузки сертификата'));
      
      const inspection = await Api.getInspectionByGuid({ guid: args.inspectionGuid });
      const selectedPieceGuid = pieceSelectors.selectPieceEditedGuid(thunkAPI.getState())!;

      const updatedDefect = inspection.inspectionDefects.find((defect) => (defect.pieces || []).find((piece) => piece.guid === selectedPieceGuid) !== undefined)!;
      const updatedPiece = (updatedDefect.pieces || []).find((piece) => piece.guid === selectedPieceGuid)!;

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      return updatedPiece.qmetData.qcState;
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(checkCertAsync.fulfilled, (state, action) => {
      state.generalOpStatusLabel = '';
      state.pieceEdited!.qmetData.qcState = action.payload;
    })
    .addCase(checkCertAsync.rejected, (state, action) => {
    });
});
// #endregion

// #region viewCertAsync
type ViewCertArgs = {
  qcNum: string;
};
type ViewCertResolve = void;
export type ViewCertReject = ApiError;
export const viewCertAsync = createAsyncThunk<
  ViewCertResolve,
  ViewCertArgs,
  {
    state: RootState,
    rejectValue: ViewCertReject,
  }
>(
  `${SLICE_NAME}/viewCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Получение файла сертификата'));
      
      const result = await Api.getQCFile({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      //window.open(result.dataUrl, '_blank')?.focus();
      var a = document.createElement("a");
      a.href = result.dataUrl;
      a.download = `cert_${args.qcNum}.pdf`;
      a.click();
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region shareCertAsync
type ShareCertArgs = {
  qcNum: string;
};
type ShareCertResolve = void;
export type ShareCertReject = ApiError;
export const shareCertAsync = createAsyncThunk<
  ShareCertResolve,
  ShareCertArgs,
  {
    state: RootState,
    rejectValue: ShareCertReject,
  }
>(
  `${SLICE_NAME}/shareCertAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Получение файла сертификата'));
      
      const result = await Api.getRawQCFile({ qcNum: args.qcNum });

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      const file = new File([result.rawData], `Сертификат №${args.qcNum}.pdf`, { type: 'application/pdf' });
      navigator.share({
        title: `Сертификат №${args.qcNum}.pdf`,
        text: `Файл сертификата из приложения Checksteel`,
        files: [file]
      });
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region viewPDFAsync
type ViewPDFArgs = {
  fileName: string;
  guid: string;
  source: 'piece' | 'inspection';
};
type ViewPDFResolve = void;
export type ViewPDFReject = ApiError;
export const viewPDFAsync = createAsyncThunk<
  ViewPDFResolve,
  ViewPDFArgs,
  {
    state: RootState,
    rejectValue: ViewPDFReject,
  }
>(
  `${SLICE_NAME}/viewPDFAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Получение PDF-файла'));
      
      const result = await Api.getInspectionFileAsPDFByGuid({ guid: args.guid, source: args.source });

      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));

      var a = document.createElement("a");
      a.href = result.dataUrl;
      a.download = args.fileName;
      a.click();
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion


const pieceReducers = {
  pieceEditOpened: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditOpened = true;
  },

  pieceEditClosed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditOpened = false;
  },

  pieceForDeleteOneMarked: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (!state.pieceMarkedForDeleteGuids.includes(action.payload))
    {
      state.pieceMarkedForDeleteGuids.push(action.payload);
    }
  },

  pieceForDeleteOneUnmarked: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceMarkedForDeleteGuids.includes(action.payload))
    {
      state.pieceMarkedForDeleteGuids = state.pieceMarkedForDeleteGuids.filter((guid) => guid !== action.payload)
    }
  },

  pieceForDeleteAllMarked: (state: WritableDraft<InspectionEditState>, action: StringArrayChangedAction) => {
    state.pieceMarkedForDeleteGuids = [...action.payload];
  },

  pieceForDeleteAllUnmarked: (state: WritableDraft<InspectionEditState>) => {
    state.pieceMarkedForDeleteGuids = [];
  },

  pieceForDeleteMarkedDeleted: (state: WritableDraft<InspectionEditState>) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.pieces = (eD.pieces || [])
          .map(
            (piece) => state.pieceMarkedForDeleteGuids.includes(piece.guid) ?
              (piece.mode === PieceMode.NEW ? null : { ...piece, mode: PieceMode.DEL })
              :
              piece
          )
          .filter((piece) => piece !== null) as types.inspection.Piece[];
        state.pieceMarkedForDeleteGuids = [];
      }
    }
  },

  pieceOneDeleted: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    const gE = state.generalEdited;

    if (gE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        eD.pieces = (eD.pieces || [])
          .map(
            (piece) => piece.guid === action.payload ?
              (piece.mode === PieceMode.NEW ? null : { ...piece, mode: PieceMode.DEL })
              :
              piece
          )
          .filter((piece) => piece !== null) as types.inspection.Piece[];
      }
    }
  },

  pieceForEditSelected: (state: WritableDraft<InspectionEditState>, action: NullableStringChangedAction) => {
    if (action.payload === null)
    {
      const pieceGuid = Util.guid(true);
      const unitId = Preferences.preferredWeightUnit;
      const prefferedUnit = state.generalUnitRefer.find((item) => item.id === unitId && item.isActual) ?? null;
      const firstActualUnit = state.generalUnitRefer.find((item) => item.isActual)!;
      const workUnit = prefferedUnit ?? firstActualUnit;
      const details = workUnit.details.find((item) => item.isEntryAccounting === state.generalIsEntryAccounting);
      state.pieceEdited = {
        fields: [],
        files: [],
        groupAtt: null,
        guid: pieceGuid,
        heat: null,
        pieceNumId: null,
        isPiece: Preferences.preferredMethodUEEnter === 'piece',
        mode: types.inspection.PieceMode.NEW,
        note: '',
        piece: null,
        qcNum: null,
        qmetData: {
          group: null,
          qcNum: null,
          qcState: QCState.NONE
        },
        qmetId: null,
        topography: {
          defectDistanceFromEdge: null,
          defectSize: null,
          defectStep: null,
          defectTpgIdDistance: null,
          defectTpgIdSize: null,
          defectTpgIdStep: null,
          defectTpgTitleDistance: null,
          defectTpgTitleSize: null,
          defectTpgTitleStep: null,
        },
        weightBeforeProcessing: null,
        weightAfterProcessing: null,
        marriageShare: null,
        weight: null,
        isSmc: false,
        unit: {
          id: workUnit.id,
          title: workUnit.title,
          fieldTitle: details?.fieldTitle ?? 'Вес',
          fieldHint: details?.fieldHint ?? 'Вес',
        }
      };
      state.pieceEditOpened = true;
      state.pieceSelectedGuid = pieceGuid;
      state.pieceEditedWeight = '';
      state.pieceEditedWeightBeforeProcessing = '';
      state.pieceEditedWeightAfterProcessing = '';
      state.pieceEditedWeightAfterProcessingError = '';
      state.pieceEditedQmetIdError = '';
      state.pieceEditedHeatError = '';
      state.pieceEditedPieceError = '';
      state.pieceEditedGroupAttError = '';
      state.pieceEditedQCNumError = '';
      state.pieceEditedCustomFieldsErrors = {}
      state.pieceEditedIsMarriageShareExpanded = false;
      state.pieceEditedWeightError = '';
      state.pieceEditedNoteError = '';
      state.pieceEditedIsTopographyExpanded = false;
      state.pieceEditedTopoSize = '';
      state.pieceEditedTopoStep = '';
      state.pieceEditedTopoDistance = '';
    }
    else
    {
      const piece = (state.generalEdited!.inspectionDefects
        .find((defect) => defect.guid === state.defectExpandedGuid)!.pieces ?? [])
        .find((piece:types.inspection.Piece) => piece.guid === action.payload)!;

      const pieceEdited = (JSON.parse(JSON.stringify(piece)) as types.inspection.Piece);

      pieceEdited.topography.defectSize = pieceEdited.topography.defectSize === 0 ? null : pieceEdited.topography.defectSize;
      pieceEdited.topography.defectDistanceFromEdge = pieceEdited.topography.defectDistanceFromEdge === 0 ? null : pieceEdited.topography.defectDistanceFromEdge;
      pieceEdited.topography.defectStep = pieceEdited.topography.defectStep === 0 ? null : pieceEdited.topography.defectStep;
      pieceEdited.weightBeforeProcessing = pieceEdited.weightBeforeProcessing === 0 ? null : pieceEdited.weightBeforeProcessing;
      pieceEdited.weightAfterProcessing = pieceEdited.weightAfterProcessing === 0 ? null : pieceEdited.weightAfterProcessing;
      
      state.pieceEdited = pieceEdited;
      state.pieceEditOpened = true;
      state.pieceSelectedGuid = piece.guid;
      state.pieceEditedWeightBeforeProcessing = `${pieceEdited.weightBeforeProcessing ?? ''}`;
      state.pieceEditedWeightAfterProcessing = `${pieceEdited.weightAfterProcessing ?? ''}`;
      state.pieceEditedWeightAfterProcessingError = '';
      state.pieceEditedWeight = `${pieceEdited.weight ?? ''}`;
      state.pieceEditedQmetIdError = '';
      state.pieceEditedHeatError = '';
      state.pieceEditedPieceError = '';
      state.pieceEditedGroupAttError = '';
      state.pieceEditedQCNumError = '';
      state.pieceEditedCustomFieldsErrors = {};
      state.pieceEditedIsMarriageShareExpanded = (
                                                   pieceEdited.weightAfterProcessing !== null
                                                   &&
                                                   pieceEdited.weightAfterProcessing !== 0
                                                 )
                                                 ||
                                                 (
                                                  pieceEdited.weightAfterProcessing !== null
                                                  &&
                                                  pieceEdited.weightAfterProcessing !== 0
                                                 );
      state.pieceEditedWeightError = '';
      state.pieceEditedNoteError = '';
      state.pieceEditedIsTopographyExpanded = !Object.values(pieceEdited.topography).every((value) => value === null);
      state.pieceEditedTopoSize = `${(pieceEdited.topography.defectSize ?? '')}`;
      state.pieceEditedTopoStep = `${(pieceEdited.topography.defectStep ?? '')}`;
      state.pieceEditedTopoDistance = `${(pieceEdited.topography.defectDistanceFromEdge ?? '')}`;
    }
  },

  pieceEditedIsPieceChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.isPiece = action.payload;
      if (action.payload)
      {
        state.pieceEdited.qmetId = null;
      }
      else
      {
        state.pieceEdited.heat = null;
        state.pieceEdited.piece = null;
        state.pieceEdited.qcNum = null;
        state.pieceEdited.groupAtt = null;
      }
      Preferences.setPreferredMethodUEEnter(action.payload ? 'piece' : 'id');

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedQmetIdChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.qmetId = action.payload === '' ? null : parseInt(action.payload, 10);

      const isDouble = (state.generalEdited!.inspectionDefects
        .find((defect) => defect.guid === state.defectExpandedGuid)!.pieces ?? [])
        .find((piece: types.inspection.Piece) => piece.guid !== state.pieceEdited!.guid && piece.qmetId === state.pieceEdited!.qmetId) !== undefined;

      if (isDouble)
      {
        state.pieceEditedIsDouble = true;
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedQmetIdErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedQmetIdError = '';
  },

  pieceEditedHeatChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.heat = action.payload.trim() === '' ? null : action.payload.trim();

      const isDouble = (state.generalEdited!.inspectionDefects
        .find((defect) => defect.guid === state.defectExpandedGuid)!.pieces ?? [])
        .find((piece: types.inspection.Piece) => piece.guid !== state.pieceEdited!.guid
          && (piece.heat === state.pieceEdited!.heat && piece.piece === state.pieceEdited!.piece)
        ) !== undefined;

      if (isDouble)
      {
        state.pieceEditedIsDouble = true;
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedHeatErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedHeatError = '';
  },

  pieceEditedPieceChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.piece = action.payload.trim() === '' ? null : action.payload;

      const isDouble = (state.generalEdited!.inspectionDefects
        .find((defect) => defect.guid === state.defectExpandedGuid)!.pieces ?? [])
        .find((piece: types.inspection.Piece) => piece.guid !== state.pieceEdited!.guid
          && (piece.heat === state.pieceEdited!.heat && piece.piece === state.pieceEdited!.piece)
        ) !== undefined;

      if (isDouble)
      {
        state.pieceEditedIsDouble = true;
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedPieceErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedPieceError = '';
  },

  pieceEditedGroupAttChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.groupAtt = action.payload.trim() === '' ? null : action.payload.trim();
      state.pieceEditedIsDouble = false;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedGroupAttErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedGroupAttError = '';
  },

  pieceEditedQCNumChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.qcNum = action.payload.trim() === '' ? null : action.payload.trim();
      state.pieceEditedIsDouble = false;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedQCNumErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedQCNumError = '';
  },

  pieceEditedCustomFieldChanged: (state: WritableDraft<InspectionEditState>, action: CustomFieldChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.fields = state.pieceEdited.fields
        .filter((item) => item.code !== action.payload.code)
        .concat(action.payload.value === '' ? [] : [action.payload]);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedCustomFieldErrorFixed: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    delete state.pieceEditedCustomFieldsErrors[action.payload];
  },

  pieceEditedIsMarriageShareChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedIsMarriageShareExpanded = action.payload;

      if (!action.payload)
      {
        state.pieceEdited.weightBeforeProcessing = null;
        state.pieceEditedWeightBeforeProcessing = '';
        state.pieceEdited.weightAfterProcessing = null;
        state.pieceEditedWeightAfterProcessing = '';
        state.pieceEdited.marriageShare = null;
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedWeightBeforeProcessingChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedWeightBeforeProcessing = action.payload;
      state.pieceEdited.weightBeforeProcessing = action.payload === '' ? null : parseFloat(action.payload);

      if ((state.pieceEdited.weightBeforeProcessing ?? 0) === 0 || (state.pieceEdited.weightAfterProcessing ?? 0) === 0)
      {
        state.pieceEditedWeightAfterProcessingError = '';
        state.pieceEdited.marriageShare = null;
      }
      else
      {
        if (state.pieceEdited.weightBeforeProcessing! < state.pieceEdited.weightAfterProcessing!)
        {
          state.pieceEditedWeightAfterProcessingError = 'Вес после переработки не может быть больше веса до переработки';
          state.pieceEdited.marriageShare = null;
        }
        else
        {
          state.pieceEditedWeightAfterProcessingError = '';
          state.pieceEdited.marriageShare = parseFloat((100 * (1 - (state.pieceEdited.weightAfterProcessing!/state.pieceEdited.weightBeforeProcessing!))).toFixed(2));
        }
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedWeightAfterProcessingChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedWeightAfterProcessing = action.payload;
      state.pieceEdited.weightAfterProcessing = action.payload === '' ? null : parseFloat(action.payload);

      if ((state.pieceEdited.weightBeforeProcessing ?? 0) === 0 || (state.pieceEdited.weightAfterProcessing ?? 0) === 0)
      {
        state.pieceEditedWeightAfterProcessingError = '';
        state.pieceEdited.marriageShare = null;
      }
      else
      {
        if (state.pieceEdited.weightBeforeProcessing! < state.pieceEdited.weightAfterProcessing!)
        {
          state.pieceEditedWeightAfterProcessingError = 'Вес после переработки не может быть больше веса до переработки';
          state.pieceEdited.marriageShare = null;
        }
        else
        {
          state.pieceEditedWeightAfterProcessingError = '';
          state.pieceEdited.marriageShare = parseFloat((100 * (1 - (state.pieceEdited.weightAfterProcessing!/state.pieceEdited.weightBeforeProcessing!))).toFixed(2));
        }
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedWeightChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedWeight = action.payload;
      state.pieceEdited.weight = action.payload === '' ? null : parseFloat(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedWeightErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedWeightError = '';
  },

  pieceEditedWeightUnitChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    if (state.pieceEdited !== null)
    {
      const unit = state.generalUnitRefer.find((item) => item.id === action.payload)!;
      const details = unit.details.find((item) => item.isEntryAccounting === state.generalIsEntryAccounting)!
      state.pieceEdited.unit = {
        id: unit.id,
        title: unit.title,
        fieldTitle: details.fieldTitle,
        fieldHint: details.fieldHint,
      };

      if (unit.isInteger && state.pieceEditedWeight !== '')
      {
        state.pieceEditedWeight = `${Math.floor(parseFloat(state.pieceEditedWeight))}`;
        state.pieceEdited.weight = parseFloat(state.pieceEditedWeight);
      }

      Preferences.setPreferredWeightUnit(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }

    }
  },

  pieceEditedNoteChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.note = action.payload.trim() === '' ? null : action.payload;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedNoteErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedNoteError = '';
  },

  pieceEditedPhotoAdded: (state: WritableDraft<InspectionEditState>, action: PhotoAddedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.files.push(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedPhotoDeleted: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEdited.files = state.pieceEdited.files
        .map((file) => {
          if (file.guid !== action.payload)
          {
            return file;
          }

          if (file.mode === types.inspection.PieceFileMode.EXIST)
          {
            return { ...file, mode: types.inspection.PieceFileMode.DEL };
          }
          
          return null;
        })
        .filter((file) => file !== null) as types.inspection.PieceFile[];

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedIsTopographyChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedIsTopographyExpanded = action.payload;

      if (!action.payload)
      {
        state.pieceEdited.topography.defectSize = null;
        state.pieceEdited.topography.defectTpgIdSize = null;
        state.pieceEdited.topography.defectTpgTitleSize = null;
        state.pieceEdited.topography.defectStep = null;
        state.pieceEdited.topography.defectTpgIdStep = null;
        state.pieceEdited.topography.defectTpgTitleStep = null;
        state.pieceEdited.topography.defectDistanceFromEdge = null;
        state.pieceEdited.topography.defectTpgIdDistance = null;
        state.pieceEdited.topography.defectTpgTitleDistance = null;
        state.pieceEditedTopoSize = '';
        state.pieceEditedTopoStep = '';
        state.pieceEditedTopoDistance = '';
      }

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoSizeIdChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    if (state.pieceEdited !== null)
    {
      const id = action.payload;
      const title = state.generalTopographyRefer.find((item) => item.id === id)!.title;
      state.pieceEdited.topography.defectTpgIdSize = id;
      state.pieceEdited.topography.defectTpgTitleSize = title;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoSizeChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedTopoSize = action.payload;
      state.pieceEdited.topography.defectSize = action.payload === '' ? null : parseFloat(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoSizeErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedTopoSizeError = '';
  },

  pieceEditedTopoStepIdChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    if (state.pieceEdited !== null)
    {
      const id = action.payload;
      const title = state.generalTopographyRefer.find((item) => item.id === id)!.title;
      state.pieceEdited.topography.defectTpgIdStep = id;
      state.pieceEdited.topography.defectTpgTitleStep = title;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoStepChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedTopoStep = action.payload;
      state.pieceEdited.topography.defectStep = action.payload === '' ? null : parseFloat(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoStepErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedTopoStepError = '';
  },

  pieceEditedTopoDistanceIdChanged: (state: WritableDraft<InspectionEditState>, action: NumberChangedAction) => {
    if (state.pieceEdited !== null)
    {
      const id = action.payload;
      const title = state.generalTopographyRefer.find((item) => item.id === id)!.title;
      state.pieceEdited.topography.defectTpgIdDistance = id;
      state.pieceEdited.topography.defectTpgTitleDistance = title;

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoDistanceChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.pieceEdited !== null)
    {
      state.pieceEditedTopoDistance = action.payload;
      state.pieceEdited.topography.defectDistanceFromEdge = action.payload === '' ? null : parseFloat(action.payload);

      if (state.pieceEdited.mode !== PieceMode.NEW)
      {
        const original = state.generalOriginal!.inspectionDefects
          .map((defect) => (defect.pieces ?? []).find((piece) => piece.guid === state.pieceEdited!.guid) ?? null)
          .filter((piece) => piece !== null)
          .pop() ?? null;

        if (original !== null && state.generalAccount !== null)
        {
          if (isPiecesEquals(state.pieceEdited, original, state.generalAccount))
          {
            state.pieceEdited.mode = PieceMode.EXIST;
          }
          else
          {
            state.pieceEdited.mode = PieceMode.EDIT;
          }
        }
      }
    }
  },

  pieceEditedTopoDistanceErrorFixed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedTopoDistanceError = '';
  },

  pieceEditedIsDoubleChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    state.pieceEditedIsDouble = action.payload;
  },

  pieceEditedExternalDoublesHidden: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedExternalDoublesOpened = false;
  },

  pieceEditedExternalDoublesShow: (state: WritableDraft<InspectionEditState>) => {
    if (state.pieceEditedExternalDoublesList.length > 0)
    {
      state.pieceEditedExternalDoublesOpened = true;
    }
  },

  pieceEditedExternalDoublesClosed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceEditedExternalDoublesOpened = false;
    state.pieceEditedExternalDoublesList = [];
  },

  pieceEditedExternalDoublesCompleted: (state: WritableDraft<InspectionEditState>) => {
    const gE = state.generalEdited;
    const pE = state.pieceEdited;

    if (gE !== null && pE !== null)
    {
      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

      if (eD !== null)
      {
        const piece = (eD.pieces || []).find((item) => item.guid === pE.guid) ?? null;

        if (piece === null)
        {
          if (eD.pieces === null)
          {
            eD.pieces = [pE]
          }
          else
          {
            eD.pieces.push(pE);
          }
        }
        else
        {
          eD.pieces = (eD.pieces || []).map((item) => item.guid === pE.guid ? { ...pE } : item);
        }
      }
    }

    state.pieceEditOpened = false;
    state.pieceEdited = null;
    state.pieceEditedExternalDoublesOpened = false;
    state.pieceEditedExternalDoublesList = [];
  },

  pieceCertDownloadClosed: (state: WritableDraft<InspectionEditState>) => {
    state.pieceCertDownloadOpened = false;
  },

}

const pieceSelectors = {
  selectPieceEditDialogTitle: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => {
      const gE = state.inspectionEdit.generalEdited;

      if (gE === null)
      {
        return '';
      }

      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;
      
      if (eD === null)
      {
        return '';
      }

      const piece = (eD.pieces || []).find((piece) => piece.guid === pieceGuid) ?? null;

      if (piece === null)
      {
        return 'Добавление позиции';
      }

      return 'Изменение позиции';
    }
  ),

  selectPieceEditDialogAcceptText: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => {
      const gE = state.inspectionEdit.generalEdited;

      if (gE === null)
      {
        return '';
      }

      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;
      
      if (eD === null)
      {
        return '';
      }

      const piece = (eD.pieces || []).find((piece) => piece.guid === pieceGuid) ?? null;

      if (piece === null)
      {
        return 'Добавить';
      }

      return 'Изменить';
    }
  ),

  selectPieceTitle: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => {
      const gE = state.inspectionEdit.generalEdited;

      if (gE === null)
      {
        return '';
      }

      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;
      
      if (eD === null)
      {
        return '';
      }

      const piece = (eD.pieces || []).find((piece) => piece.guid === pieceGuid) ?? null;

      if (piece === null)
      {
        return '';
      }
      
      return piece.isPiece ?
      (
        ((piece.groupAtt !== null && piece.groupAtt !== '') || (piece.qcNum !== null && piece.qcNum !== '')) ?
          'Плавка / Партия атт/ Сертиф'
          :
          'Плавка / Партия/Ед'
      )
      :
      'Идентификационный №';
    }
  ),

  selectPieceText: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => {
      const gE = state.inspectionEdit.generalEdited;

      if (gE === null)
      {
        return '';
      }

      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;
      
      if (eD === null)
      {
        return '';
      }

      const piece = (eD.pieces || []).find((piece) => piece.guid === pieceGuid) ?? null;

      if (piece === null)
      {
        return '';
      }

      const isGroupAttHasValue = piece.groupAtt !== null && piece.groupAtt !== '';
      const isQcNumHasValue = piece.qcNum !== null && piece.qcNum !== '';
      const isHeatHasValue = piece.heat !== null && piece.heat !== '';
      const isHeatEmpty = piece.heat === null || piece.heat === '';
      const isPieceHasValue = piece.piece !== null && piece.piece !== '';

      if (piece.isPiece)
      {
        if (isGroupAttHasValue || isQcNumHasValue)
        {
          if (isHeatHasValue && isGroupAttHasValue && isQcNumHasValue)
          {
            return `${piece.heat} / ${piece.groupAtt} / ${piece.qcNum}`;
          }
          else
          {
            if (isHeatHasValue)
            {
              if (isGroupAttHasValue)
              {
                return `${piece.heat} / ${piece.groupAtt} / Нет сертиф`;
              }
              else
              {
                return `${piece.heat} / Нет партии атт / ${piece.qcNum}`;
              }
            }
            else
            {
              if (isGroupAttHasValue && isQcNumHasValue)
              {
                return `Нет плавки / ${piece.groupAtt} / ${piece.qcNum}`;
              }
              else
              {
                if (isGroupAttHasValue)
                {
                  return `Нет плавки / ${piece.groupAtt} / Нет сертиф`;
                }
                else
                {
                  return `Нет плавки / Нет партии атт / ${piece.qcNum}`;
                }
              }
            }
          }
        }
        else
        {
          if (isHeatHasValue && isPieceHasValue)
          {
              return `${piece.heat} / ${piece.piece}`;
          }
          else
          {
            if (isHeatEmpty && isPieceHasValue)
            {
              return `Нет плавки / ${piece.piece}`;
            }
            else if (isHeatHasValue)
            {
              return `${piece.heat} / Нет партии/ед`;
            }
            else
            {
              return `Нет плавки / Нет партии/ед`;
            }
          }
        }
      }
      else
      {
        if (piece.qmetId === null || piece.qmetId === 0)
        {
          return 'Нет номера';
        }
        else
        {
          return `${piece.qmetId}`;
        }
      }
    }
  ),

  selectPieceEditedText: (state: RootState) => {
    const piece = state.inspectionEdit.pieceEdited;

    if (piece === null)
    {
      return '';
    }

    const isGroupAttHasValue = piece.groupAtt !== null && piece.groupAtt !== '';
    const isQcNumHasValue = piece.qcNum !== null && piece.qcNum !== '';
    const isHeatHasValue = piece.heat !== null && piece.heat !== '';
    const isHeatEmpty = piece.heat === null || piece.heat === '';
    const isPieceHasValue = piece.piece !== null && piece.piece !== '';

    if (piece.isPiece)
    {
      if (isGroupAttHasValue || isQcNumHasValue)
      {
        if (isHeatHasValue && isGroupAttHasValue && isQcNumHasValue)
        {
          return `${piece.heat} / ${piece.groupAtt} / ${piece.qcNum}`;
        }
        else
        {
          if (isHeatHasValue)
          {
            if (isGroupAttHasValue)
            {
              return `${piece.heat} / ${piece.groupAtt} / Нет сертиф`;
            }
            else
            {
              return `${piece.heat} / Нет партии атт / ${piece.qcNum}`;
            }
          }
          else
          {
            if (isGroupAttHasValue && isQcNumHasValue)
            {
              return `Нет плавки / ${piece.groupAtt} / ${piece.qcNum}`;
            }
            else
            {
              if (isGroupAttHasValue)
              {
                return `Нет плавки / ${piece.groupAtt} / Нет сертиф`;
              }
              else
              {
                return `Нет плавки / Нет партии атт / ${piece.qcNum}`;
              }
            }
          }
        }
      }
      else
      {
        if (isHeatHasValue && isPieceHasValue)
        {
            return `${piece.heat} / ${piece.piece}`;
        }
        else
        {
          if (isHeatEmpty && isPieceHasValue)
          {
            return `Нет плавки / ${piece.piece}`;
          }
          else if (isHeatHasValue)
          {
            return `${piece.heat} / Нет партии/ед`;
          }
          else
          {
            return `Нет плавки / Нет партии/ед`;
          }
        }
      }
    }
    else
    {
      if (piece.qmetId === 0)
      {
        return 'Нет номера';
      }
      else
      {
        return `${piece.qmetId}`;
      }
    }
  },

  selectPieceIsError: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => {
      const gE = state.inspectionEdit.generalEdited;

      if (gE === null)
      {
        return false;
      }

      const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;
      
      if (eD === null)
      {
        return false;
      }

      const piece = (eD.pieces || []).find((piece) => piece.guid === pieceGuid) ?? null;

      if (piece === null)
      {
        return false;
      }

      const inspectionState = state.inspectionEdit.generalInspectionState;

      return inspectionState === InspectionState.ERROR && piece.pieceNumId === null;
    }
  ),

  selectPieceIsSelected: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => state.inspectionEdit.pieceSelectedGuid === pieceGuid
  ),

  selectPieceDeleteAllowed: (state: RootState) => {
    const inspectionState = state.inspectionEdit.generalInspectionState;
    const inspectionStatusCode = state.inspectionEdit.generalEdited!.status.code;
    const defectEdited = state.inspectionEdit.generalEdited?.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid)
    const piecesCount = defectEdited?.pieces?.length ?? 0;

    return !((inspectionState == InspectionState.EDIT && (inspectionStatusCode !== InspectionStatusCode.INTERNAL || piecesCount == 1)) || inspectionState == InspectionState.CREATE);
  },

  selectPieceMarkedForDeleteGuids: (state: RootState) => state.inspectionEdit.pieceMarkedForDeleteGuids.join(';'),

  selectPieceIsMarkedForDelete: createSelector(
    [
      (state: RootState, pieceGuid: string) => ({ state, pieceGuid })
    ],
    ({ state, pieceGuid }) => state.inspectionEdit.pieceMarkedForDeleteGuids.includes(pieceGuid)
  ),

  selectPieceGuidsForExpandedDefect: (state: RootState) => {
    const gE = state.inspectionEdit.generalEdited;

    if (gE === null)
    {
      return '';
    }

    const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;

    if (eD === null)
    {
      return '';
    }

    return (eD.pieces ?? []).filter((piece) => piece.mode !== PieceMode.DEL).map((piece) => piece.guid).join(';');
  },

  selectPiecePiecesForExpandedDefect: (state: RootState) => {
    const gE = state.inspectionEdit.generalEdited;

    if (gE === null)
    {
      return [];
    }

    const eD = gE.inspectionDefects.find((defect) => defect.guid === state.inspectionEdit.defectExpandedGuid) ?? null;

    if (eD === null)
    {
      return [];
    }

    return (eD.pieces ?? []).filter((piece) => piece.mode !== PieceMode.DEL);
  },

  selectPieceEditOpened: (state: RootState) => state.inspectionEdit.pieceEditOpened,

  selectPieceEditedIsModeNew: (state: RootState) => state.inspectionEdit.pieceEdited?.mode === PieceMode.NEW,

  selectPieceEditedIsDouble: (state: RootState) => state.inspectionEdit.pieceEditedIsDouble,

  selectPieceEditedGuid: (state: RootState) => state.inspectionEdit.pieceEdited?.guid ?? null,

  selectPieceEditedIsPiece: (state: RootState) => state.inspectionEdit.pieceEdited?.isPiece ?? false,

  selectPieceEditedIsLoadedFromQMET: (state: RootState) => (state.inspectionEdit.pieceEdited?.pieceNumId ?? null) !== null,

  selectPieceEditedQmetId: (state: RootState) => state.inspectionEdit.pieceEdited?.qmetId ?? null,

  selectPieceEditedQmetIdError: (state: RootState) => state.inspectionEdit.pieceEditedQmetIdError,

  selectPieceEditedHeat: (state: RootState) => state.inspectionEdit.pieceEdited?.heat ?? null,

  selectPieceEditedHeatError: (state: RootState) => state.inspectionEdit.pieceEditedHeatError,

  selectPieceEditedPiece: (state: RootState) => state.inspectionEdit.pieceEdited?.piece ?? null,

  selectPieceEditedPieceError: (state: RootState) => state.inspectionEdit.pieceEditedPieceError,

  selectPieceEditedGroupAtt: (state: RootState) => state.inspectionEdit.pieceEdited?.groupAtt ?? null,

  selectPieceEditedGroupAttError: (state: RootState) => state.inspectionEdit.pieceEditedGroupAttError,

  selectPieceEditedIsSmc: (state: RootState) => state.inspectionEdit.pieceEdited?.isSmc ?? false,

  selectPieceEditedQCNum: (state: RootState) => state.inspectionEdit.pieceEdited?.qcNum ?? state.inspectionEdit.pieceEdited?.qmetData?.qcNum ?? null,

  selectPieceEditedQCState: (state: RootState) => state.inspectionEdit.pieceEdited?.qmetData?.qcState ?? null,

  selectPieceEditedQCNumError: (state: RootState) => state.inspectionEdit.pieceEditedQCNumError,

  selectPieceEditedCustomFields: (state: RootState) => state.inspectionEdit.pieceEdited?.fields ?? [],

  selectPieceEditedCustomFieldsErrors: (state: RootState) => state.inspectionEdit.pieceEditedCustomFieldsErrors,

  selectPieceEditedIsMarriageShareExpanded: (state: RootState) => state.inspectionEdit.pieceEditedIsMarriageShareExpanded,

  selectPieceEditedWeightBeforeProcessing: (state: RootState) => state.inspectionEdit.pieceEditedWeightBeforeProcessing,

  selectPieceEditedWeightAfterProcessing: (state: RootState) => state.inspectionEdit.pieceEditedWeightAfterProcessing,

  selectPieceEditedWeightAfterProcessingError: (state: RootState) => state.inspectionEdit.pieceEditedWeightAfterProcessingError,

  selectPieceEditedMarriageShare: (state: RootState) => state.inspectionEdit.pieceEdited?.marriageShare,

  selectPieceEditedWeight: (state: RootState) => state.inspectionEdit.pieceEditedWeight,

  selectPieceEditedWeightError: (state: RootState) => state.inspectionEdit.pieceEditedWeightError,

  selectPieceEditedUnit: (state: RootState) => state.inspectionEdit.pieceEdited?.unit ?? null,

  selectPieceEditedNote: (state: RootState) => state.inspectionEdit.pieceEdited?.note ?? null,

  selectPieceEditedNoteError: (state: RootState) => state.inspectionEdit.pieceEditedNoteError,

  selectPieceEditedIsTopographyExpanded: (state: RootState) => state.inspectionEdit.pieceEditedIsTopographyExpanded,

  selectPieceEditedTopoSizeId: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgIdSize ?? null,

  selectPieceEditedTopoSizeTitle: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgTitleSize ?? null,

  selectPieceEditedTopoSize: (state: RootState) => state.inspectionEdit.pieceEditedTopoSize,

  selectPieceEditedTopoSizeError: (state: RootState) => state.inspectionEdit.pieceEditedTopoSizeError,

  selectPieceEditedTopoStepId: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgIdStep ?? null,

  selectPieceEditedTopoStepTitle: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgTitleStep ?? null,

  selectPieceEditedTopoStep: (state: RootState) => state.inspectionEdit.pieceEditedTopoStep,

  selectPieceEditedTopoStepError: (state: RootState) => state.inspectionEdit.pieceEditedTopoStepError,

  selectPieceEditedTopoDistanceId: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgIdDistance ?? null,

  selectPieceEditedTopoDistanceTitle: (state: RootState) => state.inspectionEdit.pieceEdited?.topography?.defectTpgTitleDistance ?? null,

  selectPieceEditedTopoDistance: (state: RootState) => state.inspectionEdit.pieceEditedTopoDistance,

  selectPieceEditedTopoDistanceError: (state: RootState) => state.inspectionEdit.pieceEditedTopoDistanceError,

  selectPieceEditedFiles: (state: RootState) => state.inspectionEdit.pieceEdited?.files ?? [],

  selectPieceEditedExternalDoublesOpened: (state: RootState) => state.inspectionEdit.pieceEditedExternalDoublesOpened,

  selectPieceEditedExternalDoublesList: (state: RootState) => state.inspectionEdit.pieceEditedExternalDoublesList,

  selectPieceEdited: (state: RootState) => state.inspectionEdit.pieceEdited,

  selectPieceEditedOriginal: (state: RootState) => state.inspectionEdit.generalEdited?.inspectionDefects?.find((item) => item.guid === state.inspectionEdit.defectExpandedGuid)?.pieces?.find((item) => item.guid === state.inspectionEdit.pieceEdited?.guid) ?? null,

  selectPieceCertDownloadOpened: (state: RootState) => state.inspectionEdit.pieceCertDownloadOpened,
}

// #endregion

// #region ТРЕБОВАНИЯ

// #region checkComplaintBeforeAddAsync
type CheckComplaintBeforeAddArgs = void;
type CheckComplaintBeforeAddResolve = void;
export type CheckComplaintBeforeAddReject = string;
export const checkComplaintBeforeAddAsync = createAsyncThunk<
  CheckComplaintBeforeAddResolve,
  CheckComplaintBeforeAddArgs,
  {
    state: RootState,
    rejectValue: CheckComplaintBeforeAddReject,
  }
>(
  `${SLICE_NAME}/checkComplaintBeforeAddAsync`,
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();

    let resultError = '';

    const typeComplaint = complaintSelectors.selectComplaintEditedTypeComplaint(state);
    const isToScrap = complaintSelectors.selectComplaintEditedIsToScrap(state) ?? false;
    const isEvaluateRolledMetal = complaintSelectors.selectComplaintEditedIsEvaluateRolledMetal(state) ?? false;
    const isToOffsetCosts = complaintSelectors.selectComplaintEditedIsToOffsetCosts(state) ?? false;
    const isReturnOfRolledMetal = complaintSelectors.selectComplaintEditedIsReturnOfRolledMetal(state) ?? false;
    const isSale = complaintSelectors.selectComplaintEditedIsSale(state) ?? false;
    const isRepresentative = complaintSelectors.selectComplaintEditedIsRepresentative(state) ?? false;
    const isProvideResponse = complaintSelectors.selectComplaintEditedIsProvideResponse(state) ?? false;
    const isIdentifyDangerous = complaintSelectors.selectComplaintEditedIsIdentifyDangerous(state) ?? false;
    const isAnalizProblem = complaintSelectors.selectComplaintEditedIsAnalizProblem(state) ?? false;
    const is8d = complaintSelectors.selectComplaintEditedIs8d(state) ?? false;
    const otherReq = complaintSelectors.selectComplaintEditedOtherReq(state) ?? '';
    const original = complaintSelectors.selectComplaintEditedOriginal(state);

    if (
      typeComplaint === ComplaintType.COMPLAINT &&
      !isToScrap &&
      !isEvaluateRolledMetal &&
      !isToOffsetCosts &&
      !isReturnOfRolledMetal &&
      !isSale &&
      !isRepresentative &&
      !isProvideResponse &&
      !isIdentifyDangerous &&
      !isAnalizProblem &&
      !is8d
    )
    {
      return thunkAPI.rejectWithValue('Заполните хотя бы одно поле в разделе «Требования и запрашиваемые действия к поставщику».');
    }

    if ([isToScrap, isEvaluateRolledMetal, isReturnOfRolledMetal, isSale].filter((item) => item).length > 1)
    {
      return thunkAPI.rejectWithValue('Нельзя одновременно выбрать требования: «Уценить м/п до цены лома», «Уценить м/п», «Осуществить возврат м/п», «Предоставить скидку на забракованную партию проката». Выберите только одно из этих требований. ');
    }

    const defectGuid = defectSelectors.selectDefectExpandedGuid(state);

    const scrapPrice = complaintSelectors.selectComplaintEditedScrapPrice(state);
    const isScrapPriceEmpty = (scrapPrice || '').trim().length === 0;

    if (isToScrap === true && isScrapPriceEmpty)
    {
      resultError += 'Цена лома должна быть заполнена. ';
    }

    const amountOfCompensation = complaintSelectors.selectComplaintEditedAmountOfCompensation(state);
    const isAmountOfCompensationEmpty = (amountOfCompensation || '').trim().length === 0;
    const defectFiles = defectSelectors.selectDefectFiles(state, defectGuid) ?? [];
    const isFileAdded = defectFiles.filter((file) => file.type === DefectFileType.ADDITIONAL_COSTS && file.mode !== DefectFileMode.DEL).length > 0;
    const isFilesChanged = defectFiles.some((file) => file.type === DefectFileType.ADDITIONAL_COSTS && file.mode !== DefectFileMode.EXIST);

    if (isToOffsetCosts === true && (isAmountOfCompensationEmpty || !isFileAdded))
    {
      if (isAmountOfCompensationEmpty && !isFileAdded)
      {
        resultError += 'Сумма компенсации без НДС должна быть заполнена, прикрепите документ, подтверждающий дополнительные затраты';
      }
      else if (isAmountOfCompensationEmpty)
      {
        resultError += 'Сумма компенсации без НДС должна быть заполнена';
      }
      else
      {
        resultError += 'Прикрепите документ, подтверждающий дополнительные затраты';
      }
    }

    const requirementRolledMetall = complaintSelectors.selectComplaintEditedRequirementRolledMetall(state);

    if (isEvaluateRolledMetal === true && (requirementRolledMetall || '').trim().length === 0)
    {
      resultError += 'Требования к уценке должны быть заполнены. ';
    }

    const sale = complaintSelectors.selectComplaintEditedSale(state);

    if (isSale && (sale === null || sale === 0))
    {
      resultError += 'Размер скидки должен быть заполнен. ';
    }

    const email = complaintSelectors.selectComplaintEditedEmail(state);

    if (email !== null && !Util.isEmailCorrect(email))
    {
      resultError += 'Некорректно введенный E-mail. ';
    }

    const isChangeToComplaintFinance = generalSelectors.selectGeneralIsChangeToComplaintFinance(state);

    if (
      isChangeToComplaintFinance &&
      !isToScrap &&
      !isEvaluateRolledMetal &&
      !isToOffsetCosts &&
      !isReturnOfRolledMetal &&
      !isSale
    )
    {
      resultError += 'Заполните хотя бы одно финансовое требование в разделе «Требования и запрашиваемые действия к поставщику». ';
    }

    if (resultError.length >  0)
    {
      return thunkAPI.rejectWithValue(resultError);
    }

    if (original !== null)
    {
      const fio = complaintSelectors.selectComplaintEditedFIO(state);
      const enterprise = complaintSelectors.selectComplaintEditedEnterprise(state);
      const phone = complaintSelectors.selectComplaintEditedPhone(state);
      const groupRevised = complaintSelectors.selectComplaintEditedGroupRevised(state);
      const groupRevisedValue = complaintSelectors.selectComplaintEditedGroupRevisedValue(state);
      const groupUnrevised = complaintSelectors.selectComplaintEditedGroupUnrevised(state);
      const groupUnrevisedValue = complaintSelectors.selectComplaintEditedGroupUnrevisedValue(state);

      if (
        original.typeComplaint === typeComplaint
        &&
        (original.isToScrap ?? false) === isToScrap
        &&
        (`${(original.scrapPrice ?? '')}` === scrapPrice)
        &&
        (original.isEvaluateRolledMetal ?? false) === isEvaluateRolledMetal
        &&
        Util.nullOrEmptyStringTo(original.requirementRolledMetall, 'X') === Util.nullOrEmptyStringTo(requirementRolledMetall, 'X')
        &&
        (original.isToOffsetCosts ?? false) === isToOffsetCosts
        &&
        (`${(original.amountOfCompensation ?? '')}` === amountOfCompensation)
        &&
        (!isFilesChanged)
        &&
        (original.isReturnOfRolledMetal ?? false) === isReturnOfRolledMetal
        &&
        (original.isSale ?? false) === isSale
        &&
        ((original.sale ?? 0) === (sale ?? 0))
        &&
        (original.isRepresentative ?? false) === isRepresentative
        &&
        (original.isProvideResponse ?? false) === isProvideResponse
        &&
        (original.isIdentifyDangerous ?? false) === isIdentifyDangerous
        &&
        (original.isAnalizProblem ?? false) === isAnalizProblem
        &&
        (original.is8d ?? false) === is8d
        &&
        Util.nullOrEmptyStringTo(original.otherReq, 'X') === Util.nullOrEmptyStringTo(otherReq, 'X')
        &&
        Util.nullOrEmptyStringTo(original.fio, 'X') === Util.nullOrEmptyStringTo(fio, 'X')
        &&
        Util.nullOrEmptyStringTo(original.enterprise, 'X') === Util.nullOrEmptyStringTo(enterprise, 'X')
        &&
        Util.nullOrEmptyStringTo(original.phone, 'X') === Util.nullOrEmptyStringTo(phone, 'X')
        &&
        Util.nullOrEmptyStringTo(original.email, 'X') === Util.nullOrEmptyStringTo(email, 'X')
        &&
        Util.nullOrEmptyStringTo(original.groupRevised, 'X') === Util.nullOrEmptyStringTo(groupRevised, 'X')
        &&
        ((original.groupRevisedValue ?? 0) === (groupRevisedValue ?? 0))
        &&
        Util.nullOrEmptyStringTo(original.groupUnrevised, 'X') === Util.nullOrEmptyStringTo(groupUnrevised, 'X')
        &&
        ((original.groupUnrevisedValue ?? 0) === (groupUnrevisedValue ?? 0))
      ) 
      {
        return thunkAPI.rejectWithValue('Изменения отсутствуют');
      }
    }
  }
);
// #endregion

// #region addComplaintAsync
type AddComplaintArgs = {
  isTransferImmediately: boolean;
};
type AddComplaintResolve = {
  error: string;
  isTransferImmediately: boolean;
};
export type AddComplaintReject = string;
export const addComplaintAsync = createAsyncThunk<
  AddComplaintResolve,
  AddComplaintArgs,
  {
    state: RootState,
    rejectValue: AddComplaintReject,
  }
>(
  `${SLICE_NAME}/addComplaintAsync`,
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();

    let resultError = '';

    const typeComplaint = complaintSelectors.selectComplaintEditedTypeComplaint(state);
    const isToScrap = complaintSelectors.selectComplaintEditedIsToScrap(state) ?? false;
    const isEvaluateRolledMetal = complaintSelectors.selectComplaintEditedIsEvaluateRolledMetal(state) ?? false;
    const isToOffsetCosts = complaintSelectors.selectComplaintEditedIsToOffsetCosts(state) ?? false;
    const isReturnOfRolledMetal = complaintSelectors.selectComplaintEditedIsReturnOfRolledMetal(state) ?? false;
    const isSale = complaintSelectors.selectComplaintEditedIsSale(state) ?? false;
    const isRepresentative = complaintSelectors.selectComplaintEditedIsRepresentative(state) ?? false;
    const isProvideResponse = complaintSelectors.selectComplaintEditedIsProvideResponse(state) ?? false;
    const isIdentifyDangerous = complaintSelectors.selectComplaintEditedIsIdentifyDangerous(state) ?? false;
    const isAnalizProblem = complaintSelectors.selectComplaintEditedIsAnalizProblem(state) ?? false;
    const is8d = complaintSelectors.selectComplaintEditedIs8d(state) ?? false;
    const otherReq = complaintSelectors.selectComplaintEditedOtherReq(state) ?? '';
    const original = complaintSelectors.selectComplaintEditedOriginal(state);

    if (
      typeComplaint === ComplaintType.COMPLAINT &&
      !isToScrap &&
      !isEvaluateRolledMetal &&
      !isToOffsetCosts &&
      !isReturnOfRolledMetal &&
      !isSale &&
      !isRepresentative &&
      !isProvideResponse &&
      !isIdentifyDangerous &&
      !isAnalizProblem &&
      !is8d
    )
    {
      resultError = 'Заполните хотя бы одно поле в разделе «Требования и запрашиваемые действия к поставщику». ';
    }

    const defectGuid = defectSelectors.selectDefectExpandedGuid(state);

    const scrapPrice = complaintSelectors.selectComplaintEditedScrapPrice(state);
    const isScrapPriceEmpty = (scrapPrice || '').trim().length === 0;

    if (isToScrap === true && isScrapPriceEmpty)
    {
      resultError += 'Цена лома должна быть заполнена. ';
    }

    const amountOfCompensation = complaintSelectors.selectComplaintEditedAmountOfCompensation(state);
    const isAmountOfCompensationEmpty = (amountOfCompensation || '').trim().length === 0;
    const defectFiles = defectSelectors.selectDefectFiles(state, defectGuid) ?? [];
    const isFileAdded = defectFiles.filter((file) => file.type === DefectFileType.ADDITIONAL_COSTS && file.mode !== DefectFileMode.DEL).length > 0;
    const isFilesChanged = defectFiles.some((file) => file.type === DefectFileType.ADDITIONAL_COSTS && file.mode !== DefectFileMode.EXIST);

    if (isToOffsetCosts === true && (isAmountOfCompensationEmpty || !isFileAdded))
    {
      if (isAmountOfCompensationEmpty && !isFileAdded)
      {
        resultError += 'Сумма компенсации без НДС должна быть заполнена, прикрепите документ, подтверждающий дополнительные затраты';
      }
      else if (isAmountOfCompensationEmpty)
      {
        resultError += 'Сумма компенсации без НДС должна быть заполнена';
      }
      else
      {
        resultError += 'Прикрепите документ, подтверждающий дополнительные затраты';
      }
    }

    const requirementRolledMetall = complaintSelectors.selectComplaintEditedRequirementRolledMetall(state);

    if (isEvaluateRolledMetal === true && (requirementRolledMetall || '').trim().length === 0)
    {
      resultError += 'Требования к уценке должны быть заполнены. ';
    }

    const sale = complaintSelectors.selectComplaintEditedSale(state);

    if (isSale && (sale === null || sale === 0))
    {
      resultError += 'Размер скидки должен быть заполнен. ';
    }

    const email = complaintSelectors.selectComplaintEditedEmail(state);

    if (email !== null && !Util.isEmailCorrect(email))
    {
      resultError += 'Некорректно введенный E-mail. ';
    }

    const isChangeToComplaintFinance = generalSelectors.selectGeneralIsChangeToComplaintFinance(state);

    if (
      isChangeToComplaintFinance &&
      !isToScrap &&
      !isEvaluateRolledMetal &&
      !isToOffsetCosts &&
      !isReturnOfRolledMetal &&
      !isSale
    )
    {
      resultError += 'Заполните хотя бы одно финансовое требование в разделе «Требования и запрашиваемые действия к поставщику». ';
    }

    if (resultError.length >  0)
    {
      return thunkAPI.rejectWithValue(resultError);
    }

    if (original !== null)
    {
      const fio = complaintSelectors.selectComplaintEditedFIO(state);
      const enterprise = complaintSelectors.selectComplaintEditedEnterprise(state);
      const phone = complaintSelectors.selectComplaintEditedPhone(state);
      const groupRevised = complaintSelectors.selectComplaintEditedGroupRevised(state);
      const groupRevisedValue = complaintSelectors.selectComplaintEditedGroupRevisedValue(state);
      const groupUnrevised = complaintSelectors.selectComplaintEditedGroupUnrevised(state);
      const groupUnrevisedValue = complaintSelectors.selectComplaintEditedGroupUnrevisedValue(state);

      if (
        original.typeComplaint === typeComplaint
        &&
        (original.isToScrap ?? false) === isToScrap
        &&
        (`${(original.scrapPrice ?? '')}` === scrapPrice)
        &&
        (original.isEvaluateRolledMetal ?? false) === isEvaluateRolledMetal
        &&
        Util.nullOrEmptyStringTo(original.requirementRolledMetall, 'X') === Util.nullOrEmptyStringTo(requirementRolledMetall, 'X')
        &&
        (original.isToOffsetCosts ?? false) === isToOffsetCosts
        &&
        (`${(original.amountOfCompensation ?? '')}` === amountOfCompensation)
        &&
        (!isFilesChanged)
        &&
        (original.isReturnOfRolledMetal ?? false) === isReturnOfRolledMetal
        &&
        (original.isSale ?? false) === isSale
        &&
        ((original.sale ?? 0) === (sale ?? 0))
        &&
        (original.isRepresentative ?? false) === isRepresentative
        &&
        (original.isProvideResponse ?? false) === isProvideResponse
        &&
        (original.isIdentifyDangerous ?? false) === isIdentifyDangerous
        &&
        (original.isAnalizProblem ?? false) === isAnalizProblem
        &&
        (original.is8d ?? false) === is8d
        &&
        Util.nullOrEmptyStringTo(original.otherReq, 'X') === Util.nullOrEmptyStringTo(otherReq, 'X')
        &&
        Util.nullOrEmptyStringTo(original.fio, 'X') === Util.nullOrEmptyStringTo(fio, 'X')
        &&
        Util.nullOrEmptyStringTo(original.enterprise, 'X') === Util.nullOrEmptyStringTo(enterprise, 'X')
        &&
        Util.nullOrEmptyStringTo(original.phone, 'X') === Util.nullOrEmptyStringTo(phone, 'X')
        &&
        Util.nullOrEmptyStringTo(original.email, 'X') === Util.nullOrEmptyStringTo(email, 'X')
        &&
        Util.nullOrEmptyStringTo(original.groupRevised, 'X') === Util.nullOrEmptyStringTo(groupRevised, 'X')
        &&
        ((original.groupRevisedValue ?? 0) === (groupRevisedValue ?? 0))
        &&
        Util.nullOrEmptyStringTo(original.groupUnrevised, 'X') === Util.nullOrEmptyStringTo(groupUnrevised, 'X')
        &&
        ((original.groupUnrevisedValue ?? 0) === (groupUnrevisedValue ?? 0))
      ) 
      {
        return thunkAPI.rejectWithValue('Изменения отсутствуют');
      }
    }

    return {
      error: resultError,
      isTransferImmediately: args.isTransferImmediately,
    }
  }
);
extraReducers.push((builder) => {
  builder
    .addCase(addComplaintAsync.fulfilled, (state, action) => {
      if (state.generalEdited !== null)
      {
        const defect = state.generalEdited.inspectionDefects.find((item) => item.guid === state.complaintDefectGuid) ?? null;

        if (defect !== null)
        {
          if (state.complaintEdited !== null)
          {
            state.complaintContactDataExpanded = false;
            state.complaintDefectGuid = '';
            state.complaintEditOpened = false;
            defect.complaint = { ...state.complaintEdited };
            defect.complaint.isTransferImmediately = action.payload.isTransferImmediately ? true : null;
            state.complaintEdited = null;
          }
        }
      }
    })
    .addCase(addComplaintAsync.rejected, (state, action) => {
    });
});
// #endregion

const complaintReducers = {
  complaintEditOpened: (state: WritableDraft<InspectionEditState>) => {
    state.complaintEditOpened = true;
  },

  complaintEditClosed: (state: WritableDraft<InspectionEditState>) => {
    state.complaintEditOpened = false;
  },

  complaintForEditSelected: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.generalEdited !== null)
    {
      const [mode, complaintGuid] = action.payload.split(':');

      const defect = state.generalEdited.inspectionDefects
        .find((item) => item.guid === complaintGuid);

      const complaint = defect?.complaint ?? null;
      

      if (complaint === null)
      {
        state.complaintEdited = {
          typeComplaint: ComplaintType.REMARK,
          isToScrap: null,
          scrapPrice: null,
          isEvaluateRolledMetal: null,
          requirementRolledMetall: null,
          isToOffsetCosts: null,
          amountOfCompensation: null,
          isReturnOfRolledMetal: null,
          isSale: null,
          sale: null,
          isRepresentative: null,
          isProvideResponse: null,
          isIdentifyDangerous: null,
          isAnalizProblem: null,
          is8d: null,
          otherReq: null,
          groupRevised: null,
          groupRevisedValue: null,
          groupUnrevised: null,
          groupUnrevisedValue: null,
          decision: null,
          fio: null,
          enterprise: null,
          phone: null,
          email: null,
          isTransferImmediately: null,
          mode: ComplaintMode.NEW,
        };
        state.complaintEditedScrapPrice = '';
        state.complaintEditedAmountOfCompensation = '';
      }
      else
      {
        state.complaintEdited = { ...complaint };
        state.complaintEditedScrapPrice = state.complaintEdited.scrapPrice === 0 ? '' : `${state.complaintEdited.scrapPrice ?? ''}`;
        state.complaintEditedAmountOfCompensation = state.complaintEdited.amountOfCompensation === 0 ? '' : `${state.complaintEdited.amountOfCompensation ?? ''}`;
        state.complaintEdited.sale = state.complaintEdited.sale === 0 ? null : state.complaintEdited.sale;
        state.complaintEdited.groupRevisedValue = state.complaintEdited.groupRevisedValue === 0 ? null : state.complaintEdited.groupRevisedValue;
        state.complaintEdited.groupUnrevisedValue = state.complaintEdited.groupUnrevisedValue === 0 ? null : state.complaintEdited.groupUnrevisedValue;
        state.complaintEdited.mode = state.complaintEdited.mode === ComplaintMode.NEW ? ComplaintMode.NEW : ComplaintMode.EDIT;
      }

      state.complaintDefectGuid = complaintGuid;
      state.complaintEditedMode = mode as 'new' | 'edit' | 'tocomplaint' | 'tocomplaintfinance' | 'view';
      state.complaintEditOpened = true;
      state.complaintContactDataExpanded = (state.complaintEdited?.fio ?? null) !== null || 
                                           (state.complaintEdited?.enterprise ?? null) !== null ||
                                           (state.complaintEdited?.phone ?? null) !== null ||
                                           (state.complaintEdited?.email ?? null) !== null;
    }
  },

  complaintEditedTypeComplaintChanged: (state: WritableDraft<InspectionEditState>, action: ComplaintTypeChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.typeComplaint = action.payload;

      state.complaintEdited.isToScrap = null;
      state.complaintEdited.scrapPrice = null;
      state.complaintEdited.isEvaluateRolledMetal = null;
      state.complaintEdited.requirementRolledMetall = null;
      state.complaintEdited.isToOffsetCosts = null;
      state.complaintEdited.amountOfCompensation = null;

      const gE = state.generalEdited;

      if (gE !== null)
      {
        const eD = gE.inspectionDefects.find((defect) => defect.guid === state.defectExpandedGuid) ?? null;

        if (eD !== null)
        {
          eD.files = eD.files
            .map((file) => {
              if (file.type === types.inspection.DefectFileType.ADDITIONAL_COSTS)
              {
                return file.mode === types.inspection.DefectFileMode.NEW ?
                  null
                  :
                  { ...file, mode: types.inspection.DefectFileMode.DEL };
              }

              return file;
            })
            .filter((file) => file !== null) as types.inspection.DefectFile[];

          if (eD.mode !== types.inspection.DefectMode.NEW)
          {
            eD.mode = types.inspection.DefectMode.EDIT;
          }
        }
      }

      state.complaintEdited.isReturnOfRolledMetal = null;
      state.complaintEdited.isSale = null;
      state.complaintEdited.sale = null;
      state.complaintEdited.isRepresentative = null;
      state.complaintEdited.isProvideResponse = null;
      state.complaintEdited.isIdentifyDangerous = null;
      state.complaintEdited.isAnalizProblem = null;
      state.complaintEdited.is8d = null;
    }
  },

  complaintEditedIsToScrapChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isToScrap = action.payload || null;

      if (!action.payload)
      {
        state.complaintEdited.scrapPrice = null;
        state.complaintEditedScrapPrice = '';
      }
    }
  },

  complaintEditedScrapPriceChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEditedScrapPrice = action.payload;
      state.complaintEdited.scrapPrice = action.payload === '' ? null : parseFloat(action.payload);
    }
  },

  complaintEditedIsToOffsetCostsChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isToOffsetCosts = action.payload || null;

      if (!action.payload)
      {
        state.complaintEdited.amountOfCompensation = null;
        state.complaintEditedAmountOfCompensation = '';
      }
    }
  },

  complaintEditedAmountOfCompensationChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEditedAmountOfCompensation = action.payload;
      state.complaintEdited.amountOfCompensation = action.payload === '' ? null : parseFloat(action.payload);
    }
  },

  complaintEditedIsReturnOfRolledMetalChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isReturnOfRolledMetal = action.payload || null;
    }
  },

  complaintEditedIsSaleChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isSale = action.payload || null;

      if (!action.payload)
      {
        state.complaintEdited.sale = null;
      }
    }
  },

  complaintEditedSaleChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.sale = action.payload === '' ? null : parseInt(action.payload, 10);
    }
  },

  complaintEditedIsEvaluateRolledMetalChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isEvaluateRolledMetal = action.payload || null;

      if (!action.payload)
      {
        state.complaintEdited.requirementRolledMetall = null;
      }
    }
  },

  complaintEditedRequirementRolledMetallChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.requirementRolledMetall = action.payload;
    }
  },

  complaintEditedIsRepresentativeChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isRepresentative = action.payload || null;
    }
  },

  complaintEditedIsProvideResponseChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isProvideResponse = action.payload || null;
    }
  },

  complaintEditedIsIdentifyDangerousChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isIdentifyDangerous = action.payload || null;
    }
  },

  complaintEditedIsAnalizProblemChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.isAnalizProblem = action.payload || null;
    }
  },

  complaintEditedIs8dChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.is8d = action.payload || null;
    }
  },

  complaintEditedOtherReqChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.otherReq = action.payload === '' ? null : action.payload;
    }
  },

  complaintContactDataExpandedChanged: (state: WritableDraft<InspectionEditState>, action: BooleanChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintContactDataExpanded = action.payload;

      if (!action.payload)
      {
        state.complaintEdited.fio = null;
        state.complaintEdited.enterprise = null;
        state.complaintEdited.phone = null;
        state.complaintEdited.email = null;
      }
    }
  },

  complaintEditedFIOChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.fio = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedEnterpriseChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.enterprise = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedPhoneChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.phone = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedEmailChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.email = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedGroupRevisedChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.groupRevised = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedGroupRevisedValueChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.groupRevisedValue = action.payload === '' ? null : parseInt(action.payload, 10);
    }
  },

  complaintEditedGroupUnrevisedChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.groupUnrevised = action.payload === '' ? null : action.payload;
    }
  },

  complaintEditedGroupUnrevisedValueChanged: (state: WritableDraft<InspectionEditState>, action: StringChangedAction) => {
    if (state.complaintEdited !== null)
    {
      state.complaintEdited.groupUnrevisedValue = action.payload === '' ? null : parseInt(action.payload, 10);
    }
  },

}

const complaintSelectors = {
  selectComplaintEditOpened: (state: RootState) => state.inspectionEdit.complaintEditOpened,
  selectComplaintDefectGuid: (state: RootState) => state.inspectionEdit.complaintDefectGuid,
  selectComplaintEditedTypeComplaint: (state: RootState) => state.inspectionEdit.complaintEdited?.typeComplaint ?? null,
  selectComplaintEditedIsToScrap: (state: RootState) => state.inspectionEdit.complaintEdited?.isToScrap ?? null,
  selectComplaintEditedScrapPrice: (state: RootState) => state.inspectionEdit.complaintEditedScrapPrice ?? null,
  selectComplaintEditedIsEvaluateRolledMetal: (state: RootState) => state.inspectionEdit.complaintEdited?.isEvaluateRolledMetal ?? null,
  selectComplaintEditedRequirementRolledMetall: (state: RootState) => state.inspectionEdit.complaintEdited?.requirementRolledMetall ?? null,
  selectComplaintEditedIsToOffsetCosts: (state: RootState) => state.inspectionEdit.complaintEdited?.isToOffsetCosts ?? null,
  selectComplaintEditedAmountOfCompensation: (state: RootState) => state.inspectionEdit.complaintEditedAmountOfCompensation ?? null,
  selectComplaintEditedIsReturnOfRolledMetal: (state: RootState) => state.inspectionEdit.complaintEdited?.isReturnOfRolledMetal ?? null,
  selectComplaintEditedIsSale: (state: RootState) => state.inspectionEdit.complaintEdited?.isSale ?? null,
  selectComplaintEditedSale: (state: RootState) => state.inspectionEdit.complaintEdited?.sale ?? null,
  selectComplaintEditedIsRepresentative: (state: RootState) => state.inspectionEdit.complaintEdited?.isRepresentative ?? null,
  selectComplaintEditedIsProvideResponse: (state: RootState) => state.inspectionEdit.complaintEdited?.isProvideResponse ?? null,
  selectComplaintEditedIsIdentifyDangerous: (state: RootState) => state.inspectionEdit.complaintEdited?.isIdentifyDangerous ?? null,
  selectComplaintEditedIsAnalizProblem: (state: RootState) => state.inspectionEdit.complaintEdited?.isAnalizProblem ?? null,
  selectComplaintEditedIs8d: (state: RootState) => state.inspectionEdit.complaintEdited?.is8d ?? null,
  selectComplaintEditedIsOtherReqVisible: (state: RootState) => 
    (state.inspectionEdit.complaintEdited?.isToScrap ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isEvaluateRolledMetal ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isToOffsetCosts ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isReturnOfRolledMetal ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isSale ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isRepresentative ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isProvideResponse ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isIdentifyDangerous ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.isAnalizProblem ?? false)
    ||
    (state.inspectionEdit.complaintEdited?.is8d ?? false),
  selectComplaintEditedOtherReq: (state: RootState) => state.inspectionEdit.complaintEdited?.otherReq ?? null,
  selectComplaintEditedContactDataExpanded: (state: RootState) => state.inspectionEdit.complaintContactDataExpanded,
  selectComplaintEditedMode: (state: RootState) => state.inspectionEdit.complaintEditedMode,
  selectComplaintEditedFIO: (state: RootState) => state.inspectionEdit.complaintEdited?.fio ?? null,
  selectComplaintEditedEnterprise: (state: RootState) => state.inspectionEdit.complaintEdited?.enterprise ?? null,
  selectComplaintEditedPhone: (state: RootState) => state.inspectionEdit.complaintEdited?.phone ?? null,
  selectComplaintEditedEmail: (state: RootState) => state.inspectionEdit.complaintEdited?.email ?? null,
  selectComplaintEditedGroupRevised: (state: RootState) => state.inspectionEdit.complaintEdited?.groupRevised ?? null,
  selectComplaintEditedGroupRevisedValue: (state: RootState) => state.inspectionEdit.complaintEdited?.groupRevisedValue ?? null,
  selectComplaintEditedGroupUnrevised: (state: RootState) => state.inspectionEdit.complaintEdited?.groupUnrevised ?? null,
  selectComplaintEditedGroupUnrevisedValue: (state: RootState) => state.inspectionEdit.complaintEdited?.groupUnrevisedValue ?? null,
  selectComplaintEditedOriginal: (state: RootState) => state.inspectionEdit.generalEdited?.inspectionDefects?.find((item) => item.guid === state.inspectionEdit.complaintDefectGuid)?.complaint ?? null,
  selectComplaintEditedDecision: (state: RootState) => state.inspectionEdit.complaintEdited?.decision ?? null,
}

// #endregion

// #region СОХРАНЕНИЕ ОСМОТРА

// #region saveInspectionAsync

const getPieceErrorMessage = (
  piece: types.inspection.Piece,
  isEntryAccounting: boolean,
  isWireRod: boolean,
  isDefect: boolean,
  account: types.auth.Account,
) => {
    //isDefect = true - проверки на дефекте. В этом случае надо выводить, на каком УЕ ошибка
    let isErrorHeat = false;
    let isErrorPiece = false;
    let isErrorGroupAtt = false;
    let isErrorQcNum = false;
    let isErrorPieceWireRod = false;
    let isErrorQmet = false;
    let isErrorWeight = false;
    let isErrorWeightControlKg = false;
    let isErrorWeightControlT = false;
    let isErrorWeightControlItems = false;
    let isErrorField = false;
    let isErrorSizeField = false;
    let isErrorPhoto = true;

    if (piece.isPiece)
    {
      const pieceHeatEmpty = piece.heat == null || piece.heat === '';
      const piecePieceEmpty = piece.piece === null || piece.piece === '';
      const pieceGroupAttEmpty = piece.groupAtt === null || piece.groupAtt === '';
      const pieceQcNumEmpty = piece.qcNum === null || piece.qcNum === '';

      if (isWireRod)
      {
        if (pieceHeatEmpty && piecePieceEmpty && pieceGroupAttEmpty && pieceQcNumEmpty)
        {
          isErrorPieceWireRod = true;
        } 
        else if (pieceGroupAttEmpty && pieceQcNumEmpty)
        {
          if (pieceHeatEmpty)
          {
            isErrorHeat = true;
          }
          if (piecePieceEmpty)
          {
            isErrorPiece = true;
          }
        } 
        else if (pieceHeatEmpty && piecePieceEmpty)
        {
          if (pieceGroupAttEmpty)
          {
            isErrorGroupAtt = true
          }
          if (pieceQcNumEmpty)
          {
            isErrorQcNum = true
          }
        }
      } 
      else
      {
        if (pieceHeatEmpty)
        {
          isErrorHeat = true
        }
        if (piecePieceEmpty)
        {
          isErrorPiece = true
        }
      }
    }
    else
    {
      if (piece.qmetId === null || piece.qmetId === 0 || `${piece.qmetId}`.length < Constants.PIECE_NUM_ID_LENGTH_MIN)
      {
        isErrorQmet = true;
      }
    }

    if (!isEntryAccounting && account.role !== types.auth.AccountRole.Contractor)
    {
      if (piece.weight === null || piece.weight === 0)
      {
        isErrorWeight = true;
      }
    }

    if (!isErrorWeight && piece.weight !== null && piece.weight > 0)
    {
      if (piece.unit.id === 1 && (piece.weight < 1 || piece.weight > 100000))
      {
        isErrorWeightControlKg = true;
      }
      else if (piece.unit.id === 2 && (piece.weight < 0.001 || piece.weight > 100))
      {
        isErrorWeightControlT = true;
      }
      else if (piece.unit.id === 3 && piece.weight > 100000)
      {
        isErrorWeightControlItems = true;
      }
    }

    account.fields.forEach((accField) => {
      const field = piece.fields.find((item) => item.code === accField.code) ?? null;
      
      if (!accField.isOptional && (field === null || field.value === ''))
      {
        isErrorField = true;
      }

      if (accField.size > 0 && field !== null && field.value.length > accField.size)
      {
        isErrorSizeField = true;
      }
    });

    piece.files.forEach((file) => {
      if (file.type === types.inspection.PieceFileType.GENERAL && file.guid !== null && file.mode !== types.inspection.PieceFileMode.DEL)
      {
        isErrorPhoto = false;
      }
    })

    if (
      isErrorHeat ||
      isErrorPiece ||
      isErrorGroupAtt ||
      isErrorQcNum ||
      isErrorPieceWireRod ||
      isErrorQmet ||
      isErrorWeight ||
      isErrorWeightControlKg ||
      isErrorWeightControlT ||
      isErrorWeightControlItems ||
      isErrorField ||
      isErrorSizeField ||
      isErrorPhoto
    )
    {
      let message = 'Заполните обязательные поля: ';
      let messageSize = 'Превышена размерность поля: ';
      let messageWeight = '';

      if (isErrorHeat)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}№ плавки`;
        }
        else
        {
          message = `${message}, № плавки`;
        }
      }

      if (isErrorPiece)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}№ рулона/листа/трубы`;
        }
        else
        {
          message = `${message}, № рулона/листа/трубы`;
        }
      }

      if (isErrorGroupAtt)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}№ партии аттестации`;
        }
        else
        {
          message = `${message}, № партии аттестации`;
        }
      }

      if (isErrorQcNum)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}№ сертификата качества`;
        }
        else
        {
          message = `${message}, № сертификата качества`;
        }
      }

      if (isErrorPieceWireRod)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}№ плавки и № рулона/листа/трубы или № партии аттестации и № сертификата качества`;
        }
        else
        {
          message = `${message}, № плавки и № рулона/листа/трубы или № партии аттестации и № сертификата качества`;
        }
      }

      if (isErrorQmet)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}Идентификационный номер (должен быть от ${Constants.PIECE_NUM_ID_LENGTH_MIN} до ${Constants.PIECE_NUM_ID_LENGTH_MAX} цифр)`;
        }
        else
        {
          message = `${message}, Идентификационный номер (должен быть от ${Constants.PIECE_NUM_ID_LENGTH_MIN} до ${Constants.PIECE_NUM_ID_LENGTH_MAX} цифр)`;
        }
      }

      if (isErrorWeight)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}Количество брака`;
        }
        else
        {
          message = `${message}, Количество брака`;
        }
      }

      if (isErrorField)
      {
        account.fields.forEach((accField) => {
          const field = piece.fields.find((item) => item.code === accField.code) ?? null;
          
          if (!accField.isOptional && (field === null || field.value === ''))
          {
            if (message === 'Заполните обязательные поля: ')
            {
              message = `${message}${accField.title}`;
            }
            else
            {
              message = `${message}, ${accField.title}`;
            }
          }
        });
      }

      if (isErrorPhoto)
      {
        if (message === 'Заполните обязательные поля: ')
        {
          message = `${message}Фото общего вида`;
        }
        else
        {
          message = `${message}, Фото общего вида`;
        }
      }

      if (isErrorSizeField)
      {
        account.fields.forEach((accField) => {
          const field = piece.fields.find((item) => item.code === accField.code) ?? null;

          if (accField.size > 0 && field !== null && field.value.length > accField.size)
          {
            if (messageSize === 'Превышена размерность поля: ')
            {
              messageSize = `${messageSize}${accField.title}`;
            }
            else
            {
              messageSize = `${messageSize}, ${accField.title}`;
            }
          }
        });
      }

      if (isErrorWeightControlKg)
      {
          messageWeight = `Вес не должен быть менее 1 кг и не более 100000 кг, а Вы пытаетесь ввести ${piece.weight}`;
      }
      if (isErrorWeightControlT)
      {
          messageWeight = `Вес не должен быть менее 0.001 т и не более 100 т, а Вы пытаетесь ввести  ${piece.weight}`;
      }
      if (isErrorWeightControlItems)
      {
          messageWeight = `Количество не должно быть более 100000 шт, а Вы пытаетесь ввести  ${piece.weight}`;
      }

      let messageAll = '';

      if (message !== 'Заполните обязательные поля: ')
      {
        messageAll = message;
      }

      if (messageSize !== 'Превышена размерность поля: ')
      {
        if (messageAll === '')
        {
          messageAll = `${messageSize}`;
        }
        else
        {
          messageAll = `${messageAll} ${messageSize}`;
        }
      }

      if (messageWeight !== '')
      {
        if (messageAll === '')
        {
          messageAll = messageWeight;
        }
        else
        {
          messageAll = `${messageAll} ${messageWeight}`;
        }
      }

      if (isDefect && messageAll !== '')
      {
        messageAll = `УЕ ${piece.isPiece ? `${piece.heat}/${piece.piece}` : `${piece.qmetId}`} - ${messageAll}`;
      }

      return messageAll;
  }
  else
  {
    return '';
  }
}

const getDefectErrorMessage = (
  defect: types.inspection.Defect,
  isComplete: boolean,
  isCheckDefect: boolean,
  mode: types.inspection.DefectMode,
  isEntryAccounting: boolean,
  account: types.auth.Account,
  isChangingRequirements: boolean,
): string => {
  let isErrorDefectId = false;
  /**
   * mobi-1540 Убрали обязательность поля Приоритет для STPK.
   */
  // let isErrorPriority = false;
  let isErrorViolatedRequirements = false;
  /**
   * mobi-1541 Для сварного шва (id = 99) место фиксации обязательно для клиента, клиента-админа и стпк
   */
  let isErrorPlaceOfFixation = false;
  let isErrorWayToUseRegectedId = false;
  let isErrorComplaint = false;
  let isErrorComplaintChanges = false;
  let isErrorPiece = false;
  let isErrorPieceFilled = false;
  let isJoinInspectionActError = false;
  let isJoinInspectionTimestampError = false;
  let isJoinInspectionFileError = false;
  let pieceError: string = '';

  if (mode === types.inspection.DefectMode.DEL)
  {
    return '';
  }

  if (!isEntryAccounting)
  {
    if (defect.defectId === null || defect.defectId === 0)
    {
      isErrorDefectId = true;
    }

    if (isCheckDefect && defect.defectId === -1)
    {
      isErrorDefectId = true;
    }
  
    /**
     * mobi-1541 Для сварного шва (id = 99) место фиксации обязательно для клиента, клиента-админа и стпк
     */
    if (
      [types.auth.AccountRole.Client, types.auth.AccountRole.ClientAdmin, types.auth.AccountRole.Stpk].includes(account.role)
      &&
      defect.defectId === 99
      &&
      defect.placeOfFixationId === null
    )
    {
      isErrorPlaceOfFixation = true;
    }

    /**
     * mobi-1540 Убрали обязательность поля Приоритет для STPK.
     */
    // if (account.role === types.auth.AccountRole.Stpk && (defect.priorityId === null || defect.priorityId === 0))
    // {
    //   isErrorPriority = true;
    // }

    if (defect.isViolationOfRequirements && (defect.violatedRequirements === null || defect.violatedRequirements === ''))
    {
      isErrorViolatedRequirements = true;
    }

    if (defect.isPossibilityUsingRejected && (defect.wayToUseRegectedId === null || defect.wayToUseRegectedId === 0))
    {
      isErrorWayToUseRegectedId = true;
    }

    if (account.role !== types.auth.AccountRole.Contractor && defect.__isJoinInspection)
    {
      if (defect.actJoinInsp === null || defect.actJoinInsp === '')
      {
        isJoinInspectionActError = true;
      }

      if (defect.timestampJoinInsp === null)
      {
        isJoinInspectionTimestampError = true;
      }

      if (!defect.files.some((file) => file.type === types.inspection.DefectFileType.ACT_JOIN_INSP && file.mode !== types.inspection.DefectFileMode.DEL))
      {
        isJoinInspectionFileError = true;
      }
    }

    /*
    if (isComplete || isChangingRequirements)
    {
      if (account.role === types.auth.AccountRole.ClientAdmin || account.role === types.auth.AccountRole.Stpk)
      {
        if (defect.complaint == null)
        {
          isErrorComplaint = true;
        }
      }
    }
    */

    if ((isComplete && account.role === types.auth.AccountRole.Stpk) || (isChangingRequirements && [types.auth.AccountRole.Stpk, types.auth.AccountRole.ClientAdmin].includes(account.role)))
    {
      if (defect.complaint == null)
      {
        isErrorComplaint = true;
      }
    }

    if (isChangingRequirements)
    {
      if (defect.complaint?.mode === ComplaintMode.EXIST)
      {
        isErrorComplaintChanges = true;
      }
    }
  }

  isErrorPiece = defect.pieces === null || defect.pieces.length === 0;

  if (defect.pieces !== null)
  {
    defect.pieces.forEach((piece) => {
      if (pieceError !== '')
      {
        return;
      }

      if (piece.mode !== types.inspection.PieceMode.DEL)
      {
        pieceError = getPieceErrorMessage(piece, isEntryAccounting, account.isWireRod, true, account);

        if (pieceError !== '')
        {
          isErrorPieceFilled = true;
        }
      }
    });
  }

  if (
    isErrorDefectId ||
    /**
     * mobi-1540 Убрали обязательность поля Приоритет для STPK.
     */
    // isErrorPriority ||
    isErrorPlaceOfFixation ||
    isErrorViolatedRequirements ||
    isErrorWayToUseRegectedId ||
    isErrorComplaint ||
    isErrorPiece ||
    isErrorPieceFilled ||
    isErrorComplaintChanges ||
    isJoinInspectionActError ||
    isJoinInspectionTimestampError ||
    isJoinInspectionFileError
  )
  {
    let message = 'Заполните обязательные поля: ';

    if (isErrorDefectId)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Вид дефекта`;
      }
      else
      {
        message = `${message}, Вид дефекта`;
      }
    }

    if (isErrorPlaceOfFixation)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Место фиксации`;
      }
      else
      {
        message = `${message}, Место фиксации`;
      }
    }

    /**
     * mobi-1540 Убрали обязательность поля Приоритет для STPK.
     */
    // if (isErrorPriority)
    // {
    //   if (message === 'Заполните обязательные поля: ')
    //   {
    //     message = `${message}Приоритет`;
    //   }
    //   else
    //   {
    //     message = `${message}, Приоритет`;
    //   }
    // }

    if (isErrorViolatedRequirements)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Укажите какое требование нарушено`;
      }
      else
      {
        message = `${message}, Укажите какое требование нарушено`;
      }
    }

    if (isErrorWayToUseRegectedId)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Способ использования забрак. проката`;
      }
      else
      {
        message = `${message}, Способ использования забрак. проката`;
      }
    }

    if (isJoinInspectionActError)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Номер акта СИ`;
      }
      else
      {
        message = `${message}, Номер акта СИ`;
      }
    }

    if (isJoinInspectionTimestampError)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Дата СИ`;
      }
      else
      {
        message = `${message}, Дата СИ`;
      }
    }

    if (isJoinInspectionFileError)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}PDF-файл СИ`;
      }
      else
      {
        message = `${message}, PDF-файл СИ`;
      }
    }

    if (isErrorComplaint)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Требование`;
      }
      else
      {
        message = `${message}, Требование`;
      }
    }

    if (isErrorPiece)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${message}Информация о продукции`;
      }
      else
      {
        message = `${message}, Информация о продукции`;
      }
    }

    if (isErrorPieceFilled)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = `${pieceError}`;
      }
      else
      {
        message = `${message}, ${pieceError}`;
      }
    }

    if (isErrorComplaintChanges)
    {
      if (message === 'Заполните обязательные поля: ')
      {
        message = 'Не внесены изменения в требования';
      }
      else
      {
        message = `${message}. Не внесены изменения в требования`;
      }
    }

    return message;
  }
  else
  {
    return '';
  }
}

type SaveInspectionArgs = {
  isComplete: boolean;
  isTransferOnly?: boolean;
};
type SaveInspectionResolve = void;
export type SaveInspectionReject = {
  error: string;
  redirectToList: boolean;
};
export const saveInspectionAsync = createAsyncThunk<
  SaveInspectionResolve,
  SaveInspectionArgs,
  {
    state: RootState,
    rejectValue: SaveInspectionReject,
  }
>(
  `${SLICE_NAME}/saveInspectionAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const gER = state.inspectionEdit.generalEdited;
      const isEntryAccounting = state.inspectionEdit.generalIsEntryAccounting;
      const isChangeToComplaint = state.inspectionEdit.generalIsChangeToComplaint;
      const isChangeToComplaintFinance = state.inspectionEdit.generalIsChangeToComplaintFinance;
      const inspectionState = state.inspectionEdit.generalInspectionState;
      const account = state.authCommon.account!;

      const isTransferOnly = args.isTransferOnly ?? false;

      if (gER !== null)
      {
        thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.BUSY));
        thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Внутренние проверки'));

        let gE = JSON.parse(JSON.stringify(gER)) as types.inspection.Inspection;
        let __isCompleted = gE.__isCompleted;

        gE.inspectionDefects.forEach((defect) => {
          (defect.pieces ?? []).forEach((piece) => {
            piece.files.forEach((file) => {
              if (file.mode === PieceFileMode.NEW)
              {
                // Каждый раз при сохранении меняем гуиды новых файлов, чтобы загрузка файлов была как в первый раз
                // (т.е. во время предыдущей отправки часть файлов могла быть отправлена на сервер)
                file.guid = Util.guid(true);
              }
            })
          });

          (defect.files ?? []).forEach((file) => {
            if (file.mode === DefectFileMode.NEW)
            {
              // Каждый раз при сохранении меняем гуиды новых файлов, чтобы загрузка файлов была как в первый раз
              // (т.е. во время предыдущей отправки часть файлов могла быть отправлена на сервер)
              file.guid = Util.guid(true);
            }
          });
        });

        // Проверяем на ошибки в любом случае
        if (!isTransferOnly || true)
        {
          if (args.isComplete)
          {
            let defectError = '';

            gE.inspectionDefects.forEach((defect) => {
              if (defectError !== '')
              {
                return;
              }

              defectError = getDefectErrorMessage(
                defect,
                false,
                true,
                defect.mode,
                isEntryAccounting,
                account,
                (isChangeToComplaint || isChangeToComplaintFinance),
              );

              if (defectError !== '' && defectError !== 'Не внесены изменения в требования')
              {
                defectError = `${defect.defectTitle ?? ''} ${defectError}`;
              }
            });

            if (defectError !== '')
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: defectError,
                redirectToList: false,
              });
            }

            const isEquals = generalSelectors.selectGeneralIsEquals(state, args.isComplete);

            if (isEquals)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: 'Изменения отсутствуют',
                redirectToList: true,
              });
            }

            let isErrorComplaint = false
            let isErrorDefects = false;
            if (
              !isEntryAccounting &&
              (account.role === AccountRole.Stpk) &&
              inspectionState !== InspectionState.ERROR
            )
            {
              gE.inspectionDefects.forEach((defect) => {
                if (defect.mode !== types.inspection.DefectMode.DEL)
                {
                  if (defect.complaint === null || defect.complaint.typeComplaint === null)
                  {
                    isErrorComplaint = true;
                  }
                }
              });
            }

            if (!isEntryAccounting)
            {
              gE.inspectionDefects.forEach((defect) => {
                if (defect.mode !== types.inspection.DefectMode.DEL)
                {
                  if (defect.defectId === null || defect.defectId === -1)
                  {
                    isErrorDefects = true;
                  }
                }
              });
            }

            if (isErrorComplaint || isErrorDefects)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: isErrorDefects ? 'Не заполнены дефекты' : 'Не заполнены требования',
                redirectToList: false,
              });
            }
            else
            {
              __isCompleted = true;
              //Осмотр завершен, но еще не передан
              gE.status.id = -1;

              if ([InspectionState.NEW, InspectionState.CREATE, InspectionState.DRAFT].includes(inspectionState))
              {
                gE.status.code = types.inspection.InspectionStatusCode.NO_TRANS_DRAFT;
              }
              else if (inspectionState === InspectionState.ERROR)
              {
                gE.status.code = types.inspection.InspectionStatusCode.NO_TRANS_ERROR;
              }
              else
              {
                gE.status.code = types.inspection.InspectionStatusCode.NO_TRANS_COMPLETED;
              }

              gE.status.title = 'Не отправлен';
              gE.timestampModified = Date.now();

              try
              {
                thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Сохранение осмотра в локальной базе данных'));
                await Db.saveInspection(gE);
              }
              catch (error)
              {
                thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
                return thunkAPI.rejectWithValue({
                  error: 'Не удалось сохранить осмотр в локальной базе данных',
                  redirectToList: false,
                });
              }
            }
          }
          else
          {
            let defectError = false;

            /*
            gE.inspectionDefects.forEach((defect) => {
              if (defect.mode !== DefectMode.DEL)
              {
                if (!isEntryAccounting)
                {
                  if (defect.defectId === null || defect.defectId === 0)
                  {
                    defectError = true;
                  }
                }
              }
            });
            */

            if (defectError)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: 'Не заполнены дефекты',
                redirectToList: false,
              });
            }

            const isEquals = generalSelectors.selectGeneralIsEquals(state, args.isComplete);

            if (isEquals)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: 'Изменения отсутствуют',
                redirectToList: true,
              });
            }

            try
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Сохранение осмотра в локальной базе данных'));
              await Db.saveInspection(gE);
            }
            catch (error)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: 'Не удалось сохранить осмотр в локальной базе данных',
                redirectToList: false,
              });
            }
          }
        }

        const filesForSend: (types.inspection.PieceFile | types.inspection.DefectFile)[] = [];

        gE.inspectionDefects.forEach((defect) => {
          if (defect.mode !== types.inspection.DefectMode.DEL)
          {
            (defect.pieces || []).forEach((piece) => {
              if (piece.mode !== types.inspection.PieceMode.DEL)
              {
                piece.files.forEach((file) => {
                  if (file.mode === PieceFileMode.NEW)
                  {
                    filesForSend.push(file);
                  }
                });
              }
            });

            (defect.files ?? []).forEach((file) => {
              if (file.mode === DefectFileMode.NEW)
              {
                filesForSend.push(file);
              }
            });
          }
        });

        if (filesForSend.length > 0)
        {
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Отправка файлов'));
          for (const file of filesForSend)
          {
            try
            {
              const data = new FormData();
              const source = await Util.binaryStringToFile(file.__binaryStr!, file.__name!, file.__mime!);

              data.append('guid', file.guid);
              data.append('ab_file_source', source);
              await Api.postInspectionFileByGuid(data);
            }
            catch (error)
            {
              thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
              return thunkAPI.rejectWithValue({
                error: 'Не удалось отправить файлы осмотра, повторите операцию позже',
                redirectToList: true
              });
            }
          }
        }

        const inspectionToSend: types.inspection.SaveInspectionDTO = {
          guid: gE.guid,
          isGroup: gE.isGroup,
          isEntryAccounting: gE.isEntryAccounting,
          timestampInspection: gE.timestampInspection,
          userId: gE.userId,
          timestampModified: Date.now(),
          isCompleted: __isCompleted,
          inspectionDefects: gE.inspectionDefects.map((defect) => ({
            guid: defect.guid,
            timestampDefect: defect.timestampDefect,
            defectId: defect.defectId,
            priorityId: defect.priorityId,
            placeOfFixationId: defect.placeOfFixationId,
            product: defect.product,
            note: defect.note,
            numExternal: defect.numExternal,
            isViolationOfRequirements: defect.isViolationOfRequirements,
            violatedRequirements: defect.violatedRequirements,
            isPossibilityUsingRejected: defect.isPossibilityUsingRejected,
            wayToUseRegectedId: defect.wayToUseRegectedId,
            actJoinInsp: defect.__isJoinInspection ? defect.actJoinInsp : null,
            timestampJoinInsp: defect.__isJoinInspection ? defect.timestampJoinInsp : null,
            files: defect.files
              .map((item) => {
                if (item.type === types.inspection.DefectFileType.ACT_JOIN_INSP)
                {
                  if (defect.__isJoinInspection)
                  {
                    return item;
                  }

                  return item.mode === types.inspection.DefectFileMode.NEW ? null : { ...item, mode: types.inspection.DefectFileMode.DEL }
                }

                return item;
              })
              .filter((item) => item !== null)
              .filter((item) => item!.mode !== types.inspection.DefectFileMode.EXIST)
              .map((file) => ({
                guid: file!.guid,
                name: file!.name,
                type: file!.type,
                mode: file!.mode
              })),
            pieces: (defect.pieces || []).map((piece) => ({
              guid: piece.guid,
              qmetId: piece.qmetId,
              heat: piece.heat,
              piece:  piece.piece,
              groupAtt: piece.groupAtt,
              qcNum: piece.qcNum,
              isPiece: piece.isPiece,
              weightBeforeProcessing: piece.weightBeforeProcessing,
              weightAfterProcessing: piece.weightAfterProcessing,
              marriageShare: piece.marriageShare,
              weight: piece.weight ?? 0,
              unitId: piece.unit.id,
              note: piece.note,
              topography: (
                piece.topography !== null && 
                (
                  (piece.topography.defectSize !== null && piece.topography.defectSize !== 0) ||
                  piece.topography.defectTpgIdSize !== null ||
                  (piece.topography.defectStep !== null && piece.topography.defectStep !== 0) ||
                  piece.topography.defectTpgIdStep !== null ||
                  (piece.topography.defectDistanceFromEdge !== null && piece.topography.defectDistanceFromEdge !== 0) ||
                  piece.topography.defectTpgIdDistance !== null
                )) ?
                  {
                    defectSize: piece.topography.defectSize,
                    defectTpgIdSize: piece.topography.defectTpgIdSize,
                    defectStep: piece.topography.defectStep,
                    defectTpgIdStep: piece.topography.defectTpgIdStep,
                    defectDistanceFromEdge: piece.topography.defectDistanceFromEdge,
                    defectTpgIdDistance: piece.topography.defectTpgIdDistance
                  }
                  :
                  null,
              fields: piece.fields.map((field) => ({
                id: field.id,
                value: field.value
              })),
              files: piece.files.filter((item) => item.mode !== types.inspection.PieceFileMode.EXIST).map((file) => ({
                guid: file.guid,
                name: file.name,
                type: file.type,
                mode: file.mode
              })),
              mode: piece.mode,
            })),
            complaint: (defect.complaint === null || defect.complaint.typeComplaint === null) ? null : {
              typeComplaint: defect.complaint.typeComplaint,
              isToScrap: defect.complaint.isToScrap,
              scrapPrice: defect.complaint.scrapPrice,
              isEvaluateRolledMetal: defect.complaint.isEvaluateRolledMetal,
              requirementRolledMetall: defect.complaint.requirementRolledMetall,
              isToOffsetCosts: defect.complaint.isToOffsetCosts,
              amountOfCompensation: defect.complaint.amountOfCompensation,
              isReturnOfRolledMetal: defect.complaint.isReturnOfRolledMetal,
              isSale: defect.complaint.isSale,
              sale:  defect.complaint.sale,
              isRepresentative: defect.complaint.isRepresentative,
              isProvideResponse: defect.complaint.isProvideResponse,
              isIdentifyDangerous: defect.complaint.isIdentifyDangerous,
              isAnalizProblem: defect.complaint.isAnalizProblem,
              is8d: defect.complaint.is8d,
              otherReq: defect.complaint.otherReq,
              groupRevised: defect.complaint.groupRevised,
              groupRevisedValue: defect.complaint.groupRevisedValue,
              groupUnrevised: defect.complaint.groupUnrevised,
              groupUnrevisedValue: defect.complaint.groupUnrevisedValue,
              fio: defect.complaint.fio,
              enterprise: defect.complaint.enterprise,
              phone: defect.complaint.phone,
              email: defect.complaint.email,
              isTransferImmediately: defect.complaint.isTransferImmediately,
              mode: defect.complaint.mode,
            },
            mode: defect.mode,
          }))
        }

        try
        {
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Отправка осмотра'));
          await Api.postInspection(inspectionToSend);
        }
        catch (error)
        {
          const apiError = error as ApiError;

          thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));

          if (apiError.statusCode === 424)
          {
            return thunkAPI.rejectWithValue({
              error: 'На сервере отсутствуют запрошенные файлы. Попробуйте заново отправить осмотр',
              redirectToList: true,
            });
          }

          if (apiError.statusCode === 409)
          {
            return thunkAPI.rejectWithValue({
              error: 'На сервере отсутствуют запрошенные файлы. Попробуйте заново отправить осмотр',
              redirectToList: true,
            });
          }
          
          return thunkAPI.rejectWithValue({
            error: 'Не удалось отправить осмотр. Попробуйте повторить операцию позже',
            redirectToList: true,
          });
        }

        try
        {
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusLabelChanged('Удаление осмотра из локальной базы данных'));
          await Db.removeInspection(gE.guid);
        }
        catch (error)
        {
          thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
          return thunkAPI.rejectWithValue({
            error: 'Осмотр отправлен, но не был удален из локальной базы данных',
            redirectToList: true,
          });
        }

        thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.SUCCESS));
      }
    }
    catch (error)
    {
      thunkAPI.dispatch(inspectionEditActions.generalOpStatusChanged(AsyncOpStatus.ERROR));
      return thunkAPI.rejectWithValue({
        error: 'При сохранении осмотра произошла ошибка, попробуйте повторить операцию позже',
        redirectToList: true,
      });
    }
  }
);
// #endregion

// #region transferToSeverstalAsync
type TransferToSeverstalArgs = {
  guid: string;
};
type TransferToSeverstalResolve = void;
export type TransferToSeverstalReject = string;
export const transferToSeverstalAsync = createAsyncThunk<
  TransferToSeverstalResolve,
  TransferToSeverstalArgs,
  {
    state: RootState,
    rejectValue: TransferToSeverstalReject,
  }
>(
  `${SLICE_NAME}/transferToSeverstalAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.postInspectionTransferToSeverstal({ guid: args.guid });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue('Не удалось передать осмотр в Северсталь');
    }
  }
);
// #endregion

// #region reportHistoryToEmailAsync
type ReportHistoryToEmailArgs = {
  guid: string;
};
type ReportHistoryToEmailResolve = string;
export type ReportHistoryToEmailReject = string;
export const reportHistoryToEmailAsync = createAsyncThunk<
  ReportHistoryToEmailResolve,
  ReportHistoryToEmailArgs,
  {
    state: RootState,
    rejectValue: ReportHistoryToEmailReject,
  }
>(
  `${SLICE_NAME}/reportHistoryToEmailAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.getInspectionHistoryRequestAsExcelToEmail({ guid: args.guid });
      const account = thunkAPI.getState().authCommon.account!;
      return `Результаты осмотра поставлены в очередь на отправку на email ${account.email}. Ожидайте результат в течение 5 - 10 минут`;
    }
    catch (error)
    {
      const apiError = error as ApiError;

      if (apiError.statusCode === 404)
      {
        return thunkAPI.rejectWithValue('Осмотр не найден');
      }

      if (apiError.statusCode === 500)
      {
        return thunkAPI.rejectWithValue('Сервер недоступен. Повторите попытку позже');
      }

      return thunkAPI.rejectWithValue('Проверьте подключение к сети. Отчет не отправлен');
    }
  }
);
// #endregion

// #region reportToEmailAsync
type ReportToEmailArgs = {
  guid: string;
};
type ReportToEmailResolve = string;
export type ReportToEmailReject = string;
export const reportToEmailAsync = createAsyncThunk<
  ReportToEmailResolve,
  ReportToEmailArgs,
  {
    state: RootState,
    rejectValue: ReportToEmailReject,
  }
>(
  `${SLICE_NAME}/reportToEmailAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.postInspectionReportByGuid({ guid: args.guid });
      const account = thunkAPI.getState().authCommon.account!;
      return `Результаты осмотра поставлены в очередь на отправку на email ${account.email}. Ожидайте результат в течение 5 - 10 минут`;
    }
    catch (error)
    {
      const apiError = error as ApiError;

      if (apiError.statusCode === 400 || apiError.statusCode === 409)
      {
        return thunkAPI.rejectWithValue('По данному осмотру выполняется синхранизация данных. Повторите попытку позже');
      }

      if (apiError.statusCode === 403 || apiError.statusCode === 449 || apiError.statusCode === 500)
      {
        return thunkAPI.rejectWithValue('Сервер недоступен. Повторите попытку позже');
      }

      return thunkAPI.rejectWithValue('Проверьте подключение к сети. Отчет не отправлен');
    }
  }
);
// #endregion

// #region deleteInspectionAsync
type DeleteInspectionArgs = {
  guid: string;
};
type DeleteInspectionResolve = void;
export type DeleteInspectionReject = string;
export const deleteInspectionAsync = createAsyncThunk<
  DeleteInspectionResolve,
  DeleteInspectionArgs,
  {
    state: RootState,
    rejectValue: DeleteInspectionReject,
  }
>(
  `${SLICE_NAME}/deleteInspectionAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.deleteInspectionByGuid({ guid: args.guid });
    }
    catch (error)
    {
      const apiError = error as ApiError;

      if (apiError.statusCode !== 404)
      {
        return thunkAPI.rejectWithValue('При попытке удалить осмотр на сервере произошла ошибка');
      }
    }

    try
    {
      await Db.removeInspection(args.guid);
    }
    catch(error)
    {
      return thunkAPI.rejectWithValue('При попытке удалить осмотр из локального хранилища произошла ошибка');
    }
  }
);
// #endregion

// #region getQMMsgAsync
type GetQMMsgArgs = {
  guid: string;
};
type GetQMMsgResolve = types.qmmsg.QMMsg[];
export type GetQMMsgReject = string;
export const getQMMsgAsync = createAsyncThunk<
  GetQMMsgResolve,
  GetQMMsgArgs,
  {
    state: RootState,
    rejectValue: GetQMMsgReject,
  }
>(
  `${SLICE_NAME}/getQMMsgAsync`,
  async (args, thunkAPI) => {
    try
    {
      const result = await Api.getQMMsgClaims({ guidInspection: args.guid });
      return result.company.map((company) => company.qmMsgs).flat();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue('Не удалось получить статус осмотра');
    }
  }
);
// #endregion



const endingReducers = {
  endingOpened: (state: WritableDraft<InspectionEditState>) => {
    state.endingOpened = true;
  },

  endingClosed: (state: WritableDraft<InspectionEditState>) => {
    state.endingOpened = false;
  },
}

const endingSelectors = {
  selectEndingOpened: (state: RootState) => state.inspectionEdit.endingOpened,
  selectEndingEndCaption: (state: RootState) => state.inspectionEdit.endingEndCaption,
  selectEndingDraftCaption: (state: RootState) => state.inspectionEdit.endingDraftCaption,
  selectEndingCloseCaption: (state: RootState) => state.inspectionEdit.endingCloseCaption,
  selectEndingCancelCaption: (state: RootState) => state.inspectionEdit.endingCancelCaption,
}

// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;
type StringChangedAction = PayloadAction<string>;
type NumberChangedAction = PayloadAction<number>;
type NullableNumberChangedAction = PayloadAction<Nullable<number>>;
type StringArrayChangedAction = PayloadAction<string[]>;
type NullableStringChangedAction = PayloadAction<Nullable<string>>;
type PhotoAddedAction = PayloadAction<types.inspection.PieceFile>;
type DefectDocumentAddedAction = PayloadAction<types.inspection.DefectFile>;
type CustomFieldChangedAction = PayloadAction<types.inspection.PieceField>;
type ComplaintTypeChangedAction = PayloadAction<types.inspection.ComplaintType>;
type BooleanChangedAction = PayloadAction<boolean>;

export const inspectionEditSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    ...generalReducers,
    ...pieceReducers,
    ...defectReducers,
    ...complaintReducers,
    ...endingReducers,
  },
  extraReducers: (builder) => extraReducers.forEach((creator) => creator(builder)),
})

export const inspectionEditSelectors = {
  ...generalSelectors,
  ...pieceSelectors,
  ...defectSelectors,
  ...complaintSelectors,
  ...endingSelectors,
}

export const inspectionEditActions = inspectionEditSlice.actions;

export default inspectionEditSlice.reducer;
