import styled              from 'styled-components';

import { CloseVector     } from 'src/components/vector/close';

import { LoadingVariants } from 'src/components/common/loading';

import { cssFlexRow,
         cssFlexColumn, 
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS       } from 'src/providers/theme/shared';



type RefersLoaderLookAndFeel = {
  paddings: number[];
  iconSize: number;
  gap: number;
  contentGap: number;

  labelTypo: Typo;
  actionTypo: Typo;

  backgroundColor: string;
  labelColor: string;
  actionColor: string;
  errorIconColor: string;
  successIconColor: string;

  loadingVariant: LoadingVariants;
}

const refersloader_light: RefersLoaderLookAndFeel = {
  paddings: [16],
  iconSize: 24,
  gap: 12,
  contentGap: 8,

  labelTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  actionTypo: themeTypos.GRTSKREG_W600_S12_LH18,

  backgroundColor: themeColors.PrimaryWhite,
  labelColor: themeColors.PrimaryBlack,
  actionColor: themeColors.AccentBlue2,
  errorIconColor: themeColors.AccentRed1,
  successIconColor: themeColors.AccentBlue2,

  loadingVariant: 'lightblue24',
}

export type RefersLoaderVariants = 'light';

export const refersloader_variants: Map<RefersLoaderVariants, RefersLoaderLookAndFeel> = 
  new Map<RefersLoaderVariants, RefersLoaderLookAndFeel>([
    ['light', refersloader_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${cssFlexRow};
  align-items: center;
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const Icon = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.iconSize}px;
  height: ${(args) => args.lookAndFeel.iconSize}px;
  flex-grow: 0;
  flex-shrink: 0;
`

export const IconError = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  color: ${(args) => args.lookAndFeel.errorIconColor};
`

export const IconSuccess = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  color: ${(args) => args.lookAndFeel.successIconColor};
`

export const ContentContainer = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.contentGap}px;
  flex-grow: 2;
`

export const ContentLabel = styled.span<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
`

export const ContentAction = styled.span<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.actionTypo)};
  color: ${(args) => args.lookAndFeel.actionColor};
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;

export const CloseContainer = styled.div<{
  lookAndFeel: RefersLoaderLookAndFeel;
}>`
  ${cssFlexRow};
`
