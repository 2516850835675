import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type TagsLookAndFeel = {
  typo: Typo;

  activeBgColor: string;
  inactiveBgColor: string;
  activeLabelColor: string;
  inactiveLabelColor: string;

  tagHeight: number;
  tagPaddings: number[];
  tagBorderRadius: number;
  gap: number;
  wrap: string;
}

const tags_h24: TagsLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S12_LH14,

  activeBgColor: themeColors.AccentBlue2,
  inactiveBgColor: themeColors.SecondaryGray2,
  activeLabelColor: themeColors.PrimaryWhite,
  inactiveLabelColor: themeColors.PrimaryBlack,

  tagHeight: 24,
  tagPaddings: [0, 8],
  tagBorderRadius: 0,
  gap: 6,
  wrap: 'wrap'
}

const tags_h36r75: TagsLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S16_LH20,

  activeBgColor: themeColors.AccentBlue2,
  inactiveBgColor: themeColors.SecondaryGray2,
  activeLabelColor: themeColors.PrimaryWhite,
  inactiveLabelColor: themeColors.PrimaryBlack,

  tagHeight: 36,
  tagPaddings: [0, 16],
  tagBorderRadius: 75,
  gap: 8,
  wrap: 'nowrap'
}

export type TagsVariants = 'h24' | 'h36r75';

export const tags_variants: Map<TagsVariants, TagsLookAndFeel> = 
  new Map<TagsVariants, TagsLookAndFeel>([
    ['h24', tags_h24],
    ['h36r75', tags_h36r75],
  ]);

export const Container = styled.div<{
  lookAndFeel: TagsLookAndFeel;
}>`
  ${cssFlexRow};

  flex-wrap: ${(args) => args.lookAndFeel.wrap};
  gap: ${(args) => args.lookAndFeel.gap}px;

  width: 100%;
  overflow-x: auto;
`

export const Tag = styled.div<{
  isActive: boolean;
  lookAndFeel: TagsLookAndFeel;
}>`
  ${cssFlexRow};

  height: ${(args) => args.lookAndFeel.tagHeight}px;

  padding: ${(args) => args.lookAndFeel.tagPaddings[0]}px ${(args) => args.lookAndFeel.tagPaddings[1]}px;
  align-items: center;
  
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}

  background-color: ${(args) => args.isActive ? args.lookAndFeel.activeBgColor : args.lookAndFeel.inactiveBgColor };
  color: ${(args) => args.isActive ? args.lookAndFeel.activeLabelColor : args.lookAndFeel.inactiveLabelColor };

  border-radius: ${(args) => args.lookAndFeel.tagBorderRadius}px;

  white-space: nowrap;

  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;
`;

export const Padding = styled.div<{
  lookAndFeel: TagsLookAndFeel;
  size: number;
}>`
  width: ${(args) => args.size - args.lookAndFeel.gap}px;
  flex-shrink: 0;
`
