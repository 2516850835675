import * as React                 from 'react';

import dayjs,
       { Dayjs                  } from 'dayjs';
import 'dayjs/locale/ru';

import { AdapterDayjs           } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider   } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker       } from '@mui/x-date-pickers/MobileDatePicker';

import { CalendarVector         } from 'src/components/vector/calendar';

import { IconButton,
         IconButtonVariants     } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants      } from 'src/components/common/input/input-base';
import { Input,
         InputCalendarVariants,
         inputcalendar_variants } from 'src/components/common/input/input-calendar/ui';

import { Nullable               } from 'src/common';



const inputBaseVariantMap: Map<InputCalendarVariants, InputBaseVariants> = 
  new Map<InputCalendarVariants, InputBaseVariants>([
    ['h48light', 'h48light']
  ]);

const iconButtonVariantMap: Map<InputCalendarVariants, IconButtonVariants> = 
  new Map<InputCalendarVariants, IconButtonVariants>([
    ['h48light', 's46bluered']
  ]);

type Props = {
  variant: InputCalendarVariants;
  isError?: boolean;
  isDisabled?: boolean;
  hint?: string;
  placeholder?: string;
  value: Nullable<Date>;
  onChange: (newValue: Nullable<Date>) => void;
}
export const InputCalendar: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  hint,
  placeholder,
  value,
  onChange,
}) => {
  const [isDateSelectorOpen, setDateSelectorOpen] = React.useState<boolean>(false);

  const lookAndFeel = inputcalendar_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  return (
    <LocalizationProvider dateAdapter = { AdapterDayjs } adapterLocale = 'ru'>
        <MobileDatePicker
          open = { isDateSelectorOpen }
          label = 'Выберите дату'
          showToolbar = { false }
          mask = '__.__.____'
          value = { dayjs(value) }
          onChange={ (newValue) => {
            onChange(newValue === null ? null : (newValue as Dayjs).toDate());
          } }
          disabled = { isDisabled }
          onAccept = { () => setDateSelectorOpen(false) }
          onClose = { () => setDateSelectorOpen(false) }
          renderInput = { () => (
            <InputBase 
              variant = { inputBaseVariant }
              isError = { isError }
              isDisabled = { isDisabled }
              hint = { hint }
            >
              <Input
                lookAndFeel = { lookAndFeel }
                isDisabled = { isDisabled }
                disabled = { isDisabled }
                readOnly
                placeholder = { placeholder }
                value = { value === null ? '' : dayjs(value).format('DD.MM.YYYY') }
              />
              <IconButton
                variant = { iconButtonVariant }
                isTapAllowed = { !isDisabled }
                isDot = { false }
                onTap = { () => setDateSelectorOpen(true) }
              >
                <CalendarVector />
              </IconButton>
            </InputBase>
          )}
        />
    </LocalizationProvider>
  );
};
