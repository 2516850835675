import React from 'react';



type Props = {
  className?: string;
}
export const ReportVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M5 12V8L10 3H19V21H12M10 18H6M6 18H2M6 18V14M6 18V22' stroke = 'currentColor' strokeWidth = '2'/>
      <line y1 = '-1' x2 = '7' y2 = '-1' transform = 'matrix(-1 0 0 1 16 12)' stroke = 'currentColor' strokeWidth = '2'/>
      <line y1 = '-1' x2 = '4' y2 = '-1' transform = 'matrix(-1 0 0 1 16 16)' stroke = 'currentColor' strokeWidth = '2'/>
      <line y1 = '-1' x2 = '5' y2 = '-1' transform = 'matrix(-1 0 0 1 16 8)' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
