import styled                      from 'styled-components';

import { BlockCaptionVariants    } from 'src/components/features/inspection/common/block-caption';

import { PieceItemVariants       } from 'src/components/features/inspection/edit/piece-item';

import { cssFlexColumn           } from 'src/providers/theme/shared';



type PieceListLookAndFeel = {
  gap: number;

  blockCaptionVariant: BlockCaptionVariants;
  positionItemVariant: PieceItemVariants;
}

const piecelist_light: PieceListLookAndFeel = {
  gap: 24,

  blockCaptionVariant: 'light',
  positionItemVariant: 'light',
}

export type PieceListVariants = 'light';

export const piecelist_variants: Map<PieceListVariants, PieceListLookAndFeel> = 
  new Map<PieceListVariants, PieceListLookAndFeel>([
    ['light', piecelist_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const List = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
`
