import * as React                  from 'react';

import { TreeNode                } from 'react-treebeard';

import Drawer                      from '@mui/material/Drawer';

import { ArrowBackVector         } from 'src/components/vector/arrowback';
import { StarOutlineVector       } from 'src/components/vector/star-outline';
import { LoupeVector             } from 'src/components/vector/loupe';

import { IconButton              } from 'src/components/common/icon-button';
import { Typography              } from 'src/components/common/typography';
import { Tree,
         walkTree                } from 'src/components/common/tree';
import { InputTextbox            } from 'src/components/common/input/input-textbox';
import { HGap                    } from 'src/components/common/flex/hgap';
import { Grow                    } from 'src/components/common/flex/grow';

import { Container,
         HeaderContainer,
         SearchContainer,
         TreeContainer,
         CloseOutlineVectorBig,
         DefectSelectorVariants,
         defectselector_variants } from 'src/components/features/common/defect-selector/ui';

import * as types                  from 'src/services/api/types';



type Props = {
  variant: DefectSelectorVariants;
  defectList: types.refers.DefectReferElement[];
  favoriteList: number[];
  isOpened: boolean;
  onClose: () => void;
  onFavoriteChange: (id: number, isFavorite: boolean) => void;
  onSelect: (defect: types.refers.DefectReferElement) => void;
}
export const DefectSelector: React.FC<Props> = ({
  variant,
  defectList,
  favoriteList,
  isOpened,
  onClose,
  onFavoriteChange,
  onSelect,
}) => {
  const lookAndFeel = defectselector_variants.get(variant)!;

  const [mode, setMode] = React.useState<'tree' | 'favorite'>('tree');
  const [searchPhrase, setSearchPhrase] = React.useState<string>('');
  const [treeData, setTreeData] = React.useState<TreeNode[]>([]);

  /**
   * Сложный эффект, который подготавливает данные для дерева
   * в формате, которое она понимает. Дефекты приходят в виде
   * плоского массива, но дереву нужна иерархическая структура.
   * Этапы:
   * 1. Сформировать собственно дерево
   * 2. Узлы верхнего уровня отсортировать на поддеревья и листовые вершины. Отсортировать
   *    каждую из этих групп по алфавиту.
   * 3. Все узлы поддеревьев также рекурсивно отсортировать по алфавиту.
   * 4. Первое поддерево пометить флагом (для ui).
   */
  React.useEffect(() => {
    if (defectList.length > 0)
    {
      const root: TreeNode[] = [];
      let defectsWorkCopy = [...defectList];
      const nodeCache: { [key: string]: TreeNode } = {};

      defectsWorkCopy.sort((a, b) => a.parentId === 0 && b.parentId === 0 ? 0 : a.parentId - b.parentId);

      defectsWorkCopy.filter((defect) => !([-1, -999].includes(defect.id))).forEach((defect) => {
        if (defect.parentId === 0)
        {
          const topLevelNode: TreeNode = {
            id: `${defect.id}`,
            name: defect.title,
            ...(defect.isGroup ? { children: [] } : {}),
            extra: defect,
            isFavorite: defect.isFavorite,
            isTopLevel: true,
            isTopLevelFirst: false,
          };

          if (defect.isGroup)
          {
            nodeCache[`${defect.id}`] = topLevelNode;
          }

          root.push(topLevelNode);
        }
        else
        {
          const node = nodeCache[`${defect.parentId}`];

          if (node) 
          {
            if (node.children === undefined)
            {
              node.children = [];
            }

            const newNode: TreeNode = {
              id: `${defect.id}`,
              name: defect.title,
              ...(defect.isGroup ? { children: [] } : {}),
              extra: defect,
              isFavorite: defect.isFavorite,
              isTopLevel: false,
              isTopLevelFirst: false,
            };

            if (defect.isGroup)
            {
              nodeCache[`${defect.id}`] = newNode;
            }

            (node.children as TreeNode[]).push(newNode);
          }
        }
      });

      const rootDirs = root.filter(
        (item) => item.children !== undefined
      );
      const rootLeafs = root.filter(
        (item) => item.children === undefined
      );
      const sortedRoot = [
        ...rootDirs.sort((a, b) => (a.name > b.name ? 1 : -1)),
        ...rootLeafs.sort((a, b) => (a.name > b.name ? 1 : -1)),
      ];

      sortedRoot.forEach((walkNode) => {
        walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
          if (node.children)
          {
            const dirs = node.children.filter(
              (item) => item.children !== undefined
            );
            const leafs = node.children.filter(
              (item) => item.children === undefined
            );
            node.children = [
              ...dirs.sort((a, b) => (a.name > b.name ? 1 : -1)),
              ...leafs.sort((a, b) => (a.name > b.name ? 1 : -1)),
            ];
            node.active = false;
          }
        });
      })

      if (sortedRoot.length > 0)
      {
        sortedRoot[0].isTopLevelFirst = true;
      }

      setTreeData([ ...sortedRoot ]);
    }
  }, [defectList]);

  React.useEffect(() => {
    const lcSearch = searchPhrase.toLowerCase();

    setTreeData(
      tD => {
        if (tD.length > 0)
        {
          tD.forEach((walkNode) => {
            walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
              node.name = (node.extra as types.refers.DefectReferElement).title;
              node.hidden = mode === 'favorite' && !node.isFavorite;
              if (node.children)
              {
                node.toggled = false;
              }
            });
          });
          
          if (searchPhrase.length > 0)
          {
            tD.forEach((walkNode) => {
              walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
                if (!node.children)
                {
                  const title = (node.extra as types.refers.DefectReferElement).title;
                  const lcTitle = title.toLowerCase();
                  if (lcTitle.includes(lcSearch))
                  {
                    const searchIndex = lcTitle.indexOf(lcSearch);
                    const startStr = title.substring(0, searchIndex);
                    const middleStr = title.substring(searchIndex, searchIndex + searchPhrase.length);
                    const endStr = title.substring(searchIndex + searchPhrase.length);

                    node.name = `${startStr}<span>${middleStr}</span>${endStr}`;
                    path.forEach((pathNode) => {
                      pathNode.toggled = true;
                    });
                    node.hidden = mode === 'favorite' && !node.isFavorite;
                  }
                  else
                  {
                    node.name = title;
                    node.hidden = true;
                  }
                }
              });
            });
          }

          tD.forEach((walkNode) => {
            walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
              if (node.children)
              {
                node.hidden = node.children.every((node) => node.hidden);
              }
            });
          });

          return [...tD];
        }

        return tD;
      }
    );
  }, [searchPhrase, mode]);

  React.useEffect(() => {
    setTreeData(
      tD => {
        if (tD.length > 0)
        {
          tD.forEach((walkNode) => {
            walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
              node.isFavorite = favoriteList.includes((node.extra as types.refers.DefectReferElement).id);
              node.hidden = mode === 'favorite' && !node.isFavorite;
            });
          });

          tD.forEach((walkNode) => {
            walkTree(walkNode, [], (node: TreeNode, path: TreeNode[]) => {
              if (node.children)
              {
                node.hidden = node.children.every((node) => node.hidden);
              }
            });
          });

          return [...tD];
        }

        return tD;
      }
    );
  }, [favoriteList, mode]);

  const onTreeNodeToggleHandler = (node: TreeNode, toggled: boolean) => {
    const isLeafNode = !node.children;

    if (!isLeafNode)
    {
      node.toggled = toggled;
    }

    setTreeData([ ...(treeData as TreeNode[]) ]);
  };

  const onFavoriteToggleFromTree = (node: TreeNode) => {
    const extra = node.extra as types.refers.DefectReferElement;
    onFavoriteChange(extra.id, !node.isFavorite);
  }

  const onSelectFromTreeHandler = (node: TreeNode) => {
    if (node.children === undefined)
    {
      const extra = node.extra as types.refers.DefectReferElement;
      onSelect(extra);
      setSearchPhrase('');
      onClose();
    }
  }

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { () => { setSearchPhrase(''); onClose(); } }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          {mode === 'favorite' && (
            <IconButton
              variant = { lookAndFeel.headerIconButtonVariant }
              isTapAllowed
              onTap = { () => setMode('tree') }
            >
              <ArrowBackVector />
            </IconButton>
          )}
          {mode === 'tree' && (
            <HGap size = { lookAndFeel.containerPaddings[1] - lookAndFeel.headerPaddings[1] } />
          )}
          <Typography variant = { lookAndFeel.headerTypographyVariant }>
            { mode === 'tree' ? 'Справочник дефектов' : 'Избранное' }
          </Typography>
          <Grow size = { 2 } />
          {mode === 'tree' && (
            <IconButton
              variant = { lookAndFeel.headerIconButtonVariant }
              isTapAllowed
              onTap = { () => setMode('favorite') }
            >
              <StarOutlineVector />
            </IconButton>
          )}
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => { setSearchPhrase(''); onClose(); } }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        <SearchContainer lookAndFeel = { lookAndFeel }>
          <InputTextbox
            variant = { lookAndFeel.searchInputVariant }
            iconPrefix = { <LoupeVector /> }
            value = { searchPhrase }
            onChange = { setSearchPhrase }
          />
        </SearchContainer>
        <TreeContainer lookAndFeel = { lookAndFeel }>
          {treeData.length > 0 &&
            <Tree
              variant = 'light'
              onToggle = { onTreeNodeToggleHandler }
              onFavoriteToggle = { onFavoriteToggleFromTree }
              onSelect = { onSelectFromTreeHandler }
              data = { treeData }
              isFavoriteMode = { mode === 'favorite' }
              isSearchNotEmpty = { searchPhrase.trim().length > 0 }
            />
          }
        </TreeContainer>
      </Container>
    </Drawer>
  );
};
