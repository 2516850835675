import styled          from 'styled-components';

import { cssFlexRow,
         themeColors } from 'src/providers/theme/shared';



type SwitchLookAndFeel = {
  width: number;
  height: number;
  bgWidth: number;
  bgHeight: number;
  ballSize: number;

  checkedBgColor: string;
  checkedBgOpacity: number;
  checkedBallColor: string;
  uncheckedBgColor: string;
  uncheckedBgOpacity: number;
  uncheckedBallColor: string;
}

const switch_w40h24: SwitchLookAndFeel = {
  width: 40,
  height: 24,
  bgWidth: 34,
  bgHeight: 14,
  ballSize: 20,

  checkedBgColor: themeColors.AccentBlue2,
  checkedBgOpacity: 50,
  checkedBallColor: themeColors.AccentBlue2,
  uncheckedBgColor: themeColors.SecondaryGray4,
  uncheckedBgOpacity: 100,
  uncheckedBallColor: themeColors.PrimaryWhite,
}

export type SwitchVariants = 'w40h24';

export const switch_variants: Map<SwitchVariants, SwitchLookAndFeel> = 
  new Map<SwitchVariants, SwitchLookAndFeel>([
    ['w40h24', switch_w40h24],
  ]);

export const Container = styled.div<{
  lookAndFeel: SwitchLookAndFeel
}>`
  ${cssFlexRow};

  justify-content: center;
  align-items: center;

  width: ${(args) => args.lookAndFeel.width}px;
  height: ${(args) => args.lookAndFeel.height}px;
`

export const Checkbox = styled.div<{
  lookAndFeel: SwitchLookAndFeel
}>`
  ${cssFlexRow};

  width: ${(args) => args.lookAndFeel.bgWidth}px;
  height: ${(args) => args.lookAndFeel.bgHeight}px;

  > input {
    opacity: 0;
    width: 0;
    height: ${(args) => args.lookAndFeel.bgHeight}px;
  }

  > input + i {
    position: relative;
    width: ${(args) => args.lookAndFeel.bgWidth}px;
    height: ${(args) => args.lookAndFeel.bgHeight}px;
  }

  > input + i::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: ${(args) => args.lookAndFeel.bgWidth}px;
    height: ${(args) => args.lookAndFeel.bgHeight}px;
  
    background-color: ${(args) => args.lookAndFeel.uncheckedBgColor};
    opacity: ${(args) => args.lookAndFeel.uncheckedBgOpacity}%;
  
    border-radius: ${(args) => args.lookAndFeel.bgHeight * 1.25}px;
    transition: background-color 0.3s, opacity 0.3s;
  }

  > input + i::after {
    position: absolute;
    content: '';
    width: ${(args) => args.lookAndFeel.ballSize}px;
    height: ${(args) => args.lookAndFeel.ballSize}px;
    position: absolute;
    left: -${(args) => (args.lookAndFeel.ballSize - args.lookAndFeel.bgHeight) / 2}px;
    top: -${(args) => (args.lookAndFeel.ballSize - args.lookAndFeel.bgHeight) / 2}px;
  
    background-color: ${(args) => args.lookAndFeel.uncheckedBallColor};
    border-radius: 50%;
    opacity: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.237602), 0px 0px 1px rgba(0, 0, 0, 0.12);
    transition: background-color 0.3s, left 0.3s;
  }

  > input:checked + i::before {
    background-color: ${(args) => args.lookAndFeel.checkedBgColor};
    opacity: ${(args) => args.lookAndFeel.checkedBgOpacity}%;
  }

  > input:checked + i::after {
    background-color: ${(args) => args.lookAndFeel.checkedBallColor};
    left: ${(args) => args.lookAndFeel.bgWidth - args.lookAndFeel.ballSize + (args.lookAndFeel.ballSize - args.lookAndFeel.bgHeight) / 2}px;
  }

  
`;
