import { createSlice,
         createAsyncThunk,
         createSelector,
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'main:token';

export interface MainTokenState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;
  list: types.token.RestToken[];
}

const initialState: MainTokenState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',
  list: [],
}

// #region getTokenListAsync
type GetTokenListArgs = void;
type GetTokenListResolve = types.token.RestToken[];
export type GetTokenListReject = ApiError;
export const getTokenListAsync = createAsyncThunk<
  GetTokenListResolve,
  GetTokenListArgs,
  {
    state: RootState,
    rejectValue: GetTokenListReject
  }
>(
  `${SLICE_NAME}/getTokenListAsync`,
  async (_, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainTokenActions.opStatusLabelChanged('Загрузка списка токенов'));
      const result = await Api.getRefreshTokens();
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return result;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region emmitRefreshTokenAsync
type EmmitRefreshTokenArgs = void;
type EmmitRefreshTokenResolve = types.token.RestToken;
export type EmmitRefreshTokenReject = ApiError;
export const emmitRefreshTokenAsync = createAsyncThunk<
  EmmitRefreshTokenResolve,
  EmmitRefreshTokenArgs,
  {
    state: RootState,
    rejectValue: EmmitRefreshTokenReject
  }
>(
  `${SLICE_NAME}/emmitRefreshTokenAsync`,
  async (userId, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainTokenActions.opStatusLabelChanged('Выпуск нового токена'));
      const token = await Api.postEmmitRefreshToken();
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return token;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

// #region revokeRefreshTokenAsync
type RevokeRefreshTokenArgs = string;
type RevokeRefreshTokenResolve = string;
export type RevokeRefreshTokenReject = ApiError;
export const revokeRefreshTokenAsync = createAsyncThunk<
  RevokeRefreshTokenResolve,
  RevokeRefreshTokenArgs,
  {
    state: RootState,
    rejectValue: RevokeRefreshTokenReject
  }
>(
  `${SLICE_NAME}/revokeRefreshTokenAsync`,
  async (token, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainTokenActions.opStatusLabelChanged('Отзыв токена'));
      await Api.deleteRefreshToken({ token });
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return token;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainTokenActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

export const mainTokenSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokenListAsync.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(emmitRefreshTokenAsync.fulfilled, (state, action) => {
        state.list = [...state.list, action.payload];
      })
      .addCase(revokeRefreshTokenAsync.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => item.token !== action.payload);
      })
  },
})

const selectOpStatus = (state: RootState) => state.mainToken.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainToken.opStatusLabel;
const selectList = (state: RootState) => state.mainToken.list;
const selectListCount = createSelector(
  [selectList],
  (list) => list.length
);

export const mainTokenSelectors = {
  selectOpStatus,
  selectOpStatusLabel,
  selectList,
  selectListCount,
}

export const mainTokenActions = mainTokenSlice.actions;

export default mainTokenSlice.reducer;