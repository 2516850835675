import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type AppCaptionLookAndFeel = {
  labelTypo: Typo;
  labelColor: string;

  versionWidth: number;

  versionTypo: Typo;
  versionColor: string;
}

const appcaption_light: AppCaptionLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W600_S26_LH35,
  labelColor:  themeColors.AccentBlue2,

  versionWidth: 60,

  versionTypo: themeTypos.GRTSKREG_W400_S12_LH18,
  versionColor:  themeColors.PrimarySteel,
}

export type AppCaptionVariants = 'light';

export const appcaption_variants: Map<AppCaptionVariants, AppCaptionLookAndFeel> = 
  new Map<AppCaptionVariants, AppCaptionLookAndFeel>([
    ['light', appcaption_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AppCaptionLookAndFeel
}>`
  ${cssFlexRow};
  align-items: flex-end;

  width: 100%;
`

export const Version = styled.span<{
  lookAndFeel: AppCaptionLookAndFeel,
}>`
  flex-shrink: 0;
  width: ${(args) => args.lookAndFeel.versionWidth}px;
  text-align: right;

  ${(args) => typoAsCSS(args.lookAndFeel.versionTypo)}
  color: ${(args) => args.lookAndFeel.versionColor};
`

export const Caption = styled.span<{
  lookAndFeel: AppCaptionLookAndFeel,
}>`
  flex-grow: 2;
  text-align: center;

  padding-left: ${(args) => args.lookAndFeel.versionWidth}px;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
