import * as React               from 'react';

import { PatternFormat        } from 'react-number-format';

import { CloseVector          } from 'src/components/vector/close';

import { IconButton,
         IconButtonVariants   } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants    } from 'src/components/common/input/input-base';
import { Input,
         InputPhoneVariants,
         inputphone_variants  } from 'src/components/common/input/input-phone/ui';

import Util                     from 'src/services/util';

import { PatternFormatValues,
         voidFunction         } from 'src/common';



const inputBaseVariantMap: Map<InputPhoneVariants, InputBaseVariants> = 
  new Map<InputPhoneVariants, InputBaseVariants>([
    ['h48light', 'h48light']
  ]);

const iconButtonVariantMap: Map<InputPhoneVariants, IconButtonVariants> = 
  new Map<InputPhoneVariants, IconButtonVariants>([
    ['h48light', 's46bluered']
  ]);

type Props = {
  variant: InputPhoneVariants;
  isError?: boolean;
  isDisabled?: boolean;
  hint?: string;
  value: string;
  customIcon?: React.ReactNode;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
  onCustomIconTap?: () => void;
}
export const InputPhone: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  hint,
  value,
  customIcon,
  onChange,
  onFocus = voidFunction,
  onCustomIconTap = voidFunction,
}) => {
  const lookAndFeel = inputphone_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  const onChangeHandler = (values: PatternFormatValues) => {
    onChange(values.formattedValue);
  }

  const onClearHandler = () => {
    onChange(Util.getPhoneMaskEmpty());
  }
  
  const mask = React.useMemo(() => {
    const phone = Util.selectDigitsFromString(value);
    return Util.getPhoneMaskByNumber(phone);
  }, [value]);

  return (
    <InputBase variant = { inputBaseVariant } isError = { isError } isDisabled = { isDisabled } hint = { hint }>
      <PatternFormat
        format = { mask }
        allowEmptyFormatting
        mask = '_'
        customInput = { Input }
        lookAndFeel = { lookAndFeel }
        isError = { isError }
        isDisabled = { isDisabled }
        disabled = { isDisabled }
        value = { value }
        onValueChange = { onChangeHandler }
        onFocus = { onFocus }
      />
      { customIcon === undefined && value.trim().length > 0  && value !== Util.getPhoneMaskEmpty() && !isDisabled && (
        <IconButton variant = { iconButtonVariant } isTapAllowed = { !isDisabled } isDot = { false } onTap = { onClearHandler }>
          <CloseVector />
        </IconButton>
      )}
      { customIcon !== undefined && (
        <IconButton variant = { iconButtonVariant } isTapAllowed = { !isDisabled && onCustomIconTap !== voidFunction } isDot = { false } onTap = { onCustomIconTap }>
          { customIcon }
        </IconButton>
      )}
    </InputBase>
  );
};
