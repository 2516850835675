import * as React             from 'react';

import { AlertTemplateProps } from 'react-alert';

import { Container,
         Message,
         toast_variants     } from 'src/components/common/toast/ui';



export const Toast: React.FC<AlertTemplateProps> = ({
  message,
  options,
}) => {
  const lookAndFeel = options.type === 'info' ? 
    toast_variants.get('info')!
    : 
    options.type === 'success' ? 
      toast_variants.get('success')!
      : 
      toast_variants.get('error')!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Message lookAndFeel = { lookAndFeel }>
        { message }
      </Message>
    </Container>
  );
};
