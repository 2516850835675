import styled                 from 'styled-components';

import { CloseVector        } from 'src/components/vector/close';

import { TabSwitchVariants  } from 'src/components/common/tab-switch';
import { DialogVariants     } from 'src/components/common/dialog';
import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors        } from 'src/providers/theme/shared';



type PieceNumIdHelpDialogLookAndFeel = {
  paddings: number[];
  gap: number;

  captionTypo: Typo;
  captionColor: string;

  messageTypo: Typo;
  messageColor: string;
  messageAccentColor: string;

  separatorColor: string;

  dialogVariant: DialogVariants;
  switchExampleVariant: TabSwitchVariants;
  closeButtonVariant: IconButtonVariants;
}

const piecenumidhelpdialog_light: PieceNumIdHelpDialogLookAndFeel = {
  paddings: [24],
  gap: 24,

  captionTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  captionColor: themeColors.PrimaryBlack,

  messageTypo: themeTypos.GRTSKREG_W400_S14_LH20,
  messageColor: themeColors.SecondaryGray9,
  messageAccentColor: themeColors.AccentRed1,

  separatorColor: themeColors.SecondaryGray3,

  dialogVariant: 'light',
  switchExampleVariant: 'h32',
  closeButtonVariant: 's48bluered',

}

export type PieceNumIdHelpDialogVariants = 'light';

export const piecenumidhelpdialog_variants: Map<PieceNumIdHelpDialogVariants, PieceNumIdHelpDialogLookAndFeel> = 
  new Map<PieceNumIdHelpDialogVariants, PieceNumIdHelpDialogLookAndFeel>([
    ['light', piecenumidhelpdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceNumIdHelpDialogLookAndFeel
}>`
  ${cssFlexColumn};
  max-height: 90vh;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.gap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
  position: relative;
`

export const Caption = styled.div<{
  lookAndFeel: PieceNumIdHelpDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: PieceNumIdHelpDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};

  & span {
    color: ${(args) => args.lookAndFeel.messageAccentColor};
  }
`

export const Separator = styled.div<{
  lookAndFeel: PieceNumIdHelpDialogLookAndFeel
}>`
  width: 100%;
  height: 1px;
  background-color: ${(args) => args.lookAndFeel.separatorColor};
`

export const Close = styled.div<{
  lookAndFeel: PieceNumIdHelpDialogLookAndFeel
}>`
  position: absolute;
  top: 0;
  right: 0;
`


export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;
