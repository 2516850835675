import * as React                  from 'react';
 
import { useAlert                } from 'react-alert';

import { useTheme                } from 'styled-components';
 
import { ArrowBackVector         } from 'src/components/vector/arrowback';
import { FloppyVector            } from 'src/components/vector/floppy';
import { CircleWarningVector     } from 'src/components/vector/circle-warning';

import { BlockUI                 } from 'src/components/common/block-ui';
import { Dialog                  } from 'src/components/common/dialog';
import { Button                  } from 'src/components/common/button';
import { DateLabel               } from 'src/components/common/date-label';
import { TabSwitch               } from 'src/components/common/tab-switch';
import { TwoActionDialog         } from 'src/components/common/two-action-dialog';
import { VGap                    } from 'src/components/common/flex/vgap';

import { NavBar                  } from 'src/components/features/common/nav-bar';
import { PieceHelpDialog         } from 'src/components/features/inspection/edit/piece-help-dialog';
import { Roll                    } from 'src/components/features/main/shade-selection/roll';
import { Result                  } from 'src/components/features/main/shade-selection/result';
import { Report                  } from 'src/components/features/main/shade-selection/report';
import { SelectionRating         } from 'src/components/features/main/shade-selection/selection-rating';

import { ShadeSelectionLayout,
         Screen,
         ScreenBody,
         ScrollableContainer,
         ErrorIconWrapper,
         AddRoll,
         RollDivider,
         ActionsContainer,
         ActionButton,
         layout_light,
         layout_dark             } from 'src/containers/main/shade-selection/layout';

import { Urls                    } from 'src/providers/routing';

import Api                         from 'src/services/api';
import Util                        from 'src/services/util';
import Xlsx                        from 'src/services/xlsx';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                } from 'src/store';
import { GenerateSelectionReject } from 'src/store/main/shade-selection';

import { AsyncOpStatus           } from 'src/common';



type ShadeSelectionScreenElement = { layout: ShadeSelectionLayout; dispatch: AppDispatch; }

const Navigation: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Подбор оттенка'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainServices.build().navigate()
          }
        ]}
        endButtons={[
          {
            id: 'save',
            vector: <FloppyVector />,
            action: () => { }
          }
        ]}
      />
    </React.Fragment>
  )
}

const InputData: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  const [isHelpOpened, setIsHelpOpened] = React.useState<boolean>(false);
  const [isConfirmDeleteOpened, setIsConfirmDeletepOpened] = React.useState<boolean>(false);
  const [deleteGuid, setDeleteGuid] = React.useState<string>('');

  const rolls = useAppSelector(storeApi.main.shadeSelection.selectors.selectRolls);

  const onOpenHelpDialogHandler = () => {
    setIsHelpOpened(true);
  }

  const onHelpCloseHandler = () => {
    setIsHelpOpened(false);
  }

  const onDeleteHandler = (guid: string) => {
    setDeleteGuid(guid);
    setIsConfirmDeletepOpened(true);
  }

  const onConfirmDeleteCancelHandler = () => {
    setDeleteGuid('');
    setIsConfirmDeletepOpened(false);
  }

  const onConfirmDeleteAcceptHandler = () => {
    dispatch(storeApi.main.shadeSelection.actions.rollDeleted(deleteGuid));
    setDeleteGuid('');
    setIsConfirmDeletepOpened(false);
  }

  return (
    <React.Fragment>
      {rolls.map((roll, index) => (
        <React.Fragment key = { roll.id }>
          <Roll
            variant = { layout.rollVariant }
            isPrimary = { roll.isPrimary }
            isDeletable = { !roll.isPrimary && rolls.length > 2 }
            rollNumber = { index }
            rollValue = { roll.value }
            rollError = { roll.error }
            onChange = { (value) => dispatch(storeApi.main.shadeSelection.actions.rollChanged({ id: roll.id, value })) }
            onDelete = { () => onDeleteHandler(roll.id) }
            onInfo = { onOpenHelpDialogHandler }
          />
          {index === rolls.length - 1 && (
            <AddRoll layout = { layout }>
              <Button
                variant = { layout.addRollButtonVariant }
                label = 'Добавить рулон'
                onTap = { () => dispatch(storeApi.main.shadeSelection.actions.rollAppended()) }
              />
            </AddRoll>
          )}
          <RollDivider layout = { layout } />
        </React.Fragment>
      ))}
      <PieceHelpDialog
        variant = { layout.pieceHelpVariant }
        opened = { isHelpOpened }
        onClose = { onHelpCloseHandler }
      />
      <TwoActionDialog
        variant = { layout.deleteRollVariant }
        opened = { isConfirmDeleteOpened }
        caption = 'Удалить рулон?'
        isDanger
        cancelLabel = 'Отмена'
        actionLabel = 'Удалить'
        onCancel = { onConfirmDeleteCancelHandler }
        onAction = { onConfirmDeleteAcceptHandler }
      />
    </React.Fragment>
  )
}

const Results: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  const results = useAppSelector(storeApi.main.shadeSelection.selectors.selectResults);
  const selectionDate = useAppSelector(storeApi.main.shadeSelection.selectors.selectSelectionDate);
  const rolls = useAppSelector(storeApi.main.shadeSelection.selectors.selectRolls);
  const isResultGenerated = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsResultGenerated);
  const isRatingCompleted = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsRatingCompleted);

  const label = React.useMemo(() => {
    const primaryRoll = rolls.find((roll) => roll.isPrimary)!;
    return Util.shadeSelectionReportName(primaryRoll.value, selectionDate);
  }, [selectionDate, rolls]);

  const onActionHandler = async (action: 'navigate' | 'share') => {
    const uintData = Xlsx.reportShadeSelection(results, label);
    var blob = new Blob([uintData], { type: '' });

    switch (action)
    {
      case 'navigate': {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = label;
        a.click();
        break;
      }
    }
  }

  return (
    <React.Fragment>
      <Result variant = { layout.resultVariant } results = { results } />
      {isResultGenerated && results.length > 0 && (
        <React.Fragment>
          <VGap size = { 5 } />
          <Report
            variant = { layout.reportVariant }
            label = { label }
            onAction = { onActionHandler }
          />
        </React.Fragment>
      )}
      {isResultGenerated && !isRatingCompleted && (
        <React.Fragment>
          <VGap size = { 5 } />
          <Button
            variant = { layout.ratingButtonVariant }
            label = 'Оценить результат'
            onTap = { () => dispatch(storeApi.main.shadeSelection.actions.ratingOpened()) }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const Rating: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const isRatingOpened = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsRatingOpened);

  const onAcceptHandler = (isYes: boolean, note: string) => {
    dispatch(storeApi.main.shadeSelection.async.sendRatingMessageAsync({ isYes, note }))
      .unwrap()
      .catch((rawError) => {
        const error = rawError as GenerateSelectionReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  if (!isRatingOpened)
  {
    return null;
  }

  return (
    <Dialog variant = { layout.ratingDialogVariant } isOpened>
      <SelectionRating
        variant = { layout.ratingVariant }
        onAccept = { onAcceptHandler }
      />
    </Dialog>  
  )
}

const Selection: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  const selectionDate = useAppSelector(storeApi.main.shadeSelection.selectors.selectSelectionDate);
  const activeTab = useAppSelector(storeApi.main.shadeSelection.selectors.selectActiveTab);
  const isHasErrors = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsHasErrors);

  const tabs = React.useMemo(() => [
    {
      id: 'input-data',
      label: 'Ввод данных',
      prefixIcon: isHasErrors ?
        <ErrorIconWrapper layout={layout}><CircleWarningVector /></ErrorIconWrapper>
        :
        undefined
    },
    {
      id: 'result',
      label: 'Результат',
    }
  ], [isHasErrors]);

  const tab = tabs.find((item) => item.id === activeTab)!;

  return (
    <ScrollableContainer layout = { layout }>
      <DateLabel variant = { layout.dateLabelVariant } date = { new Date(selectionDate) } />
      <TabSwitch
        variant = { layout.tabSwitchVariant }
        items = { tabs }
        value = { tab }
        onChange = { (value) => dispatch(storeApi.main.shadeSelection.actions.activeTabChanged(value.id)) }
      />
      {activeTab === 'input-data' && (
        <InputData layout = { layout } dispatch = { dispatch } />
      )}
      {activeTab === 'result' && (
        <Results layout = { layout } dispatch = { dispatch } />
      )}
    </ScrollableContainer>
  )
}

const Actions: React.FC<ShadeSelectionScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const opStatus = useAppSelector(storeApi.main.shadeSelection.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.shadeSelection.selectors.selectOpStatusLabel);
  const isGenerateEnabled = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsGenerateEnabled);
  const isResultGenerated = useAppSelector(storeApi.main.shadeSelection.selectors.selectIsResultGenerated);

  const onGenerateHandler = () => {
    dispatch(storeApi.main.shadeSelection.async.generateSelectionAsync())
      .unwrap()
      .then((result) => {
        if (result.data.length > 0)
        {
          dispatch(storeApi.main.shadeSelection.actions.activeTabChanged('result'));
        }
      })
      .catch((rawError) => {
        const error = rawError as GenerateSelectionReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onRepeatHandler = () => {
    dispatch(storeApi.main.shadeSelection.actions.repeated());
  }

  return (
    <ActionsContainer layout = { layout }>
      <ActionButton layout = { layout }>
        <Button
          variant = { layout.generateButtonVariant }
          label = 'Сформировать результат'
          isDisabled = { !isGenerateEnabled }
          onTap = { onGenerateHandler }
        />
      </ActionButton>
      {isResultGenerated && (
        <ActionButton layout = { layout }>
          <Button
            variant = { layout.repeatButtonVariant }
            label = 'Новый подбор'
            onTap = { onRepeatHandler }
          />
        </ActionButton>
      )}
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
    </ActionsContainer>
  )
}

export const ShadeSelectionScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  React.useEffect(() => { dispatch(storeApi.main.shadeSelection.actions.init()); }, []);

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Selection layout = { layout } dispatch = { dispatch } />
        <Actions layout = { layout } dispatch = { dispatch } />
        <Rating layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
