import * as React              from 'react';

import { Dialog              } from 'src/components/common/dialog';
import { OneActionDialog     } from 'src/components/common/one-action-dialog';

import { HelpDialogVariants,
         helpdialog_variants } from 'src/components/features/main/inspection-list/help-dialog/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi            } from 'src/store';



type Props = {
  variant: HelpDialogVariants;
}
export const HelpDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = helpdialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  
  const opened = useAppSelector(storeApi.main.inspectionList.selectors.selectHelpOpened);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <OneActionDialog
        variant = { lookAndFeel.oneActionVarint }
        caption = 'Подсказка'
        message = 'Для подтверждения принятого решения при рассмотрении обращения -> нажмите на статус -> подтвердите или отклоните решение -> поставьте оценку'
        actionLabel = 'Продолжить'
        onAction = { () => dispatch(storeApi.main.inspectionList.actions.helpOpenedChanged(false)) }
      />
    </Dialog>
  );
};
