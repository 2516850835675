import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuTrashVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M2 5H18M16 8V17L14 19H6L4 17V8M5 5V2.5105L6.5 1H13.5L15 2.5105V5M8 8V16.0921M12 8V16.0921' />
    </svg>
  );
};
