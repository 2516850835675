import React from 'react';



type Props = {
  className?: string;
}
export const ClipboardVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M6 21L21 21L21 6' />
      <rect stroke = 'currentColor' strokeWidth = '2' x = '17' y = '17' width = '14' height = '14' transform = 'rotate(-180 17 17)' />
    </svg>
  );
};
