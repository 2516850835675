import * as React                      from 'react';

import { useAlert                    } from 'react-alert';

import { useTheme                    } from 'styled-components';

import { ProfileVector               } from 'src/components/vector/profile';
import { ServicePretenzStatusVector  } from 'src/components/vector/services/service-pretenz-status';
import { ServiceProblem8DVector      } from 'src/components/vector/services/service-problem-8d';
import { ServiceEnginePortalVector   } from 'src/components/vector/services/service-engine-portal';
import { ServiceCertCheckVector      } from 'src/components/vector/services/service-cert-check';
import { ServiceInternetShopVector   } from 'src/components/vector/services/service-internet-shop';
import { ServiceRestAPIVector        } from 'src/components/vector/services/service-rest-api';
import { ServiceTechconsultingVector } from 'src/components/vector/services/service-techconsulting';
import { ServiceShadeSelectionVector } from 'src/components/vector/services/service-shade-selection';

import { NavBar                      } from 'src/components/features/common/nav-bar';
import { Toolbar                     } from 'src/components/features/main/common/toolbar';
import { IconList,
         IconListElement             } from 'src/components/features/main/common/icon-list';

import { Screen,
         ScreenBody,
         layout_light,
         layout_dark,                } from 'src/containers/main/services/layout';

import { Urls                        } from 'src/providers/routing';

import * as types                      from 'src/services/api/types';

import { useAppSelector, 
         storeApi                    } from 'src/store';
import { AccountRole                 } from 'src/services/api/types/auth';




const { Client, ClientAdmin, Contractor, Stpk } = types.auth.AccountRole;

const allItems: IconListElement[] = [
  {
    code: 'pretenzstatus',
    busy: false,
    icon: <ServicePretenzStatusVector />,
    label: 'Статусы по претензиям',
    availableFor: (account: types.auth.AccountWithTokens) => [ClientAdmin, Stpk].includes(account.role),
  },
  {
    code: 'problem8d',
    busy: false,
    icon: <ServiceProblem8DVector />,
    label: 'Решение проблем 8D',
    availableFor: (account: types.auth.AccountWithTokens) => [ClientAdmin, Stpk].includes(account.role),
  },
  {
    code: 'engineportal',
    busy: false,
    icon: <ServiceEnginePortalVector />,
    label: 'Инжиниринговый портал',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'certcheck',
    busy: false,
    icon: <ServiceCertCheckVector />,
    label: 'Проверка сертификата',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'internetshop',
    busy: false,
    icon: <ServiceInternetShopVector />,
    label: 'Интернет-магазин',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'restapi',
    busy: false,
    icon: <ServiceRestAPIVector />,
    label: 'Токен для REST API',
    availableFor: (account: types.auth.AccountWithTokens) => account.isRestApi,
  },
  {
    code: 'techconsulting',
    busy: false,
    icon: <ServiceTechconsultingVector />,
    label: 'Techconsulting',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'lk',
    busy: false,
    icon: <ProfileVector />,
    label: 'Личный кабинет клиента<br /> ПАО «Северсталь»',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'shadeselection',
    busy: false,
    icon: <ServiceShadeSelectionVector />,
    label: 'Подбор оттенка',
    availableFor: (account: types.auth.AccountWithTokens) => {
      const shadeSelectionConfig = account.configs.find((item) => item.code === 'IND_SHADE_SELECTION') ?? null;

      return (shadeSelectionConfig !== null) && shadeSelectionConfig.value === '1'
    }
  },
];

export const ServicesScreen = () => {
  const theme = useTheme();
  const alert = useAlert();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  let roleItems = allItems.filter((item) => item.availableFor(account))

  const onIconListItemTap = (item: IconListElement) => {
    switch (item.code)
    {
      case 'pretenzstatus':
        if (account.role === AccountRole.Stpk)
        {
          Urls.QMMsgSelectCompany.build().navigate();
        }
        else
        {
          Urls.QMMsgListEsk.build('services', 'null').navigate();
        }
        break;
      case 'problem8d':
        alert.info('Раздел в разработке');
        break;
      case 'engineportal':
        window.open('https://vmeste.severstal.com/engineering-portal/', '_blank')?.focus();
        break;
      case 'certcheck':
        window.open('https://chermk.severstal.com/clients/proverka-podlinnosti-sertifikata/', '_blank')?.focus();
        break;
      case 'internetshop':
        window.open('https://market.severstal.com/ru/ru/', '_blank')?.focus();
        break;
      case 'restapi':
        Urls.MainToken.build().navigate();
        break;
      case 'techconsulting':
        window.open('https://severstal.com/rus/clients/services/techconsulting/?utm_source=techconsulting&utm_medium=landing_page&utm_campaign=stories_checksteel', '_blank')?.focus();
        break;
      case 'lk':
        window.open('https://info.severstal.com/?utm_source=checksteel&utm_medium=services&utm_campaign=lk', '_blank')?.focus();
        break;
      case 'shadeselection':
        Urls.MainShadeSelection.build().navigate();
        break;
    }
  }

  return (
    <Screen layout={layout}>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Сервисы'
      />
      <ScreenBody layout = { layout }>
        <IconList
          variant = { layout.iconlistVariant }
          items = { roleItems }
          onItemTap = { onIconListItemTap }
        />
      </ScreenBody>
      <Toolbar variant = { layout.toolbarVariant } activeItem = 'services' />
    </Screen>
  );
};
