
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceShadeSelectionVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M7 21.9326C4.79086 21.9326 3 20.1418 3 17.9326V3.93262H5H9H11V5.93262V17.9326C11 20.1418 9.20914 21.9326 7 21.9326ZM7 21.9326H16M18 21.9326H21V19.9326V15.9326V13.9326H19H16.6569M11 8.27574L12.6569 6.61891L14.0711 5.2047L15.4853 6.61891L18.3137 9.44734L19.7279 10.8615L18.3137 12.2758L9.82843 20.761M7 18.9326V16.9326' />
    </svg>
  );
};
