import * as React                 from 'react';

import { isDesktop              } from 'react-device-detect';

import { LinkBlankVector        } from 'src/components/vector/linkblank';
import { EyeShowVector          } from 'src/components/vector/eye-show';
import { SharingVector          } from 'src/components/vector/sharing';

import { Container,
         ItemList,
         Label,
         Icon,
         InfoItemsListVariants,
         infoitemslist_variants } from 'src/components/features/main/info/info-items-list/ui';

import * as types                 from 'src/services/api/types';



type Props = {
  variant: InfoItemsListVariants;
  items: types.info.InfoElement[];
  onAction: (action: 'navigate' | 'share', item: types.info.InfoElement) => void;
}
export const InfoItemsList: React.FC<Props> = ({
  variant,
  items,
  onAction,
}) => {
  const lookAndFeel = infoitemslist_variants.get(variant)!;

  const onContainerTap = (e: React.MouseEvent, item: types.info.InfoElement) => {
    onAction('navigate', item);
  }

  const onShareTap = (e: React.MouseEvent, item: types.info.InfoElement) => {
    e.preventDefault();
    e.stopPropagation();
    onAction('share', item);
  }

  return (
    <ItemList lookAndFeel = { lookAndFeel }>
      {items.map((item) => (
        <Container
          key = { item.id }
          lookAndFeel = { lookAndFeel }
          onClick = { (event) => onContainerTap.apply(null, [event, item]) }
        >
          <Label lookAndFeel = { lookAndFeel } isLink = { item.type === 'ref' }>
            { item.title }
          </Label>
          {item.type === 'ref' && 
            <Icon lookAndFeel = { lookAndFeel }>
              <LinkBlankVector />
            </Icon>
          }
          {item.type === 'file' && 
            <Icon lookAndFeel = { lookAndFeel }>
              <EyeShowVector />
            </Icon>
          }
          {item.type === 'file' && !isDesktop && 
            <Icon lookAndFeel = { lookAndFeel } onClick = { (event) => onShareTap.apply(null, [event, item]) }>
              <SharingVector />
            </Icon>
          }
        </Container>
      ))}
    </ItemList>
  );
};
