import styled                      from 'styled-components';

import { IconButtonVariants      } from 'src/components/common/icon-button';
import { TypographyVariants      } from 'src/components/common/typography';
import { LabeledVariants         } from 'src/components/common/labeled';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { TabSwitchVariants       } from 'src/components/common/tab-switch';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';
import { SwitchVariants          } from 'src/components/common/switch';

import { ReferSelectorVariants   } from 'src/components/features/common/refer-selector';

import { AttachmentPhotoVariants } from 'src/components/features/inspection/view/attachment-photo';
import { AttachmentPDFVariants   } from 'src/components/features/inspection/view/attachment-pdf';
import { StaticValueVariants     } from 'src/components/features/inspection/view/static-value';

import { themeColors,
         cssFlexRow,
         cssFlexColumn           } from 'src/providers/theme/shared';



export type PieceFragmentLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  formPaddings: number[];
  formGap: number;
  formRowGap: number;
  circleQuestionIconColor: string;

  photoListGap: number;

  hrColor: string;

  loadCertButtonBariant: IconButtonVariants;
  modeSwitcherVariant: TabSwitchVariants;
  labeledVariant: LabeledVariants;
  textboxVariant: InputTextboxVariants;
  modeSwitchConfirmDialogVariant: TwoActionDialogVariants;
  deletePhotoDialogVariant: TwoActionDialogVariants;
  submitErrorDialogVariant: TwoActionDialogVariants;
  scannerButtonVariant: IconButtonVariants;
  helpButtonVariant: IconButtonVariants;
  attachmentPhotoVariant: AttachmentPhotoVariants;
  attachmenPDFVariant: AttachmentPDFVariants;
  topoSwitchLabelTypo: TypographyVariants;
  topoSwitchVariant: SwitchVariants;
  topoNoteTypo: TypographyVariants;
  referSelectorVariant: ReferSelectorVariants;
  staticValueVariant: StaticValueVariants;
}

const piecefragment_light: PieceFragmentLookAndFeel = {
  containerBackgroundColor: themeColors.BackgroundFillLight,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  formPaddings: [20, 0],
  formGap: 20,
  formRowGap: 12,
  circleQuestionIconColor: themeColors.SecondaryGray4,

  photoListGap: 10,

  hrColor: themeColors.SecondaryGray3,

  loadCertButtonBariant: 's48bluered',
  modeSwitcherVariant: 'h32',
  labeledVariant: 'light',
  textboxVariant: 'h48light',
  modeSwitchConfirmDialogVariant: 'light',
  deletePhotoDialogVariant: 'light',
  submitErrorDialogVariant: 'light',
  scannerButtonVariant: 's48redred',
  helpButtonVariant: 's44gray4red',
  attachmentPhotoVariant: 'light',
  attachmenPDFVariant: 'light',
  topoSwitchLabelTypo: 'blackw600s18lh24',
  topoSwitchVariant: 'w40h24',
  topoNoteTypo: 'steelw400s14lh17',
  referSelectorVariant: 'light',
  staticValueVariant: 'light',
}

export type PieceFragmentVariants = 'light';

export const piecefragment_variants: Map<PieceFragmentVariants, PieceFragmentLookAndFeel> = 
  new Map<PieceFragmentVariants, PieceFragmentLookAndFeel>([
    ['light', piecefragment_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceFragmentLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
`

export const FormContainer = styled.div<{
  lookAndFeel: PieceFragmentLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.formGap}px;
  padding: ${(args) => args.lookAndFeel.formPaddings[0]}px ${(args) => args.lookAndFeel.formPaddings[1]}px;

  overflow-y: auto;
`

export const FormRowElement = styled.div<{
  lookAndFeel: PieceFragmentLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.formRowGap}px;

  & .circle-question {
    color: ${(args) => args.lookAndFeel.circleQuestionIconColor};
  }

  & > *:first-child {
    flex-grow: 2;
  }
`

export const PhotoList = styled.div<{
  lookAndFeel: PieceFragmentLookAndFeel,
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${(args) => args.lookAndFeel.photoListGap}px;
  grid-row-gap: ${(args) => args.lookAndFeel.photoListGap}px;
`

export const SubmitContainer = styled.div<{
  lookAndFeel: PieceFragmentLookAndFeel,
}>`
  flex-shrink: 0;
`
