import React from 'react';



type Props = {
  className?: string;
}
export const MailVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '16' viewBox = '0 0 16 16' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M15 13H1V11V3H15V13Z' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M12 6L8 9L4 6' />    
    </svg>
  );
};
