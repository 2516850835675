import { createSlice,
         createAsyncThunk, 
         PayloadAction,
         createSelector              } from '@reduxjs/toolkit';

import { isDesktop                   } from 'react-device-detect';

import { TagItem                     } from 'src/components/common/tags'

import Api,
       { ApiError                    } from 'src/services/api';
import * as types                      from 'src/services/api/types';
import Db                              from 'src/services/db';
import Constants                       from 'src/services/constants';
import Util                            from 'src/services/util';

import { RootState,
         storeApi                    } from 'src/store';

import { AsyncOpStatus,
         AsyncOpStatusWithTerminate,
         Nullable                    } from 'src/common';



const SLICE_NAME = 'main:inspection-list';

export interface MainInspectionListState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  referLoadingStatus: AsyncOpStatusWithTerminate;
  advLoadingStatus: AsyncOpStatusWithTerminate;

  placeOfFixations: types.refers.PlaceOfFixationReferElement[];
  filials: types.refers.FilialElement[];
  adv: types.adv.AdvElement[];
  advStat: types.adv.AdvStatistics[];
  advIsPriority: boolean;

  currentPage: number;
  isEndPage: boolean;
  pageLoading: boolean;

  inspections: types.inspection.Inspection[];

  bottomSheetContext: Nullable<types.inspection.Inspection>;

  filterOpened: boolean;
  filterIsReport: boolean;

  filterIsSendEmail: boolean;
  filterDateBeg: Nullable<Date>;
  filterDateEnd: Nullable<Date>;
  filterStatuses: TagItem[];
  filterEntryAccounting: number;
  filterUserFio: string;
  filterInspectionNum: string;
  filterDefectName: string;
  filterQcNum: string;
  filterDecision: string;
  filterPiece: string;
  filterHeat: string;
  filterProduct: string;
  filterPlaceOfFixationId: number;
  filterFilialId: number;
  filterDynamic: Record<string, string>;
  filterOrder: string;

  wcFilterIsSendEmail: boolean;
  wcFilterDateBeg: Nullable<Date>;
  wcFilterDateEnd: Nullable<Date>;
  wcFilterStatuses: TagItem[];
  wcFilterEntryAccounting: number;
  wcFilterUserFio: string;
  wcFilterInspectionNum: string;
  wcFilterDefectName: string;
  wcFilterQcNum: string;
  wcFilterDecision: string;
  wcFilterPiece: string;
  wcFilterHeat: string;
  wcFilterProduct: string;
  wcFilterPlaceOfFixationId: number;
  wcFilterFilialId: number;
  wcFilterDynamic: Record<string, string>;
  wcFilterOrder: string;

  appRatingOpened: boolean;
  helpOpened: boolean;
  remarkStatusOpened: boolean;

  reportRequestSingleBusy: boolean;
  reportRequestListBusy: boolean;
  reportRequestHistoryBusy: boolean;

  revocationGuid: string;
  revocationOpened: boolean;
  revocationNote: string;
}

const initialState: MainInspectionListState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  referLoadingStatus: AsyncOpStatusWithTerminate.TERMINATE,
  advLoadingStatus: AsyncOpStatusWithTerminate.TERMINATE,

  placeOfFixations: [],
  filials: [],
  adv: [],
  advStat: [],
  advIsPriority: false,

  currentPage: 1,
  isEndPage: false,
  pageLoading: false,

  inspections: [],

  bottomSheetContext: null,

  filterOpened: false,
  filterIsReport: false,

  filterIsSendEmail: false,
  filterDateBeg: null,
  filterDateEnd: null,
  filterStatuses: [{ id: '-2', label: 'Все' }],
  filterEntryAccounting: 0,
  filterUserFio: '',
  filterInspectionNum: '',
  filterDefectName: '',
  filterQcNum: '',
  filterDecision: '',
  filterPiece: '',
  filterHeat: '',
  filterProduct: '',
  filterPlaceOfFixationId: -1,
  filterFilialId: -1,
  filterDynamic: { },
  filterOrder: '',

  wcFilterIsSendEmail: false,
  wcFilterDateBeg: null,
  wcFilterDateEnd: null,
  wcFilterStatuses: [{ id: '-2', label: 'Все' }],
  wcFilterEntryAccounting: 0,
  wcFilterUserFio: '',
  wcFilterInspectionNum: '',
  wcFilterDefectName: '',
  wcFilterQcNum: '',
  wcFilterDecision: '',
  wcFilterPiece: '',
  wcFilterHeat: '',
  wcFilterProduct: '',
  wcFilterPlaceOfFixationId: -1,
  wcFilterFilialId: -1,
  wcFilterDynamic: { },
  wcFilterOrder: '',

  appRatingOpened: false,
  helpOpened: false,
  remarkStatusOpened: false,

  reportRequestSingleBusy: false,
  reportRequestListBusy: false,
  reportRequestHistoryBusy: false,

  revocationGuid: '',
  revocationOpened: false,
  revocationNote: '',
}

// #region getInspections
const getInspections = async (state: RootState, isReport: boolean, isSend: boolean, giveLinkForDownload: boolean,  skip: number, count: number): Promise<types.inspection.Inspection[] | types.inspection.GetInspectionListAsReportLinkOut> => {
  const dateBeg = selectWCFilterDateBeg(state);
  const dateEnd = selectWCFilterDateEnd(state);
  const statuses = selectWCFilterStatuses(state);
  const entryAccounting = selectWCFilterEntryAccounting(state);
  const userFio = selectWCFilterUserFio(state);
  const inspectionNum = selectWCFilterInspectionNum(state);
  const defectName = selectWCFilterDefectName(state);
  const qcNum = selectWCFilterQcNum(state);
  const decision = selectWCFilterDecision(state);
  const piece = selectWCFilterPiece(state);
  const heat = selectWCFilterHeat(state);
  const product = selectWCFilterProduct(state);
  const placeOfFixationId = selectWCFilterPlaceOfFixationId(state);
  const filialId = selectWCFilterFilialId(state);
  const dynamic = selectWCFilterDynamic(state);
  const order = selectWCFilterOrder(state);

  let filterNames: string[] = [];
  let filterValues: string[] = [];

  if (dateBeg !== null)
  {
    filterNames.push('dateBeg');
    filterValues.push(`${Util.plusMoscowTimezone(dateBeg.valueOf())}`);
  }

  if (dateEnd !== null)
  {
    filterNames.push('dateEnd');
    filterValues.push(`${Util.plusMoscowTimezone(dateEnd.valueOf() + (24 * 60 * 60 - 1) * 1000)}`);
  }

  if (statuses.length > 0 && statuses[0].id !== '-2')
  {
    filterNames.push('statuses');
    filterValues.push(`(${statuses.map((status) => status.id).join(';')})`);
  }

  if (entryAccounting > 0)
  {
    filterNames.push('entryAccounting');
    filterValues.push(`${entryAccounting}`);
  }

  if (userFio.trim().length > 0)
  {
    filterNames.push('userFio');
    filterValues.push(userFio.trim());
  }

  if (inspectionNum.trim().length > 0)
  {
    filterNames.push('inspectionNum');
    filterValues.push(inspectionNum.trim());
  }

  if (defectName.trim().length > 0)
  {
    filterNames.push('defectName');
    filterValues.push(defectName.trim());
  }

  if (qcNum.trim().length > 0)
  {
    filterNames.push('qcNum');
    filterValues.push(qcNum.trim());
  }

  if (decision.trim().length > 0)
  {
    filterNames.push('decision');
    filterValues.push(decision.trim());
  }

  if (piece.trim().length > 0)
  {
    filterNames.push('piece');
    filterValues.push(piece.trim());
  }

  if (heat.trim().length > 0)
  {
    filterNames.push('heat');
    filterValues.push(heat.trim());
  }

  if (product.trim().length > 0)
  {
    filterNames.push('product');
    filterValues.push(product.trim());
  }

  if (placeOfFixationId > -1)
  {
    filterNames.push('placeOfFixationId');
    filterValues.push(`${placeOfFixationId}`);
  }

  if (isDesktop && filialId > -1)
  {
    filterNames.push('filialsId');
    filterValues.push(`${filialId}`);
  }

  Object.keys(dynamic).forEach((key) => {
    if (dynamic[key].trim().length > 0)
    {
      filterNames.push(key);
      filterValues.push(dynamic[key].trim());
    }
  });

  if (order.trim().length > 0)
  {
    filterNames.push('order');
    filterValues.push(order.trim());
  }

  const args: types.inspection.GetInspectionListIn = { isReport, isSend, giveLinkForDownload, skip, count };

  if (filterNames.length > 0)
  {
    args.filterNames = filterNames.join(',');
    args.filterValues = filterValues.join(',');
  }

  let remoteList: types.inspection.Inspection[] = [];
  let localList: types.inspection.Inspection[] = [];

  try
  {
    if (giveLinkForDownload)
    {
      return (await Api.getInspectionList(args)) as types.inspection.GetInspectionListAsReportLinkOut;
    }
    else
    {
      remoteList = (await Api.getInspectionList(args)) as types.inspection.GetInspectionListOut;
    }
    
  }
  catch (error)
  {
    if (giveLinkForDownload)
    {
      throw error;
    }
  }

  remoteList.forEach((item) => { item.__isTrans = true; });

  if (skip === 0)
  {
    try
    {
      localList = await Db.getInspections();
    }
    catch (error) { }

    localList.forEach((inspect) => {
      if (dateBeg !== null && inspect.timestampInspection < dateBeg.valueOf())
      {
        return;
      }

      if (dateEnd !== null && inspect.timestampInspection > dateEnd.valueOf())
      {
        return;
      }

      if (!statuses.some((status) => status.id === '-2' || status.id === '-1' || status.id === `${inspect.status.id}`))
      {
        return;
      }

      if (entryAccounting > 0 && ((inspect.isEntryAccounting && entryAccounting === 2) || (!inspect.isEntryAccounting && entryAccounting === 1)))
      {
        return;
      }

      if (inspectionNum.trim().length > 0 && inspect.inspectionNum.includes(inspectionNum.trim()))
      {
        return;
      }

      if (userFio.trim().length > 0)
      {
        const userFioParts = userFio.trim().split(' ');
        const inspectUserFioParts = inspect.userFio.split(' ');

        if (!userFioParts.every((part, index) => index <= inspectUserFioParts.length - 1 && inspectUserFioParts[index].toLowerCase().includes(part.toLowerCase())))
        {
          return;
        }
      }

      if (defectName.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.defectTitle || '').includes(defectName.trim())))
      {
        return;
      }

      if (product.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.product || '').includes(product.trim())))
      {
        return;
      }

      if (placeOfFixationId > -1 && !inspect.inspectionDefects.some((defect) => defect.placeOfFixationId === placeOfFixationId))
      {
        return;
      }

      if (qcNum.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.pieces || []).some((pieceOne) => (pieceOne.qcNum || '').includes(qcNum.trim()))))
      {
        return
      }

      if (heat.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.pieces || []).some((pieceOne) => (pieceOne.heat || '').includes(heat.trim()))))
      {
        return
      }

      if (piece.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.pieces || []).some((pieceOne) => (pieceOne.piece || '').includes(piece.trim()))))
      {
        return
      }

      if (!Object.keys(dynamic).every((key) => inspect.inspectionDefects.some((defect) => (defect.pieces || []).some((pieceOne) => pieceOne.fields.some((fieldOne) => fieldOne.code === key && fieldOne.value.includes(dynamic[key].trim()))))))
      {
        return
      }

      if (decision.trim().length > 0 && !inspect.inspectionDefects.some((defect) => (defect.complaint?.decision || '').includes(decision.trim())))
      {
        return
      }

      //Если в inspects уже есть осмотр, который у нас не отправлен и пришел вновь с сервера, то его надо удалить. Приоритет у непереданного!
      remoteList = remoteList.filter((item) => item.guid !== inspect.guid);
      remoteList.push(inspect);
    });
  }
  
  remoteList.sort((a, b) => {
    /** Сначала идут локальные осмотры, изменения по которым не были отправлены на сервер */
    if (!a.__isTrans && b.__isTrans)
    {
      return -1;
    }
    if (a.__isTrans && !b.__isTrans)
    {
      return 1;
    }
    if (!a.__isTrans && !b.__isTrans)
    {
      return b.timestampInspection - a.timestampInspection;
    }

    /** Далее идут осмотры с ошибкой */
    if (a.status.id === 8 && b.status.id !== 8)
    {
      return -1;
    }
    if (a.status.id !== 8 && b.status.id === 8)
    {
      return 1;
    }
    if (a.status.id === 8 && b.status.id === 8)
    {
      return b.timestampInspection - a.timestampInspection;
    }

    /** И наконец все остальные */

    return b.timestampInspection - a.timestampInspection;
  })

  return remoteList;
}
// #endregion

// #region prepareInfoAsync
type PrepareInfoArgs = void;
type PrepareInfoResolve = {
  isRefersUpdate: boolean;
  isAdvUpdate: boolean;
  placeOfFixations: types.refers.PlaceOfFixationReferElement[];
  filials: types.refers.FilialElement[];
  adv: types.adv.AdvElement[];
  advStat: types.adv.AdvStatistics[];
  inspections: types.inspection.Inspection[];
  isEndPage: boolean;
};
export type PrepareInfoReject = void;
export const prepareInfoAsync = createAsyncThunk<
  PrepareInfoResolve,
  PrepareInfoArgs,
  {
    state: RootState,
    rejectValue: PrepareInfoReject,
  }
>(
  `${SLICE_NAME}/prepareInfoAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.BUSY));

    let isRefersUpdate = false;

    /** Проверка необходимости обновления справочников */
    thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Проверка необходимости обновления справочников'));
    const dbRefersHash = await Db.getOptString('REFER_HASH');
    try
    {
      const apiRefersHash = (await Api.getRefersHash()).hash;
      if (apiRefersHash !== null && (dbRefersHash === null || dbRefersHash !== apiRefersHash))
      {
        isRefersUpdate = true;
      }
    }
    catch (error) { console.log(error) }

    let isAdvUpdate = false;

    /** Проверка необходимости обновления рекламы */
    thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Проверка необходимости обновления рекламы'));
    const dbAdvHash = await Db.getOptString('ADV_HASH');
    try
    {
      const apiAdvHash = (await Api.getAdvHash()).hash;
      if (apiAdvHash !== null && (dbAdvHash === null || dbAdvHash !== apiAdvHash))
      {
        isAdvUpdate = true;
      }
    }
    catch (error) { console.log(error) }

    /** Загружаем список осмотров  */
    thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Загрузка списка осмотров'));
    let inspections: types.inspection.Inspection[] = [];
    const currentPage = thunkAPI.getState().mainInspectionList.currentPage;
    const pageSize = Constants.INSPECTIONS_PAGE_SIZE;
    try
    {
      inspections = (await getInspections(thunkAPI.getState(), false, false, false, 0, currentPage * pageSize)) as types.inspection.Inspection[];
    }
    catch (error) { console.log(error) }

    const placeOfFixations = isRefersUpdate ? [] : await Db.getPlaceOfFixations();
    const filials = isRefersUpdate ? [] : await Db.getFilials();
    const adv = isAdvUpdate ? [] : await Db.getAdv();
    const advStat = isAdvUpdate ? [] : await Db.getAdvStat();

    adv.sort((a, b) => a.sortOrder === b.sortOrder ? Math.abs(a.id) - Math.abs(b.id) : a.sortOrder - b.sortOrder);
    advStat.sort((a, b) => a.sortOrder - b.sortOrder);

    return {
      placeOfFixations,
      filials,
      isRefersUpdate,
      isAdvUpdate,
      adv,
      advStat,
      inspections,
      isEndPage: inspections.length < currentPage * pageSize,
    }
  }
);
// #endregion

// #region downloadRefersAsync
type DownloadRefersArgs = void;
type DownloadRefersResolve = {
  placeOfFixations: types.refers.PlaceOfFixationReferElement[];
  filials: types.refers.FilialElement[];
};
export type DownloadRefersReject = void;
export const downloadRefersAsync = createAsyncThunk<
  DownloadRefersResolve,
  DownloadRefersArgs,
  {
    state: RootState,
    rejectValue: DownloadRefersReject,
  }
>(
  `${SLICE_NAME}/downloadRefersAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(storeApi.main.inspectionList.actions.refersLoadingStatusChanged(AsyncOpStatusWithTerminate.BUSY));

    try
    {
      const apiRefersHash = (await Api.getRefersHash()).hash;
      const refers = await Api.getRefers();
      await Db.updateRefers(refers, apiRefersHash!);
      thunkAPI.dispatch(storeApi.main.inspectionList.actions.refersLoadingStatusChanged(AsyncOpStatusWithTerminate.SUCCESS));
    }
    catch (error)
    {
      thunkAPI.dispatch(storeApi.main.inspectionList.actions.refersLoadingStatusChanged(AsyncOpStatusWithTerminate.ERROR));
    }

    const placeOfFixations = await Db.getPlaceOfFixations();
    const filials = await Db.getFilials();

    return {
      placeOfFixations,
      filials,
    }
  }
);
// #endregion

// #region downloadAdvAsync
type DownloadAdvArgs = void;
type DownloadAdvResolve = {
  adv: types.adv.AdvElement[];
  advStat: types.adv.AdvStatistics[];
};
export type DownloadAdvReject = void;
export const downloadAdvAsync = createAsyncThunk<
  DownloadAdvResolve,
  DownloadAdvArgs,
  {
    state: RootState,
    rejectValue: DownloadAdvReject,
  }
>(
  `${SLICE_NAME}/downloadAdvAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(storeApi.main.inspectionList.actions.advLoadingStatusChanged(AsyncOpStatusWithTerminate.BUSY));

    try
    {
      const adv = await Api.getAdv();

      for (const ad of adv)
      {
        ad.__b64Preview = (await (await Api.getAdvFileById({ id: ad.idPreview }))).dataUrl;
        ad.__b64Full = (await (await Api.getAdvFileById({ id: ad.idFile }))).dataUrl;
      }
      const apiAdvHash = (await Api.getAdvHash()).hash;
      const advStat = await Api.getAdvStatistics();
      await Db.updateAdvs(adv, advStat, apiAdvHash!);
      thunkAPI.dispatch(storeApi.main.inspectionList.actions.advLoadingStatusChanged(AsyncOpStatusWithTerminate.SUCCESS));
    }
    catch (error)
    {
      thunkAPI.dispatch(storeApi.main.inspectionList.actions.advLoadingStatusChanged(AsyncOpStatusWithTerminate.ERROR));
    }

    const adv = await Db.getAdv();
    const advStat = await Db.getAdvStat();

    adv.sort((a, b) => a.sortOrder === b.sortOrder ? Math.abs(a.id) - Math.abs(b.id) : a.sortOrder - b.sortOrder);
    advStat.sort((a, b) => a.sortOrder - b.sortOrder);

    return {
      adv,
      advStat
    }
  }
);
// #endregion

// #region filterInspectionsAsync
type FilterInspectionsArgs = {
  isStart: boolean;
};
type FilterInspectionsResolve = {
  list: types.inspection.Inspection[];
  isStart: boolean;
  isEndPage: boolean;
}
export type FilterInspectionsReject = ApiError;
export const filterInspectionsAsync = createAsyncThunk<
  FilterInspectionsResolve,
  FilterInspectionsArgs,
  {
    state: RootState,
    rejectValue: FilterInspectionsReject,
  }
>(
  `${SLICE_NAME}/filterInspectionsAsync`,
  async (args, thunkAPI) => {
    if (args.isStart)
    {
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Загрузка списка осмотров'));
    }
    else
    {
      thunkAPI.dispatch(mainInspectionListActions.pageLoading(true));
    }

    const state = thunkAPI.getState();

    try
    {
      const pageSize = Constants.INSPECTIONS_PAGE_SIZE;
      const skip = args.isStart ? 0 : state.mainInspectionList.currentPage * pageSize;
      const list = (await getInspections(state, false, false, false, skip, pageSize)) as types.inspection.Inspection[];

      if (!args.isStart)
      {
        thunkAPI.dispatch(mainInspectionListActions.pageLoading(false));
      }

      return {
        list,
        isStart: args.isStart,
        isEndPage: list.length < pageSize,
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region reportInspectionsAsync
type ReportInspectionsArgs = void;
type ReportInspectionsResolve = void;
export type ReportInspectionsReject = ApiError;
export const reportInspectionsAsync = createAsyncThunk<
  ReportInspectionsResolve,
  ReportInspectionsArgs,
  {
    state: RootState,
    rejectValue: ReportInspectionsReject,
  }
>(
  `${SLICE_NAME}/reportInspectionsAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Получение списка осмотров и формирование отчета'));

    const state = thunkAPI.getState();

    try
    {
      await getInspections(state, true, true, false, 0, 1000000);
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.IDLE));
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region reportInspectionsAsLinkAsync
type ReportInspectionsAsLinkArgs = void;
type ReportInspectionsAsLinkResolve = types.inspection.GetInspectionListAsReportLinkOut;
export type ReportInspectionsAsLinkReject = ApiError;
export const reportInspectionsAsLinkAsync = createAsyncThunk<
  ReportInspectionsAsLinkResolve,
  ReportInspectionsAsLinkArgs,
  {
    state: RootState,
    rejectValue: ReportInspectionsAsLinkReject,
  }
>(
  `${SLICE_NAME}/reportInspectionsAsLinkAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Получение списка осмотров и формирование отчета'));

    const state = thunkAPI.getState();

    try
    {
      const result = (await getInspections(state, true, false, true, 0, 1000000)) as types.inspection.GetInspectionListAsReportLinkOut;
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.IDLE));

      return result;
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region revocationAsync
type RevocationArgs = void;
type RevocationResolve = void;
export type RevocationReject = ApiError;
export const revocationAsync = createAsyncThunk<
  RevocationResolve,
  RevocationArgs,
  {
    state: RootState,
    rejectValue: RevocationReject
  }
>(
  `${SLICE_NAME}/revocationAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainInspectionListActions.opStatusLabelChanged('Отзыв осмотра'));

      const state = thunkAPI.getState();
      const inspectionGuid = mainInspectionListSelectors.selectRevocationGuid(state);
      const note = mainInspectionListSelectors.selectRevocationNote(state);

      await Api.postInspectionRevocation({
        guid: inspectionGuid,
        revocationNote: note,
      });
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.IDLE));
    }
    catch (error)
    {
      thunkAPI.dispatch(mainInspectionListActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion


type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;
type OpStatusWithTerminateChangedAction = PayloadAction<AsyncOpStatusWithTerminate>;
type AdsChangedAction = PayloadAction<types.adv.AdvElement[]>;
type AdsStatChangedAction = PayloadAction<types.adv.AdvStatistics[]>;
type InspectionsChangedAction = PayloadAction<types.inspection.Inspection[]>;
type InspectionChangedAction = PayloadAction<types.inspection.Inspection>;
type BooleanChangedAction = PayloadAction<boolean>;
type DateChangedAction = PayloadAction<Nullable<Date>>;
type StringChangedAction = PayloadAction<string>;
type NumberChangedAction = PayloadAction<number>;
type RecordStringStringChangedAction = PayloadAction<Record<string, string>>;
type TagItemChangedAction = PayloadAction<TagItem[]>;

export const mainInspectionListSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
    refersLoadingStatusChanged: (state, action: OpStatusWithTerminateChangedAction) => {
      state.referLoadingStatus = action.payload;
    },
    advLoadingStatusChanged: (state, action: OpStatusWithTerminateChangedAction) => {
      state.advLoadingStatus = action.payload;
    },
    adsChanged: (state, action: AdsChangedAction) => {
      state.adv = action.payload;
    },
    adsStatChanged: (state, action: AdsStatChangedAction) => {
      state.advStat = action.payload;
    },
    adsIsPriorityChanged: (state, action: BooleanChangedAction) => {
      state.advIsPriority = action.payload;
    },
    inspectionsChanged: (state, action: InspectionsChangedAction) => {
      state.inspections = action.payload;
    },
    bottomSheetContextChanged: (state, action: InspectionChangedAction) => {
      state.bottomSheetContext = action.payload;
    },

    appRatingOpenedChanged: (state, action: BooleanChangedAction) => {
      state.appRatingOpened = action.payload;
    },
    helpOpenedChanged: (state, action: BooleanChangedAction) => {
      state.helpOpened = action.payload;
    },
    remarkStatusOpenedChanged: (state, action: BooleanChangedAction) => {
      state.remarkStatusOpened = action.payload;
    },
    paginationReset: (state) => {
      state.currentPage = 1;
      state.isEndPage = false;
    },
    pageLoading: (state, action: BooleanChangedAction) => {
      state.pageLoading = action.payload;
    },

    filterIsSendEmailChanged: (state, action: BooleanChangedAction) => {
      state.filterIsSendEmail = action.payload;
    },
    filterDateBegChanged: (state, action: DateChangedAction) => {
      state.filterDateBeg = action.payload;
    },
    filterDateEndChanged: (state, action: DateChangedAction) => {
      state.filterDateEnd = action.payload;
    },
    filterStatusesChanged: (state, action: TagItemChangedAction) => {
      state.filterStatuses = action.payload;
    },
    filterEntryAccountingChanged: (state, action: NumberChangedAction) => {
      state.filterEntryAccounting = action.payload;
    },
    filterUserFioChanged: (state, action: StringChangedAction) => {
      state.filterUserFio = action.payload;
    },
    filterInspectionNumChanged: (state, action: StringChangedAction) => {
      state.filterInspectionNum = action.payload;
    },
    filterDefectNameChanged: (state, action: StringChangedAction) => {
      state.filterDefectName = action.payload;
    },
    filterQcNumChanged: (state, action: StringChangedAction) => {
      state.filterQcNum = action.payload;
    },
    filterDecisionChanged: (state, action: StringChangedAction) => {
      state.filterDecision = action.payload;
    },
    filterPieceChanged: (state, action: StringChangedAction) => {
      state.filterPiece = action.payload;
    },
    filterHeatChanged: (state, action: StringChangedAction) => {
      state.filterHeat = action.payload;
    },
    filterProductChanged: (state, action: StringChangedAction) => {
      state.filterProduct = action.payload;
    },
    filterPlaceOfFixationIdChanged: (state, action: NumberChangedAction) => {
      state.filterPlaceOfFixationId = action.payload;
    },
    filterFilialIdChanged: (state, action: NumberChangedAction) => {
      state.filterFilialId = action.payload;
    },
    filterDynamicChanged: (state, action: RecordStringStringChangedAction) => {
      state.filterDynamic = { ...state.filterDynamic, ...action.payload };
    },
    filterOrderChanged: (state, action: StringChangedAction) => {
      state.filterOrder = action.payload;
    },

    wcFilterIsSendEmailChanged: (state, action: BooleanChangedAction) => {
      state.wcFilterIsSendEmail = action.payload;
    },
    wcFilterDateBegChanged: (state, action: DateChangedAction) => {
      state.wcFilterDateBeg = action.payload;
    },
    wcFilterDateEndChanged: (state, action: DateChangedAction) => {
      state.wcFilterDateEnd = action.payload;
    },
    wcFilterStatusesChanged: (state, action: TagItemChangedAction) => {
      state.wcFilterStatuses = action.payload;
    },
    wcFilterEntryAccountingChanged: (state, action: NumberChangedAction) => {
      state.wcFilterEntryAccounting = action.payload;
    },
    wcFilterUserFioChanged: (state, action: StringChangedAction) => {
      state.wcFilterUserFio = action.payload;
    },
    wcFilterInspectionNumChanged: (state, action: StringChangedAction) => {
      state.wcFilterInspectionNum = action.payload;
    },
    wcFilterDefectNameChanged: (state, action: StringChangedAction) => {
      state.wcFilterDefectName = action.payload;
    },
    wcFilterQcNumChanged: (state, action: StringChangedAction) => {
      state.wcFilterQcNum = action.payload;
    },
    wcFilterDecisionChanged: (state, action: StringChangedAction) => {
      state.wcFilterDecision = action.payload;
    },
    wcFilterPieceChanged: (state, action: StringChangedAction) => {
      state.wcFilterPiece = action.payload;
    },
    wcFilterHeatChanged: (state, action: StringChangedAction) => {
      state.wcFilterHeat = action.payload;
    },
    wcFilterProductChanged: (state, action: StringChangedAction) => {
      state.wcFilterProduct = action.payload;
    },
    wcFilterPlaceOfFixationIdChanged: (state, action: NumberChangedAction) => {
      state.wcFilterPlaceOfFixationId = action.payload;
    },
    wcFilterFilialIdChanged: (state, action: NumberChangedAction) => {
      state.wcFilterFilialId = action.payload;
    },
    wcFilterDynamicChanged: (state, action: RecordStringStringChangedAction) => {
      state.wcFilterDynamic = { ...state.filterDynamic, ...action.payload };
    },
    wcFilterOrderChanged: (state, action: StringChangedAction) => {
      state.wcFilterOrder = action.payload;
    },

    filterOpened: (state, action: BooleanChangedAction) => {
      state.wcFilterIsSendEmail = false;
      state.wcFilterDateBeg = state.filterDateBeg;
      state.wcFilterDateEnd = state.filterDateEnd;
      state.wcFilterStatuses = state.filterStatuses.map((status) => ({ ...status }));
      state.wcFilterEntryAccounting = state.filterEntryAccounting;
      state.wcFilterUserFio = state.filterUserFio;
      state.wcFilterInspectionNum = state.filterInspectionNum;
      state.wcFilterDefectName = state.filterDefectName;
      state.wcFilterQcNum = state.filterQcNum;
      state.wcFilterDecision = state.filterDecision;
      state.wcFilterPiece = state.filterPiece;
      state.wcFilterHeat = state.filterHeat;
      state.wcFilterProduct = state.filterProduct;
      state.wcFilterPlaceOfFixationId = state.filterPlaceOfFixationId;
      state.wcFilterFilialId = state.filterFilialId;
      state.wcFilterDynamic = { ...state.filterDynamic };
      state.wcFilterOrder = state.filterOrder;

      state.filterOpened = true;
      state.filterIsReport = action.payload;
    },
    filterClosed: (state) => {
      state.filterOpened = false;
    },
    filterReseted: (state) => {
      state.wcFilterIsSendEmail = false;
      state.wcFilterDateBeg = null;
      state.wcFilterDateEnd = null;
      state.wcFilterStatuses = [{ id: '-2', label: 'Все' }];
      state.wcFilterEntryAccounting = 0;
      state.wcFilterUserFio = '';
      state.wcFilterInspectionNum = '';
      state.wcFilterDefectName = '';
      state.wcFilterQcNum = '';
      state.wcFilterDecision = '';
      state.wcFilterPiece = '';
      state.wcFilterHeat = '';
      state.wcFilterProduct = '';
      state.wcFilterPlaceOfFixationId = -1;
      state.wcFilterFilialId = -1;
      state.wcFilterDynamic = { };
      state.wcFilterOrder = '';
    },

    reportRequestSingleBusyChanged: (state, action: BooleanChangedAction) => {
      state.reportRequestSingleBusy = action.payload;
    },
    reportRequestListBusyChanged: (state, action: BooleanChangedAction) => {
      state.reportRequestListBusy = action.payload;
    },
    reportRequestHistoryBusyChanged: (state, action: BooleanChangedAction) => {
      state.reportRequestHistoryBusy = action.payload;
    },

    revocationGuidChanged: (state, action: StringChangedAction) => {
      state.revocationGuid = action.payload;
    },
    revocationNoteChanged: (state, action: StringChangedAction) => {
      state.revocationNote = action.payload;
    },
    revocationOpenedChanged: (state, action: BooleanChangedAction) => {
      state.revocationOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(prepareInfoAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.placeOfFixations = action.payload.placeOfFixations;
        state.filials = action.payload.filials;
        state.adv = action.payload.adv;
        state.advStat = action.payload.advStat;
        state.inspections = action.payload.inspections;
        state.isEndPage = action.payload.isEndPage;
        state.referLoadingStatus = action.payload.isRefersUpdate ? AsyncOpStatusWithTerminate.IDLE : AsyncOpStatusWithTerminate.TERMINATE;
        state.advLoadingStatus = action.payload.isAdvUpdate ? AsyncOpStatusWithTerminate.IDLE : AsyncOpStatusWithTerminate.TERMINATE;
      })
      .addCase(downloadRefersAsync.fulfilled, (state, action) => {
        state.placeOfFixations = action.payload.placeOfFixations;
        state.filials = action.payload.filials;
      })
      .addCase(downloadAdvAsync.fulfilled, (state, action) => {
        state.adv = action.payload.adv;
        state.advStat = action.payload.advStat;
      })
      .addCase(filterInspectionsAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        state.filterIsSendEmail = false;
        state.filterOpened = false;
        state.filterIsReport = false;

        state.filterDateBeg = state.wcFilterDateBeg;
        state.filterDateEnd = state.wcFilterDateEnd;
        state.filterStatuses = state.wcFilterStatuses.map((status) => ({ ...status }));
        state.filterEntryAccounting = state.wcFilterEntryAccounting;
        state.filterUserFio = state.wcFilterUserFio.trim();
        state.filterInspectionNum = state.wcFilterInspectionNum.trim();
        state.filterDefectName = state.wcFilterDefectName.trim();
        state.filterQcNum = state.wcFilterQcNum.trim();
        state.filterDecision = state.wcFilterDecision.trim();
        state.filterPiece = state.wcFilterPiece.trim();
        state.filterHeat = state.wcFilterHeat.trim();
        state.filterProduct = state.wcFilterProduct.trim();
        state.filterPlaceOfFixationId = state.wcFilterPlaceOfFixationId;
        state.filterFilialId = state.wcFilterFilialId;
        state.filterDynamic = { ...state.wcFilterDynamic };
        state.filterOrder = state.wcFilterOrder.trim();

        state.inspections = action.payload.isStart ? [...action.payload.list] : [...state.inspections, ...action.payload.list];
        state.currentPage = action.payload.isStart ? 1 : state.currentPage + 1;
        state.isEndPage = action.payload.isEndPage;

      })
      .addCase(filterInspectionsAsync.rejected, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.pageLoading = false;
      })
      .addCase(reportInspectionsAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        state.filterOpened = false;
        state.filterIsSendEmail = false;
        state.filterIsReport = false; 
      })
      .addCase(reportInspectionsAsLinkAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        state.filterOpened = false;
        state.filterIsSendEmail = false;
        state.filterIsReport = false; 
      });
  },
})

const selectOpStatus = (state: RootState) => state.mainInspectionList.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainInspectionList.opStatusLabel;

const selectReferLoadingStatus = (state: RootState) => state.mainInspectionList.referLoadingStatus;
const selectAdvLoadingStatus = (state: RootState) => state.mainInspectionList.advLoadingStatus;

const selectPlaceOfFixations = (state: RootState) => state.mainInspectionList.placeOfFixations;
const selectFilials = (state: RootState) => state.mainInspectionList.filials;
const selectAdv = (state: RootState) => state.mainInspectionList.adv;
const selectAdvStat = (state: RootState) => state.mainInspectionList.advStat;
const selectAdvIsPriority = (state: RootState) => state.mainInspectionList.advIsPriority;
const selectInspections = (state: RootState) => state.mainInspectionList.inspections;
const selectBottomSheetContext = (state: RootState) => state.mainInspectionList.bottomSheetContext;

const selectFilterOpened = (state: RootState) => state.mainInspectionList.filterOpened;
const selectFilterIsReport = (state: RootState) => state.mainInspectionList.filterIsReport;
const selectIsEndPage = (state: RootState) => state.mainInspectionList.isEndPage;
const selectPageLoading = (state: RootState) => state.mainInspectionList.pageLoading;

const selectFilterIsSendEmail = (state: RootState) => state.mainInspectionList.filterIsSendEmail;
const selectFilterDateBeg = (state: RootState) => state.mainInspectionList.filterDateBeg;
const selectFilterDateEnd = (state: RootState) => state.mainInspectionList.filterDateEnd;
const selectFilterStatuses = (state: RootState) => state.mainInspectionList.filterStatuses;
const selectFilterEntryAccounting = (state: RootState) => state.mainInspectionList.filterEntryAccounting;
const selectFilterUserFio = (state: RootState) => state.mainInspectionList.filterUserFio;
const selectFilterInspectionNum = (state: RootState) => state.mainInspectionList.filterInspectionNum;
const selectFilterDefectName = (state: RootState) => state.mainInspectionList.filterDefectName;
const selectFilterQcNum = (state: RootState) => state.mainInspectionList.filterQcNum;
const selectFilterDecision = (state: RootState) => state.mainInspectionList.filterDecision;
const selectFilterPiece = (state: RootState) => state.mainInspectionList.filterPiece;
const selectFilterHeat = (state: RootState) => state.mainInspectionList.filterHeat;
const selectFilterProduct = (state: RootState) => state.mainInspectionList.filterProduct;
const selectFilterPlaceOfFixationId = (state: RootState) => state.mainInspectionList.filterPlaceOfFixationId;
const selectFilterFilialId = (state: RootState) => state.mainInspectionList.filterFilialId;
const selectFilterDynamic = (state: RootState) => state.mainInspectionList.filterDynamic;
const selectFilterOrder = (state: RootState) => state.mainInspectionList.filterOrder;

const selectWCFilterIsSendEmail = (state: RootState) => state.mainInspectionList.wcFilterIsSendEmail;
const selectWCFilterDateBeg = (state: RootState) => state.mainInspectionList.wcFilterDateBeg;
const selectWCFilterDateEnd = (state: RootState) => state.mainInspectionList.wcFilterDateEnd;
const selectWCFilterStatuses = (state: RootState) => state.mainInspectionList.wcFilterStatuses;
const selectWCFilterEntryAccounting = (state: RootState) => state.mainInspectionList.wcFilterEntryAccounting;
const selectWCFilterUserFio = (state: RootState) => state.mainInspectionList.wcFilterUserFio;
const selectWCFilterInspectionNum = (state: RootState) => state.mainInspectionList.wcFilterInspectionNum;
const selectWCFilterDefectName = (state: RootState) => state.mainInspectionList.wcFilterDefectName;
const selectWCFilterQcNum = (state: RootState) => state.mainInspectionList.wcFilterQcNum;
const selectWCFilterDecision = (state: RootState) => state.mainInspectionList.wcFilterDecision;
const selectWCFilterPiece = (state: RootState) => state.mainInspectionList.wcFilterPiece;
const selectWCFilterHeat = (state: RootState) => state.mainInspectionList.wcFilterHeat;
const selectWCFilterProduct = (state: RootState) => state.mainInspectionList.wcFilterProduct;
const selectWCFilterPlaceOfFixationId = (state: RootState) => state.mainInspectionList.wcFilterPlaceOfFixationId;
const selectWCFilterFilialId = (state: RootState) => state.mainInspectionList.wcFilterFilialId;
const selectWCFilterDynamic = (state: RootState) => state.mainInspectionList.wcFilterDynamic;
const selectWCFilterOrder = (state: RootState) => state.mainInspectionList.wcFilterOrder;

const selectAppRatingOpened = (state: RootState) => state.mainInspectionList.appRatingOpened;
const selectHelpOpened = (state: RootState) => state.mainInspectionList.helpOpened;
const selectRemarkStatusOpened = (state: RootState) => state.mainInspectionList.remarkStatusOpened;

const selectReportRequestSingleBusy = (state: RootState) => state.mainInspectionList.reportRequestSingleBusy;
const selectReportRequestListBusy = (state: RootState) => state.mainInspectionList.reportRequestListBusy;
const selectReportRequestHistoryBusy = (state: RootState) => state.mainInspectionList.reportRequestHistoryBusy;

const selectRevocationGuid = (state: RootState) => state.mainInspectionList.revocationGuid;
const selectRevocationOpened = (state: RootState) => state.mainInspectionList.revocationOpened;
const selectRevocationNote = (state: RootState) => state.mainInspectionList.revocationNote;

const selectFiltersFilled = createSelector(
  [
    selectFilterDateBeg,
    selectFilterDateEnd,
    selectFilterStatuses,
    selectFilterUserFio,
    selectFilterInspectionNum,
    selectFilterDefectName,
    selectFilterQcNum,
    selectFilterDecision,
    selectFilterPiece,
    selectFilterHeat,
    selectFilterProduct,
    selectFilterPlaceOfFixationId,
    selectFilterFilialId,
    selectFilterDynamic,
    selectFilterOrder,
    selectFilterEntryAccounting,
  ],
  (dateBeg, dateEnd, statuses, userFio, inspectionNum, defectName, qNum, decision,
    piece, heat, product, placeOfFixationId, filialId, dynamic, order, entryAccounting) => {
    return (
      dateBeg !== null
      ||
      dateEnd !== null
      ||
      (statuses.length > 0 && statuses[0].id !== '-2')
      ||
      userFio.trim().length > 0
      ||
      inspectionNum.trim().length > 0
      ||
      defectName.trim().length > 0
      ||
      qNum.trim().length > 0
      ||
      decision.trim().length > 0
      ||
      piece.trim().length > 0
      ||
      heat.trim().length > 0
      ||
      product.trim().length > 0
      ||
      placeOfFixationId > -1
      ||
      (isDesktop && filialId > -1)
      ||
      (Object.keys(dynamic).length > 0 && Object.keys(dynamic).some((key) => dynamic[key].trim().length > 0))
      ||
      order.trim().length > 0
      ||
      entryAccounting > 0
    )
  }
);

export const mainInspectionListSelectors = {
  selectOpStatus,
  selectOpStatusLabel,
  selectReferLoadingStatus,
  selectAdvLoadingStatus,
  selectPlaceOfFixations,
  selectFilials,
  selectAdv,
  selectAdvStat,
  selectAdvIsPriority,
  selectInspections,
  selectBottomSheetContext,
  selectIsEndPage,
  selectPageLoading,
  selectFilterOpened,
  selectFilterIsReport,
  selectFilterIsSendEmail,
  selectFilterDateBeg,
  selectFilterDateEnd,
  selectFilterStatuses,
  selectFilterEntryAccounting,
  selectFilterUserFio,
  selectFilterInspectionNum,
  selectFilterDefectName,
  selectFilterQcNum,
  selectFilterDecision,
  selectFilterPiece,
  selectFilterHeat,
  selectFilterProduct,
  selectFilterPlaceOfFixationId,
  selectFilterFilialId,
  selectFilterDynamic,
  selectFilterOrder,
  selectWCFilterIsSendEmail,
  selectWCFilterDateBeg,
  selectWCFilterDateEnd,
  selectWCFilterStatuses,
  selectWCFilterEntryAccounting,
  selectWCFilterUserFio,
  selectWCFilterInspectionNum,
  selectWCFilterDefectName,
  selectWCFilterQcNum,
  selectWCFilterDecision,
  selectWCFilterPiece,
  selectWCFilterHeat,
  selectWCFilterProduct,
  selectWCFilterPlaceOfFixationId,
  selectWCFilterFilialId,
  selectWCFilterDynamic,
  selectWCFilterOrder,
  selectAppRatingOpened,
  selectHelpOpened,
  selectRemarkStatusOpened,
  selectReportRequestSingleBusy,
  selectReportRequestListBusy,
  selectReportRequestHistoryBusy,
  selectFiltersFilled,
  selectRevocationGuid,
  selectRevocationOpened,
  selectRevocationNote,
}

export const mainInspectionListActions = mainInspectionListSlice.actions;

export default mainInspectionListSlice.reducer;