import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexRow,
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS          } from 'src/providers/theme/shared';



type NavBarLookAndFeel = {
  height: number;
  paddings: number[];
  gap: number;

  buttonVariant: IconButtonVariants;
  fakeButtonSize: number;

  labelColor: string;
  labelTypo: Typo;

  backgroundColor: string;
}

const navbar_lightblue: NavBarLookAndFeel = {
  height: 56,
  paddings: [4],
  gap: 4,

  buttonVariant: 's48whitered',
  fakeButtonSize: 48,

  labelColor:  themeColors.PrimaryWhite,
  labelTypo: themeTypos.GRTSKREG_W600_S15_LH20,

  backgroundColor: themeColors.PrimaryBlue100,
}

const navbar_lightwhite: NavBarLookAndFeel = {
  height: 56,
  paddings: [4],
  gap: 4,

  buttonVariant: 's48bluered',
  fakeButtonSize: 48,

  labelColor: themeColors.PrimaryBlack,
  labelTypo: themeTypos.GRTSKREG_W400_S15_LH22,

  backgroundColor: themeColors.PrimaryWhite,
}

export type NavBarVariants = 'lightblue' | 'lightwhite';

export const navbar_variants: Map<NavBarVariants, NavBarLookAndFeel> = 
  new Map<NavBarVariants, NavBarLookAndFeel>([
    ['lightblue', navbar_lightblue],
    ['lightwhite', navbar_lightwhite],
  ]);

export const Container = styled.div<{
  lookAndFeel: NavBarLookAndFeel
}>`
  width: 100%;
  height: ${(args) => args.lookAndFeel.height}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;

  ${cssFlexRow};
  align-items: center;
  gap: ${(args) => args.lookAndFeel.gap}px;
  flex-shrink: 0;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const ButtonContainer = styled.div<{
  lookAndFeel: NavBarLookAndFeel
}>`
  display: flex;
  height: ${(args) => args.lookAndFeel.height - 2 * args.lookAndFeel.paddings[0]}px;
`

export const FakeButton = styled.div<{
  lookAndFeel: NavBarLookAndFeel
}>`
  flex-shrink: 0;
  width: ${(args) => args.lookAndFeel.fakeButtonSize}px;
  height: ${(args) => args.lookAndFeel.fakeButtonSize}px;
`

export const Label = styled.div<{
  lookAndFeel: NavBarLookAndFeel,
}>`
  flex-grow: 2;
  text-align: center;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
