import * as React                 from 'react';

import { v4 as uuidv4           } from 'uuid';

import { useAlert               } from 'react-alert';

import { useNavigate            } from 'react-router-dom';

import Drawer                     from '@mui/material/Drawer';

import { PhotoProvider          } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { ArrowBackVector        } from 'src/components/vector/arrowback';
import { CircleQuestionVector   } from 'src/components/vector/circle-question';
import { ScannerVector          } from 'src/components/vector/scanner';
import { ArrowDownVector        } from 'src/components/vector/arrow-down';
import { DownloadVector         } from 'src/components/vector/download';
import { ReloadVector           } from 'src/components/vector/reload';
import { EyeShowVector          } from 'src/components/vector/eye-show';
import { SharingVector          } from 'src/components/vector/sharing';

import { IconButton             } from 'src/components/common/icon-button';
import { Button                 } from 'src/components/common/button';
import { Typography             } from 'src/components/common/typography';
import { Labeled                } from 'src/components/common/labeled';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { InputMultiline         } from 'src/components/common/input/input-multiline';
import { Grow                   } from 'src/components/common/flex/grow';
import { TabItem, TabSwitch     } from 'src/components/common/tab-switch';
import { TwoActionDialog        } from 'src/components/common/two-action-dialog';
import { Switch                 } from 'src/components/common/switch';

import { ReferSelector,
         ReferSelectorItem      } from 'src/components/features/common/refer-selector';

import { PieceNumIdHelpDialog   } from 'src/components/features/inspection/edit/piecenumid-help-dialog';
import { PieceHelpDialog        } from 'src/components/features/inspection/edit/piece-help-dialog';
import { GroupAttHelpDialog     } from 'src/components/features/inspection/edit/groupatt-help-dialog';
import { Scanner                } from 'src/components/features/inspection/edit/scanner';
import { AttachmentAdd          } from 'src/components/features/inspection/edit/attachment-add';
import { AttachmentPhoto        } from 'src/components/features/inspection/edit/attachment-photo';
import { AttachmentPDF          } from 'src/components/features/inspection/edit/attachment-pdf';
import { CertDownloadDialog     } from 'src/components/features/inspection/edit/cert-download-dialog';

import { PieceEditLookAndFeel,
         Container,
         HeaderContainer,
         FormContainer,
         FormRowElement,
         PhotoList,
         SubmitContainer,
         WeightContainer,
         MarriageShareContainer,
         Hr,
         CloseOutlineVectorBig, 
         PieceEditVariants,
         pieceedit_variants     } from 'src/components/features/inspection/edit/piece-edit-dialog/ui';

import Util                       from 'src/services/util';
import Db                         from 'src/services/db';
import * as types                 from 'src/services/api/types';
import { QCState                } from 'src/services/api/types/inspection';
import { AccountRole            } from 'src/services/api/types/auth';
import { InspectionState        } from 'src/services/api/types/inspection';
import Constants                  from 'src/services/constants';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';



const TYPE_GENERAL = types.inspection.PieceFileType.GENERAL;
const TYPE_PHOTO = types.inspection.PieceFileType.PHOTO;
const TYPE_PDF = types.inspection.PieceFileType.PDF;
const MODE_NEW = types.inspection.PieceFileMode.NEW;
const MODE_DEL = types.inspection.PieceFileMode.DEL;

type PieceEditFormElement = { lookAndFeel: PieceEditLookAndFeel; dispatch: AppDispatch; };

const InputUEModeSwitcher: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const isPiece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsPiece);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const [isConfirmOpened, setIsConfirmOpened] = React.useState<boolean>(false);
  const [confirmedTab, setConfirmedTab] = React.useState<string>('');

  const items = [
    { id: 'id', label: 'Идентификац. №' },
    { id: 'piece', label: account.isWireRod ? '№ партии/плавки' : '№ плавки' }
  ];
  const item = isPiece ? items[1] : items[0];

  const isDisabled = inspectionState === InspectionState.EDIT;

  const onModeSwitchHandler = (tab: TabItem) => {
    setConfirmedTab(tab.id);
    setIsConfirmOpened(true);
  }

  const onConfirmAcceptHandler = () => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedIsPieceChanged(confirmedTab === 'piece'));
    setIsConfirmOpened(false);
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onConfirmCancelHandler = () => {
    setIsConfirmOpened(false);
  }

  return (
    <React.Fragment>
      <TwoActionDialog
        variant = { lookAndFeel.modeSwitchConfirmDialogVariant }
        opened = { isConfirmOpened }
        caption = 'Внимание!'
        message = 'При переключении данные о позиции будут частично стерты'
        onCancel = { onConfirmCancelHandler }
        onAction = { onConfirmAcceptHandler }
      />
      <TabSwitch
        variant = { lookAndFeel.modeSwitcherVariant }
        items = { items }
        value = { item }
        isDisabled = { isDisabled }
        onChange = { onModeSwitchHandler }
      />
    </React.Fragment>
  )
}

const QmetId: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const qmetId = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQmetId);
  const qmetIdError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQmetIdError);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const [isHelpOpened, setIsHelpOpened] = React.useState<boolean>(false);
  const [isScannerOpened, setIsScannerOpened] = React.useState<boolean>(false);

  const isDisabled = inspectionState === InspectionState.EDIT;

  const onOpenHelpDialogHandler = () => {
    setIsHelpOpened(true);
  }

  const onHelpCloseHandler = () => {
    setIsHelpOpened(false);
  }

  const onScannerTapHandler = () => {
    setIsScannerOpened(true);
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Идентификационный №' isRequired>
      <FormRowElement lookAndFeel = { lookAndFeel }>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { `${qmetId ?? ''}` }
          isError = { qmetIdError !== '' }
          isDisabled = { isDisabled }
          hint = { qmetIdError }
          maxLength = { Constants.PIECE_NUM_ID_LENGTH_MAX }
          customIcon = { qmetId === null ? <CircleQuestionVector className = 'circle-question' /> : undefined }
          onCustomIconTap = { onOpenHelpDialogHandler }
          valueTransformer = { Util.selectDigitsFromString }
          onChange = { (value) => {
            dispatch(storeApi.inspection.edit.actions.pieceEditedQmetIdChanged(value));
            setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
          }}
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedQmetIdErrorFixed()) }
        />
        {!isDisabled && (
          <IconButton variant = { lookAndFeel.scannerButtonVariant } isTapAllowed onTap = { onScannerTapHandler }>
            <ScannerVector />
          </IconButton>
        )}
      </FormRowElement>
      <PieceNumIdHelpDialog
        variant = { lookAndFeel.piecenumidHelpVariant }
        opened = { isHelpOpened }
        onClose = { onHelpCloseHandler }
      />
      {isScannerOpened && (
        <Scanner
          variant = { lookAndFeel.scannerVariant }
          onCancel = { () => { console.log('cancel'); setIsScannerOpened(false); } }
          onDecode = { (code) => {
            setIsScannerOpened(false);
            dispatch(storeApi.inspection.edit.actions.pieceEditedQmetIdChanged(code));
            setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
          }}
        />
      )}
    </Labeled>
  )
}

const Heat: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const heat = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedHeat);
  const heatError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedHeatError);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const piece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedPiece);

  const isRequired = !account.isWireRod || (account.isWireRod && piece !== null);
  const isDisabled = inspectionState === InspectionState.EDIT;

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ плавки' isRequired = { isRequired }>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${heat ?? ''}` }
        isError = { heatError !== '' }
        isDisabled = { isDisabled }
        hint = { heatError }
        valueTransformer = { Util.selectHeatAllowedCharsFromString }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedHeatChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
        onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedHeatErrorFixed()) }
      />
    </Labeled>
  )
}

const Piece: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const piece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedPiece);
  const pieceError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedPieceError);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const groupAtt = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedGroupAtt);
  const qcNum = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQCNum);

  const isRequired = !account.isWireRod || (account.isWireRod && piece !== null);
  const isDisabled = inspectionState === InspectionState.EDIT || groupAtt !== null || qcNum !== null;

  const [isHelpOpened, setIsHelpOpened] = React.useState<boolean>(false);

  const onOpenHelpDialogHandler = () => {
    setIsHelpOpened(true);
  }

  const onHelpCloseHandler = () => {
    setIsHelpOpened(false);
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ рулона/листа/трубы' isRequired = { isRequired }>
      <FormRowElement lookAndFeel = { lookAndFeel }>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { `${piece ?? ''}` }
          isError = { pieceError !== '' }
          hint = { pieceError }
          isDisabled = { isDisabled }
          customIcon = { piece === null ? <CircleQuestionVector className = 'circle-question' /> : undefined }
          onCustomIconTap = { onOpenHelpDialogHandler }
          onChange = { (value) => {
            dispatch(storeApi.inspection.edit.actions.pieceEditedPieceChanged(value));
            setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
          }}
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedPieceErrorFixed()) }
        />
        <PieceHelpDialog
          variant = { lookAndFeel.pieceHelpVariant }
          opened = { isHelpOpened }
          onClose = { onHelpCloseHandler }
        />
      </FormRowElement>
    </Labeled>
  )
}

const GroupAtt: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const groupAtt = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedGroupAtt);
  const groupAttError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedGroupAttError);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const qcNum = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQCNum);
  const piece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedPiece);

  const isRequired = groupAtt !== null || qcNum !== null;
  const isWireRod = account.isWireRod || groupAtt !== null;
  const isDisabled = inspectionState === InspectionState.EDIT || piece !== null;

  const [isHelpOpened, setIsHelpOpened] = React.useState<boolean>(false);

  const onOpenHelpDialogHandler = () => {
    setIsHelpOpened(true);
  }

  const onHelpCloseHandler = () => {
    setIsHelpOpened(false);
  }

  if (!isWireRod)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ партии аттестации' isRequired = { isRequired }>
      <FormRowElement lookAndFeel = { lookAndFeel }>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { `${groupAtt ?? ''}` }
          isError = { groupAttError !== '' }
          hint = { groupAttError }
          isDisabled = { isDisabled }
          customIcon = { groupAtt === null ? <CircleQuestionVector className = 'circle-question' /> : undefined }
          onCustomIconTap = { onOpenHelpDialogHandler }
          onChange = { (value) => {
            dispatch(storeApi.inspection.edit.actions.pieceEditedGroupAttChanged(value));
            setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
          }}
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedGroupAttErrorFixed()) }
        />
        <GroupAttHelpDialog
          variant = { lookAndFeel.groupattHelpVariant }
          opened = { isHelpOpened }
          onClose = { onHelpCloseHandler }
        />
      </FormRowElement>
    </Labeled>
  )
}

const QCNum: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const qcNum = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQCNum);
  const qcState = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQCState);
  const qcNumError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedQCNumError);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const inspectionGuid = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralEditedGuid);

  const groupAtt = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedGroupAtt);
  const piece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedPiece);
  const isSmc = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsSmc);

  const isRequired = groupAtt !== null || qcNum !== null;
  const isWireRod = account.isWireRod || groupAtt !== null;
  const isDisabled = inspectionState === InspectionState.EDIT || piece !== null || !isWireRod;

  const downloadHandler = () => {
    if (qcNum !== null)
    {
      dispatch(storeApi.inspection.edit.async.downloadCertAsync({ qcNum }));
    }
  }

  const reloadHandler = () => {
    dispatch(storeApi.inspection.edit.async.checkCertAsync({ inspectionGuid }));
  }

  const showHandler = () => {
    if (qcNum !== null)
    {
      dispatch(storeApi.inspection.edit.async.viewCertAsync({ qcNum }));
    }
  }

  const sharingHandler = () => {
    if (qcNum !== null)
    {
      dispatch(storeApi.inspection.edit.async.shareCertAsync({ qcNum }));
    }
  }

  if (!isWireRod && qcNum === null)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = '№ сертификата качества' isRequired = { isRequired }>
      <FormRowElement lookAndFeel = { lookAndFeel }>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { `${qcNum ?? ''}` }
          isError = { qcNumError !== '' }
          hint = { qcNumError }
          isDisabled = { isDisabled }
          onChange = { (value) => {
            dispatch(storeApi.inspection.edit.actions.pieceEditedQCNumChanged(value));
            setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
          }}
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedQCNumErrorFixed()) }
        />
        { isDisabled && !isSmc && qcNum !== '' && qcState === QCState.NONE && 
          <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { downloadHandler }>
            <DownloadVector />
          </IconButton>
        }
        { isDisabled && !isSmc && qcNum !== '' && qcState === QCState.LOADING && 
          <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { reloadHandler }>
            <ReloadVector />
          </IconButton>
        }
        { isDisabled && !isSmc && qcNum !== '' && qcState === QCState.LOADED && 
          <React.Fragment>
            <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { showHandler }>
              <EyeShowVector />
            </IconButton>
            <IconButton variant = { lookAndFeel.loadCertButtonBariant } isTapAllowed onTap = { sharingHandler }>
              <SharingVector />
            </IconButton>
          </React.Fragment>
        }
      </FormRowElement>
    </Labeled>
  )
}

const CustomField: React.FC<PieceEditFormElement & { field: types.auth.FieldDescriptor }> = ({ field, lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);
  const [referItems, setReferItems] = React.useState<ReferSelectorItem[]>([]);

  const customFields = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedCustomFields);
  const customFieldsErrors = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedCustomFieldsErrors);

  const fieldValue = customFields.find((item) => item.code === field.code)?.value;
  const fieldError = customFieldsErrors[field.code];

  React.useEffect(() => {
    if (field.refer !== null)
    {
      Db.getRefers()
        .then((refers) => {
          setReferItems(
            refers
              .filter((item) => item.referId === field.refer!.id)
              .map((item) => ({ id: item.title, label: item.title }))
          );
        })
        .catch((error) => {

        });
    }
  }, [field]);

  const onChangeValueHandler = (value: ReferSelectorItem) => {
    onChangeHandler(value.id);
  }

  const onChangeHandler = (value: string) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedCustomFieldChanged({
      id: field.id,
      code: field.code,
      value: value
    }));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  };

  if (field.refer === null)
  {
    return (
      <Labeled 
        key = { field.code }
        variant = { lookAndFeel.labeledVariant }
        label = { field.title }
        isRequired = { !field.isOptional }
      >
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { fieldValue ?? '' }
          isError = { fieldError !== undefined }
          hint = { fieldError ?? '' }
          placeholder = { field.hint ?? '' }
          maxLength = { field.size }
          onChange = { onChangeHandler }
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedCustomFieldErrorFixed(field.code)) }
        />
      </Labeled>
    )
  }

  return (
    <React.Fragment>
      <Labeled 
        key = { field.code }
        variant = { lookAndFeel.labeledVariant }
        label = { field.title }
        isRequired = { !field.isOptional }
      >
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { fieldValue ?? '' }
          isError = { fieldError !== undefined }
          hint = { fieldError ?? '' }
          readOnly = { !field.refer.isCanEditHand }
          placeholder = { field.hint ?? '' }
          maxLength = { field.size }
          customIcon = { <ArrowDownVector /> }
          onChange = { onChangeHandler }
          onCustomIconTap = { () => setReferOpened(true) }
          onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedCustomFieldErrorFixed(field.code)) }
        />
      </Labeled>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { referItems }
        isOpened = { referOpened }
        isSearchable = { field.refer.isFind }
        label = { field.title }
        selectedId = { fieldValue ? `${fieldValue}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onChangeValueHandler }
      />
    </React.Fragment>
  )
}

const CustomFields: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const fields = [...account.fields].sort((a, b) => a.id - b.id);

  return (
    <React.Fragment>
      {fields.map((field) => (
        <CustomField field = { field } lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
      ))}
    </React.Fragment>
  )
}

const IsMarriageShare: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const isMarriageShareExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsMarriageShareExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const onMariageExpanedChanged = (checked: boolean) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedIsMarriageShareChanged(checked));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  if (isEntryAccounting || account.role === AccountRole.Contractor)
  {
    return null;
  }

  return (
    <FormRowElement lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.topoSwitchLabelTypo }>Рассчитать долю брака</Typography>
      <Switch
        variant = { lookAndFeel.topoSwitchVariant }
        value = { isMarriageShareExpanded }
        onChange = { onMariageExpanedChanged }
      />
    </FormRowElement>
  )
}

const WeightBeforeProcessing: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const weight = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedWeightBeforeProcessing);
  const isMarriageShareExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsMarriageShareExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isMarriageShareExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Вес до переработки'
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${weight ?? ''}` }
        placeholder = 'До переработки'
        valueTransformer = { Util.selectDigitsAndDotFromString3 }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedWeightBeforeProcessingChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
      />
    </Labeled>
  )
}

const WeightAfterProcessing: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const weight = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedWeightAfterProcessing);
  const weightError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedWeightAfterProcessingError);
  const isMarriageShareExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsMarriageShareExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isMarriageShareExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Вес годного после переработки'
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${weight ?? ''}` }
        isError = { weightError !== '' }
        hint = { weightError }
        placeholder = 'После переработки'
        valueTransformer = { Util.selectDigitsAndDotFromString3 }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedWeightAfterProcessingChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
      />
    </Labeled>
  )
}

const MarriageShare: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const marriageShare = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedMarriageShare);
  const isMarriageShareExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsMarriageShareExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isMarriageShareExpanded)
  {
    return null;
  }

  return (
    <MarriageShareContainer lookAndFeel = { lookAndFeel }>
      <span>Доля брака</span>
      <span>{ `${marriageShare ?? 0}%` }</span>
    </MarriageShareContainer>
  )
}

const Weight: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const unitsAll = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralUnitRefer);
  const weight = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedWeight);
  const weightError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedWeightError);
  const unit = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedUnit);
  const unitElement = unitsAll.find((item) => item.id === unit?.id) ?? null;
  const defectGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectExpandedGuid);
  const defectId = useAppSelector((state) => storeApi.inspection.edit.selectors.selectDefectDefectId(state, defectGuid)) ?? 0;
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const unitDetail = unitElement === null ? null : unitElement.details.find((item) => item.isEntryAccounting === isEntryAccounting) ?? null;

  const [referOpened, setReferOpened] = React.useState<boolean>(false);
  const [warningOpened, setWarningOpened] = React.useState<boolean>(false);
  const [warningShowed, setWarningShowed] = React.useState<boolean>(false);
  const [warningMessage, setWarningMessage] = React.useState<string>('');

  const items = unitsAll.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }));

  React.useEffect(() => {
    if (weight.endsWith('.'))
    {
      if (!warningShowed && unit?.id === 1)
      {
        setWarningMessage('Указывать вес нетто в кг.');
        setWarningOpened(true);
      }
    }
    else
    {
      if (weight.length > 0 && [19,34].includes(defectId) && ((unit?.id === 1 && parseFloat(weight) > 3000) || (unit?.id === 2 && parseFloat(weight) > 3)))
      {
        setWarningMessage('При выборе дефекта «Недостача» или «Излишки» необходимо указывать разницу между фактическим весом и весом по документу поставки');
        setWarningOpened(true);
        dispatch(storeApi.inspection.edit.actions.pieceEditedWeightChanged(''));
        setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
      }
    }
    
  }, [warningShowed, weight, unit]);

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedWeightUnitChanged(parseInt(item.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onWarningCloseHandler = () => {
    setWarningOpened(false);
    setWarningShowed(true);
  }

  return (
    <React.Fragment>
      <Labeled
        variant = { lookAndFeel.labeledVariant }
        label = { unit?.fieldTitle ?? 'Вес' }
        isRequired = { !isEntryAccounting && account.role !== AccountRole.Contractor }
      >
        <WeightContainer lookAndFeel = { lookAndFeel }>
          <InputTextbox
            variant = { lookAndFeel.textboxVariant }
            value = { `${weight ?? ''}` }
            isError = { weightError !== '' }
            hint = { weightError }
            placeholder = { unitDetail?.fieldHint ?? '' }
            valueTransformer = { (unitElement?.isInteger) ? Util.selectDigitsFromString : Util.selectDigitsAndDotFromString3 }
            onChange = { (value) => {
              dispatch(storeApi.inspection.edit.actions.pieceEditedWeightChanged(value));
              setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
            }}
            onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedWeightErrorFixed()) }
          />
          <InputTextbox
            variant = { lookAndFeel.textboxVariant }
            value = { unit?.title ?? '' }
            readOnly
            onChange = { (value: string) => {  } }
            placeholder = ''
            customIcon = { <ArrowDownVector /> }
            onCustomIconTap = { () => setReferOpened(true) }
          />
        </WeightContainer>
      </Labeled>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Единица измерения'
        selectedId = { unit ? `${unit.id}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
      <TwoActionDialog
        variant = { lookAndFeel.weightInputWarningDialogVariant }
        opened = { warningOpened }
        caption = 'Внимание!'
        message = { warningMessage }
        withoutCancel
        isDanger
        onAction = { onWarningCloseHandler }
      />
    </React.Fragment>
  )
}

const Note: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const note = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedNote);
  const noteError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedNoteError);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Детальное описание по позиции (прочие комментарии)'>
      <InputMultiline
        variant = { lookAndFeel.multilineVariant }
        value = { note ?? '' }
        isError = { noteError !== '' }
        hint = { noteError }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedNoteChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
        onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedNoteErrorFixed()) }
      />
    </Labeled>
  )
}

const Photo: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const alert = useAlert();
  const [typeForAdd, setTypeForAdd] = React.useState<types.inspection.PieceFileType>(TYPE_GENERAL);
  const [guidForDelete, setGuidForDelete] = React.useState<string>('');
  const [confirmDeleteOpened, setConfirmDeleteOpened] = React.useState<boolean>(false);
  const [warningAppendOpened, setWarningAppendOpened] = React.useState<boolean>(false);
  const [warningAppendMessage, setWarningAppendMessage] = React.useState<string>('');

  const files = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedFiles);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  
  const photoCountAll = parseInt(account.configs.find((config) => config.code === 'CNT_PHOTO')?.value ?? '15', 10);
  const pdfCountAll = parseInt(account.configs.find((config) => config.code === 'CNT_PDF')?.value ?? '3', 10);
  const general = files.find((file) => file.type === TYPE_GENERAL) ?? null;
  const isGeneralExists = general !== null && general.mode !== MODE_DEL;
  const photos = files.filter((file) => file.type === TYPE_PHOTO && file.mode !== MODE_DEL);
  const photoCountAllow = photoCountAll - (isGeneralExists ? 1 : 0) - photos.length;
  const pdfs = files.filter((file) => file.type === TYPE_PDF && file.mode !== MODE_DEL);
  const pdfCountAllow = pdfCountAll - pdfs.length;

  const onPhotosSelectedHandler = async (list: FileList) => {
    const listAsArray = Array.from(list);
    let counter = photoCountAllow;
    let type = typeForAdd;
    let warningMessage = '';

    if (type === TYPE_PHOTO && !isGeneralExists)
    {
      counter -= 1;
    }

    if (isGeneralExists)
    {
      if (list.length > counter)
      {
        warningMessage = `Вы выбрали ${list.length} фото, можно добавить только ${counter}. Лишние фотографии не добавлены`;
      }
    }
    else
    {
      if (type === TYPE_GENERAL)
      {
        if (list.length > counter)
        {
          warningMessage = `Вы выбрали ${list.length} фото, можно добавить только ${counter}. Лишние фотографии не добавлены`;
        }
      }
      else
      {
        if (list.length > counter)
        {
          warningMessage = `Вы выбрали ${list.length} фото, можно добавить только ${counter}. Одно фото нужно обязательно загрузить через плитку Общий вид. Лишние фотографии не добавлены`;
        }
      }
    }

    if (warningMessage !== '')
    {
      setWarningAppendMessage(warningMessage);
      setWarningAppendOpened(true);
    }

    let generalList: File[] = [];
    let photoList: File[] = [];

    for (const file of listAsArray)
    {
      if (counter === 0)
      {
        break;
      }

      if (type === TYPE_GENERAL)
      {
        generalList.push(file);
        type = TYPE_PHOTO;
      }
      else
      {
        photoList.push(file);
      }

      counter -= 1;
    }

    for await (const file of generalList)
    {
      const fileTypeByExt = Util.checkFileTypeByExt(file);

      if (!['JPG', 'JFIF', 'BMP', 'PNG', 'WEBP'].includes(fileTypeByExt))
      {
        alert.error(`Файл ${file.name} не является изображением`);
        continue;
      }

      const fileTypeByHeader = await Util.checkFileTypeByHeader(file);

      if (!['JPG', 'JFIF', 'BMP', 'PNG', 'WEBP'].includes(fileTypeByHeader))
      {
        alert.error(`Файл ${file.name} не является изображением`);
        continue;
      }

      const guid = uuidv4();
      const resizedFile = await Util.resizeIfImage(file, Constants.PIECE_IMAGE_BIGGEST_SIZE);
      const dataUrlResult = await Util.imageToDataURL(resizedFile);
      const binaryStr = await Util.fileToBinaryString(resizedFile);

      const fileName = Util.buildAttachmentName(TYPE_GENERAL, file.name);

      if (dataUrlResult.ok)
      {
        dispatch(storeApi.inspection.edit.actions.pieceEditedPhotoAdded({
          guid,
          mode: MODE_NEW,
          name: fileName,
          type: TYPE_GENERAL,
          __dataUrl: dataUrlResult.result,
          __binaryStr: binaryStr,
          __mime: 'image/jpeg',
          __name: fileName
        }));
      }
      else
      {
        alert.error(`Файл ${file.name} не является изображением`);
      }
    }

    for await (const file of photoList)
    {
      const fileTypeByExt = Util.checkFileTypeByExt(file);

      if (!['JPG', 'JFIF', 'BMP', 'PNG', 'WEBP'].includes(fileTypeByExt))
      {
        alert.error(`Файл ${file.name} не является изображением`);
        continue;
      }

      const fileTypeByHeader = await Util.checkFileTypeByHeader(file);

      if (!['JPG', 'JFIF', 'BMP', 'PNG', 'WEBP'].includes(fileTypeByHeader))
      {
        alert.error(`Файл ${file.name} не является изображением`);
        continue;
      }

      const guid = uuidv4();
      const resizedFile = await Util.resizeIfImage(file, Constants.PIECE_IMAGE_BIGGEST_SIZE);
      const dataUrlResult = await Util.imageToDataURL(resizedFile);
      const binaryStr = await Util.fileToBinaryString(resizedFile);

      const fileName = Util.buildAttachmentName(TYPE_PHOTO, file.name);

      if (dataUrlResult.ok)
      {
        dispatch(storeApi.inspection.edit.actions.pieceEditedPhotoAdded({
          guid,
          mode: MODE_NEW,
          name: fileName,
          type: TYPE_PHOTO,
          __dataUrl: dataUrlResult.result,
          __binaryStr: binaryStr,
          __mime: 'image/jpeg',
          __name: fileName
        }));
      }
      else
      {
        alert.error(`Файл ${file.name} не является изображением`);
      }
    }

    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onPDFSelectedHandler = async (list: FileList) => {
    const listAsArray = Array.from(list);
    let warningMessage = '';

    if (list.length > pdfCountAllow)
    {
      warningMessage = `Вы выбрали ${list.length} документов, можно добавить только ${pdfCountAllow}. Лишние документы не добавлены`;
    }

    if (warningMessage !== '')
    {
      setWarningAppendMessage(warningMessage);
      setWarningAppendOpened(true);
    }

    const pdfList = listAsArray.length > pdfCountAllow ? listAsArray.slice(0, pdfCountAllow) : listAsArray;

    for await (const file of pdfList)
    {
      const fileTypeByExt = Util.checkFileTypeByExt(file);

      if (!['PDF'].includes(fileTypeByExt))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      const fileTypeByHeader = await Util.checkFileTypeByHeader(file);

      if (!['PDF'].includes(fileTypeByHeader))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      if ((file.size / Math.pow(1024, 2)) > Constants.PIECE_PDF_BIGGEST_SIZE_MB)
      {
        alert.error(`Размер файла ${file.name} превышает допустимый размер прикрепляемого файла в ${Constants.PIECE_PDF_BIGGEST_SIZE_MB} Мбайт`);
        continue;
      }

      const guid = uuidv4();
      const binaryStr = await Util.fileToBinaryString(file);
      const dataUrlResult = await Util.pdfToDataURL(file);

      const fileName = Util.buildAttachmentName(TYPE_PDF, file.name);

      if (dataUrlResult.ok)
      {
        dispatch(storeApi.inspection.edit.actions.pieceEditedPhotoAdded({
          guid,
          mode: MODE_NEW,
          name: fileName,
          type: TYPE_PDF,
          __dataUrl: dataUrlResult.result,
          __binaryStr: binaryStr,
          __mime: file.type,
          __name: fileName
        }));
      }
      else
      {
        alert.error(`Произошла ошибка при обработке файла`);
      }
    }

    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAddHandler = (attachmentType: types.inspection.PieceFileType) => {
    setTypeForAdd(attachmentType);
    setTimeout(() => document.getElementById(`piece-${attachmentType === types.inspection.PieceFileType.PDF ? 'pdf' : 'photo'}-selector`)?.click(), 0);
  }

  const onDeleteHandler = (guid: string) => {
    setConfirmDeleteOpened(true);
    setGuidForDelete(guid);
  }

  const onCancelDeletePhotoHandler = () => {
    setConfirmDeleteOpened(false);
  }

  const onAcceptDeletePhotoHandler = () => {
    setConfirmDeleteOpened(false);
    dispatch(storeApi.inspection.edit.actions.pieceEditedPhotoDeleted(guidForDelete));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAcceptWarningAppendHandler = () => {
    setWarningAppendOpened(false);
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Фотографии дефекта'>
      <PhotoList lookAndFeel = { lookAndFeel }>
        <PhotoProvider >
          {!isGeneralExists && (
            <AttachmentAdd
              variant = { lookAndFeel.attachmentAddVariant }
              attachmentType = { types.inspection.PieceFileType.GENERAL }
              label = 'Общий вид'
              isRequired
              onTap = { onAddHandler }
            />
          )}
          {isGeneralExists && (
            <AttachmentPhoto
              variant = { lookAndFeel.attachmentPhotoVariant }
              isAllowDelete
              photo = { general }
              onDelete = { onDeleteHandler }
            />
          )}
          {photos.map((photo) => (
            <AttachmentPhoto
              key = { photo.guid }
              variant = { lookAndFeel.attachmentPhotoVariant }
              isAllowDelete
              photo = { photo }
              onDelete = { onDeleteHandler }
            />
          ))}
          {pdfs.map((pdf) => (
            <AttachmentPDF
              key = { pdf.guid }
              variant = { lookAndFeel.attachmentPhotoVariant }
              isAllowDelete
              pdf = { pdf }
              source = 'piece'
              onDelete = { onDeleteHandler }
            />
          ))}
          {((isGeneralExists && photoCountAllow > 0) || (!isGeneralExists && photoCountAllow > 1)) && (
            <AttachmentAdd
              variant = { lookAndFeel.attachmentAddVariant }
              attachmentType = { types.inspection.PieceFileType.PHOTO }
              label = 'Доп. фото'
              allowCount = { photoCountAllow - ( isGeneralExists ? 0 : 1 ) }
              onTap = { onAddHandler }
            />
          )}
          {pdfCountAllow > 0 && (
            <AttachmentAdd
              variant = { lookAndFeel.attachmentAddVariant }
              attachmentType = { types.inspection.PieceFileType.PDF }
              label = 'Доп. файл'
              allowCount = { pdfCountAllow }
              onTap = { onAddHandler }
            />
          )}
          </PhotoProvider>
      </PhotoList>
      <input
        id = 'piece-photo-selector' 
        type = 'file' 
        accept = '.png,.bmp,.jpg,.jpeg,.jfif,.webp'
        multiple
        onChange = { (e) => e.target.files !== null && onPhotosSelectedHandler(e.target.files) } 
        onClick = { () => (document.getElementById('piece-photo-selector') as HTMLInputElement).value = '' }
        style = { { display: 'none' } }
      />
      <input
        id = 'piece-pdf-selector' 
        type = 'file' 
        accept = '.pdf'
        multiple
        onChange = { (e) => e.target.files !== null && onPDFSelectedHandler(e.target.files) }
        onClick = { () => (document.getElementById('piece-pdf-selector') as HTMLInputElement).value = '' }
        style = { { display: 'none' } }
      />
      <TwoActionDialog 
        variant = { lookAndFeel.deletePhotoDialogVariant } 
        opened = { confirmDeleteOpened }
        caption = 'Удалить вложение?'
        onCancel = { onCancelDeletePhotoHandler }
        onAction = { onAcceptDeletePhotoHandler }
      />
      <TwoActionDialog 
        variant = { lookAndFeel.deletePhotoDialogVariant } 
        opened = { warningAppendOpened }
        caption = 'Внимание'
        message = { warningAppendMessage }
        withoutCancel
        actionLabel = 'Ok'
        isDanger
        onAction = { onAcceptWarningAppendHandler }
      />
    </Labeled>
  )
}

const IsTopography: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const onTopoExpanedChanged = (checked: boolean) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedIsTopographyChanged(checked));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  if (isEntryAccounting || account.role === AccountRole.Contractor)
  {
    return null;
  }

  return (
    <FormRowElement lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.topoSwitchLabelTypo }>Топография дефекта</Typography>
      <Switch
        variant = { lookAndFeel.topoSwitchVariant }
        value = { isTopographyExpanded }
        onChange = { onTopoExpanedChanged }
      />
    </FormRowElement>
  )
}

const TopographyNote: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Typography variant = { lookAndFeel.topoNoteTypo }>
      Топографию дефекта заполнить<br /> ниже или сделать фото<br /> с рулеткой
    </Typography>
  )
}

const TopographySize: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const size = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoSize);
  const sizeError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoSizeError);

  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Размер дефекта'
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${size ?? ''}` }
        isError = { sizeError !== '' }
        hint = { sizeError }
        placeholder = 'мм'
        valueTransformer = { Util.selectDigitsAndDotFromString3 }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedTopoSizeChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
        onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedTopoSizeErrorFixed()) }
      />
    </Labeled>
  )
}

const TopographySizeId: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const sizeId = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoSizeId);
  const sizeTitle = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoSizeTitle);
  const topoRefer = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralTopographyRefer);
  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = topoRefer.filter((item) => item.isActual && item.typeTopography === 'size').map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedTopoSizeIdChanged(parseInt(item.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { sizeTitle ?? '' }
        readOnly
        onChange = { (value: string) => {  } }
        placeholder = ''
        customIcon = { <ArrowDownVector /> }
        onCustomIconTap = { () => setReferOpened(true) }
      />
      <Hr lookAndFeel = { lookAndFeel } />
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Размер дефекта'
        selectedId = { sizeId ? `${sizeId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const TopographyStep: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const step = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoStep);
  const stepError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoStepError);

  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Шаг дефекта'
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${step ?? ''}` }
        isError = { stepError !== '' }
        hint = { stepError }
        placeholder = 'мм'
        valueTransformer = { Util.selectDigitsAndDotFromString3 }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedTopoStepChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
        onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedTopoStepErrorFixed()) }
      />
    </Labeled>
  )
}

const TopographyStepId: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const stepTitle = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoStepTitle);
  const stepId = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoStepId);
  const topoRefer = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralTopographyRefer);
  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = topoRefer.filter((item) => item.isActual && item.typeTopography === 'step').map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedTopoStepIdChanged(parseInt(item.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { stepTitle ?? '' }
        readOnly
        onChange = { (value: string) => {  } }
        placeholder = ''
        customIcon = { <ArrowDownVector /> }
        onCustomIconTap = { () => setReferOpened(true) }
      />
      <Hr lookAndFeel = { lookAndFeel } />
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Шаг дефекта'
        selectedId = { stepId ? `${stepId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const TopographyDistance: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const distance = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoDistance);
  const distanceError = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoDistanceError);

  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = 'Расстояние от кромки'
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { `${distance ?? ''}` }
        isError = { distanceError !== '' }
        hint = { distanceError }
        placeholder = 'мм'
        valueTransformer = { Util.selectDigitsAndDotFromString3 }
        onChange = { (value) => {
          dispatch(storeApi.inspection.edit.actions.pieceEditedTopoDistanceChanged(value));
          setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
        }}
        onFocus = { () => dispatch(storeApi.inspection.edit.actions.pieceEditedTopoDistanceErrorFixed()) }
      />
    </Labeled>
  )
}

const TopographyDistanceId: React.FC<PieceEditFormElement> = ({ lookAndFeel, dispatch }) => {
  const distanceId = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoDistanceId);
  const distanceTitle = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedTopoDistanceTitle);
  const topoRefer = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralTopographyRefer);
  const isTopographyExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsTopographyExpanded);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = topoRefer.filter((item) => item.isActual && item.typeTopography === 'distance').map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.pieceEditedTopoDistanceIdChanged(parseInt(item.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  if (isEntryAccounting || account.role === AccountRole.Contractor || !isTopographyExpanded)
  {
    return null;
  }

  return (
    <React.Fragment>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { distanceTitle ?? '' }
        readOnly
        onChange = { (value: string) => {  } }
        placeholder = ''
        customIcon = { <ArrowDownVector /> }
        onCustomIconTap = { () => setReferOpened(true) }
      />
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Расстояние от кромки'
        selectedId = { distanceId ? `${distanceId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}


type Props = {
  variant: PieceEditVariants;
}
export const PieceEdit: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = pieceedit_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [submitErrorOpened, setSubmitErrorOpened] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>('');
  const [closeConfirmOpened, setCloseConfirmOpened] = React.useState<boolean>(false);
  const [forceBack, setForceBack] = React.useState<boolean>(false);

  const isOpened = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditOpened);
  const isDouble = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsDouble);
  const isPiece = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsPiece);
  const isLoadedFromQMET = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedIsLoadedFromQMET);
  const pieceGuid = useAppSelector(storeApi.inspection.edit.selectors.selectPieceEditedGuid) ?? '';
  const dialogTitle = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceEditDialogTitle(store, pieceGuid));
  const acceptText = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceEditDialogAcceptText(store, pieceGuid));

  const goBack = (force: boolean) => {
    setForceBack(force);
    setTimeout(() => navigate(-1), 100);
  }

  const onActionSubmitErrorHandler = () => {
    if (submitError === 'Изменения отсутствуют')
    {
      goBack(true);
    }
    else
    {
      dispatch(storeApi.inspection.edit.actions.pieceEditedIsDoubleChanged(false));
    }
    setSubmitErrorOpened(false);
    setSubmitError('');
  }

  const onSaveAcceptHandler = () => {
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true, isApplyEditedPiece: true })), 200);
    setCloseConfirmOpened(false);
    dispatch(storeApi.inspection.edit.actions.pieceEditClosed());
  }

  const onSaveCancelHandler = () => {
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
    setCloseConfirmOpened(false);
    dispatch(storeApi.inspection.edit.actions.pieceEditClosed());
  }

  const onSubmitHandler = () => {
    dispatch(storeApi.inspection.edit.async.addPieceAsync())
      .unwrap()
      .then((result) => {
        setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
        goBack(true);
      })
      .catch((error) => {
        setSubmitError(error);
        setSubmitErrorOpened(true);
      });
  }

  React.useEffect(() => {
    if (isOpened)
    {
      navigate('', { state: { isPopup: true } });
    }
  }, [isOpened, navigate])

  React.useEffect(() => {
    const backListener = (ev: PopStateEvent) => {
      ev.preventDefault();
      if (forceBack)
      {
        dispatch(storeApi.inspection.edit.actions.pieceEditClosed());
      }
      else
      {
        dispatch(storeApi.inspection.edit.async.closePieceAsync())
          .unwrap()
          .then((result) => {
            if (result.hasChanges)
            {
              setCloseConfirmOpened(true);
            }
            else
            {
              dispatch(storeApi.inspection.edit.actions.pieceEditClosed());
            }
          })
      }
    }
    window.addEventListener('popstate', backListener);

    return () => {
      window.removeEventListener('popstate', backListener);
    }
  }, [dispatch, forceBack]);

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { () => goBack(false) }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => goBack(false) }
          >
            <ArrowBackVector />
          </IconButton>
          <Typography variant = { lookAndFeel.headerTypographyVariant }>
            { dialogTitle }
          </Typography>
          <Grow size = { 2 } />
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => goBack(false) }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        <FormContainer lookAndFeel = { lookAndFeel }>
          <InputUEModeSwitcher lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          {!isPiece && (
            <React.Fragment>
              <QmetId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
              {isLoadedFromQMET && (
                <React.Fragment>
                  <Heat lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
                  <Piece lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
                  <QCNum lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {isPiece && (
            <React.Fragment>
              <Heat lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
              <Piece lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
              <GroupAtt lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
              <QCNum lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
            </React.Fragment>
          )}
          <CustomFields lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <IsMarriageShare lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <WeightBeforeProcessing lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <WeightAfterProcessing lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <MarriageShare lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <Weight lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <Note lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <Photo lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <IsTopography lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographyNote lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographySize lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographySizeId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographyStep lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographyStepId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographyDistance lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <TopographyDistanceId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <SubmitContainer lookAndFeel = { lookAndFeel }>
            <Button
              variant = { lookAndFeel.submitButtonVariant }
              onTap = { onSubmitHandler }
              label = { acceptText }
            />
          </SubmitContainer>
        </FormContainer>
        <TwoActionDialog 
          variant = { lookAndFeel.submitErrorDialogVariant } 
          opened = { submitErrorOpened || isDouble }
          caption = 'Ошибка'
          message = { isDouble ? 'Найден дубль позиции' : submitError }
          isDanger
          withoutCancel
          onAction = { onActionSubmitErrorHandler }
        />
        <TwoActionDialog 
          variant = { lookAndFeel.submitErrorDialogVariant } 
          opened = { closeConfirmOpened }
          caption = 'Сохранить информацию?'
          message = 'Позиция будет сохранена без проверки на обязательность заполнения полей'
          isDanger
          cancelLabel = 'Отмена'
          actionLabel = 'Понятно'
          onAction = { onSaveAcceptHandler }
          onCancel = { onSaveCancelHandler }
        />
      </Container>
      <CertDownloadDialog variant = { lookAndFeel.certDownloadDialogVariant } />
    </Drawer>
  );
};
