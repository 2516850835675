import React                     from 'react';

import { RouteObject           } from 'react-router-dom';

import { SelectCompanyScreen   } from 'src/containers/qmmsg/select-company';
import { ListScreen            } from 'src/containers/qmmsg/list';
import { DetailScreen          } from 'src/containers/qmmsg/detail';
import { QuestionHistoryScreen } from 'src/containers/qmmsg/question-history';

import { BaseUrl               } from 'src/providers/routing/base-url';
import { WithInitNavigate      } from 'src/providers/routing/with-init-navigate';



class SelectCompanyUrl extends BaseUrl {
  build(): SelectCompanyUrl {
    this._url = '/qmmsg/select-company';
    return this;
  }
}

class ListByEskUrl extends BaseUrl {
  build(from: string, eskCodes: string): ListByEskUrl {
    this._url = `/qmmsg/list-esk/${from}/${eskCodes}`;
    return this;
  }
}

class ListByInspectionUrl extends BaseUrl {
  build(from: string, guidInspection: string): ListByInspectionUrl {
    this._url = `/qmmsg/list-inspection/${from}/${guidInspection}`;
    return this;
  }
}

class DetailUrl extends BaseUrl {
  build(from: string, qmMsgId: number, isScrollBottom: boolean = false): DetailUrl {
    this._url = `/qmmsg/detail/${from}/${qmMsgId}${isScrollBottom ? '#scrollBottom' : ''}`;
    return this;
  }
}

class QuestionHistoryUrl extends BaseUrl {
  build(qmMsgId: number): QuestionHistoryUrl {
    this._url = `/qmmsg/question-history/${qmMsgId}`;
    return this;
  }
}


export const QMMsgUrls = {
  QMMsgSelectCompany: new SelectCompanyUrl('/qmmsg/select-company'),
  QMMsgListEsk: new ListByEskUrl('/qmmsg/list-esk/:from/:eskCodes'),
  QMMsgListInspection: new ListByInspectionUrl('/qmmsg/list-inspection/:from/:guidInspection'),
  QMMsgDetail: new DetailUrl('/qmmsg/detail/:from/:qmMsgId'),
  QMMsgQuestionHistory: new QuestionHistoryUrl('/qmmsg/question-history/:qmMsgId'),
}

export const QMMsgRoutes: RouteObject[] = [
  {
    path: QMMsgUrls.QMMsgSelectCompany.pattern,
    element: <WithInitNavigate isProtectedRoute><SelectCompanyScreen /></WithInitNavigate>
  },
  {
    path: QMMsgUrls.QMMsgListEsk.pattern,
    element: <WithInitNavigate isProtectedRoute><ListScreen /></WithInitNavigate>
  },
  {
    path: QMMsgUrls.QMMsgListInspection.pattern,
    element: <WithInitNavigate isProtectedRoute><ListScreen /></WithInitNavigate>
  },
  {
    path: QMMsgUrls.QMMsgDetail.pattern,
    element: <WithInitNavigate isProtectedRoute><DetailScreen /></WithInitNavigate>
  },
  {
    path: QMMsgUrls.QMMsgQuestionHistory.pattern,
    element: <WithInitNavigate isProtectedRoute><QuestionHistoryScreen /></WithInitNavigate>
  },
];
