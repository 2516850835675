import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexRow,
         themeColors        } from 'src/providers/theme/shared';



type NavBarLookAndFeel = {
  height: number;
  paddings: number[];

  buttonVariant: IconButtonVariants;
  logoColor: string;
  backgroundColor: string;
}

const navbar_light: NavBarLookAndFeel = {
  height: 56,
  paddings: [4],

  buttonVariant: 's48bluered',
  logoColor:  themeColors.PrimaryBlue100,
  backgroundColor: themeColors.BackgroundFillLight,
}

export type NavBarVariants = 'light';

export const navbar_variants: Map<NavBarVariants, NavBarLookAndFeel> = 
  new Map<NavBarVariants, NavBarLookAndFeel>([
    ['light', navbar_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: NavBarLookAndFeel
}>`
  width: 100%;
  height: ${(args) => args.lookAndFeel.height}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;

  ${cssFlexRow};
  align-items: flex-top;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const Logo = styled.span<{
  lookAndFeel: NavBarLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: center;
  flex-grow: 2;
  color: ${(args) => args.lookAndFeel.logoColor};
`
