import * as React               from 'react';

import { FavoriteVector       } from 'src/components/vector/favorite';

import { LabeledContent       } from 'src/components/common/labeled-content';

import { Status,
         StatusVariants       } from 'src/components/features/qmmsg/common/status';

import { Container,
         HeaderContainer,
         RedDot,
         HeaderLabel,
         PropertiesContainer, 
         PropertyLabel,
         ItemVariants,
         item_variants        } from 'src/components/features/qmmsg/list/item/ui';

import * as types               from 'src/services/api/types';



type Props = {
  variant: ItemVariants;
  item: types.qmmsg.QMMsg;
  isViewed: boolean;
  onClick: (item: types.qmmsg.QMMsg) => void;
}
export const Item: React.FC<Props> = ({
  variant,
  item,
  isViewed,
  onClick,
}) => {
  const lookAndFeel = item_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } onClick = { onClick.bind(null, item) }>
      <HeaderContainer lookAndFeel = { lookAndFeel } isFavorite = { item.isFavorite }>
        {!isViewed &&
          <RedDot lookAndFeel = { lookAndFeel } />
        }
        <HeaderLabel lookAndFeel = { lookAndFeel }>
          { `${item.signMsg === types.qmmsg.QMMsgSignMsgEnum.COMPLAINT ? 'Претензия' : 'Уведомление'} №${item.qmMsgNumber}` }
        </HeaderLabel>
        <FavoriteVector />
      </HeaderContainer>
      <Status variant = { getStatus(item) } label = { item.status.title } />
      <PropertiesContainer lookAndFeel = { lookAndFeel }>
        <LabeledContent variant = { lookAndFeel.propertiesLabeledVariant } label = 'Дата регистрации'>
          <PropertyLabel lookAndFeel = { lookAndFeel }>
            { new Date(item.timestampReg).toLocaleDateString() }
          </PropertyLabel>
        </LabeledContent>
        <LabeledContent variant = { lookAndFeel.propertiesLabeledVariant } label = 'Тип обращения'>
          <PropertyLabel lookAndFeel = { lookAndFeel }>
            { item.type.title }
          </PropertyLabel>
        </LabeledContent>
        <LabeledContent variant = { lookAndFeel.propertiesLabeledVariant } label = 'Количество брака'>
          <PropertyLabel lookAndFeel = { lookAndFeel }>
            { `${item.numberMarriage.quantity} ${item.numberMarriage.unit.toLowerCase()}` }
          </PropertyLabel>
        </LabeledContent>
      </PropertiesContainer>
    </Container>
  );
};

const getStatus = (item: types.qmmsg.QMMsg): StatusVariants => {
  switch (item.status.id) {
    case 1:
    case 5:
      return 'grayfill';
    case 0:
    case 2:
      return 'redoutline';
  }

  return 'blueoutline';
}
