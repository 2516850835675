import { createSlice,
         createAsyncThunk,
         createSelector,
         PayloadAction     } from '@reduxjs/toolkit';

import { v4 as uuidv4      } from 'uuid';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';
import Util                  from 'src/services/util';

import { RootState         } from 'src/store';

import { AsyncOpStatus, 
         Nullable          } from 'src/common';



const SLICE_NAME = 'main:messages';

export interface MainMessagesState {
  timestampBeg: Nullable<number>;
  timestampEnd: Nullable<number>;
  inspectionNum: string;
  topic: string;
  content: string;
  opStatus: AsyncOpStatus;
  opStatusLabel: string;
  list: types.message.Message[];
  addOpened: boolean;
  addConfirmOpened: boolean;
}

const initialState: MainMessagesState = {
  timestampBeg: Util.beginDay(new Date(Date.now() - 31 * 24 * 60 * 60 * 1000)).valueOf(),
  timestampEnd: Util.endDay(new Date()).valueOf(),
  inspectionNum: '',
  topic: '',
  content: '',
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',
  list: [],
  addOpened: false,
  addConfirmOpened: false,
}

// #region getMessagesListAsync
type GetMessagesListArgs = void;
type GetMessagesListResolve = types.message.Message[];
export type GetMessagesListReject = ApiError;
export const getMessagesListAsync = createAsyncThunk<
  GetMessagesListResolve,
  GetMessagesListArgs,
  {
    state: RootState,
    rejectValue: GetMessagesListReject
  }
>(
  `${SLICE_NAME}/getMessagesListAsync`,
  async (_, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainMessagesActions.opStatusLabelChanged('Загрузка списка сообщений'));
      const result = (await Api.getMessageList({
        timestampBeg: 1,
        timestampEnd: Date.now(),
      })).sort((a, b) => b.timestampMessage - a.timestampMessage);
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return result;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region addMessageAsync
type AddMessageArgs = void;
type AddMessageResolve = types.message.Message[];
export type AddMessageReject = ApiError;
export const addMessageAsync = createAsyncThunk<
  AddMessageResolve,
  AddMessageArgs,
  {
    state: RootState,
    rejectValue: AddMessageReject
  }
>(
  `${SLICE_NAME}/addMessageAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainMessagesActions.opStatusLabelChanged('Добавление нового сообщения'));

      const state = thunkAPI.getState();
      const inspectionNum = mainMessagesSelectors.selectInspectionNum(state);
      const topic = mainMessagesSelectors.selectTopic(state);
      const textMessage = mainMessagesSelectors.selectContent(state);

      await Api.postMessage({
        guid: uuidv4(),
        inspectionNum: inspectionNum === '' ? null : inspectionNum,
        topic: inspectionNum === '' ? topic : `Сообщение по осмотру ${inspectionNum}`,
        textMessage
      });
      const result = (await Api.getMessageList({
        timestampBeg: 1,
        timestampEnd: Date.now(),
      })).sort((a, b) => b.timestampMessage - a.timestampMessage);
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return result;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

// #region deleteMessageAsync
type DeleteMessageArgs = string;
type DeleteMessageResolve = string;
export type DeleteMessageReject = ApiError;
export const deleteMessageAsync = createAsyncThunk<
  DeleteMessageResolve,
  DeleteMessageArgs,
  {
    state: RootState,
    rejectValue: DeleteMessageReject
  }
>(
  `${SLICE_NAME}/deleteMessageAsync`,
  async (guid, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainMessagesActions.opStatusLabelChanged('Удаление сообщения'));
      await Api.deleteMessage({ guid });
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return guid;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainMessagesActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

type NunberChangedAction = PayloadAction<Nullable<number>>;
type BooleanChangedAction = PayloadAction<boolean>;
type StringChangedAction = PayloadAction<string>;
type AsyncOpStatusChangedAction = PayloadAction<AsyncOpStatus>;

export const mainMessagesSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    timestampBegChanged: (state, action: NunberChangedAction) => {
      state.timestampBeg = action.payload;
    },
    timestampEndChanged: (state, action: NunberChangedAction) => {
      state.timestampEnd = action.payload;
    },
    inspectionNumChanged: (state, action: StringChangedAction) => {
      state.inspectionNum = action.payload;
    },
    topicChanged: (state, action: StringChangedAction) => {
      state.topic = action.payload;
    },
    contentChanged: (state, action: StringChangedAction) => {
      state.content = action.payload;
    },
    opStatusChanged: (state, action: AsyncOpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: StringChangedAction) => {
      state.opStatusLabel = action.payload;
    },
    addOpenedChanged: (state, action: BooleanChangedAction) => {
      state.addOpened = action.payload;
    },
    addConfirmOpenedChanged: (state, action: BooleanChangedAction) => {
      state.addConfirmOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessagesListAsync.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(addMessageAsync.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(deleteMessageAsync.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => item.guid !== action.payload);
      })
  },
})

const selectTimestampBeg = (state: RootState) => state.mainMessages.timestampBeg;
const selectTimestampEnd = (state: RootState) => state.mainMessages.timestampEnd;
const selectInspectionNum = (state: RootState) => state.mainMessages.inspectionNum;
const selectTopic = (state: RootState) => state.mainMessages.topic;
const selectContent = (state: RootState) => state.mainMessages.content;
const selectOpStatus = (state: RootState) => state.mainMessages.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainMessages.opStatusLabel;
const selectAddOpened = (state: RootState) => state.mainMessages.addOpened;
const selectAddConfirmOpened = (state: RootState) => state.mainMessages.addConfirmOpened;
const selectList = (state: RootState) => state.mainMessages.list;
const selectListCount = (state: RootState) => state.mainMessages.list.length;
const selectFilteredList = createSelector(
  [selectTimestampBeg, selectTimestampEnd, selectInspectionNum, selectList],
  (timestampBeg, timestampEnd, inspectionNum, list) => {
    return inspectionNum === '' ?
      list.filter(
        (item) => (item.timestampMessage >= (timestampBeg === null ? 0 : timestampBeg.valueOf()))
                  &&
                  (item.timestampMessage <= (timestampEnd === null ? Date.now() : timestampEnd.valueOf()))
      )
      :
      list.filter((item) => item.inspectionNum === inspectionNum)
  }
);

export const mainMessagesSelectors = {
  selectTimestampBeg,
  selectTimestampEnd,
  selectInspectionNum,
  selectTopic,
  selectContent,
  selectOpStatus,
  selectOpStatusLabel,
  selectAddOpened,
  selectAddConfirmOpened,
  selectList,
  selectListCount,
  selectFilteredList,
}

export const mainMessagesActions = mainMessagesSlice.actions;

export default mainMessagesSlice.reducer;