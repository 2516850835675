import { createSlice,
         createAsyncThunk,
         PayloadAction,
         createSelector      } from '@reduxjs/toolkit';

import Api,
       { ApiError            } from 'src/services/api';
import * as types              from 'src/services/api/types';
import Util                    from 'src/services/util';

import { RootState           } from 'src/store';
import { authCommonSelectors } from 'src/store/auth/common';

import { isPost,
         isPassword,
         isPhone,
         isEmail,
         isName,
         isPatronymic,
         isSurname           } from 'src/validators';



const SLICE_NAME = 'auth:reg-enter-data';

export interface AuthRegEnterDataState {
  surname: string;
  surnameError: string;
  name: string;
  nameError: string;
  patronymic: string;
  patronymicError: string;
  post: string;
  postError: string;
  phone: string;
  phoneError: string;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  passwordRepeat: string;
  passwordRepeatError: string;
}

const initialState: AuthRegEnterDataState = {
  surname: '',
  surnameError: '',
  name: '',
  nameError: '',
  patronymic: '',
  patronymicError: '',
  post: '',
  postError: '',
  phone: '',
  phoneError: '',
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  passwordRepeat: '',
  passwordRepeatError: '',
}

// #region checkRegisterDataAsync
type CheckRegisterDataArgs = void;
type CheckRegisterDataResolve = void;
type CheckRegisterDataReject = {
  surnameError?: string;
  nameError?: string;
  patronymicError?: string;
  postError?: string;
  phoneError?: string;
  emailError?: string;
  passwordError?: string;
  passwordRepeatError?: string;
};
export const checkRegisterDataAsync = createAsyncThunk<
  CheckRegisterDataResolve,
  CheckRegisterDataArgs,
  {
    state: RootState,
    rejectValue: CheckRegisterDataReject
  }
>(
  `${SLICE_NAME}/checkRegisterDataAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const surnameError = isSurname(selectSurname(state)).error ?? '';
    const nameError = isName(selectName(state)).error ?? '';
    const patronymicError = isPatronymic(selectPatronymic(state), true).error ?? '';
    const postError = isPost(selectPost(state)).error ?? '';
    const phoneError = isPhone(selectPhone(state)).error ?? '';
    const emailError = isEmail(selectEmail(state)).error ?? '';
    const passwordError = isPassword(selectPassword(state)).error ?? '';
    const passwordRepeatError = selectPassword(state).trim() !== selectPasswordRepeat(state).trim() ? 'Пароли не совпадают' : '';
    const hasErrors = [surnameError, nameError, patronymicError, postError, phoneError, emailError, passwordError, passwordRepeatError]
      .some((value) => value.length > 0);

    if (hasErrors)
    {
      return thunkAPI.rejectWithValue({
        ...(surnameError.length > 0 ? { surnameError } : {}),
        ...(nameError.length > 0 ? { nameError } : {}),
        ...(patronymicError.length > 0 ? { patronymicError } : {}),
        ...(postError.length > 0 ? { postError } : {}),
        ...(phoneError.length > 0 ? { phoneError } : {}),
        ...(emailError.length > 0 ? { emailError } : {}),
        ...(passwordError.length > 0 ? { passwordError } : {}),
        ...(passwordRepeatError.length > 0 ? { passwordRepeatError } : {}),
      });
    }
  }
);
// #endregion

// #region registerCompleteAsync
type RegisterCompleteArgs = void;
type RegisterCompleteResolve = types.auth.AccountWithTokens;
export type RegisterCompleteReject = ApiError;
export const registerCompleteAsync = createAsyncThunk<
  RegisterCompleteResolve,
  RegisterCompleteArgs,
  {
    state: RootState,
    rejectValue: RegisterCompleteReject
  }
>(
  `${SLICE_NAME}/registerCompleteAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const authKey = authCommonSelectors.selectAuthKey(state);
    const firstName = selectName(state).trim();
    const lastName = selectSurname(state).trim();
    const middleName = selectPatronymic(state).trim();
    const post = selectPost(state).trim();
    const email = selectEmail(state).trim();
    const password = selectPassword(state).trim();

    try
    {
      return await Api.postAuthRegistration({
        authKey,
        firstName,
        lastName,
        middleName,
        post,
        email,
        password,
        pushToken: '',
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region copyPhoneFromRegStartAsync
type CopyPhoneFromRegStartArgs = void;
type CopyPhoneFromRegStartResolve = void;
export type CopyPhoneFromRegStartReject = void;
export const copyPhoneFromRegStartAsync = createAsyncThunk<
  CopyPhoneFromRegStartResolve,
  CopyPhoneFromRegStartArgs,
  {
    state: RootState,
    rejectValue: CopyPhoneFromRegStartReject
  }
>(
  `${SLICE_NAME}/copyPhoneFromRegStartAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(authRegEnterDataActions.phoneChanged(thunkAPI.getState().authRegStart.phone));
  }
);
// #endregion

type SurnameChangedAction = PayloadAction<string>;
type NameChangedAction = PayloadAction<string>;
type PatronymicChangedAction = PayloadAction<string>;
type PostChangedAction = PayloadAction<string>;
type PhoneChangedAction = PayloadAction<string>;
type EmailChangedAction = PayloadAction<string>;
type PasswordChangedAction = PayloadAction<string>;
type PasswordRepeatChangedAction = PayloadAction<string>;

export const authRegEnterDataSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    surnameChanged: (state, action: SurnameChangedAction) => {
      state.surname = action.payload;
    },
    surnameErrorFixed: (state) => {
      state.surnameError = '';
    },
    nameChanged: (state, action: NameChangedAction) => {
      state.name = action.payload;
    },
    nameErrorFixed: (state) => {
      state.nameError = '';
    },
    patronymicChanged: (state, action: PatronymicChangedAction) => {
      state.patronymic = action.payload;
    },
    patronymicErrorFixed: (state) => {
      state.patronymicError = '';
    },
    postChanged: (state, action: PostChangedAction) => {
      state.post = action.payload;
    },
    postErrorFixed: (state) => {
      state.postError = '';
    },
    phoneChanged: (state, action: PhoneChangedAction) => {
      state.phone = action.payload;
    },
    phoneErrorFixed: (state) => {
      state.phoneError = '';
    },
    emailChanged: (state, action: EmailChangedAction) => {
      state.email = action.payload;
    },
    emailErrorFixed: (state) => {
      state.emailError = '';
    },
    passwordChanged: (state, action: PasswordChangedAction) => {
      state.password = action.payload;
    },
    passwordErrorFixed: (state) => {
      state.passwordError = '';
    },
    passwordRepeatChanged: (state, action: PasswordRepeatChangedAction) => {
      state.passwordRepeat = action.payload;
    },
    passwordRepeatErrorFixed: (state) => {
      state.passwordRepeatError = '';
    },
    reset: (state) => {
      state.surname = '';
      state.surnameError = '';
      state.name = '';
      state.nameError = '';
      state.patronymic = '';
      state.patronymicError = '';
      state.post = '';
      state.postError = '';
      state.phone = '';
      state.phoneError = '';
      state.email = '';
      state.emailError = '';
      state.password = '';
      state.passwordError = '';
      state.passwordRepeat = '';
      state.passwordRepeatError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkRegisterDataAsync.rejected, (state, action) => {
        state.surnameError = action.payload?.surnameError ?? '';
        state.nameError = action.payload?.nameError ?? '';
        state.patronymicError = action.payload?.patronymicError ?? '';
        state.postError = action.payload?.postError ?? '';
        state.phoneError = action.payload?.phoneError ?? '';
        state.emailError = action.payload?.emailError ?? '';
        state.passwordError = action.payload?.passwordError ?? '';
        state.passwordRepeatError = action.payload?.passwordRepeatError ?? '';
      });
  },
})

const selectSurname = (state: RootState) => state.authRegEnterData.surname;
const selectSurnameError = (state: RootState) => state.authRegEnterData.surnameError;
const selectName = (state: RootState) => state.authRegEnterData.name;
const selectNameError = (state: RootState) => state.authRegEnterData.nameError;
const selectPatronymic = (state: RootState) => state.authRegEnterData.patronymic;
const selectPatronymicError = (state: RootState) => state.authRegEnterData.patronymicError;
const selectPost = (state: RootState) => state.authRegEnterData.post;
const selectPostError = (state: RootState) => state.authRegEnterData.postError;
const selectPhone = (state: RootState) => state.authRegEnterData.phone;
const selectPhoneError = (state: RootState) => state.authRegEnterData.phoneError;
const selectEmail = (state: RootState) => state.authRegEnterData.email;
const selectEmailError = (state: RootState) => state.authRegEnterData.emailError;
const selectPassword = (state: RootState) => state.authRegEnterData.password;
const selectPasswordError = (state: RootState) => state.authRegEnterData.passwordError;
const selectPasswordRepeat = (state: RootState) => state.authRegEnterData.passwordRepeat;
const selectPasswordRepeatError = (state: RootState) => state.authRegEnterData.passwordRepeatError;
const selectFormFilled = createSelector(
  [selectSurname, selectName, selectPost, selectPhone, selectEmail, selectPassword, selectPasswordRepeat],
  (surname, name, post, phone, email, password, passwordRepeat) => {
    return (
      surname.trim().length > 0
      && 
      name.trim().length > 0
      && 
      post.trim().length > 0
      &&
      Util.selectDigitsFromString(phone).length > 1
      && 
      email.trim().length > 0
      &&
      password.trim().length > 0
      &&
      passwordRepeat.trim().length > 0
    )
  }
);

export const authRegEnterDataSelectors = {
  selectSurname,
  selectSurnameError,
  selectName,
  selectNameError,
  selectPatronymic,
  selectPatronymicError,
  selectPost,
  selectPostError,
  selectPhone,
  selectPhoneError,
  selectEmail,
  selectEmailError,
  selectPassword,
  selectPasswordError,
  selectPasswordRepeat,
  selectPasswordRepeatError,
  selectFormFilled,
}

export const authRegEnterDataActions = authRegEnterDataSlice.actions;

export default authRegEnterDataSlice.reducer;