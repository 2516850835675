import styled                   from 'styled-components';

import { IconButtonVariants   } from "src/components/common/icon-button";
import { InputTextboxVariants } from "src/components/common/input/input-textbox";

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow,   
         cssFlexColumn        } from 'src/providers/theme/shared';



type RollLookAndFeel = {
  paddings: number[];
  topGap: number;
  inputLabelGap: number;
  inputGap: number;

  primaryHeaderTypo: Typo;
  commonHeaderTypo: Typo;
  rollNumberTypo: Typo;

  headerColor: string;
  rollNumberColor: string;
  asteriskColor: string;
  circleQuestionColor: string;

  trashButtonVariant: IconButtonVariants;
  textboxVariant: InputTextboxVariants;
}

const roll_light: RollLookAndFeel = {
  paddings: [20, 0],
  topGap: 20,
  inputLabelGap: 3,
  inputGap: 8,

  primaryHeaderTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  commonHeaderTypo: themeTypos.GRTSKREG_W400_S16_LH20,
  rollNumberTypo: themeTypos.GRTSKREG_W400_S14_LH16,

  headerColor: themeColors.PrimaryBlack,
  rollNumberColor: themeColors.AccentBlue1,
  asteriskColor: themeColors.AccentRed1,
  circleQuestionColor: themeColors.SecondaryGray4,
  
  trashButtonVariant: 's36redblue',
  textboxVariant: 'h48light',
}

export type RollVariants = 'light';

export const roll_variants: Map<RollVariants, RollLookAndFeel> = 
  new Map<RollVariants, RollLookAndFeel>([
    ['light', roll_light],
  ]);

export const RollContainer = styled.div<{
  lookAndFeel: RollLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.topGap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`;

export const RollHeader = styled.div<{
  lookAndFeel: RollLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  justify-content: space-between;
`;

export const RollHeaderLabel = styled.span<{
  lookAndFeel: RollLookAndFeel,
  isPrimary: boolean,
}>`
  ${(args) => typoAsCSS(args.isPrimary ? args.lookAndFeel.primaryHeaderTypo : args.lookAndFeel.commonHeaderTypo)};
  color: ${(args) => args.lookAndFeel.headerColor};
`;

export const RollInput = styled.div<{
  lookAndFeel: RollLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.inputGap}px;

  & .circle-question {
    color: ${(args) => args.lookAndFeel.circleQuestionColor};
  }
`;

export const RollInputLabel = styled.div<{
  lookAndFeel: RollLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.inputLabelGap}px;

  ${(args) => typoAsCSS(args.lookAndFeel.rollNumberTypo)};
  color: ${(args) => args.lookAndFeel.rollNumberColor};
`;

export const RollInputLabelAsterisk = styled.div<{
  lookAndFeel: RollLookAndFeel
}>`
  color: ${(args) => args.lookAndFeel.asteriskColor};
`;
