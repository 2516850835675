import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type InputTextboxLookAndFeel = {
  prefixTypo: Typo;
  prefixNormalColor: string;
  prefixDisabledColor: string;
  prefixIconSize: number;

  typo: Typo;
  normalColor: string;
  disabledColor: string;

  placeholderColor: string;
};

const inputtextbox_h48light: InputTextboxLookAndFeel = {
  prefixTypo: themeTypos.GRTSKREG_W400_S15_LH20,
  prefixNormalColor: themeColors.SecondaryGray5,
  prefixDisabledColor: themeColors.SecondaryGray4,
  prefixIconSize: 24,

  typo: themeTypos.GRTSKREG_W400_S15_LH20,
  normalColor: themeColors.PrimaryBlack,
  disabledColor: themeColors.SecondaryGray9,

  placeholderColor: themeColors.SecondaryGray4,
};

export type InputTextboxVariants = 'h48light';

export const inputtextbox_variants: Map<InputTextboxVariants, InputTextboxLookAndFeel> = 
  new Map<InputTextboxVariants, InputTextboxLookAndFeel>([
    ['h48light', inputtextbox_h48light],
  ]);

export const Input = styled.input<{
  isDisabled: boolean;
  lookAndFeel: InputTextboxLookAndFeel;
}>`
  width: 1px;
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.isDisabled ? args.lookAndFeel.disabledColor : args.lookAndFeel.normalColor};

  &::placeholder {
    color: ${(args) => args.lookAndFeel.placeholderColor};
  }
`;

export const Prefix = styled.span<{
  isDisabled: boolean;
  lookAndFeel: InputTextboxLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.prefixTypo)}
  color: ${(args) => args.isDisabled ? args.lookAndFeel.prefixDisabledColor : args.lookAndFeel.prefixNormalColor};
`;

export const IconPrefix = styled.span<{
  isDisabled: boolean;
  lookAndFeel: InputTextboxLookAndFeel;
}>`
  ${cssFlexRow};
  height: 100%;
  align-items: center;

  & > svg {
    width: ${(args) => args.lookAndFeel.prefixIconSize}px;
    height: ${(args) => args.lookAndFeel.prefixIconSize}px;
  }
`;
