import * as React                     from 'react';

import { Treebeard,
         TreeNode,
         decorators,
         theme as treeTheme         } from "react-treebeard";

import { EmptyCaseLoupeVector       } from 'src/components/vector/empty-case/loupe';

import { IconButton                 } from 'src/components/common/icon-button';
import { EmptyCase                  } from 'src/components/common/empty-case';
import { HeaderContainer,
         TreeContainer,
         NodeLabel,
         TreeLookAndFeel,
         TreeMinusVector,
         TreePlusVector,
         TreeStarFavoriteVector,
         TreeStarNotFavoriteVector, 
         TreeVariants,
         tree_variants              } from 'src/components/common/tree/ui';



const Toggle = () => <div />;
const Loading = () => <div />;

type HeaderProps = {
  onSelect: (node: TreeNode) => void;
  node: TreeNode;
  lookAndFeel: TreeLookAndFeel;
  onFavoriteToggle: (node: TreeNode) => void;
};

export const Header: React.FC<HeaderProps> = ({
  onSelect,
  node,
  lookAndFeel,
  onFavoriteToggle,
}) => {
  return node.hidden ? null : (
    <HeaderContainer
      lookAndFeel = { lookAndFeel }
      isTopLevel = { node.isTopLevel }
      isTopLevelFirst = { node.isTopLevelFirst }
    >
      {node.children !== undefined && node.toggled && (
        <TreeMinusVector lookAndFeel = { lookAndFeel } />
      )}
      {node.children !== undefined && !node.toggled && (
        <TreePlusVector lookAndFeel = { lookAndFeel } />
      )}
      <NodeLabel
        lookAndFeel = { lookAndFeel }
        dangerouslySetInnerHTML = { { __html: node.name} }
        onClick = { () => { onSelect(node) } }
      />
      {node.children === undefined && node.isFavorite && (
        <IconButton
          variant = { lookAndFeel.treeFavoriteIconButtonVariant }
          isTapAllowed
          onTap = { () => { onFavoriteToggle(node) } }
        >
          <TreeStarFavoriteVector lookAndFeel = { lookAndFeel } />
        </IconButton>
      )}
      {node.children === undefined && !node.isFavorite && (
        <IconButton
          variant = { lookAndFeel.treeFavoriteIconButtonVariant }
          isTapAllowed
          onTap = { () => { onFavoriteToggle(node) } }
        >
          <TreeStarNotFavoriteVector lookAndFeel = { lookAndFeel } />
        </IconButton>
      )}
    </HeaderContainer>
  );
};

type Action = (node: TreeNode, path: TreeNode[]) => void;
export const walkTree = (node: TreeNode, path: TreeNode[], action: Action) => {
  action(node, path);

  if (node.children)
  {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < node.children.length; i++)
    {
      walkTree(node.children[i], path.concat([node]), action);
    }
  }
};

type Props = {
  variant: TreeVariants;
  data: TreeNode[];
  isFavoriteMode: boolean;
  isSearchNotEmpty: boolean;
  onToggle: (node: TreeNode, toggled: boolean) => void;
  onFavoriteToggle: (node: TreeNode) => void;
  onSelect: (node: TreeNode) => void;
};

export const Tree: React.FC<Props> = ({
  variant,
  data,
  isFavoriteMode,
  isSearchNotEmpty,
  onToggle,
  onFavoriteToggle,
  onSelect,
}) => {
  const lookAndFeel = tree_variants.get(variant)!;
  const emptyCase = data.length > 0 && data.every((node) => node.hidden);
  
  return (
    <TreeContainer lookAndFeel = { lookAndFeel }>
      {emptyCase && (
        <EmptyCase
          variant = { lookAndFeel.treeEmptyCaseVariant } 
          icon = { <EmptyCaseLoupeVector /> }
          header = { isFavoriteMode && !isSearchNotEmpty ? 'Раздел Избранное пуст' : 'Ничего не найдено' }
          message = { isFavoriteMode && !isSearchNotEmpty ? 'Вы еще ничего не добавили в избранное' : 'Попробуйте изменить поисковый запрос' }
        />
      )}
      {!emptyCase && (
        <Treebeard
          data = { data }
          onToggle = { onToggle }
          style = { {
            ...treeTheme,
            tree: {
              ...treeTheme.tree,
              base: {
                listStyle: 'none',
                backgroundColor: `${lookAndFeel.treeBackgroundColor}`,
              },
              node: {
                ...treeTheme.tree.node,
                subtree: {
                  paddingLeft: `${lookAndFeel.treeSubtreePaddings[3]}px`,
                  listStyle: 'none',
                }
              },
            },
          }}
          decorators = { {
            ...decorators,
            Toggle,
            Header: (props) => (
              <Header
                lookAndFeel = { lookAndFeel }
                onFavoriteToggle = { onFavoriteToggle }
                onSelect = { onSelect }
                { ...props }
              />
            ),
            Loading,
          }}
        />
      )}
    </TreeContainer>
  );
};

