import * as React               from 'react';

import { ArrowDownVector      } from 'src/components/vector/arrow-down';
import { ArrowUpVector        } from 'src/components/vector/arrow-up';

import { IconButton           } from 'src/components/common/icon-button';
import { Typography           } from 'src/components/common/typography';

import { StepElement          } from 'src/components/features/qmmsg/detail/step-element';

import { Wrapper,
         Container,
         Steps,
         ExpandButtonWrapper, 
         StepFlowVariants,
         stepflow_variants    } from 'src/components/features/qmmsg/detail/step-flow/ui';

import * as types               from 'src/services/api/types';



type Step = {
  id: number;
  header: string;
  content?: string;
  isActive: boolean;
  isLast: boolean;
}

type Props = {
  variant: StepFlowVariants;
  claim: types.qmmsg.QMMsg;
  steps: types.refers.StepQMReferElement[];
}
export const StepFlow: React.FC<Props> = ({
  variant,
  claim,
  steps,
}) => {
  const lookAndFeel = stepflow_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const stepElements = React.useMemo(() => {
    const workCopy = [...steps];

    workCopy.sort((a, b) => a.id === b.id ? 0 : a.id - b.id);

    const result: Step[] = [];

    let n = 1;
    let cnt = 0;
    let nResponce = 0;
    
    workCopy.forEach((item) => {
      if (item.id > 0 && ((item.code === 'RESPONSE' && nResponce === 0) || item.code !== 'RESPONSE'))
      {
        if (item.code !== 'RESPONSE')
        {
          cnt += 1;
        }
        else
        {
          if (
            claim.step.id < item.id ||
            (item.subcode === 'ВРАБ' && claim.status.id === 1) ||
            (item.subcode === 'ОТКЛ' && claim.status.id === 2) ||
            (item.subcode === 'ПРЗН' && claim.status.id === 3) ||
            (item.subcode === 'ПРЧС' && claim.status.id === 4) ||
            (item.subcode === 'ОТЛЖ' && claim.status.id === 5) ||
            (item.subcode === 'ПДВД' && claim.status.id === 6) ||
            (item.subcode === 'ОТЗВ' && claim.status.id === 7)
          )
          {
            cnt += 1;
            nResponce = 1;
          }
        }
      }
    });

    let bAddItem = false;
    nResponce = 0;
    workCopy.forEach((item) => {
        bAddItem = false;

        if (item.id > 0 && ((item.code === 'RESPONSE' && nResponce === 0) || item.code !== 'RESPONSE'))
        {
          if (item.code !== 'RESPONSE')
          {
              bAddItem = true;
          }
          else
          {
            if (
              claim.step.id < item.id ||
              (item.subcode === 'ВРАБ' && claim.status.id === 1) ||
              (item.subcode === 'ОТКЛ' && claim.status.id === 2) ||
              (item.subcode === 'ПРЗН' && claim.status.id === 3) ||
              (item.subcode === 'ПРЧС' && claim.status.id === 4) ||
              (item.subcode === 'ОТЛЖ' && claim.status.id === 5) ||
              (item.subcode === 'ПДВД' && claim.status.id === 6) ||
              (item.subcode === 'ОТЗВ' && claim.status.id === 7)
            )
            {
              bAddItem = true;
              nResponce = 1;
            }
          }

          if (bAddItem)
          {
            result.push({
              id: item.id,
              header: item.title,
              content: claim.step.id >= item.id && expanded ? item.description : undefined,
              isActive: claim.step.id >= item.id,
              isLast: n === cnt
            });
            n += 1;
          }
        }
    })

    return result;
  }, [claim, steps, expanded]);


  return (
    <Wrapper lookAndFeel = { lookAndFeel }>
      <Container lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.headerTypographyVariant }>Ход рассмотрения</Typography>
        <Steps lookAndFeel = { lookAndFeel }>
          {stepElements.map((item) => (
            <StepElement
              key = { item.id }
              variant = { lookAndFeel.stepElementVariant }
              header = { item.header }
              content = { item.content }
              isActive = { item.isActive }
              isLast = { item.isLast }
            />
          ))}
          
        </Steps>
      </Container>
      <ExpandButtonWrapper lookAndFeel = { lookAndFeel }>
        <IconButton
          variant = { lookAndFeel.expandButtonVariant }
          isTapAllowed
          onTap = { () => setExpanded(!expanded) }
        >
          { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
        </IconButton>
      </ExpandButtonWrapper>
    </Wrapper>
  );
};
