import styled                 from 'styled-components';

import { CloseVector        } from 'src/components/vector/close';

import { IconButtonVariants } from 'src/components/common/icon-button';

import { MessageVariants    } from 'src/components/features/main/messages/message';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn      } from 'src/providers/theme/shared';



type MessageDeleteGuideLookAndFeel = {
  backColor: string;
  backOpacity: number;
  backZIndex: number;

  headerTypo: Typo;
  headerColor: string;

  trashColor: string;
  trashSize: number;

  padding: number;
  paddingClose: number;

  iconButtonVariant: IconButtonVariants;

  messageVariant: MessageVariants;

  noMoreShowHeight: number;
  noMoreShowRadius: number;
  noMoreShowBgColor: string;
  noMoreShowTypo: Typo;
  noMoreShowColor: string;
}

const messagedeleteguide_light: MessageDeleteGuideLookAndFeel = {
  backColor: themeColors.AccentBlue1,
  backOpacity: 0.5,
  backZIndex: 1300,

  headerTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  headerColor: themeColors.PrimaryWhite,

  trashColor: themeColors.AccentRed1,
  trashSize: 100,

  padding: 24,
  paddingClose: 10,

  iconButtonVariant: 's48whitered',

  messageVariant: 'light',

  noMoreShowHeight: 32,
  noMoreShowRadius: 43,
  noMoreShowBgColor: themeColors.PrimaryWhite,
  noMoreShowTypo: themeTypos.GRTSKREG_W400_S11_LH14,
  noMoreShowColor: themeColors.AccentBlue1,
}

export type MessageDeleteGuideVariants = 'light';

export const messagedeleteguide_variants: Map<MessageDeleteGuideVariants, MessageDeleteGuideLookAndFeel> = 
  new Map<MessageDeleteGuideVariants, MessageDeleteGuideLookAndFeel>([
    ['light', messagedeleteguide_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  width: 100vw;
  height: 100dvh;
  position: fixed;

  overflow: hidden;
`

export const HalfCircle = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  width: 140vw;
  height: 140vw;

  top: calc((100% - 140vw) / 2);
  left: calc(100% - 140vw / 2);

  position: absolute;

  background-color: ${(args) => args.lookAndFeel.backColor};
  border-radius: 50%;
`

export const Backdrop = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${(args) => args.lookAndFeel.backColor};
  opacity: ${(args) => args.lookAndFeel.backOpacity};
`

export const Content = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${cssFlexColumn};
`

export const Header = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${cssFlexColumn};
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 2;

  padding: ${(args) => args.lookAndFeel.padding}px;
  padding-right: 0;
`

export const HeaderClose = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  padding-right: ${(args) => args.lookAndFeel.paddingClose}px;
`

export const HeaderTrash = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;
  width: ${(args) => args.lookAndFeel.trashSize}px;
  height: 100%;

  background-color: ${(args) => args.lookAndFeel.trashColor};

  & svg {
    color: ${(args) => args.lookAndFeel.headerColor};
  }
`

export const HeaderLabel = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)}
  color: ${(args) => args.lookAndFeel.headerColor};
  text-align: right;

  padding-right: ${(args) => args.lookAndFeel.padding}px;
`

export const Guide = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: flex-end;
  align-items: flex-end;
`

export const NoMoreShow = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 2;

  padding: ${(args) => args.lookAndFeel.padding}px;
`

export const NoMoreShowButton = styled.div<{
  lookAndFeel: MessageDeleteGuideLookAndFeel,
}>`
  ${cssFlexRow};
  height: ${(args) => args.lookAndFeel.noMoreShowHeight}px;
  justify-content: center;
  align-items: center;
  
  border-radius: ${(args) => args.lookAndFeel.noMoreShowRadius}px;
  background-color: ${(args) => args.lookAndFeel.noMoreShowBgColor};

  ${(args) => typoAsCSS(args.lookAndFeel.noMoreShowTypo)}
  color: ${(args) => args.lookAndFeel.noMoreShowColor};

  padding-left: ${(args) => args.lookAndFeel.padding}px;
  padding-right: ${(args) => args.lookAndFeel.padding}px;

  cursor: pointer;
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;