import * as React          from 'react';

import { ReportVector    } from 'src/components/vector/report';
import { FilterVector    } from 'src/components/vector/filter';
import { RefreshVector   } from 'src/components/vector/refresh';

import { IconButton      } from 'src/components/common/icon-button';
import { Typography      } from 'src/components/common/typography';
import { Loading         } from 'src/components/common/loading';

import { Container,
         HeaderVariants,
         header_variants } from 'src/components/features/main/inspection-list/header/ui';



type Props = {
  variant: HeaderVariants;
  isFiltersNotEmpty: boolean;
  isReportBusy: boolean;
  onReport: () => void;
  onFilter: () => void;
  onRefresh: () => void;
}
export const Header: React.FC<Props> = ({
  variant,
  isFiltersNotEmpty,
  isReportBusy,
  onReport,
  onFilter,
  onRefresh,
}) => {
  const lookAndFeel = header_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } id = 'filter-container'>
      <Typography variant = { lookAndFeel.labelTypographyVariant }>Осмотры</Typography>
      <IconButton
        variant = { lookAndFeel.iconButtonVariant }
        isTapAllowed
        onTap = { onRefresh }
      >
        <RefreshVector />
      </IconButton>
      <IconButton
        variant = { lookAndFeel.iconButtonVariant }
        isTapAllowed
        onTap = { () => !isReportBusy && onReport() }
      >
        { isReportBusy ? <Loading variant = { lookAndFeel.loadingVariant } /> :<ReportVector /> }
      </IconButton>
      <IconButton
        variant = { lookAndFeel.iconButtonVariant }
        isTapAllowed
        isDot = { isFiltersNotEmpty }
        onTap = { onFilter }
      >
        <FilterVector />
      </IconButton>
    </Container>
  );
};
