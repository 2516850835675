import styled           from 'styled-components';

import { themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type WelcomeLookAndFeel = {
  typo: Typo;
  color: string;
}

const welcome_light: WelcomeLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH28,
  color: themeColors.PrimaryBlack,
}

export type WelcomeVariants = 'light';

export const welcome_variants: Map<WelcomeVariants, WelcomeLookAndFeel> = 
  new Map<WelcomeVariants, WelcomeLookAndFeel>([
    ['light', welcome_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: WelcomeLookAndFeel,
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.color};
`
