export default class Constants {
  static APP_VERSION = '1.6.0';
  static API_VERSION = 'v2';
  static API_HOST = `${process.env && process.env.REACT_APP_API_HOST}`.endsWith('/') ?
    `${process.env && process.env.REACT_APP_API_HOST}`
    :
    `${process.env && process.env.REACT_APP_API_HOST}/`;
    
  static SMS_CODE_FREQUENCY = 60;
  static SMS_CODE_LENGTH = 6;
  static PWD_MIN_LENGTH = 8;
  static NOTIFICATION_LOAD_FREQUENCY = 300;
  static REFER_FULL_HEIGHT_COUNT = 11;
  static APP_RATING_REPEAT_UNTIL_SUCCESS = 60 * 60 * 24 * 30;
  static APP_RATING_REPEAT_UNTIL_FAIL = 60 * 60 * 24 * 10;
  static REST_TOKEN_RENEW_PERIOD_MS = 14 * 24 * 60 * 60 * 1000;
  static PIECE_NUM_ID_LENGTH_MIN = 9;
  static PIECE_NUM_ID_LENGTH_MAX = 11;
  static PIECE_IMAGE_BIGGEST_SIZE = 1024;
  static PIECE_PDF_BIGGEST_SIZE_MB = 5;
  static INSPECTIONS_PAGE_SIZE = 10;
  static QMMSG_PAGE_SIZE = 30;
  static SHADE_SELECTION_ROLL_DIGITS = 8;
}