import styled                         from 'styled-components';

import { BlockUIVariants            } from 'src/components/common/block-ui';
import { InputCalendarVariants      } from 'src/components/common/input/input-calendar';
import { EmptyCaseVariants          } from 'src/components/common/empty-case';
import { FABVariants                } from 'src/components/common/fab';
import { DialogVariants             } from 'src/components/common/dialog';
import { OneActionDialogVariants    } from 'src/components/common/one-action-dialog';

import { NavBarVariants             } from 'src/components/features/common/nav-bar';
import { ToolbarVariants            } from 'src/components/features/main/common/toolbar';

import { MessageVariants            } from 'src/components/features/main/messages/message';
import { AddMessageVariants         } from 'src/components/features/main/messages/add-message';
import { MessageDeleteGuideVariants } from 'src/components/features/main/messages/message-delete-guide';

import { themeColors, 
         cssFlexColumn,   
         cssFlexRow                 } from 'src/providers/theme/shared';



export type MessagesLayout = {
  backgroundColor: string;

  paddings: number[];
  datesGap: number;
  filterGap: number;
  itemsGap: number;

  navbarVariant: NavBarVariants;

  emptyCaseVariant: EmptyCaseVariants;
  blockuiVariant: BlockUIVariants;

  messageVariant: MessageVariants;
  inputCalendarVariant: InputCalendarVariants;
  toolbarVariant: ToolbarVariants;

  fabVariant: FABVariants;
  fabBottom: number;
  fabRight: number;

  addMessageVariant: AddMessageVariants;
  dialogVariant: DialogVariants;
  oneActionDialogVariant: OneActionDialogVariants;

  messageDeleteGuideVariant: MessageDeleteGuideVariants;
}

export const layout_light: MessagesLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],
  datesGap: 16,
  filterGap: 24,
  itemsGap: 8,

  navbarVariant: 'lightblue',

  emptyCaseVariant: 'light',
  blockuiVariant: 'light',

  messageVariant: 'light',
  inputCalendarVariant: 'h48light',
  toolbarVariant: 'light',

  fabVariant: 's56blue',
  fabBottom: 96,
  fabRight: 16,

  addMessageVariant: 'light',
  dialogVariant: 'light',
  oneActionDialogVariant: 'light',

  messageDeleteGuideVariant: 'light',
}

export const layout_dark: MessagesLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: MessagesLayout,
}>`
  width: 100vw;
  height: 100dvh;
  position: relative;

  ${cssFlexColumn};
  
  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: MessagesLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  gap: ${(args) => args.layout.filterGap}px;
  padding: ${(args) => args.layout.paddings[0]}px;

  overflow-y: scroll;
`

export const FilterContainer = styled.div<{
  layout: MessagesLayout,
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.layout.datesGap}px;

  & > div {
    flex-grow: 1;
  }
`

export const MessagesContainer = styled.div<{
  layout: MessagesLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.itemsGap}px;
`

export const FABContainer = styled.div<{
  layout: MessagesLayout,
  withToolbar: boolean,
}>`
  position: absolute;
  bottom: ${(args) => args.withToolbar ? args.layout.fabBottom : args.layout.fabRight }px;
  right: ${(args) => args.layout.fabRight}px;
`
