import * as React                    from 'react';

import { Button                    } from 'src/components/common/button';
import { Dialog                    } from 'src/components/common/dialog';
import { IconButton                } from 'src/components/common/icon-button';
import { Loading                   } from 'src/components/common/loading';

import { Container,
         LoadingContainer,
         LoadingLabel,
         Caption,
         Close,
         CloseOutlineVectorBig,
         SelectModeDialogVariants,
         selectmodedialog_variants } from 'src/components/features/inspection/edit/select-mode-dialog/ui';

import { Urls                      } from 'src/providers/routing';

import { AccountRole               } from 'src/services/api/types/auth';
import { InspectionState           } from 'src/services/api/types/inspection';
import Db                            from 'src/services/db';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch            } from 'src/store';



const withDefectCaptions: Record<AccountRole, string> = {
  [AccountRole.Client]: 'Осмотр с отклонением',
  [AccountRole.ClientAdmin]: 'Осмотр с отклонением',
  [AccountRole.Contractor]: 'С дефектом',
  [AccountRole.Stpk]: ''
}

const withoutDefectCaptions: Record<AccountRole, string> = {
  [AccountRole.Client]: 'Учёт м/проката',
  [AccountRole.ClientAdmin]: 'Учёт м/проката',
  [AccountRole.Contractor]: 'Без дефекта',
  [AccountRole.Stpk]: ''
}

type Props = {
  variant: SelectModeDialogVariants;
}
export const SelectModeDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = selectmodedialog_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const [uiState, setUiState] = React.useState<'none' | 'loading' | 'mode'>('none');

  const currentAccount = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const opened = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralSelectModeOpened);
  
  React.useEffect(() => {
    if (opened)
    {
      console.log('on opened');
      Db.getUnits().then((units) => {
        if (units.length === 0)
        {
          setUiState('loading');
          setTimeout(() => {
          dispatch(storeApi.main.inspectionList.async.downloadRefersAsync())
            .unwrap()
            .then((result) => {
              setUiState('mode');
            });
          }, 500);
        }
        else
        {
          setUiState('mode');
        }
        console.log(Date.now());
      });
    }
  }, [opened, dispatch]);

  const withDefectCaption = withDefectCaptions[currentAccount.role];
  const withoutDefectCaption = withoutDefectCaptions[currentAccount.role];

  const onTapWithDefectHandler = () => {
    dispatch(storeApi.inspection.edit.actions.generalSelectModeClosed());
    Urls.InspectionEdit.build('none', InspectionState.NEW, true, false, false, false).navigate();
  }

  const onTapWithoutDefectHandler = () => {
    dispatch(storeApi.inspection.edit.actions.generalSelectModeClosed());
    Urls.InspectionEdit.build('none', InspectionState.NEW, false, false, false, false).navigate();
  }

  if (uiState === 'none')
  {
    return null;
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Close lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.closeButtonVariant }
            isTapAllowed
            onTap = { () => dispatch(storeApi.inspection.edit.actions.generalSelectModeClosed()) }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </Close>
        {uiState === 'loading' && (
          <LoadingContainer lookAndFeel = { lookAndFeel }>
            <Loading variant = { lookAndFeel.loadingVariant } />
            <LoadingLabel lookAndFeel = { lookAndFeel }>Загрузка справочников</LoadingLabel>
          </LoadingContainer>
        )}
        {uiState === 'mode' && (
          <React.Fragment>
            <Caption lookAndFeel = { lookAndFeel }>
              Вид осмотра
            </Caption>
            <Button
              variant = { lookAndFeel.buttonVariant }
              label = { withoutDefectCaption }
              onTap = { onTapWithoutDefectHandler }
            />
            <Button
              variant = { lookAndFeel.buttonVariant }
              label = { withDefectCaption }
              onTap = { onTapWithDefectHandler }
            />
          </React.Fragment>
        )}
      </Container>
    </Dialog>
  );
};
