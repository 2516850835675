import { css } from 'styled-components';
import { generateMedia } from 'styled-media-query'



export enum ColorVariant {
  ACCENT_BLUE_1 = 'AccentBlue1',
  ACCENT_BLUE_2 = 'AccentBlue2',
  BLUE_3_NA = 'Blue3NA',
  ACCENT_RED_1 = 'AccentRed1',
  RED_2_NA = 'Red2NA',
  ACCENT_GREEN_1 = 'AccentGreen1',
  GRAY_1 = 'Gray1',
  SECONDARY_GRAY_1 = 'SecondaryGray1',
  SECONDARY_GRAY_2 = 'SecondaryGray2',
  SECONDARY_GRAY_3 = 'SecondaryGray3',
  SECONDARY_GRAY_4 = 'SecondaryGray4',
  SECONDARY_GRAY_5 = 'SecondaryGray5',
  SECONDARY_GRAY_9 = 'SecondaryGray9',
  // 10 и 11 NA не имеют названия в дизайн системе
  SECONDARY_GRAY_10_NA = 'SecondaryGray10NA',
  SECONDARY_GRAY_11_NA = 'SecondaryGray11NA',
  PRIMARY_WHITE = 'PrimaryWhite',
  PRIMARY_STEEL = 'PrimarySteel',
  PRIMARY_BLACK = 'PrimaryBlack',
  PRIMARY_BLUE_100 = 'PrimaryBlue100',
  ALERT_SUCCESS = 'AlertSuccess',
  BACKGROUND_FILL_LIGHT = 'BackgroundFillLight',
  CARD_BORDER_COLOR = 'CardBorderColor',
}

export const themeColors: Record<ColorVariant, string> = {
  [ColorVariant.ACCENT_BLUE_1]: '#003482',
  [ColorVariant.ACCENT_BLUE_2]: '#0041A0',
  [ColorVariant.BLUE_3_NA]: 'rgba(24, 94, 197, 0.2)',
  [ColorVariant.ACCENT_RED_1]: '#FF0000',
  [ColorVariant.RED_2_NA]: 'rgba(197, 24, 24, 0.2)',
  [ColorVariant.ACCENT_GREEN_1]: '#00A02D',
  [ColorVariant.GRAY_1]: '#FEFEFE',
  [ColorVariant.SECONDARY_GRAY_1]: '#F4F4F4',
  [ColorVariant.SECONDARY_GRAY_2]: '#E9E9E9',
  [ColorVariant.SECONDARY_GRAY_3]: '#DEDEDE',
  [ColorVariant.SECONDARY_GRAY_4]: '#BCBCBC',
  [ColorVariant.SECONDARY_GRAY_5]: '#979191',
  [ColorVariant.SECONDARY_GRAY_9]: '#53565A',
  [ColorVariant.SECONDARY_GRAY_10_NA]: '#E5ECF5',
  [ColorVariant.SECONDARY_GRAY_11_NA]: '#A0B9DC',
  [ColorVariant.PRIMARY_WHITE]: '#FFFFFF',
  [ColorVariant.PRIMARY_STEEL]: '#909090',
  [ColorVariant.PRIMARY_BLACK]: '#222222',
  [ColorVariant.PRIMARY_BLUE_100]: '#002F6C',
  [ColorVariant.ALERT_SUCCESS]: '#1EB76E',
  [ColorVariant.BACKGROUND_FILL_LIGHT]: '#FBFBFB',
  [ColorVariant.CARD_BORDER_COLOR]: 'rgba(25, 25, 25, 0.2)',
}

export enum FontVariant {
  GRTSK_PETA_ITALIC = 'GrtskPetaItalic',
  GRTSK_PETA_REGULAR = 'GrtskPetaRegular',
  GRTSK_PETA_BOLD = 'GrtskPetaBold',
}

export const themeFonts: Record<FontVariant, string> = {
  [FontVariant.GRTSK_PETA_ITALIC]: 'GrtskPeta Italic',
  [FontVariant.GRTSK_PETA_REGULAR]: 'GrtskPeta Regular',
  [FontVariant.GRTSK_PETA_BOLD]: 'GrtskPeta Bold',
}

export type Typo = {
  fontFamily: string;
  fontSize: number;
  fontWeight?: number;
  textDecoration?: string;
  lineHeight?: number;
}

export enum TypoVariant {
  GRTSKREG_W400_S08_LH10 = 'GRTSKREG_W400_S08_LH10',
  GRTSKREG_W400_S09_LH20 = 'GRTSKREG_W400_S09_LH20',
  GRTSKREG_W400_S10_LH12 = 'GRTSKREG_W400_S10_LH12',
  GRTSKREG_W400_S10_LH16 = 'GRTSKREG_W400_S10_LH16',
  GRTSKREG_W400_S11_LH14 = 'GRTSKREG_W400_S11_LH14',
  GRTSKREG_W400_S11_LH18 = 'GRTSKREG_W400_S11_LH18',
  GRTSKREG_W400_S12_LH14 = 'GRTSKREG_W400_S12_LH14',
  GRTSKREG_W400_S12_LH18 = 'GRTSKREG_W400_S12_LH18',
  GRTSKREG_W400_S12_LH22 = 'GRTSKREG_W400_S12_LH22',
  GRTSKREG_W400_S12_LH32 = 'GRTSKREG_W400_S12_LH32',
  GRTSKREG_W400_S13_LH16 = 'GRTSKREG_W400_S13_LH16',
  GRTSKREG_W400_S13_LH20 = 'GRTSKREG_W400_S13_LH20',
  GRTSKREG_W400_S14_LH16 = 'GRTSKREG_W400_S14_LH16',
  GRTSKREG_W400_S14_LH17 = 'GRTSKREG_W400_S14_LH17',
  GRTSKREG_W400_S14_LH20 = 'GRTSKREG_W400_S14_LH20',
  GRTSKREG_W400_S15_LH20 = 'GRTSKREG_W400_S15_LH20',
  GRTSKREG_W400_S15_LH22 = 'GRTSKREG_W400_S15_LH22',
  GRTSKREG_W400_S15_LH23 = 'GRTSKREG_W400_S15_LH23',
  GRTSKREG_W400_S15_LH28 = 'GRTSKREG_W400_S15_LH28',
  GRTSKREG_W400_S16_LH20 = 'GRTSKREG_W400_S16_LH20',
  GRTSKREG_W400_S16_LH21 = 'GRTSKREG_W400_S16_LH21',
  GRTSKREG_W400_S16_LH22 = 'GRTSKREG_W400_S16_LH22',
  GRTSKREG_W400_S16_LH24 = 'GRTSKREG_W400_S16_LH24',
  GRTSKREG_W400_S17_LH19 = 'GRTSKREG_W400_S17_LH19',
  GRTSKREG_W400_S20_LH20 = 'GRTSKREG_W400_S20_LH20',
  GRTSKREG_W400_S20_LH24 = 'GRTSKREG_W400_S20_LH24',
  GRTSKREG_W400_S20_LH28 = 'GRTSKREG_W400_S20_LH28',
  GRTSKREG_W400_S24_LH24 = 'GRTSKREG_W400_S24_LH24',
  GRTSKREG_W600_S12_LH16 = 'GRTSKREG_W600_S12_LH16',
  GRTSKREG_W600_S12_LH18 = 'GRTSKREG_W600_S12_LH18',
  GRTSKREG_W600_S14_LH17 = 'GRTSKREG_W600_S14_LH17',
  GRTSKREG_W600_S15_LH20 = 'GRTSKREG_W600_S15_LH20',
  GRTSKREG_W500_S18_LH22 = 'GRTSKREG_W500_S18_LH22',
  GRTSKREG_W600_S18_LH24 = 'GRTSKREG_W600_S18_LH24',
  GRTSKREG_W600_S18_LH26 = 'GRTSKREG_W600_S18_LH26',
  GRTSKREG_W600_S18_LH28 = 'GRTSKREG_W600_S18_LH28',
  GRTSKREG_W600_S26_LH35 = 'GRTSKREG_W600_S26_LH35',
  GRTSKREG_W600_S28_LH32 = 'GRTSKREG_W600_S28_LH32',
  GRTSKREG_W600_S32_LH43 = 'GRTSKREG_W600_S32_LH43',
}

export const themeTypos: Record<TypoVariant, Typo> = {
  [TypoVariant.GRTSKREG_W400_S08_LH10]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 10,
  },
  [TypoVariant.GRTSKREG_W400_S09_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 9,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S10_LH12]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 12,
  },
  [TypoVariant.GRTSKREG_W400_S10_LH16]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 16,
  },
  [TypoVariant.GRTSKREG_W400_S11_LH14]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 14,
  },
  [TypoVariant.GRTSKREG_W400_S11_LH18]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 18,
  },
  [TypoVariant.GRTSKREG_W400_S12_LH14]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 14,
  },
  [TypoVariant.GRTSKREG_W400_S12_LH18]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 18,
  },
  [TypoVariant.GRTSKREG_W400_S12_LH22]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 22,
  },
  [TypoVariant.GRTSKREG_W400_S12_LH32]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 32,
  },
  [TypoVariant.GRTSKREG_W400_S13_LH16]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 16,
  },
  [TypoVariant.GRTSKREG_W400_S13_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S14_LH16]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 16,
  },
  [TypoVariant.GRTSKREG_W400_S14_LH17]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 17,
  },
  [TypoVariant.GRTSKREG_W400_S14_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S15_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S15_LH22]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 22,
  },
  [TypoVariant.GRTSKREG_W400_S15_LH23]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 23,
  },
  [TypoVariant.GRTSKREG_W400_S15_LH28]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 28,
  },
  [TypoVariant.GRTSKREG_W400_S16_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S16_LH21]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 21,
  },
  [TypoVariant.GRTSKREG_W400_S16_LH22]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 22,
  },
  [TypoVariant.GRTSKREG_W400_S16_LH24]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 24,
  },
  [TypoVariant.GRTSKREG_W400_S17_LH19]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 17,
    fontWeight: 400,
    lineHeight: 19,
  },
  [TypoVariant.GRTSKREG_W400_S20_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W400_S20_LH24]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 24,
  },
  [TypoVariant.GRTSKREG_W400_S20_LH28]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 28,
  },
  [TypoVariant.GRTSKREG_W400_S24_LH24]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 24,
  },
  [TypoVariant.GRTSKREG_W600_S12_LH16]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 16,
  },
  [TypoVariant.GRTSKREG_W600_S12_LH18]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 18,
  },
  [TypoVariant.GRTSKREG_W600_S14_LH17]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 17,
  },
  [TypoVariant.GRTSKREG_W600_S15_LH20]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 20,
  },
  [TypoVariant.GRTSKREG_W600_S18_LH24]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 24,
  },
  [TypoVariant.GRTSKREG_W500_S18_LH22]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 22,
  },
  [TypoVariant.GRTSKREG_W600_S18_LH26]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 26,
  },
  [TypoVariant.GRTSKREG_W600_S18_LH28]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 28,
  },
  [TypoVariant.GRTSKREG_W600_S26_LH35]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 35,
  },
  [TypoVariant.GRTSKREG_W600_S28_LH32]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 32,
  },
  [TypoVariant.GRTSKREG_W600_S32_LH43]: {
    fontFamily: themeFonts.GrtskPetaRegular,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 43,
  },
}

export const typoAsCSS = (typo: Typo): string => {
  return `
    font-family: ${typo.fontFamily};
    font-size: ${typo.fontSize}px;
    ${typo.fontWeight !== undefined ? 'font-weight: ' + typo.fontWeight + ';' : ''}
    ${typo.textDecoration !== undefined ? 'text-decoration: ' + typo.textDecoration + ';' : ''}
    ${typo.lineHeight !== undefined ? 'line-height: ' + typo.lineHeight + 'px;' : ''}
  `;
}

/**
 * Общие куски CSS, которые часто повторяются при описании компонентов,
 * и которые можно потом вставить в styled-компонент
 * https://styled-components.com/docs/api#css
 */
 export const cssFlexRow = css`
 display: flex;
 flex-direction: row;
`;

export const cssFlexRowCC = css`
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
`;

export const cssFlexColumn = css`
 display: flex;
 flex-direction: column;
`;


/**
 * Breakpoint-ы для медиа запросов
 */
export const MQ = generateMedia({
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
});
