import * as React                from 'react';

import { Button                } from 'src/components/common/button';
import { Checkbox              } from 'src/components/common/checkbox';
import { Container, 
         Caption,
         Checkboxes,
         Actions,
         AcceptDialogVariants,
         acceptdialog_variants } from 'src/components/features/auth/reg-enter-data/accept-dialog/ui';

import Constants                 from 'src/services/constants';



type Props = {
  variant: AcceptDialogVariants;
  personalAccept: boolean;
  rulesAccept: boolean;
  onPersonalAcceptChange: (value: boolean) => void;
  onRulesAcceptChange: (value: boolean) => void;
  onAccept: () => void;
  onCancel: () => void;
}
export const AcceptDialog: React.FC<Props> = ({
  variant,
  personalAccept,
  rulesAccept,
  onPersonalAcceptChange,
  onRulesAcceptChange,
  onAccept,
  onCancel,
}) => {
  const lookAndFeel = acceptdialog_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Для продолжения работы необходимо ознакомиться с документами
      </Caption>
      <Checkboxes lookAndFeel = { lookAndFeel }>
        <Checkbox
          variant = { lookAndFeel.checkboxVariant }
          label = 'Согласие на обработку персональных даннных'
          url = { `${Constants.API_HOST}api/serv-proxy/official/4/1652280098/soglasie_checksteel.pdf` }
          value = { personalAccept }
          onChange = { onPersonalAcceptChange }
        />
        <Checkbox
          variant = { lookAndFeel.checkboxVariant }
          label = 'Правила по работе с приложением'
          url = { `${Constants.API_HOST}api/serv-proxy/official/3/1629980904/pravila_po_rabote_s_prilozheniem_checksteel.pdf` }
          value = { rulesAccept }
          onChange = { onRulesAcceptChange }
        />
      </Checkboxes>
      <Actions lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.cancelButtonVariant }
          label = 'Отмена'
          onTap = { onCancel }
        />
        <Button
          variant = { lookAndFeel.acceptButtonVariant }
          label = 'Принимаю'
          isDisabled = { !personalAccept || !rulesAccept }
          onTap = { onAccept }
        />
      </Actions>
    </Container>
  );
};
