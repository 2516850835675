import * as React                      from 'react';

import { Button                      } from 'src/components/common/button';
import { InputDigitCode              } from 'src/components/common/input/input-digitcode';
import { SendAgain                   } from 'src/components/common/send-again';
import { VGap                        } from 'src/components/common/flex/vgap';

import { Container,
         Header,
         Label,
         InputCodeContainer,
         InputLabel,
         InputContainer,
         ActionButtons,
         PhoneConfirmDialogVariants,
         phoneconfirmnote_variants   } from 'src/components/features/main/personal/phone-confirm-dialog/ui';

import Util                            from 'src/services/util';
import Constants                       from 'src/services/constants';



type Props = {
  variant: PhoneConfirmDialogVariants;
  onSend: () => void;
  onCancel: () => void;
  onAccept: (code: string) => void;
}
export const PhoneConfirmDialog: React.FC<Props> = ({
  variant,
  onSend,
  onCancel,
  onAccept,
}) => {
  const lookAndFeel = phoneconfirmnote_variants.get(variant)!;
  const [code, setCode] = React.useState<string>('');

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Header lookAndFeel = { lookAndFeel }>Номер телефона изменен</Header>
      <Label lookAndFeel = { lookAndFeel }>
        Подтвердите новый номер с<br />помощью кода из СМС<br />сообщения.
      </Label>
      <InputCodeContainer lookAndFeel = { lookAndFeel }>
        <InputLabel lookAndFeel = { lookAndFeel }>
          Код из SMS
        </InputLabel>
        <VGap size = { lookAndFeel.digitGapTop } />
        <InputContainer lookAndFeel = { lookAndFeel }>
          <InputDigitCode
            variant = { lookAndFeel.inputDigitVariant }
            countDigits = { Constants.SMS_CODE_LENGTH }
            value = ''
            onChange = { (newValue) => setCode(newValue) }
          />
        </InputContainer>
        <VGap size = { lookAndFeel.digitGapBottom } />
        <SendAgain
          variant = { lookAndFeel.sendAgainVariant }
          onSendAgain = { onSend }
        />
      </InputCodeContainer>
      <ActionButtons lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.cancelVariant }
          label = 'Отмена'
          isDisabled = { false }
          onTap = {onCancel}
        />
        <Button
          variant = { lookAndFeel.submitVariant }
          label = 'Подтвердить'
          isDisabled = { Util.selectDigitsFromString(code).length < 6 }
          onTap = { () => onAccept(code) }
        />
      </ActionButtons>
    </Container>
  );
};
