import * as React          from 'react';

import { EyeShowVector   } from 'src/components/vector/eye-show';

import { Container,
         Label,
         Icon,
         ReportVariants,
         report_variants } from 'src/components/features/main/shade-selection/report/ui';



type Props = {
  variant: ReportVariants;
  label: string;
  onAction: (action: 'navigate') => void;
}
export const Report: React.FC<Props> = ({
  variant,
  label,
  onAction,
}) => {
  const lookAndFeel = report_variants.get(variant)!;

  const onContainerTap = () => {
    onAction('navigate');
  }

  return (
    <Container
      lookAndFeel = { lookAndFeel }
      onClick = { (event) => onContainerTap.apply(null, []) }
    >
      <Label lookAndFeel = { lookAndFeel }>
        { label }
      </Label>
      <Icon lookAndFeel = { lookAndFeel }>
        <EyeShowVector />
      </Icon>
    </Container>
  );
};
