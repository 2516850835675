import styled from 'styled-components';



export const Container = styled.div<{
  size: number
}>`
  height: ${(args) => args.size}px;
  flex-shrink: 0;
`
