import styled           from 'styled-components';

import { themeColors,
         Typo,
         themeTypos,
         typoAsCSS    } from 'src/providers/theme/shared';



type OrganizationLookAndFeel = {
  labelTypo: Typo;
  color: string;
}

const organization_light: OrganizationLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W600_S18_LH26,
  color: themeColors.PrimaryBlack,
}

export type OrganizationVariants = 'light';

export const organization_variants: Map<OrganizationVariants, OrganizationLookAndFeel> = 
  new Map<OrganizationVariants, OrganizationLookAndFeel>([
    ['light', organization_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: OrganizationLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}

  color: ${(args) => args.lookAndFeel.color};
`
