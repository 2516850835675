import React from 'react';



type Props = {
  className?: string;
}
export const ArrowUpVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '14' height = '14' viewBox = '0 0 14 14' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M13 10L8 4L6 4L1 10' />
    </svg>
  );
};
