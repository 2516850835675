import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuClockVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M12.5 7.32324V12.4997L9.5 15.3232' />
      <circle stroke = 'currentColor' cx = '12' cy = '12' r = '9' strokeWidth = '1.5'/>
    </svg>
  );
};
