import styled            from 'styled-components';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexColumn } from 'src/providers/theme/shared';



type ResultLookAndFeel = {
  paddings: number[];
  margins: number[];
  topGap: number;
  itemGap: number;

  itemHeaderTypo: Typo;
  itemPrimaryTypo: Typo;
  itemRollTypo: Typo;
  emptyTypo: Typo;

  itemHeaderColor: string;
  itemPrimaryColor: string;
  itemRollColor: string;
  emptyColor: string;

  dividerColor: string;
}

const result_light: ResultLookAndFeel = {
  paddings: [24, 0],
  margins: [12, 0, 0, 0],
  topGap: 24,
  itemGap: 8,

  itemHeaderTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  itemPrimaryTypo: themeTypos.GRTSKREG_W500_S18_LH22,
  itemRollTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  emptyTypo: themeTypos.GRTSKREG_W400_S12_LH14,

  itemHeaderColor: themeColors.AccentBlue2,
  itemPrimaryColor: themeColors.AccentBlue2,
  itemRollColor: themeColors.PrimaryBlack,
  emptyColor: themeColors.SecondaryGray9,

  dividerColor: themeColors.SecondaryGray3,
}

export type ResultVariants = 'light';

export const result_variants: Map<ResultVariants, ResultLookAndFeel> = 
  new Map<ResultVariants, ResultLookAndFeel>([
    ['light', result_light],
  ]);

export const ResultContainer = styled.div<{
  lookAndFeel: ResultLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.topGap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
  margin: ${(args) => args.lookAndFeel.margins[0]}px ${(args) => args.lookAndFeel.margins[1]}px ${(args) => args.lookAndFeel.margins[2]}px ${(args) => args.lookAndFeel.margins[3]}px;
  border-top: 1px solid ${(args) => args.lookAndFeel.dividerColor};
  border-bottom: 1px solid ${(args) => args.lookAndFeel.dividerColor};
`;

export const ResultItem = styled.div<{
  lookAndFeel: ResultLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.itemGap}px;
`;

export const ResultHeader = styled.span<{
  lookAndFeel: ResultLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemHeaderTypo)};
  color: ${(args) => args.lookAndFeel.itemHeaderColor};
`;

export const ResultPrimary = styled.span<{
  lookAndFeel: ResultLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemPrimaryTypo)};
  color: ${(args) => args.lookAndFeel.itemPrimaryColor};
`;

export const ResultRoll = styled.span<{
  lookAndFeel: ResultLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemRollTypo)};
  color: ${(args) => args.lookAndFeel.itemRollColor};
`;

export const ResultEmpty = styled.span<{
  lookAndFeel: ResultLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.emptyTypo)};
  color: ${(args) => args.lookAndFeel.emptyColor};
  text-align: center;
`;