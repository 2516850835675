import styled             from 'styled-components';

import { cssFlexColumn, 
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS      } from 'src/providers/theme/shared';



type VersionLookAndFeel = {
  gap: number;

  roleTypo: Typo;
  versionTypo: Typo;

  color: string;
}

const version_light: VersionLookAndFeel = {
  gap: 2,

  roleTypo: themeTypos.GRTSKREG_W600_S14_LH17,
  versionTypo: themeTypos.GRTSKREG_W400_S14_LH17,

  color: themeColors.PrimaryBlack,
}

export type VersionVariants = 'light';

export const version_variants: Map<VersionVariants, VersionLookAndFeel> = 
  new Map<VersionVariants, VersionLookAndFeel>([
    ['light', version_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: VersionLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  color: ${(args) => args.lookAndFeel.color};
`

export const RoleLabel = styled.span<{
  lookAndFeel: VersionLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.roleTypo)};

  text-align: right;
`;

export const VersionLabel = styled.span<{
  lookAndFeel: VersionLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.versionTypo)};

  text-align: right;
`;