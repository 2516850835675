import * as React                      from 'react';

import { Button                      } from 'src/components/common/button';

import { Container,
         Header,
         Label,
         ActionButtons,
         EmailConfirmDialogVariants,
         emailconfirmnote_variants   } from 'src/components/features/main/personal/email-confirm-dialog/ui';



type Props = {
  variant: EmailConfirmDialogVariants;
  isChanged: boolean;
  email: string;
  onCancel: () => void;
  onAccept: () => void;
}
export const EmailConfirmDialog: React.FC<Props> = ({
  variant,
  isChanged,
  email,
  onCancel,
  onAccept,
}) => {
  const lookAndFeel = emailconfirmnote_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Header lookAndFeel = { lookAndFeel }>E-mail не подтвержден</Header>
      <Label lookAndFeel = { lookAndFeel }>
        Подтвердите свой электронный адрес. Для этого перейдите по ссылке в письме, которое придет вам на почту:
        <i>{ email }</i>
        {isChanged && ('после сохранения изменений.')}
      </Label>
      <ActionButtons lookAndFeel = { lookAndFeel }>
        {!isChanged && (
          <Button
            variant = { lookAndFeel.cancelVariant }
            label = 'Отмена'
            isDisabled = { false }
            onTap = {onCancel}
          />
        )}
        <Button
          variant = { lookAndFeel.submitVariant }
          label = {isChanged ? 'Продолжить' : 'Отправить'}
          isDisabled = { false }
          onTap = {onAccept}
        />
      </ActionButtons>
    </Container>
  );
};
