import { createSlice,
         createAsyncThunk,  
         PayloadAction,
         createSelector    } from '@reduxjs/toolkit';

import { v4 as uuidv4      } from 'uuid';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';
import Db                    from 'src/services/db';

import { RootState,
         storeApi          } from 'src/store';

import { AsyncOpStatus,
         Nullable          } from 'src/common';



const SLICE_NAME = 'qmmsg:detail';

export interface QMMsgDetailState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  stepQM: types.refers.StepQMReferElement[];

  claim: Nullable<types.qmmsg.QMMsg>;

  activeTab: string;

  addQDOpened: boolean;
  addQDMessage: string;
  addQDBusy: boolean;
  addQDError: boolean;

  backFROpened: boolean;
  backFRMessage: string;
  backFRBusy: boolean;
  backFRError: boolean;

  rateOpened: boolean;
  rateMessage: string;
  rateBusy: boolean;
  rateError: boolean;

  feedbackThanksOpened: boolean;

  isEmptyCaseVisible: boolean;
  isStepFlowVisible: boolean;
  isActionsVisible: boolean;
  isComebackVisible: boolean;
  isRateVisible: boolean;

  rate: number;
}

const initialState: QMMsgDetailState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  stepQM: [],

  claim: null,

  activeTab: 'status',

  addQDOpened: false,
  addQDMessage: '',
  addQDBusy: false,
  addQDError: false,

  backFROpened: false,
  backFRMessage: '',
  backFRBusy: false,
  backFRError: false,

  rateOpened: false,
  rateMessage: '',
  rateBusy: false,
  rateError: false,

  feedbackThanksOpened: false,
  
  isEmptyCaseVisible: false,
  isStepFlowVisible: false,
  isActionsVisible: false,
  isComebackVisible: false,
  isRateVisible: false,

  rate: -1,
}


// #region prepareInfoAsync
type PrepareInfoArgs = number;
type PrepareInfoResolve = {
  stepQM: types.refers.StepQMReferElement[];
  claim: types.qmmsg.QMMsg;
  isEmptyCaseVisible: boolean;
  isStepFlowVisible: boolean;
  isActionsVisible: boolean;
  isComebackVisible: boolean;
  isRateVisible: boolean;
};
export type PrepareInfoReject = ApiError;
export const prepareInfoAsync = createAsyncThunk<
  PrepareInfoResolve,
  PrepareInfoArgs,
  {
    state: RootState,
    rejectValue: PrepareInfoReject,
  }
>(
  `${SLICE_NAME}/prepareInfoAsync`,
  async (qmMsgId, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(qmmsgDetailActions.opStatusChanged(AsyncOpStatus.BUSY));

      /** Проверка необходимости обновления справочников */
      thunkAPI.dispatch(qmmsgDetailActions.opStatusLabelChanged('Проверка необходимости обновления справочников'));
      const dbRefersHash = await Db.getOptString('REFER_HASH');
      const apiRefersHash = (await Api.getRefersHash()).hash;
      if (apiRefersHash !== null && (dbRefersHash === null || dbRefersHash !== apiRefersHash))
      {
        thunkAPI.dispatch(qmmsgDetailActions.opStatusLabelChanged('Загрузка и обновление справочников'));
        const refers = await Api.getRefers();
        await Db.updateRefers(refers, apiRefersHash!);
      }

      const stepQM = await Db.getStepsQmMsg();
      const qmmsgViewed = (await Db.getOptString('QMMSG_VIEWED'))?.split(',') ?? [];

      thunkAPI.dispatch(qmmsgDetailActions.opStatusLabelChanged('Загрузка уведомления/претензии'));
      const claim = await Api.getQMMsgClaimById({
        qmMsgId
      });

      const code = `${claim.qmMsgId}_${claim.step.id}_${claim.status.id}_${claim.condition.id}`;

      if (!qmmsgViewed.includes(code))
      {
        await Db.setOptString('QMMSG_VIEWED', [...qmmsgViewed, code].join(','));
        thunkAPI.dispatch(storeApi.qmmsg.list.actions.viewedChanged([...qmmsgViewed, code].join(',')));
      }

      let isEmptyCaseVisible = false;
      let isStepFlowVisible = false;
      let isActionsVisible = false;
      let isComebackVisible = false;
      let isRateVisible = false;

      if (claim.step === null || claim.step.id === 0)
      {
        isEmptyCaseVisible = true;
      }
      else
      {
        isStepFlowVisible = true;

        if (claim.action !== null && (claim.action === 'comeback' || claim.action === 'approved'))
        {
          if (claim.action === 'comeback')
          {
            isComebackVisible = true;
          }
          else
          {
            isRateVisible = true;
          }
        }
        else
        {
          if (claim.condition.id === 2)
          {
            isActionsVisible = true;
          }
        }
      }

      return { claim, stepQM, isEmptyCaseVisible, isStepFlowVisible, isActionsVisible, isComebackVisible, isRateVisible }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region changeFavoriteAsync
type ChangeFavoriteArgs = {
  qmMsgId: number;
  isFavorite: boolean;
};
type ChangeFavoriteResolve = {
  isFavorite: boolean;
};
export type ChangeFavoriteReject = ApiError;
export const changeFavoriteAsync = createAsyncThunk<
  ChangeFavoriteResolve,
  ChangeFavoriteArgs,
  {
    state: RootState,
    rejectValue: ChangeFavoriteReject,
  }
>(
  `${SLICE_NAME}/changeFavoriteAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(qmmsgDetailActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(qmmsgDetailActions.opStatusLabelChanged(
        args.isFavorite ? 'Добавляем уведомление/претензию в избранное' : 'Удаляем уведомление/претензию из избранного'
      ));

      await Api.putQMMsgFavorite({
        ...args,
        timestamp: Date.now()
      });

      return { isFavorite: args.isFavorite }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region addQuestionAsync
type AddQuestionArgs = void;
type AddQuestionResolve = void;
export type AddQuestionReject = ApiError;
export const addQuestionAsync = createAsyncThunk<
  AddQuestionResolve,
  AddQuestionArgs,
  {
    state: RootState,
    rejectValue: AddQuestionReject,
  }
>(
  `${SLICE_NAME}/addQuestionAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const question = qmmsgDetailSelectors.selectAddQDMessage(state);
      const qmMsgId = qmmsgDetailSelectors.selectClaim(state)!.qmMsgId;

      thunkAPI.dispatch(qmmsgDetailActions.addQDBusyChanged(true));

      await Api.postQMMsgQuestion({
        guid: uuidv4(),
        qmMsgId,
        question,
        timestamp: Date.now(),
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region doActionAsync
type DoActionArgs = string;
type DoActionResolve = string;
export type DoActionReject = ApiError;
export const doActionAsync = createAsyncThunk<
  DoActionResolve,
  DoActionArgs,
  {
    state: RootState,
    rejectValue: DoActionReject,
  }
>(
  `${SLICE_NAME}/doActionAsync`,
  async (action, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const note = action === 'approved' ? '' : qmmsgDetailSelectors.selectBackFRMessage(state);
      const qmMsgId = qmmsgDetailSelectors.selectClaim(state)!.qmMsgId;

      if (action === 'approved')
      {
        thunkAPI.dispatch(qmmsgDetailActions.opStatusChanged(AsyncOpStatus.BUSY));
        thunkAPI.dispatch(qmmsgDetailActions.opStatusLabelChanged('Подтверждение действия'));
      }
      else
      {
        thunkAPI.dispatch(qmmsgDetailActions.backFRBusyChanged(true));
      }
    
      await Api.postQMMsgAction({
        action,
        qmMsgId,
        note
      });

      return action;
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region addRatingAsync
type AddRatingArgs = void;
type AddRatingResolve = void;
export type AddRatingReject = ApiError;
export const addRatingAsync = createAsyncThunk<
  AddRatingResolve,
  AddRatingArgs,
  {
    state: RootState,
    rejectValue: AddRatingReject,
  }
>(
  `${SLICE_NAME}/addRatingAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const ratingNote = qmmsgDetailSelectors.selectRateMessage(state);
      const rating = qmmsgDetailSelectors.selectRate(state);
      const qmMsgId = qmmsgDetailSelectors.selectClaim(state)!.qmMsgId;

      thunkAPI.dispatch(qmmsgDetailActions.rateBusyChanged(true));

      await Api.postQMMsgRating({
        rating,
        ratingNote,
        qmMsgId,
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

type StringChangedAction = PayloadAction<string>;
type NumberChangedAction = PayloadAction<number>;
type BooleanChangedAction = PayloadAction<boolean>;

export const qmmsgDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },

    activeTabChanged: (state, action: StringChangedAction) => {
      state.activeTab = action.payload;
    },
    rateChanged: (state, action: NumberChangedAction) => {
      state.rate = action.payload;
    },

    feedbackThanksOpened: (state, action: BooleanChangedAction) => {
      state.feedbackThanksOpened = action.payload;
    },

    addQDOpened: (state) => {
      state.addQDOpened = true;
      state.addQDMessage = '';
      state.addQDBusy = false;
      state.addQDError = false;
    },
    addQDClosed: (state) => {
      state.addQDOpened = false;
    },
    addQDErrorReset: (state) => {
      state.addQDError = false;
    },
    addQDMessageChanged: (state, action: StringChangedAction) => {
      state.addQDMessage = action.payload;
      state.addQDError = false;
    },
    addQDBusyChanged: (state, action: BooleanChangedAction) => {
      state.addQDBusy = action.payload;
    },

    rateOpened: (state) => {
      state.rateOpened = true;
      state.rateMessage = '';
      state.rateBusy = false;
      state.rateError = false;
    },
    rateClosed: (state) => {
      state.rateOpened = false;
    },
    rateErrorReset: (state) => {
      state.rateError = false;
    },
    rateMessageChanged: (state, action: StringChangedAction) => {
      state.rateMessage = action.payload;
      state.rateError = false;
    },
    rateBusyChanged: (state, action: BooleanChangedAction) => {
      state.rateBusy = action.payload;
    },

    backFROpened: (state) => {
      state.backFROpened = true;
      state.backFRMessage = '';
      state.backFRBusy = false;
      state.backFRError = false;
    },
    backFRClosed: (state) => {
      state.backFROpened = false;
    },
    backFRErrorReset: (state) => {
      state.backFRError = false;
    },
    backFRMessageChanged: (state, action: StringChangedAction) => {
      state.backFRMessage = action.payload;
      state.backFRError = false;
    },
    backFRBusyChanged: (state, action: BooleanChangedAction) => {
      state.backFRBusy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(prepareInfoAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.claim = action.payload.claim;
        state.stepQM = action.payload.stepQM;
        state.isEmptyCaseVisible = action.payload.isEmptyCaseVisible;
        state.isStepFlowVisible = action.payload.isStepFlowVisible;
        state.isActionsVisible = action.payload.isActionsVisible;
        state.isComebackVisible = action.payload.isComebackVisible;
        state.isRateVisible = action.payload.isRateVisible;
        state.rate = action.payload.claim.rating.rating !== null ? parseInt(action.payload.claim.rating.rating, 10) : 0;
      })
      .addCase(prepareInfoAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
      .addCase(changeFavoriteAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.claim = state.claim === null ? null : { ...state.claim, isFavorite: action.payload.isFavorite };
      })
      .addCase(changeFavoriteAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
      .addCase(addQuestionAsync.fulfilled, (state, action) => {
        state.addQDBusy = false;
        state.addQDOpened = false;
      })
      .addCase(addQuestionAsync.rejected, (state) => {
        state.addQDBusy = false;
        state.addQDError = true;
      })
      .addCase(addRatingAsync.fulfilled, (state, action) => {
        state.rateBusy = false;
        state.rateOpened = false;
      })
      .addCase(addRatingAsync.rejected, (state) => {
        state.rateBusy = false;
        state.rateError = true;
      })
      .addCase(doActionAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.backFRBusy = false;
        state.backFROpened = false;
        state.isActionsVisible = false;

        if (action.payload === 'comeback')
        {
          state.isComebackVisible = true;
          state.feedbackThanksOpened = true;
        }
        else
        {
          state.isRateVisible = true;
        }
      })
      .addCase(doActionAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.backFRBusy = false;
        state.backFRError = true;
      })
  },
})

const selectOpStatus = (state: RootState) => state.qmmsgDetail.opStatus;
const selectOpStatusLabel = (state: RootState) => state.qmmsgDetail.opStatusLabel;

const selectStepQM = (state: RootState) => state.qmmsgDetail.stepQM;

const selectClaim = (state: RootState) => state.qmmsgDetail.claim;

const selectActiveTab = (state: RootState) => state.qmmsgDetail.activeTab;
const selectIsFavorite = createSelector([selectClaim], (claim) => claim?.isFavorite ?? false);

const selectAddQDOpened = (state: RootState) => state.qmmsgDetail.addQDOpened;
const selectAddQDMessage = (state: RootState) => state.qmmsgDetail.addQDMessage;
const selectAddQDBusy = (state: RootState) => state.qmmsgDetail.addQDBusy;
const selectAddQDError = (state: RootState) => state.qmmsgDetail.addQDError;

const selectBackFROpened = (state: RootState) => state.qmmsgDetail.backFROpened;
const selectBackFRMessage = (state: RootState) => state.qmmsgDetail.backFRMessage;
const selectBackFRBusy = (state: RootState) => state.qmmsgDetail.backFRBusy;
const selectBackFRError = (state: RootState) => state.qmmsgDetail.backFRError;

const selectRateOpened = (state: RootState) => state.qmmsgDetail.rateOpened;
const selectRateMessage = (state: RootState) => state.qmmsgDetail.rateMessage;
const selectRateBusy = (state: RootState) => state.qmmsgDetail.rateBusy;
const selectRateError = (state: RootState) => state.qmmsgDetail.rateError;

const selectFeedbackThanksOpened = (state: RootState) => state.qmmsgDetail.feedbackThanksOpened;

const selectIsActionsVisible = (state: RootState) => state.qmmsgDetail.isActionsVisible;
const selectIsComebackVisible = (state: RootState) => state.qmmsgDetail.isComebackVisible;
const selectIsEmptyCaseVisible = (state: RootState) => state.qmmsgDetail.isEmptyCaseVisible;
const selectIsRateVisible = (state: RootState) => state.qmmsgDetail.isRateVisible;
const selectIsStepFlowVisible = (state: RootState) => state.qmmsgDetail.isStepFlowVisible;

const selectRate = (state: RootState) => state.qmmsgDetail.rate;


export const qmmsgDetailSelectors = {
  selectOpStatus,
  selectOpStatusLabel,

  selectStepQM,

  selectClaim,

  selectActiveTab,
  selectIsFavorite,

  selectAddQDOpened,
  selectAddQDMessage,
  selectAddQDBusy,
  selectAddQDError,

  selectBackFROpened,
  selectBackFRMessage,
  selectBackFRBusy,
  selectBackFRError,

  selectRateOpened,
  selectRateMessage,
  selectRateBusy,
  selectRateError,

  selectFeedbackThanksOpened,

  selectIsActionsVisible,
  selectIsComebackVisible,
  selectIsEmptyCaseVisible,
  selectIsRateVisible,
  selectIsStepFlowVisible,

  selectRate,
}

export const qmmsgDetailActions = qmmsgDetailSlice.actions;

export default qmmsgDetailSlice.reducer;