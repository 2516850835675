import React from 'react';



type Props = {
  className?: string;
}
export const AddPhotoVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '40' height = '40' viewBox = '0 0 40 40' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path className = 'secondary' stroke = 'currentColor' strokeWidth = '2' d = 'M4 3L35 3V27H29.973M4.83784 6V27H10' />
      <path className = 'secondary' stroke = 'currentColor' strokeWidth = '2' d = 'M10 20L15.0769 16.1111L19.3077 19.2222L27.7692 13H32' />
      <path className = 'primary' stroke = 'currentColor' strokeWidth = '2' d = 'M20 35V27' />
      <path className = 'primary' stroke = 'currentColor' strokeWidth = '2' d = 'M16 31H24' />
      <path className = 'secondary' stroke = 'currentColor' fillRule = 'evenodd' clipRule = 'evenodd' strokeWidth = '2' strokeLinecap = 'round' strokeLinejoin = 'round' d = 'M17 9.5C17 10.8797 15.8794 12 14.4993 12C13.1192 12 12 10.8797 12 9.5C12 8.1203 13.1192 7 14.4993 7C15.8794 7 17 8.1203 17 9.5Z' />
      <rect className = 'primary' stroke = 'currentColor' x = '13' y = '24' width = '14' height = '14' strokeWidth = '2'/>
    </svg>
  );
};
