import styled             from 'styled-components';

import { cssFlexColumn,
         cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type PinLookAndFeel = {
  headerTypo: Typo;
  headerColor: string;

  cellCodeTypo: Typo;
  cellCodeActiveColor: string;
  cellCodeInactiveColor: string;
  cellCodeSize: number;
  cellCodeBallSize: number;
  cellCodeGap: number;
  cellCodePaddings: number[];

  cellDigitTypo: Typo;
  cellDigitColor: string;
  cellDigitSize: number;
  cellDigitHGap: number;
  cellDigitVGap: number;
  cellDigitSelectedBackgroundColor: string;
  cellDigitSelectedBorderColor: string;

  backspaceColor: string;
}

const pin_light: PinLookAndFeel = {
  headerTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  headerColor: themeColors.AccentBlue2,

  cellCodeTypo: themeTypos.GRTSKREG_W400_S24_LH24,
  cellCodeActiveColor: themeColors.AccentBlue2,
  cellCodeInactiveColor: themeColors.SecondaryGray3,
  cellCodeSize: 24,
  cellCodeBallSize: 12,
  cellCodeGap: 16,
  cellCodePaddings: [18, 0, 36, 0],

  cellDigitTypo: themeTypos.GRTSKREG_W400_S24_LH24,
  cellDigitColor: themeColors.PrimaryBlack,
  cellDigitSize: 50,
  cellDigitHGap: 50,
  cellDigitVGap: 9,
  cellDigitSelectedBackgroundColor: themeColors.PrimaryWhite,
  cellDigitSelectedBorderColor: themeColors.SecondaryGray3,

  backspaceColor: themeColors.AccentBlue2,
}

export type PinVariants = 'light';

export const pin_variants: Map<PinVariants, PinLookAndFeel> = 
  new Map<PinVariants, PinLookAndFeel>([
    ['light', pin_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PinLookAndFeel
}>`
  ${cssFlexColumn};

  width: 100%;
`

export const Header = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  align-self: center;

  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)}

  color: ${(args) => args.lookAndFeel.headerColor};
`

export const CodeContainer = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.cellCodeGap}px;

  align-self: center;
  height: ${(args) => args.lookAndFeel.cellCodeSize}px;
  padding: ${(args) => args.lookAndFeel.cellCodePaddings[0]}px 0 ${(args) => args.lookAndFeel.cellCodePaddings[2]}px 0;
`

export const CodeCell = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  width: ${(args) => args.lookAndFeel.cellCodeSize}px;
  height: ${(args) => args.lookAndFeel.cellCodeSize}px;
`

export const CodeCellBall = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  width: ${(args) => args.lookAndFeel.cellCodeBallSize}px;
  height: ${(args) => args.lookAndFeel.cellCodeBallSize}px;
  border-radius: ${(args) => args.lookAndFeel.cellCodeBallSize}px;
  background-color:  ${(args) => args.lookAndFeel.cellCodeInactiveColor};

  &.active {
    background-color:  ${(args) => args.lookAndFeel.cellCodeActiveColor};
  }
`

export const CodeCellDigit = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.cellCodeTypo)}
  color: ${(args) => args.lookAndFeel.cellCodeActiveColor};
`

export const DigitContainer = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexColumn};
  align-self: center;
  gap: ${(args) => args.lookAndFeel.cellDigitVGap}px;
`

export const DigitRow = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.cellDigitHGap}px;

  height: ${(args) => args.lookAndFeel.cellDigitSize}px;
`

export const DigitCell = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  width: ${(args) => args.lookAndFeel.cellDigitSize}px;
  height: ${(args) => args.lookAndFeel.cellDigitSize}px;
  border-radius: ${(args) => args.lookAndFeel.cellDigitSize}px;

  ${(args) => typoAsCSS(args.lookAndFeel.cellDigitTypo)}
  color: ${(args) => args.lookAndFeel.cellDigitColor};

  background-color: transparent;
  border: 1px solid transparent;

  cursor: pointer;

  &.active {
    background-color: ${(args) => args.lookAndFeel.cellDigitSelectedBackgroundColor};
    border: 1px solid ${(args) => args.lookAndFeel.cellDigitSelectedBorderColor};
  }
`

export const ActionCell = styled.div<{
  lookAndFeel: PinLookAndFeel,
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  width: ${(args) => args.lookAndFeel.cellDigitSize}px;
  height: ${(args) => args.lookAndFeel.cellDigitSize}px;
  border-radius: ${(args) => args.lookAndFeel.cellDigitSize}px;

  background-color: transparent;
  border: 1px solid transparent;

  &.active {
    background-color: ${(args) => args.lookAndFeel.cellDigitSelectedBackgroundColor};
    border: 1px solid ${(args) => args.lookAndFeel.cellDigitSelectedBorderColor};
  }

  &.backspace svg {
    color: ${(args) => args.lookAndFeel.backspaceColor};
  }
`
