import * as React                 from 'react';

import { useAlert               } from 'react-alert';

import { v4 as uuidv4           } from 'uuid';

import { ArrowDownVector        } from 'src/components/vector/arrow-down';
import { ArrowUpVector          } from 'src/components/vector/arrow-up';
import { TrashVector            } from 'src/components/vector/trash';
import { DefectOKVector         } from 'src/components/vector/defect-ok';
import { DefectErrorVector      } from 'src/components/vector/defect-error';
import { ListVector             } from 'src/components/vector/list';
import { EditVector             } from 'src/components/vector/edit';

import { Typography             } from 'src/components/common/typography';
import { Switch                 } from 'src/components/common/switch';

import { IconButton             } from 'src/components/common/icon-button';
import { TwoActionDialog        } from 'src/components/common/two-action-dialog';
import { Labeled                } from 'src/components/common/labeled';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { InputCalendar          } from 'src/components/common/input/input-calendar';
import { InputMultiline         } from 'src/components/common/input/input-multiline';

import { ReferSelector,
         ReferSelectorItem      } from 'src/components/features/common/refer-selector';
import { DefectSelector         } from 'src/components/features/common/defect-selector';

import { PieceEdit              } from 'src/components/features/inspection/edit/piece-edit-dialog';
import { PieceList              } from 'src/components/features/inspection/edit/piece-list';
import { AttachmentAdd          } from 'src/components/features/inspection/edit/attachment-add';
import { AttachmentPDF          } from 'src/components/features/inspection/edit/attachment-pdf';

import { DefectItemLookAndFeel, 
         Container,
         Header,
         HeaderStatus,
         HeaderLabel,
         HeaderTrashAction,
         HeaderExpandAction,
         DefectDateTime,
         DefectBody,
         FieldWithSelector,
         FieldWithSwitch,
         PhotoList,
         Hr,
         PieceListContainer,
         DefectItemVariants,
         defectitem_variants    } from 'src/components/features/inspection/edit/defect-item/ui';

import * as types                 from 'src/services/api/types';
import Util                       from 'src/services/util';
import { DefectReferElement     } from 'src/services/api/types/refers';
import { AccountRole            } from 'src/services/api/types/auth';
import { InspectionState,
         InspectionStatusCode   } from 'src/services/api/types/inspection';
import Constants                  from 'src/services/constants';

import { useAppSelector, 
         storeApi,    
         useAppDispatch         } from 'src/store';

import { Nullable               } from 'src/common';



const MODE_DEL = types.inspection.DefectFileMode.DEL;
const ACT_JOIN_INSP = types.inspection.DefectFileType.ACT_JOIN_INSP;
const MODE_NEW = types.inspection.DefectFileMode.NEW;

type DefectItemSubComponentProps = { 
  lookAndFeel: DefectItemLookAndFeel; 
  defectGuid: string;
}

const DefectItemHeader: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const countDefects = useAppSelector(storeApi.inspection.edit.selectors.selectDefectAllCount);
  const expandedGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectExpandedGuid);
  const isOk = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectIsOk(store, defectGuid));
  const title = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectTitle(store, defectGuid));

  const [isConfirmOpened, changeConfirmOpened] = React.useState<boolean>(false);

  const isExpanded = expandedGuid === defectGuid;

  const onDeleteClickHandler = () => {
    changeConfirmOpened(true);
  }

  const onExpandCollapseHandler = () => {
    dispatch(storeApi.inspection.edit.actions.defectExpandedGuidChanged(isExpanded ? '' : defectGuid));
  }

  const onConfirmDeleteCancelHandler = () => {
    changeConfirmOpened(false);
  }

  const onConfirmDeleteAcceptHandler = () => {
    changeConfirmOpened(false);
    dispatch(storeApi.inspection.edit.actions.defectDeleted(defectGuid));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Header lookAndFeel = { lookAndFeel }>
      {!isExpanded && (
        <HeaderStatus  lookAndFeel = { lookAndFeel } isOk = { isOk }>
          { isOk ? <DefectOKVector /> : <DefectErrorVector /> }
        </HeaderStatus>
      )}
      {isExpanded && countDefects > 1 && (
        <HeaderStatus  lookAndFeel = { lookAndFeel } isOk = { isOk } />
      )}
      <HeaderLabel lookAndFeel = { lookAndFeel }>
        { title ?? 'Информация о дефекте' }
      </HeaderLabel>
      {countDefects > 1 && (
        <React.Fragment>
          <HeaderTrashAction lookAndFeel = { lookAndFeel }>
            <IconButton
              variant = { lookAndFeel.actionButtonVariant }
              isTapAllowed
              onTap = { onDeleteClickHandler }
            >
              <TrashVector />
            </IconButton>
          </HeaderTrashAction>
          <HeaderExpandAction lookAndFeel = { lookAndFeel }>
            <IconButton
              variant = { lookAndFeel.actionButtonVariant }
              isTapAllowed
              onTap = { onExpandCollapseHandler }
            >
              { isExpanded ? <ArrowUpVector /> : <ArrowDownVector /> }
            </IconButton>
          </HeaderExpandAction>
        </React.Fragment>
      )}

      <TwoActionDialog
        variant = { lookAndFeel.deleteConfirmVariant }
        opened = { isConfirmOpened }
        caption = 'Удалить выбранный дефект?'
        isDanger
        cancelLabel = 'Отмена'
        actionLabel = 'Удалить'
        onCancel = { onConfirmDeleteCancelHandler }
        onAction = { onConfirmDeleteAcceptHandler }
      />
    </Header>
  )
}

const DefectItemDateTime: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dateTime = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectDateTime(store, defectGuid));

  return (
    <DefectDateTime lookAndFeel = { lookAndFeel }>
      <EditVector />
      { dateTime === null ? '' : `${new Date(dateTime).toLocaleDateString()} ${Util.timeHHMM(new Date(dateTime))}` }
    </DefectDateTime>
  )
}

const DefectItemDefect: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const defectId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectDefectId(store, defectGuid));
  const defectAll = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralDefectRefer);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const inspectionStatusCode = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionStatusCode)
  const defectStr = defectAll.find((item) => item.id === defectId)?.title ?? '';
  
  const isEditAllowed = [InspectionState.NEW, InspectionState.CREATE, InspectionState.DRAFT, InspectionState].includes(inspectionState)
    || 
    defectId === null
    ||
    inspectionStatusCode === InspectionStatusCode.INTERNAL;

  const favoriteList = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralFavoriteDefects);

  const [selectorOpened, setSelectorOpened] = React.useState<boolean>(false);

  const onFavoriteChangeHandler = (defectId: number, isFavorite: boolean) => {
     dispatch(storeApi.inspection.edit.async.changeDefectFavoriteAsync({ defectId, isFavorite }));
  }

  const onSelectHandler = (node: DefectReferElement) => {
    dispatch(storeApi.inspection.edit.actions.defectDefectChanged(node.id));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onClearHandler = () => {
    dispatch(storeApi.inspection.edit.actions.defectDefectChanged(null));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Вид дефекта' isRequired>
        <FieldWithSelector lookAndFeel = { lookAndFeel }>
          <InputTextbox
            variant = { lookAndFeel.textboxVariant }
            value = { defectStr }
            readOnly
            isDisabled = { !isEditAllowed }
            isClearHidden
            onChange = { onClearHandler }
            placeholder = ''
          />
          <IconButton
            variant = { lookAndFeel.selectorButtonVariant }
            isTapAllowed = { isEditAllowed }
            onTap = { () => setSelectorOpened(true) }
          >
            <ListVector />
          </IconButton>
        </FieldWithSelector>
      </Labeled>
      <DefectSelector
        variant = { lookAndFeel.defectSelectorVariant }
        defectList = { defectAll.filter((item) => item.isActual) }
        favoriteList = { favoriteList }
        isOpened = { selectorOpened }
        onClose = { () => setSelectorOpened(false) }
        onFavoriteChange = { onFavoriteChangeHandler }
        onSelect = { onSelectHandler }
      />
    </React.Fragment>
  )
}

const DefectExternalNumber: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const externalNumber = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectExternalNumber(store, defectGuid));

  const onChangeHandler = (value: string) => {
    dispatch(storeApi.inspection.edit.actions.defectExternalNumberChanged(value));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting || account.role !== types.auth.AccountRole.Stpk)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Внешний номер обращения'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { externalNumber ?? '' }
        maxLength = { 250 }
        onChange = { onChangeHandler }
      />
    </Labeled>
  )
}

const DefectItemPlaceOfFixation: React.FC<DefectItemSubComponentProps> = ({ 
  lookAndFeel,
  defectGuid
}) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const defectId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectDefectId(store, defectGuid));
  const placeId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectPlaceOfFixationId(store, defectGuid));
  const placeAll = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralPlaceOfFixationRefer);
  const placeStr = placeAll.find((item) => item.id === placeId)?.title ?? '';

  const isRequired = [AccountRole.Client, AccountRole.ClientAdmin, AccountRole.Stpk].includes(account.role) && defectId === 99;

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = placeAll.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.defectPlaceOfFixationChanged(parseInt(item.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  /**
   * mobi-1541 Для сварного шва (id = 99) место фиксации обязательно для клиента, клиента-админа и стпк
   */
  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Место фиксации' isRequired={isRequired}>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { placeStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = ''
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </Labeled>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Место фиксации'
        selectedId = { placeId ? `${placeId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const DefectItemPriority: React.FC<DefectItemSubComponentProps> = ({ 
  lookAndFeel,
  defectGuid
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const priorityId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectPriorityId(store, defectGuid));
  const prioritiesAll = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralPriorityRefer);
  const priorityStr = prioritiesAll.find((item) => item.id === priorityId)?.title ?? '';

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = prioritiesAll.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.defectPriorityChanged(parseInt(item.id, 10))); 
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting || account.role !== AccountRole.Stpk)
  {
    return null;
  }

  /**
   * mobi-1540 Убрали обязательность поля Приоритет для STPK.
   */
  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Приоритет'>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { priorityStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = ''
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </Labeled>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Приоритет'
        selectedId = { priorityId ? `${priorityId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const DefectItemProduct: React.FC<DefectItemSubComponentProps> = ({ 
  lookAndFeel,
  defectGuid
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const placeId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectPlaceOfFixationId(store, defectGuid));
  const product = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectProduct(store, defectGuid));
  const products = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralProductRefer);

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = products.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.defectProductChanged(item.label));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting || placeId !== 3)
  {
    return null;
  }

  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Укажите название изделия'>
        <FieldWithSelector lookAndFeel = { lookAndFeel }>
          <InputTextbox
            variant = { lookAndFeel.textboxVariant }
            value = { product ?? '' }
            onChange = { (value: string) => {
              dispatch(storeApi.inspection.edit.actions.defectProductChanged(value));
              setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
            } }
          />
          <IconButton variant = { lookAndFeel.selectorButtonVariant } isTapAllowed onTap = { () => setReferOpened(true) }>
            <ListVector />
          </IconButton>
        </FieldWithSelector>
      </Labeled>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Изделие'
        selectedId = { null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const DefectItemNote: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const note = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectNote(store, defectGuid));

  const onChangeHandler = (value: string) => {
    dispatch(storeApi.inspection.edit.actions.defectNoteChanged(value));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Детальное описание дефекта (прочие комментарии)'>
      <InputMultiline
        variant = { lookAndFeel.multilineVariant }
        value = { note ?? '' }
        onChange = {  onChangeHandler }
      />
    </Labeled>
  )
}

const DefectItemViolatedRequirements: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const isViolationOfRequirements = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectIsViolationOfRequirements(store, defectGuid));
  const violatedRequirements = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectViolatedRequirements(store, defectGuid));

  const onChangeFlagHandler = (checked: boolean) => {
    dispatch(storeApi.inspection.edit.actions.defectIsViolationOfRequirementsChanged(checked));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onChangeValueHandler = (value: string) => {
    dispatch(storeApi.inspection.edit.actions.defectViolatedRequirementsChanged(value));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Нарушение требований стандарта и заказа</Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { isViolationOfRequirements }
          onChange = { onChangeFlagHandler }
        />
      </FieldWithSwitch>
      {isViolationOfRequirements && (
        <Labeled variant = { lookAndFeel.labeledVariant } label = 'Укажите какое требование нарушено' isRequired>
          <InputTextbox
            variant = { lookAndFeel.textboxVariant }
            value = { violatedRequirements ?? '' }
            onChange = {  onChangeValueHandler }
          />
        </Labeled>
      )}
    </React.Fragment>
  )
}

const DefectItemWayToUseRegected: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const isPossibilityUsingRejected = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectIsPossibilityUsingRejected(store, defectGuid));
  const wayToUseRegectedId = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectWayToUseRegectedId(store, defectGuid));
  const wayToUseRegectedAll = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralWaysToUseRegectedRefer);
  const wayToUseRegectedStr = wayToUseRegectedAll.find((item) => item.id === wayToUseRegectedId)?.title ?? '';

  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const items = wayToUseRegectedAll.filter((item) => item.isActual).map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))

  const onChangeFlagHandler = (checked: boolean) => {
    dispatch(storeApi.inspection.edit.actions.defectPossibilityUsingRejectedChanged(checked));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onChangeValueHandler = (value: ReferSelectorItem) => {
    dispatch(storeApi.inspection.edit.actions.defectWayToUseRegectedChanged(parseInt(value.id, 10)));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Возможность использования забракованного проката </Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { isPossibilityUsingRejected }
          onChange = { onChangeFlagHandler }
        />
      </FieldWithSwitch>
      {isPossibilityUsingRejected && (
        <React.Fragment>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Способ использ. забракованного проката' isRequired>
            <InputTextbox
              variant = { lookAndFeel.textboxVariant }
              value = { wayToUseRegectedStr }
              readOnly
              onChange = { (value: string) => {  } }
              placeholder = ''
              customIcon = { <ArrowDownVector /> }
              onCustomIconTap = { () => setReferOpened(true) }
            />
          </Labeled>
          <ReferSelector
            variant = { lookAndFeel.referSelectorVariant }
            referItemsList = { items }
            isOpened = { referOpened }
            label = 'Способ использования'
            selectedId = { wayToUseRegectedId ? `${wayToUseRegectedId}` : null }
            onClose = { () => setReferOpened(false) }
            onSelect = { onChangeValueHandler }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const JoinInspection: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defectGuid,
}) => {
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [guidForDelete, setGuidForDelete] = React.useState<string>('');
  const [confirmDeleteOpened, setConfirmDeleteOpened] = React.useState<boolean>(false);
  const [warningAppendOpened, setWarningAppendOpened] = React.useState<boolean>(false);
  const [warningAppendMessage, setWarningAppendMessage] = React.useState<string>('');

  const files = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectFiles(store, defectGuid)) ?? [];
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  
  const pdfs = files.filter((file) => file.type === ACT_JOIN_INSP && file.mode !== MODE_DEL);
  const pdfCountAllow = 1 - pdfs.length;

  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);
  const isJoinInspectionExpanded = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectIsJoinInspectionExpanded(store, defectGuid));
  const joinInspectionAct = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectJoinInspectionAct(store, defectGuid));
  const joinInspectionTimestamp = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectJoinInspectionTimestamp(store, defectGuid));

  const onChangeFlagHandler = (checked: boolean) => {
    dispatch(storeApi.inspection.edit.actions.defectJoinInspectionChanged(checked));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onChangeActValueHandler = (value: string) => {
    dispatch(storeApi.inspection.edit.actions.defectJoinInspectionActChanged(value));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onChangeTimestampValueHandler = (value: Nullable<Date>) => {
    dispatch(storeApi.inspection.edit.actions.defectJoinInspectionTimestampChanged(value ? value.valueOf() : null));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  const onPDFSelectedHandler = async (list: FileList) => {
    const listAsArray = Array.from(list);
    let warningMessage = '';

    if (list.length > pdfCountAllow)
    {
      warningMessage = `Вы выбрали ${list.length} документов, можно добавить только ${pdfCountAllow}. Лишние документы не добавлены`;
    }

    if (warningMessage !== '')
    {
      setWarningAppendMessage(warningMessage);
      setWarningAppendOpened(true);
    }

    const pdfList = listAsArray.length > pdfCountAllow ? listAsArray.slice(0, pdfCountAllow) : listAsArray;

    for await (const file of pdfList)
    {
      const fileTypeByExt = Util.checkFileTypeByExt(file);

      if (!['PDF'].includes(fileTypeByExt))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      const fileTypeByHeader = await Util.checkFileTypeByHeader(file);

      if (!['PDF'].includes(fileTypeByHeader))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      if ((file.size / Math.pow(1024, 2)) > Constants.PIECE_PDF_BIGGEST_SIZE_MB)
      {
        alert.error(`Размер файла ${file.name} превышает допустимый размер прикрепляемого файла в ${Constants.PIECE_PDF_BIGGEST_SIZE_MB} Мбайт`);
        continue;
      }

      const guid = uuidv4();
      const binaryStr = await Util.fileToBinaryString(file);
      const dataUrlResult = await Util.pdfToDataURL(file);

      const fileName = Util.buildDefectAttachmentName(ACT_JOIN_INSP, file.name);

      if (dataUrlResult.ok)
      {
        dispatch(storeApi.inspection.edit.actions.defectDocumentAdded({
          guid,
          mode: MODE_NEW,
          name: fileName,
          type: ACT_JOIN_INSP,
          __dataUrl: dataUrlResult.result,
          __binaryStr: binaryStr,
          __mime: file.type,
          __name: fileName
        }));
      }
      else
      {
        alert.error(`Произошла ошибка при обработке файла`);
      }
    }

    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAddHandler = () => {
    document.getElementById(`defect-pdf-selector`)?.click();
  }

  const onDeleteHandler = (guid: string) => {
    setConfirmDeleteOpened(true);
    setGuidForDelete(guid);
  }

  const onCancelDeleteHandler = () => {
    setConfirmDeleteOpened(false);
  }

  const onAcceptDeleteHandler = () => {
    setConfirmDeleteOpened(false);
    dispatch(storeApi.inspection.edit.actions.defectDocumentDeleted(guidForDelete));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAcceptWarningAppendHandler = () => {
    setWarningAppendOpened(false);
  }

  if (isEntryAccounting || account.role === types.auth.AccountRole.Contractor)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Совместная инспекция</Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { isJoinInspectionExpanded }
          onChange = { onChangeFlagHandler }
        />
      </FieldWithSwitch>
      {isJoinInspectionExpanded && (
        <React.Fragment>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Номер акта СИ' isRequired>
            <InputTextbox
              variant = { lookAndFeel.textboxVariant }
              value = { joinInspectionAct ?? '' }
              onChange = { onChangeActValueHandler }
            />
          </Labeled>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Дата СИ' isRequired>
            <InputCalendar
              variant = { lookAndFeel.calendarVariant }
              value = { joinInspectionTimestamp ? new Date(joinInspectionTimestamp) : null }
              onChange = { onChangeTimestampValueHandler }
            />
          </Labeled>
          <PhotoList lookAndFeel = { lookAndFeel }>
            {pdfs.map((pdf) => (
              <AttachmentPDF
                key = { pdf.guid }
                variant = { lookAndFeel.attachmentPDFVariants }
                isAllowDelete
                pdf = { pdf }
                source = 'inspection'
                onDelete = { onDeleteHandler }
              />
            ))}
            {pdfCountAllow > 0 && (
              <AttachmentAdd
                variant = { lookAndFeel.attachmentAddVariants }
                attachmentType = { types.inspection.PieceFileType.PDF }
                allowCount = { pdfCountAllow }
                label = 'Акт СИ'
                isRequired
                onTap = { onAddHandler }
              />
            )}
          </PhotoList>
          <input
            id = 'defect-pdf-selector'
            type = 'file'
            accept = '.pdf'
            onChange = { (e) => e.target.files !== null && onPDFSelectedHandler(e.target.files) }
            onClick = { () => (document.getElementById('defect-pdf-selector') as HTMLInputElement).value = '' }
            style = { { display: 'none' } }
          />
          <TwoActionDialog 
            variant = { lookAndFeel.pdfDialogVariant } 
            opened = { confirmDeleteOpened }
            caption = 'Удалить акт СИ?'
            onCancel = { onCancelDeleteHandler }
            onAction = { onAcceptDeleteHandler }
          />
          <TwoActionDialog 
            variant = { lookAndFeel.pdfDialogVariant } 
            opened = { warningAppendOpened }
            caption = 'Внимание'
            message = { warningAppendMessage }
            withoutCancel
            actionLabel = 'Ok'
            isDanger
            onAction = { onAcceptWarningAppendHandler }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const Separator: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Hr lookAndFeel = { lookAndFeel } />
  )
}


type Props = {
  variant: DefectItemVariants;
  defectGuid: string;
}
export const DefectItem: React.FC<Props> = ({
  variant,
  defectGuid,
}) => {
  const lookAndFeel = defectitem_variants.get(variant)!;

  const expandedGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectExpandedGuid);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);

  const isExpanded = expandedGuid === defectGuid || isEntryAccounting;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <DefectItemHeader lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
      {isExpanded && (
        <DefectBody lookAndFeel = { lookAndFeel }>
          <DefectItemDateTime lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemDefect lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectExternalNumber lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemPriority lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemPlaceOfFixation lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemProduct lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemNote lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <Separator lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemViolatedRequirements lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <Separator lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <DefectItemWayToUseRegected lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <Separator lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <JoinInspection lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <Separator lookAndFeel = { lookAndFeel } defectGuid = { defectGuid } />
          <PieceListContainer lookAndFeel = { lookAndFeel }>
            <PieceList
              variant='light'
              defectGuid = { defectGuid }
            />
          </PieceListContainer>
          <PieceEdit variant = { lookAndFeel.pieceEditVariant } />
        </DefectBody>
      )}
    </Container>
  );
};
