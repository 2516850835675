import React from 'react';



type Props = {
  className?: string;
}
export const MessageActionApprovedVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '17' viewBox = '0 0 16 17' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M4 7.5L7 10.5L12 5.5' />
    </svg>
  );
};
