import * as React                      from 'react';

import { Button                      } from 'src/components/common/button';
import { EmptyCaseDanger             } from 'src/components/common/empty-case';

import { DefectItem                  } from 'src/components/features/inspection/view/defect-item';

import { Container, 
         List,
         Actions,
         DefectListFragmentVariants,
         defectlistfragment_variants } from 'src/components/features/inspection/view/defectlist-fragment/ui';

import { AccountRole                 } from 'src/services/api/types/auth';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch              } from 'src/store';



type Props = {
  variant: DefectListFragmentVariants;
}
export const DefectListFragment: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = defectlistfragment_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const defects = useAppSelector(storeApi.inspection.view.selectors.selectDefects);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);
  const is404 = useAppSelector(storeApi.inspection.view.selectors.selectIs404);

  const isJumpToComplaintAllowed = true;
  const isClientAdmin = account.role === AccountRole.ClientAdmin;
  const isStpk = account.role === AccountRole.Stpk;

  if (is404)
  {
    return (
      <Container lookAndFeel = { lookAndFeel }>
        <EmptyCaseDanger
          variant = { lookAndFeel.dangerEmptyCase }
          header = 'Осмотр отсутствует на сервере'
          isDanger
          message = 'Осмотр с ошибкой удален'
          withButton
          buttonText = 'Вернуться к списку уведомлений'
          onButtonTap = { () => dispatch(storeApi.inspection.view.actions.viewClosed()) }
        />
      </Container>
    );
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <List lookAndFeel = { lookAndFeel }>
        {defects.map((defect) => (
          <DefectItem
            variant = { lookAndFeel.defectItemVariant }
            defect = { defect }
            key = { defect.guid }
          />
        ))}
      </List>
      {!isEntryAccounting && (isClientAdmin || isStpk) && (
        <Actions lookAndFeel = { lookAndFeel }>
          <Button
            variant = { lookAndFeel.completeButtonVariant }
            label = 'Перейти к требованиям'
            isDisabled = { !isJumpToComplaintAllowed }
            onTap = { () => dispatch(storeApi.inspection.view.actions.changeViewFragment('complaintlist')) }
          />
        </Actions>
      )}
    </Container>
  );
};
