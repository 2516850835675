import * as React           from 'react';

import { IconButton       } from 'src/components/common/icon-button';

import { Container,
         ButtonContainer,
         Label,
         FakeButton,
         NavBarVariants,
         navbar_variants  } from 'src/components/features/common/nav-bar/ui';



export type NavBarButton = {
  id: string;
  vector: React.ReactNode;
  isDot?: boolean;
  isRed?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  action: () => void;
}

type Props = {
  variant: NavBarVariants;
  label: string;
  startButtons?: NavBarButton[];
  endButtons?: NavBarButton[];
}
export const NavBar: React.FC<Props> = ({
  variant,
  label,
  startButtons = [],
  endButtons = [],
}) => {
  const lookAndFeel = navbar_variants.get(variant)!;
  const sbl = startButtons.length;
  const ebl = endButtons.length;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {startButtons.length > 0 && (
        <ButtonContainer lookAndFeel = { lookAndFeel }>
          {startButtons.map((button) => 
            <IconButton
              key = { button.id }
              variant = { lookAndFeel.buttonVariant }
              isTapAllowed = { !button.isDisabled }
              isDot = { button.isDot && !button.isDisabled }
              isSelected = { button.isSelected }
              isDisabled = { button.isDisabled }
              onTap = { button.action }
            >
              { button.vector }
            </IconButton>
          )}
          {sbl < ebl && new Array(ebl - sbl).fill(0).map((_) => <FakeButton lookAndFeel = { lookAndFeel } />)}
        </ButtonContainer>
      )}
      <Label lookAndFeel = { lookAndFeel }>
        {label}
      </Label>
      {endButtons.length > 0 && (
        <ButtonContainer lookAndFeel = { lookAndFeel }>
          {endButtons.map((button) => 
            <IconButton
              key = { button.id }
              variant = { lookAndFeel.buttonVariant }
              isTapAllowed = { !button.isDisabled }
              isDot = { button.isDot && !button.isDisabled }
              isSelected = { button.isSelected }
              isDisabled = { button.isDisabled }
              onTap = { button.action }
            >
              {button.vector}
            </IconButton>
          )}
        </ButtonContainer>
      )}
      {ebl < sbl && new Array(sbl - ebl).fill(0).map((_) => <FakeButton lookAndFeel = { lookAndFeel } />)}
    </Container>
  );
};
