import styled from 'styled-components';



export const Container = styled.div<{
  size: number
}>`
  width: ${(args) => args.size}px;
  flex-shrink: 0;
`
