import { NavigateFunction } from 'react-router-dom';



export class BaseUrl {
  protected _url: string = '';
  static navigate: NavigateFunction;

  constructor(private readonly _pattern: string) { }

  pushState(state: any) {
    //BaseUrl.navigate('', { state });
    window.history.pushState(state, '');
    //window.history.replaceState()
    return this;
  }

  navigateWithReplace(state?: any) {
    BaseUrl.navigate(this._url, { replace: true, state });
    return this;
  }

  navigate() {
    BaseUrl.navigate(this._url);
    return this;
  }

  get pattern() {
    return this._pattern;
  }
}