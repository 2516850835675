import styled           from 'styled-components';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS    } from 'src/providers/theme/shared';



type DateLabelLookAndFeel = {
  paddings: number[];

  labelTypo: Typo;
  labelColor: string;
}

const datelabel_light: DateLabelLookAndFeel = {
  paddings: [0, 0, 8, 0],

  labelTypo: themeTypos.GRTSKREG_W600_S12_LH16,
  labelColor: themeColors.AccentBlue2,
}

export type DateLabelVariants = 'light';

export const datelabel_variants: Map<DateLabelVariants, DateLabelLookAndFeel> = 
  new Map<DateLabelVariants, DateLabelLookAndFeel>([
    ['light', datelabel_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DateLabelLookAndFeel
}>`
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}

  color: ${(args) => args.lookAndFeel.labelColor};
`
