import * as React           from 'react';

import { Container,
         RoleLabel,
         VersionLabel,
         VersionVariants,
         version_variants } from 'src/components/features/main/profile/version/ui';



type Props = {
  variant: VersionVariants;
  role: string;
  version: string;
}
export const Version: React.FC<Props> = ({
  variant,
  role,
  version,
}) => {
  const lookAndFeel = version_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <RoleLabel lookAndFeel = { lookAndFeel }>
        { role }
      </RoleLabel>
      <VersionLabel lookAndFeel = { lookAndFeel }>
        { version }
      </VersionLabel>
    </Container>
  );
};
