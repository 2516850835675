import React from 'react';



type Props = {
  className?: string;
}
export const FloppyVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M1 2.5L18 2.5L22 7V21.5L2 21.5L2 6.5M8 21.5V16L16 16L16 21.5' />
    </svg>
  );
};
