import * as React                     from 'react';

import { DangerVector               } from 'src/components/vector/danger';
import { CircleCheckVector          } from 'src/components/vector/circle-check';

import { Loading                    } from 'src/components/common/loading';

import { Container,
         Icon,
         IconError,
         IconSuccess,
         ContentContainer,
         ContentLabel,
         ContentAction,
         CloseOutlineVectorBig,
         CloseContainer,
         RefersLoaderVariants,
         refersloader_variants      } from 'src/components/features/main/inspection-list/refers-loader/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi                   } from 'src/store';

import { AsyncOpStatusWithTerminate } from 'src/common';



type Props = {
  variant: RefersLoaderVariants;
}
export const RefersLoader: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = refersloader_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const { BUSY, ERROR, TERMINATE, SUCCESS } = AsyncOpStatusWithTerminate;

  const referLoadingStatus = useAppSelector(storeApi.main.inspectionList.selectors.selectReferLoadingStatus);

  React.useEffect(() => {
    if (referLoadingStatus === AsyncOpStatusWithTerminate.IDLE)
    {
      dispatch(storeApi.main.inspectionList.async.downloadRefersAsync());
    }
  }, [referLoadingStatus, dispatch]);

  const onActionHandler = () => {
    dispatch(storeApi.main.inspectionList.async.downloadRefersAsync());
  }

  const onSuccessHandler = () => {
    dispatch(storeApi.main.inspectionList.actions.refersLoadingStatusChanged(AsyncOpStatusWithTerminate.TERMINATE));
  }

  if (referLoadingStatus === TERMINATE)
  {
    return null;
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {[BUSY, ERROR, SUCCESS].includes(referLoadingStatus) && (
        <Icon lookAndFeel = { lookAndFeel }>
          {referLoadingStatus === BUSY && (
            <Loading variant = { lookAndFeel.loadingVariant } />
          )}
          {referLoadingStatus === ERROR && (
            <IconError lookAndFeel = { lookAndFeel }>
              <DangerVector />
            </IconError>
          )}
          {referLoadingStatus === SUCCESS && (
            <IconSuccess lookAndFeel = { lookAndFeel }>
              <CircleCheckVector />
            </IconSuccess>
          )}
        </Icon>
      )}
      <ContentContainer lookAndFeel = { lookAndFeel }>
        {referLoadingStatus === BUSY && (
          <ContentLabel lookAndFeel = { lookAndFeel }>Справочники загружаются</ContentLabel>
        )}
        {referLoadingStatus === ERROR && (
          <React.Fragment>
            <ContentLabel lookAndFeel = { lookAndFeel }>Справочники не были загружены</ContentLabel>
            <ContentAction lookAndFeel = { lookAndFeel } onClick = { onActionHandler }>Загрузить справочники</ContentAction>
          </React.Fragment>
        )}
        {referLoadingStatus === SUCCESS && (
          <ContentLabel lookAndFeel = { lookAndFeel }>Справочники успешно загружены</ContentLabel>
        )}
      </ContentContainer>
      {referLoadingStatus === SUCCESS && (
        <CloseContainer lookAndFeel = { lookAndFeel } onClick = { onSuccessHandler }>
          <CloseOutlineVectorBig />
        </CloseContainer>
      )}
    </Container>
  );
};
