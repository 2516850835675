import React from 'react';



type Props = {
  className?: string;
}
export const EyeHideVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <g clipPath = 'url(#clip0_17981_27623)'>
        <path
          d = 'M20.9 8.5L23 10.6V13.4L16.8889 19H10M14.5 5H7.11111L1 10.6L1 13.4L3.5 16'
          stroke = 'currentColor'
          strokeWidth = '2'
        />
        <path
          d = 'M8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8'
          stroke = 'currentColor'
          strokeWidth = '2'
        />
        <line
          x1 = '23.7071'
          y1 = '0.707107'
          x2 = '-0.292894'
          y2 = '24.7071'
          stroke = 'currentColor'
          strokeWidth = '2'
        />
      </g>
      <defs>
        <clipPath id = 'clip0_17981_27623'>
          <rect width = '24' height = '24' fill = 'white'/>
        </clipPath>
      </defs>
    </svg>
  );
};
