import styled,
       { keyframes   } from 'styled-components';

import { themeColors } from 'src/providers/theme/shared';



type LoadingLookAndFeel = {
  width: number;
  height: number;
  bgColor: string;
  activeColor: string;
}

const loading_lightblue24: LoadingLookAndFeel = {
  width: 24,
  height: 24,
  bgColor: themeColors.SecondaryGray2,
  activeColor: themeColors.AccentBlue2,
}

export type LoadingVariants = 'lightblue24';

export const loading_variants: Map<LoadingVariants, LoadingLookAndFeel> = 
  new Map<LoadingVariants, LoadingLookAndFeel>([
    ['lightblue24', loading_lightblue24],
  ]);

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{
  lookAndFeel: LoadingLookAndFeel
}>`
  border-radius: 50%;
  width: ${(args) => args.lookAndFeel.width}px;
  height: ${(args) => args.lookAndFeel.height}px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid ${(args) => args.lookAndFeel.bgColor};
  border-right: 2px solid ${(args) => args.lookAndFeel.activeColor};
  border-bottom: 2px solid ${(args) => args.lookAndFeel.activeColor};
  border-left: 2px solid ${(args) => args.lookAndFeel.activeColor};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: ${spin} 1.1s infinite linear;


  &:after {
    border-radius: 50%;
    width: ${(args) => args.lookAndFeel.width}px;
    height: ${(args) => args.lookAndFeel.height}px;
  }
`
