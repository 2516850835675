import * as React                     from 'react';
 
import { useAlert                   } from 'react-alert';

import { useTheme                   } from 'styled-components';
 
import { ArrowBackVector            } from 'src/components/vector/arrowback';
import { TrashVector                } from 'src/components/vector/trash';
import { CloseVector                } from 'src/components/vector/close';

import { BlockUI                    } from 'src/components/common/block-ui';
import { Dialog                     } from 'src/components/common/dialog';
import { EmptyCaseEnvelope          } from 'src/components/common/empty-case';
import { Tags,
         TagItem                    } from 'src/components/common/tags';

import { NavBar                     } from 'src/components/features/common/nav-bar';

import { DateLabel                  } from 'src/components/features/main/notifications/date-label';
import { Notification               } from 'src/components/features/main/notifications/notification';
import { DeleteConfirmDialog        } from 'src/components/features/main/notifications/delete-confirm-dialog';

import { NotificationsLayout,
         Screen,
         ScreenBody,
         TagsContainer,
         NotificationsContainer,
         layout_light,
         layout_dark                } from 'src/containers/main/notifications/layout';

import { InspectionViewDialog       } from 'src/containers/inspection/view';

import { Urls                       } from 'src/providers/routing';

import Api                            from 'src/services/api';
import * as types                     from 'src/services/api/types';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                   } from 'src/store';
import { DeleteNotificationsReject,
         ReadNotificationsReject    } from 'src/store/main/notifications';

import { AsyncOpStatus              } from 'src/common';



const {
  ALL,
  NEW_INSPECTION,
  CHANGE_STATUS_INSPECTION, 
  ERROR_INSPECTION,
  CHANGE_STEP_8D,
  READ_MESSAGE,
  CHANGE_STEP_QMMSG,
  SURVEY } = types.notification.NotificationCode;

const filterItems: TagItem[] = [
  { id: ALL, label: 'Все' },
  { id: ERROR_INSPECTION, label: 'Ошибка в осмотре' },
  { id: CHANGE_STATUS_INSPECTION, label: 'Новый статус' },
  { id: NEW_INSPECTION, label: 'Новый осмотр' },
  { id: CHANGE_STEP_QMMSG, label: 'Статус по претензии' },
  { id: CHANGE_STEP_8D, label: '8D' },
  { id: READ_MESSAGE, label: 'Сообщения' },
  { id: SURVEY, label: 'Опросы' },
]

type NotificationsScreenElement = { layout: NotificationsLayout; dispatch: AppDispatch; }

const Navigation: React.FC<NotificationsScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const [dialogOpened, setDialogOpened] = React.useState<boolean>(false);

  const onConfirmRemoveNotificationsHandler = () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.main.notifications.async.deleteNotificationsAsync())
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        setDialogOpened(false);
        alert.success('Уведомления были успешно удалены');
      })
      .catch((rawError) => {
        const error = rawError as DeleteNotificationsReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Уведомления'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainInspections.build().navigate()
          }
        ]}
        endButtons={[
          {
            id: 'trash',
            vector: <TrashVector />,
            action: () => setDialogOpened(true)
          }
        ]}
      />
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = 'Удаление уведомлений'
      />
      <Dialog variant = { layout.dialogVariant } isOpened = { dialogOpened }>
        <DeleteConfirmDialog
          variant = { layout.deleteConfirmDialogVariant }
          onCancel = { () => setDialogOpened(false) }
          onAccept = { onConfirmRemoveNotificationsHandler }
        />
      </Dialog>
    </React.Fragment>
  )
}

const Filters: React.FC<NotificationsScreenElement> = ({ layout, dispatch }) => {
  const filter = useAppSelector(storeApi.main.notifications.selectors.selectFilter);

  const selectedTags = filterItems.filter((item) => filter.find((one) => item.id === one) !== undefined);
  const onTagsChangeHandler = (tags: TagItem[]) => {
    dispatch(storeApi.main.notifications.actions.filterChanged(tags.map((item) => item.id) as types.notification.NotificationCode[]));
  }

  return (
    <TagsContainer layout = { layout }>
      <Tags
        variant = { layout.tagsVariant }
        items = { filterItems }
        value = { selectedTags }
        allId = { ALL }
        singleselect
        paddings = { layout.paddings[1] }
        onChange = { onTagsChangeHandler }
      />
    </TagsContainer>
  )
}

const Notifications: React.FC<NotificationsScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const list = useAppSelector(storeApi.main.notifications.selectors.selectFilteredList);
  const opStatus = useAppSelector(storeApi.main.notifications.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.notifications.selectors.selectOpStatusLabel);
  const surveyOpened = useAppSelector(storeApi.main.notifications.selectors.selectSurveyOpened);
  const surveyCaption = useAppSelector(storeApi.main.notifications.selectors.selectSurveyCaption);
  const surveyImageUrl = useAppSelector(storeApi.main.notifications.selectors.selectSurveyImageUrl);
  const surveyUrl = useAppSelector(storeApi.main.notifications.selectors.selectSurveyUrl);

  const notificationReadTaskExecuted = React.useRef<boolean>(false);

  const dateList = list.reduce(
    (acc, v) => {
      if (acc.length === 0)
      {
        return [new Date(v.timestamp).toLocaleDateString()];
      }
      else
      {
        const dateStr = new Date(v.timestamp).toLocaleDateString();
        if (!acc.includes(dateStr))
        {
          return [...acc, dateStr];
        }
        else
        {
          return acc;
        }
      }
    },
    [] as string[]
  );

  React.useEffect(() => {
    if (notificationReadTaskExecuted.current)
    {
      return;
    }

    notificationReadTaskExecuted.current = true;
    dispatch(storeApi.main.notifications.async.readNotificationsAsync())
      .unwrap()
      .then((result) => {
        notificationReadTaskExecuted.current = false;
      })
      .catch((rawError) => {
        notificationReadTaskExecuted.current = false;
        const error = rawError as ReadNotificationsReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      })
  }, [alert, dispatch])

  const onNotificationTap = (notification: types.notification.Notification) => {
    switch (notification.code)
    {
      case NEW_INSPECTION:
      case CHANGE_STATUS_INSPECTION:
      case ERROR_INSPECTION:
        if (notification.guidSource !== null)
        {
          dispatch(storeApi.inspection.view.async.openForViewAsync({ viewGuid: notification.guidSource }));
        }
        break;
      case CHANGE_STEP_8D:
        break;
      case READ_MESSAGE:
        break;
      case CHANGE_STEP_QMMSG:
        break;
      case SURVEY:
        dispatch(storeApi.main.notifications.async.openSurveyAsync(notification.idSource ?? 0))
          .unwrap()
          .then((result) => {
            
          })
          .catch((rawError) => {
            alert.error(rawError);
          });
        break;
    }
  }

  const onCloseSurveyHandler = () => {
    dispatch(storeApi.main.notifications.actions.surveyClosed());
  }

  return (
    <React.Fragment>
      {list.length === 0 && (
        <EmptyCaseEnvelope
          variant = { layout.emptyCaseVariant }
          header = 'Уведомления отсутствуют'
        />
      )}
      {list.length > 0 &&
        <React.Fragment>
          {dateList.map((date) => (
            <React.Fragment key = { date }>
              <DateLabel variant = { layout.dateLabelVariant } date = { date } />
              <NotificationsContainer layout = { layout }>
                {list
                  .filter((notification) => new Date(notification.timestamp).toLocaleDateString() === date)
                  .map((notification) => (
                    <Notification
                      key = { notification.id }
                      variant = { layout.notificationVariant }
                      notification = { notification }
                      onTap = { onNotificationTap }
                    />
                  ))
                }
              </NotificationsContainer>
            </React.Fragment>
          ))}
        </React.Fragment>
      }
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
      {surveyOpened && (
        <div className='swiper-slide fixed'>
          <img src = { surveyImageUrl } alt = '' />
          <div className='survey-close' onClick={ onCloseSurveyHandler }>
            <CloseVector />
          </div>
          <a href = { surveyUrl } target = '_blank' rel = 'noreferrer' onClick={ onCloseSurveyHandler }>Пройти опрос</a>
          <div className = 'survey-container'>
            <div className = 'survey-caption'>
              Опрос
            </div>
            <div className = 'survey-name'>
              { surveyCaption }
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export const NotificationsScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <Filters layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Notifications layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
      <InspectionViewDialog variant = { layout.inspectionViewVariant } />
    </Screen>
  );
};
