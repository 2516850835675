import * as React                         from 'react';

import { useAlert                       } from 'react-alert';

import { isDesktop                      } from 'react-device-detect';

import { useTheme                       } from 'styled-components';

import { InspectionMenuViewVector       } from 'src/components/vector/inspection-menu/view';
import { InspectionMenuEditVector       } from 'src/components/vector/inspection-menu/edit';
import { InspectionMenuTransferVector   } from 'src/components/vector/inspection-menu/transfer';
import { InspectionMenuEmailVector      } from 'src/components/vector/inspection-menu/email';
import { InspectionMenuStatusVector     } from 'src/components/vector/inspection-menu/status';
import { InspectionMenuMessageVector    } from 'src/components/vector/inspection-menu/message';
import { InspectionMenuTrashVector      } from 'src/components/vector/inspection-menu/trash';
import { InspectionMenuClockVector      } from 'src/components/vector/inspection-menu/clock';
import { InspectionMenuRevocationVector } from 'src/components/vector/inspection-menu/revocation';

import { BottomSheet                    } from 'src/components/common/bottom-sheet';
import { BlockUI                        } from 'src/components/common/block-ui';
import { EmptyCaseEnvelope,
         EmptyCaseLoupe                 } from 'src/components/common/empty-case';
import { Dialog                         } from 'src/components/common/dialog';
import { BottomSheetMenuItem            } from 'src/components/common/bottom-sheet/component';
import { TwoActionDialog                } from 'src/components/common/two-action-dialog';
import { Loading                        } from 'src/components/common/loading';

import { Toolbar                        } from 'src/components/features/main/common/toolbar';

import { NavBar                         } from 'src/components/features/main/inspection-list/nav-bar';
import { Ads                            } from 'src/components/features/main/inspection-list/ads';
import { Header                         } from 'src/components/features/main/inspection-list/header';
import { Inspection                     } from 'src/components/features/main/inspection-list/inspection';
import { Filter                         } from 'src/components/features/main/inspection-list/filter';
import { AppRating                      } from 'src/components/features/main/inspection-list/app-rating';
import { HelpDialog                     } from 'src/components/features/main/inspection-list/help-dialog';
import { RemarkStatusDialog             } from 'src/components/features/main/inspection-list/remark-status-dialog';
import { QMMsgJumpGuide                 } from 'src/components/features/main/inspection-list/qmmsg-jump-guide';
import { RefersLoader                   } from 'src/components/features/main/inspection-list/refers-loader';
import { Revocation                     } from 'src/components/features/main/inspection-list/revocation-dialog';

import { InspectionViewDialog           } from 'src/containers/inspection/view';

import { InspectionListLayout,
         Screen,
         ScreenBody,
         InspectionList,
         AdvLoading,
         AdvLoadingContainer,
         PageLoadingContainer,
         PageLoading,
         layout_light,
         layout_dark                    } from 'src/containers/main/inspection-list/layout';

import { Urls                           } from 'src/providers/routing';

import Api                                from 'src/services/api';
import * as types                         from 'src/services/api/types';
import { InspectionState,
         InspectionType                 } from 'src/services/api/types/inspection';
import Constants                          from 'src/services/constants';
import Preferences                        from 'src/services/preferences';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                       } from 'src/store';
import { RevocationReject               } from 'src/store/main/inspection-list';
import { GetNotificationsReject         } from 'src/store/main/notifications';

import { AsyncOpStatus,
         AsyncOpStatusWithTerminate,
         Nullable                       } from 'src/common';



type InspectionListScreenElement = { layout: InspectionListLayout; dispatch: AppDispatch; }

const Navigation: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const notificationTaskTimer = React.useRef<NodeJS.Timer | null>(null);
  const notificationTaskExecuted = React.useRef<boolean>(false);

  const isUnreadNotifications = useAppSelector(storeApi.main.notifications.selectors.selectHasUnreadNotifications);

  const loadNotifications = React.useCallback(() => {
    if (notificationTaskExecuted.current)
    {
      return;
    }
    
    notificationTaskExecuted.current = true;
    dispatch(storeApi.main.notifications.async.getNotificationsAsync())
      .unwrap()
      .then((result) => {
        notificationTaskExecuted.current = false;
      })
      .catch((rawError) => {
        notificationTaskExecuted.current = false;
        const error = rawError as GetNotificationsReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      })
  }, [alert, dispatch]);

  React.useEffect(() => {
    loadNotifications();
  
    notificationTaskTimer.current = setInterval(() => {
      loadNotifications();
    }, Constants.NOTIFICATION_LOAD_FREQUENCY * 1000);

    return () => {
      if (notificationTaskTimer.current !== null)
      {
        clearInterval(notificationTaskTimer.current);
        notificationTaskTimer.current = null;
      }
    }
  }, [loadNotifications, alert, dispatch]);

  return (
    <NavBar
      variant = { layout.navbarVariant }
      isUnreadNotifications = { isUnreadNotifications }
      onNotificationsTap = { () => Urls.MainNotifications.build().navigate() }
      onProfileTap = { () => Urls.MainProfile.build().navigate() }
    />
  )
}

const BlockUi: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const opStatus = useAppSelector(storeApi.main.inspectionList.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.inspectionList.selectors.selectOpStatusLabel);

  return (
    <BlockUI
      variant = { layout.blockUiVariant }
      isOpened = { opStatus === AsyncOpStatus.BUSY }
      message = { opStatusLabel } 
    />
  )
}

const Adv: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const { IDLE, BUSY, ERROR } = AsyncOpStatusWithTerminate;

  const adv = useAppSelector(storeApi.main.inspectionList.selectors.selectAdv);
  const advStat = useAppSelector(storeApi.main.inspectionList.selectors.selectAdvStat);
  const advIsPriority = useAppSelector(storeApi.main.inspectionList.selectors.selectAdvIsPriority);

  const advLoadingStatus = useAppSelector(storeApi.main.inspectionList.selectors.selectAdvLoadingStatus);

  React.useEffect(() => {
    if (advLoadingStatus === AsyncOpStatusWithTerminate.IDLE)
    {
      dispatch(storeApi.main.inspectionList.async.downloadAdvAsync());
    }
  }, [advLoadingStatus, dispatch]);

  if (advLoadingStatus === ERROR || advLoadingStatus === IDLE)
  {
    return null;
  }

  if (advLoadingStatus === BUSY)
  {
    return (
      <AdvLoadingContainer layout = { layout }>
        <AdvLoading layout = { layout }>
          <Loading variant = { layout.advLoadingVariant } />
        </AdvLoading>
      </AdvLoadingContainer>
    )
  }

  return (
    <Ads
      variant = { layout.adsVariant }
      items = { adv }
      statistics = { advStat }
      paddings = { 16 }
      isPriorityShow = { advIsPriority }
      onSwiperClose = { () => advIsPriority && dispatch(storeApi.main.inspectionList.actions.adsIsPriorityChanged(false)) }
    />
  )
}

const InspectionsHeader: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const inspections = useAppSelector(storeApi.main.inspectionList.selectors.selectInspections);
  const isFilterFilled = useAppSelector(storeApi.main.inspectionList.selectors.selectFiltersFilled);
  const filterOpened = useAppSelector(storeApi.main.inspectionList.selectors.selectFilterOpened);
  const filterIsReport = useAppSelector(storeApi.main.inspectionList.selectors.selectFilterIsReport);
  const filterIsSendEmail = useAppSelector(storeApi.main.inspectionList.selectors.selectWCFilterIsSendEmail);
  const reportRequestListBusy = useAppSelector(storeApi.main.inspectionList.selectors.selectReportRequestListBusy);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (inspections.length === 0 && !isFilterFilled)
  {
    return null;
  }

  const tryDownloadReport = (count: number, fileGuid: string) => {
    window.setTimeout(() => {
      Api.getInspectionListAsExcelByGuid({ guid: fileGuid })
        .then((result) => {
          dispatch(storeApi.main.inspectionList.actions.reportRequestListBusyChanged(false));
        })
        .catch((error) => {
          const newCount = count - 1;
          if (newCount > 0)
          {
            tryDownloadReport(newCount, fileGuid);
          }
          else
          {
            alert.error('Не удалось загрузить запрошенный отчет. Повторите позже или уменьшите период отчета.');
            dispatch(storeApi.main.inspectionList.actions.reportRequestListBusyChanged(false));
          }
        });
    }, 1000);
  };

  const requestReport = (request: types.inspection.GetInspectionListAsReportLinkOut) => {
    alert.success('Отчет формируется и скоро начнется скачивание, не закрывайте окно осмотра');
    dispatch(storeApi.main.inspectionList.actions.reportRequestListBusyChanged(true));
    const calculatedCount = Math.ceil((request.retryAfter + 10000) / 1000);
    tryDownloadReport(
      calculatedCount,
      request.location.substring(request.location.lastIndexOf("/") + 1)
    );
  };

  const onReportHandler = () => {
    dispatch(storeApi.main.inspectionList.actions.filterOpened(true));
  }

  const onFilterHandler = () => {
    dispatch(storeApi.main.inspectionList.actions.filterOpened(false));
  }

  const onCloseHandler = () => {
    dispatch(storeApi.main.inspectionList.actions.filterClosed());
  }

  const onAcceptHandler = () => {
    if (filterIsReport)
    {
      if (filterIsSendEmail)
      {
        dispatch(storeApi.main.inspectionList.async.reportInspectionsAsync())
          .unwrap()
          .then(() => {
            alert.success(`Отчет поставлен в очередь на отправку на email ${account.email}. Ожидайте результат в течение 5 - 10 минут.`);
          })
          .catch(() => {
            alert.error('Произошла ошибка во время формирования отчета. Попробуйте повторить операцию позже.')
          });
      }
      else
      {
        dispatch(storeApi.main.inspectionList.async.reportInspectionsAsLinkAsync())
          .unwrap()
          .then((request) => {
            requestReport(request);
          })
          .catch(() => {
            alert.error('Произошла ошибка во время формирования отчета. Попробуйте повторить операцию позже.')
          });
      }
    }
    else
    {
      dispatch(storeApi.main.inspectionList.async.filterInspectionsAsync({ isStart: true }));
    }
  }

  const onRefreshHandler = () => {
    dispatch(storeApi.main.inspectionList.async.prepareInfoAsync());
  }

  return (
    <React.Fragment>
      <Header
        variant = { layout.headerVaraint }
        isFiltersNotEmpty = { isFilterFilled }
        isReportBusy = { reportRequestListBusy }
        onReport = { onReportHandler }
        onFilter = { onFilterHandler }
        onRefresh = { onRefreshHandler }
      />
      <Filter
        isReport = { filterIsReport }
        isOpened = { filterOpened }
        variant = { layout.filterVariant }
        onClose = { onCloseHandler }
        onAccept = { onAcceptHandler }
      />
    </React.Fragment>
  )
}

const Inspections: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const [bottomSheetOpened, setBottomSheetOpened] = React.useState<boolean>(false);
  const [bottomSheetActions, setBottomSheetActions] = React.useState<string[]>([]);
  const inspections = useAppSelector(storeApi.main.inspectionList.selectors.selectInspections);
  const isFilterFilled = useAppSelector(storeApi.main.inspectionList.selectors.selectFiltersFilled);
  const currentAccount = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const bottomSheetContext = useAppSelector(storeApi.main.inspectionList.selectors.selectBottomSheetContext);
  const reportRequestSingleBusy = useAppSelector(storeApi.main.inspectionList.selectors.selectReportRequestSingleBusy);
  const reportRequestHistoryBusy = useAppSelector(storeApi.main.inspectionList.selectors.selectReportRequestHistoryBusy);

  const [deleteConfirmOpened, setDeleteConfirmOpened] = React.useState<boolean>(false);
  const [deletedInspection, setDeletedInspection] = React.useState<Nullable<types.inspection.Inspection>>(null);

  const onThreeDotTapHandler = (inspection: types.inspection.Inspection) => {
    let actions: string[] = [];

    const { DRAFT, INTERNAL, TAKE_TO_WORK, COMPLETED, ERROR, RETURN_FOR_REVISION, NO_TRANS_DRAFT, NO_TRANS_ERROR, NO_TRANS_COMPLETED } = types.inspection.InspectionStatusCode;
    const { REMARK, COMPLAINT } = types.inspection.ComplaintType;
    const isClient = currentAccount.role === types.auth.AccountRole.Client;
    const isClientAdmin = currentAccount.role === types.auth.AccountRole.ClientAdmin;
    const isStpk = currentAccount.role === types.auth.AccountRole.Stpk;
    const isEntryAccounting = inspection.isEntryAccounting;
    const statusCode = inspection.status.code;
    const complaint = inspection.inspectionDefects === null || inspection.inspectionDefects.length === 0 ? null : inspection.inspectionDefects[0].complaint;
    const typeComplaint = complaint === null ? null : complaint.typeComplaint;
    const isInitiator = inspection.userId === currentAccount.id;
    const isFinanceComplaint = complaint !== null && (complaint.isToScrap || complaint.isEvaluateRolledMetal || complaint.isToOffsetCosts || complaint.isReturnOfRolledMetal || complaint.isSale)

    switch (statusCode)
    {
      case DRAFT:
      case NO_TRANS_DRAFT:
        actions = [
          'view',
          'edit',
          'trash',
        ];
        break;
      case INTERNAL:
        actions = [
          'view',
          'edit',
          isEntryAccounting && (isClientAdmin || isClient) ? 'create' : '',
          isClientAdmin && typeComplaint === REMARK ? 'transfer' : '',
          'email',
          'change-history',
          (isClientAdmin || isInitiator) ? 'trash' : '',
        ].filter((item) => item !== '');
        break;
      case TAKE_TO_WORK:
        actions = [
          'view',
          'edit',
          isClientAdmin && complaint !== null && typeComplaint === REMARK ? 'requirement-remark' : '',
          isClientAdmin && inspection.inspectionType === InspectionType.NOTIFICATION && typeComplaint === COMPLAINT && !isFinanceComplaint ? 'requirement-complaint' : '',
          'email',
          isClientAdmin || isStpk ? 'status' : '',
          'message',
          'change-history',
          isClientAdmin ? 'revocation' : '',
        ].filter((item) => item !== '');
        break;
      case COMPLETED:
        actions = [
          'view',
          'edit',
          isClientAdmin && complaint !== null && typeComplaint === REMARK ? 'requirement-remark' : '',
          isClientAdmin && inspection.inspectionType === InspectionType.NOTIFICATION && typeComplaint === COMPLAINT && !isFinanceComplaint ? 'requirement-complaint' : '',
          'email',
          isClientAdmin || isStpk ? 'status' : '',
          'message',
          'change-history',
          isClientAdmin ? 'revocation' : '',
        ].filter((item) => item !== '');
        break;
      case RETURN_FOR_REVISION:
        actions = [
          'view',
          isClientAdmin || isStpk ? 'status' : '',
          'message',
          'change-history',
          isClientAdmin ? 'revocation' : '',
        ].filter((item) => item !== '');
        break;
      case ERROR:
      case NO_TRANS_ERROR:
        actions = [
          'view',
          'edit',
          'change-history',
          'trash',
        ];
        break;
      case NO_TRANS_COMPLETED:
        actions = [
          'view',
          'edit',
          'change-history',
        ];
        break;
      default:
        actions = [
          'view',
          'change-history',
        ];
    }

    if (actions.length > 0)
    {
      dispatch(storeApi.main.inspectionList.actions.bottomSheetContextChanged(inspection));
      setBottomSheetActions(actions);
      setBottomSheetOpened(true);
    }
  }

  const onQuestionTapHandler = (inspection: types.inspection.Inspection) => {
    dispatch(storeApi.main.inspectionList.actions.helpOpenedChanged(true));
  }

  const onDangerTapHandler = (inspection: types.inspection.Inspection) => {
    Urls.InspectionEdit.build(inspection.guid, getInspectionState(inspection), false, true, false, false).navigate();
  }

  const onBottomSheetCloseHandler = () => {
    setBottomSheetOpened(false);
  }

  const onBottomSheetSelectHandler = (item: BottomSheetMenuItem) => {
    setBottomSheetOpened(false);
    if (bottomSheetContext !== null)
    {
      switch (item.action)
      {
        case 'view':
          dispatch(storeApi.inspection.view.async.openForViewAsync({ viewGuid: bottomSheetContext.guid }));
          break;
        case 'edit':
          Urls.InspectionEdit.build(bottomSheetContext.guid, getInspectionState(bottomSheetContext), false, false, false, false).navigate();
          break;
        case 'create':
          Urls.InspectionEdit.build(bottomSheetContext.guid, InspectionState.CREATE, false, false, false, false).navigate();
          break;
        case 'requirement-remark':
          Urls.InspectionEdit.build(bottomSheetContext.guid, getInspectionState(bottomSheetContext), false, false, true, false).navigate();
          break;
        case 'requirement-complaint':
          Urls.InspectionEdit.build(bottomSheetContext.guid, getInspectionState(bottomSheetContext), false, false, false, true).navigate();
          break;
        case 'transfer':
          dispatch(storeApi.inspection.edit.async.transferToSeverstalAsync({ guid: bottomSheetContext.guid }))
            .unwrap()
            .then(() => {
              alert.success('Осмотр передан в Северсталь');

              dispatch(storeApi.main.inspectionList.async.prepareInfoAsync())
                .unwrap()
                .then(() => {
                  if (Preferences.isAppRating)
                  {
                    setTimeout(() => {
                      dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(true));
                    }, 2000);
                  }
                  else
                  {
                    if (Preferences.isPriorityAdv)
                    {
                      Preferences.removeAppAdvPriorityShow();
                      dispatch(storeApi.main.inspectionList.actions.adsIsPriorityChanged(true));
                    }
                  }
                });
            })
            .catch((error) => alert.error(error))
          break;
        case 'email':
          if (isDesktop)
          {
            if (!reportRequestSingleBusy)
            {
              requestReport(bottomSheetContext.guid);
            }
          }
          else
          {
            dispatch(storeApi.inspection.edit.async.reportToEmailAsync({ guid: bottomSheetContext.guid }))
              .unwrap()
              .then((message) => {
                alert.success(message);
              })
              .catch((error) => alert.error(error))
          }
          break;
        case 'status':
          dispatch(storeApi.inspection.edit.async.getQMMsgAsync({ guid: bottomSheetContext.guid }))
            .unwrap()
            .then((list) => {
              if (list.length === 0 && bottomSheetContext.status !== null)
              {
                dispatch(storeApi.main.inspectionList.actions.remarkStatusOpenedChanged(true));
              }
              else if (list.length === 1)
              {
                Urls.QMMsgDetail.build('inspectionlist', list[0].qmMsgId).navigate();
              }
              else
              {
                Urls.QMMsgListInspection.build('inspectionlist', bottomSheetContext.guid).navigate();
              }
            })
            .catch((error) => alert.error(error))
          break;
        case 'message':
          Urls.MainMessages.build(bottomSheetContext.inspectionNum).navigate();
          break;
        case 'change-history':
          if (isDesktop)
          {
            if (!reportRequestHistoryBusy)
            {
              requestHistoryReport(bottomSheetContext.guid);
            }
          }
          else
          {
            dispatch(storeApi.inspection.edit.async.reportHistoryToEmailAsync({ guid: bottomSheetContext.guid }))
              .unwrap()
              .then((message) => {
                alert.success(message);
              })
              .catch((error) => alert.error(error))
          }
          break;
        case 'revocation':
          dispatch(storeApi.main.inspectionList.actions.revocationGuidChanged(bottomSheetContext.guid));
          dispatch(storeApi.main.inspectionList.actions.revocationOpenedChanged(true));
          break;
        case 'trash':
          setDeletedInspection(bottomSheetContext);
          setDeleteConfirmOpened(true);
          break;
      }
    }
  }

  const onDeleteCancelHandler = () => {
    setDeleteConfirmOpened(false);
    setDeletedInspection(null);
  }

  const onDeleteAcceptHandler = () => {
    setDeleteConfirmOpened(false);
    dispatch(storeApi.inspection.edit.async.deleteInspectionAsync({ guid: deletedInspection!.guid }))
      .unwrap()
      .then(() => {
        alert.success('Осмотр удален');

        dispatch(storeApi.main.inspectionList.async.prepareInfoAsync())
          .unwrap()
          .then(() => {
            if (Preferences.isAppRating)
            {
              setTimeout(() => {
                dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(true));
              }, 2000);
            }
            else
            {
              if (Preferences.isPriorityAdv)
              {
                Preferences.removeAppAdvPriorityShow();
                dispatch(storeApi.main.inspectionList.actions.adsIsPriorityChanged(true));
              }
            }
          });
      })
      .catch((error) => alert.error(error));
  }

  const tryDownloadReport = (count: number, fileGuid: string) => {
    window.setTimeout(() => {
      Api.getInspectionReportAsExcelByGuid({ guid: fileGuid })
        .then((result) => {
          dispatch(storeApi.main.inspectionList.actions.reportRequestSingleBusyChanged(false));
        })
        .catch((error) => {
          const newCount = count - 1;
          if (newCount > 0)
          {
            tryDownloadReport(newCount, fileGuid);
          }
          else
          {
            alert.error('Не удалось загрузить запрошенный отчет. Повторите позже или уменьшите период отчета.');
            dispatch(storeApi.main.inspectionList.actions.reportRequestSingleBusyChanged(false));
          }
        });
    }, 1000);
  };

  const requestReport = (auditGuid: string) => {
    Api.getInspectionRequestReportAsExcelByGuid({ guid: auditGuid })
      .then((request) => {
        alert.success('Отчет формируется и скоро начнется скачивание, не закрывайте окно осмотра');
        dispatch(storeApi.main.inspectionList.actions.reportRequestSingleBusyChanged(true));
        const calculatedCount = Math.ceil((request.retryAfter + 10000) / 1000);
        tryDownloadReport(
          calculatedCount,
          request.location.substring(request.location.lastIndexOf("/") + 1)
        );
      })
      .catch((error) => {
        alert.error('Запрос отчета завершился ошибкой. Повторите позже.');
      });
  };

  const tryDownloadHistoryReport = (count: number, fileGuid: string) => {
    window.setTimeout(() => {
      Api.getInspectionHistoryReportAsExcel({ guid: fileGuid })
        .then((result) => {
          dispatch(storeApi.main.inspectionList.actions.reportRequestHistoryBusyChanged(false));
        })
        .catch((error) => {
          const newCount = count - 1;
          if (newCount > 0)
          {
            tryDownloadHistoryReport(newCount, fileGuid);
          }
          else
          {
            alert.error('Не удалось загрузить запрошенный отчет. Повторите позже.');
            dispatch(storeApi.main.inspectionList.actions.reportRequestHistoryBusyChanged(false));
          }
        });
    }, 1000);
  };

  const requestHistoryReport = (auditGuid: string) => {
    Api.getInspectionHistoryRequestAsExcel({ guid: auditGuid })
      .then((request) => {
        alert.success('Отчет формируется и скоро начнется скачивание, не закрывайте окно осмотра');
        dispatch(storeApi.main.inspectionList.actions.reportRequestHistoryBusyChanged(true));
        const calculatedCount = Math.ceil((request.retryAfter + 10000) / 1000);
        tryDownloadHistoryReport(
          calculatedCount,
          request.location.substring(request.location.lastIndexOf("/") + 1)
        );
      })
      .catch((error) => {
        alert.error('Запрос отчета завершился ошибкой. Повторите позже.');
      });
  };

  if (inspections.length > 0)
  {
    return (
      <React.Fragment>
        <InspectionList layout = { layout }>
          <RefersLoader variant = { layout.refersLoaderVariant } />
          {inspections.map((inspection) => (
            <Inspection
              key = { inspection.guid }
              variant = { layout.inspectionVariant }
              inspection = { inspection }
              currentAccount = { currentAccount! }
              onThreeDotTap = { onThreeDotTapHandler }
              onQuestionTap = { onQuestionTapHandler }
              onDangerTap = { onDangerTapHandler }
            />
          ))}
        </InspectionList>
        <BottomSheet variant = { layout.bottomSheetVariant } 
          isOpened = { bottomSheetOpened }
          menu = {
            [
              { action: 'view', icon: <InspectionMenuViewVector />, label: 'Просмотр' },
              { action: 'edit', icon: <InspectionMenuEditVector />, label: 'Редактировать осмотр' },
              { action: 'create', icon: <InspectionMenuEditVector />, label: 'Создать  осмотр' },
              { action: 'requirement-remark', icon: <InspectionMenuEditVector />, label: 'Изменить требования' },
              { action: 'requirement-complaint', icon: <InspectionMenuEditVector />, label: 'Изменить требования' },
              { action: 'transfer', icon: <InspectionMenuTransferVector />, label: 'Передать на Северсталь' },
              { action: 'email', icon: reportRequestSingleBusy ? <Loading variant={layout.pageLoadingVariant} /> : <InspectionMenuEmailVector />, label: isDesktop ? 'Сформировать отчет' : 'Отправить на e-mail' },
              { action: 'status', icon: <InspectionMenuStatusVector />, label: 'Статус осмотра' },
              { action: 'message', icon: <InspectionMenuMessageVector />, label: 'Перейти к сообщениям' },
              { action: 'change-history', icon: reportRequestHistoryBusy ? <Loading variant={layout.pageLoadingVariant} /> : <InspectionMenuClockVector />, label: 'История изменений' },
              { action: 'revocation', icon: <InspectionMenuRevocationVector />, label: 'Отозвать осмотр' },
              { action: 'trash', icon: <InspectionMenuTrashVector />, label: 'Удалить осмотр', isDanger: true },
            ].filter((item) => bottomSheetActions.includes(item.action))
          }
          onClose = { onBottomSheetCloseHandler }
          onMenuItemSelected = { onBottomSheetSelectHandler }
        />
        <TwoActionDialog 
          variant = { layout.deleteConfirmDialog }
          opened = { deleteConfirmOpened }
          caption = 'Удалить осмотр?'
          message = 'Удаленный осмотр невозможно будет восстановить!'
          isDanger
          cancelLabel = 'Отмена'
          actionLabel = 'Удалить'
          onCancel = { onDeleteCancelHandler }
          onAction = { onDeleteAcceptHandler }
        />
      </React.Fragment>
    )
  }

  if (isFilterFilled)
  {
    return (
      <EmptyCaseLoupe
        variant = { layout.emptyCaseVariant }
        header = 'Ничего не найдено'
        message = 'Попробуйте изменить поисковый запрос'
      />
    )
  }

  return (
    <EmptyCaseEnvelope
      variant = { layout.emptyCaseVariant }
      header = 'Осмотры не добавлены'
    />
  )
}

const Rating: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const appRatingOpened = useAppSelector(storeApi.main.inspectionList.selectors.selectAppRatingOpened);

  const onCancelHandler = () => {
    Preferences.planAppRatingUntilFail();
    dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(false));
  }

  const onAcceptHandler = (rating: number, note: string) => {
    Api.postRating({ rating, note })
      .then(() => {
        Preferences.planAppRatingUntilSuccess();
        dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(false));
        alert.success('Ваша оценка успешно сохранена');
      })
      .catch(() => {
        Preferences.planAppRatingUntilFail();
        dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(false));
        alert.success('Не удалось сохранить Вашу оценку');
      });
  }

  return (
    <Dialog variant = { layout.appRatingDialogVariant } isOpened = { appRatingOpened }>
      <AppRating
        variant = { layout.appRatingVariant }
        onCancel = { onCancelHandler }
        onAccept = { onAcceptHandler }
      />
    </Dialog>  
  )
}

const QMMsgJumpGuideWrapper: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const [qmmsgJumpGuideShow, setQMMsgJumpGuideShow] = React.useState<boolean>(
    (Preferences.qmmsgJumpGuideShowCount + 1) === 5
    ||
    (
      (Preferences.qmmsgJumpGuideShowCount + 1) > 5
      &&
      (Preferences.qmmsgJumpGuideShowCount + 1) % 10 === 0
      &&
      (Preferences.qmmsgJumpGuideShowCount + 1) <= 30
    )
  );

  React.useEffect(() => {
    const deffer = setTimeout(
      () => {
        if (Preferences.qmmsgJumpGuideShowCount < 31)
        {
          Preferences.setQMMsgJumpGuideShowCount(Preferences.qmmsgJumpGuideShowCount + 1);
        }
      },
      500
    );

    return () => clearTimeout(deffer);
  }, []);

  const onQMMsgJumpGuideCloseHandler = (noMoreShow: boolean) => {
    setQMMsgJumpGuideShow(false);

    if (noMoreShow)
    {
      Preferences.setQMMsgJumpGuideShowCount(31);
    }
  }

  if (!qmmsgJumpGuideShow)
  {
    return null;
  }

  return (
    <QMMsgJumpGuide variant = { layout.qmmsgJumpGuideVariant } onClose = { onQMMsgJumpGuideCloseHandler } />
  );
}

const RevocationBottomSheet: React.FC<InspectionListScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const revocationOpened = useAppSelector(storeApi.main.inspectionList.selectors.selectRevocationOpened);
  const note = useAppSelector(storeApi.main.inspectionList.selectors.selectRevocationNote);

  const onRevocationHandler = () => {
    dispatch(storeApi.main.inspectionList.async.revocationAsync())
      .unwrap()
      .then((result) => {
        alert.success('Осмотр отозван');
        onCloseHandler();

        dispatch(storeApi.main.inspectionList.async.prepareInfoAsync())
          .unwrap()
          .then(() => {
            if (Preferences.isAppRating)
            {
              setTimeout(() => {
                dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(true));
              }, 2000);
            }
            else
            {
              if (Preferences.isPriorityAdv)
              {
                Preferences.removeAppAdvPriorityShow();
                dispatch(storeApi.main.inspectionList.actions.adsIsPriorityChanged(true));
              }
            }
          });
      })
      .catch((rawError) => {
        const error = rawError as RevocationReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onCloseHandler = () => {
    dispatch(storeApi.main.inspectionList.actions.revocationNoteChanged(''));
    dispatch(storeApi.main.inspectionList.actions.revocationOpenedChanged(false));
  }

  return (
    <Revocation
      variant = { layout.revocationVariant }
      isOpened = { revocationOpened }
      note = { note }
      onNoteChange = { (value: string) => dispatch(storeApi.main.inspectionList.actions.revocationNoteChanged(value)) }
      onRevocation = { onRevocationHandler }
      onClose = { onCloseHandler }
    />
  )
}

const fadeOutOnScroll = () => {
  const screenBody = document.getElementById('inspections-screen-body');
  const advContainer = document.getElementById('ads-list-container');
  const filterContainer = document.getElementById('filter-container');
	
  if (screenBody !== null && advContainer !== null && filterContainer !== null)
  {
    var elementHeight = advContainer.offsetHeight;
    var scrollTop = screenBody.scrollTop;
    
    var opacity = scrollTop < elementHeight ? 1 - scrollTop / elementHeight : 0;
   
    if (opacity >= 0) {
      advContainer.style.opacity = `${opacity}`;
      filterContainer.style.boxShadow = `0px 10px 10px 0px rgba(34, 60, 80, ${0.1 - 0.1 * opacity})`;
    }
  }
}

export const InspectionListScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;
  const bootOpStarted = React.useRef<boolean>(false);
  const isEndPage = useAppSelector(storeApi.main.inspectionList.selectors.selectIsEndPage);
  const pageLoading = useAppSelector(storeApi.main.inspectionList.selectors.selectPageLoading);

  const scrollHandler = React.useCallback(() => {
    const screenBody = document.getElementById('inspections-screen-body');

    if (screenBody !== null)
    {
      const scrollTop = screenBody.scrollTop;
      const offsetHeight = screenBody.offsetHeight;
      const scrollHeight = screenBody.scrollHeight;
      sessionStorage.setItem('incpectionsListPosition', `${scrollTop}`);
      fadeOutOnScroll();

      if (Math.abs(scrollHeight - offsetHeight - scrollTop) < 10)
      {
        if (!pageLoading && !isEndPage)
        {
          dispatch(storeApi.main.inspectionList.async.filterInspectionsAsync({ isStart: false }));
        }
      }
    }
  }, [pageLoading, isEndPage, dispatch]);

  React.useEffect(() => {

    const screenBody = document.getElementById('inspections-screen-body');

    if (screenBody !== null)
    {
      const incpectionsListPosition = sessionStorage.getItem('incpectionsListPosition');

      if (incpectionsListPosition !== null)
      {
        screenBody.scrollTo({ top: parseInt(incpectionsListPosition, 10) })
      }
      screenBody.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (screenBody !== null)
      {
        screenBody.removeEventListener('scroll', scrollHandler);
      }
    }
  }, [scrollHandler]);

  React.useEffect(
    () => {
      if (bootOpStarted.current)
      {
        return;
      }

      bootOpStarted.current = true;
      dispatch(storeApi.main.inspectionList.async.prepareInfoAsync())
        .unwrap()
        .then(() => {
          if (Preferences.isAppRating)
          {
            setTimeout(() => {
              dispatch(storeApi.main.inspectionList.actions.appRatingOpenedChanged(true));
            }, 2000);
          }
          else
          {
            if (Preferences.isPriorityAdv)
            {
              Preferences.removeAppAdvPriorityShow();
              dispatch(storeApi.main.inspectionList.actions.adsIsPriorityChanged(true));
            }
          }
        });
    },
    [dispatch]
  );

  return (
    <Screen layout = { layout }>
      <BlockUi layout = { layout } dispatch = { dispatch } />
      <Rating layout = { layout } dispatch = { dispatch } />
      <HelpDialog variant = { layout.helpDialogVariant } />
      <RemarkStatusDialog variant = { layout.remarkStatusDialogVariant } />
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout } id = 'inspections-screen-body'>
        <Adv layout = { layout } dispatch = { dispatch } />
        <InspectionsHeader layout = { layout } dispatch = { dispatch } />
        <Inspections layout = { layout } dispatch = { dispatch } />
        {pageLoading && (
          <PageLoadingContainer layout = { layout }>
            <PageLoading layout = { layout }>
              <Loading variant = { layout.pageLoadingVariant } />
            </PageLoading>
          </PageLoadingContainer>
        )}
      </ScreenBody>
      <Toolbar variant = { layout.toolbarVariant } activeItem = 'home' />
      <QMMsgJumpGuideWrapper layout = { layout } dispatch = { dispatch } />
      <InspectionViewDialog variant = { layout.inspectionViewVariant } />
      <RevocationBottomSheet layout = { layout } dispatch = { dispatch } />
    </Screen>
  );
};

const getInspectionState = (inspect: types.inspection.Inspection): InspectionState => {
  switch (inspect.status.code)
  {
    case types.inspection.InspectionStatusCode.DRAFT:
      return InspectionState.DRAFT;
    case types.inspection.InspectionStatusCode.ERROR:
      return InspectionState.ERROR;
    case types.inspection.InspectionStatusCode.NO_TRANS_DRAFT:
      return InspectionState.DRAFT;
    case types.inspection.InspectionStatusCode.NO_TRANS_ERROR:
      return InspectionState.ERROR;
    case types.inspection.InspectionStatusCode.NO_TRANS_COMPLETED:
      return InspectionState.EDIT;
  }

  return InspectionState.EDIT;
}
