import styled                   from 'styled-components';

import { CloseVector          } from 'src/components/vector/close';

import { IconButtonVariants   } from 'src/components/common/icon-button';
import { EmptyCaseVariants    } from 'src/components/common/empty-case';
import { TypographyVariants   } from 'src/components/common/typography';
import { InputTextboxVariants } from 'src/components/common/input/input-textbox';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn        } from 'src/providers/theme/shared';



export type ReferSelectorLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerTextColor: string;
  headerTextTypo: Typo;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  searchHeight: number;
  searchInputVariant: InputTextboxVariants;

  referItemHeight: number;
  referItemTypo: Typo;
  referBorderColor: string;
  referEmptyCaseVariant: EmptyCaseVariants;
  referLabelColor: string;
  referSearchColor: string;
}

const referselector_light: ReferSelectorLookAndFeel = {
  containerBackgroundColor: themeColors.PrimaryWhite,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerTextColor: themeColors.PrimaryBlack,
  headerTextTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  searchHeight: 56,
  searchInputVariant: 'h48light',

  referItemHeight: 46,
  referItemTypo: themeTypos.GRTSKREG_W400_S15_LH20,
  referBorderColor: themeColors.SecondaryGray2,
  referEmptyCaseVariant: 'light',
  referLabelColor: themeColors.PrimaryBlack,
  referSearchColor: themeColors.AccentBlue1,
}

export type ReferSelectorVariants = 'light';

export const referselector_variants: Map<ReferSelectorVariants, ReferSelectorLookAndFeel> = 
  new Map<ReferSelectorVariants, ReferSelectorLookAndFeel>([
    ['light', referselector_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
  fullHeight: boolean,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: ${(args) => args.fullHeight ? '100vh' : 'auto'};

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;
  padding-left: ${(args) => args.lookAndFeel.containerPaddings[1]}px;
`

export const SearchContainer = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.searchHeight}px;
  align-items: center;

  padding: ${(args) => args.lookAndFeel.containerPaddings[0]}px ${(args) => args.lookAndFeel.containerPaddings[1]}px;

  & > div {
    width: 100%;
  }
`

export const ReferItemsContainer = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
  overflow-y: scroll;

  padding: ${(args) => args.lookAndFeel.containerPaddings[0]}px ${(args) => args.lookAndFeel.containerPaddings[1]}px;
`

export const ReferItem = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
  isSelected: boolean;
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.referItemHeight}px;
  flex-shrink: 0;
  align-items: center;

  color: ${(args) => args.isSelected ? args.lookAndFeel.referSearchColor : args.lookAndFeel.referLabelColor};

  border-bottom: 1px solid ${(args) => args.lookAndFeel.referBorderColor};
`

export const ReferItemLabel = styled.div<{
  lookAndFeel: ReferSelectorLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.referItemTypo)}
  width: 100%;

  & span {
    color: ${(args) => args.lookAndFeel.referSearchColor};
    font-weight: bold;
  }
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;