import * as React             from 'react';

import { Container,
         DateLabelVariants,
         datelabel_variants } from 'src/components/features/main/notifications/date-label/ui';



type Props = {
  variant: DateLabelVariants;
  date: string;
}
export const DateLabel: React.FC<Props> = ({
  variant,
  date,
}) => {
  const lookAndFeel = datelabel_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { date }
    </Container>
  );
};
