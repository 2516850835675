import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuTransferVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M13.0533 11.9791L16.3197 15.2434L18.8283 5.88138L15.5619 2.61704L13.0533 11.9791Z' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M11.5118 6.71916L12.7764 1.99966L3.26216 4.58327L1.99988 9.29414L11.5118 6.71916Z' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M7.66901 10.6692L2.9868 12.007L10.0101 19.0303L14.6923 17.6925L7.66901 10.6692Z' />
    </svg>
  );
};
