import * as React               from 'react';

import cc                       from 'classcat';

import { CircleCheckVector    } from 'src/components/vector/circle-check';

import { Typography           } from 'src/components/common/typography';

import { Container,
         ColumnIcon,
         Icon,
         Dash,
         TextColumn,
         StepElementVariants,
         stepelement_variants } from 'src/components/features/qmmsg/detail/step-element/ui';



type Props = {
  variant: StepElementVariants;
  isActive?: boolean;
  isLast?: boolean;
  header: string;
  content?: string;
}
export const StepElement: React.FC<Props> = ({
  variant,
  isActive = false,
  isLast = false,
  header,
  content,
}) => {
  const lookAndFeel = stepelement_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } isLast = { isLast }>
      <ColumnIcon lookAndFeel = { lookAndFeel }>
        <Icon lookAndFeel = { lookAndFeel } className={cc({ active: isActive })}>
          <CircleCheckVector />
        </Icon>
        {!isLast &&
          <Dash lookAndFeel = { lookAndFeel }></Dash>
        }
      </ColumnIcon>
      <TextColumn lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.stepHeaderTypographyVariant }>{ header }</Typography>
        {content !== undefined &&
          <Typography variant = { lookAndFeel.stepContentTypographyVariant }>{ content }</Typography>
        }
      </TextColumn>
    </Container>
  );
};
