import * as React          from 'react';

import { ArrowBackVector } from 'src/components/vector/arrowback';

import { IconButton      } from 'src/components/common/icon-button';
import { Container,
         Back,
         Label,
         NavBarVariants,
         navbar_variants } from 'src/components/features/auth/common/nav-bar/ui';



type Props = {
  variant: NavBarVariants;
  label: string;
  onBackTap: () => void;
}
export const NavBar: React.FC<Props> = ({
  variant,
  label,
  onBackTap,
}) => {
  const lookAndFeel = navbar_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Back lookAndFeel = { lookAndFeel }>
        <IconButton variant = { lookAndFeel.buttonVariant } isTapAllowed onTap = { onBackTap }>
          <ArrowBackVector />
        </IconButton>
      </Back>
      <Label lookAndFeel = { lookAndFeel }>
        { label }
      </Label>
    </Container>
  );
};
