import { utils as xlsx,
         write, 
         writeFile,
         CellStyle      } from 'xlsx-js-style';

import * as types         from 'src/services/api/types';



class Xlsx {

  private cellStyle(border: string, alignment: string, isBold: boolean, isItalic: boolean): CellStyle {
    return {
      ...(
        border === '' ?
          { }
          :
          {
            border: {
              ...(
                border.includes('L') ?
                  { left: { style: border.includes('*') ? 'medium' : 'thin', color: { rgb: '000000' } } }
                  :
                  { }
              ),
              ...(
                border.includes('T') ?
                  { top: { style: border.includes('*') ? 'medium' : 'thin', color: { rgb: '000000' } } }
                  :
                  { }
              ),
              ...(
                border.includes('R') ?
                  { right: { style: border.includes('*') ? 'medium' : 'thin', color: { rgb: '000000' } } }
                  :
                  { }
              ),
              ...(
                border.includes('B') ?
                  { bottom: { style: border.includes('*') ? 'medium' : 'thin', color: { rgb: '000000' } } }
                  :
                  { }
              ),
            }
          }
      ),
      ...(
        alignment === '' ?
          { wrapText: true }
          :
          {
            alignment: {
              horizontal: ( alignment[0] === 'L' ? 'left' : (alignment[0] === 'R' ? 'right' : 'center') ),
              vertical: ( alignment[1] === 'T' ? 'top' : (alignment[1] === 'B' ? 'bottom' : 'center') ),
              wrapText: true,
            }
          }
      ),
      ...(
        (isBold || isItalic) ? 
          { font: { bold: isBold, italic: isItalic } }
          :
          { }
      ),

    }
  }

  reportShadeSelection(data: types.service.ShadeSelectionItem[], fileName: string): Uint8Array {
    const workbook = xlsx.book_new();

    let rows = [
      [
        { 
          v: 'Результат подбора рулонов по оттенку из разных партий, носит рекомендательный характер и не гарантирует  100% попадания в цвет по оттенку. Поставщик не несёт ответственности за различие оттенков конечного изделия у потребителя при условии, что изделие было произведено из разных партий.',
          s: this.cellStyle('', 'L', false, true),
        }
      ],
      [
        {
          v: '',
        }
      ],
      [
        {
          v: 'Основной рулон',
          s: this.cellStyle('', 'L', true, false)
        }
      ],
      [
        {
          v: '',
        }
      ],
      [
        {
          v: 'Номер рулона',
          s: this.cellStyle('LTR*', 'L', false, false)
        }
      ],
      [
        {
          v: data[0].pieceEtalon,
          s: this.cellStyle('LTRB*', 'L', false, false)
        }
      ],
      [
        {
          v: '',
        }
      ],
      [
        {
          v: '',
        }
      ],
      [
        {
          v: 'Результат подбора',
          s: this.cellStyle('', 'L', true, false)
        }
      ],
      [
        {
          v: '',
        }
      ],
    ];

    data.forEach((item, index) => {
      rows.push(
        [
          {
            v: `Допускается комбинация №${index + 1}`,
            s: {
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'ccffe5' },
                bgColor: { rgb: "ccffe5" },
              }
            }
          }
        ],
        [
          {
            v: '',
          }
        ],
        [
          {
            v: 'Номер рулона',
            s: this.cellStyle('LTR*', 'L', false, false)
          }
        ],
        [
          {
            v: item.pieceEtalon,
            s: this.cellStyle('LTRB*', 'L', false, false)
          }
        ],
        [
          {
            v: '',
          }
        ],
        [
          {
            v: 'Комбинируемые рулоны',
            s: this.cellStyle('LTR*', 'L', false, false)
          }
        ],
      );

      item.pieces.forEach((subItem) => {
        rows.push(
          [
            {
              v: subItem.piece,
              s: this.cellStyle('LTRB*', 'L', false, false)
            }
          ],
        );
      });

      rows.push(
        [
          {
            v: '',
          }
        ],
      );
    })

    const ws = xlsx.aoa_to_sheet(rows);
    var wscols = [
	    {wpx: 350},
    ];
    ws['!cols'] = wscols;

    xlsx.book_append_sheet(workbook, ws, 'Подбор оттенка');
    return write(workbook, { type: 'array', bookType: 'xlsx' });
  }
  
  report(data: types.inspection.Inspection[], role: types.auth.AccountRole, fields: types.auth.FieldDescriptor[]): void {
    const workbook = xlsx.book_new();
    const worksheet = xlsx.json_to_sheet([]);

    const headerCellMerges = {
      [types.auth.AccountRole.Client]: {
        vertical: [
          { col: 0, rowS: 0, rowE: 1 }, { col: 1, rowS: 0, rowE: 1 }, { col: 2, rowS: 0, rowE: 1 },
          { col: 3, rowS: 0, rowE: 1 }, { col: 4, rowS: 0, rowE: 1 }, { col: 5, rowS: 0, rowE: 1 },
          { col: 6, rowS: 0, rowE: 1 }, { col: 7, rowS: 0, rowE: 1 }, { col: 8, rowS: 0, rowE: 1 },
          { col: 9, rowS: 0, rowE: 1 }, { col: 10, rowS: 0, rowE: 1 },{ col: 15, rowS: 0, rowE: 1 },
          ...fields.map((_, index) => ({ col: 16 + index, rowS: 0, rowE: 1 })),
        ],
        horizontal: [{ row: 0, colS: 11, colE: 14 }]
      },
      [types.auth.AccountRole.ClientAdmin]: {
        vertical: [
          { col: 0, rowS: 0, rowE: 1 }, { col: 1, rowS: 0, rowE: 1 }, { col: 2, rowS: 0, rowE: 1 },
          { col: 3, rowS: 0, rowE: 1 }, { col: 4, rowS: 0, rowE: 1 }, { col: 5, rowS: 0, rowE: 1 },
          { col: 6, rowS: 0, rowE: 1 }, { col: 7, rowS: 0, rowE: 1 }, { col: 8, rowS: 0, rowE: 1 },
          { col: 9, rowS: 0, rowE: 1 }, { col: 10, rowS: 0, rowE: 1 },{ col: 15, rowS: 0, rowE: 1 },
          ...fields.map((_, index) => ({ col: 16 + index, rowS: 0, rowE: 1 })),
        ],
        horizontal: [{ row: 0, colS: 11, colE: 14 }]
      },
      [types.auth.AccountRole.Contractor]: {
        vertical: [
          { col: 0, rowS: 0, rowE: 1 }, { col: 1, rowS: 0, rowE: 1 }, { col: 2, rowS: 0, rowE: 1 },
          { col: 3, rowS: 0, rowE: 1 }, { col: 4, rowS: 0, rowE: 1 }, { col: 5, rowS: 0, rowE: 1 },
          { col: 6, rowS: 0, rowE: 1 },
          ...fields.map((_, index) => ({ col: 12 + index, rowS: 0, rowE: 1 })),
        ],
        horizontal: [{ row: 0, colS: 7, colE: 11 }]
      },
      [types.auth.AccountRole.Stpk]: {
        vertical: [
          { col: 0, rowS: 0, rowE: 1 }, { col: 1, rowS: 0, rowE: 1 }, { col: 2, rowS: 0, rowE: 1 },
          { col: 3, rowS: 0, rowE: 1 }, { col: 4, rowS: 0, rowE: 1 }, { col: 5, rowS: 0, rowE: 1 },
          { col: 6, rowS: 0, rowE: 1 }, { col: 7, rowS: 0, rowE: 1 }, { col: 8, rowS: 0, rowE: 1 },
          { col: 9, rowS: 0, rowE: 1 }, { col: 10, rowS: 0, rowE: 1 }, { col: 11, rowS: 0, rowE: 1 },
          { col: 16, rowS: 0, rowE: 1 },
          ...fields.map((_, index) => ({ col: 17 + index, rowS: 0, rowE: 1 })),
        ],
        horizontal: [{ row: 0, colS: 12, colE: 15 }]
      }
    };

    const colWidth = {
      [types.auth.AccountRole.Client]: [12, 16, 16, 21, 40, 15, 12, 12, 12, 12, 40, 25, 25, 25, 40, 30, ...fields.map((_) => 20)],
      [types.auth.AccountRole.ClientAdmin]: [12, 16, 16, 21, 40, 15, 12, 12, 12, 12, 40, 25, 25, 25, 40, 30, ...fields.map((_) => 20)],
      [types.auth.AccountRole.Contractor]: [12, 16, 21, 40, 17, 17, 40, 25, 25, 40, 12, 12, ...fields.map((_) => 20)],
      [types.auth.AccountRole.Stpk]: [12, 16, 16, 21, 40, 15, 12, 12, 12, 12, 40, 25, 25, 25, 40, 30, 30, ...fields.map((_) => 20)],
    };

    const headerRowHeight = {
      [types.auth.AccountRole.Client]: [20, 30],
      [types.auth.AccountRole.ClientAdmin]: [20, 30],
      [types.auth.AccountRole.Contractor]: [20, 30],
      [types.auth.AccountRole.Stpk]: [20, 30],
    };

    const headerColLabels = {
      [types.auth.AccountRole.Client]: {
        top: [
          '№ осмотра', 'Дата обнаружения несоответствия', 'Вид обращения', 'Идентификационный №',
          'Детальное описание по позиции (прочие комментарии)', '№ сертификата', '№ Плавки', '№ Партии',
          'Вес', 'Ед. изм.', 'ФИО, зарегистрировавшего осмотр', 'Информация о дефекте', '', '', '', 'Статус',
          ...fields.map((field) => field.title),
        ],
        bottom: [
          '', '', '', '', '', '', '', '', '', '', '', 'Продукт', 'Дефект', 'Место фиксации',
          'Детальное описание (прочие комментарии)', '', ...fields.map((_) => ''),
        ]
      },
      [types.auth.AccountRole.ClientAdmin]: {
        top: [
          '№ осмотра', 'Дата обнаружения несоответствия', 'Вид обращения', 'Идентификационный №',
          'Детальное описание по позиции (прочие комментарии)', '№ сертификата', '№ Плавки', '№ Партии',
          'Вес', 'Ед. изм.', 'ФИО, зарегистрировавшего осмотр', 'Информация о дефекте', '', '', '', 'Статус',
          ...fields.map((field) => field.title),
        ],
        bottom: [
          '', '', '', '', '', '', '', '', '', '', '', 'Продукт', 'Дефект', 'Место фиксации',
          'Детальное описание (прочие комментарии)', '', ...fields.map((_) => ''),
        ]
      },
      [types.auth.AccountRole.Contractor]: {
        top: [
          '№ осмотра', 'Дата обнаружения несоответствия', 'Идентификационный №',
          'Детальное описание по позиции (прочие комментарии)', '№ Плавки', '№ Партии',
          'ФИО, зарегистрировавшего осмотр', 'Информация о дефекте', '', '', '', '',
          ...fields.map((field) => field.title),
        ],
        bottom: [
          '', '', '', '', '', '', '', 'Дефект', 'Место фиксации', 'Детальное описание (прочие комментарии)',
          'Вес задержанного', 'Ед. изм.', ...fields.map((_) => ''),
        ]
      },
      [types.auth.AccountRole.Stpk]: {
        top: [
          '№ осмотра', 'Дата обнаружения несоответствия', 'Вид обращения', 'Идентификационный №',
          'Детальное описание по позиции (прочие комментарии)', '№ сертификата', '№ Плавки', 
          '№ Партии', 'Вес', 'Ед. изм.', 'ФИО, зарегистрировавшего осмотр', 'Приоритет', 'Информация о дефекте', '', '', '',
          'Статус', ...fields.map((field) => field.title),
        ],
        bottom: [
          '', '', '', '', '', '', '', '', '', '', '', '', 'Продукт', 'Дефект', 'Место фиксации',
          'Детальное описание (прочие комментарии)', '', ...fields.map((_) => ''),
        ]
      },
    }

    const styleLeft = this.cellStyle('LTRB', 'LC', false, false);
    const styleCenter = this.cellStyle('LTRB', 'CC', false, false);
    const styleRight = this.cellStyle('LTRB', 'RC', false, false);

    const columnStyles = {
      [types.auth.AccountRole.Client]: [
        styleRight,
        styleCenter,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleRight,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        ...fields.map((_) => styleLeft),
      ],
      [types.auth.AccountRole.ClientAdmin]: [
        styleRight,
        styleCenter,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleRight,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        ...fields.map((_) => styleLeft),
      ],
      [types.auth.AccountRole.Contractor]: [
        styleRight,
        styleCenter,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleRight,
        styleLeft,
        ...fields.map((_) => styleLeft),
      ],
      [types.auth.AccountRole.Stpk]: [
        styleRight,
        styleCenter,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleRight,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        styleLeft,
        ...fields.map((_) => styleLeft),
      ],
    }

    worksheet["!merges"] = [
      ...headerCellMerges[role].vertical.map(
        (item) => ({ s: { c: item.col, r: item.rowS }, e: { c: item.col, r: item.rowE } })
      ),
      ...headerCellMerges[role].horizontal.map(
        (item) => ({ s: { c: item.colS, r: item.row }, e: { c: item.colE, r: item.row } })
      ),
    ];

    worksheet['!cols'] = [ ...colWidth[role].map((item) => ({ width: item }))];
    worksheet['!rows'] = [ ...headerRowHeight[role].map((item) => ({ hpt: item }))];


    xlsx.sheet_add_aoa(worksheet, [headerColLabels[role].top, headerColLabels[role].bottom], { });

    headerCellMerges[role].vertical.forEach((item) => {
      worksheet[xlsx.encode_cell({r: 0, c: item.col})].s = this.cellStyle('LTRB*', 'CC', true, false);
      worksheet[xlsx.encode_cell({r: 1, c: item.col})].s = this.cellStyle('LTRB*', '', false, false);
    });

    headerCellMerges[role].horizontal.forEach((item) => {
      for (let i = item.colS; i <= item.colE; i++)
      {
        worksheet[xlsx.encode_cell({r: 0, c: i})].s = this.cellStyle(
          i === item.colS ? 'LTB*' : (i === item.colE ? 'TRB*' : 'TB*'),
          i === item.colS ? 'CC' : '',
          i === item.colS ? true : false,
          false
        );
        worksheet[xlsx.encode_cell({r: 1, c: i})].s = this.cellStyle('LTRB*', 'CC', true, false);
      }
    });

    fields.forEach((field, index) => {
      worksheet[xlsx.encode_cell({r: 0, c: headerColLabels[role].top.length - fields.length + index})].s = this.cellStyle('LTRB*', 'CC', true, false);
      worksheet[xlsx.encode_cell({r: 1, c: headerColLabels[role].top.length - fields.length + index})].s = this.cellStyle('LTRB*', '', false, false);
    });

    const reportDataRows: string[][] = [];
    const commonColumnsCount = headerColLabels[role].top.length - fields.length;
    const isContractor = role === types.auth.AccountRole.Contractor;
    const isClient = role === types.auth.AccountRole.Client;
    const isClientAdmin = role === types.auth.AccountRole.ClientAdmin;
    const isStpk = role === types.auth.AccountRole.Stpk;

    data.forEach((inspection) => {
      const defects = inspection.inspectionDefects ?? [];
      
      defects.forEach((defect) => {
        const pieces = defect.pieces ?? [];

        pieces.forEach((piece) => {
          const dataRow = Array.from({length: headerColLabels[role].top.length}, (_) => '');

          for (let columnNum = 0; columnNum < commonColumnsCount; columnNum++)
          {
            if (columnNum === 0) {
              //№ осмотра
              dataRow[columnNum] = `${inspection.inspectionNum}`;
            }
            else if (columnNum === 1 && inspection.timestampInspection > 0)
            {
              //Дата обнаружения несоответствия
              dataRow[columnNum] = `${new Date(inspection.timestampInspection).toLocaleDateString()}`;
            }
            else if (columnNum === 2 && !isContractor && defect.complaint !== null)
            {
              //вид обращения
              if (inspection.isEntryAccounting)
              {
                dataRow[columnNum] = 'Входной учет';
              }
              else if (defect.complaint.typeComplaint === types.inspection.ComplaintType.REMARK)
              {
                dataRow[columnNum] = 'Замечание';
              }
              else if (defect.complaint.typeComplaint === types.inspection.ComplaintType.COMPLAINT)
              {
                dataRow[columnNum] = 'Рекламация';
              }
            }
            else if (((columnNum === 3 && !isContractor) || (columnNum === 2 && isContractor)) && piece.qmetId !== null)
            {
              //идентификацилонный номер
              dataRow[columnNum] = `${piece.qmetId}`;
            }
            else if (((columnNum === 4 && !isContractor) || (columnNum === 3 && isContractor)) && piece.note !== null)
            {
              //Детальное описание по позиции (прочие комментарии)
              dataRow[columnNum] = piece.note;
            }
            else if (columnNum === 5 && !isContractor && piece.qmetData !== null && piece.qmetData.qcNum !== null)
            {
              //номер сертификата
              dataRow[columnNum] = piece.qmetData.qcNum;
            }
            else if (((columnNum === 6 && !isContractor) || (columnNum === 4 && isContractor)) && piece.heat !== null)
            {
              //плавка
              dataRow[columnNum] = piece.heat;
            }
            else if (((columnNum === 7 && !isContractor) || (columnNum === 5 && isContractor)) && piece.qmetData !== null && piece.qmetData.group !== null)
            {
              //партия
              dataRow[columnNum] = piece.qmetData.group;
            }
            else if (((columnNum === 8 && !isContractor) || (columnNum === 10 && isContractor)) && piece.weight !== null)
            {
              //вес
              dataRow[columnNum] = `${piece.weight}`;
            }
            else if (((columnNum === 9 && !isContractor) || (columnNum === 11 && isContractor)) && piece.unit !== null)
            {
              //единица измерения
              dataRow[columnNum] = `${piece.unit.title}`;
            }
            else if (((columnNum === 10 && !isContractor) || (columnNum === 6 && isContractor)) && inspection.userFio !== null)
            {
              //ФИО зарегистрировавшего осмотр
              dataRow[columnNum] = inspection.userFio;
            }
            else if (columnNum === 11 && isStpk && defect.priorityTitle !== null)
            {
              dataRow[columnNum] = defect.priorityTitle;
            }
            else if (((columnNum === 11 && (isClient || isClientAdmin)) || (columnNum === 12 && isStpk)) && defect.product !== null)
            {
              //продукт
              dataRow[columnNum] = defect.product;
            }
            else if (((columnNum === 12 && (isClient || isClientAdmin)) || (columnNum === 13 && isStpk) || (columnNum === 7 && isContractor)) && defect.defectTitle !== null)
            {
              //дефект
              dataRow[columnNum] = defect.defectTitle;
            }
            else if (((columnNum === 13 && (isClient || isClientAdmin)) || (columnNum === 14 && isStpk) || (columnNum === 8 && isContractor)) && defect.placeOfFixationTitle !== null)
            {
              //место фиксации
              dataRow[columnNum] = defect.placeOfFixationTitle;
            }
            else if (((columnNum === 14 && (isClient || isClientAdmin)) || (columnNum === 15 && isStpk) || (columnNum === 9 && isContractor)) && defect.note !== null)
            {
              //детальное описание проблемы
              dataRow[columnNum] = defect.note;
            }
            else if (((columnNum === 15 && (isClient || isClientAdmin)) || (columnNum === 16 && isStpk)) && inspection.status !== null && inspection.status.title !== null)
            {
              //статус
              dataRow[columnNum] = inspection.status.title;
            }
          }

          //дополнительные поля
          fields.forEach((field, index) => {
            let fieldValue = '';

            (piece.fields ?? []).forEach((pieceField) => {
              if (field.code === pieceField.code)
              {
                fieldValue = pieceField.value ?? '';
              }
            });

            dataRow[commonColumnsCount + index] = fieldValue;
          });

          reportDataRows.push(dataRow);
        });
      });
    });

    xlsx.sheet_add_json(worksheet, reportDataRows, { skipHeader: true, origin: -1 });

    

    for (let r = 0; r < reportDataRows.length; r++)
    {
      for (let c = 0; c < reportDataRows[r].length; c++)
      {
        worksheet[xlsx.encode_cell({r: 2 + r, c: c})].s = columnStyles[role][c];
      }
    }

    xlsx.book_append_sheet(workbook, worksheet, 'Осмотры');
    writeFile(workbook, "Inspections.xlsx", { compression: true }); 
  }
   
}

export default new Xlsx();