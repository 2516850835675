import styled           from 'styled-components';

import { themeColors, 
         Typo,
         typoAsCSS,
         themeTypos   } from 'src/providers/theme/shared';



type TypographyLookAndFeel = {
  typo: Typo
  color: string;
}

const typography_blackw600s14lh17: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S14_LH17,
  color: themeColors.PrimaryBlack,
}

const typography_blackw600s18lh24: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S18_LH24,
  color: themeColors.PrimaryBlack,
}

const typography_blackw400s20lh24: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S20_LH24,
  color: themeColors.PrimaryBlack,
}

const typography_blackw400s10lh16: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S10_LH16,
  color: themeColors.PrimaryBlack,
}

const typography_blackw400s16lh22: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S16_LH22,
  color: themeColors.PrimaryBlack,
}

const typography_blackw400s15lh20: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH20,
  color: themeColors.PrimaryBlack,
}

const typography_blackw600s28lh32: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S28_LH32,
  color: themeColors.PrimaryBlack,
}

const typography_redw600s12lh18: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S12_LH18,
  color: themeColors.AccentRed1,
}

const typography_blackw400s14lh17: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S14_LH17,
  color: themeColors.PrimaryBlack,
}

const typography_steelw400s14lh17: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S14_LH17,
  color: themeColors.PrimarySteel,
}

const typography_blackw400s14lh20: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S14_LH20,
  color: themeColors.PrimaryBlack,
}

const typography_blackw600s15lh20: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S15_LH20,
  color: themeColors.PrimaryBlack,
}

const typography_blue100w400s16lh22: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S16_LH22,
  color: themeColors.PrimaryBlue100,
}

const typography_steelw400s12lh14: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S12_LH14,
  color: themeColors.PrimarySteel,
}

const typography_redw400s12lh14: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S12_LH14,
  color: themeColors.AccentRed1,
}

const typography_blue2w400s15lh28: TypographyLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH28,
  color: themeColors.AccentBlue2,
}


export type TypographyVariants = 'blackw600s18lh24' | 
                                 'blackw400s20lh24' | 
                                 'blackw600s28lh32' | 
                                 'blackw400s14lh17' |
                                 'blackw400s15lh20' |
                                 'blackw400s16lh22' |
                                 'steelw400s14lh17' |
                                 'blackw400s14lh20' |
                                 'blackw600s15lh20' |
                                 'blue100w400s16lh22' |
                                 'blackw400s10lh16' |
                                 'redw600s12lh18' |
                                 'steelw400s12lh14' |
                                 'redw400s12lh14' |
                                 'blackw600s14lh17' |
                                 'blue2w400s15lh28';

export const typography_variants: Map<TypographyVariants, TypographyLookAndFeel> = 
  new Map<TypographyVariants, TypographyLookAndFeel>([
    ['blackw600s18lh24', typography_blackw600s18lh24],
    ['blackw400s20lh24', typography_blackw400s20lh24],
    ['blackw600s28lh32', typography_blackw600s28lh32],
    ['blackw400s14lh17', typography_blackw400s14lh17],
    ['blackw400s15lh20', typography_blackw400s15lh20],
    ['blackw400s16lh22', typography_blackw400s16lh22],
    ['steelw400s14lh17', typography_steelw400s14lh17],
    ['blackw400s14lh20', typography_blackw400s14lh20],
    ['blackw600s15lh20', typography_blackw600s15lh20],
    ['blue100w400s16lh22', typography_blue100w400s16lh22],
    ['blackw400s10lh16', typography_blackw400s10lh16],
    ['redw600s12lh18', typography_redw600s12lh18],
    ['blackw600s14lh17', typography_blackw600s14lh17],
    ['steelw400s12lh14', typography_steelw400s12lh14],
    ['redw400s12lh14', typography_redw400s12lh14],
    ['blue2w400s15lh28', typography_blue2w400s15lh28],
  ]);

export const Container = styled.span<{
  lookAndFeel: TypographyLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)};
  color: ${(args) => args.lookAndFeel.color};
`
