import { createSlice,
         createAsyncThunk } from '@reduxjs/toolkit';

import Api,
       { ApiError         } from 'src/services/api';
import * as types           from 'src/services/api/types';

import { RootState        } from 'src/store';



const SLICE_NAME = 'main:info';

export interface MainInfoState {
  list: types.info.InfoElement[];
}

const initialState: MainInfoState = {
  list: [],
}

// #region getInfo
type GetInfoArgs = void;
type GetInfoResolve = types.info.GetInfoOut;
export type GetInfoReject = ApiError;
export const getInfoAsync = createAsyncThunk<
  GetInfoResolve,
  GetInfoArgs,
  {
    state: RootState,
    rejectValue: GetInfoReject
  }
>(
  `${SLICE_NAME}/getInfoAsync`,
  async (_, thunkAPI) => {
    try
    {
      return await Api.getInfo();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

export const mainInfoSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoAsync.fulfilled, (state, action) => {
        state.list = action.payload;
      });
  },
})

const selectList = (state: RootState) => state.mainInfo.list;

export const mainInfoSelectors = {
  selectList,
}

export const mainInfoActions = mainInfoSlice.actions;

export default mainInfoSlice.reducer;