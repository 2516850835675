import styled                         from 'styled-components';

import { BottomSheetVariants        } from 'src/components/common/bottom-sheet';
import { BlockUIVariants            } from 'src/components/common/block-ui';
import { EmptyCaseVariants          } from 'src/components/common/empty-case';
import { DialogVariants             } from 'src/components/common/dialog';
import { TwoActionDialogVariants    } from 'src/components/common/two-action-dialog';
import { LoadingVariants            } from 'src/components/common/loading';

import { ReferSelectorVariants      } from 'src/components/features/common/refer-selector';
import { DefectSelectorVariants     } from 'src/components/features/common/defect-selector';

import { ToolbarVariants            } from 'src/components/features/main/common/toolbar';

import { NavBarVariants             } from 'src/components/features/main/inspection-list/nav-bar';
import { AdsVariants                } from 'src/components/features/main/inspection-list/ads';
import { HeaderVariants             } from 'src/components/features/main/inspection-list/header';
import { InspectionVariants         } from 'src/components/features/main/inspection-list/inspection';
import { FilterVariants             } from 'src/components/features/main/inspection-list/filter';
import { AppRatingVariants          } from 'src/components/features/main/inspection-list/app-rating';
import { HelpDialogVariants         } from 'src/components/features/main/inspection-list/help-dialog';
import { RemarkStatusDialogVariants } from 'src/components/features/main/inspection-list/remark-status-dialog';
import { QMMsgJumpGuideVariants     } from 'src/components/features/main/inspection-list/qmmsg-jump-guide';
import { RefersLoaderVariants       } from 'src/components/features/main/inspection-list/refers-loader';
import { RevocationVariants         } from 'src/components/features/main/inspection-list/revocation-dialog';

import { InspectionViewVariants     } from 'src/containers/inspection/view';

import { themeColors, 
         cssFlexColumn              } from 'src/providers/theme/shared';



export type InspectionListLayout = {
  backgroundColor: string;

  inspectionListGap: number;
  inspectionListPaddings: number[];

  advLoadingHeight: number;
  advLoadingSize: number;

  pageLoadingHeight: number;
  pageLoadingSize: number;

  navbarVariant: NavBarVariants;
  toolbarVariant: ToolbarVariants;
  bottomSheetVariant: BottomSheetVariants;
  defectsTreeVariant: DefectSelectorVariants;
  referSelectorVariant: ReferSelectorVariants;
  adsVariant: AdsVariants;
  headerVaraint: HeaderVariants;
  inspectionVariant: InspectionVariants;
  filterVariant: FilterVariants;
  blockUiVariant: BlockUIVariants;
  emptyCaseVariant: EmptyCaseVariants;
  appRatingVariant: AppRatingVariants;
  appRatingDialogVariant: DialogVariants;
  helpDialogVariant: HelpDialogVariants;
  remarkStatusDialogVariant: RemarkStatusDialogVariants;
  qmmsgJumpGuideVariant: QMMsgJumpGuideVariants;
  inspectionViewVariant: InspectionViewVariants;
  deleteConfirmDialog: TwoActionDialogVariants;
  refersLoaderVariant: RefersLoaderVariants;
  advLoadingVariant: LoadingVariants;
  pageLoadingVariant: LoadingVariants;
  revocationVariant: RevocationVariants;
}

export const layout_light: InspectionListLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  inspectionListGap: 8,
  inspectionListPaddings: [16],

  advLoadingHeight: 90,
  advLoadingSize: 24,

  pageLoadingHeight: 36,
  pageLoadingSize: 24,

  navbarVariant: 'light',
  toolbarVariant: 'light',
  bottomSheetVariant: 'light',
  defectsTreeVariant: 'light',
  referSelectorVariant: 'light',
  adsVariant: 'w124h90_light',
  headerVaraint: 'light',
  inspectionVariant: 'light',
  filterVariant: 'light',
  blockUiVariant: 'light',
  emptyCaseVariant: 'light',
  appRatingVariant: 'light',
  appRatingDialogVariant: 'light',
  helpDialogVariant: 'light',
  remarkStatusDialogVariant: 'light',
  qmmsgJumpGuideVariant: 'light',
  inspectionViewVariant: 'light',
  deleteConfirmDialog: 'light',
  refersLoaderVariant: 'light',
  advLoadingVariant: 'lightblue24',
  pageLoadingVariant: 'lightblue24',
  revocationVariant: 'light',
}

export const layout_dark: InspectionListLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: InspectionListLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  & > *:last-child {
    flex-shrink: 0;
  }
  
`

export const ScreenBody = styled.div<{
  layout: InspectionListLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  & > * {
    background-color: ${(args) => args.layout.backgroundColor};
  }

  overflow-y: scroll;
`

export const InspectionList = styled.div<{
  layout: InspectionListLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.inspectionListGap}px;
  padding: ${(args) => args.layout.inspectionListPaddings[0]}px;
  padding-top: 0;
 
`

export const AdvLoadingContainer = styled.div<{
  layout: InspectionListLayout,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: ${(args) => args.layout.advLoadingHeight}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`

export const AdvLoading = styled.div<{
  layout: InspectionListLayout,
}>`
  width: ${(args) => args.layout.advLoadingSize}px;
  height: ${(args) => args.layout.advLoadingSize}px;
`

export const PageLoadingContainer = styled.div<{
  layout: InspectionListLayout,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: ${(args) => args.layout.pageLoadingHeight}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`

export const PageLoading = styled.div<{
  layout: InspectionListLayout,
}>`
  width: ${(args) => args.layout.pageLoadingSize}px;
  height: ${(args) => args.layout.pageLoadingSize}px;
`
