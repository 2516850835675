import * as React                  from 'react';
 
import { CircleQuestionVector    } from 'src/components/vector/circle-question';
import { TrashVector             } from 'src/components/vector/trash';

import { InputTextbox            } from 'src/components/common/input/input-textbox';
import { IconButton              } from 'src/components/common/icon-button';

import { RollContainer,
         RollHeader,
         RollHeaderLabel,
         RollInput,
         RollInputLabel,
         RollInputLabelAsterisk,
         RollVariants,
         roll_variants           } from 'src/components/features/main/shade-selection/roll/ui';

import Util                        from 'src/services/util';



type Props = {
  variant: RollVariants;
  isPrimary: boolean;
  isDeletable: boolean;
  rollNumber: number;
  rollValue: string;
  rollError: string;
  onChange: (value: string) => void;
  onInfo: () => void;
  onDelete: () => void;
}
export const Roll: React.FC<Props> = ({
  variant,
  isPrimary,
  isDeletable,
  rollNumber,
  rollValue,
  rollError,
  onChange,
  onInfo,
  onDelete
}) => {
  const lookAndFeel = roll_variants.get(variant)!;

  return (
    <RollContainer lookAndFeel = { lookAndFeel }>
      <RollHeader lookAndFeel = { lookAndFeel }>
        <RollHeaderLabel lookAndFeel = { lookAndFeel } isPrimary = { isPrimary }>
          { isPrimary ? 'Эталонный рулон' : `Рулон ${rollNumber}` }
        </RollHeaderLabel>
        {isDeletable && (
          <IconButton
            variant = { lookAndFeel.trashButtonVariant }
            isTapAllowed
            onTap = { () => onDelete() }
          >
            <TrashVector />
          </IconButton>
        )}
      </RollHeader>
      <RollInput lookAndFeel = { lookAndFeel }>
        <RollInputLabel lookAndFeel = { lookAndFeel }>
          № рулона
          <RollInputLabelAsterisk lookAndFeel = { lookAndFeel }>*</RollInputLabelAsterisk>
        </RollInputLabel>
        <InputTextbox
          variant = { lookAndFeel.textboxVariant }
          value = { rollValue }
          placeholder = '●●●●●● ●●'
          isError = { rollError !== '' }
          hint = { rollError }
          customIcon = { rollValue === '' ? <CircleQuestionVector className = 'circle-question' /> : undefined }
          onCustomIconTap = { () => onInfo() }
          valueTransformer = { Util.selectRollNumberFromString }
          onChange = { onChange }
        />
      </RollInput>
    </RollContainer>
  );
};
