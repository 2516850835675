import styled               from 'styled-components';

import { ButtonVariants   } from 'src/components/common/button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,
         cssFlexRow       } from 'src/providers/theme/shared';



type DeleteConfirmDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  gap: number;
  actionsGap: number;

  cancelButtonVariant: ButtonVariants;
  acceptButtonVariant: ButtonVariants;
}

const deleteconfirmdialog_light: DeleteConfirmDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  gap: 32,
  actionsGap: 16,

  cancelButtonVariant: 'h48blueoutline',
  acceptButtonVariant: 'h48bluefill',
}

export type DeleteConfirmDialogVariants = 'light';

export const deleteconfirmdialog_variants: Map<DeleteConfirmDialogVariants, DeleteConfirmDialogLookAndFeel> = 
  new Map<DeleteConfirmDialogVariants, DeleteConfirmDialogLookAndFeel>([
    ['light', deleteconfirmdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DeleteConfirmDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: DeleteConfirmDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Actions = styled.div<{
  lookAndFeel: DeleteConfirmDialogLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
`