import * as React           from 'react';

import { Container,
         WelcomeVariants,
         welcome_variants } from 'src/components/features/auth/phone-enter/welcome/ui';



type Props = {
  variant: WelcomeVariants;
}
export const Welcome: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = welcome_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      Добро пожаловать в приложение
    </Container>
  );
};
