import React from 'react';



type Props = {
  className?: string;
}
export const EditVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 16 16' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' strokeMiterlimit = '10' d = 'M6 10V7.66667L11.6667 2L14 4.33333L8.66667 10H6Z' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M7 3H2V14H13V9'/>
    </svg>
  );
};
