import * as React                   from 'react';

import { Button                   } from 'src/components/common/button';
import { RadioGroup,
         RadioGroupItem           } from 'src/components/common/radio-group';
import { LabeledContent           } from 'src/components/common/labeled-content';
import { InputMultiline           } from 'src/components/common/input/input-multiline';

import { Container, 
         Caption,
         Message,
         SelectionRatingVariants,
         selectionrating_variants } from 'src/components/features/main/shade-selection/selection-rating/ui';



type Props = {
  variant: SelectionRatingVariants;
  onAccept: (isYes: boolean, note: string) => void;
}
export const SelectionRating: React.FC<Props> = ({
  variant,
  onAccept,
}) => {
  const lookAndFeel = selectionrating_variants.get(variant)!;

  const [answer, setAnswer] = React.useState<string>('YES');
  const [note, setNote] = React.useState<string>('');

  const isDisabled = answer === 'NO' && note.trim() === '';

  const items: RadioGroupItem[] = [{ id: 'YES', label: 'Да' }, { id: 'NO', label: 'Нет' }];
  const value = items.find((item) => item.id === answer)!;

  const onRadioChangeHandler = (value: RadioGroupItem) => {
    setAnswer(value.id)
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Вы удовлетворены результатом подбора?
      </Caption>
      <RadioGroup
        variant = { lookAndFeel.radioVariant }
        layout = 'horizontal-center'
        items = { items }
        value = { value }
        onChange = { onRadioChangeHandler }
      />
      <Message lookAndFeel = { lookAndFeel }>
        Если результат подбора некорректный, то подробно опишите, почему так считаете. Это поможет нам стать лучше.
      </Message>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Комментарий'>
        <InputMultiline
          variant = { lookAndFeel.multilineVariant }
          maxLength = { 2000 }
          value = { note }
          onChange = { setNote }
        />
      </LabeledContent>
      <Button
        variant = { lookAndFeel.sendButtonVariant }
        label = 'Отправить результат'
        isDisabled = { isDisabled }
        onTap = { () => onAccept(answer === 'YES', note.trim()) }
      />
    </Container>
  );
};
