import * as React                from 'react';

import { Container,
         Content,
         Header,
         Message,
         Time,
         NotificationVariants,
         notification_variants } from 'src/components/features/main/notifications/notification/ui';

import Util                      from 'src/services/util';

import * as types                from 'src/services/api/types';



type Props = {
  variant: NotificationVariants;
  notification: types.notification.Notification
  onTap: (notification: types.notification.Notification) => void;
}
export const Notification: React.FC<Props> = ({
  variant,
  notification,
  onTap
}) => {
  const lookAndFeel = notification_variants.get(variant)!;

  const header = notification.topic;
  const message = notification.text;
  const date = new Date(notification.timestamp);

  const time = Util.timeHHMM(date);

  return (
    <Container lookAndFeel = { lookAndFeel } onClick = { () => onTap(notification) }>
      <Content lookAndFeel = { lookAndFeel }>
        <Header lookAndFeel = { lookAndFeel }>{ header }</Header>
        <Message lookAndFeel = { lookAndFeel }>{ message }</Message>
      </Content>
      <Time lookAndFeel = { lookAndFeel }>{ time === '00:00' ? '' : time }</Time>
    </Container>
  );
};
