import React from 'react';



type Props  =  {
  className?: string;
}
export const EmptyCaseEnvelopeVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '112' height = '88' viewBox = '0 0 112 88' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M111 16.2002H1V71.8002H111V16.2002Z' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M76.6547 34.8369L94.9819 53.1642M76.6547 53.1642L81.653 48.1658M94.9819 34.8369L85.8183 44.0006' />
      <line stroke = 'currentColor' strokeWidth = '2' y1 = '-1' x2 = '27' y2 = '-1' transform = 'matrix(-1 8.24093e-08 9.27412e-08 1 61 33)' />
      <line stroke = 'currentColor' strokeWidth = '2' y1 = '-1' x2 = '19' y2 = '-1' transform = 'matrix(-1 8.24093e-08 9.27412e-08 1 61 60)' />
      <line stroke = 'currentColor' strokeWidth = '2' y1 = '-1' x2 = '14' y2 = '-1' transform = 'matrix(-1 0 0 1 30 33)' />
      <line stroke = 'currentColor' strokeWidth = '2' y1 = '-1' x2 = '45' y2 = '-1' transform = 'matrix(-1 8.24093e-08 9.27412e-08 1 61 46)' />
      <line stroke = 'currentColor' strokeWidth = '2' y1 = '-1' x2 = '23' y2 = '-1' transform = 'matrix(-1 1.40027e-07 5.45806e-08 1 39 60)' />
    </svg>
  );
};
