import * as React    from 'react';

import { Container } from 'src/components/common/flex/hgap/ui';



type Props = {
  size: number;
}
export const HGap: React.FC<Props> = ({
  size
}) => {
  return (
    <Container size = { size } />
  );
};
