import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region POST rating

export type PostRatingIn = {
  /** Оценка от 1 до 5 */
  rating: number;
  /** Примечание к оценке */
  note: string;
}

export const preparePostRating
  : PrepareCallHandler<PostRatingIn, void> = (arg: PostRatingIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/rating`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
