import styled                     from 'styled-components';

import { ButtonVariants         } from 'src/components/common/button';
import { InputDigitCodeVariants } from 'src/components/common/input/input-digitcode';
import { SendAgainVariants      } from 'src/components/common/send-again';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow,   
         cssFlexColumn          } from 'src/providers/theme/shared';



type PhoneConfirmDialogLookAndFeel = {
  paddings: number[];
  inputWidth: number;

  gap: number;
  digitGapTop: number;
  digitGapBottom: number;
  actionsGap: number;

  headerTypo: Typo;
  headerColor: string;

  labelTypo: Typo;
  labelColor: string;

  inputLabelTypo: Typo;
  inputLabelColor: string;

  sendAgainVariant: SendAgainVariants;
  inputDigitVariant: InputDigitCodeVariants;
  cancelVariant: ButtonVariants;
  submitVariant: ButtonVariants;
}

const phoneconfirmdialog_light: PhoneConfirmDialogLookAndFeel = {
  paddings: [28, 20],
  inputWidth: 200,

  gap: 20,
  digitGapTop: 8,
  digitGapBottom: 25,
  actionsGap: 16,

  headerTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  headerColor: themeColors.AccentRed1,

  labelTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  labelColor: themeColors.PrimaryBlack,

  inputLabelTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  inputLabelColor: themeColors.AccentBlue1,

  sendAgainVariant: 'light',
  inputDigitVariant: 'h48light',
  cancelVariant: 'h48blueoutline',
  submitVariant: 'h48bluefill',
}

export type PhoneConfirmDialogVariants = 'light';

export const phoneconfirmnote_variants: Map<PhoneConfirmDialogVariants, PhoneConfirmDialogLookAndFeel> = 
  new Map<PhoneConfirmDialogVariants, PhoneConfirmDialogLookAndFeel>([
    ['light', phoneconfirmdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Header = styled.span<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};
  color: ${(args) => args.lookAndFeel.headerColor};
  text-align: center;
`;

export const Label = styled.span<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
  text-align: center;
`;

export const InputCodeContainer = styled.span<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  ${cssFlexColumn};
  align-items: center;
`;

export const InputLabel = styled.span<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.inputLabelTypo)};
  color: ${(args) => args.lookAndFeel.inputLabelColor};
  text-align: center;
`;

export const InputContainer = styled.div<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  width: ${(args) => args.lookAndFeel.inputWidth}px;
  margin: 0 auto;
`;

export const ActionButtons = styled.div<{
  lookAndFeel: PhoneConfirmDialogLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;

  gap: ${(args) => args.lookAndFeel.actionsGap}px;
`