import styled             from 'styled-components';

import { cssFlexColumn, 
         cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



export type InputBaseLookAndFeel = {
  borderNormalColor: string;
  borderFocusColor: string;
  borderErrorColor: string;
  borderDisabledColor: string;

  backgroundNormalColor: string;
  backgroundDisabledColor: string;

  placeholderNormalColor: string;
  placeholderDisabledColor: string;

  hintTypo: Typo;
  hintNormalColor: string;
  hintErrorColor: string;

  isHeightAuto: boolean;

  height: number;
  vgap: number;
  hgap: number;
  paddings: number[];
}

const inputbase_h48light: InputBaseLookAndFeel = {
  borderNormalColor: themeColors.SecondaryGray4,
  borderFocusColor: themeColors.PrimaryBlue100,
  borderErrorColor: themeColors.AccentRed1,
  borderDisabledColor: themeColors.SecondaryGray2,

  backgroundNormalColor: themeColors.PrimaryWhite,
  backgroundDisabledColor: themeColors.SecondaryGray2,

  placeholderNormalColor: themeColors.SecondaryGray5,
  placeholderDisabledColor: themeColors.SecondaryGray4,

  hintTypo: themeTypos.GRTSKREG_W400_S10_LH12,
  hintNormalColor: themeColors.PrimarySteel,
  hintErrorColor: themeColors.AccentRed1,

  isHeightAuto: false,

  height: 48,
  vgap: 2,
  hgap: 5,
  paddings: [0, 0, 0, 16],
}

const inputbase_autolight: InputBaseLookAndFeel = {
  ...inputbase_h48light,
  isHeightAuto: true,
}

const getBorderColor = (lookAndFeel: InputBaseLookAndFeel, isError: boolean, isDisabled: boolean): string => {
  if (isDisabled)
  {
    return lookAndFeel.borderDisabledColor;
  }

  return isError ? lookAndFeel.borderErrorColor : lookAndFeel.borderNormalColor
}

export type InputBaseVariants = 'h48light' | 'autolight';

export const inputbase_variants: Map<InputBaseVariants, InputBaseLookAndFeel> = 
  new Map<InputBaseVariants, InputBaseLookAndFeel>([
    ['h48light', inputbase_h48light],
    ['autolight', inputbase_autolight],
  ]);

export const Container = styled.div<{
  lookAndFeel: InputBaseLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.vgap}px;
`;

export const InputWrapper = styled.div<{
  isError: boolean;
  isDisabled: boolean;
  lookAndFeel: InputBaseLookAndFeel;
}>`
  ${cssFlexRow};
  align-items: center;

  height: ${(args) => args.lookAndFeel.isHeightAuto ? 'auto' : args.lookAndFeel.height}px;
  border: 1px solid ${(args) => getBorderColor(args.lookAndFeel, args.isError, args.isDisabled)};

  background-color: ${(args) => args.isDisabled ? args.lookAndFeel.backgroundDisabledColor : args.lookAndFeel.backgroundNormalColor};

  &:focus-within {
    border-color: ${(args) => args.isError ? args.lookAndFeel.borderErrorColor : args.lookAndFeel.borderFocusColor}
  }

  & .prefix-icon {
    color: ${(args) => args.lookAndFeel.borderNormalColor}
  }

  &:focus-within .prefix-icon {
    color: ${(args) => args.lookAndFeel.borderFocusColor}
  }

  & input, & textarea {
    border: none;
    outline: none;
    background-color: ${(args) => args.isDisabled ? args.lookAndFeel.backgroundDisabledColor : args.lookAndFeel.backgroundNormalColor};
  }
`;

export const InputContent = styled.div<{
  lookAndFeel: InputBaseLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.hgap}px;
  width: 100%;
  align-items: center;

  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;
`;

export const Hint = styled.div<{
  isError: boolean;
  lookAndFeel: InputBaseLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.hintTypo)}

  color: ${(args) => args.isError ? args.lookAndFeel.hintErrorColor : args.lookAndFeel.hintNormalColor };
`;