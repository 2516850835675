import React from 'react';



type Props = {
  className?: string;
}
export const EmailStatusSuccessVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '12' height = '12' viewBox = '0 0 12 12' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <circle fill = '#0041A0' cx = '6' cy = '6' r = '6' />
      <path stroke = 'white' strokeWidth = '2' d = 'M9.5 4L5.5 8L3 5.5' />
    </svg>
  );
};
