import { createSlice,
         PayloadAction,
         createSelector } from '@reduxjs/toolkit';

import { RootState      } from 'src/store';



const SLICE_NAME = 'auth:pin-create';

export interface AuthPinCreateState {
  code: string;
  step: number;
}

const initialState: AuthPinCreateState = {
  code: '',
  step: 1,
}

type CodeChangedAction = PayloadAction<string>;

export const authPinCreateSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    codeChanged: (state, action: CodeChangedAction) => {
      state.code = action.payload;
      state.step = 2;
    },
    reset: (state) => {
      state.code = '';
      state.step = 1;
    }
  },
})

const selectCode = (state: RootState) => state.authPinCreate.code;
const selectStep = (state: RootState) => state.authPinCreate.step;
const selectLabel = createSelector(
  [selectStep],
  (step) => {
    return step === 1 ? 'Создайте ПИН-код для входа' : 'Повторите ПИН-код';
  }
);

export const authPinCreateSelectors = {
  selectCode,
  selectStep,
  selectLabel,
}

export const authPinCreateActions = authPinCreateSlice.actions;

export default authPinCreateSlice.reducer;