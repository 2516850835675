import * as React           from 'react';

import cc                   from 'classcat';

import { Root,
         Spinner,
         Message,
         BlockUIVariants,
         blockui_variants } from 'src/components/common/block-ui/ui';



type Props = {
  variant: BlockUIVariants;
  isOpened: boolean;
  message: string;
}
export const BlockUI: React.FC<Props> = ({
  variant,
  isOpened,
  message,
}) => {
  const lookAndFeel = blockui_variants.get(variant)!;

  return (
    <Root lookAndFeel = { lookAndFeel } className = { cc({ opened: isOpened }) }>
      <Spinner lookAndFeel = { lookAndFeel } />
      <Message lookAndFeel = { lookAndFeel }>
        { message }
      </Message>
    </Root>
  );
};
