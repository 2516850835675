import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export type Message = {
  /** ИД сообщения */
  id: number;
  /** GUID сообщения */
  guid: string;
  /** ИД регистрации пользователя Checksteel, отправившего сообщение */
  userId: number;
  /** ФИО пользователя, создавшего сообщение */
  fio: string;
  /** Email клиента */
  email: string;
  /** Тема сообщения */
  topic: string;
  /** Текст сообщения */
  textMessage: string;
  /** Номер осмотра, м.б. null */
  inspectionNum: Nullable<string>;
  /** Дата создания сообщения */
  timestampMessage: number;
  /** true - отправлен ответ пользователю из СУК */
  isResponse: boolean;
}

// #endregion


// #region GET message/list

export type GetMessageListIn = {
  /** Начало временного окна */
  timestampBeg?: number;
  /** Окончание временного окна */
  timestampEnd?: number;
  /** Номер осмотра */
  inspectionNum?: string;
}

export type GetMessageListOut = Array<Message>;

export const prepareGetMessageList
  : PrepareCallHandler<GetMessageListIn, GetMessageListOut> = (arg: GetMessageListIn) => {
    const params = {
      ...(arg.timestampBeg !== undefined ? { timestampBeg: arg.timestampBeg } : { }),
      ...(arg.timestampEnd !== undefined ? { timestampEnd: arg.timestampEnd } : { }),
      ...(arg.inspectionNum !== undefined ? { inspectionNum: encodeURIComponent(arg.inspectionNum) } : { }),
    }

    return {
      
      locked: true,
      config: {
        url: `api/mobile/${Constants.API_VERSION}/message/list`,
        method: 'GET',
        params,
        responseType: 'json',

        pwaLocked: 'access',
        pwaRetry: false,
      },
    };
}

// #endregion


// #region POST message

export type PostMessageIn = {
  /** GUID сообщения */
  guid: string;
  /** Тема сообщения */
  topic: string;
  /** Текст сообщения */
  textMessage: string;
  /** Номер осмотра, м.б. null */
  inspectionNum: Nullable<string>;
}

export const preparePostMessage
  : PrepareCallHandler<PostMessageIn, void> = (arg: PostMessageIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/message`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region DELETE message/{guid}

export type DeleteMessageIn = {
  /** GUID сообщения */
  guid: string;
}

export const prepareDeleteMessage
  : PrepareCallHandler<DeleteMessageIn, void> = (arg: DeleteMessageIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/message/${arg.guid}`,
      method: 'DELETE',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
