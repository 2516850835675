import styled              from 'styled-components';
 
import { cssFlexColumn, 
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,        
         cssFlexRowCC    } from 'src/providers/theme/shared';



type AttachmentAddLookAndFeel = {
  paddings: number[];
  gap: number;

  photoAreaHeight: number;
  photoAreaColor: string;
  photoPrimaryColor: string;
  photoSecondaryColor: string;

  labelTypo: Typo;
  labelColor: string;

  allowCountTypo: Typo;
  allowCountColor: string;
  allowCountGap: number;

  containerBorderColor: string;
  containerBackgroundColor: string;
}

const attachmentadd_light: AttachmentAddLookAndFeel = {
  paddings: [4, 4, 12, 4],
  gap: 10,

  photoAreaHeight: 76,
  photoAreaColor: themeColors.SecondaryGray1,
  photoPrimaryColor: themeColors.AccentBlue2,
  photoSecondaryColor: themeColors.SecondaryGray4,

  labelTypo: themeTypos.GRTSKREG_W400_S10_LH12,
  labelColor: themeColors.PrimaryBlack,

  allowCountTypo: themeTypos.GRTSKREG_W400_S08_LH10,
  allowCountColor: themeColors.SecondaryGray5,
  allowCountGap: 5,

  containerBorderColor: themeColors.SecondaryGray3,
  containerBackgroundColor: themeColors.PrimaryWhite,
}

export type AttachmentAddVariants = 'light';

export const attachmentadd_variants: Map<AttachmentAddVariants, AttachmentAddLookAndFeel> = 
  new Map<AttachmentAddVariants, AttachmentAddLookAndFeel>([
    ['light', attachmentadd_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AttachmentAddLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  position: relative;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px
           ${(args) => args.lookAndFeel.paddings[1]}px
           ${(args) => args.lookAndFeel.paddings[2]}px
           ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.containerBorderColor};

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const PhotoArea = styled.div<{
  lookAndFeel: AttachmentAddLookAndFeel
}>`
  ${cssFlexRowCC};
  height: ${(args) => args.lookAndFeel.photoAreaHeight}px;

  background-color: ${(args) => args.lookAndFeel.photoAreaColor};

  & path.primary, & rect.primary {
    color: ${(args) => args.lookAndFeel.photoPrimaryColor};
  }

  & path.secondary {
    color: ${(args) => args.lookAndFeel.photoSecondaryColor};
  }
`

export const LabelsStack = styled.div<{
  lookAndFeel: AttachmentAddLookAndFeel;
}>`
  ${cssFlexColumn};
  justify-content: center;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.allowCountGap}px;
`

export const Label = styled.div<{
  lookAndFeel: AttachmentAddLookAndFeel;
}>`
  ${cssFlexRowCC};

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};

  & > i {
    color: red;
  }
`

export const AllowCount = styled.div<{
  lookAndFeel: AttachmentAddLookAndFeel;
}>`
  ${cssFlexRowCC};

  ${(args) => typoAsCSS(args.lookAndFeel.allowCountTypo)};
  color: ${(args) => args.lookAndFeel.allowCountColor};
`
