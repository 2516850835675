import * as React              from 'react';

import { Ripple              } from 'src/components/common/ripple';
import { Container,
         RippleWrapper,
         IconButtonVariants,
         iconbutton_variants } from 'src/components/common/icon-button/ui';



type Props = {
  variant: IconButtonVariants;
  isDot?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isTapAllowed?: boolean;
  children: React.ReactNode;
  onTap: () => void;
}
export const IconButton: React.FC<Props> = ({
  variant,
  isDot = false,
  isSelected = false,
  isDisabled = false,
  isTapAllowed = false,
  children,
  onTap
}) => {
  const lookAndFeel = iconbutton_variants.get(variant)!;

  return (
    <RippleWrapper lookAndFeel = { lookAndFeel }>
      <Ripple isTapAllowed = { isTapAllowed } onTap = { onTap }>
        <Container isDot = { isDot } isSelected = { isSelected } isDisabled = { isDisabled } lookAndFeel = { lookAndFeel }>
          { children }
        </Container>
      </Ripple>
    </RippleWrapper>
  );
};
