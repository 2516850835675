import React from 'react';



type Props = {
  className?: string;
}
export const StarVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = {className} width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path 
        d = 'M23 11.418L21.9487 9.00682H15.7099C15.5117 9.0069 15.3185 8.94415 15.1581 8.82756C14.9977 8.71098 14.8783 8.54656 14.817 8.35786L12.8915 2.41797H11.1085L9.18298 8.35786C9.12167 8.54656 9.00226 8.71098 8.84186 8.82756C8.68146 8.94415 8.48831 9.0069 8.29009 9.00682H2.05199L1.5 10.7067L6.5471 14.3777C6.70748 14.4942 6.82685 14.6586 6.8881 14.8473C6.94934 15.036 6.9493 15.2392 6.888 15.4279L4.96244 21.3678L6.40661 22.418L11.4537 18.7477C11.614 18.6309 11.8071 18.5681 12.0053 18.5681C12.2036 18.5681 12.3967 18.6309 12.557 18.7477L17.6041 22.418L19.0483 21.3678L17.2121 15.1378V14.4991L17.5 13.918L19.5 12.918'
        fill = 'currentColor'
        stroke = 'currentColor'
        strokeWidth = '1.5'
        strokeMiterlimit = '10'
      />
    </svg>
  );
};
