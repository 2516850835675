import * as React                 from 'react';

import Drawer                     from '@mui/material/Drawer';

import { ArrowDownVector        } from 'src/components/vector/arrow-down';

import { IconButton             } from 'src/components/common/icon-button';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { Button                 } from 'src/components/common/button';
import { InputCalendar          } from 'src/components/common/input/input-calendar';
import { LabeledContent         } from 'src/components/common/labeled-content';

import { ReferSelector,
         ReferSelectorItem      } from 'src/components/features/common/refer-selector';

import { ReportLookAndFeel,
         Container,
         HeaderContainer,
         HeaderLabel,
         FormContainer,
         ActionsContainer,
         DateContainer,
         CloseOutlineVectorBig,
         ReportVariants,
         report_variants        } from 'src/components/features/qmmsg/list/report/ui';

import { StepQMReferElement     } from 'src/services/api/types/refers';
import Util                       from 'src/services/util';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';

import { Nullable               } from 'src/common';



type ReportElement = { lookAndFeel: ReportLookAndFeel; dispatch: AppDispatch; }

const ReportPeriod: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const dateBeg = useAppSelector(storeApi.qmmsg.list.selectors.selectReportTimestampBeg);
  const dateEnd = useAppSelector(storeApi.qmmsg.list.selectors.selectReportTimestampEnd);

  const onDateBegChangeHandler = (date: Nullable<Date>) => {
    if (date !== null)
    {
      date = Util.beginDay(date);

      if (dateEnd !== null && dateEnd.valueOf() < date.valueOf())
      {
        date = Util.beginDay(dateEnd);
      }
    }

    dispatch(storeApi.qmmsg.list.actions.reportTimestampBegChanged(date))
  }

  const onDateEndChangeHandler = (date: Nullable<Date>) => {
    if (date !== null)
    {
      date = Util.endDay(date);

      if (dateBeg !== null && dateBeg.valueOf() > date.valueOf())
      {
        date = Util.endDay(dateBeg);
      }
    }

    dispatch(storeApi.qmmsg.list.actions.reportTimestampEndChanged(date))
  }

  return (
    <DateContainer  lookAndFeel = { lookAndFeel }>
      <InputCalendar 
        variant = { lookAndFeel.dateInputVariant }
        value = { dateBeg }
        onChange = { onDateBegChangeHandler }
      />
      <InputCalendar
        variant = { lookAndFeel.dateInputVariant }
        value = { dateEnd }
        onChange = { onDateEndChangeHandler }
      />
    </DateContainer>
  )
}

const ReportStatusId: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const statusId = useAppSelector(storeApi.qmmsg.list.selectors.selectReportStatusId);
  const statusesAll = useAppSelector(storeApi.qmmsg.list.selectors.selectStatusQM);
  const statusStr = statusesAll.find((item) => item.id === statusId)?.title ?? '';

  const items = [{ id: 'SETNULL', label: 'Не выбрано' }, ...statusesAll.map((item) => ({
    id: `${item.id}`,
    label: item.title
  }))];

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
    dispatch(storeApi.qmmsg.list.actions.reportStatusIdChanged(item.id === 'SETNULL' ? null : parseInt(item.id, 10)));
  }

  return (
    <React.Fragment>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Статус'>
        <InputTextbox
          variant = { lookAndFeel.inputVariant }
          value = { statusStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = 'Введите значение'
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </LabeledContent>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Статус'
        selectedId = { statusId ? `${statusId}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const ReportStepCode: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const stepCode = useAppSelector(storeApi.qmmsg.list.selectors.selectReportStepCode);
  const stepsAll = useAppSelector(storeApi.qmmsg.list.selectors.selectStepQM);
  const stepStr = stepsAll.find((item) => item.code === stepCode)?.title ?? '';

  const items = [
    { id: 'SETNULL', label: 'Не выбрано' },
    ...stepsAll
      .reduce<StepQMReferElement[]>((acc, v) => acc.find((item) => item.code === v.code) === undefined ? [...acc, v] : acc, [])
      .map((item) => ({
        id: item.code,
        label: item.title
      }))
  ]

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
     dispatch(storeApi.qmmsg.list.actions.reportStepCodeChanged(item.id === 'SETNULL' ? null : item.id));
  }

  return (
    <React.Fragment>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Ход рассмотрения'>
        <InputTextbox
          variant = { lookAndFeel.inputVariant }
          value = { stepStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = 'Введите значение'
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </LabeledContent>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Шаг'
        selectedId = { stepCode ? `${stepCode}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}

const ReportClaimNumber: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const qmmsgNumber = useAppSelector(storeApi.qmmsg.list.selectors.selectReportQMMsgNumber);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Номер уведомления/претензии'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { qmmsgNumber }
        onChange = { (value: string) => dispatch(storeApi.qmmsg.list.actions.reportQMMsgNumberChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const ReportOutgoingNumber: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const outgoingNumber = useAppSelector(storeApi.qmmsg.list.selectors.selectReportOutgoingNum);

  return (
    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Исходящий номер клиента'>
      <InputTextbox
        variant = { lookAndFeel.inputVariant }
        value = { outgoingNumber }
        onChange = { (value: string) => dispatch(storeApi.qmmsg.list.actions.reportOutgoingNumChanged(value)) }
        placeholder = 'Введите значение'
      />
    </LabeledContent>
  )
}

const ReportTypeId: React.FC<ReportElement> = ({ lookAndFeel, dispatch }) => {
  const [referOpened, setReferOpened] = React.useState<boolean>(false);

  const typeCode = useAppSelector(storeApi.qmmsg.list.selectors.selectReportTypeCode);
  const typesAll = useAppSelector(storeApi.qmmsg.list.selectors.selectTypeQM);
  const typeStr = typesAll.find((item) => item.code === typeCode)?.title ?? '';

  const items = [{ id: 'SETNULL', label: 'Не выбрано' }, ...typesAll.map((item) => ({
    id: item.code,
    label: item.title
  }))]

  const onItemSelectedHandler = (item: ReferSelectorItem) => {
     dispatch(storeApi.qmmsg.list.actions.reportTypeCodeChanged(item.id === 'SETNULL' ? null : item.id)); 
  }

  return (
    <React.Fragment>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Тип обращения'>
        <InputTextbox
          variant = { lookAndFeel.inputVariant }
          value = { typeStr }
          readOnly
          onChange = { (value: string) => {  } }
          placeholder = 'Введите значение'
          customIcon = { <ArrowDownVector /> }
          onCustomIconTap = { () => setReferOpened(true) }
        />
      </LabeledContent>
      <ReferSelector
        variant = { lookAndFeel.referSelectorVariant }
        referItemsList = { items }
        isOpened = { referOpened }
        label = 'Тип обращения'
        selectedId = { typeCode ? `${typeCode}` : null }
        onClose = { () => setReferOpened(false) }
        onSelect = { onItemSelectedHandler }
      />
    </React.Fragment>
  )
}


type Props = {
  variant: ReportVariants;
  isOpened: boolean;
  onClose: () => void;
  onAccept: () => void;
}
export const Report: React.FC<Props> = ({
  variant,
  isOpened,
  onClose,
  onAccept,
}) => {
  const lookAndFeel = report_variants.get(variant)!;
  const dispatch = useAppDispatch();

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { () => onClose() }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          <HeaderLabel lookAndFeel = { lookAndFeel }>
            Отчет
          </HeaderLabel>
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { () => onClose() }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        <FormContainer lookAndFeel = { lookAndFeel }>
          <ReportPeriod lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <ReportStatusId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <ReportStepCode lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <ReportClaimNumber lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <ReportOutgoingNumber lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
          <ReportTypeId lookAndFeel = { lookAndFeel } dispatch = { dispatch } />
        </FormContainer>
        <ActionsContainer lookAndFeel = { lookAndFeel }>
          <Button
            variant = { lookAndFeel.actionAcceptVariant }
            label = 'Отправить на email'
            onTap = { onAccept }
          />
        </ActionsContainer>
      </Container>
    </Drawer>
  );
};
