import inspectionEdit,
       { inspectionEditActions,
         inspectionEditSelectors,  
         buildForEditAsync,
         addPieceAsync,
         closePieceAsync,
         addComplaintAsync,
         checkComplaintBeforeAddAsync,
         gotoComplaintAsync,
         checkDefectsFilledAsync,
         saveInspectionAsync,
         saveImmediatelyAsync,
         deleteImmediatelyAsync,
         transferToSeverstalAsync,
         reportToEmailAsync,
         reportHistoryToEmailAsync,
         getQMMsgAsync,
         viewPDFAsync,
         downloadCertAsync as editDownloadCertAsync,
         viewCertAsync as editViewCertAsync,
         shareCertAsync as editShareCertAsync,
         checkCertAsync as editCheckCertAsync,
         downloadPhotoAsync as editDownloadPhotoAsync, 
         deleteInspectionAsync,
         changeDefectFavoriteAsync,
         addDefectAsync                                } from 'src/store/inspection/edit';

import inspectionView,
       { inspectionViewActions,
         inspectionViewSelectors,  
         openForViewAsync,
         downloadCertAsync,
         downloadPhotoAsync,
         checkCertAsync,
         viewCertAsync,
         shareCertAsync,
         viewPDFAsync as viewViewPDFAsync              } from 'src/store/inspection/view';


export const inspection = {
  edit: {
    actions: inspectionEditActions,
    selectors: inspectionEditSelectors,
    async: {
      buildForEditAsync,
      addPieceAsync,
      closePieceAsync,
      checkComplaintBeforeAddAsync,
      addComplaintAsync,
      changeDefectFavoriteAsync,
      addDefectAsync,
      gotoComplaintAsync,
      checkDefectsFilledAsync,
      saveInspectionAsync,
      saveImmediatelyAsync,
      deleteImmediatelyAsync,
      transferToSeverstalAsync,
      reportToEmailAsync,
      reportHistoryToEmailAsync,
      getQMMsgAsync,
      viewPDFAsync,
      deleteInspectionAsync,
      downloadCertAsync: editDownloadCertAsync,
      viewCertAsync: editViewCertAsync,
      shareCertAsync: editShareCertAsync,
      checkCertAsync: editCheckCertAsync,
      downloadPhotoAsync: editDownloadPhotoAsync,
    }
  },
  view: {
    actions: inspectionViewActions,
    selectors: inspectionViewSelectors,
    async: {
      openForViewAsync,
      downloadCertAsync,
      checkCertAsync,
      viewCertAsync,
      shareCertAsync,
      downloadPhotoAsync,
      viewViewPDFAsync,
    }
  },
};

export const inspectionReducers = {
  inspectionEdit,
  inspectionView,
}
