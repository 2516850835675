import * as React                         from 'react';

import { useAlert                       } from 'react-alert';

import { ThreeDotsSpinnerAnimatedVector } from 'src/components/vector/animate/three-dots-spinner';

import { Dialog                         } from 'src/components/common/dialog';
import { Button                         } from 'src/components/common/button';
import { Link                           } from 'src/components/common/link';
import { InputMultiline                 } from 'src/components/common/input/input-multiline';
import { Rating                         } from 'src/components/common/rating';

import { Container, 
         Caption,
         Note,
         Error,
         Spinner,
         RateDialogVariants,
         ratedialog_variants            } from 'src/components/features/qmmsg/detail/rate-dialog/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi                       } from 'src/store';
         
import { voidFunction                   } from 'src/common';



type Props = {
  variant: RateDialogVariants;
}
export const RateDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = ratedialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const alert = useAlert();
  
  const rate = useAppSelector(storeApi.qmmsg.detail.selectors.selectRate);
  const opened = useAppSelector(storeApi.qmmsg.detail.selectors.selectRateOpened);
  const message = useAppSelector(storeApi.qmmsg.detail.selectors.selectRateMessage);
  const isBusy = useAppSelector(storeApi.qmmsg.detail.selectors.selectRateBusy);
  const isError = useAppSelector(storeApi.qmmsg.detail.selectors.selectRateError);

  const onSendHandler = () => {
    dispatch(storeApi.qmmsg.detail.async.addRatingAsync())
      .unwrap()
      .then(() => {
        alert.success('Спасибо за оценку нашей работы. Ваше мнение очень важно для нас.');
      })
      .catch((rawError) => {
      });
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Оцените работу с сервисом
        </Caption>
        <Rating 
          variant = { lookAndFeel.ratingVariant }
          value = { rate - 1 }
          isHideLabel
          isDisabled
          onRating = { voidFunction }
        />
        <Note lookAndFeel = { lookAndFeel }>
          Пожалуйста, оцените насколько в целом вы остались довольны всем процессом — от момента подачи уведомления до получения решения или оплаты от СВС 
        </Note>

        <InputMultiline
          variant = { lookAndFeel.inputVariant }
          value = { message }
          isError = { isError }
          onChange = { (value) => dispatch(storeApi.qmmsg.detail.actions.rateMessageChanged(value)) }
          onFocus = { () => dispatch(storeApi.qmmsg.detail.actions.rateErrorReset()) }
        />

        {isError &&
          <Error lookAndFeel = { lookAndFeel }>
            Оценка не отправлена
          </Error>
        }

        {!isBusy &&
          <Button
            variant = { lookAndFeel.sendButtonVariant }
            isDisabled = { message.trim().length === 0 && rate < 4 }
            label = { isError ? 'Повторить отправку' : 'Отправить' }
            onTap = { onSendHandler }
          />
        }

        {isBusy && 
          <Spinner lookAndFeel = { lookAndFeel }>
            <ThreeDotsSpinnerAnimatedVector />
          </Spinner>
        }

        <Link
          variant = { lookAndFeel.cancelLinkVarint }
          label = { isBusy ? 'Отправка оценки' : 'Отмена' }
          isCentered
          isDisabled = { isBusy }
          onTap = { () => !isBusy && dispatch(storeApi.qmmsg.detail.actions.rateClosed()) }
        />

      </Container>
    </Dialog>
  );
};
