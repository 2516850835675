import * as React                   from 'react';


import { PieceHelpEx11Vector      } from 'src/components/vector/art/piece-help-ex1-1';
import { PieceHelpEx12Vector      } from 'src/components/vector/art/piece-help-ex1-2';
import { PieceHelpEx13Vector      } from 'src/components/vector/art/piece-help-ex1-3';
import { PieceHelpEx14Vector      } from 'src/components/vector/art/piece-help-ex1-4';
import { PieceHelpEx21Vector      } from 'src/components/vector/art/piece-help-ex2-1';
import { PieceHelpEx22Vector      } from 'src/components/vector/art/piece-help-ex2-2';
import { PieceHelpEx23Vector      } from 'src/components/vector/art/piece-help-ex2-3';
import { PieceHelpEx24Vector      } from 'src/components/vector/art/piece-help-ex2-4';

import { TabSwitch,
         TabItem                  } from 'src/components/common/tab-switch';
import { Dialog                   } from 'src/components/common/dialog';
import { IconButton               } from 'src/components/common/icon-button';

import { Container,
         Caption,
         Message,
         Close,
         Group,
         GroupHeader,
         Scroll,
         CloseOutlineVectorBig,
         PieceHelpDialogVariants,
         piecehelpdialog_variants } from 'src/components/features/inspection/edit/piece-help-dialog/ui';



const tabs: TabItem[] = [{ id: 'ex1', label: 'Пример 1' }, {id: 'ex2', label: 'Пример 2'}];

type Props = {
  variant: PieceHelpDialogVariants;
  opened: boolean;
  onClose: () => void;
}
export const PieceHelpDialog: React.FC<Props> = ({
  variant,
  opened,
  onClose,
}) => {
  const lookAndFeel = piecehelpdialog_variants.get(variant)!;
  const [tab, setTab] = React.useState<TabItem>(tabs[0]);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Close lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.closeButtonVariant }
            isTapAllowed
            onTap = { onClose }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </Close>
        <Caption lookAndFeel = { lookAndFeel }>
          Подсказка
        </Caption>
        <Scroll lookAndFeel = { lookAndFeel }>
          <TabSwitch variant = { lookAndFeel.switchExampleVariant } items = { tabs } value = { tab } onChange = { (value) => setTab(value) } />
          { tab.id === 'ex1' && (
            <Message lookAndFeel = { lookAndFeel }>
              Для корректного создания осмотра, необходимо вводить номер плавки и номер рулона/листа/трубы с маркировки продукции 
            </Message>
          )}
          { tab.id === 'ex2' && (
            <Message lookAndFeel = { lookAndFeel }>
              Для корректного создания осмотра, необходимо вводить номер плавки и номер рулона/листа/трубы указанные в сертификате
            </Message>
          )}
          { tab.id === 'ex1' && (
            <React.Fragment>
              <Group lookAndFeel = { lookAndFeel }>
                <GroupHeader lookAndFeel = { lookAndFeel }>Пример маркировки листов</GroupHeader>
                <PieceHelpEx11Vector />
              </Group>
              <Group lookAndFeel = { lookAndFeel }>
                <GroupHeader lookAndFeel = { lookAndFeel }>Пример маркировки сортовго проката</GroupHeader>
                <PieceHelpEx12Vector />
                <PieceHelpEx13Vector />
              </Group>
              <Group lookAndFeel = { lookAndFeel }>
                <GroupHeader lookAndFeel = { lookAndFeel }>Пример маркировки рулонов</GroupHeader>
                <PieceHelpEx14Vector />
              </Group>
            </React.Fragment>
          )}
          { tab.id === 'ex2' && (
            <Group lookAndFeel = { lookAndFeel }>
              <GroupHeader lookAndFeel = { lookAndFeel }>Пример данных из сертификата</GroupHeader>
              <PieceHelpEx21Vector />
              <PieceHelpEx22Vector />
              <PieceHelpEx23Vector />
              <PieceHelpEx24Vector />
            </Group>
          )}
        </Scroll>
       </Container>
    </Dialog>
  );
};
