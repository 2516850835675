import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type ToastLookAndFeel = {
  typo: Typo;

  bgColor: string;
  textColor: string;

  paddings: number[];
};

const baseToastLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S14_LH20,

  textColor: themeColors.PrimaryWhite,

  paddings: [14, 0, 16, 24],
};

const toast_info: ToastLookAndFeel = {
  ...baseToastLookAndFeel,
  bgColor: themeColors.PrimarySteel,
};

const toast_success: ToastLookAndFeel = {
  ...baseToastLookAndFeel,
  bgColor: themeColors.AlertSuccess,
};

const toast_error: ToastLookAndFeel = {
  ...baseToastLookAndFeel,
  bgColor: themeColors.AccentRed1,
};

export type ToastVariants = 'info' | 'success' | 'error';

export const toast_variants: Map<ToastVariants, ToastLookAndFeel> = 
  new Map<ToastVariants, ToastLookAndFeel>([
    ['info', toast_info],
    ['success', toast_success],
    ['error', toast_error],
  ]);

export const Container = styled.div<{
  lookAndFeel: ToastLookAndFeel;
}>`
  width: 100%;

  ${cssFlexRow};

  background-color: ${(args) => args.lookAndFeel.bgColor};
`;

export const Message = styled.span<{
  lookAndFeel: ToastLookAndFeel,
}>`
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.textColor};

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
`;
