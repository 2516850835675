import * as React                    from 'react';

import { useAlert                  } from 'react-alert';

import { v4 as uuidv4              } from 'uuid';

import Drawer                        from '@mui/material/Drawer';

import { PhotoProvider             } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { ArrowBackVector           } from 'src/components/vector/arrowback';

import { IconButton                } from 'src/components/common/icon-button';
import { Button                    } from 'src/components/common/button';
import { Checkbox                  } from 'src/components/common/checkbox';
import { RadioGroup,
         RadioGroupItem            } from 'src/components/common/radio-group';
import { Typography                } from 'src/components/common/typography';
import { Labeled                   } from 'src/components/common/labeled';
import { InputTextbox              } from 'src/components/common/input/input-textbox';
import { Grow                      } from 'src/components/common/flex/grow';
import { TwoActionDialog           } from 'src/components/common/two-action-dialog';

import { AttachmentAdd             } from 'src/components/features/inspection/edit/attachment-add';
import { AttachmentPDF             } from 'src/components/features/inspection/edit/attachment-pdf';

import { ComplaintEditLookAndFeel, 
         Container,
         HeaderContainer,
         FormContainer,
         SubmitContainer,
         PhotoList,
         Hr,
         CloseOutlineVectorBig, 
         ComplaintEditVariants,
         complaintedit_variants    } from 'src/components/features/inspection/complaint/complaint-edit-dialog/ui';

import { Urls                      } from 'src/providers/routing';

import * as types                    from 'src/services/api/types';
import Util                          from 'src/services/util';
import { ComplaintType             } from 'src/services/api/types/inspection';
import { AccountRole               } from 'src/services/api/types/auth';
import Constants                     from 'src/services/constants';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                  } from 'src/store';

import { voidFunction              } from 'src/common';



const MODE_DEL = types.inspection.DefectFileMode.DEL;
const ADDITIONAL_COSTS = types.inspection.DefectFileType.ADDITIONAL_COSTS;
const MODE_NEW = types.inspection.DefectFileMode.NEW;

type ComplaintEditMode = 'new' | 'edit' | 'tocomplaint' | 'tocomplaintfinance' | 'view';

type ComplaintEditFormElement = { lookAndFeel: ComplaintEditLookAndFeel; dispatch: AppDispatch; mode: ComplaintEditMode };

const Type: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);

  let items: RadioGroupItem[] = [];

  switch (mode)
  {
    case 'tocomplaint':
      items = [{ id: 'complaint', label: 'Рекламация' }];
      break;
    case 'tocomplaintfinance':
      items = [{ id: 'complaint', label: 'Рекламация' }];
      break;
    case 'view':
      items = complaintType === ComplaintType.COMPLAINT ? [{ id: 'complaint', label: 'Рекламация' }] : [{ id: 'remark', label: 'Замечание' }];
      break;
    default:
      items = [{ id: 'remark', label: 'Замечание' }, { id: 'complaint', label: 'Рекламация' }];
  }

  const item = complaintType === ComplaintType.REMARK ?
    items.find((item) => item.id === 'remark')!
    :
    items.find((item) => item.id === 'complaint')!;

  const onChangeHandler = (value: RadioGroupItem) => {
    if (['new', 'edit'].includes(mode))
    {
      dispatch(storeApi.inspection.edit.actions.complaintEditedTypeComplaintChanged(
        value.id === 'remark' ? ComplaintType.REMARK : ComplaintType.COMPLAINT
      ));
    }
  }

  return (
    <RadioGroup
      variant = 'light'
      items = { items }
      value = { item }
      onChange = { onChangeHandler }
    />
  )
}

const IsToScrap: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isToScrap = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsToScrap);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsToScrapChanged(value));
  }

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Уценить металлопрокат до цены лома'
      value = { isToScrap ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const ScrapPrice: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isToScrap = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsToScrap);
  const scrapPrice = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedScrapPrice);

  if (complaintType === ComplaintType.REMARK || !isToScrap)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } isRequired label = 'Цена лома, руб/т'>
      <InputTextbox
        placeholder = 'Цена лома'
        variant = { lookAndFeel.textboxVariant }
        value = { scrapPrice !== null ? `${scrapPrice}` : '' }
        valueTransformer = { Util.selectDigitsAndDotFromString7_2 }
        maxLength = { 10 }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedScrapPriceChanged(value)) }
      />
    </Labeled>
  )
}

const IsEvaluateRolledMetal: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isEvaluateRolledMetal = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsEvaluateRolledMetal);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsEvaluateRolledMetalChanged(value));
  }

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Уценить метоллопрокат'
      value = { isEvaluateRolledMetal ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const RequirementRolledMetall: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isEvaluateRolledMetal = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsEvaluateRolledMetal);
  const requirementRolledMetall = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedRequirementRolledMetall);

  if (complaintType === ComplaintType.REMARK || !isEvaluateRolledMetal)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } isRequired label = 'Укажите требование к уценке'>
      <InputTextbox
        placeholder = 'Требования к уценке'
        variant = { lookAndFeel.textboxVariant }
        value = { requirementRolledMetall !== null ? `${requirementRolledMetall}` : '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedRequirementRolledMetallChanged(value)) }
      />
    </Labeled>
  )
}

const IsToOffsetCosts: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isToOffsetCosts = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsToOffsetCosts);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsToOffsetCostsChanged(value));
  }

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Компенсировать дополнительные затраты'
      value = { isToOffsetCosts ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const AmountOfCompensation: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const alert = useAlert();

  const [guidForDelete, setGuidForDelete] = React.useState<string>('');
  const [confirmDeleteOpened, setConfirmDeleteOpened] = React.useState<boolean>(false);
  const [warningAppendOpened, setWarningAppendOpened] = React.useState<boolean>(false);
  const [warningAppendMessage, setWarningAppendMessage] = React.useState<string>('');

  const defectGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectExpandedGuid);

  const files = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectFiles(store, defectGuid)) ?? [];
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  
  const pdfCountAll = parseInt(account.configs.find((config) => config.code === 'CNT_ADD_COSTS')?.value ?? '3', 10);
  const pdfs = files.filter((file) => file.type === ADDITIONAL_COSTS && file.mode !== MODE_DEL);
  const pdfCountAllow = pdfCountAll - pdfs.length;
  
  const complaintMode = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedMode);
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isToOffsetCosts = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsToOffsetCosts);
  const amountOfCompensation = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedAmountOfCompensation);

  
  const onPDFSelectedHandler = async (list: FileList) => {
    const listAsArray = Array.from(list);
    let warningMessage = '';

    if (list.length > pdfCountAllow)
    {
      warningMessage = `Вы выбрали ${list.length} документов, можно добавить только ${pdfCountAllow}. Лишние документы не добавлены`;
    }

    if (warningMessage !== '')
    {
      setWarningAppendMessage(warningMessage);
      setWarningAppendOpened(true);
    }

    const pdfList = listAsArray.length > pdfCountAllow ? listAsArray.slice(0, pdfCountAllow) : listAsArray;

    for await (const file of pdfList)
    {
      const fileTypeByExt = Util.checkFileTypeByExt(file);

      if (!['PDF'].includes(fileTypeByExt))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      const fileTypeByHeader = await Util.checkFileTypeByHeader(file);

      if (!['PDF'].includes(fileTypeByHeader))
      {
        alert.error(`Файл ${file.name} не является PDF-документом`);
        continue;
      }

      if ((file.size / Math.pow(1024, 2)) > Constants.PIECE_PDF_BIGGEST_SIZE_MB)
      {
        alert.error(`Размер файла ${file.name} превышает допустимый размер прикрепляемого файла в ${Constants.PIECE_PDF_BIGGEST_SIZE_MB} Мбайт`);
        continue;
      }

      const guid = uuidv4();
      const binaryStr = await Util.fileToBinaryString(file);
      const dataUrlResult = await Util.pdfToDataURL(file);

      const fileName = Util.buildDefectAttachmentName(ADDITIONAL_COSTS, file.name);

      if (dataUrlResult.ok)
      {
        dispatch(storeApi.inspection.edit.actions.defectDocumentAdded({
          guid,
          mode: MODE_NEW,
          name: fileName,
          type: ADDITIONAL_COSTS,
          __dataUrl: dataUrlResult.result,
          __binaryStr: binaryStr,
          __mime: file.type,
          __name: fileName
        }));
      }
      else
      {
        alert.error(`Произошла ошибка при обработке файла`);
      }
    }

    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAddHandler = () => {
    document.getElementById(`complaint-pdf-selector`)?.click();
  }

  const onDeleteHandler = (guid: string) => {
    setConfirmDeleteOpened(true);
    setGuidForDelete(guid);
  }

  const onCancelDeleteHandler = () => {
    setConfirmDeleteOpened(false);
  }

  const onAcceptDeleteHandler = () => {
    setConfirmDeleteOpened(false);
    dispatch(storeApi.inspection.edit.actions.defectDocumentDeleted(guidForDelete));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: true })), 200);
  }

  const onAcceptWarningAppendHandler = () => {
    setWarningAppendOpened(false);
  }

  if (complaintType === ComplaintType.REMARK || !isToOffsetCosts)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } isRequired label = 'Сумма компенсации без НДС'>
      <InputTextbox
        placeholder = 'Сумма с ед. изм.'
        variant = { lookAndFeel.textboxVariant }
        value = { amountOfCompensation !== null ? `${amountOfCompensation}` : '' }
        valueTransformer = { Util.selectDigitsAndDotFromString7_2 }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedAmountOfCompensationChanged(value)) }
      />
      <PhotoList lookAndFeel = { lookAndFeel }>
        <PhotoProvider >
          {pdfs.map((pdf) => (
            <AttachmentPDF
              key = { pdf.guid }
              variant = { lookAndFeel.attachmentPDFVariants }
              isAllowDelete = { complaintMode !== 'view' }
              pdf = { pdf }
              source = 'inspection'
              onDelete = { onDeleteHandler }
            />
          ))}
          {pdfCountAllow > 0 && complaintMode !== 'view' && (
            <AttachmentAdd
              variant = { lookAndFeel.attachmentAddVariants }
              attachmentType = { types.inspection.PieceFileType.PDF }
              label = 'PDF файл'
              isRequired = { pdfs.length === 0 }
              allowCount = { pdfCountAllow }
              onTap = { onAddHandler }
            />
          )}
        </PhotoProvider>
      </PhotoList>
      <input
        id = 'complaint-pdf-selector'
        type = 'file'
        accept = '.pdf'
        multiple
        onChange = { (e) => e.target.files !== null && onPDFSelectedHandler(e.target.files) }
        onClick = { () => (document.getElementById('complaint-pdf-selector') as HTMLInputElement).value = '' }
        style = { { display: 'none' } }
      />
      <TwoActionDialog 
        variant = { lookAndFeel.pdfDialogVariant } 
        opened = { confirmDeleteOpened }
        caption = 'Удалить документ?'
        onCancel = { onCancelDeleteHandler }
        onAction = { onAcceptDeleteHandler }
      />
      <TwoActionDialog 
        variant = { lookAndFeel.pdfDialogVariant } 
        opened = { warningAppendOpened }
        caption = 'Внимание'
        message = { warningAppendMessage }
        withoutCancel
        actionLabel = 'Ok'
        isDanger
        onAction = { onAcceptWarningAppendHandler }
      />
    </Labeled>
  )
}

const IsReturnOfRolledMetal: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isReturnOfRolledMetal = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsReturnOfRolledMetal);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsReturnOfRolledMetalChanged(value));
  }

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Осуществить возврат металлопроката'
      value = { isReturnOfRolledMetal ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const IsSale: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isSale = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsSale);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsSaleChanged(value));
  }

  if (complaintType === ComplaintType.REMARK)
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить скидку на забракованную партию проката в размере'
      value = { isSale ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const Sale: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isSale = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsSale);
  const sale = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedSale);

  if (complaintType === ComplaintType.REMARK || !isSale)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } isRequired label = 'Размер скидки (%)'>
      <InputTextbox
        placeholder = '%'
        variant = { lookAndFeel.textboxVariant }
        value = { sale !== null ? `${sale}` : '' }
        valueTransformer = { Util.selectPercentFromString }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedSaleChanged(value)) }
      />
    </Labeled>
  )
}

const IsRepresentative: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isRepresentative = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsRepresentative);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsRepresentativeChanged(value));
  }

  if (complaintType === ComplaintType.REMARK && (mode !== 'view' || !isRepresentative))
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Направить представителя'
      value = { isRepresentative ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const IsProvideResponse: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isProvideResponse = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsProvideResponse);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsProvideResponseChanged(value));
  }

  if (complaintType === ComplaintType.REMARK && (mode !== 'view' || !isProvideResponse))
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить оперативный ответ по действиям с забракованным металлопрокатом или возможности переработки проката'
      value = { isProvideResponse ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const IsIdentifyDangerous: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isIdentifyDangerous = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsIdentifyDangerous);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsIdentifyDangerousChanged(value));
  }

  if (complaintType === ComplaintType.REMARK && (mode !== 'view' || !isIdentifyDangerous))
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Определить потенциально опасные партии проката по указанному несоответствию'
      value = { isIdentifyDangerous ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const IsAnalizProblem: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const isAnalizProblem = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsAnalizProblem);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIsAnalizProblemChanged(value));
  }

  if (complaintType === ComplaintType.REMARK && (mode !== 'view' || !isAnalizProblem))
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Провести анализ проблем с целью выяснения причины дефекта'
      value = { isAnalizProblem ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const Is8d: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const is8d = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIs8d);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintEditedIs8dChanged(value));
  }

  if (complaintType === ComplaintType.REMARK && (mode !== 'view' || !is8d))
  {
    return null;
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Предоставить отчет в формате 8D'
      value = { is8d ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const OtherReq: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const complaintType = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const otherReq = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedOtherReq);
  const isOtherReqVisible = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedIsOtherReqVisible);

  if (complaintType === ComplaintType.COMPLAINT && !isOtherReqVisible)
  {
    return null;
  }

  return (
    <Labeled
      variant = { lookAndFeel.labeledVariant }
      label = { complaintType === ComplaintType.REMARK ? 'Другие требования' : 'Комментарий к требованию' }
    >
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { otherReq ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedOtherReqChanged(value)) }
      />
    </Labeled>
  )
}

const IsContactDataExpanded: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const contactDataExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedContactDataExpanded);

  const onChangeHandler = (value: boolean) => {
    dispatch(storeApi.inspection.edit.actions.complaintContactDataExpandedChanged(value));
  }

  return (
    <Checkbox
      variant = 'h24bluetext'
      label = 'Данные контактного лица представителя'
      value = { contactDataExpanded ?? false }
      isDisabled = { mode === 'view' }
      onChange = { onChangeHandler }
    />
  )
}

const FIO: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const contactDataExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedContactDataExpanded);
  const fio = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedFIO);

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'ФИО'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { fio ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedFIOChanged(value)) }
      />
    </Labeled>
  )
}

const Enterprise: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const contactDataExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedContactDataExpanded);
  const enterprise = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedEnterprise);

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Организация'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { enterprise ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedEnterpriseChanged(value)) }
      />
    </Labeled>
  )
}

const Phone: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const contactDataExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedContactDataExpanded);
  const phone = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedPhone);

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Телефон'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { phone ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedPhoneChanged(value)) }
      />
    </Labeled>
  )
}

const Email: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const contactDataExpanded = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedContactDataExpanded);
  const email = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedEmail);

  if (!contactDataExpanded)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'E-mail'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { email ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedEmailChanged(value)) }
      />
    </Labeled>
  )
}

const GroupRevised: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const groupRevised = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedGroupRevised);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Переработанные партии без дефектов'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        placeholder = '№'
        value = { groupRevised ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedGroupRevisedChanged(value)) }
      />
    </Labeled>
  )
}

const GroupRevisedValue: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const groupRevisedValue = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedGroupRevisedValue);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Объем (т)'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        placeholder = 'Тонн'
        value = { `${groupRevisedValue ?? ''}` }
        valueTransformer = { Util.selectDigitsFromString }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedGroupRevisedValueChanged(value)) }
      />
    </Labeled>
  )
}

const GroupUnrevised: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const groupUnrevised = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedGroupUnrevised);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Не переработанные партии на складе'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        placeholder = '№'
        value = { groupUnrevised ?? '' }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedGroupUnrevisedChanged(value)) }
      />
    </Labeled>
  )
}

const GroupUnrevisedValue: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const groupUnrevisedValue = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedGroupUnrevisedValue);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Объем (т)'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        placeholder = 'Тонн'
        value = { `${groupUnrevisedValue ?? ''}` }
        valueTransformer = { Util.selectDigitsFromString }
        isDisabled = { mode === 'view' }
        onChange = { (value) => dispatch(storeApi.inspection.edit.actions.complaintEditedGroupUnrevisedValueChanged(value)) }
      />
    </Labeled>
  )
}

const Decision: React.FC<ComplaintEditFormElement> = ({ lookAndFeel, dispatch, mode }) => {
  const decision = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedDecision);

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Решение по забракованной продукции'>
      <InputTextbox
        variant = { lookAndFeel.textboxVariant }
        value = { decision ?? '' }
        isDisabled
        onChange = { voidFunction }
      />
    </Labeled>
  )
}


type Props = {
  variant: ComplaintEditVariants;
}
export const ComplaintEdit: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = complaintedit_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const [requestSendOpened, setRequestSendOpened] = React.useState<boolean>(false);
  const [submitErrorOpened, setSubmitErrorOpened] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>('');

  const isOpened = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditOpened);
  const mode = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedMode);
  const isGroup = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsGroup);
  const typeComplaint = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintEditedTypeComplaint);
  const defectGuid = useAppSelector(storeApi.inspection.edit.selectors.selectComplaintDefectGuid);
  const defectTypeComplaint = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectComplaintType(store, defectGuid));
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const onSubmitHandler = () => {
    dispatch(storeApi.inspection.edit.async.checkComplaintBeforeAddAsync())
      .unwrap()
      .then(() => {
        if (typeComplaint === ComplaintType.REMARK)
        {
          if (account.role === AccountRole.ClientAdmin)
          {
            setRequestSendOpened(true);
          }
          else
          {
            submit(account.role === AccountRole.Stpk);
          }
        }
        else
        {
          submit(false);
        }
      })
      .catch((error) => {
        setSubmitError(error);
        setSubmitErrorOpened(true);
      });
  }

  const onRequestSendCancelHandler = () => {
    setRequestSendOpened(false);
    submit(false);
  }

  const onRequestSendAcceptHandler = () => {
    setRequestSendOpened(false);
    submit(true);
  }

  const onActionSubmitErrorHandler = () => {
    if (submitError === 'Изменения отсутствуют')
    {
      if (isGroup)
      {
        dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
      }
      else
      {
        Urls.InspectionReturnFromComplaint.build().navigate();
      }
    }
    setSubmitErrorOpened(false);
    setSubmitError('');
  }

  const submit = (isTransferImmediately: boolean) => {
    dispatch(storeApi.inspection.edit.async.addComplaintAsync({ isTransferImmediately }))
      .unwrap()
      .then((result) => {
        setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
        if (isGroup)
        {
          dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
        }
        else
        {
          Urls.InspectionReturnFromComplaint.build(['tocomplaint', 'tocomplaintfinance'].includes(mode)).navigate();
        }
      })
      .catch((error) => {
        setSubmitError(error);
        setSubmitErrorOpened(true);
      });
  }

  const closeAndSaveImmediately = () => {
    dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
    if (isGroup)
    {
      dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
    }
    else
    {
      Urls.InspectionReturnFromComplaint.build().navigate();
    }
  }

  const closeByCancel = () => {
    dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
    if (isGroup)
    {
      dispatch(storeApi.inspection.edit.actions.complaintEditClosed());
    }
    else
    {
      Urls.InspectionReturnFromComplaint.build().navigate();
    }
  }

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { closeAndSaveImmediately }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <HeaderContainer lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { closeAndSaveImmediately }
          >
            <ArrowBackVector />
          </IconButton>
          <Typography variant = { lookAndFeel.headerTypographyVariant }>
            { mode === 'view' ? 'Требование' : (mode === 'new' ? 'Добавление требования' : 'Изменение требования') }
          </Typography>
          <Grow size = { 2 } />
          <IconButton
            variant = { lookAndFeel.headerIconButtonVariant }
            isTapAllowed
            onTap = { closeAndSaveImmediately }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </HeaderContainer>
        <FormContainer lookAndFeel = { lookAndFeel }>
          <Type lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Typography variant = 'blackw600s18lh24'>Требования и запрашиваемые действия к поставщику</Typography>
          <IsToScrap lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <ScrapPrice lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsEvaluateRolledMetal lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <RequirementRolledMetall lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsToOffsetCosts lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <AmountOfCompensation lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsReturnOfRolledMetal lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsSale lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Sale lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsRepresentative lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsProvideResponse lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsIdentifyDangerous lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <IsAnalizProblem lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Is8d lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <OtherReq lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Hr lookAndFeel = { lookAndFeel } />
          <IsContactDataExpanded lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <FIO lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Enterprise lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Phone lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Email lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Hr lookAndFeel = { lookAndFeel } />
          <Typography variant = 'blackw600s18lh24'>Информация</Typography>
          <GroupRevised lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <GroupRevisedValue lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <GroupUnrevised lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <GroupUnrevisedValue lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          <Decision lookAndFeel = { lookAndFeel } dispatch = { dispatch } mode = { mode } />
          {mode !== 'view' && (
            <SubmitContainer lookAndFeel = { lookAndFeel }>
              <Button
                variant = { lookAndFeel.cancelButtonVariant }
                onTap = { closeByCancel }
                label = 'Отмена'
              />
              <Button
                variant = { lookAndFeel.submitButtonVariant }
                onTap = { onSubmitHandler }
                label = { defectTypeComplaint === null ? 'Добавить' : 'Изменить' }
              />
            </SubmitContainer>
          )}
        </FormContainer>
        <TwoActionDialog 
          variant = { lookAndFeel.requestSendDialogVariant }
          opened = { requestSendOpened }
          caption = 'Передать на Северсталь для рассмотрения?'
          cancelLabel = 'Отмена'
          actionLabel = 'Передать'
          onCancel = { onRequestSendCancelHandler }
          onAction = { onRequestSendAcceptHandler }
        />
        <TwoActionDialog 
          variant = { lookAndFeel.requestSendDialogVariant }
          opened = { submitErrorOpened }
          caption = 'Ошибка'
          message = { submitError }
          isDanger
          withoutCancel
          onAction = { onActionSubmitErrorHandler }
        />
      </Container>
    </Drawer>
  );
};
