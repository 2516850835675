import styled                 from 'styled-components';

import { CloseVector        } from 'src/components/vector/close';

import { DialogVariants     } from 'src/components/common/dialog';
import { ButtonVariants     } from 'src/components/common/button';
import { IconButtonVariants } from 'src/components/common/icon-button';
import { LoadingVariants    } from 'src/components/common/loading';

import { cssFlexColumn,
         cssFlexRow,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors        } from 'src/providers/theme/shared';



type SelectModeDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  gap: number;

  loadingHeight: number;
  loadingTypo: Typo;
  loadingColor: string;
  loadingGap: number;

  buttonVariant: ButtonVariants;
  dialogVariant: DialogVariants;
  closeButtonVariant: IconButtonVariants;
  loadingVariant: LoadingVariants;
}

const selectmodedialog_light: SelectModeDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.PrimaryBlack,

  gap: 24,

  loadingHeight: 168,
  loadingTypo: themeTypos.GRTSKREG_W400_S12_LH14,
  loadingColor: themeColors.PrimaryBlack,
  loadingGap: 10,

  buttonVariant: 'h48bluefill',
  dialogVariant: 'light',
  closeButtonVariant: 's48bluered',
  loadingVariant: 'lightblue24',
}

export type SelectModeDialogVariants = 'light';

export const selectmodedialog_variants: Map<SelectModeDialogVariants, SelectModeDialogLookAndFeel> = 
  new Map<SelectModeDialogVariants, SelectModeDialogLookAndFeel>([
    ['light', selectmodedialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: SelectModeDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  position: relative;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const LoadingContainer = styled.div<{
  lookAndFeel: SelectModeDialogLookAndFeel
}>`
  ${cssFlexColumn};
  justify-content: center;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.loadingGap}px;

  height: ${(args) => args.lookAndFeel.loadingHeight}px;
`

export const LoadingLabel = styled.div<{
  lookAndFeel: SelectModeDialogLookAndFeel
}>`
  ${cssFlexRow};
  justify-content: center;

  ${(args) => typoAsCSS(args.lookAndFeel.loadingTypo)};
  color: ${(args) => args.lookAndFeel.loadingColor};
`

export const Caption = styled.div<{
  lookAndFeel: SelectModeDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Close = styled.div<{
  lookAndFeel: SelectModeDialogLookAndFeel
}>`
  position: absolute;
  top: 0;
  right: 0;
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;
