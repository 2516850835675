import * as React               from 'react';

import { useNavigate          } from 'react-router-dom';

import { useTheme             } from 'styled-components';

import { useAlert             } from 'react-alert';

import { Dialog               } from 'src/components/common/dialog';
import { OneActionDialog      } from 'src/components/common/one-action-dialog';
import { BlockUI              } from 'src/components/common/block-ui';
import { Labeled              } from 'src/components/common/labeled';
import { InputPhone           } from 'src/components/common/input/input-phone';
import { InputTextbox, 
         OnlyDigits           } from 'src/components/common/input/input-textbox';
import { Button               } from 'src/components/common/button';
import { VGap                 } from 'src/components/common/flex/vgap';

import { NavBar               } from 'src/components/features/auth/common/nav-bar';

import { Screen, 
         ScreenBody, 
         ActionButtons, 
         layout_light, 
         layout_dark, 
         RegRequestCodeLayout } from 'src/containers/auth/reg-request-code/layout';

import { Urls                 } from 'src/providers/routing';

import Api                      from 'src/services/api';

import { AppDispatch,
         useAppSelector,  
         useAppDispatch,
         storeApi             } from 'src/store';
import { RequestInviteReject  } from 'src/store/auth/reg-request-code'

import { AsyncOpStatus        } from 'src/common';



type RegRequestInviteScreenElement = { layout: RegRequestCodeLayout; dispatch: AppDispatch; }

const Navigation: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const navigate = useNavigate();

  return (
    <NavBar
      variant = { layout.navbarVariant }
      label = 'Заявка на получение пригласительного кода'
      onBackTap = { () => navigate(-1) }
    />
  )
}

const Company: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const company = useAppSelector(storeApi.auth.regRequestCode.selectors.selectCompany);
  const companyError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectCompanyError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapForm } />
      <Labeled variant = { layout.labeledVariant } label = 'Название компании' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { company }
          isError = { companyError !== '' }
          hint = { companyError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.companyChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.companyErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Inn: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const inn = useAppSelector(storeApi.auth.regRequestCode.selectors.selectInn);
  const innError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectInnError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'ИНН организации' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { inn }
          isError = { innError !== '' }
          hint = { innError }
          type = 'number'
          inputMode = 'numeric'
          valueTransformer = { OnlyDigits }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.innChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.innErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Surname: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const surname = useAppSelector(storeApi.auth.regRequestCode.selectors.selectSurname);
  const surnameError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectSurnameError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Фамилия' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { surname }
          isError = { surnameError !== '' }
          hint = { surnameError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.surnameChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.surnameErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Name: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const name = useAppSelector(storeApi.auth.regRequestCode.selectors.selectName);
  const nameError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectNameError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Имя' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { name }
          isError = { nameError !== '' }
          hint = { nameError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.nameChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.nameErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Patronymic: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const patronymic = useAppSelector(storeApi.auth.regRequestCode.selectors.selectPatronymic);
  const patronymicError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectPatronymicError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Отчество' isOptional>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { patronymic }
          isError = { patronymicError !== '' }
          hint = { patronymicError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.patronymicChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.patronymicErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Phone: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const phone = useAppSelector(storeApi.auth.regRequestCode.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectPhoneError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Номер телефона' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.phoneChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.phoneErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Email: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const email = useAppSelector(storeApi.auth.regRequestCode.selectors.selectEmail);
  const emailError = useAppSelector(storeApi.auth.regRequestCode.selectors.selectEmailError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Е-mail' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { email }
          isError = { emailError !== '' }
          hint = { emailError }
          onChange = { (value) => dispatch(storeApi.auth.regRequestCode.actions.emailChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regRequestCode.actions.emailErrorFixed()) }
        />
      </Labeled>
      <VGap size = { layout.gapButtons } />
    </React.Fragment>
  )
}



const Actions: React.FC<RegRequestInviteScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const formFilled = useAppSelector(storeApi.auth.regRequestCode.selectors.selectFormFilled);

  const cancelTapHandler = () => {
    navigate(-1);
  }

  const onSendTapHandler = () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.auth.regRequestCode.async.requestInviteAsync())
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        setCompleted(true);
      })
      .catch((rawError) => {
        const error = rawError as RequestInviteReject;
        if (error.reason === 'api' && !Api.isCommonAuthError(error.apiError?.statusCode ?? 0))
        {
          alert.error(error.apiError?.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  const onCompleteTapHandler = () => {
    dispatch(storeApi.auth.regStart.actions.inviteCodeChanged('39NT74OK'));
    setCompleted(false);
    Urls.RegisterStart.build().navigateWithReplace({ backTo: 'login' });
  }

  return (
    <ActionButtons layout = { layout }>
      <Button
        variant = { layout.cancelButtonVariant }
        label = 'Отмена'
        onTap = { cancelTapHandler }
      />
      <Button
        variant = { layout.submitButtonVariant }
        label = 'Отправить'
        isDisabled = { !formFilled }
        onTap = { onSendTapHandler }
      />
      <BlockUI
        variant = { layout.blockuiVariant } 
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = 'Запрос пригласительного кода'
      />
      <Dialog variant = { layout.dialogVariant } isOpened = { completed }>
        <OneActionDialog
          variant = { layout.completeVariant }
          caption = 'Заявка оформлена'
          message = 'Актуальный пригласительный код и ваша компания будут подтверждены в течение 24 часов'
          actionLabel = 'Продолжить работу'
          onAction = {onCompleteTapHandler}
        />
      </Dialog>
    </ActionButtons>
  )
}

export const RegRequestCodeScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      console.log('reg-qrequest-code push');
      Urls.RegisterRequestCode.pushState({});
    }

    bootOpStarted.current = true;

    const handler = (ev: PopStateEvent) => {
      console.log('reg-request-code', ev);
      switch (ev.state.usr?.backTo)
      {
        case 'reg':
          Urls.RegisterStart.build().navigateWithReplace({ backTo: 'login' });
          break;
        default:
      }
    }
    window.addEventListener('popstate', handler);

    return () => window.removeEventListener('popstate', handler);
  });

  console.log(window.history);

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Company layout = { layout } dispatch = { dispatch } />
        <Inn layout = { layout } dispatch = { dispatch } />
        <Surname layout = { layout } dispatch = { dispatch } />
        <Name layout = { layout } dispatch = { dispatch } />
        <Patronymic layout = { layout } dispatch = { dispatch } />
        <Phone layout = { layout } dispatch = { dispatch } />
        <Email layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
      <Actions layout = { layout } dispatch = { dispatch } />
    </Screen>
  );
};
