
import React from 'react';



type Props = {
  className?: string;
}
export const InfoVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 9V7M12 10.5V17M3 12C3 10.22 3.52784 8.47991 4.51677 6.99987C5.50571 5.51983 6.91131 4.36628 8.55585 3.68509C10.2004 3.0039 12.01 2.82567 13.7558 3.17294C15.5016 3.5202 17.1053 4.37737 18.364 5.63604C19.6226 6.89471 20.4798 8.49836 20.8271 10.2442C21.1743 11.99 20.9961 13.7996 20.3149 15.4442C19.6337 17.0887 18.4802 18.4943 17.0001 19.4832C15.5201 20.4722 13.78 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12Z' />
    </svg>
  );
};
