import styled             from 'styled-components';

import { cssFlexColumn, 
         cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type LabeledLookAndFeel = {
  typo: Typo;

  labelColor: string;
  asteriskColor: string;
  optionalColor: string;

  labelGap: number;
  internalLabelGap: number;
  multiChildrenGap: number;
}

const labeled_light: LabeledLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S14_LH17,

  labelColor: themeColors.AccentBlue1,
  asteriskColor: themeColors.AccentRed1,
  optionalColor: themeColors.SecondaryGray5,

  labelGap: 8,
  internalLabelGap: 3,
  multiChildrenGap: 8,
}

export type LabeledVariants = 'light';

export const labeled_variants: Map<LabeledVariants, LabeledLookAndFeel> = 
  new Map<LabeledVariants, LabeledLookAndFeel>([
    ['light', labeled_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: LabeledLookAndFeel
}>`
  ${cssFlexColumn};

  width: 100%;
  gap: ${(args) => args.lookAndFeel.labelGap}px;
`

export const Label = styled.span<{
  lookAndFeel: LabeledLookAndFeel,
}>`
  ${cssFlexRow};

  gap: ${(args) => args.lookAndFeel.internalLabelGap}px;
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`

export const Asterisk = styled.i<{
  lookAndFeel: LabeledLookAndFeel,
}>`
  font-style: normal;
  color: ${(args) => args.lookAndFeel.asteriskColor};
`

export const Optional = styled.i<{
  lookAndFeel: LabeledLookAndFeel,
}>`
  font-style: normal;
  color: ${(args) => args.lookAndFeel.optionalColor};
`

export const Component = styled.div<{
  lookAndFeel: LabeledLookAndFeel,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.multiChildrenGap}px;
`;
