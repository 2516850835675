import * as React                 from 'react';

import Drawer                     from '@mui/material/Drawer';

import { IconButton             } from 'src/components/common/icon-button';
import { InputMultiline         } from 'src/components/common/input/input-multiline';
import { Button                 } from 'src/components/common/button';


import { Container,
         Header,
         Close,
         CloseOutlineVectorBig, 
         FormContainer,
         RevocationVariants,
         revocation_variants    } from 'src/components/features/main/inspection-list/revocation-dialog/ui';



type Props = {
  variant: RevocationVariants;
  isOpened: boolean;
  note: string;
  onNoteChange: (value: string) => void;
  onClose: () => void;
  onRevocation: () => void;
}
export const Revocation: React.FC<Props> = ({
  variant,
  isOpened,
  note,
  onNoteChange,
  onClose,
  onRevocation,
}) => {
  const lookAndFeel = revocation_variants.get(variant)!;

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <Header lookAndFeel = { lookAndFeel }>
          Причина отзыва
          <Close lookAndFeel = { lookAndFeel }>
            <IconButton variant = { lookAndFeel.headerCloseVariant } isTapAllowed onTap = { onClose }>
              <CloseOutlineVectorBig />
            </IconButton>
          </Close>
        </Header>
        <FormContainer lookAndFeel = { lookAndFeel }>
          <InputMultiline
            variant = { lookAndFeel.inputMultilineVariant }
            placeholder='Введите причину'
            value = { note }
            isClearAllowed={false}
            onChange = { onNoteChange }
          />
          <Button
            variant = { lookAndFeel.submitVariant }
            label = 'Отозвать'
            isDisabled = { note.trim().length < 5 }
            onTap = { onRevocation }
          />
        </FormContainer>
      </Container>
    </Drawer>
  );
};
