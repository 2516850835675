import { createSlice,
         createAsyncThunk,
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';
import Constants             from 'src/services/constants';
import Util                  from 'src/services/util';
import Preferences           from 'src/services/preferences';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'main:shade-selection';

type Roll = {
  id: string;
  isPrimary: boolean;
  value: string;
  error: string;
}

export interface MainShadeSelectionState {
  selectionDate: number;
  activeTab: string;
  opStatus: AsyncOpStatus;
  opStatusLabel: string;
  rolls: Roll[];
  results: types.service.ShadeSelectionItem[];
  isResultGenerated: boolean;
  isRatingCompleted: boolean;
  isRatingOpened: boolean;
}

const initialState: MainShadeSelectionState = {
  selectionDate: Date.now(),
  activeTab: 'input-data',
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',
  rolls: [
    { id: Util.guid(true), isPrimary: true, value: '', error: '' },
    { id: Util.guid(true), isPrimary: false, value: '', error: '' }
  ],
  results: [],
  isResultGenerated: false,
  isRatingCompleted: false,
  isRatingOpened: false,
}

// #region generateSelectionAsync
type GenerateSelectionArgs = void;
type GenerateSelectionResolve = types.service.PostShadeSelectionsOut;
export type GenerateSelectionReject = ApiError;
export const generateSelectionAsync = createAsyncThunk<
  GenerateSelectionResolve,
  GenerateSelectionArgs,
  {
    state: RootState,
    rejectValue: GenerateSelectionReject
  }
>(
  `${SLICE_NAME}/generateSelectionAsync`,
  async (_, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState().mainShadeSelection;

      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusLabelChanged('Выполняется подбор'));
      const results = await Api.postShadeSelections({
        pieceEtalon: state.rolls.find((roll) => roll.isPrimary)?.value ?? '',
        pieces: state.rolls.filter((roll) => !roll.isPrimary).map((roll) => ({ piece: roll.value })),
      });
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.IDLE));
      return results;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

// #region sendRatingMessageAsync
type SendRatingMessageArgs = { isYes: boolean; note: string; };
type SendRatingMessageResolve = void;;
export type SendRatingMessageReject = ApiError;
export const sendRatingMessageAsync = createAsyncThunk<
  SendRatingMessageResolve,
  SendRatingMessageArgs,
  {
    state: RootState,
    rejectValue: SendRatingMessageReject
  }
>(
  `${SLICE_NAME}/sendRatingMessageAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState().mainShadeSelection;

      const message = `
        Эталонный рулон: ${state.rolls[0].value} ${state.rolls[0].error !== '' ? ` (${state.rolls[0].error})` : ''}.
        Рулоны для подбора: ${state.rolls.filter((item) => !item.isPrimary).map((roll) => `${roll.value} ${roll.error !== '' ? ` (${roll.error})` : ''}`)}.
        Результат подбора: ${args.isYes ? 'Корректный' : 'Некорректный'}.
        Комментарий пользователя: ${args.note !== '' ? args.note : 'Отсутствует'}.
      `;

      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusLabelChanged('Отправка оценки'));
      const results = await Api.postMessage({
        guid: Util.guid(false),
        inspectionNum: null,
        topic: 'Подбор по оттенку',
        textMessage: message,
      });
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.IDLE));
      return results;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainShadeSelectionActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

type ActiveTabChangedAction = PayloadAction<string>;
type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;
type RollChangedPayload = {
  id: string;
  value: string;
};
type RollChangedAction = PayloadAction<RollChangedPayload>;
type RollDeletedAction = PayloadAction<string>;

export const mainShadeSelectionSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    init: (state) => {
      const storedValue = Preferences.shadeSelection;

      if (storedValue === '')
      {
        state.selectionDate = Date.now();
        state.activeTab = 'input-data';
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.rolls = [
          { id: Util.guid(true), isPrimary: true, value: '', error: '' },
          { id: Util.guid(true), isPrimary: false, value: '', error: '' }
        ];
        state.results = [];
        state.isResultGenerated = false;
        state.isRatingCompleted = false;
        state.isRatingOpened = false;

        Preferences.setShadeSelection(JSON.stringify(initialState));
      }
      else
      {
        const storedState = JSON.parse(storedValue) as MainShadeSelectionState;
        state.selectionDate = storedState.selectionDate;
        state.activeTab = storedState.activeTab;
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.rolls = storedState.rolls;
        state.results = storedState.results;
        state.isResultGenerated = storedState.isResultGenerated;
        state.isRatingCompleted = storedState.isRatingCompleted;
        state.isRatingOpened = false;
      }
    },
    activeTabChanged: (state, action: ActiveTabChangedAction) => {
      state.activeTab = action.payload;
      Preferences.setShadeSelection(JSON.stringify(state));
    },
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
    rollAppended: (state) => {
      state.rolls.push({ id: Util.guid(true), isPrimary: false, value: '', error: '' });
      Preferences.setShadeSelection(JSON.stringify(state));
    },
    rollChanged: (state, action: RollChangedAction) => {
      state.rolls.find((roll) => roll.id === action.payload.id)!.value = action.payload.value;
      const digitsCount = Util.selectDigitsFromString(action.payload.value).length;
      
      if (digitsCount === 0 || digitsCount === Constants.SHADE_SELECTION_ROLL_DIGITS)
      {
        state.rolls.find((roll) => roll.id === action.payload.id)!.error = '';
      }
      else
      {
        state.rolls.find((roll) => roll.id === action.payload.id)!.error = 'Рулон должен соответствовать формату ●●●●●● ●●';
      }
      Preferences.setShadeSelection(JSON.stringify(state));
    },
    rollDeleted: (state, action: RollDeletedAction) => {
      state.rolls = state.rolls.filter((roll) => roll.id !== action.payload);
      Preferences.setShadeSelection(JSON.stringify(state));
    },
    ratingOpened: (state) => {
      state.isRatingOpened = true;
    },
    repeated: (state) => {
      if (state.isRatingCompleted)
      {
        state.selectionDate = Date.now();
        state.activeTab = 'input-data';
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.rolls = [
          { id: Util.guid(true), isPrimary: true, value: '', error: '' },
          { id: Util.guid(true), isPrimary: false, value: '', error: '' }
        ];
        state.results = [];
        state.isResultGenerated = false;
        state.isRatingCompleted = false;
        state.isRatingOpened = false;
      }
      else
      {
        state.isRatingOpened = true;
      }
      Preferences.setShadeSelection(JSON.stringify(state));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateSelectionAsync.fulfilled, (state, action) => {
        state.results = action.payload.data;
        state.isResultGenerated = true;
        state.isRatingOpened = false;
        state.isRatingCompleted = false;

        if (action.payload.errors.length > 0)
        {
          state.rolls = state.rolls.map((roll) => ({
            ...roll,
            error: action.payload.errors.find((error) => error.piece === roll.value)?.errorText ?? roll.error
          }))
        }
        Preferences.setShadeSelection(JSON.stringify(state));
      })
      .addCase(sendRatingMessageAsync.fulfilled, (state, action) => {
        state.isRatingCompleted = true;
        state.isRatingOpened = false;
        Preferences.setShadeSelection(JSON.stringify(state));
      })
  },
})

const selectSelectionDate = (state: RootState) => state.mainShadeSelection.selectionDate;
const selectActiveTab = (state: RootState) => state.mainShadeSelection.activeTab;
const selectOpStatus = (state: RootState) => state.mainShadeSelection.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainShadeSelection.opStatusLabel;
const selectRolls = (state: RootState) => state.mainShadeSelection.rolls;
const selectResults = (state: RootState) => state.mainShadeSelection.results;
const selectIsHasErrors = (state: RootState) => state.mainShadeSelection.rolls.some((roll) => roll.error !== '');
const selectIsGenerateEnabled = (state: RootState) => state.mainShadeSelection.rolls.every((roll) => roll.value !== '' && roll.error === '');
const selectIsResultGenerated = (state: RootState) => state.mainShadeSelection.isResultGenerated;
const selectIsRatingCompleted = (state: RootState) => state.mainShadeSelection.isRatingCompleted;
const selectIsRatingOpened = (state: RootState) => state.mainShadeSelection.isRatingOpened;

export const mainShadeSelectionSelectors = {
  selectSelectionDate,
  selectActiveTab,
  selectOpStatus,
  selectOpStatusLabel,
  selectRolls,
  selectResults,
  selectIsHasErrors,
  selectIsGenerateEnabled,
  selectIsResultGenerated,
  selectIsRatingCompleted,
  selectIsRatingOpened,
}

export const mainShadeSelectionActions = mainShadeSelectionSlice.actions;

export default mainShadeSelectionSlice.reducer;