import * as React          from 'react';

import { useTheme        } from 'styled-components';

import { useAlert        } from 'react-alert';

import { useParams,
         useNavigate     } from 'react-router-dom';

import { InputDigitCode  } from 'src/components/common/input/input-digitcode';
import { Grow            } from 'src/components/common/flex/grow';
import { BlockUI         } from 'src/components/common/block-ui';
import { SendAgain       } from 'src/components/common/send-again';

import { NavBar          } from 'src/components/features/auth/common/nav-bar';

import { SMSCodeLayout, 
         Screen,
         ScreenBody,
         layout_light,
         layout_dark     } from 'src/containers/auth/sms-code/layout';

import { Urls            } from 'src/providers/routing';

import Constants           from 'src/services/constants';
import Util                from 'src/services/util';
import * as types          from 'src/services/api/types';
import Api                 from 'src/services/api';

import { AppDispatch,
         useAppDispatch,
         storeApi        } from 'src/store';
import { CheckSMSReject,
         SendSMSReject   } from 'src/store/auth/sms-code';



type SMSCodeScreenElement = { layout: SMSCodeLayout; dispatch: AppDispatch; type: types.auth.TypeTwoFactor; }

const Navigation: React.FC<SMSCodeScreenElement> = ({layout, dispatch, type}) => {
  const navigate = useNavigate();

  return (
    <NavBar
      variant = { layout.navbarVariant }
      label = 'Введите код из SMS'
      onBackTap = { () => navigate(-1) }
    />
  )
}

const Code: React.FC<SMSCodeScreenElement> = ({layout, dispatch, type}) => {
  const alert = useAlert();
  const [blocked, setBlocked] = React.useState<boolean>(false);

  const onCodeChangeHandler = (newValue: string) => {
    const code = Util.selectDigitsFromString(newValue);
    dispatch(storeApi.auth.smsCode.actions.codeChanged(code));

    if (code.length === Constants.SMS_CODE_LENGTH)
    {
      if (['reg', 'changePhone'].includes(type))
      {
        setBlocked(true);
        dispatch(storeApi.auth.smsCode.async.checkSMSAsync({ code, type }))
          .unwrap()
          .then((result) => {
            setBlocked(false);
            dispatch(storeApi.auth.common.actions.authKeyChanged(result.authKey));
            switch (type)
            {
              case 'reg':
                dispatch(storeApi.auth.regEnterData.async.copyPhoneFromRegStartAsync());
                Urls.RegisterEnterData.build().navigateWithReplace({ backTo: 'reg' });
                break;
              case 'changePhone':
                // todo - go куда-то Urls.ResetPassword.build('continue').navigate();
                break;
            }
          })
          .catch((rawError) => {
            const error = rawError as CheckSMSReject;
            if (!Api.isCommonAuthError(error.statusCode ?? 0))
            {
              alert.error(error.message);
            }
            setBlocked(false);
          });
      }
      else
      {
        switch (type)
        {
          case 'login':
            Urls.Login.build('continue').navigateWithReplace();
            break;
          case 'reset':
            Urls.ResetPassword.build('continue').navigateWithReplace();
            break;
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Grow size = { 2 } />
      <InputDigitCode
        variant = { layout.inputDigitCodeVariant }
        countDigits = { 6 }
        value = ''
        onChange = { onCodeChangeHandler }
      />
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { blocked }
        message = 'Проверка SMS-кода'
      />
    </React.Fragment>
  )
}

const SendRepeat: React.FC<SMSCodeScreenElement> = ({layout, dispatch, type}) => {
  const alert = useAlert();
  const [blocked, setBlocked] = React.useState<boolean>(false);
  
  const bootOpStarted = React.useRef<boolean>(false);

  const onSendAgainHandler = React.useCallback(() => {
    setBlocked(true);
    dispatch(storeApi.auth.smsCode.async.sendSMSAsync(type))
      .unwrap()
      .then(() => {
        setBlocked(false);
      })
      .catch((rawError) => {
        const error = rawError as SendSMSReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
        setBlocked(false);
      });
  }, [dispatch, alert, type]);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      bootOpStarted.current = true;
      onSendAgainHandler();
    }
  }, [onSendAgainHandler]);

  return (
    <React.Fragment>
      <Grow size = { 2 } />
      <SendAgain
        variant = { layout.sendAgainVariant }
        onSendAgain = { onSendAgainHandler } />
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { blocked }
        message = 'Отправка SMS-кода'
      />
    </React.Fragment>
  )
}

export const SMSCodeScreen = () => {
  const theme = useTheme();
  const { type } = useParams();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      Urls.SmsCode.pushState({});
    }

    bootOpStarted.current = true;

    const handler = (ev: PopStateEvent) => {
      switch (ev.state.usr?.backTo)
      {
        case 'login':
          Urls.Login.build('start').navigateWithReplace();
          break;
        case 'pwdReset':
          Urls.ResetPassword.build('start').navigateWithReplace({ backTo: 'login' });
          break;
        case 'reg':
          Urls.RegisterStart.build().navigateWithReplace({ backTo: 'login' });
          break;
        default:
      }
    }
    window.addEventListener('popstate', handler);

    return () => window.removeEventListener('popstate', handler);
  });

  console.log(window.history);

  return (
    <Screen layout={layout}>
      <Navigation layout={layout} dispatch={dispatch} type={type as types.auth.TypeTwoFactor} />
      <ScreenBody layout={layout}>
        <Code layout={layout} dispatch={dispatch} type={type as types.auth.TypeTwoFactor} />
        <SendRepeat layout={layout} dispatch={dispatch} type={type as types.auth.TypeTwoFactor} />
      </ScreenBody>
    </Screen>
  );
};
