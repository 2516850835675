import styled                      from 'styled-components';

import { BlockUIVariants         } from 'src/components/common/block-ui';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { EmptyCaseVariants       } from 'src/components/common/empty-case';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { NavBarVariants          } from 'src/components/features/common/nav-bar';
import { AdminUserVariants       } from 'src/components/features/main/admin/admin-user';

import { themeColors, 
         cssFlexColumn           } from 'src/providers/theme/shared';



export type AdminLayout = {
  backgroundColor: string;

  paddings: number[];
  searchGap: number;
  itemsGap: number;

  navbarVariant: NavBarVariants;

  emptyCaseVariant: EmptyCaseVariants;
  blockuiVariant: BlockUIVariants;

  adminUserVariant: AdminUserVariants;
  inputTextboxVariant: InputTextboxVariants;
  blockConfirmVariant: TwoActionDialogVariants;
}

export const layout_light: AdminLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],
  searchGap: 24,
  itemsGap: 8,

  navbarVariant: 'lightblue',

  emptyCaseVariant: 'light',
  blockuiVariant: 'light',

  adminUserVariant: 'light',
  inputTextboxVariant: 'h48light',
  blockConfirmVariant: 'light',
}

export const layout_dark: AdminLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: AdminLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: AdminLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  gap: ${(args) => args.layout.searchGap}px;
  padding: ${(args) => args.layout.paddings[0]}px;

  overflow: scroll;
`

export const UsersContainer = styled.div<{
  layout: AdminLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.itemsGap}px;
`