import styled                      from 'styled-components';

import { CloseVector             } from 'src/components/vector/close';

import { IconButtonVariants      } from 'src/components/common/icon-button';
import { ButtonVariants          } from 'src/components/common/button';
import { TypographyVariants      } from 'src/components/common/typography';
import { LabeledVariants         } from 'src/components/common/labeled';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { AttachmentPDFVariants   } from 'src/components/features/inspection/edit/attachment-pdf';
import { AttachmentAddVariants   } from 'src/components/features/inspection/edit/attachment-add';

import { themeColors,
         cssFlexRow,
         cssFlexColumn           } from 'src/providers/theme/shared';



export type ComplaintEditLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  formPaddings: number[];
  formGap: number;
  formActionsGap: number;
  formSubmitToPadding: number;

  photoListGap: number;

  hrColor: string;

  labeledVariant: LabeledVariants;
  textboxVariant: InputTextboxVariants;
  submitButtonVariant: ButtonVariants;
  cancelButtonVariant: ButtonVariants;
  requestSendDialogVariant: TwoActionDialogVariants;
  pdfDialogVariant: TwoActionDialogVariants;
  attachmentPDFVariants: AttachmentPDFVariants;
  attachmentAddVariants: AttachmentAddVariants;
}

const complaintedit_light: ComplaintEditLookAndFeel = {
  containerBackgroundColor: themeColors.BackgroundFillLight,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  formPaddings: [20, 16],
  formGap: 30,
  formActionsGap: 16,
  formSubmitToPadding: 16,

  photoListGap: 10,

  hrColor: themeColors.SecondaryGray3,

  labeledVariant: 'light',
  textboxVariant: 'h48light',
  submitButtonVariant: 'h48bluefill',
  cancelButtonVariant: 'h48blueoutline',
  requestSendDialogVariant: 'light',
  pdfDialogVariant: 'light',
  attachmentPDFVariants: 'light',
  attachmentAddVariants: 'light',
}

export type ComplaintEditVariants = 'light';

export const complaintedit_variants: Map<ComplaintEditVariants, ComplaintEditLookAndFeel> = 
  new Map<ComplaintEditVariants, ComplaintEditLookAndFeel>([
    ['light', complaintedit_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100dvh;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;
  padding-left: ${(args) => args.lookAndFeel.containerPaddings[1]}px;
`

export const FormContainer = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.formGap}px;
  padding: ${(args) => args.lookAndFeel.formPaddings[0]}px ${(args) => args.lookAndFeel.formPaddings[1]}px;

  overflow-y: auto;
`

export const SubmitContainer = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.formActionsGap}px;
  flex-shrink: 0;
  padding-top: ${(args) => args.lookAndFeel.formSubmitToPadding}px;

  & > *:first-child {
    width: 60%;
  }
`

export const PhotoList = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${(args) => args.lookAndFeel.photoListGap}px;
  grid-row-gap: ${(args) => args.lookAndFeel.photoListGap}px;
`

export const Hr = styled.div<{
  lookAndFeel: ComplaintEditLookAndFeel,
}>`
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;