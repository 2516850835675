import { createSlice,
         createAsyncThunk, 
         PayloadAction     } from '@reduxjs/toolkit';

import Api                   from 'src/services/api';
import Db                    from 'src/services/db';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'main:profile';

export interface MainProfileState {
  refersLoadingStatus: AsyncOpStatus;
}

const initialState: MainProfileState = {
  refersLoadingStatus: AsyncOpStatus.IDLE,
}

// #region updateRefersAsync
type UpdateRefersArgs = void;
type UpdateRefersResolve = void;
export type UpdateRefersReject = void;
export const updateRefersAsync = createAsyncThunk<
  UpdateRefersResolve,
  UpdateRefersArgs,
  {
    state: RootState,
    rejectValue: UpdateRefersReject
  }
>(
  `${SLICE_NAME}/updateRefersAsync`,
  async (_, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainProfileActions.refersLoadingStatusChanged(AsyncOpStatus.BUSY));
      const dbRefersHash = await Db.getOptString('REFER_HASH');
      const apiRefersHash = (await Api.getRefersHash()).hash;
      if (apiRefersHash !== null && (dbRefersHash === null || dbRefersHash !== apiRefersHash))
      {
        const refers = await Api.getRefers();
        await Db.updateRefers(refers, apiRefersHash!);
      }
      thunkAPI.dispatch(mainProfileActions.refersLoadingStatusChanged(AsyncOpStatus.SUCCESS));
    }
    catch (error)
    {
      thunkAPI.dispatch(mainProfileActions.refersLoadingStatusChanged(AsyncOpStatus.ERROR));
    }
  }
);
// #endregion

type RefersLoadingStatusChangedAction = PayloadAction<AsyncOpStatus>;

export const mainProfileSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    refersLoadingStatusChanged: (state, action: RefersLoadingStatusChangedAction) => {
      state.refersLoadingStatus = action.payload;
    },
  },
})

const selectRefersLoadingStatus = (state: RootState) => state.mainProfile.refersLoadingStatus;

export const mainProfileSelectors = {
  selectRefersLoadingStatus,
}

export const mainProfileActions = mainProfileSlice.actions;

export default mainProfileSlice.reducer;