import * as React           from 'react';

import { Container,
         Label,
         Asterisk,
         Optional,
         Component,
         LabeledVariants,
         labeled_variants } from 'src/components/common/labeled/ui';



type Props = {
  variant: LabeledVariants;
  label: string;
  isRequired?: boolean;
  isOptional?: boolean;
  children: React.ReactNode;
}
export const Labeled: React.FC<Props> = ({
  variant,
  label,
  isRequired = false,
  isOptional = false,
  children
}) => {
  const lookAndFeel = labeled_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Label lookAndFeel = { lookAndFeel }>
        { label }
        { isRequired && (<Asterisk lookAndFeel = { lookAndFeel }>*</Asterisk>)}
        { isOptional && (<Optional lookAndFeel = { lookAndFeel }>(необязательно)</Optional>)}
      </Label>
      <Component lookAndFeel = { lookAndFeel }>{ children }</Component>
    </Container>
  );
};
