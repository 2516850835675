import React from 'react';



type Props  =  {
  className?: string;
}
export const EmptyCaseKeyVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '112' height = '88' viewBox = '0 0 112 88' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M69.3333 4C84.0609 4 96 15.9391 96 30.6667C96 45.3943 84.0609 57.3333 69.3333 57.3333C66.6392 57.3333 64.0384 56.9338 61.587 56.1908L51.5556 66.2222H42.6667V75.1111H33.7778V84H16V66.2222L43.8092 38.413C43.0662 35.9616 42.6667 33.3608 42.6667 30.6667C42.6667 20.7962 48.0293 12.1783 56 7.56753' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M59 22L79 41M59 41L66.2727 34.0909M79 22L69 31.5' />
    </svg>
  );
};
