import * as React                      from 'react';

import { Button                      } from 'src/components/common/button';
import { Dialog                      } from 'src/components/common/dialog';

import { Container, 
         Caption,
         Message,
         CertDownloadDialogVariants,
         certdownloaddialog_variants } from 'src/components/features/inspection/view/cert-download-dialog/ui';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch              } from 'src/store';



type Props = {
  variant: CertDownloadDialogVariants;
}
export const CertDownloadDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = certdownloaddialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const opened = useAppSelector(storeApi.inspection.view.selectors.selectCertDownloadOpened);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Запрос на получение сертификата
        </Caption>
        <Message lookAndFeel = { lookAndFeel }>
          Ваш запрос поставлен в очередь, через 10 минут зайдите в осмотр снова
        </Message>
        <Button
          variant = { lookAndFeel.buttonVariant }
          label = 'Продолжить'
          onTap = { () => dispatch(storeApi.inspection.view.actions.certDownloadClosed()) }
        />
      </Container>
    </Dialog>
  );
};
