import styled                        from 'styled-components';

import { BlockUIVariants           } from 'src/components/common/block-ui';
import { EmptyCaseVariants         } from 'src/components/common/empty-case';
import { ButtonVariants            } from 'src/components/common/button';

import { NavBarVariants            } from 'src/components/features/common/nav-bar';
import { RevokeTokenDialogVariants } from 'src/components/features/main/token/revoke-token-dialog';
import { TokenItemVariants         } from 'src/components/features/main/token/token-item';

import { themeColors, 
         cssFlexColumn             } from 'src/providers/theme/shared';



export type TokenLayout = {
  backgroundColor: string;

  paddings: number[];
  bodyGap: number;
  itemsGap: number;

  navbarVariant: NavBarVariants;

  emptyCaseVariant: EmptyCaseVariants;
  blockuiVariant: BlockUIVariants;

  revokeDialogVariant: RevokeTokenDialogVariants;
  tokenItemVariant: TokenItemVariants;

  buttonVariant: ButtonVariants;
}

export const layout_light: TokenLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],
  bodyGap: 20,
  itemsGap: 8,

  navbarVariant: 'lightblue',

  emptyCaseVariant: 'light',
  blockuiVariant: 'light',

  revokeDialogVariant: 'light',
  tokenItemVariant: 'light',

  buttonVariant: 'h48bluefill',
}

export const layout_dark: TokenLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: TokenLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: TokenLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  gap: ${(args) => args.layout.bodyGap}px;
  padding: ${(args) => args.layout.paddings[0]}px;

  overflow: scroll;

  & > *:nth-child(1) {
    flex-grow: 2;
  }

  & > *:nth-child(2) {
    flex-shrink: 0;
  }
`

export const ItemsContainer = styled.div<{
  layout: TokenLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.itemsGap}px;
`