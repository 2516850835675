import React from 'react';



type Props = {
  className?: string;
}
export const EyeShowVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path
        d = 'M1 13.4L7.11111 19H16.8889L23 13.4V10.6L16.8889 5H7.11111L1 10.6V13.4Z'
        stroke = 'currentColor' 
        strokeWidth = '2'
      />
      <path
        d = 'M8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8'
        stroke = 'currentColor'
        strokeWidth = '2'
      />
      <path
        d = 'M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12'
        stroke = 'currentColor' 
        strokeWidth = '1.5'
      />
    </svg>
  );
};



