import styled                  from 'styled-components';

import { BlockUIVariants     } from 'src/components/common/block-ui';
import { EmptyCaseVariants   } from 'src/components/common/empty-case';

import { NavBarVariants      } from 'src/components/features/common/nav-bar';

import { HistoryItemVariants } from 'src/components/features/qmmsg/question-history/history-item';

import { themeColors, 
         cssFlexColumn       } from 'src/providers/theme/shared';



export type QuestionHistoryLayout = {
  backgroundColor: string;

  paddings: number[];
  itemsGap: number;

  navbarVariant: NavBarVariants;
  questionHistoryItemVariant: HistoryItemVariants;
  blockUiVariant: BlockUIVariants;
  emptyCaseVariant: EmptyCaseVariants;
}

export const layout_light: QuestionHistoryLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],
  itemsGap: 12,

  navbarVariant: 'lightblue',
  questionHistoryItemVariant: 'light',
  blockUiVariant: 'light',
  emptyCaseVariant: 'light',
}

export const layout_dark: QuestionHistoryLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: QuestionHistoryLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: QuestionHistoryLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[0]}px;
  padding-bottom: ${(args) => args.layout.paddings[0]}px;
  padding-left: ${(args) => args.layout.paddings[0]}px;

  overflow: auto;
`

export const ItemsList = styled.div<{
  layout: QuestionHistoryLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.itemsGap}px;
`
