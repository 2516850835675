import * as React            from 'react';

import { InfoVector        } from 'src/components/vector/info';

import { Container,
         Icon,
         Label,
         InfoLinkVariants,
         infolink_variants } from 'src/components/features/main/help/info-link/ui';



type Props = {
  variant: InfoLinkVariants;
  children: React.ReactNode;
  onTap: () => void;
}
export const InfoLink: React.FC<Props> = ({
  variant,
  children,
  onTap,
}) => {
  const lookAndFeel = infolink_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } onClick = { onTap }>
      <Icon lookAndFeel = { lookAndFeel }>
        <InfoVector />
      </Icon>
      <Label lookAndFeel = { lookAndFeel }>
        { children }
      </Label>
    </Container>
  );
};
