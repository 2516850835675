import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';
import { TypographyVariants } from 'src/components/common/typography';

import { cssFlexColumn,
         themeColors        } from 'src/providers/theme/shared';



type DetailNoteLookAndFeel = {
  paddings: number[];
  gap: number;

  backgroundColor: string;
  borderColor: string;

  expandButtonVariant: IconButtonVariants;
  expandButtonTop: number;
  expandButtonRight: number;

  headerTypographyVariant: TypographyVariants;
  noteTypographyVariant: TypographyVariants;
}

const detailnote_light: DetailNoteLookAndFeel = {
  paddings: [16],
  gap: 12,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expandButtonVariant: 's48bluered',
  expandButtonTop: 2,
  expandButtonRight: 16,

  headerTypographyVariant: 'blackw400s15lh20',
  noteTypographyVariant: 'blackw400s14lh17',
}

export type DetailNoteVariants = 'light';

export const detailnote_variants: Map<DetailNoteVariants, DetailNoteLookAndFeel> = 
  new Map<DetailNoteVariants, DetailNoteLookAndFeel>([
    ['light', detailnote_light],
  ]);

export const Wrapper = styled.div<{
  lookAndFeel: DetailNoteLookAndFeel;
}>`
  width: 100%;
  position: relative;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor} !important;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
`;

export const Container = styled.div<{
  lookAndFeel: DetailNoteLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const ExpandButtonWrapper = styled.div<{
  lookAndFeel: DetailNoteLookAndFeel;
}>`
  position: absolute;
  top: ${(args) => args.lookAndFeel.expandButtonTop}px;
  right: ${(args) => args.lookAndFeel.expandButtonRight}px;
`
