
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceProblem8DVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M14 7V20H18.4444L22 16.4444V7.55556L18.4444 4H14V5' />
      <path fill = 'currentColor' d = 'M3.5 4V3H3.08579L2.79289 3.29289L3.5 4ZM8.54545 4L9.26335 3.30385L8.96871 3H8.54545V4ZM2 5.5L1.29289 4.79289L1 5.08579V5.5H2ZM2 10.5H1V10.8802L1.25259 11.1644L2 10.5ZM3.33333 12L4.08074 12.6644V11.3356L3.33333 12ZM2 13.5L1.25259 12.8356L1 13.1198V13.5H2ZM2 18.5H1V18.9142L1.29289 19.2071L2 18.5ZM3.5 20L2.79289 20.7071L3.08579 21H3.5V20ZM8.5 20V21H8.91421L9.20711 20.7071L8.5 20ZM10 18.5L10.7071 19.2071L11 18.9142V18.5H10ZM10 13.5H11V13.0948L10.7179 12.8039L10 13.5ZM10 10.5L10.7179 11.1961L11 10.9052V10.5H10ZM10 5.5H11V5.09477L10.7179 4.80385L10 5.5ZM3.5 5H8.54545V3H3.5V5ZM2.70711 6.20711L4.20711 4.70711L2.79289 3.29289L1.29289 4.79289L2.70711 6.20711ZM1 5.5V10.5H3V5.5H1ZM1.25259 11.1644L2.58592 12.6644L4.08074 11.3356L2.74741 9.83564L1.25259 11.1644ZM2.58592 11.3356L1.25259 12.8356L2.74741 14.1644L4.08074 12.6644L2.58592 11.3356ZM1 13.5V18.5H3V13.5H1ZM1.29289 19.2071L2.79289 20.7071L4.20711 19.2929L2.70711 17.7929L1.29289 19.2071ZM3.5 21H8.5V19H3.5V21ZM9.20711 20.7071L10.7071 19.2071L9.29289 17.7929L7.79289 19.2929L9.20711 20.7071ZM11 18.5V13.5H9V18.5H11ZM10.7179 12.8039L9.26335 11.3039L7.82755 12.6961L9.2821 14.1961L10.7179 12.8039ZM9.26335 12.6961L10.7179 11.1961L9.2821 9.80385L7.82755 11.3039L9.26335 12.6961ZM11 10.5V5.5H9V10.5H11ZM10.7179 4.80385L9.26335 3.30385L7.82755 4.69615L9.2821 6.19615L10.7179 4.80385ZM8.54545 11H6V13H8.54545V11Z' />
    </svg>
  );
};
