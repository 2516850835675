import styled           from 'styled-components';

import { cssFlexRow,
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type EmailStatusLookAndFeel = {
  labelTypo: Typo;
  labelColor: string;
  iconStrokeColor: string;
  gap: number;
}

const emailstatus_lightsuccess: EmailStatusLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  labelColor: themeColors.AccentBlue2,
  iconStrokeColor: themeColors.PrimaryWhite,
  gap: 4,
}

const emailstatus_lightdanger: EmailStatusLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  labelColor: themeColors.AccentRed1,
  iconStrokeColor: themeColors.PrimaryWhite,
  gap: 4,
}

export type EmailStatusVariants = 'lightsuccess' | 'lightdanger';

export const emailstatus_variants: Map<EmailStatusVariants, EmailStatusLookAndFeel> = 
  new Map<EmailStatusVariants, EmailStatusLookAndFeel>([
    ['lightsuccess', emailstatus_lightsuccess],
    ['lightdanger', emailstatus_lightdanger],
  ]);

export const Container = styled.span<{
  lookAndFeel: EmailStatusLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.gap}px;
  align-items: center;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}

  color: ${(args) => args.lookAndFeel.labelColor};

  & circle {
    fill: ${(args) => args.lookAndFeel.labelColor};
  }

  & path {
    stroke: ${(args) => args.lookAndFeel.iconStrokeColor};
  }
`
