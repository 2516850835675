import * as React           from 'react';

import { Link             } from 'src/components/common/link';

import { Container,
         Text,
         ContentVariants,
         content_variants } from 'src/components/features/main/help/content/ui';



type Props = {
  variant: ContentVariants;
}
export const Content: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = content_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Text lookAndFeel = { lookAndFeel }>
        Ознакомиться с более подробной информацией о Checksteel вы можете на официальном портале: 
      </Text>
      <Link
        variant = { lookAndFeel.linkVariant }
        label = 'Мобильное приложение Checksteel (severstal.com)'
        url = 'https://vmeste.severstal.com/services_and_support/checksteel/'
      />
    </Container>
  );
};
