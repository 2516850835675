import * as React                         from 'react';

import { useAlert                       } from 'react-alert';

import { ThreeDotsSpinnerAnimatedVector } from 'src/components/vector/animate/three-dots-spinner';

import { Dialog                         } from 'src/components/common/dialog';
import { Button                         } from 'src/components/common/button';
import { LabeledContent                 } from 'src/components/common/labeled-content';
import { Link                           } from 'src/components/common/link';
import { InputMultiline                 } from 'src/components/common/input/input-multiline';

import { Container, 
         Caption,
         Error,
         Spinner,
         BackForRevisionDialogVariants,
         backforrevisiondialog_variants } from 'src/components/features/qmmsg/detail/back-for-revision-dialog/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi                       } from 'src/store';



type Props = {
  variant: BackForRevisionDialogVariants;
}
export const BackForRevisionDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = backforrevisiondialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const alert = useAlert();
  
  const opened = useAppSelector(storeApi.qmmsg.detail.selectors.selectBackFROpened);
  const message = useAppSelector(storeApi.qmmsg.detail.selectors.selectBackFRMessage);
  const isBusy = useAppSelector(storeApi.qmmsg.detail.selectors.selectBackFRBusy);
  const isError = useAppSelector(storeApi.qmmsg.detail.selectors.selectBackFRError);

  const onSendHandler = () => {
    dispatch(storeApi.qmmsg.detail.async.doActionAsync('comeback'))
      .unwrap()
      .then(() => {
        alert.success('Возвращено на доработку');
      })
      .catch((rawError) => {
      });
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Вернуть на доработку
        </Caption>

        <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Причина возврата на доработку'>
          <InputMultiline
            variant = { lookAndFeel.inputVariant }
            value = { message }
            isError = { isError }
            onChange = { (value) => dispatch(storeApi.qmmsg.detail.actions.backFRMessageChanged(value)) }
            onFocus = { () => dispatch(storeApi.qmmsg.detail.actions.backFRErrorReset()) }
          />
        </LabeledContent>

        {isError &&
          <Error lookAndFeel = { lookAndFeel }>
            Действие не подтверждено
          </Error>
        }

        {!isBusy &&
          <Button
            variant = { lookAndFeel.sendButtonVariant }
            isDisabled = { message.trim().length === 0 }
            label = { isError ? 'Повторить отправку' : 'Подтвердить' }
            onTap = { onSendHandler }
          />
        }

        {isBusy && 
          <Spinner lookAndFeel = { lookAndFeel }>
            <ThreeDotsSpinnerAnimatedVector />
          </Spinner>
        }

        <Link
          variant = { lookAndFeel.cancelLinkVarint }
          label = { isBusy ? 'Подтверждение действия' : 'Отмена' }
          isCentered
          isDisabled = { isBusy }
          onTap = { () => !isBusy && dispatch(storeApi.qmmsg.detail.actions.backFRClosed()) }
        />

      </Container>
    </Dialog>
  );
};
