import * as React                 from 'react';

import { useTheme               } from 'styled-components';

import { useNavigate            } from 'react-router-dom';

import { useAlert               } from 'react-alert';

import { BlockUI                } from 'src/components/common/block-ui';
import { InputPassword          } from 'src/components/common/input/input-password';
import { Requirements           } from 'src/components/common/requirements';
import { Dialog                 } from 'src/components/common/dialog';
import { OneActionDialog        } from 'src/components/common/one-action-dialog';
import { Labeled                } from 'src/components/common/labeled';
import { InputPhone             } from 'src/components/common/input/input-phone';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { InputMultiline         } from 'src/components/common/input/input-multiline';
import { Button                 } from 'src/components/common/button';
import { VGap                   } from 'src/components/common/flex/vgap';

import { NavBar                 } from 'src/components/features/auth/common/nav-bar';
import { Organization           } from 'src/components/features/auth/reg-enter-data/organization';
import { AcceptDialog           } from 'src/components/features/auth/reg-enter-data/accept-dialog';
import { Divider                } from 'src/components/features/auth/reg-enter-data/divider';

import { RegEnterDataLayout,
         Screen,
         ScreenBody,
         ActionButtons,
         layout_light,
         layout_dark            } from 'src/containers/auth/reg-enter-data/layout';

import { Urls                   } from 'src/providers/routing';

import Util                       from 'src/services/util';
import Api                        from 'src/services/api';
import Constants                  from 'src/services/constants';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi               } from 'src/store';
import { RegisterCompleteReject } from 'src/store/auth/reg-enter-data';

import { AsyncOpStatus          } from 'src/common';



type RegEnterDataScreenElement = { layout: RegEnterDataLayout; dispatch: AppDispatch; }

const Navigation: React.FC<RegEnterDataScreenElement> = ({ layout }) => {
  const navigate = useNavigate();

  return (
    <NavBar
      variant = { layout.navbarVariant }
      label = 'Укажите свои данные'
      onBackTap = { () => navigate(-1) }
    />
  )
}

const Org: React.FC<RegEnterDataScreenElement> = ({ layout }) => {
  const enterprise = useAppSelector(storeApi.auth.common.selectors.selectEnterpriseName);

  return (
    <React.Fragment>
      <VGap size = { layout.gapBeforeOrg } />
      <Organization variant = { layout.orgVariant }>
        { enterprise }
      </Organization>
    </React.Fragment>
  )
}

const Surname: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const surname = useAppSelector(storeApi.auth.regEnterData.selectors.selectSurname);
  const surnameError = useAppSelector(storeApi.auth.regEnterData.selectors.selectSurnameError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Фамилия' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { surname }
          isError = { surnameError !== '' }
          hint = { surnameError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.surnameChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.surnameErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Name: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const name = useAppSelector(storeApi.auth.regEnterData.selectors.selectName);
  const nameError = useAppSelector(storeApi.auth.regEnterData.selectors.selectNameError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Имя' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { name }
          isError = { nameError !== '' }
          hint = { nameError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.nameChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.nameErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Patronymic: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const patronymic = useAppSelector(storeApi.auth.regEnterData.selectors.selectPatronymic);
  const patronymicError = useAppSelector(storeApi.auth.regEnterData.selectors.selectPatronymicError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Отчество' isOptional>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { patronymic }
          isError = { patronymicError !== '' }
          hint = { patronymicError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.patronymicChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.patronymicErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Post: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const post = useAppSelector(storeApi.auth.regEnterData.selectors.selectPost);
  const postError = useAppSelector(storeApi.auth.regEnterData.selectors.selectPostError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Должность' isRequired>
        <InputMultiline
          variant = { layout.inputMultilineVariant }
          value = { post }
          isError = { postError !== '' }
          hint = { postError }
          rows = { 1 }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.postChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.postErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Phone: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const phone = useAppSelector(storeApi.auth.regEnterData.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.auth.regEnterData.selectors.selectPhoneError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Телефон' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          isDisabled
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.phoneChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.phoneErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Email: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const email = useAppSelector(storeApi.auth.regEnterData.selectors.selectEmail);
  const emailError = useAppSelector(storeApi.auth.regEnterData.selectors.selectEmailError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Е-mail' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { email }
          isError = { emailError !== '' }
          hint = { emailError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.emailChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.emailErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Password: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const password = useAppSelector(storeApi.auth.regEnterData.selectors.selectPassword);
  const passwordError = useAppSelector(storeApi.auth.regEnterData.selectors.selectPasswordError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Divider variant = { layout.dividerVariant } />
      <VGap size = { layout.gapFormElements} />
      <Labeled variant = { layout.labeledVariant } label = 'Придумайте пароль' isRequired>
        <InputPassword
          variant = { layout.inputPasswordVariant }
          value = { password }
          isError = { passwordError !== '' }
          hint = { passwordError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.passwordChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.passwordErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const PasswordRepeat: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const passwordRepeat = useAppSelector(storeApi.auth.regEnterData.selectors.selectPasswordRepeat);
  const passwordRepeatError = useAppSelector(storeApi.auth.regEnterData.selectors.selectPasswordRepeatError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Повторите пароль' isRequired>
        <InputPassword
          variant = { layout.inputPasswordVariant }
          value = { passwordRepeat }
          isError = { passwordRepeatError !== '' }
          hint = { passwordRepeatError }
          onChange = { (value) => dispatch(storeApi.auth.regEnterData.actions.passwordRepeatChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regEnterData.actions.passwordRepeatErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const RequirementsList: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const password = useAppSelector(storeApi.auth.regEnterData.selectors.selectPassword);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Requirements
        variant = { layout.requirementsVariant }
        items={[
          {
            completed: password.trim().length >= Constants.PWD_MIN_LENGTH,
            label: 'Минимальная длина пароля 8 символов'
          },
          { 
            completed: Util.stringContainsDigit(password.trim()),
            label: 'Пароль должен содержать хотя бы одну цифру'
          },
          {
            completed: Util.stringContainsUpperCaseChar(password.trim()),
            label: 'Пароль должен содержать хотя бы одну букву в верхнем регистре'
          }
        ]}
      />
      <VGap size = { layout.gapButtons } />
    </React.Fragment>
  )
}

const Actions: React.FC<RegEnterDataScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const [acceptOpened, setAcceptOpened] = React.useState<boolean>(false);
  const [congratulationOpened, setCongratulationOpened] = React.useState<boolean>(false);
  const [personalAccept, setPersonalAccept] = React.useState<boolean>(true);
  const [rulesAccept, setRulesAccept] = React.useState<boolean>(true);
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);

  const formFilled = useAppSelector(storeApi.auth.regEnterData.selectors.selectFormFilled);

  const onCheckTapHandler = () => {
    dispatch(storeApi.auth.regEnterData.async.checkRegisterDataAsync())
      .unwrap()
      .then((result) => {
        setAcceptOpened(true);
      });
  }

  const onCompleteTapHandler = () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.auth.regEnterData.async.registerCompleteAsync())
      .unwrap()
      .then((result) => {
        dispatch(storeApi.auth.common.actions.accountChanged(result));
        setAcceptOpened(false);
        setCongratulationOpened(true);
        setOpStatus(AsyncOpStatus.IDLE);
      })
      .catch((rawError) => {
        const error = rawError as RegisterCompleteReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  return (
    <ActionButtons layout = { layout }>
      <Button
        variant = { layout.submitButtonVariant }
        label = 'Продолжить'
        isDisabled = { !formFilled }
        onTap = { onCheckTapHandler }
      />
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = 'Завершение регистрации'
      />
      <Dialog variant = {layout.acceptVariant} isOpened = {acceptOpened}>
        <AcceptDialog
          variant = { layout.acceptVariant }
          personalAccept = { personalAccept }
          rulesAccept = { rulesAccept }
          onPersonalAcceptChange = { (value: boolean) => setPersonalAccept(value) }
          onRulesAcceptChange = { (value: boolean) => setRulesAccept(value) }
          onCancel = { () => setAcceptOpened(false) }
          onAccept = { onCompleteTapHandler }
        />
      </Dialog>
      <Dialog variant = { layout.acceptVariant } isOpened = { congratulationOpened }>
        <OneActionDialog
          variant = { layout.congratVariant }
          caption = 'Спасибо за регистрацию'
          actionLabel = 'Продолжить работу'
          onAction = { () => Urls.PinCreate.build().navigateWithReplace() }
        />
      </Dialog>
    </ActionButtons>
  )
}

export const RegEnterDataScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      Urls.RegisterEnterData.pushState({});
    }

    bootOpStarted.current = true;

    const handler = (ev: PopStateEvent) => {
      switch (ev.state.usr?.backTo)
      {
        case 'reg':
          Urls.RegisterStart.build().navigateWithReplace({ backTo: 'login' });
          break;
        default:
      }
    }
    window.addEventListener('popstate', handler);

    return () => window.removeEventListener('popstate', handler);
  });

  console.log(window.history);

  return (
    <Screen layout={layout}>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Org layout = { layout } dispatch = { dispatch } />
        <Surname layout = { layout } dispatch = { dispatch } />
        <Name layout = { layout } dispatch = { dispatch } />
        <Patronymic layout = { layout } dispatch = { dispatch } />
        <Post layout = { layout } dispatch = { dispatch } />
        <Phone layout = { layout } dispatch = { dispatch } />
        <Email layout = { layout } dispatch = { dispatch } />
        <Password layout = { layout } dispatch = { dispatch } />
        <PasswordRepeat layout = { layout } dispatch = { dispatch } />
        <RequirementsList layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
      <Actions layout = { layout } dispatch = { dispatch } />
    </Screen>
  );
};
