import styled                     from 'styled-components';

import { IconButtonVariants     } from 'src/components/common/icon-button';
import { TypographyVariants     } from 'src/components/common/typography';
import { DateLabelVariants      } from 'src/components/common/date-label';
import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { QMMsgListVariants      } from 'src/components/features/main/inspection-list/qmmsg-list';

import { cssFlexColumn,
         cssFlexRow,
         themeColors            } from 'src/providers/theme/shared';



type InspectionLookAndFeel = {
  paddings: number[];
  headerHeight: number;

  dateGap: number;
  datePaddingTop: number;

  statusPaddingTop: number;

  backgroundColor: string;
  borderColor: string;

  expandButtonVariant: IconButtonVariants;
  dateLabelCreatedVariant: DateLabelVariants;
  dateLabelInspectionVariant: DateLabelVariants;
  threeDotButtonVariant: IconButtonVariants;
  questionButtonVariant: IconButtonVariants;

  labeledVariant: LabeledContentVariants;
  labeledTypographyVariant: TypographyVariants;

  inspectionTypeTypographyVariant: TypographyVariants;

  contentGap: number;
  contentNumberWidth: number;
  contentDividerColor: string;

  dangerGap: number;
  dangerHeight: number;
  dangerBackgroundColor: string;
  dangerBorderColor: string;
  dangerIconColor: string;
  dangerHeaderTypographyVariant: TypographyVariants;
  dangerMessageTypographyVariant: TypographyVariants;

  qmmsgListVariant: QMMsgListVariants;
}

const inspection_light: InspectionLookAndFeel = {
  paddings: [16],
  headerHeight: 70,

  dateGap: 6,
  datePaddingTop: 12,

  statusPaddingTop: 16,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expandButtonVariant: 's48bluered',
  dateLabelCreatedVariant: 'h24bluecalendar',
  dateLabelInspectionVariant: 'h24grayedit',
  threeDotButtonVariant: 's48bluered',
  questionButtonVariant: 's44gray4red',

  labeledVariant: 'gray5w400s12lh14gap4',
  labeledTypographyVariant: 'blackw400s14lh17',

  inspectionTypeTypographyVariant: 'blackw600s14lh17',

  contentGap: 16,
  contentNumberWidth: 100,
  contentDividerColor: themeColors.SecondaryGray3,

  dangerGap: 2,
  dangerHeight: 60,
  dangerBackgroundColor: themeColors.PrimaryWhite,
  dangerBorderColor: themeColors.AccentRed1,
  dangerIconColor: themeColors.AccentRed1,
  dangerHeaderTypographyVariant: 'redw600s12lh18',
  dangerMessageTypographyVariant: 'blackw400s10lh16',

  qmmsgListVariant: 'light',
}

export type InspectionVariants = 'light';

export const inspection_variants: Map<InspectionVariants, InspectionLookAndFeel> = 
  new Map<InspectionVariants, InspectionLookAndFeel>([
    ['light', inspection_light],
  ]);

export const WrapperContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.dangerGap}px;
`;

export const Wrapper = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  width: 100%;
  position: relative;
  min-height: 100px;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`;

export const Container = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;

  padding-bottom: ${(args) => args.lookAndFeel.paddings[0]}px;
`

export const ExpandButtonWrapper = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const InspectionHeaderContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
  isRightPadding: boolean;
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight + args.lookAndFeel.contentGap}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.isRightPadding ? args.lookAndFeel.paddings[0] : 0}px;
  padding-bottom: ${(args) => args.lookAndFeel.contentGap}px;
  align-items: flex-start;
  flex-shrink: 0;

  & > *:first-child {
    flex-grow: 2;
  }
`

export const InspectionDateContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.dateGap}px;
  padding-top: ${(args) => args.lookAndFeel.datePaddingTop}px;
`

export const InspectionStatusContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  padding-top: ${(args) => args.lookAndFeel.statusPaddingTop}px;
`

export const InspectionContentContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.contentGap}px;
`

export const InspectionQMMsgContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  padding: 0 ${(args) => args.lookAndFeel.paddings[0]}px;
  align-items: flex-start;
  justify-content: space-between;
`

export const InspectionTypeContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  padding: 0 ${(args) => args.lookAndFeel.paddings[0]}px;
`

export const InspectionNumberContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;
  padding: 0 ${(args) => args.lookAndFeel.paddings[0]}px;
  align-items: flex-start;
  
  & > *:first-child {
    width: ${(args) => args.lookAndFeel.contentNumberWidth}px;
    flex-shrink: 0;
  }

  & > *:last-child {
    flex-grow: 2;
  }
`

export const InspectionGroupContentContainer = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.contentGap}px;
  padding: 0 ${(args) => args.lookAndFeel.paddings[0]}px;
`

export const InspectionDivider = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  width: 100%;
  padding: 0 ${(args) => args.lookAndFeel.paddings[0]}px;
  border-bottom: 1px solid ${(args) => args.lookAndFeel.contentDividerColor};
`

export const Danger = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexRow}
  width: 100%;
  height: ${(args) => args.lookAndFeel.dangerHeight}px;
  align-items: center;

  background-color: ${(args) => args.lookAndFeel.dangerBackgroundColor};
  border: 1px solid ${(args) => args.lookAndFeel.dangerBorderColor};
`

export const DangerIcon = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexRow}
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${(args) => args.lookAndFeel.dangerHeight}px;
  height: ${(args) => args.lookAndFeel.dangerHeight}px;
  
  color: ${(args) => args.lookAndFeel.dangerIconColor};
`

export const DangerContent = styled.div<{
  lookAndFeel: InspectionLookAndFeel;
}>`
  ${cssFlexColumn}
`