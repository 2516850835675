import styledNormalize        from 'styled-normalize';
import { createGlobalStyle,
         ThemeProvider      } from 'styled-components';

import { ColorVariant,
         themeColors,
         FontVariant,
         themeFonts         } from 'src/providers/theme/shared';
import { MQ                 } from 'src/providers/theme/shared';



/**
 * GlobalStyles
 * https://styled-components.com/docs/api#createglobalstyle
 */
const GlobalStyles = createGlobalStyle`
  ${styledNormalize};

  html {
    height: 100%;
  }
  
  body {
    min-height: 100%;
    overscroll-behavior-y: contain;
  }

  * {
    box-sizing: border-box;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 0px;
    border: 0px solid transparent;
  }

  ::-ms-reveal {
    display: none;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide.fixed {
    position: fixed;
    left: 0;
    top: 0;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: url("#clip1");
  }

  .swiper-slide a {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 2px solid white;
    border-radius: 30px;
    color: white;
    font-size: 18px;
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    text-decoration: none;
    font-family: GrtskPeta Regular;
  }

  .swiper-slide .survey-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .swiper-slide .survey-close svg {
    color: gray;
    width: 24px;
    height: 24px;
  }

  .swiper-slide .survey-container {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 140px;
    display: flex;
    flex-direction: column;
  }

  .swiper-slide .survey-container .survey-caption {
    text-align: left;
    font-family: GrtskPeta Regular;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
  }

  .swiper-slide .survey-container .survey-name {
    text-align: left;
    font-family: GrtskPeta Regular;
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
  }

  .swiper-slide .stat-container {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 60px;
    background-color: #002B6D;
    border-bottom: 4px solid #CC0D14;
    display: flex;
    flex-direction: column;
  }

  .swiper-slide .stat-container .gradient {
    height: 16px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }

  .swiper-slide .stat-container .data-container {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .swiper-slide .stat-container .data-container .header {
    text-align: left;
    font-family: GrtskPeta Regular;
    font-weight: 400;
    font-size: 30px;
    line-height: 110%;
    color: #FFFFFF;
  }

  .swiper-slide .stat-container .data-container .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: GrtskPeta Regular;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
  }

  .swiper-slide .stat-container .data-container .data .item {
    display: flex;
    justify-content: space-between;
    font-family: GrtskPeta Regular;
  }

  .swiper .close-button {
    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 1;
  }

  .swiper .close-button svg {
    display: inline-block;
    color: white;
    width: 17px;
    height: 17px;
  }

  .swiper .close-button svg path {
    stroke-width: 1;
  }

  .swiper .progress-bar {
    position: absolute;
    height: 3px;
    top: 20px;
    right: 20px;
    left: 20px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .swiper .progress-bar .progress-line {
    --progress: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: var(--progress);
    background-color: white;
  }

  .react-ripples {
    width: 100%;
  }

  .MuiModal-backdrop {
    background-color: rgba(0,47,108,0.5)  !important;
  }

  ${MQ.greaterThan('laptop')`
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    ::-webkit-scrollbar-track {
      background: ${themeColors.SecondaryGray2};
    }

    ::-webkit-scrollbar-thumb {
      background: ${themeColors.SecondaryGray4};
      opacity: 50%;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${themeColors.AccentRed1};
    } 
  `};
  
  .swipe-to-delete {
    height: auto !important;
    max-height: none !important;
  }

  /* rstdi = react-swipe-to-delete-ios */

  .rstdi {
    --rstdiHeight: 30px;
    --rstdiTransitionDuration: 250ms;
    --rstdiTranslate: 0px;
    --rstdiIsRtl: 0;
    --rstdiDeleteColor: rgba(252, 58, 48, 1);
    --rstdiDeleteWidth: 75px;
    --rstdiButtonMarginRight: 0px;
    --rstdiButtonMarginLeft: 0px;

    width: auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    height: var(--rstdiHeight);
    max-height: calc(var(--rstdiHeight) + 10px);
  }

  .rstdi *,
  .rstdi *:before,
  .rstdi *:after {
    box-sizing: border-box;
  }

  .rstdi.deleting {
    transition: all var(--rstdiTransitionDuration) ease-out;
    max-height: 0;
  }

  .rstdi .content {
    height: 100%;
    width: auto;
    position: relative;
    transform: translateX(var(--rstdiTranslate));
  }

  .rstdi .content.transition {
    transition: transform var(--rstdiTransitionDuration) ease-out;
  }

  .rstdi .content.deleting {
    height: 100%;
    width: auto;
    position: relative;
    transform: scale(0) translateX(var(--rstdiTranslate));
  }

  .rstdi .delete {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background: var(--rstdiDeleteColor);
    font-weight: 400;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rstdi .delete button {
    width: var(--rstdiDeleteWidth);
    transition: margin transform var(--rstdiTransitionDuration) ease-in-out;
    margin-left: var(--rstdiButtonMarginLeft);
    margin-right: var(--rstdiButtonMarginRight);
    text-align: center;
    height: 100%;
    background: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
`;



/**
 * Определяем тему, что позволит нам удобно обращаться к ее свойствам
 * https://styled-components.com/docs/api#create-a-declarations-file
 */
 interface AppTheme {
  color: Record<ColorVariant, string>;
  font: Record<FontVariant, string>;
  rippleDuration: number;
  colorScheme: 'light' | 'dark';
}

declare module 'styled-components' {
  export interface DefaultTheme extends AppTheme {}
}



/**
 * Собственно сама тема, которую будем использовать в качестве значения провайдера
 */
const appTheme: AppTheme = {
  color: themeColors,
  font: themeFonts,
  rippleDuration: 200,
  colorScheme: 'light',
};



/**
 * Создаем компонент ThemeProvider, который будет оборачивать соответствующий
 * компонент из styled-components и инкапсулировать в себе тему и глобальные стили
 * https://styled-components.com/docs/advanced#theming
 */
type Props = {
  children: React.ReactNode;
}
export const ThemeAppProvider: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}