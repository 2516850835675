import styled                  from 'styled-components';

import { IconButtonVariants  } from 'src/components/common/icon-button';
import { TypographyVariants  } from 'src/components/common/typography';

import { StepElementVariants } from 'src/components/features/qmmsg/detail/step-element';

import { cssFlexColumn,
         themeColors         } from 'src/providers/theme/shared';



type StepFlowLookAndFeel = {
  paddings: number[];
  gap: number;

  backgroundColor: string;
  borderColor: string;

  expandButtonVariant: IconButtonVariants;
  expandButtonTop: number;
  expandButtonRight: number;

  headerTypographyVariant: TypographyVariants;
  stepElementVariant: StepElementVariants;
}

const stepflow_light: StepFlowLookAndFeel = {
  paddings: [16],
  gap: 24,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expandButtonVariant: 's48bluered',
  expandButtonTop: 2,
  expandButtonRight: 16,

  headerTypographyVariant: 'blackw600s15lh20',
  stepElementVariant: 'light',
}

export type StepFlowVariants = 'light';

export const stepflow_variants: Map<StepFlowVariants, StepFlowLookAndFeel> = 
  new Map<StepFlowVariants, StepFlowLookAndFeel>([
    ['light', stepflow_light],
  ]);

export const Wrapper = styled.div<{
  lookAndFeel: StepFlowLookAndFeel;
}>`
  width: 100%;
  position: relative;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor} !important;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
`;

export const Container = styled.div<{
  lookAndFeel: StepFlowLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const Steps = styled.div<{
  lookAndFeel: StepFlowLookAndFeel;
}>`
  ${cssFlexColumn};
`

export const ExpandButtonWrapper = styled.div<{
  lookAndFeel: StepFlowLookAndFeel;
}>`
  position: absolute;
  top: ${(args) => args.lookAndFeel.expandButtonTop}px;
  right: ${(args) => args.lookAndFeel.expandButtonRight}px;
`
