import React from 'react';



type Props  =  {
  className?: string;
}
export const EmptyCaseLoupeVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '88' height = '88' viewBox = '0 0 88 88' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M29 28L50 49M29 49L36.6364 41.3636M50 28L39.5 38.5' />
      <path stroke = 'currentColor' strokeWidth = '2' strokeLinecap = 'square' d = 'M61.1395 61.1395L82 82M61.4169 60.8588C67.0988 55.0372 70.6 47.0775 70.6 38.3C70.6 20.4612 56.1388 6 38.3 6C20.4612 6 6 20.4612 6 38.3C6 56.1388 20.4612 70.6 38.3 70.6C40.9177 70.6 43.4627 70.2886 45.9 69.7008' />
    </svg>
  );
};
