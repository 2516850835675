import * as React                        from 'react';

import { Dialog                        } from 'src/components/common/dialog';
import { OneActionDialog               } from 'src/components/common/one-action-dialog';

import { FeedbackThanksDialogVariants,
         feedbackthanksdialog_variants } from 'src/components/features/qmmsg/detail/feedback-thanks-dialog/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi                      } from 'src/store';



type Props = {
  variant: FeedbackThanksDialogVariants;
}
export const FeedbackThanksDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = feedbackthanksdialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  
  const opened = useAppSelector(storeApi.qmmsg.detail.selectors.selectFeedbackThanksOpened);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <OneActionDialog
        variant = { lookAndFeel.oneActionVarint }
        caption = 'Спасибо за обратную связь'
        message = 'Информация направлена ответственному для проработки'
        actionLabel = 'Ok'
        onAction = { () => dispatch(storeApi.qmmsg.detail.actions.feedbackThanksOpened(false)) }
      />
    </Dialog>
  );
};
