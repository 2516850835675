import * as React                         from 'react';

import { Typography                     } from 'src/components/common/typography';

import { ComplaintDefectItem            } from 'src/components/features/inspection/view/complaint-defect-item';

import { Container, 
         List,
         ComplaintListFragmentVariants,
         complaintlistfragment_variants } from 'src/components/features/inspection/view/complaintlist-fragment/ui';

import { useAppSelector, 
         storeApi                       } from 'src/store';



type Props = {
  variant: ComplaintListFragmentVariants;
}
export const ComplaintListFragment: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = complaintlistfragment_variants.get(variant)!;

  const defects = useAppSelector(storeApi.inspection.view.selectors.selectDefects);

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.headerTypographyVariant }>Информация о дефекте</Typography>
      <List lookAndFeel = { lookAndFeel }>
        {defects.map((defect, index) => (
          <ComplaintDefectItem
            variant = { lookAndFeel.defectItemVariant }
            defect = { defect }
            isLast = { index === defects.length - 1 }
            key = { defect.guid }
          />
        ))}
      </List>
    </Container>
  );
};
