import * as React          from 'react';

import { useTheme        } from 'styled-components';

import { useAlert        } from 'react-alert';

import { BlockUI         } from 'src/components/common/block-ui';
import { Grow            } from 'src/components/common/flex/grow';
import { Dialog          } from 'src/components/common/dialog';
import { OneActionDialog } from 'src/components/common/one-action-dialog';
import { Link            } from 'src/components/common/link';

import { Pin             } from 'src/components/features/auth/common/pin';
import { Welcome         } from 'src/components/features/auth/pin-enter/welcome';

import { PINEnterLayout,
         Screen,
         layout_light,
         layout_dark     } from 'src/containers/auth/pin-enter/layout';

import { Urls            } from 'src/providers/routing';

import Preferences         from 'src/services/preferences';
import Api, 
       { AppStatusCodes,
         HTTPStatusCodes } from 'src/services/api';
import { AccountRole     } from 'src/services/api/types/auth';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch, 
         storeApi        } from 'src/store';
import { CheckReject     } from 'src/store/auth/pin-enter';

import { AsyncOpStatus   } from 'src/common';



type PinEnterScreenElement = { layout: PINEnterLayout; dispatch: AppDispatch; }

const Caption: React.FC<PinEnterScreenElement> = ({ layout }) => {
  return (
    <Welcome variant = { layout.welcomeVariant }>Здравствуйте,<br/>{ Preferences.storedAccountOwner }</Welcome>
  )
}

const PinInput: React.FC<PinEnterScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const [mismatched, setMismatched] = React.useState<boolean>(false);
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const attempt = useAppSelector(storeApi.auth.pinEnter.selectors.selectAttempt);

  const onPinCompleteHandler = async (pin: string) => {
    const account = await Preferences.extractAndDecryptAccount(pin);

    if (account === null)
    {
      dispatch(storeApi.auth.pinEnter.actions.codeChanged(pin));

      if (attempt + 1 === 3)
      {
        Preferences.removeStoredAccount();
      }

      setMismatched(true) 
    }
    else
    {
      Api.setupJwt(account.jwtRefresh, account.jwtAccess);
      setOpStatus(AsyncOpStatus.BUSY);
      dispatch(storeApi.auth.pinEnter.async.checkAsync())
        .unwrap() 
        .then((result) => {
          setOpStatus(AsyncOpStatus.IDLE);
          dispatch(storeApi.auth.common.actions.accountChanged({
            ...result,
            role: localStorage.getItem('__testingRole__') === null ? result.role : localStorage.getItem('__testingRole__') as AccountRole,
            expRefresh: account.expRefresh,
            jwtAccess: account.jwtAccess,
            jwtRefresh: account.jwtRefresh,
          }));
          Preferences.setAppAdvPriorityShow();
          Urls.MainInspections.build().navigateWithReplace();
        })
        .catch((rawError) => {
          setOpStatus(AsyncOpStatus.IDLE);
          const error = rawError as CheckReject;

          if (error.statusCode === AppStatusCodes.NetworkError)
          {
            dispatch(storeApi.auth.common.actions.accountChanged({ ...account }));
            Preferences.setAppAdvPriorityShow();
            Urls.MainInspections.build().navigateWithReplace();
          }
          else
          {
            if (!Api.isCommonAuthError(error.statusCode ?? 0))
            {
              alert.error(error.message);
            }
            else
            {
              if (error.statusCode === HTTPStatusCodes.Unauthorized)
              {
                alert.error(error.message);
                Urls.Login.build().navigate();
              }
            }
          }
        });
    }
  }

  const onMismatchDialogClose = () => {
    setMismatched(false);

    if (attempt === 3)
    {
      Urls.Login.build('start').navigateWithReplace();
    }
  }

  return (
    <React.Fragment>
      <Grow size = { 2 } />
      <Pin
        variant = { layout.pinVariant }
        label = 'Введите PIN-код'
        onPinComplete = { onPinCompleteHandler }
      />
      <Grow size = { 2 } />
      <BlockUI
        variant={layout.blockUIVariant}
        isOpened={opStatus === AsyncOpStatus.BUSY}
        message={'Получение аккаунта пользователя'}
      />
      <Dialog variant = { layout.dialogVariant } isOpened = { mismatched }>
        <OneActionDialog
          variant = { layout.pinCheckDialogVariant }
          caption = 'Неверный PIN-код'
          actionLabel = { attempt < 3 ? 'Попробовать снова' : 'Войти по логину/паролю' }
          onAction = { onMismatchDialogClose }
        />
      </Dialog>
    </React.Fragment>
  )
}

export const PINEnterScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  console.log(window.history);

  return (
    <Screen layout={layout}>
      <Caption layout = { layout } dispatch = { dispatch } />
      <PinInput layout = { layout } dispatch = { dispatch } />
      <Link 
        variant = { layout.linkVariant }
        label = 'Войти по логину/паролю'
        isCentered
        onTap = { () => Urls.Login.build('start').navigateWithReplace() }
      />
    </Screen>
  );
};
