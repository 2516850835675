import React from 'react';

import { RouteObject          } from 'react-router-dom';

import { SplashScreen         } from 'src/containers/auth/splash';
import { PINEnterScreen       } from 'src/containers/auth/pin-enter';
import { PINCreateScreen      } from 'src/containers/auth/pin-create';
import { PhoneEnterScreen     } from 'src/containers/auth/phone-enter';
import { RegStartScreen       } from 'src/containers/auth/reg-start';
import { RegRequestCodeScreen } from 'src/containers/auth/reg-request-code';
import { RegEnterDataScreen   } from 'src/containers/auth/reg-enter-data';
import { SMSCodeScreen        } from 'src/containers/auth/sms-code';
import { PwdResetScreen       } from 'src/containers/auth/pwd-reset';

import { BaseUrl              } from 'src/providers/routing/base-url';
import { WithInitNavigate     } from 'src/providers/routing/with-init-navigate';

import * as types               from 'src/services/api/types';



class SplashUrl extends BaseUrl {
  build(): SplashUrl {
    this._url = '/';
    return this;
  }
}

class PinCreateUrl extends BaseUrl {
  build(): PinCreateUrl {
    this._url = '/auth/pin-create';
    return this;
  }
}

class PinEnterUrl extends BaseUrl {
  build(): PinEnterUrl {
    this._url = '/auth/pin-enter';
    return this;
  }
}

class LoginUrl extends BaseUrl {
  build(stage: 'start' | 'continue' = 'start'): LoginUrl {
    this._url = `/auth/login/${stage}`;
    return this;
  }
}

class RegisterStartUrl extends BaseUrl {
  build(): RegisterStartUrl {
    this._url = '/auth/reg-start';
    return this;
  }
}

class RegisterRequestCodeUrl extends BaseUrl {
  build(): RegisterRequestCodeUrl {
    this._url = '/auth/reg-request-code';
    return this;
  }
}

class RegisterEnterDataUrl extends BaseUrl {
  build(): RegisterEnterDataUrl {
    this._url = '/auth/reg-enter-data';
    return this;
  }
}

class SmsCodeUrl extends BaseUrl {
  build(type: types.auth.TypeTwoFactor): SmsCodeUrl {
    this._url = `/auth/2fa/${type}`;
    return this;
  }
}

class ResetPasswordUrl extends BaseUrl {
  build(stage: 'start' | 'continue' = 'start'): ResetPasswordUrl {
    this._url = `/auth/reset/${stage}`;
    return this;
  }
}

export const AuthUrls = {
  Splash: new SplashUrl('/'),
  PinCreate: new PinCreateUrl('/auth/pin-create'),
  PinEnter: new PinEnterUrl('/auth/pin-enter'),
  Login: new LoginUrl('/auth/login/:stage'),
  RegisterStart: new RegisterStartUrl('/auth/reg-start'),
  RegisterRequestCode: new RegisterRequestCodeUrl('/auth/reg-request-code'),
  RegisterEnterData: new RegisterEnterDataUrl('/auth/reg-enter-data'),
  SmsCode: new SmsCodeUrl('/auth/2fa/:type'),
  ResetPassword: new ResetPasswordUrl('/auth/reset/:stage'),
}

export const AuthRoutes: RouteObject[] = [
  {
    path: AuthUrls.Splash.pattern,
    element: <WithInitNavigate><SplashScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.Login.pattern,
    element: <WithInitNavigate><PhoneEnterScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.PinCreate.pattern,
    element: <WithInitNavigate><PINCreateScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.PinEnter.pattern,
    element: <WithInitNavigate><PINEnterScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.RegisterStart.pattern,
    element: <WithInitNavigate><RegStartScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.RegisterRequestCode.pattern,
    element: <WithInitNavigate><RegRequestCodeScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.RegisterEnterData.pattern,
    element: <WithInitNavigate><RegEnterDataScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.SmsCode.pattern,
    element: <WithInitNavigate><SMSCodeScreen /></WithInitNavigate>
  },
  {
    path: AuthUrls.ResetPassword.pattern,
    element: <WithInitNavigate><PwdResetScreen /></WithInitNavigate>
  },
];
