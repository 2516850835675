import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region Common

export type AdminElement = {
  /** ID пользователя */
  id: number;
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество */
  middleName: string;
  /** Должность */
  post: string;
  /** Номер телефона */
  phone: number;
  /** email */
  email: string;
  /** Email подтвержден */
  isEmailConfirmed: boolean;
}

// #endregion


// #region GET admin/list

export type GetAdminListIn = void;

export type GetAdminListOut = Array<AdminElement>;

export const prepareGetAdminList
  : PrepareCallHandler<GetAdminListIn, GetAdminListOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/admin/list`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST admin/block_user/{user_id}

export type PostAdminBlockUserByIdIn = {
  /** ID пользователя */
  userId: number;
}

export const preparePostAdminBlockUserById
  : PrepareCallHandler<PostAdminBlockUserByIdIn, void> = (arg: PostAdminBlockUserByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/admin/block_user/${arg.userId}`,
      method: 'POST',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
