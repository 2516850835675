import * as React                 from 'react';

import { ArrowDownVector        } from 'src/components/vector/arrow-down';
import { ArrowUpVector          } from 'src/components/vector/arrow-up';
import { DefectOKVector         } from 'src/components/vector/defect-ok';
import { DefectErrorVector      } from 'src/components/vector/defect-error';
import { EditVector             } from 'src/components/vector/edit';

import { Typography             } from 'src/components/common/typography';
import { Switch                 } from 'src/components/common/switch';
import { IconButton             } from 'src/components/common/icon-button';
import { Labeled                } from 'src/components/common/labeled';

import { PieceList              } from 'src/components/features/inspection/view/piece-list';
import { StaticValue            } from 'src/components/features/inspection/view/static-value';

import { AttachmentPDF          } from 'src/components/features/inspection/edit/attachment-pdf';

import { DefectItemLookAndFeel, 
         Container,
         Header,
         HeaderStatus,
         HeaderLabel,
         HeaderExpandAction,
         DefectDateTime,
         DefectBody,
         FieldWithSwitch,
         PhotoList,
         Hr,
         PieceListContainer,
         DefectItemVariants,
         defectitem_variants    } from 'src/components/features/inspection/view/defect-item/ui';

import Util                       from 'src/services/util';
import * as types                 from 'src/services/api/types';

import { useAppSelector, 
         storeApi,    
         useAppDispatch         } from 'src/store';




type DefectItemSubComponentProps = { 
  lookAndFeel: DefectItemLookAndFeel; 
  defect: types.inspection.Defect;
}

const DefectItemHeader: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const dispatch = useAppDispatch();

  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);
  const countDefects = useAppSelector(storeApi.inspection.view.selectors.selectDefectAllCount);
  const expandedGuid = useAppSelector(storeApi.inspection.view.selectors.selectDefectExpandedGuid);
  
  const isExpanded = expandedGuid === defect.guid;
  const isOk = React.useMemo(() => {
    const defectPass= defect.defectId !== null;
    const piecesPass = (defect.pieces || []).length > 0;
    const violationPass = defect.isViolationOfRequirements === false || defect.violatedRequirements !== null;
    const rejectPass = defect.isPossibilityUsingRejected === false || defect.wayToUseRegectedId !== null;

      return defectPass && piecesPass && violationPass && rejectPass;
  }, [defect])
  
  const title = defect.defectTitle;

  const onExpandCollapseHandler = () => {
    dispatch(storeApi.inspection.view.actions.defectExpandedGuidChanged(isExpanded ? '' : defect.guid));
  }

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Header lookAndFeel = { lookAndFeel }>
      {!isExpanded && (
        <HeaderStatus  lookAndFeel = { lookAndFeel } isOk = { isOk }>
          { isOk ? <DefectOKVector /> : <DefectErrorVector /> }
        </HeaderStatus>
      )}
      {isExpanded && countDefects > 1 && (
        <HeaderStatus  lookAndFeel = { lookAndFeel } isOk = { isOk } />
      )}
      <HeaderLabel lookAndFeel = { lookAndFeel }>
        { title ?? 'Информация о дефекте' }
      </HeaderLabel>
      {countDefects > 1 && (
        <React.Fragment>
          <HeaderExpandAction lookAndFeel = { lookAndFeel }>
            <IconButton
              variant = { lookAndFeel.actionButtonVariant }
              isTapAllowed
              onTap = { onExpandCollapseHandler }
            >
              { isExpanded ? <ArrowUpVector /> : <ArrowDownVector /> }
            </IconButton>
          </HeaderExpandAction>
        </React.Fragment>
      )}
    </Header>
  )
}

const DefectItemDateTime: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const dateTime = defect.timestampDefect;

  return (
    <DefectDateTime lookAndFeel = { lookAndFeel }>
      <EditVector />
      { dateTime === null ? '' : `${new Date(dateTime).toLocaleDateString()} ${Util.timeHHMM(new Date(dateTime))}` }
    </DefectDateTime>
  )
}

const DefectItemDefect: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Вид дефекта'>
        <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.defectTitle ?? '' }</StaticValue>
      </Labeled>
    </React.Fragment>
  )
}

const DefectExternalNumber: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);
  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  if (isEntryAccounting || account.role !== types.auth.AccountRole.Stpk)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Внешний номер обращения'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.numExternal ?? '' }</StaticValue>
    </Labeled>
  )
}

const DefectItemPlaceOfFixation: React.FC<DefectItemSubComponentProps> = ({ 
  lookAndFeel,
  defect
}) => {
  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Место фиксации'>
        <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.placeOfFixationTitle ?? '' }</StaticValue>
      </Labeled>
    </React.Fragment>
  )
}

const DefectItemProduct: React.FC<DefectItemSubComponentProps> = ({ 
  lookAndFeel,
  defect
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <Labeled variant = { lookAndFeel.labeledVariant } label = 'Укажите название изделия'>
        <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.product ?? '' }</StaticValue>
      </Labeled>
    </React.Fragment>
  )
}

const DefectItemNote: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Labeled variant = { lookAndFeel.labeledVariant } label = 'Детальное описание дефекта (прочие комментарии)'>
      <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.note ?? '' }</StaticValue>
    </Labeled>
  )
}

const DefectItemViolatedRequirements: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Нарушение требований стандарта и заказа</Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { defect.isViolationOfRequirements }
          onChange = { (_) => { } }
        />
      </FieldWithSwitch>
      {defect.isViolationOfRequirements && (
        <Labeled variant = { lookAndFeel.labeledVariant } label = 'Укажите какое требование нарушено'>
          <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.violatedRequirements ?? '' }</StaticValue>
        </Labeled>
      )}
    </React.Fragment>
  )
}

const DefectItemWayToUseRegected: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Возможность использования забракованного проката</Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { defect.isPossibilityUsingRejected }
          onChange = { (_) => { } }
        />
      </FieldWithSwitch>
      {defect.isPossibilityUsingRejected && (
        <React.Fragment>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Способ использ. забракованного проката'>
            <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.wayToUseRegectedTitle ?? '' }</StaticValue>
          </Labeled>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const JoinInspection: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
  defect,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <React.Fragment>
      <FieldWithSwitch lookAndFeel = { lookAndFeel }>
        <Typography variant = { lookAndFeel.switchFieldTypography }>Совместная инспекция</Typography>
        <Switch
          variant = { lookAndFeel.switchVariant }
          value = { Util.nullOrEmptyStringTo(defect.actJoinInsp, 'X') !== 'X' }
          onChange = { (_) => { } }
        />
      </FieldWithSwitch>
      {Util.nullOrEmptyStringTo(defect.actJoinInsp, 'X') !== 'X' && (
        <React.Fragment>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Номер акта СИ'>
            <StaticValue variant = { lookAndFeel.staticValueVariant }>{ defect.actJoinInsp ?? '' }</StaticValue>
          </Labeled>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Дата СИ'>
            <StaticValue variant = { lookAndFeel.staticValueVariant }>{ new Date(defect.timestampJoinInsp!).toLocaleDateString() }</StaticValue>
          </Labeled>
          <PhotoList lookAndFeel = { lookAndFeel }>
            <AttachmentPDF
              variant = { lookAndFeel.attachmentPDFVariants }
              isAllowDelete = { false }
              pdf = {
                defect.files.find(
                  (file) => file.type === types.inspection.DefectFileType.ACT_JOIN_INSP && file.mode !== types.inspection.DefectFileMode.DEL
                )!
              }
              source = 'inspection'
            />
          </PhotoList>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const Separator: React.FC<DefectItemSubComponentProps> = ({
  lookAndFeel,
}) => {
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  if (isEntryAccounting)
  {
    return null;
  }

  return (
    <Hr lookAndFeel = { lookAndFeel } />
  )
}


type Props = {
  variant: DefectItemVariants;
  defect: types.inspection.Defect;
}
export const DefectItem: React.FC<Props> = ({
  variant,
  defect,
}) => {
  const lookAndFeel = defectitem_variants.get(variant)!;

  const expandedGuid = useAppSelector(storeApi.inspection.view.selectors.selectDefectExpandedGuid);
  const isEntryAccounting = useAppSelector(storeApi.inspection.view.selectors.selectIsEntryAccounting);

  const isExpanded = expandedGuid === defect.guid || isEntryAccounting;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <DefectItemHeader lookAndFeel = { lookAndFeel } defect = { defect } />
      {isExpanded && (
        <DefectBody lookAndFeel = { lookAndFeel }>
          <DefectItemDateTime lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemDefect lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectExternalNumber lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemPlaceOfFixation lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemProduct lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemNote lookAndFeel = { lookAndFeel } defect = { defect } />
          <Separator lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemViolatedRequirements lookAndFeel = { lookAndFeel } defect = { defect } />
          <Separator lookAndFeel = { lookAndFeel } defect = { defect } />
          <DefectItemWayToUseRegected lookAndFeel = { lookAndFeel } defect = { defect } />
          <Separator lookAndFeel = { lookAndFeel } defect = { defect } />
          <JoinInspection lookAndFeel = { lookAndFeel } defect = { defect } />
          <Separator lookAndFeel = { lookAndFeel } defect = { defect } />
          <PieceListContainer lookAndFeel = { lookAndFeel }>
            <PieceList
              variant='light'
              pieces = { defect.pieces || [] }
            />
          </PieceListContainer>
        </DefectBody>
      )}
    </Container>
  );
};
