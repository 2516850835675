import { createSlice,
         createAsyncThunk, 
         createSelector,    
         PayloadAction        } from '@reduxjs/toolkit';

import Api,
       { ApiError             } from 'src/services/api';
import * as types               from 'src/services/api/types';
import Util                     from 'src/services/util';

import { RootState            } from 'src/store';
import { authCommonSelectors,
         authCommonActions    } from 'src/store/auth/common';

import { isPhone,
         isEmail,
         isPost,
         isName,
         isPatronymic,
         isSurname            } from 'src/validators';



const SLICE_NAME = 'main:personal';

export interface MainPersonalState {
  surname: string;
  surnameError: string;
  name: string;
  nameError: string;
  patronymic: string;
  patronymicError: string;
  post: string;
  postError: string;
  phone: string;
  phoneError: string;
  email: string;
  emailError: string;

  pushNewInspection: boolean;
  pushChangeStatusInspection: boolean;
  pushErrorInspection: boolean;
  pushChangeStep8D: boolean;
  pushReadMessage: boolean;
  pushChangeStepQMMSG: boolean;

  phoneConfirmed: boolean;
  authKey: string;
}

const initialState: MainPersonalState = {
  surname: '',
  surnameError: '',
  name: '',
  nameError: '',
  patronymic: '',
  patronymicError: '',
  post: '',
  postError: '',
  phone: '',
  phoneError: '',
  email: '',
  emailError: '',

  pushNewInspection: false,
  pushChangeStatusInspection: false,
  pushErrorInspection: false,
  pushChangeStep8D: false,
  pushReadMessage: false,
  pushChangeStepQMMSG: false,

  phoneConfirmed: true,
  authKey: '',
}

const { NEW_INSPECTION, 
        CHANGE_STATUS_INSPECTION,
        ERROR_INSPECTION,
        CHANGE_STEP_8D,
        READ_MESSAGE,
        CHANGE_STEP_QMMSG         } = types.notification.NotificationCode;

// #region copyStateFromAccountAsync
type CopyStateFromAccountArgs = void;
type CopyStateFromAccountResolve = types.auth.AccountWithTokens;
export type CopyStateFromAccountReject = void;
export const copyStateFromAccountAsync = createAsyncThunk<
  CopyStateFromAccountResolve,
  CopyStateFromAccountArgs,
  {
    state: RootState,
    rejectValue: CopyStateFromAccountReject
  }
>(
  `${SLICE_NAME}/copyStateFromAccountAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    let account = authCommonSelectors.selectAccount(state)!;

    try
    {
      const newAccount = await Api.getAuthCheck();
      account = { ...account, ...newAccount };
      thunkAPI.dispatch(authCommonActions.accountChanged(account));
    }
    catch (error) { }

    return account;
  }
);
// #endregion

// #region sendEmailAsync
type SendEmailArgs = void;
type SendEmailResolve = void;
export type SendEmailReject = ApiError;
export const sendEmailAsync = createAsyncThunk<
  SendEmailResolve,
  SendEmailArgs,
  {
    state: RootState,
    rejectValue: SendEmailReject,
  }
>(
  `${SLICE_NAME}/sendEmailAsync`,
  async (args, thunkAPI) => {
    try
    {
      await Api.postProfileConfirmEmail();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region sendSMSCodeAsync
type SendSMSCodeArgs = number;
type SendSMSCodeResolve = string;
export type SendSMSCodeReject = ApiError;
export const sendSMSCodeAsync = createAsyncThunk<
  SendSMSCodeResolve,
  SendSMSCodeArgs,
  {
    state: RootState,
    rejectValue: SendSMSCodeReject,
  }
>(
  `${SLICE_NAME}/sendSMSCodeAsync`,
  async (args, thunkAPI) => {
    try
    {
      console.log('sendSMSCodeAsync');
      const { authKey } = await Api.postProfileKeyChangePhone({ phone: args });
      await Api.postAuth2fa({ type: 'changePhone', authKey });
      return authKey;
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region checkSMSCodeAsync
type CheckSMSCodeArgs = number;
type CheckSMSCodeResolve = string;
export type CheckSMSCodeReject = ApiError;
export const checkSMSCodeAsync = createAsyncThunk<
  CheckSMSCodeResolve,
  CheckSMSCodeArgs,
  {
    state: RootState,
    rejectValue: CheckSMSCodeReject,
  }
>(
  `${SLICE_NAME}/checkSMSCodeAsync`,
  async (args, thunkAPI) => {
    try
    {
      const authKeyPrev = selectAuthKey(thunkAPI.getState());
      const { authKey } = await Api.postAuth2faControl({ type: 'changePhone', authKey: authKeyPrev, code2fa: args });
      return authKey;
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region savePersonalAsync
type SavePersonalArgs = void;
type SavePersonalResolve = void;
export type SavePersonalReject = {
  reason: 'validation' | 'api';
  validationErrors?: {
    surnameError?: string;
    nameError?: string;
    patronymicError?: string;
    postError?: string;
    phoneError?: string;
    emailError?: string;
  };
  apiError?: ApiError;
}
export const savePersonalAsync = createAsyncThunk<
  SavePersonalResolve,
  SavePersonalArgs,
  {
    state: RootState,
    rejectValue: SavePersonalReject,
  }
>(
  `${SLICE_NAME}/savePersonalAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const account = authCommonSelectors.selectAccount(state)!;
    const surname = selectSurname(state);
    const name = selectName(state);
    const patronymic = selectPatronymic(state);
    const post = selectPost(state);
    const phone = selectPhone(state);
    const email = selectEmail(state);
    const authKey = selectAuthKey(state);
    const pushNewInspection = selectPushNewInspection(state);
    const pushChangeStatusInspection = selectPushChangeStatusInspection(state);
    const pushErrorInspection = selectPushErrorInspection(state);
    const pushChangeStep8D = selectPushChangeStep8D(state);
    const pushReadMessage = selectPushReadMessage(state);
    const pushChangeStepQMMSG = selectPushChangeStepQMMSG(state);

    const surnameError = isSurname(surname).error ?? '';
    const nameError = isName(name).error ?? '';
    const patronymicError = isPatronymic(patronymic, true).error ?? '';
    const postError = isPost(post).error ?? '';
    const phoneError = isPhone(phone).error ?? '';
    const emailError = isEmail(email).error ?? '';
    const hasErrors = [surnameError, nameError, patronymicError, postError, phoneError, emailError]
      .some((value) => value.length > 0);

    if (hasErrors)
    {
      return thunkAPI.rejectWithValue({
        reason: 'validation',
        validationErrors: {
          ...(surnameError.length > 0 ? { surnameError } : {}),
          ...(nameError.length > 0 ? { nameError } : {}),
          ...(patronymicError.length > 0 ? { patronymicError } : {}),
          ...(postError.length > 0 ? { postError } : {}),
          ...(phoneError.length > 0 ? { phoneError } : {}),
          ...(emailError.length > 0 ? { emailError } : {}),
        }
      });
    }

    try
    {
      await Api.postProfileChangeProfile({
        authKey: `${account.phone}` === Util.selectDigitsFromString(phone) ? null : authKey,
        lastName: surname.trim(),
        firstName: name.trim(),
        middleName: patronymic.trim(),
        post: post.trim(),
        phone: Util.selectDigitsFromString(phone),
        email: email.trim(),
        pushes: [
          pushNewInspection ? NEW_INSPECTION : '',
          pushChangeStatusInspection ? CHANGE_STATUS_INSPECTION : '',
          pushErrorInspection ? ERROR_INSPECTION : '',
          pushChangeStep8D ? CHANGE_STEP_8D : '',
          pushReadMessage ? READ_MESSAGE : '',
          pushChangeStepQMMSG ? CHANGE_STEP_QMMSG : ''
        ].filter((item) => item !== '')
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue({
        reason: 'api',
        apiError: (error as ApiError),
      })
    }
  }
);
// #endregion

// #region checkAsync
type CheckArgs = void;
type CheckResolve = types.auth.Account;
export type CheckReject = ApiError;
export const checkAsync = createAsyncThunk<
  CheckResolve,
  CheckArgs,
  {
    state: RootState,
    rejectValue: CheckReject,
  }
>(
  `${SLICE_NAME}/checkAsync`,
  async (args, thunkAPI) => {
    try
    {
      return  await Api.getAuthCheck();
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type SurnameChangedAction = PayloadAction<string>;
type NameChangedAction = PayloadAction<string>;
type PatronymicChangedAction = PayloadAction<string>;
type PostChangedAction = PayloadAction<string>;
type PhoneChangedAction = PayloadAction<string>;
type EmailChangedAction = PayloadAction<string>;
type PushNewInspectionChangedAction = PayloadAction<boolean>;
type PushChangeStatusInspectionChangedAction = PayloadAction<boolean>;
type PushErrorInspectionChangedAction = PayloadAction<boolean>;
type PushChangeStep8DChangedAction = PayloadAction<boolean>;
type PushReadMessageChangedAction = PayloadAction<boolean>;
type PushChangeStepQMMSGChangedAction = PayloadAction<boolean>;
type PhoneConfirmedChangedAction = PayloadAction<boolean>;

export const mainPersonalSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    pushNewInspectionChanged: (state, action: PushNewInspectionChangedAction) => {
      state.pushNewInspection = action.payload;
    },
    pushChangeStatusInspectionChanged: (state, action: PushChangeStatusInspectionChangedAction) => {
      state.pushChangeStatusInspection = action.payload;
    },
    pushErrorInspectionChanged: (state, action: PushErrorInspectionChangedAction) => {
      state.pushErrorInspection = action.payload;
    },
    pushChangeStep8DChanged: (state, action: PushChangeStep8DChangedAction) => {
      state.pushChangeStep8D = action.payload;
    },
    pushReadMessageChanged: (state, action: PushReadMessageChangedAction) => {
      state.pushReadMessage = action.payload;
    },
    pushChangeStepQMMSGChanged: (state, action: PushChangeStepQMMSGChangedAction) => {
      state.pushChangeStepQMMSG = action.payload;
    },
    surnameChanged: (state, action: SurnameChangedAction) => {
      state.surname = action.payload;
    },
    surnameErrorFixed: (state) => {
      state.surnameError = '';
    },
    nameChanged: (state, action: NameChangedAction) => {
      state.name = action.payload;
    },
    nameErrorFixed: (state) => {
      state.nameError = '';
    },
    patronymicChanged: (state, action: PatronymicChangedAction) => {
      state.patronymic = action.payload;
    },
    patronymicErrorFixed: (state) => {
      state.patronymicError = '';
    },
    postChanged: (state, action: PostChangedAction) => {
      state.post = action.payload;
    },
    postErrorFixed: (state) => {
      state.postError = '';
    },
    phoneChanged: (state, action: PhoneChangedAction) => {
      state.phone = action.payload;
    },
    phoneErrorFixed: (state) => {
      state.phoneError = '';
    },
    emailChanged: (state, action: EmailChangedAction) => {
      state.email = action.payload;
    },
    emailErrorFixed: (state) => {
      state.emailError = '';
    },
    phoneConfirmedChanged: (state, action: PhoneConfirmedChangedAction) => {
      state.phoneConfirmed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(copyStateFromAccountAsync.fulfilled, (state, action) => {
        state.surname = action.payload.lastName;
        state.surnameError = '';
        state.name = action.payload.firstName;
        state.nameError = '';
        state.patronymic = action.payload.middleName ?? '';
        state.patronymicError = '';
        state.post = action.payload.post;
        state.postError = '';
        state.phone = `${action.payload.phone}`;
        state.phoneError = '';
        state.email = action.payload.email;
        state.emailError = '';

        state.pushNewInspection = action.payload.pushes.find((item) => item.code === NEW_INSPECTION)?.isSubscribe ?? false;
        state.pushChangeStatusInspection = action.payload.pushes.find((item) => item.code === CHANGE_STATUS_INSPECTION)?.isSubscribe ?? false;
        state.pushErrorInspection = action.payload.pushes.find((item) => item.code === ERROR_INSPECTION)?.isSubscribe ?? false;
        state.pushChangeStep8D = action.payload.pushes.find((item) => item.code === CHANGE_STEP_8D)?.isSubscribe ?? false;
        state.pushReadMessage = action.payload.pushes.find((item) => item.code === READ_MESSAGE)?.isSubscribe ?? false;
        state.pushChangeStepQMMSG = action.payload.pushes.find((item) => item.code === CHANGE_STEP_QMMSG)?.isSubscribe ?? false;

        state.phoneConfirmed = true;
        state.authKey = '';
      })
      .addCase(sendSMSCodeAsync.fulfilled, (state, action) => {
        state.authKey = action.payload;
      })
      .addCase(checkSMSCodeAsync.fulfilled, (state, action) => {
        state.authKey = action.payload;
        state.phoneConfirmed = true;
      })
      .addCase(savePersonalAsync.rejected, (state, action) => {
        switch (action.payload?.reason)
        {
          case 'validation':
            state.surnameError = action.payload.validationErrors?.surnameError ?? '';
            state.nameError = action.payload.validationErrors?.nameError ?? '';
            state.patronymicError = action.payload.validationErrors?.patronymicError ?? '';
            state.postError = action.payload.validationErrors?.postError ?? '';
            state.phoneError = action.payload.validationErrors?.phoneError ?? '';
            state.emailError = action.payload.validationErrors?.emailError ?? '';
            break;
        }
      });
  },
})

const selectSurname = (state: RootState) => state.mainPersonal.surname;
const selectSurnameError = (state: RootState) => state.mainPersonal.surnameError;
const selectName = (state: RootState) => state.mainPersonal.name;
const selectNameError = (state: RootState) => state.mainPersonal.nameError;
const selectPatronymic = (state: RootState) => state.mainPersonal.patronymic;
const selectPatronymicError = (state: RootState) => state.mainPersonal.patronymicError;
const selectPost = (state: RootState) => state.mainPersonal.post;
const selectPostError = (state: RootState) => state.mainPersonal.postError;
const selectPhone = (state: RootState) => state.mainPersonal.phone;
const selectPhoneError = (state: RootState) => state.mainPersonal.phoneError;
const selectEmail = (state: RootState) => state.mainPersonal.email;
const selectEmailError = (state: RootState) => state.mainPersonal.emailError;
const selectPushNewInspection = (state: RootState) => state.mainPersonal.pushNewInspection;
const selectPushChangeStatusInspection = (state: RootState) => state.mainPersonal.pushChangeStatusInspection;
const selectPushErrorInspection = (state: RootState) => state.mainPersonal.pushErrorInspection;
const selectPushChangeStep8D = (state: RootState) => state.mainPersonal.pushChangeStep8D;
const selectPushReadMessage = (state: RootState) => state.mainPersonal.pushReadMessage;
const selectPushChangeStepQMMSG = (state: RootState) => state.mainPersonal.pushChangeStepQMMSG;
const selectPhoneConfirmed = (state: RootState) => state.mainPersonal.phoneConfirmed;
const selectAuthKey = (state: RootState) => state.mainPersonal.authKey;
const selectFormFilled = createSelector(
  [selectSurname, selectName, selectPost, selectPhone, selectEmail],
  (surname, name, post, phone, email) => {
    return (
      surname.trim().length > 0
      && 
      name.trim().length > 0
      && 
      post.trim().length > 0
      &&
      Util.selectDigitsFromString(phone).length > 1
      && 
      email.trim().length > 0
    )
  }
);

export const mainPersonalSelectors = {
  selectSurname,
  selectSurnameError,
  selectName,
  selectNameError,
  selectPatronymic,
  selectPatronymicError,
  selectPost,
  selectPostError,
  selectPhone,
  selectPhoneError,
  selectEmail,
  selectEmailError,
  selectPushNewInspection,
  selectPushChangeStatusInspection,
  selectPushErrorInspection,
  selectPushChangeStep8D,
  selectPushReadMessage,
  selectPushChangeStepQMMSG,
  selectPhoneConfirmed,
  selectAuthKey,
  selectFormFilled,
}

export const mainPersonalActions = mainPersonalSlice.actions;

export default mainPersonalSlice.reducer;