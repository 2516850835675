import * as React                from 'react';

import { ToolbarHomeVector     } from 'src/components/vector/toolbar/toolbar-home';
import { ToolbarMessageVector  } from 'src/components/vector/toolbar/toolbar-message';
import { ToolbarAddVector      } from 'src/components/vector/toolbar/toolbar-add';
import { ToolbarServicesVector } from 'src/components/vector/toolbar/toolbar-services';
import { ToolbarInfoVector     } from 'src/components/vector/toolbar/toolbar-info';

import { Ripple                } from 'src/components/common/ripple';

import { SelectModeDialog      } from 'src/components/features/inspection/edit/select-mode-dialog';

import { Container,
         Item,
         Label,
         ItemAdd,
         ToolbarVariants,
         toolbar_variants      } from 'src/components/features/main/common/toolbar/ui';

import { Urls                  } from 'src/providers/routing';

import { AccountRole           } from 'src/services/api/types/auth';
import { InspectionState       } from 'src/services/api/types/inspection';

import { useAppDispatch,
         useAppSelector,
         storeApi              } from 'src/store';



type Props = {
  variant: ToolbarVariants;
  activeItem: 'home' | 'message' | 'services' | 'info';
}
export const Toolbar: React.FC<Props> = ({
  variant,
  activeItem,
}) => {
  const lookAndFeel = toolbar_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const onToolbarItemTap = (item: 'home' | 'message' | 'add' | 'services' | 'info') => {
    switch (item)
    {
      case 'home':
        Urls.MainInspections.build().navigate();
        dispatch(storeApi.main.inspectionList.actions.paginationReset());
        dispatch(storeApi.qmmsg.list.actions.paginationReset());
        sessionStorage.removeItem('incpectionsListPosition');
        sessionStorage.removeItem('qmmsgListPosition');
        break;
      case 'info':
        Urls.MainInfo.build().navigate();
        dispatch(storeApi.main.inspectionList.actions.paginationReset());
        dispatch(storeApi.qmmsg.list.actions.paginationReset());
        sessionStorage.removeItem('incpectionsListPosition');
        sessionStorage.removeItem('qmmsgListPosition');
        break;
      case 'services':
        Urls.MainServices.build().navigate();
        dispatch(storeApi.main.inspectionList.actions.paginationReset());
        dispatch(storeApi.qmmsg.list.actions.paginationReset());
        sessionStorage.removeItem('incpectionsListPosition');
        sessionStorage.removeItem('qmmsgListPosition');
        break;
      case 'message':
        Urls.MainMessages.build('all').navigate();
        dispatch(storeApi.main.inspectionList.actions.paginationReset());
        dispatch(storeApi.qmmsg.list.actions.paginationReset());
        sessionStorage.removeItem('incpectionsListPosition');
        sessionStorage.removeItem('qmmsgListPosition');
        break;
      case 'add':
        if (currentAccount.role === AccountRole.Stpk)
        {
          Urls.InspectionEdit.build('none', InspectionState.NEW, true, false, false, false).navigate();
        }
        else
        {
          dispatch(storeApi.inspection.edit.actions.generalSelectModeOpened());
        }
        break;
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Ripple isTapAllowed onTap = { () => onToolbarItemTap('home') }>
        <Item
          lookAndFeel = {lookAndFeel}
          isActive = { activeItem === 'home' }
        >
          <ToolbarHomeVector />
          <Label lookAndFeel = { lookAndFeel }>Главная</Label>
        </Item>
      </Ripple>
      <Ripple isTapAllowed onTap = { () => onToolbarItemTap('message') }>
        <Item
          lookAndFeel = {lookAndFeel}
          isActive = { activeItem === 'message' }
        >
          <ToolbarMessageVector />
          <Label lookAndFeel = { lookAndFeel }>Сообщения</Label>
        </Item>
      </Ripple>
      <Ripple isTapAllowed onTap = { () => onToolbarItemTap('add') }>
        <ItemAdd
          lookAndFeel = {lookAndFeel}
          isActive = { false }
        >
          <ToolbarAddVector />
          <Label lookAndFeel = { lookAndFeel }>Создать</Label>
        </ItemAdd>
      </Ripple>
      <Ripple isTapAllowed onTap = { () => onToolbarItemTap('services') }>
        <Item
          lookAndFeel = {lookAndFeel}
          isActive = { activeItem === 'services' }
        >
          <ToolbarServicesVector />
          <Label lookAndFeel = { lookAndFeel }>Сервисы</Label>
        </Item>
      </Ripple>
      <Ripple isTapAllowed onTap = { () => onToolbarItemTap('info') }>
        <Item
          lookAndFeel = {lookAndFeel}
          isActive = { activeItem === 'info' }
        >
          <ToolbarInfoVector />
          <Label lookAndFeel = { lookAndFeel }>Инфо</Label>
        </Item>
      </Ripple>
      <SelectModeDialog variant = { lookAndFeel.selectModeDialogVariant } />
    </Container>
  );
};
