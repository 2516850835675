import React                        from 'react';

import { PhotoView                } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import { CloseVector              } from 'src/components/vector/close';

import { Loading                  } from 'src/components/common/loading';

import { Container,
         PhotoArea,
         Label,
         Close,
         AttachmentPhotoVariants,
         attachmentphoto_variants } from 'src/components/features/inspection/edit/attachment-photo/ui';

import * as types                   from 'src/services/api/types';

import { useAppDispatch,
         storeApi                 } from 'src/store';



type LoadingPhase = 'idle' | 'loading' | 'loaded';

type Props = {
  variant: AttachmentPhotoVariants;
  photo: types.inspection.PieceFile;
  isAllowDelete: boolean;
  onDelete?: (guid: string) => void;
}
export const AttachmentPhoto: React.FC<Props> = ({ variant, photo, isAllowDelete, onDelete }) => {
  const lookAndFeel = attachmentphoto_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const bootOpStarted = React.useRef<boolean>(false);

  const [loadingState, setLoadingState] = React.useState<LoadingPhase>((photo !== undefined && photo.__dataUrl !== undefined && photo.__dataUrl !== '') ? 'loaded' : 'idle');

  const isGeneral = photo.type === types.inspection.PieceFileType.GENERAL;
  
  React.useEffect(() => {
    if (loadingState === 'idle' && photo !== undefined)
    {
      if (bootOpStarted.current)
      {
        return;
      }

      bootOpStarted.current = true;

      setLoadingState('loading');
      dispatch(storeApi.inspection.edit.async.downloadPhotoAsync({ fileGuid: photo.guid }))
        .unwrap()
        .then((result) => {
          setLoadingState('loaded');
        })
        .catch((error) => { });
    }
  }, [loadingState, photo, dispatch]);

  const onDeleteHandler = () => {
    if (onDelete !== undefined)
    {
      onDelete(photo.guid);
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {(loadingState === 'idle' || loadingState === 'loading') && (
        <PhotoArea lookAndFeel = { lookAndFeel }>
          <Loading variant = { lookAndFeel.loadingVariant } />
        </PhotoArea>
      )}
      {loadingState === 'loaded' && (
        <PhotoArea lookAndFeel = { lookAndFeel }>
          <PhotoView src = { photo.__dataUrl }>
            <img src = { photo.__dataUrl } alt = 'Фото' />
          </PhotoView>
        </PhotoArea>
      )}
      {loadingState === 'loaded' && isAllowDelete && (
        <Close lookAndFeel = { lookAndFeel } onClick = { onDeleteHandler }>
          <CloseVector />
        </Close>
      )}
      <Label lookAndFeel = { lookAndFeel }>
        { `${isGeneral ? 'Общий вид' : 'Доп. фото'}` }
        {isGeneral && <i>*</i>}
      </Label>
    </Container>
  );
}
