import * as React                from 'react';

import { Button                } from 'src/components/common/button';
import { Dialog                } from 'src/components/common/dialog';
import { Link                  } from 'src/components/common/link';

import { Container, 
         Caption,
         EndingDialogVariants,
         endingdialog_variants } from 'src/components/features/inspection/edit/ending-dialog/ui';

import { InspectionState       } from 'src/services/api/types/inspection';

import { useAppSelector, 
         storeApi              } from 'src/store';



type Props = {
  variant: EndingDialogVariants;
  onSaveModeSelect: (saveMode: 'complete' | 'draft' | 'exit' | 'cancel') => void;
}
export const EndingDialog: React.FC<Props> = ({
  variant,
  onSaveModeSelect,
}) => {
  const lookAndFeel = endingdialog_variants.get(variant)!;
  
  const opened = useAppSelector(storeApi.inspection.edit.selectors.selectEndingOpened);
  const endCaption = useAppSelector(storeApi.inspection.edit.selectors.selectEndingEndCaption);
  const draftCaption = useAppSelector(storeApi.inspection.edit.selectors.selectEndingDraftCaption);
  const cancelCaption = useAppSelector(storeApi.inspection.edit.selectors.selectEndingCancelCaption);
  const closeCaption = useAppSelector(storeApi.inspection.edit.selectors.selectEndingCloseCaption);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const isNoTrans = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsNoTrans);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Завершение осмотра
        </Caption>
        <Button
          variant = { lookAndFeel.endButtonVariant }
          label = { endCaption }
          onTap = { () => onSaveModeSelect('complete') }
        />
        {![InspectionState.EDIT, InspectionState.ERROR].includes(inspectionState) && (
          <Button
            variant = { lookAndFeel.draftButtonVariant }
            label = { draftCaption }
            onTap = { () => onSaveModeSelect('draft') }
          />
        )}
        <Button
          variant = { lookAndFeel.closeButtonVariant }
          label = { closeCaption }
          isDisabled = { isNoTrans }
          onTap = { () => onSaveModeSelect('exit') }
        />
        <Link
          variant = { lookAndFeel.cancelLinkVariant }
          label = { cancelCaption }
          isCentered
          onTap = { () => onSaveModeSelect('cancel') }
        />
      </Container>
    </Dialog>
  );
};
