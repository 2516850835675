
import React from 'react';



type Props = {
  className?: string;
}
export const LinkBlankVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M19 13.418L19 22.418H4L4.00006 7.41797H12M15.5 4.41797L21.5 4.41797M21.5 4.41797V10.418M21.5 4.41797L11.5 14.418' />
    </svg>
  );
};
