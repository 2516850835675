import { createSlice,
         createAsyncThunk, 
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'qmmsg:question-history';

export interface QMMsgQuestionHistoryState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  items: types.qmmsg.QMMsgQuestion[];
}

const initialState: QMMsgQuestionHistoryState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  items: [],
}

// #region prepareInfoAsync
type PrepareInfoArgs = number;
type PrepareInfoResolve = {
  items: types.qmmsg.QMMsgQuestion[];
};
export type PrepareInfoReject = ApiError;
export const prepareInfoAsync = createAsyncThunk<
  PrepareInfoResolve,
  PrepareInfoArgs,
  {
    state: RootState,
    rejectValue: PrepareInfoReject,
  }
>(
  `${SLICE_NAME}/prepareInfoAsync`,
  async (qmMsgId, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(qmmsgQuestionHistoryActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(qmmsgQuestionHistoryActions.opStatusLabelChanged('Загрузка истории запросов'));
      const items = await Api.getQMMsgClaimQuestionsById({
        qmMsgId
      });

      return { items }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

export const qmmsgQuestionHistorySlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(prepareInfoAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.items = action.payload.items;
      })
      .addCase(prepareInfoAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
  },
})

const selectOpStatus = (state: RootState) => state.qmmsgQuestionHistory.opStatus;
const selectOpStatusLabel = (state: RootState) => state.qmmsgQuestionHistory.opStatusLabel;

const selectItems = (state: RootState) => state.qmmsgQuestionHistory.items;


export const qmmsgQuestionHistorySelectors = {
  selectOpStatus,
  selectOpStatusLabel,

  selectItems
}

export const qmmsgQuestionHistoryActions = qmmsgQuestionHistorySlice.actions;

export default qmmsgQuestionHistorySlice.reducer;