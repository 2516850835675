import * as React          from 'react';

import { useAlert        } from 'react-alert';

import { useTheme        } from 'styled-components';

import { BlockUI         } from 'src/components/common/block-ui';
import { VGap            } from 'src/components/common/flex/vgap';

import { NavBar          } from 'src/components/features/common/nav-bar';
import { Toolbar         } from 'src/components/features/main/common/toolbar';
import { BlockCaption    } from 'src/components/features/main/common/block-caption';

import { InfoItemsList   } from 'src/components/features/main/info/info-items-list';

import { InfoLayout,
         Screen,
         ScreenBody,
         layout_light,
         layout_dark     } from 'src/containers/main/info/layout';

import Api                 from 'src/services/api';
import * as types          from 'src/services/api/types';

import { AppDispatch,
         useAppSelector,
         useAppDispatch,
         storeApi        } from 'src/store';
import { GetInfoReject   } from 'src/store/main/info';



type InfoScreenElement = { layout: InfoLayout; dispatch: AppDispatch; }

const Navigation: React.FC<InfoScreenElement> = ({ layout, dispatch }) => {
  return (
    <NavBar variant = { layout.navbarVariant } label = 'Информация' />
  )
}

const Materials: React.FC<InfoScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const [blocked, setBlocked] = React.useState<boolean>(false);
  const [blockedLabel, setBlockedLabel] = React.useState<string>('');
  const list = useAppSelector(storeApi.main.info.selectors.selectList);
  
  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      bootOpStarted.current = true;
      setBlockedLabel('Получение информационных материалов');
      setBlocked(true);
      dispatch(storeApi.main.info.async.getInfoAsync())
        .unwrap()
        .then(() => {
          setBlocked(false);
        })
        .catch((error) => {
          setBlocked(false);
          alert.error((error as GetInfoReject).message);
        });
    }
  }, [dispatch, alert]);

  const categories = React.useMemo(
    () => list.reduce<string[]>((p, v) => p.includes(v.kindInfoTitle) ? p : [...p, v.kindInfoTitle], []),
    [list]
  );

  const onActionHandler = (action: 'navigate' | 'share', item: types.info.InfoElement) => {
    switch (action)
    {
      case 'navigate':
        if (item.type === 'ref')
        {
          window.open(item.url, '_blank')?.focus();
        }
        else
        {
          setBlockedLabel('Получение файла');
          setBlocked(true);
          Api.getInfoFileById({ id: item.id })
            .then((result) => { window.open(result.dataUrl, '_blank')?.focus(); setBlocked(false);})
            .catch((error) => { alert.error('Не удалось получить файл'); setBlocked(false); });
        }
        break;
      case 'share':
        setBlockedLabel('Получение файла');
        setBlocked(true);
        Api.getRawInfoFileById({ id: item.id })
          .then((result) => {
            const file = new File([result.rawData], item.title, { type: 'application/pdf' });
            navigator.share({
              title: item.title,
              text: `Файл из приложения Checksteel`,
              files: [file]
            });
            setBlocked(false);
          })
          .catch((error) => { alert.error('Не удалось получить файл'); setBlocked(false); });
        break;
    }
  }

  return (
    <React.Fragment>
      {categories.map((category) => (
        <React.Fragment key = { category }>
          <BlockCaption variant = { layout.blockCaptionVariant }>{ category }</BlockCaption>
          <InfoItemsList
            variant = { layout.infoItemsListVariant }
            items = { list.filter((item) => item.kindInfoTitle === category) }
            onAction = { onActionHandler }
          />
        </React.Fragment>
      ))}
      <VGap size = { layout.paddings[1] } />
      <BlockUI
        variant = { layout.blockUIVariant }
        isOpened = { blocked }
        message = {blockedLabel}
      />
    </React.Fragment>
  )
}


export const InfoScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  

  return (
    <Screen layout = { layout }>
      <Navigation layout={layout} dispatch={dispatch} />
      <ScreenBody layout = { layout }>
        <Materials layout={layout} dispatch={dispatch} />
      </ScreenBody>
      <Toolbar variant = { layout.toolbarVariant } activeItem = 'info' />
    </Screen>
  );
};
