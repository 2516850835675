import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,  
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type StatusLookAndFeel = {
  height: number;
  paddings: number[];
  labelTypo: Typo;
  
  borderColor: string;
  backgroundColor: string;
  textColor: string;
}

const status_base = {
  height: 18,
  paddings: [0, 6],
  labelTypo: themeTypos.GRTSKREG_W400_S10_LH12,
}

const status_redoutline: StatusLookAndFeel = {
  ...status_base,

  borderColor: themeColors.AccentRed1,
  backgroundColor: themeColors.PrimaryWhite,
  textColor: themeColors.AccentRed1,
}

const status_blueoutline: StatusLookAndFeel = {
  ...status_base,

  borderColor: themeColors.AccentBlue2,
  backgroundColor: themeColors.PrimaryWhite,
  textColor: themeColors.AccentBlue2,
}

const status_grayfill: StatusLookAndFeel = {
  ...status_base,

  borderColor: themeColors.SecondaryGray4,
  backgroundColor: themeColors.SecondaryGray4,
  textColor: themeColors.PrimaryWhite,
}

export type StatusVariants = 'redoutline' | 'blueoutline' | 'grayfill';

export const status_variants: Map<StatusVariants, StatusLookAndFeel> = 
  new Map<StatusVariants, StatusLookAndFeel>([
    ['redoutline', status_redoutline],
    ['blueoutline', status_blueoutline],
    ['grayfill', status_grayfill],
  ]);

export const Container = styled.div<{
  lookAndFeel: StatusLookAndFeel;
}>`
  ${cssFlexRow}
  align-items: center;
  width: fit-content;
  height: ${(args) => args.lookAndFeel.height}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  color: ${(args) => args.lookAndFeel.textColor};

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
`
