import styled                     from 'styled-components';

import { DialogVariants         } from 'src/components/common/dialog';
import { LabeledContentVariants } from 'src/components/common/labeled-content';
import { ButtonVariants         } from 'src/components/common/button';
import { LinkVariants           } from 'src/components/common/link';
import { InputMultilineVariants } from 'src/components/common/input/input-multiline';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,
         cssFlexRow             } from 'src/providers/theme/shared';



type BackForRevisionDialogLookAndFeel = {
  paddings: number[];
  gap: number;

  captionTypo: Typo;
  captionColor: string;

  errorTypo: Typo;
  errorColor: string;

  sendTypo: Typo;
  sendColor: string;

  spinnerColor: string;
  spinnerHeight: number;

  labeledVariant: LabeledContentVariants;
  inputVariant: InputMultilineVariants;

  dialogVariant: DialogVariants;
  sendButtonVariant: ButtonVariants;
  cancelLinkVarint: LinkVariants;
}

const backforrevision_light: BackForRevisionDialogLookAndFeel = {
  paddings: [28, 20],
  gap: 24,

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  errorTypo: themeTypos.GRTSKREG_W400_S13_LH20,
  errorColor: themeColors.AccentRed1,

  sendTypo: themeTypos.GRTSKREG_W400_S13_LH20,
  sendColor: themeColors.PrimarySteel,

  spinnerColor: themeColors.AccentBlue1,
  spinnerHeight: 48,

  labeledVariant: 'blue1w400s14lh17gap4',
  inputVariant: 'light',

  dialogVariant: 'light',
  sendButtonVariant: 'h48bluefill',
  cancelLinkVarint: 'blue13',
}

export type BackForRevisionDialogVariants = 'light';

export const backforrevisiondialog_variants: Map<BackForRevisionDialogVariants, BackForRevisionDialogLookAndFeel> = 
  new Map<BackForRevisionDialogVariants, BackForRevisionDialogLookAndFeel>([
    ['light', backforrevision_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: BackForRevisionDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: BackForRevisionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Error = styled.div<{
  lookAndFeel: BackForRevisionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.errorTypo)};
  color: ${(args) => args.lookAndFeel.errorColor};
`

export const Spinner = styled.div<{
  lookAndFeel: BackForRevisionDialogLookAndFeel
}>`
  ${cssFlexRow};
  justify-Content: center;
  align-items: center;

  & svg {
    width: 80px;
    height: 20px;
    color: ${(args) => args.lookAndFeel.spinnerColor};
  }
`