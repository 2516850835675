import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region Common

export type InfoElement = {
  /** ИД информации */
  id: number;
  /** Идентификатор раздела */
  kindInfoId: number;
  /** Заголовок раздела */
  kindInfoTitle: string;
  /** Тип данных, ref - ссылка, file - файл */
  type: 'ref' | 'file';
  /** Заголовок информации */
  title: string;
  /** URL */
  url: string;
  /** Порядок сортировки */
  sortOrder: number;
  /** Дата изменения */
  dateModified: number;
}

// #endregion


// #region GET info

export type GetInfoIn = void;

export type GetInfoOut = Array<InfoElement>;

export const prepareGetInfo
  : PrepareCallHandler<GetInfoIn, GetInfoOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/info`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET info/file/{id}

export type GetInfoFileByIdIn = {
  /** Идентификатор файла */
  id: number;
}

export type GetInfoFileByIdOut = {
  /** Файл в формате data url */
  dataUrl: string;
}

export const prepareGetInfoFileById
  : PrepareCallHandler<GetInfoFileByIdIn, GetInfoFileByIdOut> = (arg: GetInfoFileByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/info/file/${arg.id}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (data) => {
        const typedBlob = new Blob([data], {type: 'application/pdf'});
        return ({
          dataUrl: window.URL.createObjectURL(typedBlob)
        })
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET info/file/{id}

export type GetRawInfoFileByIdIn = {
  /** Идентификатор файла */
  id: number;
}

export type GetRawInfoFileByIdOut = {
  /** Файл в формате data url */
  rawData: Blob;
}

export const prepareGetRawInfoFileById
  : PrepareCallHandler<GetRawInfoFileByIdIn, GetRawInfoFileByIdOut> = (arg: GetRawInfoFileByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/info/file/${arg.id}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (data) => {
        console.log(data);
        //const typedBlob = new Blob([data], {type: 'application/pdf'});
        return { rawData: data};
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
