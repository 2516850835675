import styled                     from 'styled-components';

import { InputDigitCodeVariants } from 'src/components/common/input/input-digitcode';
import { LinkVariants           } from 'src/components/common/link';
import { SendAgainVariants      } from 'src/components/common/send-again';
import { BlockUIVariants        } from 'src/components/common/block-ui';

import { NavBarVariants         } from 'src/components/features/auth/common/nav-bar';

import { cssFlexColumn,
         themeColors            } from 'src/providers/theme/shared';



export type SMSCodeLayout = {
  paddings: number[];

  backgroundColor: string;

  navbarVariant: NavBarVariants;
  inputDigitCodeVariant: InputDigitCodeVariants;
  sendAgainVariant: SendAgainVariants;
  linkVariant: LinkVariants;
  blockuiVariant: BlockUIVariants;
}

export const layout_light: SMSCodeLayout = {
  paddings: [30, 36, 47, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  navbarVariant: 'light',
  inputDigitCodeVariant: 'h48light',
  sendAgainVariant: 'light',
  linkVariant: 'blue15',
  blockuiVariant: 'light',
}

export const layout_dark: SMSCodeLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: SMSCodeLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: SMSCodeLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`
