import styled                      from 'styled-components';

import { CheckboxVariants        } from 'src/components/common/checkbox';
import { ButtonVariants          } from 'src/components/common/button';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';
import { TypographyVariants      } from 'src/components/common/typography';

import { BlockCaptionVariants    } from 'src/components/features/inspection/common/block-caption';

import { PieceItemVariants       } from 'src/components/features/inspection/edit/piece-item';

import { cssFlexColumn,
         cssFlexRowCC,
         cssFlexRow              } from 'src/providers/theme/shared';



type PieceListLookAndFeel = {
  gap: number;
  checkboxContainerWidth: number;
  textBottomPadding: number;
  allLeftPadding: number;
  allSelectorGap: number;

  deleteConfirmVariant: TwoActionDialogVariants;
  checkboxVariant: CheckboxVariants;
  blockCaptionVariant: BlockCaptionVariants;
  positionItemVariant: PieceItemVariants;
  addPositionButtonVariant: ButtonVariants;
  allVariant: TypographyVariants;
  deleteUnselectVariant: TypographyVariants;
  deleteSelectVariant: TypographyVariants;
}

const piecelist_light: PieceListLookAndFeel = {
  gap: 24,
  checkboxContainerWidth: 36,
  textBottomPadding: 4,
  allLeftPadding: 8,
  allSelectorGap: 4,

  deleteConfirmVariant: 'light',
  checkboxVariant: 'h24bluetext',
  blockCaptionVariant: 'light',
  positionItemVariant: 'light',
  addPositionButtonVariant: 'h36blueoutline',
  allVariant: 'steelw400s12lh14',
  deleteUnselectVariant: 'steelw400s12lh14',
  deleteSelectVariant: 'redw400s12lh14',
}

export type PieceListVariants = 'light';

export const piecelist_variants: Map<PieceListVariants, PieceListLookAndFeel> = 
  new Map<PieceListVariants, PieceListLookAndFeel>([
    ['light', piecelist_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const List = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
`

export const AllSelector = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.allSelectorGap}px;
`

export const AllSelectorCheckboxContainer = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexRowCC};
  width: ${(args) => args.lookAndFeel.checkboxContainerWidth}px;

  & > div {
    width: auto;
  }
`

export const AllSelectorAllContainer = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexRow};
  padding-bottom: ${(args) => args.lookAndFeel.textBottomPadding}px;
  padding-left: ${(args) => args.lookAndFeel.allLeftPadding}px;
  align-items: end;
  flex-grow: 2;
`

export const AllSelectorDeleteContainer = styled.div<{
  lookAndFeel: PieceListLookAndFeel
}>`
  ${cssFlexRow};
  padding-bottom: ${(args) => args.lookAndFeel.textBottomPadding}px;
  align-items: end;

  cursor: pointer;
`

