import styled                     from 'styled-components';

import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { cssFlexColumn, 
         cssFlexRow, 
         themeColors            } from 'src/providers/theme/shared';



type StatusInfoLookAndFeel = {
  paddings: number[];
  borderColor: string;
  backgroundColor: string;
  gap: number;

  labeledContentVariant: LabeledContentVariants;
}

const statusinfo_light: StatusInfoLookAndFeel = {
  paddings: [12, 16, 16, 16],
  borderColor: themeColors.CardBorderColor,
  backgroundColor: themeColors.PrimaryWhite,
  gap: 12,

  labeledContentVariant: 'gray5w400s12lh14gap4',
}

export type StatusInfoVariants = 'light';

export const statusinfo_variants: Map<StatusInfoVariants, StatusInfoLookAndFeel> = 
  new Map<StatusInfoVariants, StatusInfoLookAndFeel>([
    ['light', statusinfo_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: StatusInfoLookAndFeel;
}>`
  ${cssFlexColumn}
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const TwoColumnRow = styled.div<{
  lookAndFeel: StatusInfoLookAndFeel;
}>`
  ${cssFlexRow}
  width: 100%;


`
