import React                         from 'react';

import { RouteObject               } from 'react-router-dom';

import { InspectionEditScreen      } from 'src/containers/inspection/edit';
import { InspectionComplaintScreen } from 'src/containers/inspection/complaint';

import { BaseUrl                   } from 'src/providers/routing/base-url';
import { WithInitNavigate          } from 'src/providers/routing/with-init-navigate';

import { InspectionState           } from 'src/services/api/types/inspection';



class InspectionEditUrl extends BaseUrl {
  build(guid: string, state: InspectionState, withDefect: boolean, isTransferOnly: boolean, isChangeToComplaint: boolean, isChangeToComplaintFinance: boolean): InspectionEditUrl {
    this._url = `/inspection/edit?guid=${guid}&state=${state}&withDefect=${withDefect ? 'Y' : 'N'}&isTransferOnly=${isTransferOnly ? 'Y' : 'N'}&isChangeToComplaint=${isChangeToComplaint ? 'Y' : 'N'}&isChangeToComplaintFinance=${isChangeToComplaintFinance ? 'Y' : 'N'}`;
    return this;
  }
}

class InspectionReturnFromComplaintUrl extends BaseUrl {
  build(isSend?: boolean): InspectionReturnFromComplaintUrl {
    this._url = `/inspection/return-from-complaint${isSend ? '?isSend=Y' : ''}`;
    return this;
  }
}

class InspectionComplaintUrl extends BaseUrl {
  build(action: 'list' | 'new' | 'edit' | 'tocomplaint' | 'tocomplaintfinance' | 'view'): InspectionComplaintUrl {
    this._url = `/inspection/complaint/${action}`;
    return this;
  }
}

export const InspectionUrls = {
  InspectionEdit: new InspectionEditUrl('/inspection/edit'),
  InspectionReturnFromComplaint: new InspectionReturnFromComplaintUrl('/inspection/return-from-complaint'),
  InspectionComplaint: new InspectionComplaintUrl('/inspection/complaint/:action'),
}

export const InspectionRoutes: RouteObject[] = [
  {
    path: InspectionUrls.InspectionEdit.pattern,
    element: <WithInitNavigate isProtectedRoute><InspectionEditScreen /></WithInitNavigate>
  },
  {
    path: InspectionUrls.InspectionReturnFromComplaint.pattern,
    element: <WithInitNavigate isProtectedRoute><InspectionEditScreen /></WithInitNavigate>
  },
  {
    path: InspectionUrls.InspectionComplaint.pattern,
    element: <WithInitNavigate isProtectedRoute><InspectionComplaintScreen /></WithInitNavigate>
  },
];
