import styled             from 'styled-components';

import { DialogVariants } from 'src/components/common/dialog';
import { ButtonVariants } from 'src/components/common/button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,    
         cssFlexRow} from 'src/providers/theme/shared';



type DuplicateDialogLookAndFeel = {
  paddings: number[];
  backgroundColor: string;

  captionTypo: Typo;
  captionColor: string;

  messageTypo: Typo;
  messageColor: string;

  numberTypo: Typo;
  numberColor: string;

  gap: number;
  actionsGap: number;

  itemPaddings: number[];
  itemGap: number;
  itemBackgroundColor: string;
  itemBorderColor: string;
  itemDateRowTypo: Typo;
  itemDateRowColor: string;
  itemDateRowIconColor: string;
  itemDateRowHeight: number;
  itemArgCaptionTypo: Typo;
  itemArgCaptionColor: string;
  itemArgContentTypo: Typo;
  itemArgContentColor: string;
  itemArgGap: number;
  itemArgsGap: number;

  buttonOkVariant: ButtonVariants;
  buttonCancelVariant: ButtonVariants;
  dialogVariant: DialogVariants;
}

const duplicatedialog_light: DuplicateDialogLookAndFeel = {
  paddings: [28, 20],

  backgroundColor: themeColors.BackgroundFillLight,

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentRed1,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.PrimaryBlack,

  numberTypo: themeTypos.GRTSKREG_W600_S15_LH20,
  numberColor: themeColors.PrimaryBlack,

  gap: 20,
  actionsGap: 16,

  itemPaddings: [12, 12, 12, 16],
  itemGap: 8,
  itemBackgroundColor: themeColors.PrimaryWhite,
  itemBorderColor: themeColors.SecondaryGray2,
  itemDateRowTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  itemDateRowColor: themeColors.AccentBlue1,
  itemDateRowIconColor: themeColors.AccentBlue2,
  itemDateRowHeight: 16,
  itemArgCaptionTypo: themeTypos.GRTSKREG_W400_S12_LH14,
  itemArgCaptionColor: themeColors.SecondaryGray5,
  itemArgContentTypo: themeTypos.GRTSKREG_W400_S14_LH16,
  itemArgContentColor: themeColors.PrimaryBlack,
  itemArgGap: 4,
  itemArgsGap: 16,

  buttonOkVariant: 'h48bluefill',
  buttonCancelVariant: 'h48blueoutline',
  dialogVariant: 'light',
}

export type DuplicateDialogVariants = 'light';

export const duplicatedialog_variants: Map<DuplicateDialogVariants, DuplicateDialogLookAndFeel> = 
  new Map<DuplicateDialogVariants, DuplicateDialogLookAndFeel>([
    ['light', duplicatedialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  position: relative;
  height: 90dvh;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`

export const Number = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.numberTypo)};
  color: ${(args) => args.lookAndFeel.numberColor};
`

export const Actions = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
`

export const ItemContainer = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.itemGap}px;
  padding: ${(args) => args.lookAndFeel.itemPaddings[0]}px
           ${(args) => args.lookAndFeel.itemPaddings[1]}px
           ${(args) => args.lookAndFeel.itemPaddings[2]}px
           ${(args) => args.lookAndFeel.itemPaddings[3]}px;
  
  background-color: ${(args) => args.lookAndFeel.itemBackgroundColor};
  border: 1px solid ${(args) => args.lookAndFeel.itemBorderColor};

  & > span {
    width: fit-content;
  }
`

export const ItemDateRow = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexRow};
  justify-content: space-between;

  ${(args) => typoAsCSS(args.lookAndFeel.itemDateRowTypo)};
  color: ${(args) => args.lookAndFeel.itemDateRowColor};

  & > svg {
    color: ${(args) => args.lookAndFeel.itemDateRowIconColor};
  }
`

export const ItemDateContainer = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.itemGap}px;
  height: ${(args) => args.lookAndFeel.itemDateRowHeight}px;

  & > svg {
    color: ${(args) => args.lookAndFeel.itemDateRowIconColor};
    width: ${(args) => args.lookAndFeel.itemDateRowHeight}px;
    height: ${(args) => args.lookAndFeel.itemDateRowHeight}px;
  }
`

export const ItemArgCaption = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemArgCaptionTypo)};
  color: ${(args) => args.lookAndFeel.itemArgCaptionColor};
`

export const ItemArgContent = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemArgContentTypo)};
  color: ${(args) => args.lookAndFeel.itemArgContentColor};
`

export const ItemArg = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.itemArgGap}px;
`

export const ItemArgs = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.itemArgsGap}px;
`

export const Items = styled.div<{
  lookAndFeel: DuplicateDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  overflow: auto;
`