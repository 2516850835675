import React from 'react';



type Props = {
  className?: string;
}
export const CircleQuestionVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9.08997 8.99959C9.32507 8.33126 9.78912 7.7677 10.3999 7.40873C11.0107 7.04975 11.7289 6.91853 12.4271 7.0383C13.1254 7.15808 13.7588 7.52112 14.215 8.06312C14.6713 8.60512 14.921 9.29111 14.92 9.99959C14.92 11.9996 12 13 12 13V15M12 17V19M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'/>
    </svg>
  );
};
