import { createSlice,
         createAsyncThunk,
         PayloadAction       } from '@reduxjs/toolkit';

import Api,
       { ApiError            } from 'src/services/api';
import * as types              from 'src/services/api/types';

import { RootState           } from 'src/store';
import { authCommonSelectors } from 'src/store/auth/common';



const SLICE_NAME = 'auth:sms-code';

export interface AuthSMSCodeState {
  smsCode: string;
}

const initialState: AuthSMSCodeState = {
  smsCode: '',
}

// #region checkSMSAsync
type CheckSMSArgs = { type: types.auth.TypeTwoFactor; code: string; };
type CheckSMSResolve = types.auth.PostAuth2faControlOut;
export type CheckSMSReject = ApiError;
export const checkSMSAsync = createAsyncThunk<
  CheckSMSResolve,
  CheckSMSArgs,
  {
    state: RootState,
    rejectValue: CheckSMSReject,
  }
>(
  `${SLICE_NAME}/checkSMSAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const authKey = authCommonSelectors.selectAuthKey(state);
      return  await Api.postAuth2faControl({
        type: args.type,
        authKey,
        code2fa: parseInt(args.code, 10),
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion


// #region sendSMSAsync
type SendSMSArgs = types.auth.TypeTwoFactor;
type SendSMSResolve = void;
export type SendSMSReject = ApiError;
export const sendSMSAsync = createAsyncThunk<
  SendSMSResolve,
  SendSMSArgs,
  {
    state: RootState,
    rejectValue: SendSMSReject,
  }
>(
  `${SLICE_NAME}/sendSMSAsync`,
  async (args, thunkAPI) => {
    try
    {
      const state = thunkAPI.getState();
      const authKey = authCommonSelectors.selectAuthKey(state);
      await Api.postAuth2fa({
        type: args,
        authKey,
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion


type SMSCodeChangedAction = PayloadAction<string>;

export const authSMSCodeSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    codeChanged: (state, action: SMSCodeChangedAction) => {
      state.smsCode = action.payload;
    },
    reset: (state) => {
      state.smsCode = '';
    }
  },
})

const selectSMSCode = (state: RootState) => state.authSMSCode.smsCode;

export const authSMSCodeSelectors = {
  selectSMSCode,
}

export const authSMSCodeActions = authSMSCodeSlice.actions;

export default authSMSCodeSlice.reducer;