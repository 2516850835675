import styled                        from 'styled-components';

import { DialogVariants            } from 'src/components/common/dialog';

import { RevokeTokenDialogVariants } from 'src/components/features/main/token/revoke-token-dialog';

import { cssFlexColumn, 
         cssFlexRow, 
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS                 } from 'src/providers/theme/shared';



type TokenItemLookAndFeel = {
  paddings: number[];

  gap: number;

  backgroundColor: string;
  borderColor: string;

  expireTypo: Typo;
  expireColor: string;

  tokenTypo: Typo;
  tokenColor: string;

  textGap: number;
  buttonsGap: number;

  buttonSize: number;
  buttonBorderColor: string;
  buttonIconColor: string;

  dialogVariant: DialogVariants;
  revokeVariant: RevokeTokenDialogVariants;
}

const tokenitem_light: TokenItemLookAndFeel = {
  paddings: [14, 12, 14, 15],

  gap: 8,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expireTypo: themeTypos.GRTSKREG_W400_S12_LH22,
  expireColor: themeColors.SecondaryGray5,

  tokenTypo: themeTypos.GRTSKREG_W400_S16_LH22,
  tokenColor: themeColors.PrimaryBlack,

  textGap: 4,
  buttonsGap: 8,

  buttonSize: 40,
  buttonBorderColor: themeColors.CardBorderColor,
  buttonIconColor: themeColors.AccentBlue2,

  dialogVariant: 'light',
  revokeVariant: 'light',
}

export type TokenItemVariants = 'light';

export const tokenitem_variants: Map<TokenItemVariants, TokenItemLookAndFeel> = 
  new Map<TokenItemVariants, TokenItemLookAndFeel>([
    ['light', tokenitem_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.gap}px;
  width: 100%;
  align-items: center;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;
`

export const ContentContainer = styled.div<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.textGap}px;
  width: calc(100% - ${(args) => args.lookAndFeel.buttonSize * 2 + args.lookAndFeel.buttonsGap + args.lookAndFeel.gap}px);
`

export const ExpireText = styled.div<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.expireTypo)};
  color: ${(args) => args.lookAndFeel.expireColor };
`

export const TokenText = styled.div<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.tokenTypo)};
  color: ${(args) => args.lookAndFeel.tokenColor };
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ActionsContainer = styled.div<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.buttonsGap}px;
  width: ${(args) => args.lookAndFeel.buttonSize * 2 + args.lookAndFeel.buttonsGap}px;
  flex-shrink: 0;
`

export const ActionButton = styled.button<{
  lookAndFeel: TokenItemLookAndFeel;
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;
  width: ${(args) => args.lookAndFeel.buttonSize}px;
  height: ${(args) => args.lookAndFeel.buttonSize}px;
  border: 1px solid ${(args) => args.lookAndFeel.buttonBorderColor };
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  cursor: pointer;

  & > svg {
    color: ${(args) => args.lookAndFeel.buttonIconColor };
  }
`
