import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export enum InspectionStatusCode {
  /** 0 - Черновик */
  DRAFT = 'draft',
  /** 1 - Ожидает проверки */ 
  AWAITING_VERIFICATION = 'awaitingVerification',
  /** 2 - Внутренняя проработка */
  INTERNAL = 'internal',
  /** 3 - Ожидает рассмотрения */
  AWAITING_REVIEW = 'awaitingReview',
  /** 4 - Взят в работу */
  TAKE_TO_WORK = 'takeToWork',
  /** 5 - Ожидает подтверждения */
  AWAITING_CONFIRMATION = 'awaitingConfirmation',
  /** 6 - Обработка завершена */
  COMPLETED = 'completed',
  /** 7 - Возврат на доработку */ 
  RETURN_FOR_REVISION = 'returnForRevision',
  /** 8 - Ошибка */
  ERROR = 'error',
  /** 9 - Отзыв */
  REVOCATION = 'revocation',
  /**  */
  NO_TRANS_DRAFT = 'noTransDraft',
  /**  */
  NO_TRANS_ERROR = 'noTransError',
  /**  */
  NO_TRANS_COMPLETED = 'noTransCompleted',
}

export enum InspectionState {
  NULL = 'null',
  NEW = 'new',
  DRAFT = 'draft',
  EDIT = 'edit',
  ERROR = 'error',
  VIEW = 'view',
  CREATE = 'create',
}

export type InspectionStatus = {
  /** ИД статуса. По этому ИД из справочника статусов можно получить название */
  id: number;
  /** Код статуса */
  code: InspectionStatusCode;
  /** Наименование статуса */
  title: string;
  /** timestamp присвоения статуса */
  timestamp: number;
  /** ФИО сотрудника Северстали, изменившего статус, м.б. null */
  fio: Nullable<string>;
  /** Примечание по статусу, м.б. null */
  note: Nullable<string>;
}

export enum QCState {
  /** не загружен */
  NONE = 'none',
  /** загружается */
  LOADING = 'loading',
  /** загружен */
  LOADED = 'loaded'
}

export enum ComplaintType {
  /** рекламация */
  COMPLAINT = 'complaint',
  /** замечание */
  REMARK = 'remark',
  /** NONE - искуственное значение, может иногда приходить в черновиках с мобилы */
  NONE = 'none',
}

export enum ComplaintMode {
  /** Новое */
  NEW = 'new',
  /** Откорректированное */
  EDIT = 'edit',
  /** Удаленное */
  DEL = 'del',
  /** Существующее */
  EXIST = 'exist'
}

export type Complaint = {
  /** Обращение */
  typeComplaint: Nullable<ComplaintType>;
  /** Уценить металлопрокат до цены лома, м.б. null */
  isToScrap: Nullable<boolean>;
  /** Цена лома, м.б. null */
  scrapPrice: Nullable<number>;
  /** Уценить металлопрокат, м.б. null */
  isEvaluateRolledMetal: Nullable<boolean>;
  /** Требование к уценке, м.б. null */
  requirementRolledMetall: Nullable<string>;
  /** Компенсировать дополнительные затраты, м.б. null */
  isToOffsetCosts: Nullable<boolean>;
  /** Сумма компенсации (без НДС), м.б. null */
  amountOfCompensation: Nullable<number>;
  /** Осуществить возврат металлопроката, м.б. null */
  isReturnOfRolledMetal: Nullable<boolean>;
  /** Предоставить скидку на забракованную партию проката в размере, м.б. null */
  isSale: Nullable<boolean>;
  /** Размер скидки (%), м.б. null */
  sale: Nullable<number>;
  /** Направить представителя, м.б. null */
  isRepresentative: Nullable<boolean>;
  /** Предоставить оперативный ответ по действиям с забракованным металлопрокатом или возможности переработки проката, м.б. null */
  isProvideResponse: Nullable<boolean>;
  /** Определить потенциально опасные партии проката по указанному несоответствию, м.б. null */
  isIdentifyDangerous: Nullable<boolean>;
  /** Провести анализ проблем с целью выяснения причины дефекта, м.б. null */
  isAnalizProblem: Nullable<boolean>;
  /** Предоставить отчет в формате 8D, м.б. null */
  is8d: Nullable<boolean>;
  /** Другие требования, м.б. null */
  otherReq: Nullable<string>;
  /** Переработанные партии без дефектов (номера), м.б. null */
  groupRevised: Nullable<string>;
  /** Переработанные партии без дефектов (Объем (т)), м.б. null */
  groupRevisedValue: Nullable<number>;
  /** Не переработанные партии у клиента на складе (номера), м.б. null */
  groupUnrevised: Nullable<string>;
  /** Не переработанные партии у клиента на складе (Объем (т)), м.б. null */
  groupUnrevisedValue: Nullable<number>;
  /** Принятое решение по забракованной партии, м.б. null */
  decision: Nullable<string>;
  /** ФИО, м.б. null */
  fio: Nullable<string>;
  /** Организация, м.б. null */
  enterprise: Nullable<string>;
  /** Телефон, м.б. null */
  phone: Nullable<string>;
  /** Email, м.б. null */
  email: Nullable<string>;
  /** Немедленно передать на Северсталь внутреннюю проработку после проверки осмотра */
  isTransferImmediately: Nullable<boolean>;
  /** Состояние замечания/рекламации. При получении осмотров с сервера у всех exist, остальные только в процессе создания и корректировки осмотра */
  mode: ComplaintMode;
}

export type PieceTopography = {
  /** Размер дефекта, м.б. null */
  defectSize: Nullable<number>;
  /** ID расположения топографии дефекта (размер), м.б. null */
  defectTpgIdSize: Nullable<number>;
  /** Наименование расположения топографии дефекта (размер), м.б. null */
  defectTpgTitleSize: Nullable<string>;
  /** Шаг дефекта, м.б. null */
  defectStep: Nullable<number>;
  /** ID расположения топографии дефекта (шаг), м.б. null */
  defectTpgIdStep: Nullable<number>;
  /** Наименование расположения топографии дефекта (шаг), м.б. null */
  defectTpgTitleStep: Nullable<string>;
  /** Расстояние от кромки, м.б. null */
  defectDistanceFromEdge: Nullable<number>;
  /** ИД расположения топографии дефекта (расстояние от кромки), м.б. null */
  defectTpgIdDistance: Nullable<number>;
  /** Наименование расположения топографии дефекта (расстояние от кромки), м.б. null */
  defectTpgTitleDistance: Nullable<string>;
}

export type PieceQmetData = {
  /** Партия, м.б. null */
  group: Nullable<string>;
  /** Номер сертификата качества, м.б. null */
  qcNum: Nullable<string>;
  /** Состояние сертификата качества */
  qcState: QCState;
}

export type PieceField = {
  /** ИД поля */
  id: number;
  /** Код поля */
  code: string;
  /** Значение поля */
  value: string;
}

export enum PieceFileMode {
  /** Новый файл */
  NEW = 'new',
  /** Удаленный файл */
  DEL = 'del',
  /** Существующий файл */
  EXIST = 'exist'
}

export enum PieceFileType {
  /** Общий вид */
  GENERAL = 'general',
  /** Не типизированный файл */
  PHOTO = 'photo',
  /** PDF-документ */
  PDF = 'pdf',
}

export type PieceFile = {
  /** GUID файла */
  guid: string;
  /** Имя файла */
  name: string;
  /** Тип файла */
  type: PieceFileType
  /** Состояние файла. При получении осмотров с сервера у всех exist, остальные только в процессе создания и корректировки осмотра */
  mode: PieceFileMode;
  /** Суррогатное поле, храним в нем dataUrl загруженной картинки. Не должен уходить на сервер */
  __dataUrl: string;
  /** Суррогатное поле, храним в нем бинарную строку для последующего восстановления из нее файла */
  __binaryStr?: string;
  /** Суррогатное поле, храним в нем имя для последующего восстановления файла */
  __name?: string;
  /** Суррогатное поле, храним в нем mime-тип для последующего восстановления файла */
  __mime?: string;
}

export type PieceWeightUnit = {
  /** ИД единицы измерения */
  id: number;
  /** Название единицы измерения */
  title: string;
  /** Название поля weight */
  fieldTitle: string;
  /** Hint поля weight */
  fieldHint: string;
}

export enum PieceMode {
  /** Новая УЕ */
  NEW = 'new',
  /** Откорректированная УЕ */
  EDIT = 'edit',
  /** Удаленная УЕ */
  DEL = 'del',
  /** Существующая УЕ */
  EXIST = 'exist'
}

export type Piece = {
  /** Guid УЕ */
  guid: string;
  /** Идентификационный номер, м.б. null */
  qmetId: Nullable<number>;
  /** Наименование УЕ, м.б. null */
  piece: Nullable<string>;
  /** Плавка, м.б. null */
  heat: Nullable<string>;
  /** 
   * true - ввод УЕ плавка + лист, false - ввод идентификационного номера. 
   * Нужно, чтобы открыть осмотр на корректировку или просмотр, т.к. посде получения данных с сервера 
   * уже заполнены все поля и не понять, как вводили УЕ изначально 
   */
  isPiece: boolean;
  /** Идентификатор из QMET */
  pieceNumId: Nullable<number>;
  /** Партия аттестации, м.б. null */
  groupAtt: Nullable<string>;
  /** Номер сертификата качества введенный вручную для идентификации УЕ, м.б. null */
  qcNum: Nullable<string>;
  /** Вес брака, кг, м.б. null */
  weight: Nullable<number>;
  /** Единица измерения количества брака */
  unit: PieceWeightUnit;
  /** Вес до переработки, м.б. null */
  weightBeforeProcessing: Nullable<number>;
  /** Вес годного после переработки, м.б. null */
  weightAfterProcessing: Nullable<number>;
  /** Доля брака в %, м.б. null */
  marriageShare: Nullable<number>;
  /** Детальное описание */
  note: Nullable<string>;
  /** Топография */
  topography: PieceTopography;
  /** Данные Qmet */
  qmetData: PieceQmetData;
  /** Дополнительные поля для клиента, м.б. пустым. Если поле не заполнено - его значение тут не передаем. */
  fields: Array<PieceField>;
  /** Список файлов, м.б. пуст в черновике */
  files: Array<PieceFile>;
  /** true - результат порезки сервисного металлоцентра. Нужно, чтобы закрыть возможность скачивания сертификата */
  isSmc: boolean;
  /** Состояние УЕ */
  mode: PieceMode;
}

export enum DefectMode {
  /** Новый дефект */
  NEW = 'new',
  /** Откорректированный дефект */
  EDIT = 'edit',
  /** Удаленный дефект */
  DEL = 'del',
  /** Существующий дефект */
  EXIST = 'exist'
}

export enum DefectFileType {
  /** документы для подтверждения дополнительных затрат */
  ADDITIONAL_COSTS = 'additionalCosts',
  /** акт совместной инспекции */
  ACT_JOIN_INSP = 'actJoinInsp',
}

export enum DefectFileMode {
  /** Новый файл */
  NEW = 'new',
  /** Удаленный файл */
  DEL = 'del',
  /** Существующий файл */
  EXIST = 'exist'
}

export type DefectFile = {
  /** GUID файла */
  guid: string;
  /** Имя файла */
  name: string;
  /** Тип файла */
  type: DefectFileType;
  /** Состояние файла. При получении осмотров с сервера у всех exist, остальные только в процессе создания и корректировки осмотра */
  mode: DefectFileMode;
  /** Суррогатное поле, храним в нем dataUrl загруженной картинки. Не должен уходить на сервер */
  __dataUrl: string;
  /** Суррогатное поле, храним в нем бинарную строку для последующего восстановления из нее файла */
  __binaryStr?: string;
  /** Суррогатное поле, храним в нем имя для последующего восстановления файла */
  __name?: string;
  /** Суррогатное поле, храним в нем mime-тип для последующего восстановления файла */
  __mime?: string;
}

export type Defect = {
  /**
   * GUID осмотра (тут не может быть идентификаторов групповых осмотров). 
   * В групповом осмотре inspection.guid != inspectionDefect.guid, даже если в inspectionDefects только одна запись! 
   * В не групповом они всегда равны!!!
   */
  guid: string;
  /** timestamp заведения дефекта */
  timestampDefect: number;
  /** ИД дефекта, м.б. null - во входном учете всегда null */
  defectId: Nullable<number>;
  /** Название дефекта, м.б. null - во входном учете всегда null */
  defectTitle: Nullable<string>;
  /** ИД приоритета, м.б. null - во входном учете всегда null */
  priorityId: Nullable<number>;
  /** Название приоритета, м.б. null - во входном учете всегда null */
  priorityTitle: Nullable<string>;
  /** ИД места фиксации дефекта, м.б. null - во входном учете всегда null */
  placeOfFixationId: Nullable<number>;
  /** Название места фиксации дефекта, м.б. null - во входном учете всегда null */
  placeOfFixationTitle: Nullable<string>;
  /** Название изделия, м.б. null - во входном учете всегда null */
  product: Nullable<string>;
  /** Детальное описание (прочие комментарии), м.б. null - во входном учете всегда null */
  note: Nullable<string>;
  /** true - Имеется нарушение требований стандарта и заказа */
  isViolationOfRequirements: boolean;
  /** Какое требование нарушено (если isViolationOfRequirements = true), м.б. null - во входном учете всегда null */
  violatedRequirements: Nullable<string>;
  /** true - Возможно использование забракованного проката */
  isPossibilityUsingRejected: boolean;
  /** ИД способа использования забракованного проката (если isPossibilityUsingRejected = true), м.б. null - во входном учете всегда null */
  wayToUseRegectedId: Nullable<number>;
  /** Название способа использования забракованного проката, м.б. null - во входном учете всегда null */
  wayToUseRegectedTitle: Nullable<string>;
  /** Общий по всем УЕ вес брака в тоннах. Нужно для отображения на странице просмотра осмотра. */
  cntMarriage: number;
  /** true - осмотр отправлен на почту */
  isSend: boolean;
  /** Номер акта совместной инспекции, м.б. null */
  actJoinInsp: Nullable<string>;
  /** Дата акта совместной инспекции (timestamp), м.б. null */
  timestampJoinInsp: Nullable<number>;
  /** Список файлов, м.б. пуст */
  files: DefectFile[];
  /** Внешний номер обращения */
  numExternal: Nullable<string>;
  /** Список УЕ, м.б. пуст в черновике */
  pieces: Nullable<Array<Piece>>;
  /** Рекламация */
  complaint: Nullable<Complaint>;
  /** Состояние дефекта */
  mode: DefectMode;
  /** Фиктивные поля для внутреннего использования */
  __isJoinInspection: boolean;
}

export enum QMMsgStatusCode {
  /** Нет описания */
  ZERO = '0',
  /** Нет описания */
  ONE = '1',
  /** Нет описания */
  TWO = '2',
  /** Нет описания */
  THREE = '3',
  /** Нет описания */
  FIVE = '5',
  /** Нет описания */
  NONE = 'NONE',
  /** Нет описания */
  ACCEPTED = 'ACCEPTED',
  /** Нет описания */
  CONSIDERATION = 'CONSIDERATION',
  /** Нет описания */
  MANY = 'MANY',
}

export enum QMMsgAction {
  /** Подтверждено */
  APPROVED = 'approved',
  /** возвращено на доработку */
  COMEBACK = 'comeback',
}

export type QMMsgItem = {
  /** ИД претензии/уведомления */
  id: number;
  /** Номер претензии/уведомления */
  num: string;
  /** Тип: claim - претензия, notification - уведомление */
  type: string;
  /** Код статуса */
  code: QMMsgStatusCode;
  /** Название статуса по претензии/уведомления */
  title: string;
  /** Дата регистрации претензии/уведомления */
  timestampReg: number;
  /** Действие пользователя */
  action: Nullable<QMMsgAction>;
}

export enum InspectionType {
  /** Замечание */
  REMARK = 'remark',
  /** Претензия */
  CLAIM = 'claim',
  /** Уведомление */
  NOTIFICATION = 'notification',
}

export type Inspection = {
  /**
   * GUID осмотра (для групповго осмотра - guid группового осмотра, иначе guid осмотра). 
   * В групповом осмотре inspection.guid != inspectionDefect.guid, даже если в inspectionDefects только одна запись!
   * В не групповом они всегда равны!!!
   */
  guid: string;
  /** Номер осмотра (На отображение для группового осмотра идет Г12345, а для не группового просто число). */
  inspectionNum: string;
  /** Тип осмотра: remark - замечание, claim - претензия, notification - уведомление */
  inspectionType: Nullable<InspectionType>;
  /** true - групповой осмотр */
  isGroup: boolean;
  /** true - входной учет */
  isEntryAccounting: boolean;
  /** timestamp осмотра */
  timestampInspection: number;
  /** ИД регистрации пользователя Checksteel, зарегистрировавшего осмотр */
  userId: number;
  /** ФИО регистрации пользователя Checksteel, зарегистрировавшего осмотр */
  userFio: string;
  /** timestamp создания осмотра */
  timestampCreated: number;
  /** timestamp последнего изменения осмотра */
  timestampModified: number;
  /** Статус осмотра */
  status: InspectionStatus;
  /** Дефекты */
  inspectionDefects: Array<Defect>;
  /** Статус по претензии */
  qmmsgs: QMMsgItem[];
  /** Далее идут свойста, которые добавлены для внутреннего пользования, с сервера не приходят и на сервер не отправляются */
  __isTrans: boolean;
  __isCompleted: boolean;
}

export type SaveInspectionDTO = {
  /** GUID осмотра (guid_inspection) (для групповго осмотра - guid группового осмотра, иначе guid осмотра). В групповом осмотре inspection.guid != inspectionDefect.guid, даже если в inspectionDefects только одна запись! В не групповом они всегда равны!!! */
  guid: string;
  /** true - групповой осмотр */
  isGroup: boolean;
  /** true - входной учет */
  isEntryAccounting: boolean;
  /** timestamp осмотра */
  timestampInspection: number;
  /** ИД регистрации пользователя Checksteel, зарегистрировавшего осмотр */
  userId: number;
  /** timestamp последнего изменения осмотра */
  timestampModified: number;
  /** true - осмотр завершен */
  isCompleted: boolean;
  /** Дефекты */
  inspectionDefects: {
    /** GUID осмотра(тут не может быть идентификаторов групповых осмотров). В групповом осмотре inspection.guid != inspectionDefect.guid, даже если в inspectionDefects только одна запись! В не групповом они всегда равны!!! */
    guid: string;
    /** timestamp заведения дефекта */
    timestampDefect: number;
    /** ИД дефекта */
    defectId: Nullable<number>;
    /** ИД приоритета */
    priorityId: Nullable<number>;
    /** ИД места фиксации дефекта */
    placeOfFixationId: Nullable<number>;
    /** Название изделия */
    product: Nullable<string>;
    /** Детальное описание */
    note: Nullable<string>;
    /** true - Имеется нарушение требований стандарта и заказа */
    isViolationOfRequirements: boolean;
    /** Какое требование нарушено */
    violatedRequirements: Nullable<string>;
    /** true - Возможно использование забракованного проката */
    isPossibilityUsingRejected: boolean;
    /** ИД способа использования забракованного проката */
    wayToUseRegectedId: Nullable<number>;
    /** Номер акта совместной инспекции, м.б. null */
    actJoinInsp: Nullable<string>;
    /** timestamp акта совместной инспекции, м.б. null */
    timestampJoinInsp: Nullable<number>;
    /** Внешний номер обращения */
    numExternal: Nullable<string>;
    /** Список УЕ */
    pieces: {
      /** guid УЕ */
      guid: string;
      /** Идентификационный номер */
      qmetId: Nullable<number>;
      /** Наименование УЕ */
      piece: Nullable<string>;
      /** Плавка */
      heat: Nullable<string>;
      /** true - ввод УЕ плавка + лист, false - ввод идентификационного номера */
      isPiece: boolean;
      /** Вес брака - кг, т, шт */
      weight: Nullable<number>;
      /** Единица измерения */
      unitId: number;
      /** Вес до переработки, м.б. null */
      weightBeforeProcessing: Nullable<number>;
      /** Вес годного после переработки, м.б. null */
      weightAfterProcessing: Nullable<number>;
      /** Доля брака в %, м.б. null */
      marriageShare: Nullable<number>;
      /** Детальное описание */
      note: Nullable<string>;
      /** Партия аттестации */
      groupAtt: Nullable<string>;
      /** Номер сертификата качества введенный вручную для идентификации */
      qcNum: Nullable<string>;
      /** Топография */
      topography: Nullable<{
        /** Размер дефекта */
        defectSize: Nullable<number>;
        /** ИД расположения топографии дефекта */
        defectTpgIdSize: Nullable<number>;
        /** Шаг дефекта */
        defectStep: Nullable<number>;
        /** ИД расположения топографии дефекта */
        defectTpgIdStep: Nullable<number>;
        /** Расстояние от кромки, мм */
        defectDistanceFromEdge: Nullable<number>;
        /** ИД расположения топографии дефекта */
        defectTpgIdDistance: Nullable<number>;
      }>;
      /** Дополнительные поля для клиента */
      fields: {
        /** ИД поля */
        id: number;
        /** Значение поля */
        value: string;
      }[];
      /** Список файлов */
      files: {
        /** Guid файла */
        guid: string;
        /** Название файла */
        name: string;
        /** Тип файла - general, photo или pdf */
        type: string;
        /** Режим файла */
        mode: string;
      }[];
      /** состояние УЕ: new - новая УЕ, edit - откорректированная УЕ, del - удаленная УЕ, exist - существующая УЕ */
      mode: string;
    }[];
    /** Список файлов */
    files: {
      /** Guid файла */
      guid: string;
      /** Название файла */
      name: string;
      /** Тип файла - additionalCosts, actJoinInsp */
      type: string;
      /** Режим файла */
      mode: string;
    }[];
    complaint: Nullable<{
      /** Обращение: complaint - рекламация, remark - замечание */
      typeComplaint: string;
      /** Уценить металлопрокат до цены лома */
      isToScrap: Nullable<boolean>;
      /** Цена лома, м.б. null */
      scrapPrice: Nullable<number>;
      /** Уценить металлопрокат */
      isEvaluateRolledMetal: Nullable<boolean>;
      /** Требование к уценке */
      requirementRolledMetall: Nullable<string>;
      /** Компенсировать дополнительные затраты */
      isToOffsetCosts: Nullable<boolean>;
      /** Сумма компенсации (без НДС), м.б. null */
      amountOfCompensation: Nullable<number>;
      /** Осуществить возврат металлопроката */
      isReturnOfRolledMetal: Nullable<boolean>;
      /** Предоставить скидку на забракованную партию проката в размере */
      isSale: Nullable<boolean>;
      /** Размер скидки (%) */
      sale: Nullable<number>;
      /** Направить представителя */
      isRepresentative: Nullable<boolean>;
      /** Предоставить оперативный ответ по действиям с забракованным металлопрокатом или возможности переработки проката */
      isProvideResponse: Nullable<boolean>;
      /** Определить потенциально опасные партии проката по указанному несоответствию */
      isIdentifyDangerous: Nullable<boolean>;
      /** Провести анализ проблем с целью выяснения причины дефекта */
      isAnalizProblem: Nullable<boolean>;
      /** Предоставить отчет в формате 8D */
      is8d: Nullable<boolean>;
      /** Другие требования */
      otherReq: Nullable<string>;
      /** Переработанные партии без дефектов */
      groupRevised: Nullable<string>;
      /** Переработанные партии без дефектов (Объем (т)) */
      groupRevisedValue: Nullable<number>;
      /** Не переработанные партии у клиента на складе (номера) */
      groupUnrevised: Nullable<string>;
      /** Не переработанные партии у клиента на складе (Объем (т)) */
      groupUnrevisedValue: Nullable<number>;
      /** ФИО */
      fio: Nullable<string>;
      /** Организация */
      enterprise: Nullable<string>;
      /** Телефон */
      phone: Nullable<string>;
      /** Email */
      email: Nullable<string>;
      /** Немедленно передать на Северсталь внутреннюю проработку после проверки осмотра */
      isTransferImmediately: Nullable<boolean>;
      /** Состояние замечания/рекламации: new - новое, edit - откорректированное, del - удаленное, exist - существующее */
      mode: string;
    }>;
    /** Состояние дефекта: new - новый дефект, edit - откорректированный дефект, del - удаленный дефект, exist - существующий дефект (mode). При получении осмотров с сервера у всех exist, остальные только в процессе создания и корректировки осмотра */
    mode: string;
  }[];
}

// #endregion


// #region GET inspection/list

export type GetInspectionListIn = {
  /** Наименования фильтров через запятую */
  filterNames?: string;
  /** Значения фильтров через запятую */
  filterValues?: string;
  /** Отчет */
  isReport?: boolean;
  /** Ставить в очередь и отправлять с крона */
  isSend?: boolean;
  /** Сколько значений пропустить */
  skip?: number;
  /** Усли нужно получить ссылку на отчет */
  giveLinkForDownload?: boolean;
  /** Сколько значений взять */
  count?: number;
}

export type GetInspectionListOut = Array<Inspection>;

export type GetInspectionListAsReportLinkOut = {
  /** Статус-код */
  statusCode: number;
  /** Сообщение */
  message: string;
  /** Ссылка на скачивание файла */
  location: string;
  /** Приблизительное время в ms через сколько будет готов отчет */
  retryAfter: number;
}

export const prepareGetInspectionList
  : PrepareCallHandler<GetInspectionListIn, GetInspectionListOut | GetInspectionListAsReportLinkOut> = (arg: GetInspectionListIn) => {
    const params = {
      ...(arg.filterNames !== undefined ? { filterNames: arg.filterNames } : { }),
      ...(arg.filterValues !== undefined ? { filterValues: arg.filterValues } : { }),
      ...(arg.isReport !== undefined ? { isReport: arg.isReport ? 'true' : 'false' } : { }),
      ...(arg.isSend !== undefined ? { isSend: arg.isSend ? 'true' : 'false' } : { }),
      ...(arg.giveLinkForDownload !== undefined ? { giveLinkForDownload: arg.giveLinkForDownload ? 'true' : 'false' } : { }),
      ...(arg.skip !== undefined ? { skip: arg.skip } : { }),
      ...(arg.count !== undefined ? { count: arg.count } : { }),
    }

    return {
      config: {
        url: `api/mobile/${Constants.API_VERSION}/inspection/list`,
        method: 'GET',
        params,
        responseType: 'json',

        pwaLocked: 'access',
        pwaRetry: false,
      },
    };
}

// #endregion


// #region GET inspection/{guid}

export type GetInspectionByGuidIn = {
  /** Guid осмотра */
  guid: string;
}

export const prepareGetInspectionByGuid
  : PrepareCallHandler<GetInspectionByGuidIn, Inspection> = (arg: GetInspectionByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/${arg.guid}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region DELETE inspection/{guid}

export type DeleteInspectionByGuidIn = {
  /** GUID осмотра */
  guid: string;
}

export const prepareDeleteInspectionByGuid
  : PrepareCallHandler<DeleteInspectionByGuidIn, void> = (arg: DeleteInspectionByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/${arg.guid}`,
      method: 'DELETE',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/status

export type PostInspectionStatusIn = {
  /** GUID осмотра */
  guid: string;
  /** Код статуса осмотра */
  statusCode: InspectionStatusCode.COMPLETED | InspectionStatusCode.RETURN_FOR_REVISION | InspectionStatusCode.AWAITING_REVIEW;
  /** Комментарий, если completed, то null, если returnForRevision, то обязателен */
  statusNote: Nullable<string>;
}

export const preparePostInspectionStatus
  : PrepareCallHandler<PostInspectionStatusIn, void> = (arg: PostInspectionStatusIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/status`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/complete

export type PostInspectionCompleteIn = {
  /** GUID осмотра */
  guid: string;
}

export const preparePostInspectionComplete
  : PrepareCallHandler<PostInspectionCompleteIn, void> = (arg: PostInspectionCompleteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/complete`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/return_for_revision

export type PostInspectionReturnForRevisionIn = {
  /** GUID осмотра */
  guid: string;
  /** Комментарий */
  note: string;
}

export const preparePostInspectionReturnForRevision
  : PrepareCallHandler<PostInspectionReturnForRevisionIn, void> = (arg: PostInspectionReturnForRevisionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/return_for_revision`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/transfer_to_severstal

export type PostInspectionTransferToSeverstalIn = {
  /** GUID осмотра */
  guid: string;
}

export const preparePostInspectionTransferToSeverstal
  : PrepareCallHandler<PostInspectionTransferToSeverstalIn, void> = (arg: PostInspectionTransferToSeverstalIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/transfer_to_severstal`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET inspection/file/{guid}

export type GetInspectionFileByGuidIn = {
  /** Guid файла */
  guid: string;
  /** Источник */
  source: 'piece' | 'inspection';
}

export type GetInspectionFileByGuidOut = {
  /** Blob файла */
  blob: Blob;
}

export const prepareGetInspectionFileByGuid
  : PrepareCallHandler<GetInspectionFileByGuidIn, GetInspectionFileByGuidOut> = (arg: GetInspectionFileByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/file/${arg.guid}?source=${arg.source}`,
      method: 'GET',
      responseType: 'blob',
      transformResponse: (data) => ({
        blob: data
      }),

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

export type GetInspectionFileAsPDFByGuidOut = {
  /** Data-url файла */
  dataUrl: string;
}

export const prepareGetInspectionFileAsPDFByGuid
  : PrepareCallHandler<GetInspectionFileByGuidIn, GetInspectionFileAsPDFByGuidOut> = (arg: GetInspectionFileByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/file/${arg.guid}?source=${arg.source}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (data) => {
        const typedBlob = new Blob([data], {type: 'application/pdf'});
        return ({
          dataUrl: window.URL.createObjectURL(typedBlob)
        })
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/file/{guid}

export type PostInspectionFileByGuidIn = FormData;

export const preparePostInspectionFileByGuid
  : PrepareCallHandler<PostInspectionFileByGuidIn, void> = (arg: PostInspectionFileByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/file/${arg.get('guid')}`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection

export type PostInspectionIn = SaveInspectionDTO;

export const preparePostInspection
  : PrepareCallHandler<PostInspectionIn, void> = (arg: PostInspectionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST inspection/duplicate/{guid}

export type PostInspectionDuplicateByGuidIn = {
  /** Guid осмотра */
  guid: string;
  /** true - входной учет */
  isEntryAccounting: boolean;
  /** Идентификационный номер, м.б. null */
  qmetId: Nullable<number>;
  /** номер плавки, м.б. null */
  heat: Nullable<string>;
  /** номер листа, м.б. null */
  piece: Nullable<string>;
  /** номер партии аттестации, м.б. null */
  groupAtt: Nullable<string>;
  /** номер сертификата качества, м.б. null */
  qcNum: Nullable<string>;
}

export type PostInspectionDuplicateByGuidOut = Array<Inspection>;

export const preparePostInspectionDuplicateByGuid
  : PrepareCallHandler<PostInspectionDuplicateByGuidIn, PostInspectionDuplicateByGuidOut> = (arg: PostInspectionDuplicateByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/duplicate/${arg.guid}`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
  });

// #endregion


// #region POST inspection/report/{guid}

export type PostInspectionReportByGuidIn = {
  /** Guid осмотра */
  guid: string;
}

export const preparePostInspectionReportByGuid
  : PrepareCallHandler<PostInspectionReportByGuidIn, void> = (arg: PostInspectionReportByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/report/${arg.guid}`,
      method: 'POST',

      pwaLocked: 'access',
      pwaRetry: false,
    },
  });

// #endregion


// #region GET inspection/request_report_as_excel/{guid}

export type GetInspectionRequestReportAsExcelByGuidIn = {
  /** Guid осмотра */
  guid: string;
}

export type GetInspectionRequestReportAsExcelByGuidOut = {
  /** Статус-код */
  statusCode: number;
  /** Сообщение */
  message: string;
  /** Ссылка на скачивание файла */
  location: string;
  /** Приблизительное время в ms через сколько будет готов отчет */
  retryAfter: number;
}

export const prepareGetInspectionRequestReportAsExcelByGuid
  : PrepareCallHandler<GetInspectionRequestReportAsExcelByGuidIn, GetInspectionRequestReportAsExcelByGuidOut> = (arg: GetInspectionRequestReportAsExcelByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/request_report_as_excel/${arg.guid}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
  });

// #endregion


// #region АПИ метод GET inspection/report_as_excel/{guid}

export type GetInspectionReportAsExcelByGuidIn = {
  /** guid аудита */
  guid: string;
};

// Функция вызова
export const prepareGetInspectionReportAsExcelByGuid
  : PrepareCallHandler<GetInspectionReportAsExcelByGuidIn, void> = (arg: GetInspectionReportAsExcelByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/report_as_excel/${arg.guid}`,
      method: 'GET',
      responseType: "blob",
      transformResponse: (data: Blob) => {
        if (data.type === 'application/octet-stream')
        {
          const typedBlob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const url = URL.createObjectURL(typedBlob);
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("href", url);
          downloadLink.setAttribute("download", `Осмотр ${arg.guid}`);
          downloadLink.click();
          downloadLink.remove();
        }
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})
// #endregion


// #region АПИ метод GET inspection/list/as_excel/{guid}
/** Объект запроса */
export type GetInspectionListAsExcelByGuidIn = {
  /** guid аудита */
  guid: string;
};

// Функция вызова
export const prepareGetInspectionListAsExcelByGuid
  : PrepareCallHandler<GetInspectionListAsExcelByGuidIn, void> = (arg: GetInspectionListAsExcelByGuidIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/list/as_excel/${arg.guid}`,
      method: 'GET',
      responseType: "blob",
      transformResponse: (data: Blob) => {
        if (data.type === 'application/octet-stream')
        {
          const typedBlob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const url = URL.createObjectURL(typedBlob);
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("href", url);
          downloadLink.setAttribute("download", `Список осмотров`);
          downloadLink.click();
          downloadLink.remove();
        }
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})
// #endregion


// #region GET inspection/history/request_as_excel_to_email/{guid}

export type GetInspectionHistoryRequestAsExcelToEmailIn = {
  /** Guid осмотра */
  guid: string;
}

export const prepareGetInspectionHistoryRequestAsExcelToEmail
  : PrepareCallHandler<GetInspectionHistoryRequestAsExcelToEmailIn, void> = (arg: GetInspectionHistoryRequestAsExcelToEmailIn) => {
    return {
      config: {
        url: `api/mobile/${Constants.API_VERSION}/inspection/history/request_as_excel_to_email/${arg.guid}`,
        method: 'GET',
        responseType: 'json',

        pwaLocked: 'access',
        pwaRetry: false,
      },
    };
}

// #endregion


// #region GET inspection/history/request_as_excel/{guid}

export type GetInspectionHistoryRequestAsExcelIn = {
  /** Guid осмотра */
  guid: string;
}

export type GetInspectionHistoryRequestAsExcelOut = {
  /** Статус-код */
  statusCode: number;
  /** Сообщение */
  message: string;
  /** Ссылка на скачивание файла */
  location: string;
  /** Приблизительное время в ms через сколько будет готов отчет */
  retryAfter: number;
}

export const prepareGetInspectionHistoryRequestAsExcel
  : PrepareCallHandler<GetInspectionHistoryRequestAsExcelIn, GetInspectionHistoryRequestAsExcelOut> = (arg: GetInspectionHistoryRequestAsExcelIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/history/request_as_excel/${arg.guid}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region АПИ метод GET inspection/history/report_as_excel/{guid}

export type GetInspectionHistoryReportAsExcelIn = {
  /** guid файла отчета */
  guid: string;
};

// Функция вызова
export const prepareGetInspectionHistoryReportAsExcel
  : PrepareCallHandler<GetInspectionHistoryReportAsExcelIn, void> = (arg: GetInspectionHistoryReportAsExcelIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/history/report_as_excel/${arg.guid}`,
      method: 'GET',
      responseType: "blob",
      transformResponse: (data: Blob) => {
        if (data.type === 'application/octet-stream')
        {
          const typedBlob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const url = URL.createObjectURL(typedBlob);
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("href", url);
          downloadLink.setAttribute("download", `История изменений ${arg.guid}`);
          downloadLink.click();
          downloadLink.remove();
        }
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})
// #endregion

// #region POST inspection/revocation

export type PostInspectionRevocationIn = {
  /** Guid осмотра */
  guid: string;
  /** Причина отзыва обращения */
  revocationNote: string;
}

export const preparePostInspectionRevocation
  : PrepareCallHandler<PostInspectionRevocationIn, void> = (arg: PostInspectionRevocationIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/inspection/revocation`,
      method: 'POST',
      data: arg,

      pwaLocked: 'access',
      pwaRetry: false,
    },
  });

// #endregion
