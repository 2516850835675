import * as React                        from 'react';


import { PieceNumIdHelpEx1Vector       } from 'src/components/vector/art/piecenumid-help-ex1';
import { PieceNumIdHelpEx21Vector      } from 'src/components/vector/art/piecenumid-help-ex2-1';
import { PieceNumIdHelpEx22Vector      } from 'src/components/vector/art/piecenumid-help-ex2-2';
import { PieceNumIdHelpEx3Vector       } from 'src/components/vector/art/piecenumid-help-ex3';

import { TabSwitch,
         TabItem                       } from 'src/components/common/tab-switch';
import { Dialog                        } from 'src/components/common/dialog';
import { IconButton                    } from 'src/components/common/icon-button';

import { Container,
         Caption,
         Message,
         Separator,
         Close,
         CloseOutlineVectorBig,
         PieceNumIdHelpDialogVariants,
         piecenumidhelpdialog_variants } from 'src/components/features/inspection/edit/piecenumid-help-dialog/ui';



const tabs: TabItem[] = [{ id: 'ex1', label: 'Пример 1' }, {id: 'ex2', label: 'Пример 2'}, {id: 'ex3', label: 'Пример 3'}];

type Props = {
  variant: PieceNumIdHelpDialogVariants;
  opened: boolean;
  onClose: () => void;
}
export const PieceNumIdHelpDialog: React.FC<Props> = ({
  variant,
  opened,
  onClose,
}) => {
  const lookAndFeel = piecenumidhelpdialog_variants.get(variant)!;
  const [tab, setTab] = React.useState<TabItem>(tabs[0]);

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Close lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.closeButtonVariant }
            isTapAllowed
            onTap = { onClose }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </Close>
        <Caption lookAndFeel = { lookAndFeel }>
          Подсказка
        </Caption>
        { tab.id === 'ex1' && (
          <Message lookAndFeel = { lookAndFeel }>
            Для корректного создания осмотра, необходимо вводить/сканировать Data Matrix код, либо штрих-код указанные на информационной наклейке изделия
          </Message>
        )}
        { tab.id === 'ex2' && (
          <Message lookAndFeel = { lookAndFeel }>
            Для корректного создания осмотра после СМЦ, необходимо сканировать/вводить штрих-код указанный на информационной наклейке на изделии
          </Message>
        )}
        { tab.id === 'ex3' && (
          <Message lookAndFeel = { lookAndFeel }>
            Для корректного создания осмотра после СМЦ, необходимо вводить №&nbsp;партии указанный в упаковочном листе
          </Message>
        )}
        <TabSwitch variant = { lookAndFeel.switchExampleVariant } items = { tabs } value = { tab } onChange = { (value) => setTab(value) } />
        { tab.id === 'ex1' && (
          <PieceNumIdHelpEx1Vector />
        )}
        { tab.id === 'ex2' && (
          <React.Fragment>
            <PieceNumIdHelpEx21Vector />
            <Separator lookAndFeel = { lookAndFeel } />
            <PieceNumIdHelpEx22Vector />
          </React.Fragment>
        )}
        { tab.id === 'ex3' && (
          <PieceNumIdHelpEx3Vector />
        )}
       </Container>
    </Dialog>
  );
};
