import * as React        from 'react';

import { Typography    } from 'src/components/common/typography';
import { Link          } from 'src/components/common/link';

import { Container,
         NoteVariants,
         note_variants } from 'src/components/features/qmmsg/select-company/note/ui';



type Props = {
  variant: NoteVariants;
  fio: string;
}
export const Note: React.FC<Props> = ({
  variant,
  fio,
}) => {
  const lookAndFeel = note_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.topograhyVariant }>
        Для добавления предприятий<br/> клиента отправьте запрос на
      </Typography>
      <Link
        variant = { lookAndFeel.linkVariant }
        label = 'ukpp@severstal.com'
        isCentered
        url = {`mailto:ukpp@severstal.com?subject=Требуется добавить клиентов сотруднику СТПК&body=Требуется сотруднику СТПК ${fio} добавить клиентов:`}
      />
    </Container>
  );
};
