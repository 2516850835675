import styled           from 'styled-components';

import { themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type InputMultilineLookAndFeel = {
  typo: Typo;
  normalColor: string;
  disabledColor: string;

  paddings: number[];
};

const inputmultiline_light: InputMultilineLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH22,
  normalColor: themeColors.PrimaryBlack,
  disabledColor: themeColors.SecondaryGray4,

  paddings: [8, 16, 8, 0],
};

export type InputMultilineVariants = 'light';

export const inputmultiline_variants: Map<InputMultilineVariants, InputMultilineLookAndFeel> = 
  new Map<InputMultilineVariants, InputMultilineLookAndFeel>([
    ['light', inputmultiline_light],
  ]);

export const TextareaContainer = styled.div<{
  isDisabled: boolean;
  lookAndFeel: InputMultilineLookAndFeel;
}>`
  width: 100%;

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;

  & textarea {
    width: 100%;

    ${(args) => typoAsCSS(args.lookAndFeel.typo)}

    padding: 0;
    resize: none;

    color: ${(args) => args.isDisabled ? args.lookAndFeel.disabledColor : args.lookAndFeel.normalColor};
  }
`;
