import * as React                   from 'react';

import { MessageStatusSendVector  } from 'src/components/vector/message-status/message-status-send';
import { MessageStatusErrorVector } from 'src/components/vector/message-status/message-status-error';

import { Container,
         HeaderRow,
         HeaderRowFIO,
         HeaderRowDateStatus,
         MessageRow,
         HistoryItemVariants,
         historyitem_variants     } from 'src/components/features/qmmsg/question-history/history-item/ui';

import * as types                   from 'src/services/api/types';
import Util                         from 'src/services/util';



type Props = {
  variant: HistoryItemVariants;
  item: types.qmmsg.QMMsgQuestion;
}
export const HistoryItem: React.FC<Props> = ({
  variant,
  item,
}) => {
  const lookAndFeel = historyitem_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <HeaderRow lookAndFeel = { lookAndFeel }>
        <HeaderRowFIO lookAndFeel = { lookAndFeel }>{ `${item.user.fio}` }</HeaderRowFIO>
        <HeaderRowDateStatus lookAndFeel = { lookAndFeel } isSend = { item.isSend }>
          { `${new Date(item.timestamp).toLocaleDateString()} ${Util.timeHHMM(new Date(item.timestamp))} ` }
          { item.isSend ? <MessageStatusSendVector /> : <MessageStatusErrorVector /> }
        </HeaderRowDateStatus>
      </HeaderRow>
      <MessageRow lookAndFeel = { lookAndFeel }>
        { item.question }
      </MessageRow>
    </Container>
  );
};
