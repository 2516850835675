import * as React                   from 'react';

import { Button                   } from 'src/components/common/button';
import { Dialog                   } from 'src/components/common/dialog';
import { VGap                     } from 'src/components/common/flex/vgap';
import { Container,
         Caption,
         Message,
         Actions,
         TwoActionDialogVariants,
         twoactiondialog_variants } from 'src/components/common/two-action-dialog/ui';



type Props = {
  variant: TwoActionDialogVariants;
  opened: boolean;
  caption: string;
  message?: string;
  isDanger?: boolean;
  actionLabel?: string;
  cancelLabel?: string;
  withoutCancel?: boolean;
  onAction: () => void;
  onCancel?: () => void;
}
export const TwoActionDialog: React.FC<Props> = ({
  variant,
  opened,
  caption,
  message,
  isDanger = false,
  actionLabel = 'Ок',
  cancelLabel = 'Отмена',
  withoutCancel = false,
  onAction,
  onCancel = () => { },
}) => {
  const lookAndFeel = twoactiondialog_variants.get(variant)!;

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel } isDanger = { isDanger }>
          { caption }
        </Caption>
        { message !== undefined && (
          <React.Fragment>
            <VGap size = { lookAndFeel.captionMessageGap } />
            <Message lookAndFeel = { lookAndFeel }>
              { message }
            </Message>
          </React.Fragment>
        )}
        <VGap size = { lookAndFeel.messageActionGap } />
        <Actions lookAndFeel = { lookAndFeel }>
          {!withoutCancel && (
            <Button
              variant = { lookAndFeel.cancelButtonVariant }
              label = { cancelLabel }
              onTap = { onCancel }
            />
          )}
          <Button
            variant = { lookAndFeel.actionButtonVariant }
            label = { actionLabel }
            onTap = { onAction }
          />
        </Actions>
      </Container>
    </Dialog>
  );
};
