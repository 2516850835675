import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export enum NotificationCode {
  ALL = 'ALL',
  NEW_INSPECTION = 'NEW_INSPECTION',
  CHANGE_STATUS_INSPECTION = 'CHANGE_STATUS_INSPECTION',
  ERROR_INSPECTION = 'ERROR_INSPECTION',
  CHANGE_STEP_8D = 'CHANGE_STEP_8D',
  READ_MESSAGE = 'READ_MESSAGE',
  CHANGE_STEP_QMMSG = 'CHANGE_STEP_QMMSG',
  SURVEY = 'SURVEY',
}

export type Notification = {
  /** ИД строки */
  id: number;
  /** Код */
  code: NotificationCode;
  /** Заголовок */
  topic: string;
  /** Текст */
  text: string;
  /** timestamp */
  timestamp: number;
  /** true - прочитано */
  isRead: boolean;
  /** ИД для ссылки на данные, м.б. null */
  idSource: Nullable<number>;
  /** Guid для ссылки на данные, м.б. null */
  guidSource: Nullable<string>;
}

// #endregion


// #region GET notification/notifications

export type GetNotificationNotificationsIn = void;

export type GetNotificationNotificationsOut = Array<Notification>;

export const prepareGetNotificationNotifications
  : PrepareCallHandler<GetNotificationNotificationsIn, GetNotificationNotificationsOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/notification/notifications`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
  })

// #endregion


// #region POST notification/del_notifications

export type PostNotificationDelNotificationsIn = void;

export const preparePostNotificationDelNotifications
  : PrepareCallHandler<PostNotificationDelNotificationsIn, void> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/notification/del_notifications`,
      method: 'POST',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST notification/read_notifications/{timestamp}

export type PostNotificationReadNotificationsIn = {
  /** Дата и время, с которых устанавливать пометку. Нужно, чтобы не прочитать сообщения, которые уже получены, а на экран еще не успели прийти */
  timestamp: number;
}

export const preparePostNotificationReadNotifications
  : PrepareCallHandler<PostNotificationReadNotificationsIn, void> = (arg: PostNotificationReadNotificationsIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/notification/read_notifications/${arg.timestamp}`,
      method: 'POST',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
