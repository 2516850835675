import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export type DefectReferElement = {
  /** ИД дефекта */
  id: number;
  /** Название дефекта */
  title: string;
  /** Ид родительской строки */
  parentId: number;
  /** true - строка группировки, false - сам дефект */
  isGroup: boolean;
  /** true - избранный */
  isFavorite: boolean;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type PlaceOfFixationReferElement = {
  /** ИД места фиксации дефекта */
  id: number;
  /** Название */
  title: string;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type WaysToUseRegectedReferElement = {
  /** ИД способа использования забракованного проката */
  id: number;
  /** Название */
  title: string;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type ProductReferElement = {
  /** ИД изделия */
  id: number;
  /** Название */
  title: string;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type DefectTopographyReferElement = {
  /** ИД расположения топографии дефекта */
  id: number;
  /** Название */
  title: string;
  /** тип выпадающего списка:  */
  typeTopography: 'size' | 'step' | 'distance';
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type PriorityReferElement = {
  /** ИД приоритета */
  id: number;
  /** Название */
  title: string;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
}

export type StepQMReferElement = {
  /** ИД шага */
  id: number;
  /** Код шага */
  code: string;
  /** Дополнительный код шага, м.б. null */
  subcode: Nullable<string>;
  /** Наименование шага */
  title: string;
  /** Описание шага */
  description: string;
}

export type StatusQMReferElement = {
  /** ИД статуса */
  id: number;
  /** Наименование статуса */
  title: string;
}

export type TypeQMReferElement = {
  /** Код типа обращения */
  code: string;
  /** Наименование статуса */
  title: string;
}

export type UnitDetail = {
  /** ИД детализации */
  id: number;
  /** Название поля */
  fieldTitle: string;
  /** Название хинта в поле */
  fieldHint: string;
  /** Входной учет */
  isEntryAccounting: boolean;
}

export type UnitReferElement = {
  /** ИД единицы измерения */
  id: number;
  /** Название */
  title: string;
  /** true - запись актуальна (в окне выбора показывать только актуальные записи) */
  isActual: boolean;
  /** Можно вводить только целочисленные значения */
  isInteger: boolean;
  /** Детализация единицы измерения */
  details: UnitDetail[];
}

export type FilialElement = {
  /** ИД филиала */
  id: number;
  /** Название филиала */
  title: string;
}

export type ReferElement = {
  /** ИД справочника */
  referId: number;
  /** ИД строки справочника */
  id: number;
  /** Название строки справочника */
  title: string;
}

// #endregion


// #region GET refers/hash

export type GetRefersHashIn = void;

export type GetRefersHashOut = {
  /** HASH справочников (hash) */
  hash: string;
}

export const prepareGetRefersHash
  : PrepareCallHandler<GetRefersHashIn, GetRefersHashOut> = () => {
    return {
      config: {
        url: `api/mobile/${Constants.API_VERSION}/refers/hash`,
        method: 'GET',
        responseType: 'json',

        pwaLocked: 'access',
        pwaRetry: false,
      },
    };
}

// #endregion


// #region GET refers

export type GetRefersIn = void;

export type GetRefersOut = {
  defects: DefectReferElement[];
  placeOfFixations: PlaceOfFixationReferElement[];
  waysToUseRegected: WaysToUseRegectedReferElement[];
  products: ProductReferElement[];
  defectTopographies: DefectTopographyReferElement[];
  priorities: PriorityReferElement[];
  stepsQmMsg: StepQMReferElement[];
  statusesQmMsg: StatusQMReferElement[];
  typesQmMsg: TypeQMReferElement[];
  units: UnitReferElement[];
  filials: FilialElement[];
  refers: ReferElement[];
}

export const prepareGetRefers
  : PrepareCallHandler<GetRefersIn, GetRefersOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/refers`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST refers/defect_favorite

export type PostRefersDefectFavoriteIn = {
  /** ИД дефекта */
  defectId: number;
  /** true - избранный */
  isFavorite: boolean;
}

export const preparePostRefersDefectFavorite
  : PrepareCallHandler<PostRefersDefectFavoriteIn, void> = (arg: PostRefersDefectFavoriteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/refers/defect_favorite`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
