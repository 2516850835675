import * as React           from 'react';

import { Container,
         DividerVariants,
         divider_variants } from 'src/components/features/auth/reg-enter-data/divider/ui';



type Props = {
  variant: DividerVariants;
}
export const Divider: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = divider_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } />
  );
};
