import styled          from 'styled-components';

import { themeColors } from 'src/providers/theme/shared';



type DividerLookAndFeel = {
  color: string;
}

const divider_light: DividerLookAndFeel = {
  color: themeColors.SecondaryGray3,
}

export type DividerVariants = 'light';

export const divider_variants: Map<DividerVariants, DividerLookAndFeel> = 
  new Map<DividerVariants, DividerLookAndFeel>([
    ['light', divider_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DividerLookAndFeel,
}>`
  border-top: 1px solid ${(args) => args.lookAndFeel.color};
`
