import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type FilterTagsLookAndFeel = {
  typo: Typo;

  backgroundColor: string;
  labelColor: string;

  tagHeight: number;
  tagPaddings: number[];
  gap: number;
  iconSize: number;
}

const filtertags_light: FilterTagsLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S10_LH12,

  backgroundColor: themeColors.SecondaryGray3,
  labelColor: themeColors.PrimaryBlack,

  tagHeight: 18,
  tagPaddings: [0, 0, 0, 6],
  gap: 8,
  iconSize: 8,
}

export type FilterTagsVariants = 'light';

export const filtertags_variants: Map<FilterTagsVariants, FilterTagsLookAndFeel> = 
  new Map<FilterTagsVariants, FilterTagsLookAndFeel>([
    ['light', filtertags_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: FilterTagsLookAndFeel;
}>`
  ${cssFlexRow};

  flex-wrap: wrap;
  flex-shrink: 0;
  gap: ${(args) => args.lookAndFeel.gap}px;

  width: 100%;
  overflow-x: auto;
`

export const Tag = styled.div<{
  lookAndFeel: FilterTagsLookAndFeel;
}>`
  ${cssFlexRow};
  align-items: center;

  height: ${(args) => args.lookAndFeel.tagHeight}px;

  padding-top: ${(args) => args.lookAndFeel.tagPaddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.tagPaddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.tagPaddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.tagPaddings[3]}px;
 
  
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}

  background-color: ${(args) => args.lookAndFeel.backgroundColor };
  color: ${(args) => args.lookAndFeel.labelColor };

  white-space: nowrap;

  cursor: pointer;
`;

export const Icon = styled.div<{
  lookAndFeel: FilterTagsLookAndFeel;
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  width: ${(args) => args.lookAndFeel.tagHeight}px;
  height: ${(args) => args.lookAndFeel.tagHeight}px;

  & svg {
    width: ${(args) => args.lookAndFeel.iconSize}px;
    height: ${(args) => args.lookAndFeel.iconSize}px;
  }
`;