import * as React    from 'react';

import { Container } from 'src/components/common/flex/grow/ui';



type Props = {
  size: number;
}
export const Grow: React.FC<Props> = ({
  size
}) => {
  return (
    <Container size = { size } />
  );
};
