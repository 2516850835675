import styled             from 'styled-components';

import { DialogVariants } from 'src/components/common/dialog';
import { ButtonVariants } from 'src/components/common/button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors    } from 'src/providers/theme/shared';



type CertDownloadDialogLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  messageTypo: Typo;
  messageColor: string;

  gap: number;

  buttonVariant: ButtonVariants;
  dialogVariant: DialogVariants;
}

const certdownloaddialog_light: CertDownloadDialogLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  gap: 24,

  buttonVariant: 'h48bluefill',
  dialogVariant: 'light',
}

export type CertDownloadDialogVariants = 'light';

export const certdownloaddialog_variants: Map<CertDownloadDialogVariants, CertDownloadDialogLookAndFeel> = 
  new Map<CertDownloadDialogVariants, CertDownloadDialogLookAndFeel>([
    ['light', certdownloaddialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: CertDownloadDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  position: relative;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: CertDownloadDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: CertDownloadDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`
