import React from 'react';



type Props = {
  className?: string;
}
export const BackspaceVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '27' height = '19' viewBox = '0 0 27 19' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path
        d = 'M18.943 6.23081L15.6738 9.50002M15.6738 9.50002L12.4045 12.7693M15.6738 9.50002L18.943 12.7692M15.6738 9.50002L12.4045 6.23077M1.28516 10.3152L7.01889 17.5075C7.26701 17.8187 7.64338 18 8.04142 18H24.1738C24.896 18 25.4815 17.4145 25.4815 16.6923V2.30769C25.4815 1.58547 24.896 1 24.1738 1H8.04142C7.64338 1 7.26701 1.18129 7.01889 1.49253L1.28516 8.68484C0.904946 9.16177 0.904946 9.83823 1.28516 10.3152Z' 
        stroke = 'currentColor'
        strokeWidth = '2'
        strokeLinecap = 'round'
      />
    </svg>
  );
};
