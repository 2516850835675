import React                      from 'react';

import { Container,
         PDFArea,
         Label,
         AttachmentPDFVariants,
         attachmentpdf_variants } from 'src/components/features/inspection/view/attachment-pdf/ui';

import * as types                 from 'src/services/api/types';

import { useAppDispatch,
         storeApi               } from 'src/store';



type Props = {
  variant: AttachmentPDFVariants;
  pdf: types.inspection.PieceFile;
}
export const AttachmentPDF: React.FC<Props> = ({ variant, pdf }) => {
  const lookAndFeel = attachmentpdf_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const onDownloadPDF = () => {
    if (pdf.__dataUrl !== undefined)
    {
      var a = document.createElement("a");
      a.href = pdf.__dataUrl;
      a.download = pdf.name;
      a.click();
    }
    else
    {
      dispatch(storeApi.inspection.view.async.viewViewPDFAsync({ fileName: pdf.name, guid: pdf.guid }))
        .unwrap()
        .then((result) => {
          
        })
        .catch((error) => { });
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <PDFArea lookAndFeel = { lookAndFeel } onClick = { onDownloadPDF } />
      <Label lookAndFeel = { lookAndFeel }>
        { pdf.name }
      </Label>
    </Container>
  );
}
