import React from 'react';



type Props = {
  className?: string;
}
export const InputStatusOkVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M23 5L12.5 16.5M1 10.5L11 19.5' />
    </svg>
  );
};
