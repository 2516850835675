import * as React                 from 'react';

import Drawer                     from '@mui/material/Drawer';

import { IconButton             } from 'src/components/common/icon-button';
import { Labeled                } from 'src/components/common/labeled';
import { InputTextbox           } from 'src/components/common/input/input-textbox';
import { InputMultiline         } from 'src/components/common/input/input-multiline';
import { Button                 } from 'src/components/common/button';


import { Container,
         Header,
         Close,
         CloseOutlineVectorBig, 
         FormContainer,
         AddMessageVariants,
         addmessage_variants    } from 'src/components/features/main/messages/add-message/ui';



type Props = {
  variant: AddMessageVariants;
  isOpened: boolean;
  topic: string;
  isTopicFreeze: boolean;
  content: string;
  onTopicChange: (value: string) => void;
  onContentChange: (value: string) => void;
  onClose: () => void;
  onAdd: () => void;
}
export const AddMessage: React.FC<Props> = ({
  variant,
  isOpened,
  topic,
  isTopicFreeze,
  content,
  onTopicChange,
  onContentChange,
  onClose,
  onAdd,
}) => {
  const lookAndFeel = addmessage_variants.get(variant)!;

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
    >
      <Container lookAndFeel = { lookAndFeel }>
        <Header lookAndFeel = { lookAndFeel }>
          Создать сообщение
          <Close lookAndFeel = { lookAndFeel }>
            <IconButton variant = { lookAndFeel.headerCloseVariant } isTapAllowed onTap = { onClose }>
              <CloseOutlineVectorBig />
            </IconButton>
          </Close>
        </Header>
        <FormContainer lookAndFeel = { lookAndFeel }>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Тема'>
            <InputTextbox
              variant = { lookAndFeel.inputTextboxVariant }
              value = { topic }
              maxLength = { 250 }
              isDisabled = { isTopicFreeze }
              onChange = { onTopicChange }
            />
          </Labeled>
          <Labeled variant = { lookAndFeel.labeledVariant } label = 'Сообщение'>
            <InputMultiline
              variant = { lookAndFeel.inputMultilineVariant }
              value = { content }
              maxLength = { 250 }
              onChange = { onContentChange }
            />
          </Labeled>
          <Button
            variant = { lookAndFeel.submitVariant }
            label = 'Отправить сообщение'
            isDisabled = { topic.trim().length === 0 || content.trim().length === 0 }
            onTap = { onAdd }
          />
        </FormContainer>
      </Container>
    </Drawer>
  );
};
