import * as React                           from 'react';

import { ArrowDownVector                  } from 'src/components/vector/arrow-down';
import { ArrowUpVector                    } from 'src/components/vector/arrow-up';
import { ThreeDotsVector                  } from 'src/components/vector/three-dots';
import { CircleQuestionVector             } from 'src/components/vector/circle-question';
import { DangerVector                     } from 'src/components/vector/danger';

import { IconButton                       } from 'src/components/common/icon-button';
import { Typography                       } from 'src/components/common/typography';
import { DateLabel                        } from 'src/components/common/date-label';
import { LabeledContent                   } from 'src/components/common/labeled-content';

import { Status,
         StatusVariants                   } from 'src/components/features/main/inspection-list/status';
import { QMMsgList                        } from 'src/components/features/main/inspection-list/qmmsg-list';

import { WrapperContainer,
         Wrapper,
         Container,
         ExpandButtonWrapper,
         InspectionHeaderContainer,
         InspectionDateContainer,
         InspectionStatusContainer,
         InspectionContentContainer,
         InspectionTypeContainer,
         InspectionQMMsgContainer,
         InspectionNumberContainer,
         InspectionGroupContentContainer,
         InspectionDivider,
         Danger,
         DangerIcon,
         DangerContent,
         InspectionVariants,
         inspection_variants              } from 'src/components/features/main/inspection-list/inspection/ui';

import { Urls                             } from 'src/providers/routing';

import * as types                           from 'src/services/api/types';
import { InspectionState,
         InspectionType                   } from 'src/services/api/types/inspection';

import { useAppDispatch,
         storeApi                         } from 'src/store';



type Props = {
  variant: InspectionVariants;
  inspection: types.inspection.Inspection;
  currentAccount: types.auth.AccountWithTokens;
  onThreeDotTap: (inspection: types.inspection.Inspection) => void;
  onQuestionTap: (inspection: types.inspection.Inspection) => void;
  onDangerTap: (inspection: types.inspection.Inspection) => void;
}
export const Inspection: React.FC<Props> = ({
  variant,
  inspection,
  currentAccount,
  onThreeDotTap,
  onQuestionTap,
  onDangerTap,
}) => {
  const lookAndFeel = inspection_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const isClientAdminOrStpk = ['clientAdmin', 'stpk'].includes(currentAccount.role);
  const isContractor = currentAccount.role === 'contractor';
  const isAwaitingStatus = [
    types.inspection.InspectionStatusCode.AWAITING_CONFIRMATION,
    types.inspection.InspectionStatusCode.AWAITING_REVIEW,
    types.inspection.InspectionStatusCode.AWAITING_VERIFICATION,
  ].includes(inspection.status.code);

  const qmetIds: number[] = [];
  const pieceIds: string[] = [];
  const groupAttIds: string[] = [];
  const defects: string[] = [];
  const qmetIdsByGuid: Map<string, number[]> = new Map<string, number[]>();
  const pieceIdsByGuid: Map<string, string[]> = new Map<string, string[]>();
  const groupAttIdsByGuid: Map<string, string[]> = new Map<string, string[]>();

  inspection.inspectionDefects.forEach((defect) => {
    if (defect.defectTitle !== null)
    {
      defects.push(defect.defectTitle);
    }

    (defect.pieces || []).forEach((piece) => {
      if (!piece.isPiece && piece.qmetId !== null && piece.qmetId > 0)
      {
        qmetIds.push(piece.qmetId);
        qmetIdsByGuid.set(defect.guid, [ ...(qmetIdsByGuid.get(defect.guid) ?? []), piece.qmetId]);
      }
      if (piece.isPiece && piece.heat !== null && piece.piece !== null && piece.heat !== '' && piece.piece !== '' && (piece.groupAtt === null || piece.groupAtt === ''))
      {
        pieceIds.push(`${piece.heat}/${piece.piece}`);
        pieceIdsByGuid.set(defect.guid, [ ...(pieceIdsByGuid.get(defect.guid) ?? []), `${piece.heat}/${piece.piece}`]);
      }
      if (piece.isPiece && piece.groupAtt !== null && piece.groupAtt !== '')
      {
        let newValue = '';
        if (piece.heat === null || piece.heat === '')
        {
          newValue = `Нет плавки/${piece.groupAtt}/${piece.qcNum}`;
        }
        else
        {
          newValue = `${piece.heat}/${piece.groupAtt}/${piece.qcNum}`;
        }
        groupAttIds.push(newValue);
        groupAttIdsByGuid.set(defect.guid, [ ...(groupAttIdsByGuid.get(defect.guid) ?? []), newValue]);
      }
    });
  });


  const isThreeDotsVisible = !isAwaitingStatus;
  const isQuestionButtonVisible = inspection.status.code === types.inspection.InspectionStatusCode.AWAITING_CONFIRMATION;

  const isQMMsgVisible = isClientAdminOrStpk && !inspection.isGroup && inspection.qmmsgs.length > 0;

  const isNumberSignVisible = inspection.isEntryAccounting;
  const numberSignLabel = isContractor ? 'Без дефекта' : 'Входной учет';

  const isNumberDefectVisible = !inspection.isGroup && !inspection.isEntryAccounting;
  const numberDefectLabel = defects;

  const isDefectsVisible = inspection.isGroup && !inspection.isEntryAccounting && !expanded;
  const defectsLabel = defects;

  const isQmetIdsAllVisible = (!inspection.isGroup || inspection.isEntryAccounting) && qmetIds.length > 0;
  const isPieceIdsAllVisible = (!inspection.isGroup || inspection.isEntryAccounting) && pieceIds.length > 0;
  const isGroupAttIdsAllVisible = (!inspection.isGroup || inspection.isEntryAccounting) && groupAttIds.length > 0;

  const isDefectsListVisible = expanded && inspection.isGroup;

  const onInspectionAction = () => {
    console.log('onInspectionAction');
    if (
      inspection.status != null
      &&
      (
        inspection.status.code === types.inspection.InspectionStatusCode.DRAFT
        ||
        (inspection.status.code === types.inspection.InspectionStatusCode.INTERNAL && !inspection.isEntryAccounting)
        ||
        inspection.status.code === types.inspection.InspectionStatusCode.ERROR
        ||
        inspection.status.code === types.inspection.InspectionStatusCode.NO_TRANS_DRAFT
        ||
        inspection.status.code === types.inspection.InspectionStatusCode.NO_TRANS_ERROR
      )
    )
    {
      Urls.InspectionEdit.build(inspection.guid, getInspectionState(inspection), false, false, false, false).navigate();
    }
    else
    {
      dispatch(storeApi.inspection.view.async.openForViewAsync({ viewGuid: inspection.guid }));
    }
  }

  return (
    <WrapperContainer lookAndFeel = { lookAndFeel }>
      <Wrapper lookAndFeel = { lookAndFeel }>
        <Container lookAndFeel = { lookAndFeel } onClick = { onInspectionAction }>

          { /** Заголовок карточки осмотра - временная метка, статус, кнопка вызова меню (три точки) */ }
          <InspectionHeaderContainer lookAndFeel = { lookAndFeel } isRightPadding = { isAwaitingStatus && !isQuestionButtonVisible }>
            <InspectionDateContainer lookAndFeel = { lookAndFeel }>
              <DateLabel variant = { lookAndFeel.dateLabelCreatedVariant } date = { new Date(inspection.timestampCreated) } />
              <DateLabel variant = { lookAndFeel.dateLabelInspectionVariant } date = { new Date(inspection.timestampInspection) } />
            </InspectionDateContainer>
            <InspectionStatusContainer lookAndFeel = { lookAndFeel }>
              <Status variant = { getStatusVariant(inspection.status.code) } label = { inspection.status.title } />
            </InspectionStatusContainer>
            {isThreeDotsVisible && (
              <IconButton
                variant = { lookAndFeel.threeDotButtonVariant }
                isTapAllowed
                onTap = { () => onThreeDotTap(inspection) }
              >
                <ThreeDotsVector />
              </IconButton>
            )}
            {isQuestionButtonVisible && (
              <IconButton
                variant = { lookAndFeel.questionButtonVariant }
                isTapAllowed
                onTap = { () => onQuestionTap(inspection) }
              >
                <CircleQuestionVector />
              </IconButton>
            )}
          </InspectionHeaderContainer>

          { /** Тело карточки осмотра */ }
          <InspectionContentContainer lookAndFeel = { lookAndFeel }>

            { /** Тип осмотра */}
            {inspection.inspectionType !== null && (
              <InspectionTypeContainer lookAndFeel = { lookAndFeel }>
                <Typography variant = { lookAndFeel.inspectionTypeTypographyVariant }>
                  { getInspectionTypeStr(inspection) }
                </Typography>
              </InspectionTypeContainer>
            )}

            { /** Плашка qmmsg с датой регистрации и статусом */ }
            {isQMMsgVisible && (
              <InspectionQMMsgContainer lookAndFeel = { lookAndFeel }>
                <QMMsgList
                  variant = { lookAndFeel.qmmsgListVariant }
                  list = { inspection.qmmsgs }
                  onJump = { (item) => Urls.QMMsgDetail.build('inspectionlist', item.id, true).navigate() }
                />
              </InspectionQMMsgContainer>
            )}

            { /** Плашка с номером осмотра и дефектом или признаком */ }
            <InspectionNumberContainer lookAndFeel = { lookAndFeel }>
              <LabeledContent variant = { lookAndFeel.labeledVariant } label = '№ осмотра'>
                <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                  { inspection.inspectionNum }
                </Typography>
              </LabeledContent>
              {isNumberSignVisible && (
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Признак'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { numberSignLabel }
                  </Typography>
                </LabeledContent>
              )}
              {isNumberDefectVisible && (
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Дефект'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { numberDefectLabel }
                  </Typography>
                </LabeledContent>
              )}
            </InspectionNumberContainer>

            { /** Плашка с дефектами (не список дефектов, а Дефекты и ниже через запятую перечисление) */ }
            {isDefectsVisible && (
              <InspectionGroupContentContainer lookAndFeel = { lookAndFeel }>
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Дефекты'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { defectsLabel.join(', ') }
                  </Typography>
                </LabeledContent>
              </InspectionGroupContentContainer>
            )}

            { /** Список qmetId общий */ }
            {isQmetIdsAllVisible && (
              <InspectionGroupContentContainer lookAndFeel = { lookAndFeel }>
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Идентификационный №'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { qmetIds.join(', ') }
                  </Typography>
                </LabeledContent>
              </InspectionGroupContentContainer>
            )}

            { /** Список pieceId общий */ }
            {isPieceIdsAllVisible && (
              <InspectionGroupContentContainer lookAndFeel = { lookAndFeel }>
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Плавка / Партия / Ед'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { pieceIds.join(', ') }
                  </Typography>
                </LabeledContent>
              </InspectionGroupContentContainer>
            )}

            { /** Список groupAttIds общий */ }
            {isGroupAttIdsAllVisible && (
              <InspectionGroupContentContainer lookAndFeel = { lookAndFeel }>
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Плавка / Партия атт / Сертификат'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { groupAttIds.join(', ') }
                  </Typography>
                </LabeledContent>
              </InspectionGroupContentContainer>
            )}

            { /** Список дефектов развернутый */ }
            {isDefectsListVisible && (
              <React.Fragment>
                {inspection.inspectionDefects.map((defect) => (
                  <InspectionGroupContentContainer key = { defect.guid } lookAndFeel = { lookAndFeel }>
                    <InspectionDivider lookAndFeel={lookAndFeel} />
                    <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Дефект'>
                      <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                        { defect.defectTitle }
                      </Typography>
                    </LabeledContent>
                    {qmetIdsByGuid.get(defect.guid) !== undefined && (
                      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Идентификационный №'>
                        <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                          { qmetIdsByGuid.get(defect.guid)!.join(', ') }
                        </Typography>
                      </LabeledContent>
                    )}
                    {pieceIdsByGuid.get(defect.guid) !== undefined && (
                      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Плавка / Партия / Ед'>
                        <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                          { pieceIdsByGuid.get(defect.guid)!.join(', ') }
                        </Typography>
                      </LabeledContent>
                    )}
                    {groupAttIdsByGuid.get(defect.guid) !== undefined && (
                      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Плавка / Партия атт / Сертификат'>
                        <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                          { groupAttIdsByGuid.get(defect.guid)!.join(', ') }
                        </Typography>
                      </LabeledContent>
                    )}
                  </InspectionGroupContentContainer>
                ))}
              </React.Fragment>
            )}

            { /** Инициатор */ }
            {expanded && (
              <InspectionGroupContentContainer lookAndFeel={lookAndFeel}>
                <InspectionDivider lookAndFeel={lookAndFeel} />
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Инициатор'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { inspection.userFio }
                  </Typography>
                </LabeledContent>
              </InspectionGroupContentContainer>
            )}
          </InspectionContentContainer>
        </Container>
        <ExpandButtonWrapper lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.expandButtonVariant }
            isTapAllowed
            onTap = { () => setExpanded(!expanded) }
          >
            { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
          </IconButton>
        </ExpandButtonWrapper>
      </Wrapper>
      {!inspection.__isTrans && (
        <Danger lookAndFeel = { lookAndFeel } onClick = { () => onDangerTap(inspection) }>
          <DangerIcon lookAndFeel = { lookAndFeel }>
            <DangerVector />
          </DangerIcon>
          <DangerContent lookAndFeel = { lookAndFeel }>
            <Typography variant = { lookAndFeel.dangerHeaderTypographyVariant }>
              Возникла проблема отправки осмотра
            </Typography>
            <Typography variant = { lookAndFeel.dangerMessageTypographyVariant }>
              Требуется отправка — зайдите в Осмотр
            </Typography>
          </DangerContent>
        </Danger>
      )}
    </WrapperContainer>
  );
};


const getStatusVariant = (status: types.inspection.InspectionStatusCode): StatusVariants => {
  switch (status)
  {
    case types.inspection.InspectionStatusCode.AWAITING_CONFIRMATION:
    case types.inspection.InspectionStatusCode.AWAITING_REVIEW:
    case types.inspection.InspectionStatusCode.AWAITING_VERIFICATION:
      return 'bluefill';
    case types.inspection.InspectionStatusCode.TAKE_TO_WORK:
    case types.inspection.InspectionStatusCode.COMPLETED:
    case types.inspection.InspectionStatusCode.INTERNAL:
      return 'blueoutline';
    case types.inspection.InspectionStatusCode.RETURN_FOR_REVISION:
    case types.inspection.InspectionStatusCode.REVOCATION:
      return 'redoutline';
    case types.inspection.InspectionStatusCode.ERROR:
      return 'redfill';
    case types.inspection.InspectionStatusCode.DRAFT:
      return 'gray';
    default:
      return 'gray';
  }
}

const getInspectionTypeStr = (inspection: types.inspection.Inspection): string => {
  if (inspection.qmmsgs.length === 0)
  {
    switch (inspection.inspectionType)
    {
      case InspectionType.REMARK:
        return 'Замечание';
      case InspectionType.CLAIM:
        return 'Претензия';
      case InspectionType.NOTIFICATION:
        return 'Уведомление';
    }
  }
  else
  {
    switch (inspection.inspectionType)
    {
      case InspectionType.REMARK:
        return 'Замечание';
      case InspectionType.CLAIM:
        return 'Претензия от ' + new Date(inspection.qmmsgs[0].timestampReg).toLocaleDateString();
      case InspectionType.NOTIFICATION:
        return 'Уведомление от ' + new Date(inspection.qmmsgs[0].timestampReg).toLocaleDateString();
    }
  }

  return '';
}

const getInspectionState = (inspect: types.inspection.Inspection): InspectionState => {
  switch (inspect.status.code)
  {
    case types.inspection.InspectionStatusCode.DRAFT:
      return InspectionState.DRAFT;
    case types.inspection.InspectionStatusCode.ERROR:
      return InspectionState.ERROR;
    case types.inspection.InspectionStatusCode.NO_TRANS_DRAFT:
      return InspectionState.DRAFT;
    case types.inspection.InspectionStatusCode.NO_TRANS_ERROR:
      return InspectionState.ERROR;
    case types.inspection.InspectionStatusCode.NO_TRANS_COMPLETED:
      return InspectionState.EDIT;
  }

  return InspectionState.EDIT;
}
