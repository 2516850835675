import * as React            from 'react';

import { Loading           } from 'src/components/common/loading';

import { Container,
         ListItem,
         ListItemIcon,
         ListItemLabel,
         IconListVariants,
         iconlist_variants } from 'src/components/features/main/common/icon-list/ui';

import * as types            from 'src/services/api/types';



export type IconListElement = {
  code: string;
  icon: React.ReactNode;
  label: string;
  busy: boolean;
  availableFor: (account: types.auth.AccountWithTokens) => boolean;
}

type Props = {
  variant: IconListVariants;
  items: IconListElement[];
  onItemTap: (item: IconListElement) => void;
}
export const IconList: React.FC<Props> = ({
  variant,
  items,
  onItemTap,
}) => {
  const lookAndFeel = iconlist_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {items.map((item) => (
        <ListItem key = { item.code } lookAndFeel = { lookAndFeel } isBusy = { item.busy } onClick = { () => onItemTap(item) }>
          <ListItemIcon lookAndFeel = { lookAndFeel } isBusy = { item.busy }>
            { item.busy ? <Loading variant = { lookAndFeel.loadingVariant } /> : item.icon }
          </ListItemIcon>
          <ListItemLabel lookAndFeel = { lookAndFeel } isBusy = { item.busy } dangerouslySetInnerHTML = {{ __html: item.label }} />
        </ListItem>
      ))}
    </Container>
  );
};
