
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceInternetShopVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path fill = '#0041A0' d = 'M5 3L5.98058 2.80388L5.8198 2H5V3ZM19 13V14H19.7808L19.9701 13.2425L19 13ZM21 5L21.9701 5.24254L22.2808 4H21V5ZM5.4 5L6.38058 4.80388V4.80388L5.4 5ZM4.5 15.5L3.79289 14.7929L3.5 15.0858V15.5H4.5ZM4.5 17H3.5L4.5 18V17ZM2 4H5V2H2V4ZM7 14H19V12H7V14ZM19.9701 13.2425L21.9701 5.24254L20.0299 4.75746L18.0299 12.7575L19.9701 13.2425ZM4.01942 3.19612L4.41942 5.19612L6.38058 4.80388L5.98058 2.80388L4.01942 3.19612ZM4.41942 5.19612L6.01942 13.1961L7.98058 12.8039L6.38058 4.80388L4.41942 5.19612ZM6.29289 12.2929L3.79289 14.7929L5.20711 16.2071L7.70711 13.7071L6.29289 12.2929ZM3.5 15.5V17H5.5V15.5H3.5ZM4.5 18H17V16H4.5V18ZM21 4H9V6H21V4Z' />
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '17' cy = '19' r = '2' />
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '7' cy = '19' r = '2' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M13 7V11M16.5 7V11M9.5 7V11' />
    </svg>
  );
};
