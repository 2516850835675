import React from 'react';



type Props = {
  className?: string;
}
export const ArrowRightWithLineVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '32' height = '32' viewBox = '0 0 32 32' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'white' strokeWidth = '2' d = 'M19.5 25L27.5 17L27.5 15L19.5 7' />
      <path stroke = 'white' strokeWidth = '2' d = 'M21.5 16L4.5 16' />
    </svg>
  );
};
