import * as React                      from 'react';

import { TrashVector                 } from 'src/components/vector/trash';

import { IconButton                  } from 'src/components/common/icon-button';

import { Message                     } from 'src/components/features/main/messages/message';
import { Container,
         HalfCircle,
         Backdrop,
         Content,
         Header,
         HeaderClose,
         HeaderTrash,
         CloseOutlineVectorBig,
         NoMoreShowButton,
         HeaderLabel,
         Guide,
         NoMoreShow,
         MessageDeleteGuideVariants,
         messagedeleteguide_variants } from 'src/components/features/main/messages/message-delete-guide/ui';



type Props = {
  variant: MessageDeleteGuideVariants;
  onClose: (noMoreShow: boolean) => void;
}
export const MessageDeleteGuide: React.FC<Props> = ({
  variant,
  onClose,
}) => {
  const lookAndFeel = messagedeleteguide_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <HalfCircle lookAndFeel = { lookAndFeel } />
      <Backdrop lookAndFeel = { lookAndFeel } />
      <Content lookAndFeel = { lookAndFeel }>
        <Header lookAndFeel = { lookAndFeel }>
          <HeaderClose lookAndFeel = { lookAndFeel }>
            <IconButton variant = { lookAndFeel.iconButtonVariant } isTapAllowed onTap = { () => onClose(false) }>
              <CloseOutlineVectorBig />
            </IconButton>
          </HeaderClose>
          <HeaderLabel lookAndFeel = { lookAndFeel }>
            Удаляй<br/>
            ненужные<br/>
            данные<br/>
            с помощью<br/>
            свайпа влево
          </HeaderLabel>
        </Header>
        <Guide lookAndFeel = { lookAndFeel }>
          <Message
            variant = { lookAndFeel.messageVariant }
            message = {{
              id: 0,
              guid: '',
              email: '',
              fio: 'Виноградов Олег Константинович',
              inspectionNum: '',
              isResponse: false,
              userId: 0,
              topic: 'Финансовые и административные условия',
              textMessage: '',
              timestampMessage: Date.now(),
            }}
            onDelete = { () => { } }
          />
          <HeaderTrash lookAndFeel = { lookAndFeel }>
            <TrashVector />
          </HeaderTrash>
        </Guide>
        <NoMoreShow lookAndFeel = { lookAndFeel }>
          <NoMoreShowButton lookAndFeel = { lookAndFeel } onClick = { () => onClose(true) }>
            Больше не показывать
          </NoMoreShowButton>
        </NoMoreShow>
      </Content>
    </Container>
  );
};
