import React from 'react';



type Props = {
  className?: string;
}
export const DefectOKVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '16' viewBox = '0 0 16 16' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M14.0001 3.00005L6.50009 10.5001L2 6'/>
    </svg>
  );
};
