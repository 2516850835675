import styled                            from 'styled-components';

import { CloseVector                   } from 'src/components/vector/close';

import { IconButtonVariants            } from 'src/components/common/icon-button';
import { TypographyVariants            } from 'src/components/common/typography';
import { BlockUIVariants               } from 'src/components/common/block-ui';

import { DefectListFragmentVariants    } from 'src/components/features/inspection/view/defectlist-fragment';
import { PieceFragmentVariants         } from 'src/components/features/inspection/view/piece-fragment';
import { ComplaintListFragmentVariants } from 'src/components/features/inspection/view/complaintlist-fragment';
import { ComplaintFragmentVariants     } from 'src/components/features/inspection/view/complaint-fragment';
import { CertDownloadDialogVariants    } from 'src/components/features/inspection/view/cert-download-dialog';

import { cssFlexRow,
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS,          
         cssFlexColumn                 } from 'src/providers/theme/shared';




export type InspectionViewLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  labelColor: string;
  labelTypo: Typo;

  backgroundColor: string;
  defectListFragmentVariant: DefectListFragmentVariants;
  pieceFragmentVariant: PieceFragmentVariants;
  complaintListFragmentVariant: ComplaintListFragmentVariants;
  complaintFragmentVariant: ComplaintFragmentVariants;
  certDownloadVariant: CertDownloadDialogVariants;
  blockUIVariant: BlockUIVariants;
}

const inspectionview_light: InspectionViewLookAndFeel = {
  containerBackgroundColor: themeColors.BackgroundFillLight,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  labelColor:  themeColors.PrimaryWhite,
  labelTypo: themeTypos.GRTSKREG_W600_S15_LH20,

  backgroundColor: themeColors.PrimaryBlue100,
  defectListFragmentVariant: 'light',
  pieceFragmentVariant: 'light',
  complaintListFragmentVariant: 'light',
  complaintFragmentVariant: 'light',
  certDownloadVariant: 'light',
  blockUIVariant: 'light',
}

export type InspectionViewVariants = 'light';

export const inspectionview_variants: Map<InspectionViewVariants, InspectionViewLookAndFeel> = 
  new Map<InspectionViewVariants, InspectionViewLookAndFeel>([
    ['light', inspectionview_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: InspectionViewLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100dvh;
  overflow: auto;

  padding: ${(args) => args.lookAndFeel.containerPaddings[0]}px
           ${(args) => args.lookAndFeel.containerPaddings[1]}px;
  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: InspectionViewLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;

  & > span {
    flex-grow: 2;
    text-align: center;
  }
`

export const Static = styled.div<{
  lookAndFeel: InspectionViewLookAndFeel,
}>`
  flex-grow: 2;
  text-align: center;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;
