import styled                           from 'styled-components';

import { CloseVector                  } from 'src/components/vector/close';

import { IconButtonVariants           } from 'src/components/common/icon-button';
import { ButtonVariants               } from 'src/components/common/button';
import { TypographyVariants           } from 'src/components/common/typography';
import { LabeledVariants              } from 'src/components/common/labeled';
import { InputTextboxVariants         } from 'src/components/common/input/input-textbox';
import { InputMultilineVariants       } from 'src/components/common/input/input-multiline';
import { TabSwitchVariants            } from 'src/components/common/tab-switch';
import { TwoActionDialogVariants      } from 'src/components/common/two-action-dialog';
import { SwitchVariants               } from 'src/components/common/switch';

import { ReferSelectorVariants        } from 'src/components/features/common/refer-selector';

import { PieceNumIdHelpDialogVariants } from 'src/components/features/inspection/edit/piecenumid-help-dialog';
import { PieceHelpDialogVariants      } from 'src/components/features/inspection/edit/piece-help-dialog';
import { GroupAttHelpDialogVariants   } from 'src/components/features/inspection/edit/groupatt-help-dialog';
import { ScannerVariants              } from 'src/components/features/inspection/edit/scanner';
import { AttachmentAddVariants        } from 'src/components/features/inspection/edit/attachment-add';
import { AttachmentPhotoVariants      } from 'src/components/features/inspection/edit/attachment-photo';
import { AttachmentPDFVariants        } from 'src/components/features/inspection/edit/attachment-pdf';
import { CertDownloadDialogVariants   } from 'src/components/features/inspection/edit/cert-download-dialog';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn                } from 'src/providers/theme/shared';



export type PieceEditLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  formPaddings: number[];
  formGap: number;
  formRowGap: number;
  circleQuestionIconColor: string;

  photoListGap: number;

  hrColor: string;

  weightUnitWidth: number;
  marriageShareHeight: number;
  marriageShareLabelTypo: Typo;
  marriageShareLabelColor: string;
  marriageShareValueTypo: Typo;
  marriageShareValueColor: string;

  modeSwitcherVariant: TabSwitchVariants;
  labeledVariant: LabeledVariants;
  textboxVariant: InputTextboxVariants;
  multilineVariant: InputMultilineVariants;
  modeSwitchConfirmDialogVariant: TwoActionDialogVariants;
  weightInputWarningDialogVariant: TwoActionDialogVariants;
  deletePhotoDialogVariant: TwoActionDialogVariants;
  submitErrorDialogVariant: TwoActionDialogVariants;
  scannerButtonVariant: IconButtonVariants;
  helpButtonVariant: IconButtonVariants;
  piecenumidHelpVariant: PieceNumIdHelpDialogVariants;
  pieceHelpVariant: PieceHelpDialogVariants;
  groupattHelpVariant: GroupAttHelpDialogVariants;
  scannerVariant: ScannerVariants;
  attachmentAddVariant: AttachmentAddVariants;
  attachmentPhotoVariant: AttachmentPhotoVariants;
  attachmenPDFVariant: AttachmentPDFVariants;
  submitButtonVariant: ButtonVariants;
  topoSwitchLabelTypo: TypographyVariants;
  topoSwitchVariant: SwitchVariants;
  topoNoteTypo: TypographyVariants;
  referSelectorVariant: ReferSelectorVariants;
  loadCertButtonBariant: IconButtonVariants;
  certDownloadDialogVariant: CertDownloadDialogVariants;
}

const pieceedit_light: PieceEditLookAndFeel = {
  containerBackgroundColor: themeColors.BackgroundFillLight,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  formPaddings: [20, 16],
  formGap: 20,
  formRowGap: 12,
  circleQuestionIconColor: themeColors.SecondaryGray4,

  photoListGap: 10,

  hrColor: themeColors.SecondaryGray3,

  weightUnitWidth: 120,
  marriageShareHeight: 50,
  marriageShareLabelTypo: themeTypos.GRTSKREG_W400_S16_LH20,
  marriageShareLabelColor: themeColors.PrimaryBlack,
  marriageShareValueTypo: themeTypos.GRTSKREG_W400_S16_LH20,
  marriageShareValueColor: themeColors.AccentBlue2,

  modeSwitcherVariant: 'h32',
  labeledVariant: 'light',
  textboxVariant: 'h48light',
  multilineVariant: 'light',
  modeSwitchConfirmDialogVariant: 'light',
  deletePhotoDialogVariant: 'light',
  submitErrorDialogVariant: 'light',
  weightInputWarningDialogVariant: 'light',
  scannerButtonVariant: 's48redred',
  helpButtonVariant: 's44gray4red',
  piecenumidHelpVariant: 'light',
  pieceHelpVariant: 'light',
  groupattHelpVariant: 'light',
  scannerVariant: 'light',
  attachmentAddVariant: 'light',
  attachmentPhotoVariant: 'light',
  attachmenPDFVariant: 'light',
  submitButtonVariant: 'h48bluefill',
  topoSwitchLabelTypo: 'blackw400s16lh22',
  topoSwitchVariant: 'w40h24',
  topoNoteTypo: 'steelw400s14lh17',
  referSelectorVariant: 'light',
  loadCertButtonBariant: 's48bluered',
  certDownloadDialogVariant: 'light',
}

export type PieceEditVariants = 'light';

export const pieceedit_variants: Map<PieceEditVariants, PieceEditLookAndFeel> = 
  new Map<PieceEditVariants, PieceEditLookAndFeel>([
    ['light', pieceedit_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100dvh;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;
  padding-left: ${(args) => args.lookAndFeel.containerPaddings[1]}px;
`

export const FormContainer = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.formGap}px;
  padding: ${(args) => args.lookAndFeel.formPaddings[0]}px ${(args) => args.lookAndFeel.formPaddings[1]}px;

  overflow-y: auto;
`

export const FormRowElement = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.formRowGap}px;

  & .circle-question {
    color: ${(args) => args.lookAndFeel.circleQuestionIconColor};
  }

  & > *:first-child {
    flex-grow: 2;
  }
`

export const WeightContainer = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.formRowGap}px;

  & > *:first-child {
    flex-grow: 2;
  }

  & > *:last-child {
    width: ${(args) => args.lookAndFeel.weightUnitWidth}px;
  }
`

export const MarriageShareContainer = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.marriageShareHeight}px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    color: ${(args) => args.lookAndFeel.marriageShareLabelColor};
    ${(args) => typoAsCSS(args.lookAndFeel.marriageShareLabelTypo)};
  }

  & > *:last-child {
    color: ${(args) => args.lookAndFeel.marriageShareValueColor};
    ${(args) => typoAsCSS(args.lookAndFeel.marriageShareValueTypo)};
  }
`

export const PhotoList = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${(args) => args.lookAndFeel.photoListGap}px;
  grid-row-gap: ${(args) => args.lookAndFeel.photoListGap}px;
`
export const SubmitContainer = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  flex-shrink: 0;
`

export const Hr = styled.div<{
  lookAndFeel: PieceEditLookAndFeel,
}>`
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;