import * as React                      from 'react';

import { GroupAttHelpEx1Vector       } from 'src/components/vector/art/groupatt-help-ex1';
import { GroupAttHelpEx2Vector       } from 'src/components/vector/art/groupatt-help-ex2';

import { Dialog                      } from 'src/components/common/dialog';
import { IconButton                  } from 'src/components/common/icon-button';

import { Container,
         Caption,
         Message,
         Close,
         Group,
         GroupHeader,
         Scroll,
         CloseOutlineVectorBig,
         GroupAttHelpDialogVariants,
         groupatthelpdialog_variants } from 'src/components/features/inspection/edit/groupatt-help-dialog/ui';



type Props = {
  variant: GroupAttHelpDialogVariants;
  opened: boolean;
  onClose: () => void;
}
export const GroupAttHelpDialog: React.FC<Props> = ({
  variant,
  opened,
  onClose,
}) => {
  const lookAndFeel = groupatthelpdialog_variants.get(variant)!;

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Close lookAndFeel = { lookAndFeel }>
          <IconButton
            variant = { lookAndFeel.closeButtonVariant }
            isTapAllowed
            onTap = { onClose }
          >
            <CloseOutlineVectorBig />
          </IconButton>
        </Close>
        <Caption lookAndFeel = { lookAndFeel }>
          Подсказка
        </Caption>
        <Scroll lookAndFeel = { lookAndFeel }>
          <Message lookAndFeel = { lookAndFeel }>
            Для корректного создания осмотра на катанку в мотках, необходимо вводить "№ плавки", "№ партии аттестации" и "№ сертификата качества" на поставку. Данные указаны в сертификате качества. Вес брака нетто заполнять в кг.
          </Message>
          <React.Fragment>
            <Group lookAndFeel = { lookAndFeel }>
              <GroupHeader lookAndFeel = { lookAndFeel }>Пример маркировки</GroupHeader>
              <GroupAttHelpEx1Vector />
            </Group>
            <Group lookAndFeel = { lookAndFeel }>
              <GroupHeader lookAndFeel = { lookAndFeel }>Пример сертификата качества</GroupHeader>
              <GroupAttHelpEx2Vector />
            </Group>
          </React.Fragment>
        </Scroll>
       </Container>
    </Dialog>
  );
};
