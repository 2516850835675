import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuMessageVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M10 5V8.5M10 8.5V12M10 8.5H13.5M10 8.5H6.5M8.5 14.5H16.4375L18.5 12.5V4.5L16.4375 2.5H3.4375L1.4375 4.5V17.5H2.4375L5.4375 14.5H6.5' />
    </svg>
  );
};
