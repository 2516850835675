import { v4 as uuidv4 } from 'uuid';

import Util             from 'src/services/util';
import WebCrypto        from 'src/services/webcrypto';
import * as types       from 'src/services/api/types';
import Constants        from 'src/services/constants';

import { Nullable     } from 'src/common';



class Preferences {
  /**
   * Идентификатор экземпляра приложения (имитирует идентификатор устройства).
   * Создается при первом старте приложения и сохраняется в localStorage для дальнейшего использования
   */
  private _instanceId: string;
  /**
   * Так называемая 'соль', которая используется в криптографических примитивах для 
   * шифрования аутентификационных данных залогиненного пользователя с помощью PIN-кода
   */
  private _pinSalt: ArrayBuffer;
  /**
   * Так называемый 'вектор инициализации', которая используется в криптографических примитивах для 
   * шифрования аутентификационных данных залогиненного пользователя с помощью PIN-кода
   */
  private _pinIv: ArrayBuffer;

  constructor() {
    let instanceId = localStorage.getItem('instanceId');
    if (instanceId === null)
    {
      instanceId = `checksteel-pwa-${uuidv4()}`;
      localStorage.setItem('instanceId', instanceId);
    }
    this._instanceId = instanceId;

    let pinSalt = localStorage.getItem('pinSalt');
    if (pinSalt === null)
    {
      pinSalt = Util.arrayBufferToBase64(window.crypto.getRandomValues(new Uint8Array(16)));
      localStorage.setItem('pinSalt', pinSalt);
    }
    this._pinSalt = Util.base64ToArrayBuffer(pinSalt);

    let pinIv = localStorage.getItem('pinIv');
    if (pinIv === null)
    {
      pinIv = Util.arrayBufferToBase64(window.crypto.getRandomValues(new Uint8Array(12)));
      localStorage.setItem('pinIv', pinIv);
    }
    this._pinIv = Util.base64ToArrayBuffer(pinIv);

    let appRatingBefore = localStorage.getItem('appRatingBefore');
    if (appRatingBefore === null)
    {
      localStorage.setItem('appRatingBefore', `${Util.currentTS + Constants.APP_RATING_REPEAT_UNTIL_SUCCESS}`);
    }
  }

  get instanceId(): string {
    return this._instanceId;
  }

  get pinSalt(): ArrayBuffer {
    return this._pinSalt;
  }

  get pinIv(): ArrayBuffer {
    return this._pinIv;
  }

  get hasEncryptedAccount(): boolean {
    const accountExpired = localStorage.getItem('accountExpired');

    if (accountExpired !== null)
    {
      if (parseInt(accountExpired, 10) > Date.now())
      {
        return true;
      }

      this.removeStoredAccount();

      return false;
    }

    return false;
  }

  get isAppRating(): boolean {
    let appRatingBefore = localStorage.getItem('appRatingBefore')!;
    return Util.currentTS > parseInt(appRatingBefore, 10);
  }

  planAppRatingUntilSuccess(): void {
    localStorage.setItem('appRatingBefore', `${Util.currentTS + Constants.APP_RATING_REPEAT_UNTIL_SUCCESS}`);
  }

  planAppRatingUntilFail(): void {
    localStorage.setItem('appRatingBefore', `${Util.currentTS + Constants.APP_RATING_REPEAT_UNTIL_FAIL}`);
  }

  get isPriorityAdv(): boolean {
    return localStorage.getItem('appAdvPriorityShow') !== null;
  }

  setAppAdvPriorityShow(): void {
    localStorage.setItem('appAdvPriorityShow', 'Y');
  }

  removeAppAdvPriorityShow(): void {
    localStorage.removeItem('appAdvPriorityShow');
  }

  get preferredMethodUEEnter(): string {
    return localStorage.getItem('preferredMethodUEEnter') ?? 'piece';
  }

  setPreferredMethodUEEnter(value: 'piece' | 'id'): void {
    localStorage.setItem('preferredMethodUEEnter', value);
  }

  get preferredWeightUnit(): number {
    return parseInt(localStorage.getItem('preferredWeightUnit') ?? '1', 10);
  }

  setPreferredWeightUnit(value: number): void {
    localStorage.setItem('preferredWeightUnit', `${value}`);
  }

  get messageDeleteGuideShowCount(): number {
    return parseInt(localStorage.getItem('messageDeleteGuideShowCount') ?? '0', 10);
  }

  setMessageDeleteGuideShowCount(value: number): void {
    localStorage.setItem('messageDeleteGuideShowCount', `${value}`);
  }

  get qmmsgJumpGuideShowCount(): number {
    return parseInt(localStorage.getItem('qmmsgJumpGuideShowCount') ?? '0', 10);
  }

  setQMMsgJumpGuideShowCount(value: number): void {
    localStorage.setItem('qmmsgJumpGuideShowCount', `${value}`);
  }

  get shadeSelection(): string {
    return localStorage.getItem('shadeSelection') ?? '';
  }

  setShadeSelection(value: string): void {
    localStorage.setItem('shadeSelection', value);
  }

  get storedAccountOwner(): string {
    return localStorage.getItem('accountOwner') ?? '';
  }

  removeStoredAccount(): void {
    localStorage.removeItem('accountExpired');
    localStorage.removeItem('accountOwner');
    localStorage.removeItem('accountData');
  }

  async encryptAndStoreAccount(account: types.auth.AccountWithTokens, pin: string): Promise<string> {
    try
    {
      const abResult = await WebCrypto.encryptWithPin(pin, JSON.stringify(account));
      const b64Result = Util.arrayBufferToBase64(abResult);

      localStorage.setItem('accountExpired', `${account.expRefresh}`);
      localStorage.setItem('accountOwner', `${account.firstName} ${account.middleName}`);
      localStorage.setItem('accountData', b64Result);

      return 'OK';
    }
    catch (exception)
    {
      return 'Во время шифрования аккаунта произошла ошибка';
    }
  }

  async extractAndDecryptAccount(pin: string): Promise<Nullable<types.auth.AccountWithTokens>> {
    try
    {
      const b64Result = localStorage.getItem('accountData') ?? '';
      const abResult = await WebCrypto.decryptWithPin(pin, Util.base64ToArrayBuffer(b64Result));
      const dec = new TextDecoder();
      
      return JSON.parse(dec.decode(abResult)) as types.auth.AccountWithTokens;
    }
    catch (exception)
    {
      return null;
    }
  }
}

export default new Preferences();