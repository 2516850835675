
import React from 'react';



type Props = {
  className?: string;
}
export const FingerPrintVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' strokeLinecap = 'square' strokeLinejoin = 'round' d='M18.7821 6.68296C19.6788 8.18232 20.0619 9.93685 19.8726 11.6769V12.6757C19.8715 13.7273 20.145 14.7607 20.6657 15.6721M7.97645 10.6781C7.97645 9.61854 8.39423 8.60235 9.13788 7.85311C9.88154 7.10388 10.8901 6.68296 11.9418 6.68296C12.9935 6.68296 14.0021 7.10388 14.7458 7.85311C15.4894 8.60235 15.9072 9.61854 15.9072 10.6781V11.6769C15.9072 13.838 16.6029 15.9408 17.8899 17.6696M11.9418 10.6781V12.6757C11.9387 15.5316 12.8036 18.3201 14.4202 20.666M7.97645 14.6733C8.21903 16.7606 8.82299 18.7889 9.76087 20.666M4.90327 18.6684C4.23436 16.4015 3.93308 14.0406 4.01106 11.6769V10.6781C4.00726 9.27368 4.37094 7.89304 5.06546 6.67533C5.75998 5.45761 6.7608 4.44584 7.96707 3.74196C9.17334 3.03808 10.5424 2.66697 11.9364 2.66602C13.3304 2.66506 14.7 3.03431 15.9072 3.73653' />
    </svg>
  );
};
