import * as React              from 'react';

import { Container,
         TypographyVariants,
         typography_variants } from 'src/components/common/typography/ui';



type Props = {
  variant: TypographyVariants;
  children: React.ReactNode;
}
export const Typography: React.FC<Props> = ({
  variant,
  children,
}) => {
  const lookAndFeel = typography_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { children }
    </Container>
  );
};
