import * as React                     from 'react';
 
import { useTheme                   } from 'styled-components';

import { useParams                  } from 'react-router-dom';
 
import { ArrowBackVector            } from 'src/components/vector/arrowback';

import { NavBar                     } from 'src/components/features/common/nav-bar';
import { BlockUI                    } from 'src/components/common/block-ui';

import { DefectsList                } from 'src/components/features/inspection/complaint/defects-list';

import { InspectionComplaintLayout, 
         Screen,
         ScreenBody,
         layout_light,
         layout_dark                } from 'src/containers/inspection/complaint/layout';

import { Urls                       } from 'src/providers/routing';

import { storeApi,
         AppDispatch,
         useAppSelector,
         useAppDispatch             } from 'src/store';

import { AsyncOpStatus              } from 'src/common';



type InspectionComplaintScreenElement = { layout: InspectionComplaintLayout; dispatch: AppDispatch; }

const Navigation: React.FC<InspectionComplaintScreenElement> = ({ layout, dispatch }) => {
  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Требования'
        startButtons = {[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.InspectionReturnFromComplaint.build().navigate()
          }
        ]}
        endButtons = {[]}
      />
    </React.Fragment>
  )
}

export const InspectionComplaintScreen = () => {
  const theme = useTheme();
  const { action } = useParams();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  const firstDefectGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectFirstGuid);
  const opStatus = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralOpStatus);
  const opStatusLabel = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralOpStatusLabel);

  React.useEffect(
    () => {
      if (bootOpStarted.current)
      {
        return;
      }

      if (action === undefined || firstDefectGuid === null)
      {
        return;
      }

      bootOpStarted.current = true;

      if (['new', 'edit', 'tocomplaint', 'tocomplaintfinance', 'view'].includes(action))
      {
        dispatch(storeApi.inspection.edit.actions.complaintForEditSelected(`${action}:${firstDefectGuid}`));
      }
    }
  );

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <DefectsList variant = { layout.defectListVariant } />
      </ScreenBody>
      <BlockUI variant = { layout.blockUiVariant } isOpened = { opStatus === AsyncOpStatus.BUSY } message = { opStatusLabel }  />
    </Screen>
  );
};
