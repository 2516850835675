import styled           from 'styled-components';

import { cssFlexRow,
         themeColors, 
         Typo,
         themeTypos,
         typoAsCSS    } from 'src/providers/theme/shared';



type InfoLinkLookAndFeel = {
  height: number;

  gap: number;

  iconColor: string;
  labelColor: string;
  borderColor: string;

  labelTypo: Typo;
}

const infolink_light: InfoLinkLookAndFeel = {
  height: 72,

  gap: 20,

  iconColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryBlack,
  borderColor: themeColors.SecondaryGray3,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,
}

export type InfoLinkVariants = 'light';

export const infolink_variants: Map<InfoLinkVariants, InfoLinkLookAndFeel> = 
  new Map<InfoLinkVariants, InfoLinkLookAndFeel>([
    ['light', infolink_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: InfoLinkLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.gap}px;
  align-items: center;

  height: ${(args) => args.lookAndFeel.height}px;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  border-left: none;
  border-right: none;
`

export const Icon = styled.div<{
  lookAndFeel: InfoLinkLookAndFeel;
}>`
  ${cssFlexRow};
  align-items: center;

  height: ${(args) => args.lookAndFeel.height}px;

  color: ${(args) => args.lookAndFeel.iconColor};
`

export const Label = styled.div<{
  lookAndFeel: InfoLinkLookAndFeel;
}>`
  ${cssFlexRow};
  align-items: center;
  flex-grow: 2;

  height: ${(args) => args.lookAndFeel.height}px;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
