import styled                     from 'styled-components';

import { ButtonVariants         } from 'src/components/common/button';
import { RatingVariants         } from 'src/components/common/rating';
import { LabeledContentVariants } from 'src/components/common/labeled-content';
import { InputMultilineVariants } from 'src/components/common/input/input-multiline';
import { LinkVariants           } from 'src/components/common/link';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors            } from 'src/providers/theme/shared';



type AppRatingLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  ratingVariant: RatingVariants;

  messageTypo: Typo;
  messageColor: string;

  labeledVariant: LabeledContentVariants;
  multilineVariant: InputMultilineVariants;

  sendButtonVariant: ButtonVariants;
  continueLinkVariant: LinkVariants;

  gap: number;
}

const apprating_light: AppRatingLookAndFeel = {
  paddings: [28, 20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  ratingVariant: 'h46s24',

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  labeledVariant: 'blue1w400s14lh17gap4',
  multilineVariant: 'light',

  sendButtonVariant: 'h48bluefill',
  continueLinkVariant: 'blue13',

  gap: 20,
}

export type AppRatingVariants = 'light';

export const apprating_variants: Map<AppRatingVariants, AppRatingLookAndFeel> = 
  new Map<AppRatingVariants, AppRatingLookAndFeel>([
    ['light', apprating_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AppRatingLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: AppRatingLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: AppRatingLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`
