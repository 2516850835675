import React from 'react';



type Props = {
  className?: string;
}
export const RequirementVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = {className} width = '12' height = '12' viewBox = '0 0 12 12' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M9.5 4.5L5.5 8.5L3 6' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
