import * as React                   from 'react';

import { ArrowDownVector          } from 'src/components/vector/arrow-down';
import { ArrowUpVector            } from 'src/components/vector/arrow-up';
import { TrashVector              } from 'src/components/vector/trash';

import { IconButton               } from 'src/components/common/icon-button';
import { Typography               } from 'src/components/common/typography';
import { DateLabel                } from 'src/components/common/date-label';
import { LabeledContent           } from 'src/components/common/labeled-content';
import { TwoActionDialog          } from 'src/components/common/two-action-dialog';
import { SwipeToDelete            } from 'src/components/common/swipe-to-delete';

import { Wrapper,
         Container,
         ExpandButtonWrapper,
         MessageHeaderContainer,
         MessageContentContainer, 
         MessageVariants,
         message_variants         } from 'src/components/features/main/messages/message/ui';

import * as types                   from 'src/services/api/types';

import { voidFunction             } from 'src/common';



type Props = {
  variant: MessageVariants;
  message: types.message.Message;
  onDelete: (message: types.message.Message) => void;
}
export const Message: React.FC<Props> = ({
  variant,
  message,
  onDelete,
}) => {
  const lookAndFeel = message_variants.get(variant)!;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [confirmDeleteOpened, setConfirmDeleteOpened] = React.useState<boolean>(false);

  const deleteSuccess = React.useRef<VoidFunction>(voidFunction);
  const deleteCancel = React.useRef<VoidFunction>(voidFunction);

  const onSuccessDeleteHandler = () => {
    setConfirmDeleteOpened(false);
    deleteSuccess.current();
  }

  const onCancelDeleteHandler = () => {
    setConfirmDeleteOpened(false);
    deleteCancel.current();
  }

  return (
    <React.Fragment>
      <SwipeToDelete
        className = 'swipe-to-delete'
        deleteColor = 'red'
        deleteComponent = {
          <TrashVector />
        }
        onDelete = { () => onDelete(message) }
        onDeleteConfirm = {(onSuccess: VoidFunction, onCancel: VoidFunction) => {
          deleteSuccess.current = onSuccess;
          deleteCancel.current = onCancel;
          setConfirmDeleteOpened(true);
        }}
      >
        <Wrapper lookAndFeel = { lookAndFeel }>
          <Container lookAndFeel = { lookAndFeel }>
            <MessageHeaderContainer lookAndFeel = { lookAndFeel }>
              <DateLabel variant = { lookAndFeel.dateLabelVariant } date = { new Date(message.timestampMessage) } />
            </MessageHeaderContainer>

            <MessageContentContainer lookAndFeel = { lookAndFeel }>
              <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Тема'>
                <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                  { message.topic }
                </Typography>
              </LabeledContent>
              {!expanded && (
                <Typography variant = { lookAndFeel.ownerTypographyVariant }>
                  { message.fio }
                </Typography>
              )}
              {expanded && (
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Сообщение'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { message.textMessage }
                  </Typography>
                </LabeledContent>
              )}
              {expanded && (
                <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Автор'>
                  <Typography variant = { lookAndFeel.labeledTypographyVariant }>
                    { message.fio }
                  </Typography>
                </LabeledContent>
              )}
            </MessageContentContainer>
          </Container>
          <ExpandButtonWrapper lookAndFeel = { lookAndFeel }>
            <IconButton
              variant = { lookAndFeel.expandButtonVariant }
              isTapAllowed
              onTap = { () => setExpanded(!expanded) }
            >
              { expanded ? <ArrowUpVector /> : <ArrowDownVector /> }
            </IconButton>
          </ExpandButtonWrapper>
        </Wrapper>
      </SwipeToDelete>
      <TwoActionDialog 
        variant = { lookAndFeel.deleteConfirmVariant }
        opened = { confirmDeleteOpened }
        caption = 'Удаление обращения'
        message = 'Вы действительно хотите удалить обращение?'
        actionLabel = 'Да'
        cancelLabel = 'Отмена'
        isDanger
        onAction = { onSuccessDeleteHandler }
        onCancel = { onCancelDeleteHandler }
      />
    </React.Fragment>
  );
};
