import styled               from 'styled-components';

import { CheckboxVariants } from 'src/components/common/checkbox';
import { ButtonVariants   } from 'src/components/common/button';
import { BlockUIVariants  } from 'src/components/common/block-ui';

import { NavBarVariants   } from 'src/components/features/common/nav-bar';

import { NoteVariants     } from 'src/components/features/qmmsg/select-company/note';

import { themeColors, 
         cssFlexColumn    } from 'src/providers/theme/shared';



type SelectCompanyLayout = {
  backgroundColor: string;

  paddings: number[];
  checkboxGap: number;
  checkboxTopPadding: number;

  navbarVariant: NavBarVariants;
  noteVariant: NoteVariants;
  checkboxVariant: CheckboxVariants;
  buttonVariant: ButtonVariants;

  blockUiVariant: BlockUIVariants;
}

export const layout_light: SelectCompanyLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [20, 16, 16, 16],
  checkboxGap: 18,
  checkboxTopPadding: 38,

  navbarVariant: 'lightblue',
  noteVariant: 'light',
  checkboxVariant: 'h24bluetext',
  buttonVariant: 'h48bluefill',

  blockUiVariant: 'light',
}

export const layout_dark: SelectCompanyLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: SelectCompanyLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: SelectCompanyLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`

export const CompanyList = styled.div<{
  layout: SelectCompanyLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.checkboxGap}px;
  flex-grow: 2;

  padding-top: ${(args) => args.layout.checkboxTopPadding}px;
`