import { createSlice,
         createAsyncThunk, 
         PayloadAction,
         createSelector    } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';
import Db                    from 'src/services/db';
import Constants             from 'src/services/constants';

import { RootState         } from 'src/store';

import { AsyncOpStatus,
         Nullable          } from 'src/common';



const SLICE_NAME = 'qmmsg:list';

export interface QMMsgListState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  statusQM: types.refers.StatusQMReferElement[];
  stepQM: types.refers.StepQMReferElement[];
  typeQM: types.refers.TypeQMReferElement[];

  claims: types.qmmsg.QMMsgCompany[];
  viewed: string[];

  eskCodes?: string;
  guidInspection?: string;
  currentPage: number;
  isEndPage: boolean;
  pageLoading: boolean;
  isFavorite: boolean;

  filterOpened: boolean;

  filterQMMsgNumber: string;
  filterOutgoingNum: string;
  filterStatusId: Nullable<number>;
  filterStepCode: Nullable<string>;
  filterTypeCode: Nullable<string>;
  filterTimestampBeg: Nullable<Date>;
  filterTimestampEnd: Nullable<Date>;

  wcFilterQMMsgNumber: string;
  wcFilterOutgoingNum: string;
  wcFilterStatusId: Nullable<number>;
  wcFilterStepCode: Nullable<string>;
  wcFilterTypeCode: Nullable<string>;
  wcFilterTimestampBeg: Nullable<Date>;
  wcFilterTimestampEnd: Nullable<Date>;

  reportOpened: boolean;

  reportQMMsgNumber: string;
  reportOutgoingNum: string;
  reportStatusId: Nullable<number>;
  reportStepCode: Nullable<string>;
  reportTypeCode: Nullable<string>;
  reportTimestampBeg: Nullable<Date>;
  reportTimestampEnd: Nullable<Date>;
}

const initialState: QMMsgListState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  statusQM: [],
  stepQM: [],
  typeQM: [],

  claims: [],
  viewed: [],

  eskCodes: undefined,
  guidInspection: undefined,
  currentPage: 1,
  isEndPage: false,
  pageLoading: false,
  isFavorite: false,

  filterOpened: false,

  filterQMMsgNumber: '',
  filterOutgoingNum: '',
  filterStatusId: null,
  filterStepCode: null,
  filterTypeCode: null,
  filterTimestampBeg: null,
  filterTimestampEnd: null,

  wcFilterQMMsgNumber: '',
  wcFilterOutgoingNum: '',
  wcFilterStatusId: null,
  wcFilterStepCode: null,
  wcFilterTypeCode: null,
  wcFilterTimestampBeg: null,
  wcFilterTimestampEnd: null,

  reportOpened: false,

  reportQMMsgNumber: '',
  reportOutgoingNum: '',
  reportStatusId: null,
  reportStepCode: null,
  reportTypeCode: null,
  reportTimestampBeg: null,
  reportTimestampEnd: null,
}

// #region prepareInfoAsync
type PrepareInfoArgs = {
  eskCodes?: string;
  guidInspection?: string;
};
type PrepareInfoResolve = {
  statusQM: types.refers.StatusQMReferElement[];
  stepQM: types.refers.StepQMReferElement[];
  typeQM: types.refers.TypeQMReferElement[];
  claims: types.qmmsg.QMMsgCompany[];
  viewed: string[];
  eskCodes?: string;
  guidInspection?: string;
  isEndPage: boolean;
};
export type PrepareInfoReject = ApiError;
export const prepareInfoAsync = createAsyncThunk<
  PrepareInfoResolve,
  PrepareInfoArgs,
  {
    state: RootState,
    rejectValue: PrepareInfoReject,
  }
>(
  `${SLICE_NAME}/prepareInfoAsync`,
  async (args, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(qmmsgListActions.opStatusChanged(AsyncOpStatus.BUSY));

      /** Проверка необходимости обновления справочников */
      thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Проверка необходимости обновления справочников'));
      const dbRefersHash = await Db.getOptString('REFER_HASH');
      const apiRefersHash = (await Api.getRefersHash()).hash;
      if (apiRefersHash !== null && (dbRefersHash === null || dbRefersHash !== apiRefersHash))
      {
        thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Загрузка и обновление справочников'));
        const refers = await Api.getRefers();
        await Db.updateRefers(refers, apiRefersHash!);
      }

      /** Загружаем список статусов  */
      thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Загрузка уведомлений/претензий'));

      const state = thunkAPI.getState();
      const qmMsgNumber = selectWCFilterQMMsgNumber(state);
      const outgoingNum = selectWCFilterOutgoingNum(state);
      const statusId = selectWCFilterStatusId(state);
      const stepCode = selectWCFilterStepCode(state);
      const typeCode = selectWCFilterTypeCode(state);
      const timestampBeg = selectWCFilterTimestampBeg(state);
      const timestampEnd = selectWCFilterTimestampEnd(state);

      const methodArgs: types.qmmsg.GetQMMsgClaimsIn = { };

      if (args.eskCodes !== undefined && args.eskCodes !== 'null')
      {
        methodArgs.eskCodes = (args.eskCodes ?? '').replaceAll('-', ',');
      }

      if (args.guidInspection !== undefined)
      {
        methodArgs.guidInspection = args.guidInspection;
      }

      if (timestampBeg !== null)
      {
        methodArgs.timestampBeg = timestampBeg.valueOf();
      }

      if (timestampEnd !== null)
      {
        methodArgs.timestampEnd = timestampEnd.valueOf() + (24 * 60 * 60 - 1) * 1000;
      }

      if (qmMsgNumber.trim().length > 0)
      {
        methodArgs.qmMsgNumber = qmMsgNumber.trim();
      }

      if (outgoingNum.trim().length > 0)
      {
        methodArgs.outgoingNum = outgoingNum.trim();
      }

      if (statusId !== null)
      {
        methodArgs.statusId = statusId;
      }

      if (stepCode !== null)
      {
        methodArgs.stepCode = stepCode;
      }

      if (typeCode !== null)
      {
        methodArgs.typeCode = typeCode;
      }

      const currentPage = thunkAPI.getState().qmmsgList.currentPage;
      const pageSize = Constants.QMMSG_PAGE_SIZE;

      methodArgs.skip = 0;
      methodArgs.count = currentPage * pageSize;

      const claims = await Api.getQMMsgClaims(methodArgs);

      const statusQM = await Db.getStatusesQmMsg();
      const stepQM = await Db.getStepsQmMsg();
      const typeQM = await Db.getTypesQmMsg();
      const qmmsgViewed = (await Db.getOptString('QMMSG_VIEWED'))?.split(',') ?? [];

      return {
        statusQM,
        stepQM,
        typeQM,
        claims: claims === null ? [] : claims.company,
        viewed: qmmsgViewed,
        eskCodes: args.eskCodes,
        guidInspection: args.guidInspection,
        isEndPage: claims.company.reduce((acc, v) => acc + v.qmMsgs.length, 0) < currentPage * pageSize,
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region filterClaimsAsync
type FilterClaimsArgs = {
  isStart: boolean;
};
type FilterClaimsResolve = {
  claims: types.qmmsg.QMMsgCompany[];
  isStart: boolean;
  isEndPage: boolean;
};
export type FilterClaimsReject = ApiError;
export const filterClaimsAsync = createAsyncThunk<
  FilterClaimsResolve,
  FilterClaimsArgs,
  {
    state: RootState,
    rejectValue: FilterClaimsReject,
  }
>(
  `${SLICE_NAME}/filterClaimsAsync`,
  async (args, thunkAPI) => {
    if (args.isStart)
    {
      thunkAPI.dispatch(qmmsgListActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Загрузка уведомлений/претензий'));
    }
    else
    {
      thunkAPI.dispatch(qmmsgListActions.pageLoading(true));
    }

    const state = thunkAPI.getState();
    const eskCodes = selectEskCodes(state);

    const qmMsgNumber = selectWCFilterQMMsgNumber(state);
    const outgoingNum = selectWCFilterOutgoingNum(state);
    const statusId = selectWCFilterStatusId(state);
    const stepCode = selectWCFilterStepCode(state);
    const typeCode = selectWCFilterTypeCode(state);
    const timestampBeg = selectWCFilterTimestampBeg(state);
    const timestampEnd = selectWCFilterTimestampEnd(state);

    const methodArgs: types.qmmsg.GetQMMsgClaimsIn = { };

    if (eskCodes !== undefined && eskCodes !== 'null')
    {
      methodArgs.eskCodes = (eskCodes ?? '').replaceAll('-', ',');
    }

    if (timestampBeg !== null)
    {
      methodArgs.timestampBeg = timestampBeg.valueOf();
    }

    if (timestampEnd !== null)
    {
      methodArgs.timestampEnd = timestampEnd.valueOf() + (24 * 60 * 60 - 1) * 1000;
    }

    if (qmMsgNumber.trim().length > 0)
    {
      methodArgs.qmMsgNumber = qmMsgNumber.trim();
    }

    if (outgoingNum.trim().length > 0)
    {
      methodArgs.outgoingNum = outgoingNum.trim();
    }

    if (statusId !== null)
    {
      methodArgs.statusId = statusId;
    }

    if (stepCode !== null)
    {
      methodArgs.stepCode = stepCode;
    }

    if (typeCode !== null)
    {
      methodArgs.typeCode = typeCode;
    }

    const pageSize = Constants.QMMSG_PAGE_SIZE;
    const skip = args.isStart ? 0 : state.qmmsgList.currentPage * pageSize;

    methodArgs.skip = skip;
    methodArgs.count = pageSize;

    try
    {
      const result = await Api.getQMMsgClaims(methodArgs);

      if (!args.isStart)
      {
        thunkAPI.dispatch(qmmsgListActions.pageLoading(false));
      }

      return {
        claims: result.company,
        isStart: args.isStart,
        isEndPage: result.company.reduce((acc, v) => acc + v.qmMsgs.length, 0) < pageSize,
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region filterClaimsWithResetAsync
type FilterClaimsWithResetArgs = {
  resetFilter: string;
};
type FilterClaimsWithResetResolve = {
  resetFilter: string;
  claims: types.qmmsg.QMMsgCompany[];
  isEndPage: boolean;
};
export type FilterClaimsWithResetReject = ApiError;
export const filterClaimsWithResetAsync = createAsyncThunk<
  FilterClaimsWithResetResolve,
  FilterClaimsWithResetArgs,
  {
    state: RootState,
    rejectValue: FilterClaimsWithResetReject,
  }
>(
  `${SLICE_NAME}/filterClaimsWithResetAsync`,
  async (args, thunkAPI) => {
    thunkAPI.dispatch(qmmsgListActions.opStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Загрузка уведомлений/претензий'));

    const state = thunkAPI.getState();
    const eskCodes = selectEskCodes(state);

    const qmMsgNumber = selectFilterQMMsgNumber(state);
    const outgoingNum = selectFilterOutgoingNum(state);
    const statusId = selectFilterStatusId(state);
    const stepCode = selectFilterStepCode(state);
    const typeCode = selectFilterTypeCode(state);
    const timestampBeg = selectFilterTimestampBeg(state);
    const timestampEnd = selectFilterTimestampEnd(state);

    const methodArgs: types.qmmsg.GetQMMsgClaimsIn = { };

    if (eskCodes !== undefined && eskCodes !== 'null')
    {
      methodArgs.eskCodes = (eskCodes ?? '').replaceAll('-', ',');
    }

    if (timestampBeg !== null && args.resetFilter !== 'timestampBeg')
    {
      methodArgs.timestampBeg = timestampBeg.valueOf();
    }

    if (timestampEnd !== null && args.resetFilter !== 'timestampEnd')
    {
      methodArgs.timestampEnd = timestampEnd.valueOf() + (24 * 60 * 60 - 1) * 1000;
    }

    if (qmMsgNumber.trim().length > 0 && args.resetFilter !== 'msgNumber')
    {
      methodArgs.qmMsgNumber = qmMsgNumber.trim();
    }

    if (outgoingNum.trim().length > 0 && args.resetFilter !== 'outgoingNum')
    {
      methodArgs.outgoingNum = outgoingNum.trim();
    }

    if (statusId !== null && args.resetFilter !== 'statusId')
    {
      methodArgs.statusId = statusId;
    }

    if (stepCode !== null && args.resetFilter !== 'stepCode')
    {
      methodArgs.stepCode = stepCode;
    }

    if (typeCode !== null && args.resetFilter !== 'typeCode')
    {
      methodArgs.typeCode = typeCode;
    }

    const pageSize = Constants.QMMSG_PAGE_SIZE;

    methodArgs.skip = 0;
    methodArgs.count = pageSize;

    try
    {
      const result = await Api.getQMMsgClaims(methodArgs);

      return {
        resetFilter: args.resetFilter,
        claims: result.company,
        isEndPage: result.company.reduce((acc, v) => acc + v.qmMsgs.length, 0) < pageSize,
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region filterClaimsFavoriteAsync
type FilterClaimsFavoriteArgs = void;
type FilterClaimsFavoriteResolve = {
  claims: types.qmmsg.QMMsgCompany[];
};
export type FilterClaimsFavoriteReject = ApiError;
export const filterClaimsFavoriteAsync = createAsyncThunk<
  FilterClaimsFavoriteResolve,
  FilterClaimsFavoriteArgs,
  {
    state: RootState,
    rejectValue: FilterClaimsFavoriteReject,
  }
>(
  `${SLICE_NAME}/filterClaimsFavoriteAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(qmmsgListActions.opStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Загрузка списка избранных уведомлений/претензий'));

    const state = thunkAPI.getState();
    const eskCodes = selectEskCodes(state);

    const args: types.qmmsg.GetQMMsgClaimsFavoriteIn = { };

    if (eskCodes !== undefined && eskCodes !== 'null')
    {
      args.eskCodes = (eskCodes ?? '').replaceAll('-', ',');
    }

    try
    {
      const result = await Api.getQMMsgClaimsFavorite(args);

      return {
        claims: result.company,
      }
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region reportClaimsAsync
type ReportClaimsArgs = void;
type ReportClaimsResolve = void;
export type ReportClaimsReject = ApiError;
export const reportClaimsAsync = createAsyncThunk<
  ReportClaimsResolve,
  ReportClaimsArgs,
  {
    state: RootState,
    rejectValue: ReportClaimsReject,
  }
>(
  `${SLICE_NAME}/reportClaimsAsync`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(qmmsgListActions.opStatusChanged(AsyncOpStatus.BUSY));
    thunkAPI.dispatch(qmmsgListActions.opStatusLabelChanged('Постановка отчета в очередь'));

    const state = thunkAPI.getState();
    const eskCodes = selectEskCodes(state);

    const qmMsgNumber = selectReportQMMsgNumber(state);
    const outgoingNum = selectReportOutgoingNum(state);
    const statusId = selectReportStatusId(state);
    const stepCode = selectReportStepCode(state);
    const typeCode = selectReportTypeCode(state);
    const timestampBeg = selectReportTimestampBeg(state);
    const timestampEnd = selectReportTimestampEnd(state);

    const args: types.qmmsg.GetQMMsgClaimsReportIn = { };

    if (eskCodes !== undefined && eskCodes !== 'null')
    {
      args.eskCodes = (eskCodes ?? '').replaceAll('-', ',');
    }

    if (timestampBeg !== null)
    {
      args.timestampBeg = timestampBeg.valueOf();
    }

    if (timestampEnd !== null)
    {
      args.timestampEnd = timestampEnd.valueOf() + (24 * 60 * 60 - 1) * 1000;
    }

    if (qmMsgNumber.trim().length > 0)
    {
      args.qmMsgNumber = qmMsgNumber.trim();
    }

    if (outgoingNum.trim().length > 0)
    {
      args.outgoingNum = outgoingNum.trim();
    }

    if (statusId !== null)
    {
      args.statusId = statusId;
    }

    if (stepCode !== null)
    {
      args.stepCode = stepCode;
    }

    if (typeCode !== null)
    {
      args.typeCode = typeCode;
    }

    try
    {
      await Api.getQMMsgClaimsReport(args);
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

type StatusQMChangedAction = PayloadAction<types.refers.StatusQMReferElement[]>;
type StepQMChangedAction = PayloadAction<types.refers.StepQMReferElement[]>;
type TypeQMChangedAction = PayloadAction<types.refers.TypeQMReferElement[]>;

type ClaimsChangedAction = PayloadAction<types.qmmsg.QMMsgCompany[]>;

type DateChangedAction = PayloadAction<Nullable<Date>>;
type StringChangedAction = PayloadAction<string>;
type BooleanChangedAction = PayloadAction<boolean>;
type NullableStringChangedAction = PayloadAction<Nullable<string>>;
type NullableNumberChangedAction = PayloadAction<Nullable<number>>;

export const qmmsgListSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },

    statusQMChanged: (state, action: StatusQMChangedAction) => {
      state.statusQM = action.payload;
    },
    stepQMChanged: (state, action: StepQMChangedAction) => {
      state.stepQM = action.payload;
    },
    typeQMChanged: (state, action: TypeQMChangedAction) => {
      state.typeQM = action.payload;
    },

    claimsChanged: (state, action: ClaimsChangedAction) => {
      state.claims = action.payload;
    },
    viewedChanged: (state, action: StringChangedAction) => {
      state.viewed = action.payload.split(',');
    },

    eskCodesChanged: (state, action: StringChangedAction) => {
      state.eskCodes = action.payload;
    },
    paginationReset: (state) => {
      state.currentPage = 1;
      state.isEndPage = false;
    },
    pageLoading: (state, action: BooleanChangedAction) => {
      state.pageLoading = action.payload;
    },

    filterQMMsgNumberChanged: (state, action: StringChangedAction) => {
      state.filterQMMsgNumber = action.payload;
    },
    filterOutgoingNumChanged: (state, action: StringChangedAction) => {
      state.filterOutgoingNum = action.payload;
    },
    filterStatusIdChanged: (state, action: NullableNumberChangedAction) => {
      state.filterStatusId = action.payload;
    },
    filterStepCodeChanged: (state, action: NullableStringChangedAction) => {
      state.filterStepCode = action.payload;
    },
    filterTypeCodeChanged: (state, action: NullableStringChangedAction) => {
      state.filterTypeCode = action.payload;
    },
    filterTimestampBegChanged: (state, action: DateChangedAction) => {
      state.filterTimestampBeg = action.payload;
    },
    filterTimestampEndChanged: (state, action: DateChangedAction) => {
      state.filterTimestampEnd = action.payload;
    },

    wcFilterQMMsgNumberChanged: (state, action: StringChangedAction) => {
      state.wcFilterQMMsgNumber = action.payload;
    },
    wcFilterOutgoingNumChanged: (state, action: StringChangedAction) => {
      state.wcFilterOutgoingNum = action.payload;
    },
    wcFilterStatusIdChanged: (state, action: NullableNumberChangedAction) => {
      state.wcFilterStatusId = action.payload;
    },
    wcFilterStepCodeChanged: (state, action: NullableStringChangedAction) => {
      state.wcFilterStepCode = action.payload;
    },
    wcFilterTypeCodeChanged: (state, action: NullableStringChangedAction) => {
      state.wcFilterTypeCode = action.payload;
    },
    wcFilterTimestampBegChanged: (state, action: DateChangedAction) => {
      state.wcFilterTimestampBeg = action.payload;
    },
    wcFilterTimestampEndChanged: (state, action: DateChangedAction) => {
      state.wcFilterTimestampEnd = action.payload;
    },

    reportQMMsgNumberChanged: (state, action: StringChangedAction) => {
      state.reportQMMsgNumber = action.payload;
    },
    reportOutgoingNumChanged: (state, action: StringChangedAction) => {
      state.reportOutgoingNum = action.payload;
    },
    reportStatusIdChanged: (state, action: NullableNumberChangedAction) => {
      state.reportStatusId = action.payload;
    },
    reportStepCodeChanged: (state, action: NullableStringChangedAction) => {
      state.reportStepCode = action.payload;
    },
    reportTypeCodeChanged: (state, action: NullableStringChangedAction) => {
      state.reportTypeCode = action.payload;
    },
    reportTimestampBegChanged: (state, action: DateChangedAction) => {
      state.reportTimestampBeg = action.payload;
    },
    reportTimestampEndChanged: (state, action: DateChangedAction) => {
      state.reportTimestampEnd = action.payload;
    },

    filterOpened: (state) => {
      state.wcFilterQMMsgNumber = state.filterQMMsgNumber;
      state.wcFilterOutgoingNum = state.filterOutgoingNum;
      state.wcFilterStatusId = state.filterStatusId;
      state.wcFilterStepCode = state.filterStepCode;
      state.wcFilterTypeCode = state.filterTypeCode;
      state.wcFilterTimestampBeg = state.filterTimestampBeg;
      state.wcFilterTimestampEnd = state.filterTimestampEnd;

      state.filterOpened = true;
    },
    filterClosed: (state) => {
      state.filterOpened = false;
    },
    filterReseted: (state) => {
      state.wcFilterQMMsgNumber = '';
      state.wcFilterOutgoingNum = '';
      state.wcFilterStatusId = null;
      state.wcFilterStepCode = null;
      state.wcFilterTypeCode = null;
      state.wcFilterTimestampBeg = null;
      state.wcFilterTimestampEnd = null;
    },

    reportOpened: (state) => {
      state.reportQMMsgNumber = '';
      state.reportOutgoingNum = '';
      state.reportStatusId = null;
      state.reportStepCode = null;
      state.reportTypeCode = null;
      state.reportTimestampBeg = null;
      state.reportTimestampEnd = null;

      state.reportOpened = true;
    },
    reportClosed: (state) => {
      state.reportOpened = false;
    },
    reportReseted: (state) => {
      state.reportQMMsgNumber = '';
      state.reportOutgoingNum = '';
      state.reportStatusId = null;
      state.reportStepCode = null;
      state.reportTypeCode = null;
      state.reportTimestampBeg = null;
      state.reportTimestampEnd = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(prepareInfoAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.statusQM = action.payload.statusQM;
        state.stepQM = action.payload.stepQM;
        state.typeQM = action.payload.typeQM;
        state.claims = action.payload.claims;
        state.viewed = action.payload.viewed;
        state.eskCodes = action.payload.eskCodes;
        state.guidInspection = action.payload.guidInspection;
        state.isEndPage = action.payload.isEndPage;
      })
      .addCase(prepareInfoAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
      .addCase(filterClaimsAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        state.filterOutgoingNum = state.wcFilterOutgoingNum;
        state.filterQMMsgNumber = state.wcFilterQMMsgNumber;
        state.filterStatusId = state.wcFilterStatusId;
        state.filterStepCode = state.wcFilterStepCode;
        state.filterTimestampBeg = state.wcFilterTimestampBeg;
        state.filterTimestampEnd = state.wcFilterTimestampEnd;
        state.filterTypeCode = state.wcFilterTypeCode;

        state.filterOpened = false;

        state.currentPage = action.payload.isStart ? 1 : state.currentPage + 1;
        state.isEndPage = action.payload.isEndPage;

        if (action.payload.isStart)
        {
          state.claims = [...action.payload.claims];
        }
        else
        {
          state.claims = [
            ...state.claims.map((item) => ({
              ...item,
              qmMsgs: [
                ...item.qmMsgs,
                ...action.payload.claims.find((one) => one.eskCode === item.eskCode)?.qmMsgs ?? []
              ]
            })),
            ...action.payload.claims.filter((one) => state.claims.find((two) => one.eskCode === two.eskCode) === undefined)
          ]
        }
       
        state.isFavorite = false;
      })
      .addCase(filterClaimsAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.pageLoading = false;
      })
      .addCase(filterClaimsWithResetAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        if (action.payload.resetFilter === 'timestampBeg')
        {
          state.filterTimestampBeg = null;
        }

        if (action.payload.resetFilter === 'timestampEnd')
        {
          state.filterTimestampEnd = null;
        }

        if (action.payload.resetFilter === 'msgNumber')
        {
          state.filterQMMsgNumber = '';
        }

        if (action.payload.resetFilter === 'outgoingNum')
        {
          state.filterOutgoingNum = '';
        }

        if (action.payload.resetFilter === 'statusId')
        {
          state.filterStatusId = null;
        }

        if (action.payload.resetFilter === 'stepCode')
        {
          state.filterStepCode = null;
        }

        if (action.payload.resetFilter === 'typeCode')
        {
          state.filterTypeCode = null;
        }

        state.claims = action.payload.claims;
       
        state.currentPage = 1;
        state.isEndPage = action.payload.isEndPage;
      })
      .addCase(filterClaimsWithResetAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
      .addCase(filterClaimsFavoriteAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';

        state.filterOutgoingNum = state.wcFilterOutgoingNum;
        state.filterQMMsgNumber = state.wcFilterQMMsgNumber;
        state.filterStatusId = state.wcFilterStatusId;
        state.filterStepCode = state.wcFilterStepCode;
        state.filterTimestampBeg = state.wcFilterTimestampBeg;
        state.filterTimestampEnd = state.wcFilterTimestampEnd;
        state.filterTypeCode = state.wcFilterTypeCode;

        state.filterOpened = false;

        state.claims = action.payload.claims;
       
        state.currentPage = 1;
        state.isEndPage = true;
        state.isFavorite = true;
      })
      .addCase(filterClaimsFavoriteAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      })
      .addCase(reportClaimsAsync.fulfilled, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.reportOpened = false;
      })
      .addCase(reportClaimsAsync.rejected, (state) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
      });
  },
})

const selectOpStatus = (state: RootState) => state.qmmsgList.opStatus;
const selectOpStatusLabel = (state: RootState) => state.qmmsgList.opStatusLabel;

const selectStatusQM = (state: RootState) => state.qmmsgList.statusQM;
const selectStepQM = (state: RootState) => state.qmmsgList.stepQM;
const selectTypeQM = (state: RootState) => state.qmmsgList.typeQM;

const selectClaims = (state: RootState) => state.qmmsgList.claims;
const selectViewed = (state: RootState) => state.qmmsgList.viewed;

const selectEskCodes = (state: RootState) => state.qmmsgList.eskCodes;
const selectGuidInspection = (state: RootState) => state.qmmsgList.guidInspection;
const selectIsEndPage = (state: RootState) => state.qmmsgList.isEndPage;
const selectPageLoading = (state: RootState) => state.qmmsgList.pageLoading;
const selectIsFavorite = (state: RootState) => state.qmmsgList.isFavorite;

const selectFilterOpened = (state: RootState) => state.qmmsgList.filterOpened;

const selectFilterQMMsgNumber = (state: RootState) => state.qmmsgList.filterQMMsgNumber;
const selectFilterOutgoingNum = (state: RootState) => state.qmmsgList.filterOutgoingNum;
const selectFilterStatusId = (state: RootState) => state.qmmsgList.filterStatusId;
const selectFilterStepCode = (state: RootState) => state.qmmsgList.filterStepCode;
const selectFilterTypeCode = (state: RootState) => state.qmmsgList.filterTypeCode;
const selectFilterTimestampBeg = (state: RootState) => state.qmmsgList.filterTimestampBeg;
const selectFilterTimestampEnd = (state: RootState) => state.qmmsgList.filterTimestampEnd;

const selectWCFilterQMMsgNumber = (state: RootState) => state.qmmsgList.wcFilterQMMsgNumber;
const selectWCFilterOutgoingNum = (state: RootState) => state.qmmsgList.wcFilterOutgoingNum;
const selectWCFilterStatusId = (state: RootState) => state.qmmsgList.wcFilterStatusId;
const selectWCFilterStepCode = (state: RootState) => state.qmmsgList.wcFilterStepCode;
const selectWCFilterTypeCode = (state: RootState) => state.qmmsgList.wcFilterTypeCode;
const selectWCFilterTimestampBeg = (state: RootState) => state.qmmsgList.wcFilterTimestampBeg;
const selectWCFilterTimestampEnd = (state: RootState) => state.qmmsgList.wcFilterTimestampEnd;

const selectReportOpened = (state: RootState) => state.qmmsgList.reportOpened;

const selectReportQMMsgNumber = (state: RootState) => state.qmmsgList.reportQMMsgNumber;
const selectReportOutgoingNum = (state: RootState) => state.qmmsgList.reportOutgoingNum;
const selectReportStatusId = (state: RootState) => state.qmmsgList.reportStatusId;
const selectReportStepCode = (state: RootState) => state.qmmsgList.reportStepCode;
const selectReportTypeCode = (state: RootState) => state.qmmsgList.reportTypeCode;
const selectReportTimestampBeg = (state: RootState) => state.qmmsgList.reportTimestampBeg;
const selectReportTimestampEnd = (state: RootState) => state.qmmsgList.reportTimestampEnd;


const selectFiltersFilled = createSelector(
  [
    selectFilterQMMsgNumber,
    selectFilterOutgoingNum,
    selectFilterStatusId,
    selectFilterStepCode,
    selectFilterTypeCode,
    selectFilterTimestampBeg,
    selectFilterTimestampEnd,
  ],
  (qmmsgNumber, outgoingNum, statusId, stepCode, typeCode, timestampBeg, timestampEnd) => {
    return (
      timestampBeg !== null
      ||
      timestampEnd !== null
      ||
      qmmsgNumber.trim().length > 0
      ||
      outgoingNum.trim().length > 0
      ||
      statusId !== null
      ||
      stepCode !== null
      ||
      typeCode !== null
    )
  }
);

export const qmmsgListSelectors = {
  selectOpStatus,
  selectOpStatusLabel,

  selectStatusQM,
  selectStepQM,
  selectTypeQM,

  selectClaims,
  selectViewed,

  selectEskCodes,
  selectGuidInspection,
  selectIsEndPage,
  selectPageLoading,
  selectIsFavorite,

  selectFilterOpened,

  selectFilterQMMsgNumber,
  selectFilterOutgoingNum,
  selectFilterStatusId,
  selectFilterStepCode,
  selectFilterTypeCode,
  selectFilterTimestampBeg,
  selectFilterTimestampEnd,

  selectWCFilterQMMsgNumber,
  selectWCFilterOutgoingNum,
  selectWCFilterStatusId,
  selectWCFilterStepCode,
  selectWCFilterTypeCode,
  selectWCFilterTimestampBeg,
  selectWCFilterTimestampEnd,

  selectReportOpened,

  selectReportQMMsgNumber,
  selectReportOutgoingNum,
  selectReportStatusId,
  selectReportStepCode,
  selectReportTypeCode,
  selectReportTimestampBeg,
  selectReportTimestampEnd,

  selectFiltersFilled,
}

export const qmmsgListActions = qmmsgListSlice.actions;

export default qmmsgListSlice.reducer;