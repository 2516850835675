import styled                      from 'styled-components';

import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';

import { PinVariants             } from 'src/components/features/auth/common/pin';
import { AppCaptionVariants      } from 'src/components/features/auth/common/app-caption';

import { cssFlexColumn,
         themeColors             } from 'src/providers/theme/shared';



export type PINCreateLayout = {
  paddings: number[];

  backgroundColor: string;

  appCaptionVariant: AppCaptionVariants;
  pinVariant: PinVariants;
  dialogVariant: DialogVariants;
  pinCheckDialogVariant: OneActionDialogVariants;
}

export const layout_light: PINCreateLayout = {
  paddings: [30, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  appCaptionVariant: 'light',
  pinVariant: 'light',
  dialogVariant: 'light',
  pinCheckDialogVariant: 'light',
}

export const layout_dark: PINCreateLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: PINCreateLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding: ${(args) => args.layout.paddings[0]}px ${(args) => args.layout.paddings[1]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`
