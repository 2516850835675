import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region Common

export type AdvElement = {
  /** ИД рекламного материала */
  id: number;
  /** Заголовок */
  title: string;
  /** Ссылка на ресурс */
  advLink: string;
  /** Порядок сортировки */
  sortOrder: number;
  /** true - статистика */
  isStatistic: boolean;
  /** Имя превью */
  previewName: string;
  /** Имя файла */
  fileName: string;
  /** ИД превью */
  idPreview: number;
  /** ИД файла */
  idFile: number;
  /** Приоритетная реклама */
  isPriority: boolean;
  /** Опрос */
  isSurvey: boolean;
  /** Превью в base64, с сервера не приходит - формируется на клиенте выкачиванием и преобразованием файла */
  __b64Preview: string;
  /** Полное изображение в base64, с сервера не приходит - формируется на клиенте выкачиванием и преобразованием файла */
  __b64Full: string;
}

export type AdvStatistics = {
  /** Порядок сортировки */
  sortOrder: number;
  /** Название строки */
  statName: string;
  /** Значение строки */
  statValue: string;
}

// #endregion


// #region GET adv/hash

export type GetAdvHashIn = void;

export type GetAdvHashOut = {
  /** hash-сумма */
  hash: string;
}

export const prepareGetAdvHash
  : PrepareCallHandler<GetAdvHashIn, GetAdvHashOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/adv/hash`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET adv

export type GetAdvIn = void;

export type GetAdvOut = Array<AdvElement>;

export const prepareGetAdv
  : PrepareCallHandler<GetAdvIn, GetAdvOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/adv`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET adv/file/{id}

export type GetAdvFileByIdIn = {
  /** Идентификатор файла */
  id: number;
}

export type GetAdvFileByIdOut = {
  /** Файл в формате data url */
  dataUrl: string;
}

export const prepareGetAdvFileById
  : PrepareCallHandler<GetAdvFileByIdIn, GetAdvFileByIdOut> = (arg: GetAdvFileByIdIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/adv/file/${arg.id}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (response) => {
        let image = window.btoa(
          new Uint8Array(response)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return {
          dataUrl: `data:image/jpeg;base64,${image}`
        }
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET adv/statistics

export type GetAdvStatisticsIn = void;

export type GetAdvStatisticsOut = Array<AdvStatistics>;

export const prepareGetAdvStatistics
  : PrepareCallHandler<GetAdvStatisticsIn, GetAdvStatisticsOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/adv/statistics`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
