import styled                      from 'styled-components';

import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';
import { LabeledVariants         } from 'src/components/common/labeled';
import { InputPasswordVariants   } from 'src/components/common/input/input-password';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { InputMultilineVariants  } from 'src/components/common/input/input-multiline';
import { ButtonVariants          } from 'src/components/common/button';
import { InputPhoneVariants      } from 'src/components/common/input/input-phone';
import { RequirementsVariants    } from 'src/components/common/requirements';
import { BlockUIVariants         } from 'src/components/common/block-ui';

import { NavBarVariants          } from 'src/components/features/auth/common/nav-bar';
import { OrganizationVariants    } from 'src/components/features/auth/reg-enter-data/organization';
import { DividerVariants         } from 'src/components/features/auth/reg-enter-data/divider';

import { cssFlexColumn,
         themeColors             } from 'src/providers/theme/shared';



export type RegEnterDataLayout = {
  paddings: number[];

  backgroundColor: string;

  navbarVariant: NavBarVariants;
  labeledVariant: LabeledVariants;
  inputPhoneVariant: InputPhoneVariants;
  inputTextboxVariant: InputTextboxVariants;
  inputPasswordVariant: InputPasswordVariants;
  inputMultilineVariant: InputMultilineVariants;
  submitButtonVariant: ButtonVariants;
  orgVariant: OrganizationVariants;
  dividerVariant: DividerVariants;
  requirementsVariant: RequirementsVariants;

  gapBeforeOrg: number;
  gapAfterOrg: number;
  gapFormElements: number;
  gapButtons: number;

  acceptVariant: DialogVariants;
  blockuiVariant: BlockUIVariants;
  congratVariant: OneActionDialogVariants;
}

export const layout_light: RegEnterDataLayout = {
  paddings: [30, 36, 47, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  navbarVariant: 'light',
  labeledVariant: 'light',
  inputPhoneVariant: 'h48light',
  inputTextboxVariant: 'h48light',
  inputPasswordVariant: 'h48light',
  inputMultilineVariant: 'light',
  submitButtonVariant: 'h48bluefill',
  orgVariant: 'light',
  dividerVariant: 'light',
  requirementsVariant: 'light',

  gapBeforeOrg: 16,
  gapAfterOrg: 28,
  gapFormElements: 20,
  gapButtons: 40,

  acceptVariant: 'light',
  blockuiVariant: 'light',
  congratVariant: 'light',
}

export const layout_dark: RegEnterDataLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: RegEnterDataLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: RegEnterDataLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;

  overflow-y: scroll;
`

export const ActionButtons = styled.div<{
  layout: RegEnterDataLayout,
}>`
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`
