import { createSlice,
         PayloadAction } from '@reduxjs/toolkit';

import * as types        from 'src/services/api/types';

import { RootState     } from 'src/store';

import { Nullable      } from 'src/common';



const SLICE_NAME = 'auth:common';

export interface AuthCommonState {
  account: Nullable<types.auth.AccountWithTokens>;
  authKey: string;
  enterpriseName: string;
}

const initialState: AuthCommonState = {
  account: null,
  authKey: '',
  enterpriseName: '',
}

type AccountChangedAction = PayloadAction<Nullable<types.auth.AccountWithTokens>>;
type AuthKeyChangedAction = PayloadAction<string>;
type EnterpriseNameChangedAction = PayloadAction<string>;

export const authCommonSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    accountChanged: (state, action: AccountChangedAction) => {
      state.account = action.payload;
    },
    authKeyChanged: (state, action: AuthKeyChangedAction) => {
      state.authKey = action.payload;
    },
    enterpriseNameChanged: (state, action: EnterpriseNameChangedAction) => {
      state.enterpriseName = action.payload;
    },
  }
})

const selectAccount = (state: RootState) => state.authCommon.account;
const selectAuthKey = (state: RootState) => state.authCommon.authKey;
const selectEnterpriseName = (state: RootState) => state.authCommon.enterpriseName;

export const authCommonSelectors = {
  selectAccount,
  selectAuthKey,
  selectEnterpriseName,
}

export const authCommonActions = authCommonSlice.actions;

export default authCommonSlice.reducer;