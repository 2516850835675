import * as React               from 'react';

import Drawer                   from '@mui/material/Drawer';

import { Container,
         Item,
         Icon,
         Label,
         BottomSheetVariants,
         bottomsheet_variants } from 'src/components/common/bottom-sheet/ui';



export type BottomSheetMenuItem = {
  action: string;
  icon: React.ReactNode;
  label: string;
  isDanger?: boolean;
}

type Props = {
  variant: BottomSheetVariants;
  isOpened: boolean;
  menu: BottomSheetMenuItem[];
  onMenuItemSelected: (item: BottomSheetMenuItem) => void;
  onClose: () => void;
}
export const BottomSheet: React.FC<Props> = ({
  variant,
  isOpened,
  menu,
  onMenuItemSelected,
  onClose,
}) => {
  const lookAndFeel = bottomsheet_variants.get(variant)!;

  return (
    <Drawer
      anchor = 'bottom'
      open = { isOpened }
      onClose = { onClose }
    >
      <Container lookAndFeel = { lookAndFeel }>
        {menu.map((item) => (
          <Item key = { item.action} lookAndFeel = { lookAndFeel } onClick = { () => onMenuItemSelected(item) }>
            <Icon lookAndFeel = { lookAndFeel } isDanger = { item.isDanger ?? false }>
              { item.icon }
            </Icon>
            <Label lookAndFeel = { lookAndFeel } isDanger = { item.isDanger ?? false }>
              { item.label }
            </Label>
          </Item>
        ))}
      </Container>
    </Drawer>
  );
};
