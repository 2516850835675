
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceTechconsultingVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path fill = 'currentColor' d = 'M13.3429 10.545L11.2782 11.784C11.0782 11.6467 10.8703 11.5221 10.6549 11.4107V8.93262H5.34505V11.4116C5.15033 11.5125 4.96132 11.6239 4.77846 11.7466L2.72483 10.4825L0.0180221 15.2256L2.07077 16.4879C2.05802 16.6421 2.05187 16.79 2.05187 16.9338C2.05187 17.0552 2.05626 17.1797 2.06549 17.3089L0 18.547L2.65538 23.3221L4.72264 22.0821C4.92176 22.2195 5.13055 22.3432 5.34505 22.4545V24.9326H10.6549V22.4545C10.8488 22.3537 11.0378 22.2414 11.2215 22.1195L13.2743 23.3828L15.9811 18.6397L13.9301 17.3774C13.942 17.2249 13.9481 17.0789 13.9481 16.9333C13.9481 16.8101 13.9437 16.6864 13.9354 16.5582L16 15.32L13.3429 10.545ZM12.324 15.7317L12.3996 16.2542C12.4365 16.5125 12.4536 16.7293 12.4536 16.9333C12.4536 17.1524 12.4338 17.3851 12.3908 17.6658L12.3116 18.1856L13.9332 19.1832L12.7495 21.2565L11.1279 20.2575L10.7253 20.5829C10.3921 20.8522 10.0246 21.0703 9.63341 21.231L9.16044 21.4245V23.3819H6.83912V21.4254L6.36703 21.231C5.95736 21.0635 5.57538 20.8344 5.23385 20.5519L4.8356 20.2219L3.20352 21.2013L2.04176 19.1148L3.6756 18.1345L3.60088 17.6128C3.56483 17.3596 3.54681 17.1378 3.54681 16.9338C3.54681 16.7101 3.56659 16.4774 3.60879 16.2004L3.68791 15.6796L2.06681 14.682L3.24879 12.6087L4.87209 13.6086L5.27385 13.2832C5.60703 13.014 5.97451 12.7958 6.36659 12.6343L6.83868 12.4408V10.4843H9.16V12.4417L9.63297 12.6352C10.0435 12.8027 10.4237 13.0318 10.7662 13.3143L11.1644 13.6433L12.7974 12.6644L13.9582 14.7509L12.324 15.7317Z' />
      <path fill = 'currentColor' d = 'M8 13.9326C6.34542 13.9326 5 15.2782 5 16.933C5 18.587 6.34542 19.9326 8 19.9326C9.65458 19.9326 11 18.587 11 16.933C10.9996 15.2782 9.65458 13.9326 8 13.9326ZM8 18.47C7.15164 18.47 6.46238 17.7802 6.46238 16.933C6.46238 16.0846 7.15207 15.3943 8 15.3943C8.84793 15.3943 9.53848 16.0846 9.53848 16.933C9.53848 17.7807 8.84836 18.47 8 18.47Z' />
      <path fill = 'currentColor' d = 'M18 4.14474C16.6869 4.14474 15.6196 5.17024 15.6196 6.43178C15.6196 7.69412 16.6869 8.72042 18 8.72042C19.3122 8.72042 20.3812 7.69412 20.3812 6.43178C20.3812 5.16984 19.3126 4.14474 18 4.14474ZM18 7.3575C17.4698 7.3575 17.0369 6.94273 17.0369 6.43138C17.0369 5.92203 17.4694 5.50686 18 5.50686C18.5309 5.50686 18.963 5.92163 18.963 6.43138C18.963 6.94273 18.5313 7.3575 18 7.3575Z' />
      <path fill = 'currentColor' d = 'M21.9153 1.9485L20.3675 2.80649C20.2753 2.75079 20.181 2.69829 20.0847 2.6498V0.932617H15.9153V2.649C15.8332 2.69108 15.7531 2.73516 15.6751 2.78044L14.1376 1.90602L12.0129 5.35161L13.5479 6.22523C13.5437 6.29576 13.542 6.36469 13.542 6.43282C13.542 6.48531 13.5429 6.53861 13.5454 6.59151L12 7.4487L14.0847 10.9183L15.6325 10.0587C15.7247 10.1144 15.8181 10.1669 15.9153 10.2154V11.9326H20.0847V10.2162C20.166 10.1746 20.2461 10.1309 20.3241 10.0848L21.8615 10.96L23.9871 7.51362L22.4521 6.64C22.4563 6.57027 22.4588 6.50135 22.4588 6.43242C22.4588 6.37992 22.4571 6.32662 22.4555 6.27292L24 5.41653L21.9153 1.9485ZM21.0019 6.00362C21.0248 6.14468 21.0402 6.28615 21.0402 6.43242C21.0402 6.57428 21.0265 6.72456 20.9977 6.89127L20.9189 7.35052L22.0438 7.99171L21.3643 9.09295L20.2386 8.45256L19.8574 8.7399C19.635 8.90741 19.3848 9.04526 19.1145 9.14785L18.6665 9.31857V10.5701H17.3331V9.31857L16.8859 9.14785C16.6065 9.04126 16.3449 8.89659 16.1105 8.71826L15.7335 8.43012L14.604 9.05648L13.9374 7.94803L15.0715 7.31966L14.9973 6.85881C14.9752 6.71935 14.9598 6.57869 14.9598 6.43282C14.9598 6.27533 14.9777 6.12224 15.0031 5.97036L15.0799 5.51311L13.9566 4.87433L14.6361 3.77309L15.7602 4.41347L16.1414 4.12614C16.367 3.95703 16.6173 3.81917 16.8851 3.71738L17.3331 3.54747V2.29514H18.6665V3.54667L19.1137 3.71658C19.3948 3.82478 19.6555 3.96945 19.8882 4.14698L20.2653 4.43511L21.3952 3.80875L22.0626 4.91801L20.931 5.54477L21.0019 6.00362Z' />

    </svg>
  );
};
