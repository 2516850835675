import styled                     from 'styled-components';

import { DialogVariants         } from 'src/components/common/dialog';
import { ButtonVariants         } from 'src/components/common/button';
import { LinkVariants           } from 'src/components/common/link';
import { InputMultilineVariants } from 'src/components/common/input/input-multiline';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,
         cssFlexRow             } from 'src/providers/theme/shared';



type AddQuestionDialogLookAndFeel = {
  paddings: number[];
  gap: number;

  captionTypo: Typo;
  captionColor: string;

  noteTypo: Typo;
  noteColor: string;

  errorTypo: Typo;
  errorColor: string;

  sendTypo: Typo;
  sendColor: string;

  spinnerColor: string;
  spinnerHeight: number;

  inputVariant: InputMultilineVariants;

  dialogVariant: DialogVariants;
  sendButtonVariant: ButtonVariants;
  cancelLinkVarint: LinkVariants;
}

const addquestion_light: AddQuestionDialogLookAndFeel = {
  paddings: [28, 20],
  gap: 24,

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  noteTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  noteColor: themeColors.PrimaryBlack,

  errorTypo: themeTypos.GRTSKREG_W400_S13_LH20,
  errorColor: themeColors.AccentRed1,

  sendTypo: themeTypos.GRTSKREG_W400_S13_LH20,
  sendColor: themeColors.PrimarySteel,

  spinnerColor: themeColors.AccentBlue1,
  spinnerHeight: 48,

  inputVariant: 'light',

  dialogVariant: 'light',
  sendButtonVariant: 'h48bluefill',
  cancelLinkVarint: 'blue13',
}

export type AddQuestionDialogVariants = 'light';

export const addquestiondialog_variants: Map<AddQuestionDialogVariants, AddQuestionDialogLookAndFeel> = 
  new Map<AddQuestionDialogVariants, AddQuestionDialogLookAndFeel>([
    ['light', addquestion_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AddQuestionDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Caption = styled.div<{
  lookAndFeel: AddQuestionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Note = styled.div<{
  lookAndFeel: AddQuestionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.noteTypo)};
  color: ${(args) => args.lookAndFeel.noteColor};
`

export const Error = styled.div<{
  lookAndFeel: AddQuestionDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.errorTypo)};
  color: ${(args) => args.lookAndFeel.errorColor};
`

export const Spinner = styled.div<{
  lookAndFeel: AddQuestionDialogLookAndFeel
}>`
  ${cssFlexRow};
  justify-Content: center;
  align-items: center;

  & svg {
    width: 80px;
    height: 20px;
    color: ${(args) => args.lookAndFeel.spinnerColor};
  }
`