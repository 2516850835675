import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuStatusVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' strokeLinecap = 'square' d = 'M2 4L15 4L18 7V13L15 16H2M9 7L13 9V11L9 13M4.5 10H9' />
    </svg>
  );
};
