import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         typoAsCSS,
         Typo         } from 'src/providers/theme/shared';



type CheckboxLookAndFeel = {
  checkSize: number;
  uncheckBackgroundColor: string;
  uncheckBorderColor: string;
  checkBackgroundColor: string;
  checkBorderColor: string;
  uncheckDisabledBackgroundColor: string;
  uncheckDisabledBorderColor: string;
  checkDisabledBackgroundColor: string;
  checkDisabledBorderColor: string;
  borderSize: number;
  checkSvgColor: string;
  checkDisabledSvgColor: string;

  checkGap: number;

  typo: Typo
  color: string;
  decoration: string;
  paddings: number[];
}

const checkbox_base = {
  checkSize: 24,
  uncheckBackgroundColor: themeColors.PrimaryWhite,
  uncheckBorderColor: themeColors.SecondaryGray4,
  checkBackgroundColor: themeColors.AccentBlue2,
  checkBorderColor: themeColors.AccentBlue2,
  uncheckDisabledBackgroundColor: themeColors.SecondaryGray1,
  uncheckDisabledBorderColor: themeColors.SecondaryGray4,
  checkDisabledBackgroundColor: themeColors.AccentBlue2,
  checkDisabledBorderColor: themeColors.AccentBlue2,
  borderSize: 2,
  checkSvgColor: themeColors.PrimaryWhite,
  checkDisabledSvgColor: themeColors.PrimaryWhite,

  checkGap: 20,

  typo: themeTypos.GRTSKREG_W400_S16_LH21,
  paddings: [2, 0, 0, 0],
}

const checkbox_h24bluetext: CheckboxLookAndFeel = {
  ...checkbox_base,
  color: themeColors.PrimaryBlack,
  decoration: 'none',
}

const checkbox_h24bluelink: CheckboxLookAndFeel = {
  ...checkbox_base,
  color: themeColors.AccentBlue2,
  decoration: 'underline',
}

export type CheckboxVariants = 'h24bluetext' | 'h24bluelink';

export const checkbox_variants: Map<CheckboxVariants, CheckboxLookAndFeel> = 
  new Map<CheckboxVariants, CheckboxLookAndFeel>([
    ['h24bluetext', checkbox_h24bluetext],
    ['h24bluelink', checkbox_h24bluelink],
  ]);

export const Container = styled.div<{
  lookAndFeel: CheckboxLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.checkGap}px;

  width: 100%;
`

export const Control = styled.div<{
  lookAndFeel: CheckboxLookAndFeel
}>`
  ${cssFlexRow};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: ${(args) => args.lookAndFeel.checkSize}px;
  height: ${(args) => args.lookAndFeel.checkSize}px;
  background-color: ${(args) => args.lookAndFeel.uncheckBackgroundColor};
  border: ${(args) => args.lookAndFeel.borderSize}px solid ${(args) => args.lookAndFeel.uncheckBorderColor};

  transition: background-color 0.3s, border-color 0.3s;

  &.checked {
    background-color: ${(args) => args.lookAndFeel.checkBackgroundColor};
    border-color: ${(args) => args.lookAndFeel.checkBorderColor};
  }

  &.disabled {
    background-color: ${(args) => args.lookAndFeel.uncheckDisabledBackgroundColor};
    border-color: ${(args) => args.lookAndFeel.uncheckDisabledBorderColor};
  }

  &.checked.disabled {
    background-color: ${(args) => args.lookAndFeel.checkDisabledBackgroundColor};
    border-color: ${(args) => args.lookAndFeel.checkDisabledBorderColor};
    opacity: 0.5;
  }

  & svg {
    color: ${(args) => args.lookAndFeel.checkSvgColor};
  }

  &.disabled svg {
    color: ${(args) => args.lookAndFeel.checkDisabledSvgColor};
  }

  &.disabled.checked svg {
    color: ${(args) => args.lookAndFeel.checkSvgColor};
    opacity: 0.5;
  }
`

export const LabelText = styled.span<{
  lookAndFeel: CheckboxLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.color};
  text-decoration: ${(args) => args.lookAndFeel.decoration};

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
`

export const LabelLink = styled.a<{
  lookAndFeel: CheckboxLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.color};
  text-decoration: ${(args) => args.lookAndFeel.decoration};

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
`
