import styled                      from 'styled-components';

import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';
import { LinkVariants            } from 'src/components/common/link';
import { BlockUIVariants         } from 'src/components/common/block-ui';

import { PinVariants             } from 'src/components/features/auth/common/pin';
import { WelcomeVariants         } from 'src/components/features/auth/pin-enter/welcome';

import { cssFlexColumn,
         themeColors             } from 'src/providers/theme/shared';



export type PINEnterLayout = {
  paddings: number[];

  backgroundColor: string;

  welcomeVariant: WelcomeVariants;
  pinVariant: PinVariants;
  dialogVariant: DialogVariants;
  pinCheckDialogVariant: OneActionDialogVariants;
  linkVariant: LinkVariants;
  blockUIVariant: BlockUIVariants;
}

export const layout_light: PINEnterLayout = {
  paddings: [48, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  welcomeVariant: 'light',
  pinVariant: 'light',
  dialogVariant: 'light',
  pinCheckDialogVariant: 'light',
  linkVariant: 'blue15',
  blockUIVariant: 'light',
}

export const layout_dark: PINEnterLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: PINEnterLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding: ${(args) => args.layout.paddings[0]}px ${(args) => args.layout.paddings[1]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`
