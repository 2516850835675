import { openDB,
         IDBPDatabase, 
         DBSchema,
         StoreNames    } from 'idb';

import * as types        from 'src/services/api/types';

import { Nullable      } from 'src/common';



interface CurrentSchema extends DBSchema {
  defects: { key: number, value: types.refers.DefectReferElement },
  placeOfFixations: { key: number, value: types.refers.PlaceOfFixationReferElement },
  waysToUseRegected: { key: number, value: types.refers.WaysToUseRegectedReferElement },
  products: { key: number, value: types.refers.ProductReferElement },
  defectTopographies: { key: number, value: types.refers.DefectTopographyReferElement },
  priorities: { key: number, value: types.refers.PriorityReferElement },
  stepsQmMsg: { key: number, value: types.refers.StepQMReferElement },
  statusesQmMsg: { key: number, value: types.refers.StatusQMReferElement },
  typesQmMsg: { key: number, value: types.refers.TypeQMReferElement },
  units: { key: number, value: types.refers.UnitReferElement },
  filials: { key: number, value: types.refers.FilialElement },
  refers: { key: number, value: types.refers.ReferElement },
  adv: { key: number, value: types.adv.AdvElement },
  advStat: { key: number, value: types.adv.AdvStatistics },
  optString: { key: string, value: string },
  optNumber: { key: string, value: number },
  inspections: { key: string, value: types.inspection.Inspection },
}

type StringOpts = 'REFER_HASH' | 'ADV_HASH' | 'QMMSG_VIEWED';

const ALL_REFERS_STORES: StoreNames<CurrentSchema>[] = [
  'defects',
  'placeOfFixations',
  'waysToUseRegected',
  'products',
  'defectTopographies',
  'priorities',
  'stepsQmMsg',
  'statusesQmMsg',
  'typesQmMsg',
  'units',
  'filials',
  'refers',
  'optString',
];

const ALL_ADV_STORES: StoreNames<CurrentSchema>[] = [
  'adv',
  'advStat',
  'optString',
];

const CLEAR_DATA_STORES: StoreNames<CurrentSchema>[] = [
  'defects',
  'placeOfFixations',
  'waysToUseRegected',
  'products',
  'defectTopographies',
  'priorities',
  'stepsQmMsg',
  'statusesQmMsg',
  'typesQmMsg',
  'units',
  'filials',
  'refers',
  'adv',
  'advStat',
  'optString',
  'inspections',
];

const CURRENT_DB_VERSION = 4;

class Db {
  private db: Nullable<IDBPDatabase<CurrentSchema>> = null;

  private async getDbInstance(): Promise<IDBPDatabase<CurrentSchema>> {
    if (this.db !== null)
    {
      return this.db;
    }

    try
    {
      let notifyDbReady: (value: boolean) => void;
      const dbReadyGuard = new Promise<boolean>((resolve) => { notifyDbReady = resolve });
      let upgradeNeeded = false;

      const db = await openDB<CurrentSchema>('checksteel', CURRENT_DB_VERSION, {
        upgrade: async (db, oldVersion, newVersion, transaction, event) => {
          upgradeNeeded = true;
          let currentVersion = oldVersion;

          if (currentVersion === 0)
          {
            currentVersion += 1;
            db.createObjectStore('defects', { keyPath: 'id' });
            db.createObjectStore('placeOfFixations', { keyPath: 'id' });
            db.createObjectStore('waysToUseRegected', { keyPath: 'id' });
            db.createObjectStore('products', { keyPath: 'id' });
            db.createObjectStore('defectTopographies', { keyPath: 'id' });
            db.createObjectStore('priorities', { keyPath: 'id' });
            db.createObjectStore('stepsQmMsg', { keyPath: 'id' });
            db.createObjectStore('statusesQmMsg', { keyPath: 'id' });
            db.createObjectStore('typesQmMsg', { keyPath: 'code' });
           
            db.createObjectStore('adv', { keyPath: 'id' });
            db.createObjectStore('advStat', { autoIncrement: true });

            db.createObjectStore('optString');
            db.createObjectStore('optNumber');

            db.createObjectStore('inspections');
          }

          if (currentVersion === 1)
          {
            currentVersion += 1;
            db.createObjectStore('units', { keyPath: 'id' });
          }

          if (currentVersion === 2)
          {
            currentVersion += 1;
            db.createObjectStore('filials', { keyPath: 'id' });
          }

          if (currentVersion === 3)
          {
            currentVersion += 1;
            db.createObjectStore('refers', { keyPath: ['referId', 'id'] });
          }

          /*
          при появлении версии 5 базы данных - раскоментировать и наполнить логикой
          И НЕ ЗАБУДЬ ОБНОВИТЬ CURRENT_DB_VERSION ВЫШЕ
          if (currentVersion === 4)
          {
            currentVersion += 1;

          }
          */

          notifyDbReady(true);
        },
        blocked: (currentVersion, blockedVersion, event) => {

        },
        blocking: (currentVersion, blockedVersion, event) => {
          if (this.db !== null)
          {
            this.db.close();
            this.db = null;
            window.alert("База данных устарела, пожалуйста, перезагрузите страницу.");
          }
        },
        terminated: () => {
          this.db = null;
        }
      });

      if (upgradeNeeded)
      {
        await dbReadyGuard;
      }

      this.db = db;
      return this.db;
    }
    catch(error)
    {
      throw error;
    }
  }

  async getOptString(opt: StringOpts): Promise<Nullable<string>> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.get('optString', opt)) ?? null;
    }
    catch (error)
    {
      throw error;
    }
  }

  async setOptString(opt: StringOpts, value: string): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      await db.put('optString', value, opt);
    }
    catch (error)
    {
      throw error;
    }
  }

  async updateRefers(refers: types.refers.GetRefersOut, refersHash: string): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      const tx = db.transaction(ALL_REFERS_STORES, 'readwrite');

      const defects = tx.objectStore('defects');
      await defects.clear();
      for (let element of refers.defects)
      {
        await defects.add(element);
      }
      
      const placeOfFixations = tx.objectStore('placeOfFixations');
      await placeOfFixations.clear();
      for (let element of refers.placeOfFixations)
      {
        await placeOfFixations.add(element);
      }
      
      const waysToUseRegected = tx.objectStore('waysToUseRegected');
      await waysToUseRegected.clear();
      for (let element of refers.waysToUseRegected)
      {
        await waysToUseRegected.add(element);
      }

      const products = tx.objectStore('products');
      await products.clear();
      for (let element of refers.products)
      {
        await products.add(element);
      }

      const defectTopographies = tx.objectStore('defectTopographies');
      await defectTopographies.clear();
      for (let element of refers.defectTopographies)
      {
        await defectTopographies.add(element);
      }

      const priorities = tx.objectStore('priorities');
      await priorities.clear();
      for (let element of refers.priorities)
      {
        await priorities.add(element);
      }

      const stepsQmMsg = tx.objectStore('stepsQmMsg');
      await stepsQmMsg.clear();
      for (let element of refers.stepsQmMsg)
      {
        await stepsQmMsg.add(element);
      }

      const statusesQmMsg = tx.objectStore('statusesQmMsg');
      await statusesQmMsg.clear();
      for (let element of refers.statusesQmMsg)
      {
        await statusesQmMsg.add(element);
      }

      const typesQmMsg = tx.objectStore('typesQmMsg');
      await typesQmMsg.clear();
      for (let element of refers.typesQmMsg)
      {
        await typesQmMsg.add(element);
      }

      const units = tx.objectStore('units');
      await units.clear();
      for (let element of refers.units)
      {
        await units.add(element);
      }

      const filials = tx.objectStore('filials');
      await filials.clear();
      for (let element of refers.filials)
      {
        await filials.add(element);
      }

      const refersOS = tx.objectStore('refers');
      await refersOS.clear();
      for (let element of refers.refers)
      {
        await refersOS.add(element);
      }

      const optString = tx.objectStore('optString');
      await optString.put(refersHash, 'REFER_HASH');

      await tx.done;
    }
    catch (error)
    {
      throw error;
    }
  }

  async getDefects(): Promise<types.refers.DefectReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('defects'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getPlaceOfFixations(): Promise<types.refers.PlaceOfFixationReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('placeOfFixations'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getWaysToUseRegected(): Promise<types.refers.WaysToUseRegectedReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('waysToUseRegected'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getProducts(): Promise<types.refers.ProductReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('products'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getDefectTopographies(): Promise<types.refers.DefectTopographyReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('defectTopographies'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getPriorities(): Promise<types.refers.PriorityReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('priorities'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getStepsQmMsg(): Promise<types.refers.StepQMReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('stepsQmMsg'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getStatusesQmMsg(): Promise<types.refers.StatusQMReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('statusesQmMsg'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getTypesQmMsg(): Promise<types.refers.TypeQMReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('typesQmMsg'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getUnits(): Promise<types.refers.UnitReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('units'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getFilials(): Promise<types.refers.FilialElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('filials'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getRefers(): Promise<types.refers.ReferElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('refers'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async updateAdvs(advData: types.adv.AdvElement[], advStatData: types.adv.AdvStatistics[], advHash: string): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      const tx = db.transaction(ALL_ADV_STORES, 'readwrite');

      const adv = tx.objectStore('adv');
      await adv.clear();
      for (let element of advData)
      {
        await adv.add(element);
      }
      
      const advStat = tx.objectStore('advStat');
      await advStat.clear();
      for (let element of advStatData)
      {
        await advStat.add(element);
      }
      
      const optString = tx.objectStore('optString');
      await optString.put(advHash, 'ADV_HASH');

      await tx.done;
    }
    catch (error)
    {
      throw error;
    }
  }

  async getAdv(): Promise<types.adv.AdvElement[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('adv'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getAdvStat(): Promise<types.adv.AdvStatistics[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('advStat'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async getInspections(): Promise<types.inspection.Inspection[]> {
    try
    {
      const db = await this.getDbInstance();
      
      return (await db.getAll('inspections'));
    }
    catch (error)
    {
      throw error;
    }
  }

  async saveInspection(inspection: types.inspection.Inspection): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      await db.put('inspections', inspection, inspection.guid);
    }
    catch (error)
    {
      throw error;
    }
  }

  async removeInspection(guid: string): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      await db.delete('inspections', guid);
    }
    catch (error)
    {
      throw error;
    }
  }

  async clearData(withInspections: boolean = false): Promise<void> {
    try
    {
      const db = await this.getDbInstance();
      const tx = db.transaction(CLEAR_DATA_STORES, 'readwrite');

      const adv = tx.objectStore('adv');
      await adv.clear();

      const advStat = tx.objectStore('advStat');
      await advStat.clear();

      const defects = tx.objectStore('defects');
      await defects.clear();

      const placeOfFixations = tx.objectStore('placeOfFixations');
      await placeOfFixations.clear();

      const waysToUseRegected = tx.objectStore('waysToUseRegected');
      await waysToUseRegected.clear();

      const products = tx.objectStore('products');
      await products.clear();

      const defectTopographies = tx.objectStore('defectTopographies');
      await defectTopographies.clear();

      const priorities = tx.objectStore('priorities');
      await priorities.clear();

      const stepsQmMsg = tx.objectStore('stepsQmMsg');
      await stepsQmMsg.clear();

      const statusesQmMsg = tx.objectStore('statusesQmMsg');
      await statusesQmMsg.clear();

      const typesQmMsg = tx.objectStore('typesQmMsg');
      await typesQmMsg.clear();

      const units = tx.objectStore('units');
      await units.clear();

      const filials = tx.objectStore('filials');
      await filials.clear();

      const refers = tx.objectStore('refers');
      await refers.clear();
      
      const optString = tx.objectStore('optString');
      await optString.delete('ADV_HASH');
      await optString.delete('REFER_HASH');

      if (withInspections)
      {
        const inspections = tx.objectStore('inspections');
        await inspections.clear();
      }

      await tx.done;
    }
    catch (error)
    {
      throw error;
    }
  }
}

export default new Db();