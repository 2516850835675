import React           from 'react';

import ReactDOM        from 'react-dom/client';

import { isDesktop   } from 'react-device-detect';

import App             from './App';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (isDesktop && window.matchMedia('(display-mode: standalone)').matches)
{
  window.resizeTo(500, 900);

  window.addEventListener('resize', (ev) => {
    window.resizeTo(500, 900);
  });
}