import { createSlice,
         createAsyncThunk, 
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'qmmsg:select-company';

export interface QMMsgSelectCompanyState {
  opStatus: AsyncOpStatus;
  opStatusLabel: string;

  companies: types.qmmsg.EskCode[];
  selectedCompanies: number[];
}

const initialState: QMMsgSelectCompanyState = {
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',

  companies: [],
  selectedCompanies: [],
}

// #region getCompaniesAsync
type GetCompaniesArgs = void;
type GetCompaniesResolve = types.qmmsg.EskCode[];
export type GetCompaniesReject = ApiError;
export const getCompaniesAsync = createAsyncThunk<
  GetCompaniesResolve,
  GetCompaniesArgs,
  {
    state: RootState,
    rejectValue: GetCompaniesReject,
  }
>(
  `${SLICE_NAME}/getCompaniesAsync`,
  async (_, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(qmmsgSelectCompanyActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(qmmsgSelectCompanyActions.opStatusLabelChanged('Загрузка списка компаний'));
      const result = await Api.getQMMsgEsk();
      thunkAPI.dispatch(qmmsgSelectCompanyActions.opStatusChanged(AsyncOpStatus.IDLE));
      return result.sort((a, b) => a.title > b.title ? 1 : -1);
    }
    catch (error)
    {
      thunkAPI.dispatch(qmmsgSelectCompanyActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;
type CompaniesChangedAction = PayloadAction<types.qmmsg.EskCode[]>;
type SelectedCompaniesChangedAction = PayloadAction<number>;

export const qmmsgSelectCompanySlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
    companiesChanged: (state, action: CompaniesChangedAction) => {
      state.companies = action.payload;
    },
    selectedCompaniesChanged: (state, action: SelectedCompaniesChangedAction) => {
      state.selectedCompanies = state.selectedCompanies.includes(action.payload) ? 
        state.selectedCompanies.filter((item) => item !== action.payload)
        :
        [...state.selectedCompanies, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesAsync.fulfilled, (state, action) => {
        state.opStatus = AsyncOpStatus.IDLE;
        state.opStatusLabel = '';
        state.companies = action.payload;
        state.selectedCompanies = action.payload.filter((item) => state.selectedCompanies.includes(item.eskCode)).map((item) => item.eskCode);
      })
  },
})

const selectOpStatus = (state: RootState) => state.qmmsgSelectCompany.opStatus;
const selectOpStatusLabel = (state: RootState) => state.qmmsgSelectCompany.opStatusLabel;
const selectCompanies = (state: RootState) => state.qmmsgSelectCompany.companies;
const selectSelectedCompanies = (state: RootState) => state.qmmsgSelectCompany.selectedCompanies;

export const qmmsgSelectCompanySelectors = {
  selectOpStatus,
  selectOpStatusLabel,
  selectCompanies,
  selectSelectedCompanies,
}

export const qmmsgSelectCompanyActions = qmmsgSelectCompanySlice.actions;

export default qmmsgSelectCompanySlice.reducer;