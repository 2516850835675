import * as React          from 'react';

import { Container,
         StatusVariants,
         status_variants } from 'src/components/features/qmmsg/common/status/ui';



type Props = {
  variant: StatusVariants;
  label: string;
}
export const Status: React.FC<Props> = ({
  variant,
  label,
}) => {
  const lookAndFeel = status_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { label }
    </Container>
  );
};
