import styled                 from 'styled-components';

import { DialogVariants     } from 'src/components/common/dialog';
import { TypographyVariants } from 'src/components/common/typography';
import { LinkVariants       } from 'src/components/common/link';

import { cssFlexColumn,
         cssFlexRow         } from 'src/providers/theme/shared';



type ScannerLookAndFeel = {
  paddings: number[];
  height: number;
  cancelPadding: number;

  dialogVariant: DialogVariants;
  accessVariant: TypographyVariants;
  linkVariant: LinkVariants;
}

const scanner_light: ScannerLookAndFeel = {
  paddings: [20],
  height: 400,
  cancelPadding: 10,

  dialogVariant: 'light',
  accessVariant: 'steelw400s14lh17',
  linkVariant: 'blue16',
}

export type ScannerVariants = 'light';

export const scanner_variants: Map<ScannerVariants, ScannerLookAndFeel> = 
  new Map<ScannerVariants, ScannerLookAndFeel>([
    ['light', scanner_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ScannerLookAndFeel
}>`
  ${cssFlexColumn};

  padding: ${(args) => args.lookAndFeel.paddings[0]}px;

  & video {

  }
`

export const CancelContainer = styled.div<{
  lookAndFeel: ScannerLookAndFeel
}>`
  ${cssFlexRow}

  padding: ${(args) => args.lookAndFeel.cancelPadding}px 0;
`
