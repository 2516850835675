import React                    from 'react';

import { RouteObject          } from 'react-router-dom';

import { InspectionListScreen } from 'src/containers/main/inspection-list';
import { NotificationsScreen  } from 'src/containers/main/notifications';
import { ProfileScreen        } from 'src/containers/main/profile';
import { PersonalScreen       } from 'src/containers/main/personal';
import { HelpScreen           } from 'src/containers/main/help';
import { InfoScreen           } from 'src/containers/main/info';
import { ServicesScreen       } from 'src/containers/main/services';
import { AdminScreen          } from 'src/containers/main/admin';
import { TokenScreen          } from 'src/containers/main/token';
import { MessagesScreen       } from 'src/containers/main/messages';
import { ShadeSelectionScreen } from 'src/containers/main/shade-selection';

import { BaseUrl              } from 'src/providers/routing/base-url';
import { WithInitNavigate     } from 'src/providers/routing/with-init-navigate';



class InspectionListUrl extends BaseUrl {
  build(): InspectionListUrl {
    this._url = '/main/inspections';
    return this;
  }
}

class NotificationsScreenUrl extends BaseUrl {
  build(): NotificationsScreenUrl {
    this._url = '/main/notifications';
    return this;
  }
}

class ProfileScreenUrl extends BaseUrl {
  build(): ProfileScreenUrl {
    this._url = '/main/profile';
    return this;
  }
}

class PersonalScreenUrl extends BaseUrl {
  build(): PersonalScreenUrl {
    this._url = '/main/personal';
    return this;
  }
}

class HelpScreenUrl extends BaseUrl {
  build(): HelpScreenUrl {
    this._url = '/main/help';
    return this;
  }
}

class InfoScreenUrl extends BaseUrl {
  build(): InfoScreenUrl {
    this._url = '/main/info';
    return this;
  }
}

class ServicesScreenUrl extends BaseUrl {
  build(): ServicesScreenUrl {
    this._url = '/main/services';
    return this;
  }
}

class AdminScreenUrl extends BaseUrl {
  build(): AdminScreenUrl {
    this._url = '/main/admin';
    return this;
  }
}

class TokenScreenUrl extends BaseUrl {
  build(): TokenScreenUrl {
    this._url = '/main/token';
    return this;
  }
}

class MessagesScreenUrl extends BaseUrl {
  build(inspectionNum: string): MessagesScreenUrl {
    this._url = `/main/messages/${inspectionNum}`;
    return this;
  }
}

class ShadeSelectionScreenUrl extends BaseUrl {
  build(): ShadeSelectionScreenUrl {
    this._url = '/main/shade-selection';
    return this;
  }
}

export const MainUrls = {
  MainInspections: new InspectionListUrl('/main/inspections'),
  MainNotifications: new NotificationsScreenUrl('/main/notifications'),
  MainProfile: new ProfileScreenUrl('/main/profile'),
  MainPersonal: new PersonalScreenUrl('/main/personal'),
  MainHelp: new HelpScreenUrl('/main/help'),
  MainInfo: new InfoScreenUrl('/main/info'),
  MainServices: new ServicesScreenUrl('/main/services'),
  MainAdmin: new AdminScreenUrl('/main/admin'),
  MainToken: new TokenScreenUrl('/main/token'),
  MainMessages: new MessagesScreenUrl('/main/messages/:inspectionNum'),
  MainShadeSelection: new ShadeSelectionScreenUrl('/main/shade-selection'),
}

export const MainRoutes: RouteObject[] = [
  {
    path: MainUrls.MainInspections.pattern,
    element: <WithInitNavigate isProtectedRoute><InspectionListScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainNotifications.pattern,
    element: <WithInitNavigate isProtectedRoute><NotificationsScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainProfile.pattern,
    element: <WithInitNavigate isProtectedRoute><ProfileScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainPersonal.pattern,
    element: <WithInitNavigate isProtectedRoute><PersonalScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainHelp.pattern,
    element: <WithInitNavigate isProtectedRoute><HelpScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainInfo.pattern,
    element: <WithInitNavigate isProtectedRoute><InfoScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainServices.pattern,
    element: <WithInitNavigate isProtectedRoute><ServicesScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainAdmin.pattern,
    element: <WithInitNavigate isProtectedRoute><AdminScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainToken.pattern,
    element: <WithInitNavigate isProtectedRoute><TokenScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainMessages.pattern,
    element: <WithInitNavigate isProtectedRoute><MessagesScreen /></WithInitNavigate>
  },
  {
    path: MainUrls.MainShadeSelection.pattern,
    element: <WithInitNavigate isProtectedRoute><ShadeSelectionScreen /></WithInitNavigate>
  },
];
