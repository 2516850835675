import * as React              from 'react';

import { Container,
         Caption,
         Version,
         AppCaptionVariants,
         appcaption_variants } from 'src/components/features/auth/common/app-caption/ui';



type Props = {
  variant: AppCaptionVariants;
  caption: string;
  version: string;
}
export const AppCaption: React.FC<Props> = ({
  variant,
  caption,
  version,
}) => {
  const lookAndFeel = appcaption_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        { caption }
      </Caption>
      <Version lookAndFeel = { lookAndFeel }>
        { version }
      </Version>
    </Container>
  );
};
