import * as React             from 'react';

import { TrashVector        } from 'src/components/vector/trash';
import { ClipboardVector    } from 'src/components/vector/clipboard';

import { Dialog             } from 'src/components/common/dialog';

import { RevokeTokenDialog  } from 'src/components/features/main/token/revoke-token-dialog';
import { Container,
         ContentContainer,
         ExpireText,
         TokenText,
         ActionsContainer,
         ActionButton,
         TokenItemVariants,
         tokenitem_variants } from 'src/components/features/main/token/token-item/ui';

import * as types             from 'src/services/api/types';



type Props = {
  variant: TokenItemVariants;
  token: types.token.RestToken;
  onRevoke: (token: types.token.RestToken) => void;
  onCopy: () => void;
}
export const TokenItem: React.FC<Props> = ({
  variant,
  token,
  onRevoke,
  onCopy,
}) => {
  const lookAndFeel = tokenitem_variants.get(variant)!;

  const [revokeOpened, setRevokeOpened] = React.useState<boolean>(false);

  const onCopyClipboardHandler = () => {
    navigator.clipboard.writeText(token.token);
    onCopy();
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <ContentContainer lookAndFeel = { lookAndFeel }>
        <ExpireText lookAndFeel = { lookAndFeel }>
          { `Действует до: ${new Date(token.timestampExpire).toLocaleDateString()}` }
        </ExpireText>
        <TokenText lookAndFeel = { lookAndFeel }>
          { token.token }
        </TokenText>
      </ContentContainer>
      <ActionsContainer lookAndFeel = { lookAndFeel }>
        <ActionButton lookAndFeel = { lookAndFeel } onClick = { () => setRevokeOpened(true) }>
          <TrashVector />
        </ActionButton>
        <ActionButton lookAndFeel = { lookAndFeel } onClick = { onCopyClipboardHandler }>
          <ClipboardVector />
        </ActionButton>
      </ActionsContainer>
      <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { revokeOpened }>
        <RevokeTokenDialog
          variant = { lookAndFeel.revokeVariant }
          onAccept = { () => onRevoke(token) }
          onCancel =  { () => setRevokeOpened(false) }
        />
      </Dialog>
    </Container>
  );
};
