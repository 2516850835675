import React from 'react';



type Props = {
  className?: string;
}
export const HistoryVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M2 12.418C2 14.844 2.88197 17.1874 4.4816 19.0114C6.08123 20.8354 8.28941 22.0157 10.6947 22.3324C13.1001 22.6491 15.5385 22.0805 17.5557 20.7326C19.5729 19.3848 21.0313 17.3496 21.6592 15.0061C22.2872 12.6627 22.0417 10.171 20.9687 7.99508C19.8957 5.81919 18.0682 4.1076 15.8268 3.17917C13.5854 2.25075 11.0829 2.16883 8.7856 2.94867C6.48827 3.7285 4.55279 5.31692 3.33975 7.41796M3.33975 7.41796L2.5 2.41797M3.33975 7.41796H8.11111' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 8.41797L12 13.418L16 14.418' />
    </svg>
  );
};
