import * as React             from 'react';

import { ArrowRightVector   } from 'src/components/vector/arrow-right';

import { Container, 
         ItemContainer,
         ItemKeyValue,
         ItemKey,
         ItemValue,
         ItemArrow,
         PieceItemVariants,
         pieceitem_variants } from 'src/components/features/inspection/view/piece-item/ui';

import * as types             from 'src/services/api/types';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch     } from 'src/store';



type Props = {
  variant: PieceItemVariants;
  piece: types.inspection.Piece;
}
export const PieceItem: React.FC<Props> = ({
  variant,
  piece,
}) => {
  const lookAndFeel = pieceitem_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const selectedPiece = useAppSelector(storeApi.inspection.view.selectors.selectPieceSelected);
  const isSelected = selectedPiece?.guid === piece.guid;

  const pieceTitle = React.useMemo(() => {
    return piece.isPiece ?
    (
      ((piece.groupAtt !== null && piece.groupAtt !== '') || (piece.qcNum !== null && piece.qcNum !== '')) ?
        'Плавка / Партия атт/ Сертиф'
        :
        'Плавка / Партия/Ед'
    )
    :
    'Идентификационный №';
  }, [piece]);

  const pieceText = React.useMemo(() => {
    const isGroupAttHasValue = piece.groupAtt !== null && piece.groupAtt !== '';
    const isQcNumHasValue = piece.qcNum !== null && piece.qcNum !== '';
    const isHeatHasValue = piece.heat !== null && piece.heat !== '';
    const isHeatEmpty = piece.heat === null || piece.heat === '';
    const isPieceHasValue = piece.piece !== null && piece.piece !== '';

    if (piece.isPiece)
    {
      if (isGroupAttHasValue || isQcNumHasValue)
      {
        if (isHeatHasValue && isGroupAttHasValue && isQcNumHasValue)
        {
          return `${piece.heat} / ${piece.groupAtt} / ${piece.qcNum}`;
        }
        else
        {
          if (isHeatHasValue)
          {
            if (isGroupAttHasValue)
            {
              return `${piece.heat} / ${piece.groupAtt} / Нет сертиф`;
            }
            else
            {
              return `${piece.heat} / Нет партии атт / ${piece.qcNum}`;
            }
          }
          else
          {
            if (isGroupAttHasValue && isQcNumHasValue)
            {
              return `Нет плавки / ${piece.groupAtt} / ${piece.qcNum}`;
            }
            else
            {
              if (isGroupAttHasValue)
              {
                return `Нет плавки / ${piece.groupAtt} / Нет сертиф`;
              }
              else
              {
                return `Нет плавки / Нет партии атт / ${piece.qcNum}`;
              }
            }
          }
        }
      }
      else
      {
        if (isHeatHasValue && isPieceHasValue)
        {
            return `${piece.heat} / ${piece.piece}`;
        }
        else
        {
          if (isHeatEmpty && isPieceHasValue)
          {
            return `Нет плавки / ${piece.piece}`;
          }
          else if (isHeatHasValue)
          {
            return `${piece.heat} / Нет партии/ед`;
          }
          else
          {
            return `Нет плавки / Нет партии/ед`;
          }
        }
      }
    }
    else
    {
      if (piece.qmetId === 0)
      {
        return 'Нет номера';
      }
      else
      {
        return `${piece.qmetId}`;
      }
    }
  }, [piece]);

  const pieceIsError = piece.pieceNumId === null;

  const onSelectHandler = () => {
    dispatch(storeApi.inspection.view.actions.changePieceSelected(piece));
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <ItemContainer lookAndFeel = { lookAndFeel } isSelected = { isSelected } onClick = { onSelectHandler }>
        <ItemKeyValue lookAndFeel = { lookAndFeel }>
          <ItemKey lookAndFeel = { lookAndFeel }>
            { pieceTitle }
          </ItemKey>
          <ItemValue lookAndFeel = { lookAndFeel } isError = { pieceIsError }>
            { pieceText }
          </ItemValue>
        </ItemKeyValue>
        <ItemArrow lookAndFeel = { lookAndFeel }>
          <ArrowRightVector />
        </ItemArrow>
      </ItemContainer>
    </Container>
  );
};
