import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region Common

export type ShadeSelectionItem = {
  /** Эталонный рулон */
  pieceEtalon: string;
  /** Список подбираемых/подобранных рулонов */
  pieces: { piece: string }[];
}

export type ShadeSelectionError = {
  /** Код ошибки */
  errorCode: string;
  /** Текст ошибки */
  errorText: string;
  /** Рулон */
  piece: string;
}

// #endregion


// #region POST service/parts

export type PostShadeSelectionsIn = ShadeSelectionItem;

export type PostShadeSelectionsOut = {
  /** Массив подходящих комбинаций */
  data: ShadeSelectionItem[];
  /** Массив ошибочных рулонов */
  errors: ShadeSelectionError[];
};

export const preparePostShadeSelections
  : PrepareCallHandler<PostShadeSelectionsIn, PostShadeSelectionsOut> = (arg: PostShadeSelectionsIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/service/parts`,
      method: 'POST',
      data: arg,

      pwaLocked: 'access',
      pwaRetry: false,
    },
});

// #endregion
