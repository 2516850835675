import React from 'react';



type Props = {
  className?: string;
}
export const HelpVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9.50439 8.2174C9.70603 7.64479 10.104 7.16194 10.6279 6.85437C11.1517 6.54681 11.7676 6.43438 12.3665 6.537C12.9653 6.63962 13.5085 6.95067 13.8998 7.41505C14.2911 7.87943 14.5053 8.46717 14.5044 9.07419C14.5044 10.7878 12.0001 11.6449 12.0001 11.6449L12 13M12 14V16' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M3 3H20V18H15L12 21L9 18H4V6' />
    </svg>
  );
};
