
import React from 'react';



type Props = {
  className?: string;
}
export const ServicePretenzStatusVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12.5 7.32324V12.4997L9.5 15.3232' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M21.7522 14.2222C21.9144 13.5075 22 12.7638 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C12.7638 22 13.5075 21.9144 14.2222 21.7522' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M17 19L19 21L23 17' />
    </svg>
  );
};
