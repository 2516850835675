import styled                 from 'styled-components';

import { CloseVector        } from 'src/components/vector/close';

import { TabSwitchVariants  } from 'src/components/common/tab-switch';
import { DialogVariants     } from 'src/components/common/dialog';
import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors        } from 'src/providers/theme/shared';



type GroupAttHelpDialogLookAndFeel = {
  paddings: number[];
  gap: number;
  groupGap: number;

  captionTypo: Typo;
  captionColor: string;

  messageTypo: Typo;
  messageColor: string;
  messageAccentColor: string;

  groupHeaderTypo: Typo;
  groupHeaderColor: string;

  dialogVariant: DialogVariants;
  switchExampleVariant: TabSwitchVariants;
  closeButtonVariant: IconButtonVariants;
}

const groupatthelpdialog_light: GroupAttHelpDialogLookAndFeel = {
  paddings: [24],
  gap: 24,
  groupGap: 8,

  captionTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  captionColor: themeColors.PrimaryBlack,

  messageTypo: themeTypos.GRTSKREG_W400_S14_LH20,
  messageColor: themeColors.SecondaryGray9,
  messageAccentColor: themeColors.AccentRed1,

  groupHeaderTypo: themeTypos.GRTSKREG_W400_S12_LH18,
  groupHeaderColor: themeColors.PrimarySteel,

  dialogVariant: 'light',
  switchExampleVariant: 'h32',
  closeButtonVariant: 's48bluered',
}

export type GroupAttHelpDialogVariants = 'light';

export const groupatthelpdialog_variants: Map<GroupAttHelpDialogVariants, GroupAttHelpDialogLookAndFeel> = 
  new Map<GroupAttHelpDialogVariants, GroupAttHelpDialogLookAndFeel>([
    ['light', groupatthelpdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  ${cssFlexColumn};
  align-items: center;
  gap: ${(args) => args.lookAndFeel.gap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
  position: relative;

  height: 90vh;
`

export const Scroll = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  ${cssFlexColumn};
  align-items: center;
  gap: ${(args) => args.lookAndFeel.gap}px;
  overflow-y: auto;
`

export const Caption = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};

  & span {
    color: ${(args) => args.lookAndFeel.messageAccentColor};
  }
`

export const Close = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  position: absolute;
  top: 0;
  right: 0;
`

export const Group = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  ${cssFlexColumn};
  align-items: center;
  gap: ${(args) => args.lookAndFeel.groupGap}px;

  & svg {
    width: 100%;
  }
`

export const GroupHeader = styled.div<{
  lookAndFeel: GroupAttHelpDialogLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.groupHeaderTypo)};
  color: ${(args) => args.lookAndFeel.groupHeaderColor};
`


export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;
