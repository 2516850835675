import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type TabSwitchLookAndFeel = {
  typo: Typo;

  activeBgColor: string;
  inactiveBgColor: string;
  activeLabelColor: string;
  inactiveLabelColor: string;

  tabHeight: number;
  tabGap: number;
}

const tabswitch_h32: TabSwitchLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S12_LH32,

  activeBgColor: themeColors.AccentBlue2,
  inactiveBgColor: themeColors.SecondaryGray2,
  activeLabelColor: themeColors.PrimaryWhite,
  inactiveLabelColor: themeColors.SecondaryGray5,

  tabHeight: 32,
  tabGap: 4,
}

export type TabSwitchVariants = 'h32';

export const tabswitch_variants: Map<TabSwitchVariants, TabSwitchLookAndFeel> = 
  new Map<TabSwitchVariants, TabSwitchLookAndFeel>([
    ['h32', tabswitch_h32],
  ]);

export const Container = styled.div<{
  lookAndFeel: TabSwitchLookAndFeel;
}>`
  ${cssFlexRow}

  width: 100%;
  height: ${(args) => args.lookAndFeel.tabHeight}px;
`

export const Tab = styled.div<{
  lookAndFeel: TabSwitchLookAndFeel;
}>`
  ${cssFlexRow}

  gap: ${(args) => args.lookAndFeel.tabGap}px;
  
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  width: 100%;
  height: ${(args) => args.lookAndFeel.tabHeight}px;
  
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}

  background-color: ${(args) => args.lookAndFeel.inactiveBgColor };
  color: ${(args) => args.lookAndFeel.inactiveLabelColor };

  &.active {
    background-color: ${(args) => args.lookAndFeel.activeBgColor };
    color: ${(args) => args.lookAndFeel.activeLabelColor };
  }

  white-space: nowrap;

  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;
`;
