import styled                      from 'styled-components';

import { DialogVariants          } from 'src/components/common/dialog';
import { OneActionDialogVariants } from 'src/components/common/one-action-dialog';
import { BlockUIVariants         } from 'src/components/common/block-ui';
import { LabeledVariants         } from 'src/components/common/labeled';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { ButtonVariants          } from 'src/components/common/button';
import { InputPhoneVariants      } from 'src/components/common/input/input-phone';

import { NavBarVariants          } from 'src/components/features/auth/common/nav-bar';

import { cssFlexColumn,
         cssFlexRow,
         themeColors             } from 'src/providers/theme/shared';



export type RegRequestCodeLayout = {
  paddings: number[];

  backgroundColor: string;

  navbarVariant: NavBarVariants;
  labeledVariant: LabeledVariants;
  inputPhoneVariant: InputPhoneVariants;
  inputTextboxVariant: InputTextboxVariants;
  cancelButtonVariant: ButtonVariants;
  submitButtonVariant: ButtonVariants;
  blockuiVariant: BlockUIVariants;
  dialogVariant: DialogVariants;
  completeVariant: OneActionDialogVariants;

  gapForm: number;
  gapFormElements: number;
  gapButtons: number;
  gapInnerButtons: number;
}

export const layout_light: RegRequestCodeLayout = {
  paddings: [30, 36, 47, 36],

  backgroundColor: themeColors.BackgroundFillLight,

  navbarVariant: 'light',
  labeledVariant: 'light',
  inputPhoneVariant: 'h48light',
  inputTextboxVariant: 'h48light',
  cancelButtonVariant: 'h48blueoutline',
  submitButtonVariant: 'h48bluefill',
  blockuiVariant: 'light',
  dialogVariant: 'light',
  completeVariant: 'light',

  gapForm: 48,
  gapFormElements: 20,
  gapButtons: 30,
  gapInnerButtons: 16,
}

export const layout_dark: RegRequestCodeLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: RegRequestCodeLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: RegRequestCodeLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;

  overflow-y: scroll;
`

export const ActionButtons = styled.div<{
  layout: RegRequestCodeLayout,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.layout.gapInnerButtons}px;

  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`