import styled                        from 'styled-components';

import { InputStatusOkVector        } from 'src/components/vector/input-status/ok';
import { InputStatusWarningVector   } from 'src/components/vector/input-status/warning';

import { DialogVariants             } from 'src/components/common/dialog';
import { LabeledVariants            } from 'src/components/common/labeled';
import { LabeledContentVariants     } from 'src/components/common/labeled-content';
import { TypographyVariants         } from 'src/components/common/typography';
import { InputTextboxVariants       } from 'src/components/common/input/input-textbox';
import { ButtonVariants             } from 'src/components/common/button';
import { InputPhoneVariants         } from 'src/components/common/input/input-phone';
import { BlockUIVariants            } from 'src/components/common/block-ui';

import { NavBarVariants             } from 'src/components/features/common/nav-bar';
import { BlockCaptionVariants       } from 'src/components/features/main/common/block-caption';

import { BooleanFlagsListVariants   } from 'src/components/features/main/personal/boolean-flags-list';
import { EmailConfirmDialogVariants } from 'src/components/features/main/personal/email-confirm-dialog';
import { PhoneConfirmDialogVariants } from 'src/components/features/main/personal/phone-confirm-dialog';

import { themeColors, 
         cssFlexColumn,   
         cssFlexRow                 } from 'src/providers/theme/shared';



export type PersonalLayout = {
  backgroundColor: string;
  statusOkColor: string;
  statusWarningColor: string;

  paddings: number[];
  formPaddings: number[];

  formGap: number;
  actionsGap: number;
  actionsPaddings: number[];

  navbarVariant: NavBarVariants;
  labeledContentVariant: LabeledContentVariants;
  labeledVariant: LabeledVariants;
  typoCompanyVariant: TypographyVariants;
  inputTextboxVariant: InputTextboxVariants;
  inputPhoneVariant: InputPhoneVariants;
  blockCaptionVariant: BlockCaptionVariants;
  booleanFlagsListVariant: BooleanFlagsListVariants;
  submitButtonVariant: ButtonVariants;
  cancelButtonVariant: ButtonVariants;
  dialogVariant: DialogVariants;
  emailConfirmDialogVariant: EmailConfirmDialogVariants;
  phoneConfirmDialogVariant: PhoneConfirmDialogVariants;
  blockuiVariant: BlockUIVariants;
}

export const layout_light: PersonalLayout = {
  backgroundColor: themeColors.BackgroundFillLight,
  statusOkColor: themeColors.AccentBlue2,
  statusWarningColor: themeColors.AccentRed1,

  paddings: [20, 16],
  formPaddings: [20, 0, 0, 0],

  formGap: 17,
  actionsGap: 16,
  actionsPaddings: [40, 0, 0, 0],

  navbarVariant: 'lightblue',
  labeledContentVariant: 'blue1w400s14lh17gap4',
  labeledVariant: 'light',
  typoCompanyVariant: 'blackw600s18lh24',
  inputTextboxVariant: 'h48light',
  inputPhoneVariant: 'h48light',
  blockCaptionVariant: 'light40t12b',
  booleanFlagsListVariant: 'light',
  submitButtonVariant: 'h48bluefill',
  cancelButtonVariant: 'h48blueoutline',
  dialogVariant: 'light',
  emailConfirmDialogVariant: 'light',
  phoneConfirmDialogVariant: 'light',
  blockuiVariant: 'light',
}

export const layout_dark: PersonalLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: PersonalLayout,
}>`
  ${cssFlexColumn};
  width: 100vw;
  height: 100dvh;

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: PersonalLayout,
}>`
  ${cssFlexColumn};

  padding: ${(args) => args.layout.paddings[0]}px ${(args) => args.layout.paddings[1]}px;
  
  overflow: scroll;
`

export const Form = styled.div<{
  layout: PersonalLayout,
}>`
  ${cssFlexColumn};
  width: 100%;

  gap: ${(args) => args.layout.formGap}px;
`

export const ActionButtons = styled.div<{
  layout: PersonalLayout,
}>`
  ${cssFlexRow};
  width: 100%;

  gap: ${(args) => args.layout.actionsGap}px;
  padding-top: ${(args) => args.layout.actionsPaddings[0]}px;

  & > div:nth-child(1) {
    width: 40%;
    flex-grow: 1;
  }
  & > div:nth-child(2) {
    width: 60%;
    flex-grow: 2;
  }
`

export const StatusOk = styled(InputStatusOkVector)<{color: string}>`
  color: ${(args) => args.color};
`;

export const StatusWarning = styled(InputStatusWarningVector)<{color: string}>`
  color: ${(args) => args.color};
`;
