import React from 'react';



type Props = {
  className?: string;
}
export const ThreeDotsVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <rect fill = 'currentColor' x = '10' y = '2' width = '4' height = '4' />
      <rect fill = 'currentColor' x = '10' y = '10' width = '4' height = '4' />
      <rect fill = 'currentColor' x = '10' y = '18' width = '4' height = '4'/>
    </svg>
  );
};
