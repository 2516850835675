import React                      from 'react';

import { AddPhotoVector         } from 'src/components/vector/add-photo';
import { AddFileVector          } from 'src/components/vector/add-file';

import { Container,
         PhotoArea,
         Label,
         LabelsStack,
         AllowCount,
         AttachmentAddVariants,
         attachmentadd_variants } from 'src/components/features/inspection/edit/attachment-add/ui';

import * as types                 from 'src/services/api/types';



type Props = {
  variant: AttachmentAddVariants;
  attachmentType: types.inspection.PieceFileType;
  label: string;
  allowCount?: number;
  isRequired?: boolean;
  onTap: (attachmentType: types.inspection.PieceFileType) => void;
}
export const AttachmentAdd: React.FC<Props> = ({ variant, attachmentType, allowCount, label, isRequired = false, onTap }) => {
  const lookAndFeel = attachmentadd_variants.get(variant)!;

  let icon = <AddPhotoVector />;

  /*
  let label = 'Общий вид';

  switch (attachmentType)
  {
    case types.inspection.PieceFileType.GENERAL:
      break;
    case types.inspection.PieceFileType.PHOTO:
      label = 'Доп. фото';
      break;
    case types.inspection.PieceFileType.PDF:
      icon = <AddFileVector />;
      label = 'Доп. файл';
      break;
  }

  if (customLabel !== undefined)
  {
    label = customLabel;
  }
  */

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <PhotoArea lookAndFeel = { lookAndFeel } onClick = { () => onTap(attachmentType) }>
        { icon }
      </PhotoArea>
      <LabelsStack lookAndFeel = { lookAndFeel }>
        <Label lookAndFeel = { lookAndFeel }>
          { label }
          { isRequired && <i>*</i> }
        </Label>
        {allowCount && (
          <AllowCount lookAndFeel = { lookAndFeel }>
            { `Осталось: ${allowCount} шт.` }
          </AllowCount>
        )}
      </LabelsStack>
    </Container>
  );
}
