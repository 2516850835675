import React from 'react';



type Props = {
  className?: string;
}
export const ToolbarMessageVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9.1413 20.5176L8.03445 20.0413L3.67459 21.5005L2.50027 20.1471L3.26452 18.0832L4.02876 16.0192C3.40148 14.7723 3.07642 13.3942 3.08005 11.9972C3.08069 10.3267 3.54451 8.68943 4.41955 7.2687C5.29459 5.84796 6.54629 4.6999 8.03445 3.95311C9.27634 3.32327 10.6488 2.99688 12.0401 3.00052H12.5672C14.7644 3.12223 16.8396 4.05341 18.3956 5.61575C19.9516 7.1781 20.879 9.26183 21.0002 11.468V11.9972C21.0039 13.3942 20.6788 14.7723 20.0515 16.0192C19.3078 17.5135 18.1644 18.7703 16.7494 19.6489C15.3345 20.5275 13.7038 20.9932 12.0401 20.9939C11.7047 20.9947 11.3704 20.9764 11.0387 20.9393'/>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 10.5H8M16 10.5H14M10 14H8M16 14H12'/>
    </svg>
  );
};
