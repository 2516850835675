import * as React          from 'react';

import { useTheme        } from 'styled-components';

import { ArrowBackVector } from 'src/components/vector/arrowback';

import { LabeledContent  } from 'src/components/common/labeled-content';
import { VGap            } from 'src/components/common/flex/vgap';
import { Link            } from 'src/components/common/link';

import { NavBar          } from 'src/components/features/common/nav-bar';
import { BlockCaption    } from 'src/components/features/main/common/block-caption';

import { InfoLink        } from 'src/components/features/main/help/info-link';
import { Content         } from 'src/components/features/main/help/content';

import { Screen,
         ScreenBody,
         layout_light,
         layout_dark     } from 'src/containers/main/help/layout';

import { Urls            } from 'src/providers/routing';



export const HelpScreen = () => {
  const theme = useTheme();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  return (
    <Screen layout = { layout }>
      <NavBar 
        variant = { layout.navbarVariant }
        label = 'Справка'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainProfile.build().navigate()
          }
        ]}
      />
      <ScreenBody layout = { layout }>
        <BlockCaption variant = { layout.blockCaptionVariant }>Справочная информация</BlockCaption>
        <InfoLink variant = { layout.infoLinkVariant } onTap = { () => Urls.MainInfo.build().navigate() }>Информация</InfoLink>
        <Content variant = { layout.contentVariant } />
        <BlockCaption variant = { layout.blockCaptionVariant }>Контакты</BlockCaption>
        <LabeledContent variant = { layout.labeledContentVariant } label = 'E-mail'>
          <Link
            variant = { layout.linkVariant }
            label = 'ukpp@severstal.com'
            url = 'mailto:ukpp@severstal.com'
          />
        </LabeledContent>
        <VGap size = { layout.labeledGap } />
        <LabeledContent variant = { layout.labeledContentVariant } label = 'Телефон'>
          <Link
            variant = { layout.linkVariant }
            label = '+7 (911) 506-16-14'
            url = 'tel:79115061614'
          />
        </LabeledContent>
      </ScreenBody>
    </Screen>
  );
};
