import { createSlice,
         createAsyncThunk,
         PayloadAction,
         createSelector    } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';
import Util                  from 'src/services/util';

import { RootState         } from 'src/store';

import { isInviteCode,
         isPhone           } from 'src/validators';



const SLICE_NAME = 'auth:reg-start';

export interface AuthRegStartState {
  phone: string;
  phoneError: string;
  inviteCode: string;
  inviteCodeError: string;
}

const initialState: AuthRegStartState = {
  phone: '',
  phoneError: '',
  inviteCode: '',
  inviteCodeError: '',
}

// #region checkInviteCodeAsync
type CheckInviteCodeArgs = void;
type CheckInviteCodeResolve = types.auth.PostAuthKeyRegOut;
export type CheckInviteCodeReject = {
  reason: 'validation' | 'api';
  validationErrors?: {
    phoneError?: string;
    inviteCodeError?: string;
  };
  apiError?: ApiError;
}
export const checkInviteCodeAsync = createAsyncThunk<
  CheckInviteCodeResolve,
  CheckInviteCodeArgs,
  {
    state: RootState,
    rejectValue: CheckInviteCodeReject
  }
>(
  `${SLICE_NAME}/checkInviteCodeAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const phone = selectPhone(state);
    const inviteCode = selectInviteCode(state);
    const phoneError = isPhone(phone).error ?? '';
    const inviteCodeError = isInviteCode(inviteCode).error ?? '';

    if (phoneError.length > 0 || inviteCodeError.length > 0)
    {
      return thunkAPI.rejectWithValue({
        reason: 'validation',
        validationErrors: {
          ...(phoneError.length > 0 ? { phoneError } : {}),
          ...(inviteCodeError.length > 0 ? { inviteCodeError } : {}),
        }
      });
    }

    try
    {
      return await Api.postAuthKeyReg({
        phone: parseInt(Util.selectDigitsFromString(phone), 10),
        inviteCode: inviteCode,
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue({
        reason: 'api',
        apiError: (error as ApiError),
      })
    }
  }
);
// #endregion

type PhoneChangedAction = PayloadAction<string>;
type InviteCodeChangedAction = PayloadAction<string>;

export const authRegStartSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    phoneChanged: (state, action: PhoneChangedAction) => {
      state.phone = action.payload;
    },
    phoneErrorFixed: (state) => {
      state.phoneError = '';
    },
    inviteCodeChanged: (state, action: InviteCodeChangedAction) => {
      state.inviteCode = action.payload;
    },
    inviteCodeErrorFixed: (state) => {
      state.inviteCodeError = '';
    },
    formCleared: (state) => {
      state.phone = '';
      state.phoneError = '';
      state.inviteCode = '';
      state.inviteCodeError = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkInviteCodeAsync.rejected, (state, action) => {
        switch (action.payload?.reason)
        {
          case 'validation':
            state.phoneError = action.payload?.validationErrors?.phoneError ?? '';
            state.inviteCodeError = action.payload?.validationErrors?.inviteCodeError ?? '';
            break;
        }
      });
  },
})

const selectPhone = (state: RootState) => state.authRegStart.phone;
const selectPhoneError = (state: RootState) => state.authRegStart.phoneError;
const selectInviteCode = (state: RootState) => state.authRegStart.inviteCode;
const selectInviteCodeError = (state: RootState) => state.authRegStart.inviteCodeError;
const selectFormFilled = createSelector(
  [selectPhone, selectInviteCode],
  (phone, code) => {
    const purePhone = Util.selectDigitsFromString(phone);
    return (
      Util.isPhoneValid(purePhone)
      &&
      code.trim().length === 8
    );
  }
);

export const authRegStartSelectors = {
  selectPhone,
  selectPhoneError,
  selectInviteCode,
  selectInviteCodeError,
  selectFormFilled,
}

export const authRegStartActions = authRegStartSlice.actions;

export default authRegStartSlice.reducer;