import styled                 from 'styled-components';

import { TypographyVariants } from 'src/components/common/typography';

import { cssFlexColumn, 
         cssFlexRow, 
         themeColors        } from 'src/providers/theme/shared';



type StepElementLookAndFeel = {
  activeIconBackgroundColor: string;
  activeIconColor: string;
  inactiveIconBackgroundColor: string;
  inactiveIconColor: string;
  dashColor: string;

  minHeight: number;
  iconSize: number;
  columnGap: number;
  textGap: number;
  textTopPadding: number;
  textBottomPadding: number;

  stepHeaderTypographyVariant: TypographyVariants;
  stepContentTypographyVariant: TypographyVariants;
}

const stepelement_light: StepElementLookAndFeel = {
  activeIconBackgroundColor: themeColors.AccentBlue2,
  activeIconColor: themeColors.PrimaryWhite,
  inactiveIconBackgroundColor: themeColors.SecondaryGray10NA,
  inactiveIconColor: themeColors.SecondaryGray11NA,
  dashColor: themeColors.SecondaryGray3,

  minHeight: 73,
  iconSize: 40,
  columnGap: 16,
  textGap: 4,
  textTopPadding: 11,
  textBottomPadding: 16,

  stepHeaderTypographyVariant: 'blackw600s15lh20',
  stepContentTypographyVariant: 'steelw400s12lh14',
}

export type StepElementVariants = 'light';

export const stepelement_variants: Map<StepElementVariants, StepElementLookAndFeel> = 
  new Map<StepElementVariants, StepElementLookAndFeel>([
    ['light', stepelement_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: StepElementLookAndFeel;
  isLast: boolean;
}>`
  ${cssFlexRow}
  width: 100%;
  min-height: ${(args) => args.isLast ? 0 : args.lookAndFeel.minHeight}px;
  gap: ${(args) => args.lookAndFeel.columnGap}px;
`

export const ColumnIcon = styled.div<{
  lookAndFeel: StepElementLookAndFeel;
}>`
  ${cssFlexColumn}
  width: ${(args) => args.lookAndFeel.iconSize}px;
  align-items: center;
`

export const Icon = styled.div<{
  lookAndFeel: StepElementLookAndFeel;
}>`
  ${cssFlexRow}
  width: ${(args) => args.lookAndFeel.iconSize}px;
  height: ${(args) => args.lookAndFeel.iconSize}px;
  justify-content: center;
  align-items: center;

  background-color: ${(args) => args.lookAndFeel.inactiveIconBackgroundColor};
  color: ${(args) => args.lookAndFeel.inactiveIconColor};

  &.active {
    background-color: ${(args) => args.lookAndFeel.activeIconBackgroundColor};
    color: ${(args) => args.lookAndFeel.activeIconColor};
  }
`

export const Dash = styled.div<{
  lookAndFeel: StepElementLookAndFeel;
}>`
  flex-grow: 2;
  width: 1px;
  border-right: 1px dashed ${(args) => args.lookAndFeel.dashColor};
`

export const TextColumn = styled.div<{
  lookAndFeel: StepElementLookAndFeel;
}>`
  ${cssFlexColumn}
  gap: ${(args) => args.lookAndFeel.textGap}px;
  
  padding-top: ${(args) => args.lookAndFeel.textTopPadding}px;
  padding-bottom: ${(args) => args.lookAndFeel.textBottomPadding}px;
`