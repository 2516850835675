import * as React                  from 'react';

import { Container,
         Label,
         Content,
         LabeledContentVariants,
         labeledcontent_variants } from 'src/components/common/labeled-content/ui';



type Props = {
  variant: LabeledContentVariants;
  label: string;
  additionalLabelNode?: React.ReactNode;
  children: React.ReactNode;
}
export const LabeledContent: React.FC<Props> = ({
  variant,
  label,
  additionalLabelNode,
  children,
}) => {
  const lookAndFeel = labeledcontent_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Label lookAndFeel = { lookAndFeel }>
        { label }
        { additionalLabelNode || null }
      </Label>
      <Content lookAndFeel = { lookAndFeel }>
        { children }
      </Content>
    </Container>
  );
};
