import * as React          from 'react';

import { StarVector      } from 'src/components/vector/star';

import { Ripple          } from 'src/components/common/ripple';
import { Container,
         Stars,
         Star,
         Label,
         RatingVariants,
         rating_variants } from 'src/components/common/rating/ui';



const labels = ['Ужасно', 'Плохо', 'Так себе', 'Хорошо', 'Отлично'];

type Props = {
  variant: RatingVariants;
  value: number;
  emptyText?: string;
  isHideLabel?: boolean;
  isDisabled?: boolean;
  onRating: (value: number) => void;
}
export const Rating: React.FC<Props> = ({
  variant,
  value,
  emptyText,
  isHideLabel = false,
  isDisabled = false,
  onRating,
}) => {
  const lookAndFeel = rating_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } isHideLabel = { isHideLabel }>
      <Stars lookAndFeel = { lookAndFeel }>
        { labels.map((label, index) => (
          <Ripple isTapAllowed = { !isDisabled } onTap = { () => onRating(index) }>
            <Star
              key = { label }
              lookAndFeel = { lookAndFeel }
              isActive = { value >= index }
            >
              <StarVector />
            </Star>
          </Ripple>
        ))}
      </Stars>
      {!isHideLabel &&
        <Label lookAndFeel = { lookAndFeel }>
          { value > -1 ? `${labels[value]}!` : emptyText }
        </Label>
      }
    </Container>
  );
};
