import styled                      from 'styled-components';

import { ButtonVariants          } from 'src/components/common/button';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { DefectItemVariants      } from 'src/components/features/inspection/edit/defect-item';

import { cssFlexColumn,
         cssFlexRow,
         themeColors             } from 'src/providers/theme/shared';



type DefectsListLookAndFeel = {
  gap: number;
  actionsGap: number;
  twoColumnActionsGap: number;
  padding: number[];

  hrColor: string;

  defectItemVariant: DefectItemVariants;
  fillButtonVariant: ButtonVariants;
  strokeButtonVariant: ButtonVariants;
  strokeButtonH36Variant: ButtonVariants;
  twoActionDialogVariant: TwoActionDialogVariants;
}

const defectslist_light: DefectsListLookAndFeel = {
  gap: 24,
  actionsGap: 20,
  twoColumnActionsGap: 20,
  padding: [24, 16, 0, 0],

  hrColor: themeColors.SecondaryGray3,
  
  defectItemVariant: 'light',
  strokeButtonVariant: 'h48blueoutline',
  fillButtonVariant: 'h48bluefill',
  strokeButtonH36Variant: 'h36blueoutline',
  twoActionDialogVariant: 'light',
}

export type DefectsListVariants = 'light';

export const defectslist_variants: Map<DefectsListVariants, DefectsListLookAndFeel> = 
  new Map<DefectsListVariants, DefectsListLookAndFeel>([
    ['light', defectslist_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const TwoColumnActions = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.twoColumnActionsGap}px;
`

export const List = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  overflow-y: auto;
  padding-right: ${(args) => args.lookAndFeel.padding[1]}px;
  flex-grow: 2;
`

export const Actions = styled.div<{
  lookAndFeel: DefectsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
  padding-right: ${(args) => args.lookAndFeel.padding[1]}px;
  padding-top: ${(args) => args.lookAndFeel.padding[0]}px;
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`