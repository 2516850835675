import * as React                from 'react';

import { Container,
         OrganizationVariants,
         organization_variants } from 'src/components/features/auth/reg-enter-data/organization/ui';



type Props = {
  variant: OrganizationVariants;
  children: React.ReactNode;
}
export const Organization: React.FC<Props> = ({
  variant,
  children,
}) => {
  const lookAndFeel = organization_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { children }
    </Container>
  );
};
