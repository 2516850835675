import styled                from 'styled-components';

import { AppSplashVariants } from 'src/components/features/auth/splash/app-splash';

import { themeColors       } from 'src/providers/theme/shared';



type SplashLayout = {
  backgroundColor: string;
  splashVariant: AppSplashVariants;
}

export const layout_light: SplashLayout = {
  backgroundColor: themeColors.PrimaryBlue100,
  splashVariant: 'light',
}

export const layout_dark: SplashLayout = {
  backgroundColor: themeColors.PrimaryBlue100,
  splashVariant: 'light',
}

export const Screen = styled.div<{
  layout: SplashLayout,
}>`
  width: 100%;
  height: 100%;

  background-color: ${(args) => args.layout.backgroundColor};
`
