import * as React             from 'react';

import { ArrowRightVector   } from 'src/components/vector/arrow-right';
import { TrashVector        } from 'src/components/vector/trash';

import { Checkbox           } from 'src/components/common/checkbox';
import { IconButton         } from 'src/components/common/icon-button';
import { TwoActionDialog    } from 'src/components/common/two-action-dialog';

import { Container, 
         DeleteContainer,
         ItemContainer,
         ItemKeyValue,
         ItemKey,
         ItemValue,
         ItemArrow,
         PieceItemVariants,
         pieceitem_variants } from 'src/components/features/inspection/edit/piece-item/ui';

import { InspectionState    } from 'src/services/api/types/inspection';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch     } from 'src/store';



type Props = {
  variant: PieceItemVariants;
  pieceGuid: string;
}
export const PieceItem: React.FC<Props> = ({
  variant,
  pieceGuid,
}) => {
  const lookAndFeel = pieceitem_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const isSelected = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceIsSelected(store, pieceGuid));
  const isMarkedForDelete = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceIsMarkedForDelete(store, pieceGuid));
  const isPieceDeleteAllowed = useAppSelector(storeApi.inspection.edit.selectors.selectPieceDeleteAllowed);
  const pieceTitle = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceTitle(store, pieceGuid));
  const pieceText = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceText(store, pieceGuid));
  const pieceIsError = useAppSelector((store) => storeApi.inspection.edit.selectors.selectPieceIsError(store, pieceGuid));

  const isMultiDelete = inspectionState === InspectionState.CREATE;

  const [isConfirmOpened, changeConfirmOpened] = React.useState<boolean>(false);

  const onDeleteClickHandler = () => {
    changeConfirmOpened(true);
  }

  const onMarkForDeleteHandler = (checked: boolean) => {
    if (checked)
    {
      dispatch(storeApi.inspection.edit.actions.pieceForDeleteOneMarked(pieceGuid));
    }
    else
    {
      dispatch(storeApi.inspection.edit.actions.pieceForDeleteOneUnmarked(pieceGuid));
    }
  }

  const onSelectHandler = () => {
    dispatch(storeApi.inspection.edit.actions.pieceForEditSelected(pieceGuid));
  }

  const onConfirmDeleteCancelHandler = () => {
    changeConfirmOpened(false);
  }

  const onConfirmDeleteAcceptHandler = () => {
    changeConfirmOpened(false);
    dispatch(storeApi.inspection.edit.actions.pieceOneDeleted(pieceGuid));
    setTimeout(() => dispatch(storeApi.inspection.edit.async.saveImmediatelyAsync({ isSaveEditedPiece: false })), 200);
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {(isPieceDeleteAllowed || isMultiDelete) && (
        <DeleteContainer lookAndFeel = { lookAndFeel } isSelected = { isSelected }>
          {isMultiDelete && (
            <Checkbox
              variant = { lookAndFeel.checkboxVariant }
              value = { isMarkedForDelete }
              onChange = { onMarkForDeleteHandler }
            />
          )}
          {isPieceDeleteAllowed && (
            <IconButton
              variant = { lookAndFeel.deleteButtonVariant }
              isTapAllowed
              onTap = { onDeleteClickHandler }
            >
              <TrashVector />
            </IconButton>
          )}
        </DeleteContainer>
      )}
      <ItemContainer lookAndFeel = { lookAndFeel } isSelected = { isSelected } onClick = { onSelectHandler }>
        <ItemKeyValue lookAndFeel = { lookAndFeel }>
          <ItemKey lookAndFeel = { lookAndFeel }>
            { pieceTitle }
          </ItemKey>
          <ItemValue lookAndFeel = { lookAndFeel } isError = { pieceIsError }>
            { pieceText }
          </ItemValue>
        </ItemKeyValue>
        <ItemArrow lookAndFeel = { lookAndFeel }>
          <ArrowRightVector />
        </ItemArrow>
      </ItemContainer>

      <TwoActionDialog
        variant = { lookAndFeel.deleteConfirmVariant }
        opened = { isConfirmOpened }
        caption = 'Удалить выбранную позицию?'
        isDanger
        cancelLabel = 'Отмена'
        actionLabel = 'Удалить'
        onCancel = { onConfirmDeleteCancelHandler }
        onAction = { onConfirmDeleteAcceptHandler }
      />
    </Container>
  );
};
