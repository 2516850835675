import React from 'react';



type Props = {
  className?: string;
}
export const CloseVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '12' height = '12' viewBox = '0 0 12 12' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M1 11L6 6M6 6L11 1M6 6L1 1M11 11L8 8' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
