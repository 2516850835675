import React from 'react';



type Props = {
  className?: string;
}
export const GearVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M20 18L19.0473 19.5981L16.8453 18.3064C16.2727 18.7612 15.6365 19.1372 14.9526 19.4185V22H9.04728V19.4185C8.36341 19.1372 7.72729 18.7612 7.15469 18.3064L4.95268 19.5981L2 14.4019L4.20144 13.1106C4.15182 12.7476 4.12618 12.3768 4.12618 12C4.12618 11.6232 4.15182 11.2524 4.20144 10.8894L2.00002 9.59808L3 8M21 16L22 14.4019L19.7985 13.1105C19.8482 12.7476 19.8738 12.3768 19.8738 12C19.8738 11.6232 19.8482 11.2524 19.7985 10.8894L22 9.59808L19.0473 4.40192L16.8453 5.69363C16.2727 5.23882 15.6365 4.86277 14.9526 4.5815V2H9.04728V4.58153C8.36342 4.86279 7.7273 5.23883 7.1547 5.69362L4.9527 4.40192L4 6' />
      <circle cx = '12' cy = '12' r = '3' stroke = 'currentColor' strokeWidth = '2' strokeLinecap = 'round' strokeLinejoin = 'round' />
    </svg>
  );
};
