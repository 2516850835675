import styled           from 'styled-components';

import { themeColors,
         cssFlexRow   } from 'src/providers/theme/shared';



type DialogLookAndFeel = {
  backColor: string;
  backZIndex: number;

  containerBackgroundColor: string;
}

const dialog_light: DialogLookAndFeel = {
  backColor: 'rgba(0, 47, 108, 0.5)',
  backZIndex: 1300,

  containerBackgroundColor: themeColors.PrimaryWhite,
}

export type DialogVariants = 'light';

export const dialog_variants: Map<DialogVariants, DialogLookAndFeel> = 
  new Map<DialogVariants, DialogLookAndFeel>([
    ['light', dialog_light],
  ]);

export const Root = styled.div<{
  lookAndFeel: DialogLookAndFeel
}>`
  position: fixed;
  z-index: ${(args) => args.lookAndFeel.backZIndex};
  width: 100vw;
  height: 100dvh;

  top: 0;
  left: 0;

  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  background-color: ${(args) => args.lookAndFeel.backColor};

  opacity: 0;
  left: 9999px;

  &.opened {
    left: 0;
    opacity: 100;
  }

  transition: opacity 0.3s;
`

export const Container = styled.div<{
  lookAndFeel: DialogLookAndFeel,
}>`
  width: 90%;
  max-height: 90%;
  overflow: auto;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`
