import styled          from 'styled-components';

import { Typo,
         themeTypos,
         typoAsCSS,
         themeColors } from 'src/providers/theme/shared';



type BlockCaptionLookAndFeel = {
  typo: Typo;
  color: string;
  requiredColor: string;
  asteriskPadding: number;
}

const blockcaption_light: BlockCaptionLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S18_LH24,
  color: themeColors.PrimaryBlack,
  requiredColor: themeColors.AccentRed1,
  asteriskPadding: 5,
}

export type BlockCaptionVariants = 'light';

export const blockcaption_variants: Map<BlockCaptionVariants, BlockCaptionLookAndFeel> = 
  new Map<BlockCaptionVariants, BlockCaptionLookAndFeel>([
    ['light', blockcaption_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: BlockCaptionLookAndFeel;
  isRequired: boolean;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.typo)};
  color: ${(args) => args.lookAndFeel.color};

  &::after {
    content: '${(args) => args.isRequired ? '*' : ''}';
    display: inline-block;
    color: ${(args) => args.lookAndFeel.requiredColor};
    padding-left: ${(args) => args.lookAndFeel.asteriskPadding}px;
  }
`
