import * as React              from 'react';

import { Typography          } from 'src/components/common/typography';
import { Button              } from 'src/components/common/button';
import { LabeledContent      } from 'src/components/common/labeled-content';

import { Container,
         Block,
         Hr,
         DefectItemVariants,
         defectitem_variants } from 'src/components/features/inspection/complaint/defect-item/ui';

import { ComplaintType,
         InspectionState     } from 'src/services/api/types/inspection';

import { useAppSelector, 
         storeApi,    
         useAppDispatch      } from 'src/store';



type Props = {
  variant: DefectItemVariants;
  defectGuid: string;
}
export const DefectItem: React.FC<Props> = ({
  variant,
  defectGuid,
}) => {
  const lookAndFeel = defectitem_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const defectTitle = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectTitle(store, defectGuid));
  const qmetIds = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectQmetIds(store, defectGuid));
  const pieceIds = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectPieceIds(store, defectGuid));
  const groupAttIds = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectGroupAttIds(store, defectGuid));
  const complaintType = useAppSelector((store) => storeApi.inspection.edit.selectors.selectDefectComplaintType(store, defectGuid));

  let action = 'edit';
  if (inspectionState === InspectionState.ERROR)
  {
    action = 'view';
  }
  else if (complaintType === null)
  {
    action = 'new';
  }

  const onActionHandler = () => {
    dispatch(storeApi.inspection.edit.actions.complaintForEditSelected(`${action}:${defectGuid}`));
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Block lookAndFeel = { lookAndFeel }>
        {defectTitle !== null && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Дефект'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ defectTitle }</Typography>
          </LabeledContent>
        )}
        {qmetIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Идентификационный №'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ qmetIds }</Typography>
          </LabeledContent>
        )}
        {pieceIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Номер рулона/листа/трубы'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ pieceIds }</Typography>
          </LabeledContent>
        )}
        {groupAttIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Партия атт/сертификат'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ groupAttIds }</Typography>
          </LabeledContent>
        )}
        {complaintType !== null && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Рекламация'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ complaintType === ComplaintType.COMPLAINT ? 'Рекламация' : 'Замечание' }</Typography>
          </LabeledContent>
        )}
      </Block>
      {(action !== 'view' || complaintType !== null) && (
        <Button
          variant = { lookAndFeel.actionButtonVariant }
          label = { action === 'view' ? 'Просмотреть требование' : (complaintType === null ? 'Добавить требование' : 'Изменить требование') }
          onTap = { onActionHandler }
        />
      )}
      <Hr lookAndFeel = { lookAndFeel } />
    </Container>
  );
};
