import styled                 from 'styled-components';

import { CloseVector        } from 'src/components/vector/close';

import { DialogVariants     } from 'src/components/common/dialog';
import { IconButtonVariants } from 'src/components/common/icon-button';
import { DateLabelVariants  } from 'src/components/common/date-label';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,        
         cssFlexRow         } from 'src/providers/theme/shared';



type RemarkStatusDialogLookAndFeel = {
  paddings: number[];
  gap: number;

  captionTypo: Typo;
  captionColor: string;

  labelTypo: Typo;
  labelColor: string;
  labelGap: number;

  contentTypo: Typo;
  contentColor: string;

  dialogVariant: DialogVariants;
  datelabelVariant: DateLabelVariants;
  closeButtonVariant: IconButtonVariants;
}

const remarkstatusdialog_light: RemarkStatusDialogLookAndFeel = {
  paddings: [24],
  gap: 24,

  captionTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  captionColor: themeColors.AccentBlue2,

  labelTypo: themeTypos.GRTSKREG_W400_S12_LH14,
  labelColor: themeColors.SecondaryGray5,
  labelGap: 7,

  contentTypo: themeTypos.GRTSKREG_W400_S14_LH16,
  contentColor: themeColors.PrimaryBlack,

  dialogVariant: 'light',
  datelabelVariant: 'h24bluecalendar',
  closeButtonVariant: 's48bluered',
}

export type RemarkStatusDialogVariants = 'light';

export const remarkstatusdialog_variants: Map<RemarkStatusDialogVariants, RemarkStatusDialogLookAndFeel> = 
  new Map<RemarkStatusDialogVariants, RemarkStatusDialogLookAndFeel>([
    ['light', remarkstatusdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
  position: relative;
`

export const Caption = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const DateBlock = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${cssFlexRow};
  justify-align: space-between;
`

export const ContentBlock = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.labelGap}px;
`

export const Label = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
`

export const Content = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.contentTypo)};
  color: ${(args) => args.lookAndFeel.contentColor};
`

export const Close = styled.div<{
  lookAndFeel: RemarkStatusDialogLookAndFeel
}>`
  position: absolute;
  top: 0;
  right: 0;
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;
