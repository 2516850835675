import styled                      from 'styled-components';

import { IconButtonVariants      } from 'src/components/common/icon-button';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';
import { InputTextboxVariants    } from 'src/components/common/input/input-textbox';
import { LabeledVariants         } from 'src/components/common/labeled';
import { TypographyVariants      } from 'src/components/common/typography';
import { SwitchVariants          } from 'src/components/common/switch';

import { PieceListVariants       } from 'src/components/features/inspection/view/piece-list';
import { StaticValueVariants     } from 'src/components/features/inspection/view/static-value';

import { AttachmentPDFVariants   } from 'src/components/features/inspection/edit/attachment-pdf';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,        
         cssFlexRow              } from 'src/providers/theme/shared';



export type DefectItemLookAndFeel = {
  minHeight: number;

  headerGap: number;
  headerStatusWidth: number;
  headerStatusPaddingTop: number;
  headerStatusPaddingRight: number;
  headerStatusOKColor: string;
  headerStatusErrorColor: string;
  headerLabelTypo: Typo;
  headerLabelColor: string;
  headerExpandColor: string;

  bodyGap: number;
  pieceListPaddings: number[];

  actionWidth: number;

  datetimeTypo: Typo;
  datetimeColor: string;
  datetimeIconColor: string;
  datetimeGap: number;

  photoListGap: number;
  
  selectorButtonVariant: IconButtonVariants;

  switchFieldGap: number;
  switchFieldTypography: TypographyVariants;
  switchVariant: SwitchVariants;

  hrColor: string;

  staticValueVariant: StaticValueVariants;
  labeledVariant: LabeledVariants;
  textboxVariant: InputTextboxVariants;
  deleteConfirmVariant: TwoActionDialogVariants;
  pieceListVariant: PieceListVariants;
  actionButtonVariant: IconButtonVariants;
  attachmentPDFVariants: AttachmentPDFVariants;
}

const defectitem_light: DefectItemLookAndFeel = {
  minHeight: 48,

  headerGap: 4,
  headerStatusWidth: 20,
  headerStatusPaddingTop: 4,
  headerStatusPaddingRight: 4,
  headerStatusOKColor: themeColors.AccentGreen1,
  headerStatusErrorColor: themeColors.AccentRed1,
  headerLabelTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  headerLabelColor: themeColors.PrimaryBlack,
  headerExpandColor: themeColors.AccentBlue2,

  bodyGap: 20,
  pieceListPaddings: [24],

  actionWidth: 48,

  datetimeTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  datetimeColor: themeColors.PrimaryBlack,
  datetimeIconColor: themeColors.AccentBlue1,
  datetimeGap: 8,

  photoListGap: 10,
  
  selectorButtonVariant: 's48bluered',

  switchFieldGap: 10,
  switchFieldTypography: 'blackw400s16lh22',
  switchVariant: 'w40h24',

  hrColor: themeColors.SecondaryGray3,

  staticValueVariant: 'light',
  labeledVariant: 'light',
  textboxVariant: 'h48light',
  deleteConfirmVariant: 'light',
  pieceListVariant: 'light',
  actionButtonVariant: 's48bluered',
  attachmentPDFVariants: 'light',
}

export type DefectItemVariants = 'light';

export const defectitem_variants: Map<DefectItemVariants, DefectItemLookAndFeel> = 
  new Map<DefectItemVariants, DefectItemLookAndFeel>([
    ['light', defectitem_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.bodyGap}px;
  width: 100%;
  flex-shrink: 0;
  min-height: ${(args) => args.lookAndFeel.minHeight}px;
`

export const Header = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.headerGap}px;
  align-items: center;
`

export const HeaderStatus = styled.div<{
  lookAndFeel: DefectItemLookAndFeel;
  isOk: boolean;
}>`
  width: ${(args) => args.lookAndFeel.headerStatusWidth}px;
  flex-shrink: 0;
  padding-top: ${(args) => args.lookAndFeel.headerStatusPaddingTop}px;
  padding-right: ${(args) => args.lookAndFeel.headerStatusPaddingRight}px;
  color: ${(args) => args.isOk ? args.lookAndFeel.headerStatusOKColor : args.lookAndFeel.headerStatusErrorColor};
`

export const HeaderLabel = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerLabelTypo)}
  color: ${(args) => args.lookAndFeel.headerLabelColor};
  flex-grow: 2;
`

export const HeaderExpandAction = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  width: ${(args) => args.lookAndFeel.actionWidth}px;
  flex-shrink: 0;

  & svg {
    color: ${(args) => args.lookAndFeel.headerExpandColor};
  }
`

export const DefectDateTime = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.datetimeGap}px;
  ${(args) => typoAsCSS(args.lookAndFeel.datetimeTypo)}
  color: ${(args) => args.lookAndFeel.datetimeColor};

  & > svg {
    color: ${(args) => args.lookAndFeel.datetimeIconColor};
  }
`

export const DefectBody = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.bodyGap}px;
`

export const PieceListContainer = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  padding-bottom: ${(args) => args.lookAndFeel.pieceListPaddings[0]}px;
`

export const FieldWithSwitch = styled.div<{
  lookAndFeel: DefectItemLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.switchFieldGap}px;

  & > *:first-child {
    flex-grow: 2;
  }
`

export const PhotoList = styled.div<{
  lookAndFeel: DefectItemLookAndFeel,
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${(args) => args.lookAndFeel.photoListGap}px;
  grid-row-gap: ${(args) => args.lookAndFeel.photoListGap}px;
`

export const Hr = styled.div<{
  lookAndFeel: DefectItemLookAndFeel
}>`
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`