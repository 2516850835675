import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

export type EskCode = {
  /** ESK код */
  eskCode: number;
  /** Название организации */
  title: string;
}

export type QMMsgStep = {
  /** ИД шага */
  id: number;
  /** Название шага */
  title: string;
  /** Комментарий к шагу, м.б. null */
  note: Nullable<string>;
}

export type QMMsgStatus = {
  /** ИД статуса */
  id: number;
  /** Название статуса */
  title: string;
}

export type QMMsgCondition = {
  /** ИД состояния */
  id: number;
  /** Название состояния */
  title: string;
}

export type QMMsgType = {
  /** Код типа обращения */
  code: string;
  /** Название типа обращения */
  title: string;
}

export type QMMsgNumberMarriage = {
  /** Количество */
  quantity: number;
  /** Единица измерения */
  unit: string;
}

export type QMMsgRating	= {
  /** Оценка, м.б. null */
  rating: Nullable<string>;
  /** Примечание к оценке, м.б. null */
  note: Nullable<string>;
}

export enum QMMsgSignMsgEnum {
  /** претензия */
  COMPLAINT = 'complaint',
  /** уведомление */
  NOTIF = 'notif'
}

export enum QMMsgActionEnum {
  /** в ожидании */
  AWAITING = 'awaiting',
  /** подтверждено */
  APPROVED = 'approved',
  /** возвращено в работу */
  COMEBACK = 'comeback'
}

export type QMMsg = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** Номер уведомления/претензии */
  qmMsgNumber: string;
  /** timestamp регистрации */
  timestampReg: number;
  /** Описание уведомления/претензии, м.б. null */
  description: Nullable<string>;
  /** guid уведомления/претензии из checksteel, м.б. null */
  guidInspection: Nullable<string>;
  /** complaint - претензия, notif - уведомление */
  signMsg: QMMsgSignMsgEnum;
  /**  */
  step: QMMsgStep;
  /**  */
  status: QMMsgStatus;
  /**  */
  condition: QMMsgCondition;
  /**  */
  type: QMMsgType;
  /**  */
  numberMarriage: QMMsgNumberMarriage;
  /**  */
  rating: QMMsgRating;
  /** Исходящий номер, м.б. null */
  outgoingNumber: Nullable<string>;
  /** timestamp исполнения, м.б. null */
  timestampExecution: Nullable<number>;
  /** ФИО исполнителя, м.б. null */
  fioExecutor: Nullable<string>;
  /** true - фаворит */
  isFavorite: boolean;
  /** True - когда либо возвращаласчь на доработку */
  isComeback: boolean;
  /** Действие: awaiting - в ожидании, approved - подтверждено, comeback - возвращено в работу, м.б. null */
  action: Nullable<QMMsgActionEnum>;
}

export type QMMsgCompany = {
  /** Код ESK */
  eskCode: number;
  /** Название организации */
  title: string;
  /**  */
  qmMsgs: QMMsg[];
}

export type QMMsgQuestionUser = {
  /** ИД пользователя, задавшего вопрос */
  id: number;
  /** ФИО пользователя, задавшего вопрос */
  fio: string;
  /** Должность пользователя, задавшего вопрос */
  post: string;
}

export type QMMsgQuestion = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** Guid вопроса */
  guid: string;
  /** Пользователь */
  user: QMMsgQuestionUser;
  /** Вопрос */
  question: string;
  /** timestamp вопроса */
  timestamp: number;
  /** true - отправлено специалистам Северсталь */
  isSend: boolean;
}

// #endregion


// #region GET qm_msg/esk

export type GetQMMsgEskIn = void;

export type GetQMMsgEskOut = EskCode[];

export const prepareGetQMMsgEsk
  : PrepareCallHandler<GetQMMsgEskIn, GetQMMsgEskOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/esk`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET qm_msg/claims

export type GetQMMsgClaimsIn = {
  /** Список кодов ESK через запятую - передается только в режиме СТПК, в остальных случаях null */
  eskCodes?: string;
  /** GUID осмотра */
  guidInspection?: string;
  /** Номер уведомления/претензии, м.б. null */
  qmMsgNumber?: string;
  /** Исходящий номер клиента, м.б. null */
  outgoingNum?: string;
  /** ИД статуса, м.б. null */
  statusId?: number;
  /** Код шага, м.б. null */
  stepCode?: string;
  /** ИД типа обращения, м.б. null */
  typeCode?: string;
  /** timestamp начала даты регистрации, м.б. null */
  timestampBeg?: number;
  /** timestamp окончания даты регистрации, м.б. null */
  timestampEnd?: number;
  /** С какой записи выбирать данные */
  skip?: number;
  /** Сколько записей выбирать */
  count?: number;
};

export type GetQMMsgClaimsOut = {
  /** Список компаний */
  company: QMMsgCompany[];
};

export const prepareGetQMMsgClaims
  : PrepareCallHandler<GetQMMsgClaimsIn, GetQMMsgClaimsOut> = (args) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/claims`,
      method: 'GET',
      responseType: 'json',
      params: args,

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET qm_msg/claims/report

export type GetQMMsgClaimsReportIn = {
  /** Список кодов ESK через запятую - передается только в режиме СТПК, в остальных случаях null */
  eskCodes?: string;
  /** GUID осмотра */
  guidInspection?: string;
  /** Номер уведомления/претензии, м.б. null */
  qmMsgNumber?: string;
  /** Исходящий номер клиента, м.б. null */
  outgoingNum?: string;
  /** ИД статуса, м.б. null */
  statusId?: number;
  /** Код шага, м.б. null */
  stepCode?: string;
  /** ИД типа обращения, м.б. null */
  typeCode?: string;
  /** timestamp начала даты регистрации, м.б. null */
  timestampBeg?: number;
  /** timestamp окончания даты регистрации, м.б. null */
  timestampEnd?: number;
  /** timestamp начала даты регистрации, м.б. null - передавать при первоначальной выборке null, при последующей пагинации полученное в выходной модели значение */
  timestampLast?: number;
};

export type GetQMMsgClaimsReportOut = void;

export const prepareGetQMMsgClaimsReport
  : PrepareCallHandler<GetQMMsgClaimsReportIn, GetQMMsgClaimsReportOut> = (args) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/claims/report`,
      method: 'GET',
      responseType: 'json',
      params: args,

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET qm_msg/claims/favorite

export type GetQMMsgClaimsFavoriteIn = {
  /** Список кодов ESK через запятую - передается только в режиме СТПК, в остальных случаях null */
  eskCodes?: string;
  /** GUID осмотра */
  guidInspection?: string;
  /** Номер уведомления/претензии, м.б. null */
  qmMsgNumber?: string;
  /** Исходящий номер клиента, м.б. null */
  outgoingNum?: string;
  /** ИД статуса, м.б. null */
  statusId?: number;
  /** Код шага, м.б. null */
  stepCode?: string;
  /** ИД типа обращения, м.б. null */
  typeCode?: string;
  /** timestamp начала даты регистрации, м.б. null */
  timestampBeg?: number;
  /** timestamp окончания даты регистрации, м.б. null */
  timestampEnd?: number;
  /** timestamp начала даты регистрации, м.б. null - передавать при первоначальной выборке null, при последующей пагинации полученное в выходной модели значение */
  timestampLast?: number;
};

export type GetQMMsgClaimsFavoriteOut = {
  /** Список компаний */
  company: QMMsgCompany[];
  /** Есть еще данные для пагинации. */
  isMoreDataAvailable: boolean;
  /** Дата для последующей пагинации, м.б. null */
  timestampLast: Nullable<number>;
};

export const prepareGetQMMsgClaimsFavorite
  : PrepareCallHandler<GetQMMsgClaimsFavoriteIn, GetQMMsgClaimsFavoriteOut> = (args) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/claims/favorite`,
      method: 'GET',
      responseType: 'json',
      params: args,

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET qm_msg/claims/{qmMsgId}

export type GetQMMsgClaimByIdIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
};

export type GetQMMsgClaimByIdOut = QMMsg;

export const prepareGetQMMsgClaimById
  : PrepareCallHandler<GetQMMsgClaimByIdIn, GetQMMsgClaimByIdOut> = (args) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/claims/${args.qmMsgId}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region GET qm_msg/claims/questions/{qmMsgId}

export type GetQMMsgClaimQuestionsByIdIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
};

export type GetQMMsgClaimQuestionsByIdOut = QMMsgQuestion[];

export const prepareGetQMMsgClaimQuestionsById
  : PrepareCallHandler<GetQMMsgClaimQuestionsByIdIn, GetQMMsgClaimQuestionsByIdOut> = (args) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/claims/questions/${args.qmMsgId}`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST qm_msg/action

export type PostQMMsgActionIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** Действие approved - подтверждено, comeback - возвращено в работу */
  action: string;
  /** Примечание к возврату в работу (обязательно для action = comeback) */
  note: Nullable<string>;
}

export type PostQMMsgActionOut = void;

export const preparePostQMMsgAction
  : PrepareCallHandler<PostQMMsgActionIn, PostQMMsgActionOut> = (arg: PostQMMsgActionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/action`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region PUT qm_msg/favorite

export type PutQMMsgFavoriteIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** true - фаворит, false - не фаворит */
  isFavorite: boolean;
  /** timestamp присвоения */
  timestamp: number;
}

export type PutQMMsgFavoriteOut = void;

export const preparePutQMMsgFavorite
  : PrepareCallHandler<PutQMMsgFavoriteIn, PutQMMsgFavoriteOut> = (arg: PutQMMsgFavoriteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/favorite`,
      method: 'PUT',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST qm_msg/question

export type PostQMMsgQuestionIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** guid вопроса */
  guid: string;
  /** вопрос */
  question: string;
  /** timestamp создания */
  timestamp: number;
}

export type PostQMMsgQuestionOut = void;

export const preparePostQMMsgQuestion
  : PrepareCallHandler<PostQMMsgQuestionIn, PostQMMsgQuestionOut> = (arg: PostQMMsgQuestionIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/question`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion


// #region POST qm_msg/rating

export type PostQMMsgRatingIn = {
  /** ИД уведомления/претензии */
  qmMsgId: number;
  /** оценка (число от 1 до 5 - иначе выдавать ошибку) */
  rating: number;
  /** комментарий к оценке */
  ratingNote: string;
}

export type PostQMMsgRatingOut = void;

export const preparePostQMMsgRating
  : PrepareCallHandler<PostQMMsgRatingIn, PostQMMsgRatingOut> = (arg: PostQMMsgRatingIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qm_msg/rating`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion











