
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceEnginePortalVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M24 21H1V3H6.5H17.5H23V17.4' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M4.5 9H24' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M18 6L20 6' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M14 6L16 6' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M5 13H19' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M5 17H9' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M19 17H11' />
    </svg>
  );
};
