import styled                      from 'styled-components';

import { BlockUIVariants         } from 'src/components/common/block-ui';

import { NavBarVariants          } from 'src/components/features/common/nav-bar';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { DefectsListVariants     } from 'src/components/features/inspection/edit/defects-list';
import { DuplicateDialogVariants } from 'src/components/features/inspection/edit/duplicate-dialog';
import { EndingDialogVariants    } from 'src/components/features/inspection/edit/ending-dialog';

import { InspectionViewVariants  } from 'src/containers/inspection/view';

import { themeColors, 
         cssFlexColumn           } from 'src/providers/theme/shared';



export type InspectionEditLayout = {
  backgroundColor: string;

  paddings: number[];

  navbarVariant: NavBarVariants;
  defectsListVariant: DefectsListVariants;
  duplicateDialogVariant: DuplicateDialogVariants;
  blockUiVariant: BlockUIVariants;
  inspectionViewVariant: InspectionViewVariants;
  endingDialogVariant: EndingDialogVariants;
  errorDialog: TwoActionDialogVariants;
}

export const layout_light: InspectionEditLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [16],

  navbarVariant: 'lightblue',
  defectsListVariant: 'light',
  duplicateDialogVariant: 'light',
  blockUiVariant: 'light',
  inspectionViewVariant: 'light',
  endingDialogVariant: 'light',
  errorDialog: 'light',
}

export const layout_dark: InspectionEditLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: InspectionEditLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: InspectionEditLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  padding: ${(args) => args.layout.paddings[0]}px;
  padding-right: 0;
  overflow: hidden;
`
