import styled           from 'styled-components';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow   } from 'src/providers/theme/shared';



type ReportLookAndFeel = {
  paddings: number[];
  innerGap: number;
  iconSize: number;
  iconMargin: number;

  labelTypo: Typo;

  backgroundColor: string;
  borderColor: string;
  iconBorderColor: string;
  iconColor: string;
  labelColor: string;
}

const report_light: ReportLookAndFeel = {
  paddings: [14, 12, 14, 15],
  innerGap: 8,
  iconSize: 40,
  iconMargin: 2,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,
  iconBorderColor: themeColors.CardBorderColor,
  iconColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryBlack,
}

export type ReportVariants = 'light';

export const report_variants: Map<ReportVariants, ReportLookAndFeel> = 
  new Map<ReportVariants, ReportLookAndFeel>([
    ['light', report_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ReportLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.innerGap}px;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px
           ${(args) => args.lookAndFeel.paddings[1]}px
           ${(args) => args.lookAndFeel.paddings[2]}px
           ${(args) => args.lookAndFeel.paddings[3]}px;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
`

export const Label = styled.span<{
  lookAndFeel: ReportLookAndFeel,
}>`
  flex-grow: 2;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.lookAndFeel.labelColor};
  overflow-wrap: anywhere;
`;

export const Icon = styled.span<{
  lookAndFeel: ReportLookAndFeel,
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.iconSize}px;
  height: ${(args) => args.lookAndFeel.iconSize}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: ${(args) => args.lookAndFeel.iconMargin}px;

  border: 1px solid ${(args) => args.lookAndFeel.iconBorderColor};
  color: ${(args) => args.lookAndFeel.iconColor};
`;