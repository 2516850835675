import { createSlice,
         createAsyncThunk,
         PayloadAction,
         createSelector       } from '@reduxjs/toolkit';

import Api,
       { ApiError             } from 'src/services/api';
import * as types               from 'src/services/api/types';
import Util                     from 'src/services/util';

import { RootState            } from 'src/store';
import { authCommonSelectors  } from 'src/store/auth/common';
import { authSMSCodeSelectors } from 'src/store/auth/sms-code';

import { isPassword,
         isPhone              } from 'src/validators';



const SLICE_NAME = 'auth:pwd-reset';

export interface AuthPwdResetState {
  phone: string;
  phoneError: string;
  password: string;
  passwordError: string;
  passwordRepeat: string;
  passwordRepeatError: string;
}

const initialState: AuthPwdResetState = {
  phone: '',
  phoneError: '',
  password: '',
  passwordError: '',
  passwordRepeat: '',
  passwordRepeatError: '',
}

// #region keyChangePasswordAsync
type KeyChangePasswordArgs = void;
type KeyChangePasswordResolve = types.auth.PostAuthKeyResetPasswordOut;
export type KeyChangePasswordReject = {
  reason: 'validation' | 'api';
  validationErrors?: {
    phoneError?: string;
    passwordError?: string;
    passwordRepeatError?: string;
  };
  apiError?: ApiError;
}
export const keyChangePasswordAsync = createAsyncThunk<
  KeyChangePasswordResolve,
  KeyChangePasswordArgs,
  {
    state: RootState,
    rejectValue: KeyChangePasswordReject
  }
>(
  `${SLICE_NAME}/keyChangePasswordAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const phone = selectPhone(state);
    const password = selectPassword(state);
    const passwordRepeat = selectPasswordRepeat(state);

    const phoneError = isPhone(phone).error ?? '';
    const passwordError = isPassword(password).error ?? '';
    const passwordRepeatError = password.trim() !== passwordRepeat.trim() ? 'Пароли не совпадают' : '';

    if (phoneError.length > 0 || passwordError.length > 0 || passwordRepeatError.length > 0)
    {
      return thunkAPI.rejectWithValue({
        reason: 'validation',
        validationErrors: {
          ...(phoneError.length > 0 ? { phoneError } : {}),
          ...(passwordError.length > 0 ? { passwordError } : {}),
          ...(passwordRepeatError.length > 0 ? { passwordRepeatError } : {}),
        }
      });
    }

    try
    {
      return await Api.postAuthKeyResetPassword({
        phone: parseInt(Util.selectDigitsFromString(phone), 10),
        password: password.trim(),
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue({
        reason: 'api',
        apiError: (error as ApiError),
      })
    }
  }
);
// #endregion

// #region changePasswordAsync
type ChangePasswordArgs = void;
type ChangePasswordResolve = void;
export type ChangePasswordReject = {
  reason: 'api';
  apiError?: ApiError;
}
export const changePasswordAsync = createAsyncThunk<
  ChangePasswordResolve,
  ChangePasswordArgs,
  {
    state: RootState,
    rejectValue: ChangePasswordReject
  }
>(
  `${SLICE_NAME}/changePasswordAsync`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const authKey = authCommonSelectors.selectAuthKey(state);
    const code2fa = authSMSCodeSelectors.selectSMSCode(state);

    try
    {
      return await Api.postAuthChangePassword({
        authKey,
        code2fa: parseInt(code2fa, 10),
      });
    }
    catch (error)
    {
      return thunkAPI.rejectWithValue({
        reason: 'api',
        apiError: (error as ApiError),
      })
    }
  }
);
// #endregion

type PhoneChangedAction = PayloadAction<string>;
type PasswordChangedAction = PayloadAction<string>;
type PasswordRepeatChangedAction = PayloadAction<string>;

export const authPwdResetSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    phoneChanged: (state, action: PhoneChangedAction) => {
      state.phone = action.payload;
    },
    phoneErrorFixed: (state) => {
      state.phoneError = '';
    },
    passwordChanged: (state, action: PasswordChangedAction) => {
      state.password = action.payload;
    },
    passwordErrorFixed: (state) => {
      state.passwordError = '';
    },
    passwordRepeatChanged: (state, action: PasswordRepeatChangedAction) => {
      state.passwordRepeat = action.payload;
    },
    passwordRepeatErrorFixed: (state) => {
      state.passwordRepeatError = '';
    },
    reset: (state) => {
      state.phone = '';
      state.phoneError = '';
      state.password = '';
      state.passwordError = '';
      state.passwordRepeat = '';
      state.passwordRepeatError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(keyChangePasswordAsync.rejected, (state, action) => {
        switch (action.payload?.reason)
        {
          case 'validation':
            state.phoneError = action.payload.validationErrors?.phoneError ?? '';
            state.passwordError = action.payload.validationErrors?.passwordError ?? '';
            state.passwordRepeatError = action.payload.validationErrors?.passwordRepeatError ?? '';
            break;
        }
      });
  },
})

const selectPhone = (state: RootState) => state.authPwdReset.phone;
const selectPhoneError = (state: RootState) => state.authPwdReset.phoneError;
const selectPassword = (state: RootState) => state.authPwdReset.password;
const selectPasswordError = (state: RootState) => state.authPwdReset.passwordError;
const selectPasswordRepeat = (state: RootState) => state.authPwdReset.passwordRepeat;
const selectPasswordRepeatError = (state: RootState) => state.authPwdReset.passwordRepeatError;
const selectFormFilled = createSelector(
  [selectPhone, selectPassword, selectPasswordRepeat],
  (phone, password, passwordRepeat) => {
    const passwordError = isPassword(password).error ?? '';
    const passwordRepeatError = password.trim() !== passwordRepeat.trim() ? 'Пароли не совпадают' : '';
    const purePhone = Util.selectDigitsFromString(phone);
    return (
      Util.isPhoneValid(purePhone)
      &&
      passwordError === ''
      &&
      passwordRepeatError === ''
    )
  }
);

export const authPwdResetSelectors = {
  selectPhone,
  selectPhoneError,
  selectPassword,
  selectPasswordError,
  selectPasswordRepeat,
  selectPasswordRepeatError,
  selectFormFilled,
}

export const authPwdResetActions = authPwdResetSlice.actions;

export default authPwdResetSlice.reducer;