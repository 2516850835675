import * as React                 from 'react';

import { EyeHideVector          } from 'src/components/vector/eye-hide';
import { EyeShowVector          } from 'src/components/vector/eye-show';

import { IconButton,
         IconButtonVariants     } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants      } from 'src/components/common/input/input-base';
import { Input,
         InputPasswordVariants,
         inputpassword_variants } from 'src/components/common/input/input-password/ui';

import { voidFunction           } from 'src/common';



const inputBaseVariantMap: Map<InputPasswordVariants, InputBaseVariants> = 
  new Map<InputPasswordVariants, InputBaseVariants>([
    ['h48light', 'h48light']
  ]);

const iconButtonVariantMap: Map<InputPasswordVariants, IconButtonVariants> = 
  new Map<InputPasswordVariants, IconButtonVariants>([
    ['h48light', 's46bluered']
  ]);

type Props = {
  variant: InputPasswordVariants;
  isError?: boolean;
  isDisabled?: boolean;
  hint?: string;
  maxLength?: number;
  value: string;
  onChange: (newValue: string) => void;
  onFocus?: () => void;
}
export const InputPassword: React.FC<Props> = ({
  variant,
  isError = false,
  isDisabled = false,
  hint,
  maxLength,
  value,
  onChange,
  onFocus = voidFunction,
}) => {
  const [isPassword, setIsPassword] = React.useState<boolean>(true);

  const lookAndFeel = inputpassword_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  }

  const onShowPasswordHandler = () => {
    setIsPassword(!isPassword);
  }

  return (
    <InputBase variant = { inputBaseVariant } isError = { isError } isDisabled = { isDisabled } hint = { hint }>
      <Input
        lookAndFeel = { lookAndFeel }
        isDisabled = { isDisabled }
        disabled = { isDisabled }
        type = { isPassword ? 'password' : 'text' }
        maxLength = { maxLength }
        value = { value }
        onChange = { onChangeHandler }
        onFocus = { onFocus }
      />
      <IconButton variant = { iconButtonVariant } isTapAllowed = { !isDisabled } isDot = { false } onTap = { onShowPasswordHandler }>
        { isPassword ? <EyeHideVector /> : <EyeShowVector /> }
      </IconButton>
    </InputBase>
  );
};
