import styled                     from 'styled-components';

import { ButtonVariants         } from 'src/components/common/button';
import { RadioGroupVariants     } from 'src/components/common/radio-group';
import { LabeledContentVariants } from 'src/components/common/labeled-content';
import { InputMultilineVariants } from 'src/components/common/input/input-multiline';
import { LinkVariants           } from 'src/components/common/link';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors            } from 'src/providers/theme/shared';



type SelectionRatingLookAndFeel = {
  paddings: number[];

  captionTypo: Typo;
  captionColor: string;

  radioVariant: RadioGroupVariants;

  messageTypo: Typo;
  messageColor: string;

  labeledVariant: LabeledContentVariants;
  multilineVariant: InputMultilineVariants;

  sendButtonVariant: ButtonVariants;

  gap: number;
}

const selectionrating_light: SelectionRatingLookAndFeel = {
  paddings: [20],

  captionTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  captionColor: themeColors.AccentBlue2,

  radioVariant: 'light',

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  labeledVariant: 'blue1w400s14lh17gap4',
  multilineVariant: 'light',

  sendButtonVariant: 'h48bluefill',

  gap: 20,
}

export type SelectionRatingVariants = 'light';

export const selectionrating_variants: Map<SelectionRatingVariants, SelectionRatingLookAndFeel> = 
  new Map<SelectionRatingVariants, SelectionRatingLookAndFeel>([
    ['light', selectionrating_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: SelectionRatingLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
`

export const Caption = styled.div<{
  lookAndFeel: SelectionRatingLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Message = styled.div<{
  lookAndFeel: SelectionRatingLookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`
