import * as React                  from 'react';

import { EmptyCaseAlarmVector    } from 'src/components/vector/empty-case/alarm';
import { EmptyCaseDangerVector   } from 'src/components/vector/empty-case/danger';
import { EmptyCaseEnvelopeVector } from 'src/components/vector/empty-case/envelope';
import { EmptyCaseLoupeVector    } from 'src/components/vector/empty-case/loupe';
import { EmptyCaseStarVector     } from 'src/components/vector/empty-case/star';
import { EmptyCaseUserVector     } from 'src/components/vector/empty-case/user';
import { EmptyCaseKeyVector      } from 'src/components/vector/empty-case/key';

import { Grow                    } from 'src/components/common/flex/grow';
import { Button                  } from 'src/components/common/button';
import { Container,
         Wrapper,
         Icon,
         Header,
         Message,
         ButtonContainer,
         EmptyCaseVariants,
         emptycase_variants      } from 'src/components/common/empty-case/ui';

import { voidFunction            } from 'src/common';



type Props = {
  variant: EmptyCaseVariants;
  icon: React.ReactNode;
  header: string;
  message?: string;
  isDanger?: boolean;
  withButton?: boolean;
  buttonText?: string;
  onButtonTap?: () => void;
}
export const EmptyCase: React.FC<Props> = ({
  variant,
  icon,
  header,
  message = '',
  isDanger = false,
  withButton = false,
  buttonText = '',
  onButtonTap = voidFunction,
}) => {
  const lookAndFeel = emptycase_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Grow size = { 2 } />
      <Wrapper lookAndFeel = { lookAndFeel }>
        <Icon lookAndFeel = { lookAndFeel } isDanger = { isDanger }>
          { icon }
        </Icon>
        <Header lookAndFeel = { lookAndFeel }>{ header }</Header>
        {message !== '' && (
          <Message lookAndFeel = { lookAndFeel }>{ message }</Message>
        )}
        {withButton && (
          <ButtonContainer lookAndFeel = { lookAndFeel }>
            <Button 
              variant = { lookAndFeel.buttonVariant }
              label = { buttonText }
              onTap = { onButtonTap }
            />
          </ButtonContainer>
        )}
      </Wrapper>
      <Grow size = { 2 } />
  </Container>
  );
};

export const EmptyCaseAlarm: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseAlarmVector /> }/>
);

export const EmptyCaseDanger: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseDangerVector /> }/>
);

export const EmptyCaseEnvelope: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseEnvelopeVector /> }/>
);

export const EmptyCaseLoupe: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseLoupeVector /> }/>
);

export const EmptyCaseStar: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseStarVector /> }/>
);

export const EmptyCaseUser: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseUserVector /> }/>
);

export const EmptyCaseKey: React.FC<Omit<Props, 'icon'>> = (props) => (
  <EmptyCase { ...props } icon = { <EmptyCaseKeyVector /> }/>
);
