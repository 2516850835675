import styled                     from 'styled-components';

import { CloseVector            } from 'src/components/vector/close';

import { IconButtonVariants     } from 'src/components/common/icon-button';
import { InputTextboxVariants   } from 'src/components/common/input/input-textbox';
import { InputMultilineVariants } from 'src/components/common/input/input-multiline';
import { LabeledVariants        } from 'src/components/common/labeled';
import { ButtonVariants         } from 'src/components/common/button';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn          } from 'src/providers/theme/shared';



type AddMessageLookAndFeel = {
  backColor: string;
  backZIndex: number;

  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerTypo: Typo;
  headerColor: string;
  headerCloseVariant: IconButtonVariants;

  labeledVariant: LabeledVariants;
  inputTextboxVariant: InputTextboxVariants;
  inputMultilineVariant: InputMultilineVariants;

  formGap: number;

  submitVariant: ButtonVariants;
}

const addmessage_light: AddMessageLookAndFeel = {
  backColor: 'rgba(0, 47, 108, 0.5)',
  backZIndex: 1300,

  containerBackgroundColor: themeColors.PrimaryWhite,
  containerPaddings: [20, 16],

  headerHeight: 60,
  headerTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  headerColor: themeColors.PrimaryBlack,
  headerCloseVariant: 's48bluered',

  labeledVariant: 'light',
  inputTextboxVariant: 'h48light',
  inputMultilineVariant: 'light',

  formGap: 16,

  submitVariant: 'h48bluefill',
}

export type AddMessageVariants = 'light';

export const addmessage_variants: Map<AddMessageVariants, AddMessageLookAndFeel> = 
  new Map<AddMessageVariants, AddMessageLookAndFeel>([
    ['light', addmessage_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AddMessageLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: auto;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const Header = styled.div<{
  lookAndFeel: AddMessageLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};

  color: ${(args) => args.lookAndFeel.headerColor};
`

export const Close = styled.div<{
  lookAndFeel: AddMessageLookAndFeel,
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.headerHeight}px;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 0;
  top: 0;
`

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;

export const FormContainer = styled.div<{
  lookAndFeel: AddMessageLookAndFeel,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.formGap}px;
  width: 100%;
  padding: ${(args) => args.lookAndFeel.containerPaddings[0]}px ${(args) => args.lookAndFeel.containerPaddings[1]}px;
`