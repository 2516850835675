import React from 'react';



type Props = {
  className?: string;
}
export const DownloadVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M20 16.999V19.999H4V16.999' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M8 12L11 16L11.9993 16L13 16L16 12M11.9993 3L11.9993 13' />
    </svg>
  );
};
