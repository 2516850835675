import { createSlice,
         createAsyncThunk,
         createSelector,
         PayloadAction     } from '@reduxjs/toolkit';

import Api,
       { ApiError          } from 'src/services/api';
import * as types            from 'src/services/api/types';

import { RootState         } from 'src/store';

import { AsyncOpStatus     } from 'src/common';



const SLICE_NAME = 'main:admin';

export interface MainAdminState {
  searchString: string;
  opStatus: AsyncOpStatus;
  opStatusLabel: string;
  list: types.admin.AdminElement[];
}

const initialState: MainAdminState = {
  searchString: '',
  opStatus: AsyncOpStatus.IDLE,
  opStatusLabel: '',
  list: [],
}

// #region getAdminUserListAsync
type GetAdminUserListArgs = void;
type GetAdminUserListResolve = types.admin.AdminElement[];
export type GetAdminUserListReject = ApiError;
export const getAdminUserListAsync = createAsyncThunk<
  GetAdminUserListResolve,
  GetAdminUserListArgs,
  {
    state: RootState,
    rejectValue: GetAdminUserListReject
  }
>(
  `${SLICE_NAME}/getAdminUserListAsync`,
  async (_, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainAdminActions.opStatusLabelChanged('Загрузка списка пользователей'));
      const result = await Api.getAdminList();
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.IDLE));
      return result;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError);
    }
  }
);
// #endregion

// #region blockAdminUserAsync
type BlockAdminUserArgs = number;
type BlockAdminUserResolve = number;
export type BlockAdminUserReject = ApiError;
export const blockAdminUserAsync = createAsyncThunk<
  BlockAdminUserResolve,
  BlockAdminUserArgs,
  {
    state: RootState,
    rejectValue: BlockAdminUserReject
  }
>(
  `${SLICE_NAME}/blockAdminUserAsync`,
  async (userId, thunkAPI) => {
    try
    {
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.BUSY));
      thunkAPI.dispatch(mainAdminActions.opStatusLabelChanged('Блокирование пользователя'));
      await Api.postAdminBlockUserById({ userId });
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.IDLE));
      return userId;
    }
    catch (error)
    {
      thunkAPI.dispatch(mainAdminActions.opStatusChanged(AsyncOpStatus.IDLE));
      return thunkAPI.rejectWithValue(error as ApiError)
    }
  }
);
// #endregion

type SearchStringChangedAction = PayloadAction<string>;
type OpStatusChangedAction = PayloadAction<AsyncOpStatus>;
type OpStatusLabelChangedAction = PayloadAction<string>;

export const mainAdminSlice = createSlice({
  name: SLICE_NAME,
  initialState: { ...initialState },
  reducers: {
    searchStringChanged: (state, action: SearchStringChangedAction) => {
      state.searchString = action.payload;
    },
    opStatusChanged: (state, action: OpStatusChangedAction) => {
      state.opStatus = action.payload;
    },
    opStatusLabelChanged: (state, action: OpStatusLabelChangedAction) => {
      state.opStatusLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminUserListAsync.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(blockAdminUserAsync.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => item.id !== action.payload);
      })
  },
})

const selectSearchString = (state: RootState) => state.mainAdmin.searchString;
const selectOpStatus = (state: RootState) => state.mainAdmin.opStatus;
const selectOpStatusLabel = (state: RootState) => state.mainAdmin.opStatusLabel;
const selectList = (state: RootState) => state.mainAdmin.list;
const selectRawListCount = createSelector(
  [selectList],
  (list) => list.length
);
const selectFilteredList = createSelector(
  [selectSearchString, selectList],
  (searchString, list) => {
    return list.filter(
      (item) => item.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
        item.middleName.toLowerCase().includes(searchString.toLowerCase())
    );
  }
);

export const mainAdminSelectors = {
  selectSearchString,
  selectOpStatus,
  selectOpStatusLabel,
  selectList,
  selectRawListCount,
  selectFilteredList,
}

export const mainAdminActions = mainAdminSlice.actions;

export default mainAdminSlice.reducer;