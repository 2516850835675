import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';

import { cssFlexColumn,
         cssFlexRow,
         themeColors,            
         themeTypos,            
         Typo,
         typoAsCSS          } from 'src/providers/theme/shared';



type QMMsgListLookAndFeel = {
  backgroundColor: string;

  paddings: number[];
  itemsGap: number;
  itemGap: number;
  valueGap: number;
  expandWidth: number;

  itemLabelTypo: Typo;
  itemLabelColor: string;

  itemValueTypo: Typo;
  itemValueColor: string;
  itemValueActionColor: string;

  expandButtonVariant: IconButtonVariants;
}

const qmmsglist_light: QMMsgListLookAndFeel = {
  backgroundColor: themeColors.SecondaryGray1,

  paddings: [12, 0, 12, 12],
  itemsGap: 16,
  itemGap: 4,
  valueGap: 8,
  expandWidth: 48,

  itemLabelTypo: themeTypos.GRTSKREG_W400_S12_LH14,
  itemLabelColor: themeColors.SecondaryGray5,

  itemValueTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  itemValueColor: themeColors.PrimaryBlack,
  itemValueActionColor: themeColors.AccentBlue2,

  expandButtonVariant: 's48bluered',
}

export type QMMsgListVariants = 'light';

export const qmmsglist_variants: Map<QMMsgListVariants, QMMsgListLookAndFeel> = 
  new Map<QMMsgListVariants, QMMsgListLookAndFeel>([
    ['light', qmmsglist_light],
  ]);


export const Container = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`;

export const List = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.itemsGap}px;
  flex-grow: 2;

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;
`;

export const Item = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexRow};
  justify-content: space-between;
`;

export const ItemNumber = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.itemGap}px;
`;

export const ItemNumberLabel = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.itemLabelTypo)};
  color: ${(args) => args.lookAndFeel.itemLabelColor};
`;

export const ItemNumberValue = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.valueGap}px;
  ${(args) => typoAsCSS(args.lookAndFeel.itemValueTypo)};
  color: ${(args) => args.lookAndFeel.itemValueColor};

  & > svg {
    color: ${(args) => args.lookAndFeel.itemValueActionColor};
  }
`;

export const Expand = styled.div<{
  lookAndFeel: QMMsgListLookAndFeel;
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.expandWidth}px;
  align-items: flex-start;
`;