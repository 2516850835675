import * as React                   from 'react';

import { Button                   } from 'src/components/common/button';
import { VGap                     } from 'src/components/common/flex/vgap';
import { Container,
         Caption,
         Message,
         OneActionDialogVariants,
         oneactiondialog_variants } from 'src/components/common/one-action-dialog/ui';



type Props = {
  variant: OneActionDialogVariants;
  caption: string;
  message?: string;
  actionLabel: string;
  onAction: () => void;
}
export const OneActionDialog: React.FC<Props> = ({
  variant,
  caption,
  message,
  actionLabel,
  onAction,
}) => {
  const lookAndFeel = oneactiondialog_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        { caption }
      </Caption>
      { message !== undefined && (
        <React.Fragment>
          <VGap size = { lookAndFeel.captionMessageGap } />
          <Message lookAndFeel = { lookAndFeel }>
            { message }
          </Message>
        </React.Fragment>
      )}
      <VGap size = { lookAndFeel.messageActionGap } />
      <Button
        variant = { lookAndFeel.actionButtonVariant }
        label = { actionLabel }
        onTap = { onAction }
      />
    </Container>
  );
};
