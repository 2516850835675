import styled                     from 'styled-components';

import { IconButtonVariants     } from 'src/components/common/icon-button';
import { TypographyVariants     } from 'src/components/common/typography';
import { ButtonVariants         } from 'src/components/common/button';
import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { EmailStatusVariants    } from 'src/components/features/main/admin/email-status';

import { cssFlexColumn, 
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS              } from 'src/providers/theme/shared';



type AdminUserLookAndFeel = {
  paddings: number[];

  backgroundColor: string;
  borderColor: string;

  expandButtonVariant: IconButtonVariants;

  headerTypo: Typo;
  headerColor: string;
  headerExpandedColor: string;
  headerSearchColor: string;

  labeledVariant: LabeledContentVariants;
  labeledTypographyVariant: TypographyVariants;

  containerGap: number;
  contentDividerColor: string;
  contentPaddings: number[];
  contentGap: number;

  blockButtonVariant: ButtonVariants;

  emailStatusSuccessVariant: EmailStatusVariants;
  emailStatusDangerVariant: EmailStatusVariants;
}

const adminuser_light: AdminUserLookAndFeel = {
  paddings: [12, 16],

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  expandButtonVariant: 's48bluered',

  headerTypo: themeTypos.GRTSKREG_W400_S16_LH22,
  headerColor: themeColors.PrimaryBlack,
  headerExpandedColor: themeColors.AccentBlue2,
  headerSearchColor: themeColors.AccentBlue2,

  labeledVariant: 'gray5w400s12lh14gap4',
  labeledTypographyVariant: 'blackw400s14lh17',

  containerGap: 16,
  contentDividerColor: themeColors.SecondaryGray3,

  contentPaddings: [16, 0, 0, 0],
  contentGap: 12,

  blockButtonVariant: 'h28redfill',

  emailStatusSuccessVariant: 'lightsuccess',
  emailStatusDangerVariant: 'lightdanger',
}

export type AdminUserVariants = 'light';

export const adminuser_variants: Map<AdminUserVariants, AdminUserLookAndFeel> = 
  new Map<AdminUserVariants, AdminUserLookAndFeel>([
    ['light', adminuser_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AdminUserLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.containerGap}px;
  width: 100%;

  position: relative;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const ExpandButtonWrapper = styled.div<{
  lookAndFeel: AdminUserLookAndFeel;
}>`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const AdminUserHeaderContainer = styled.div<{
  lookAndFeel: AdminUserLookAndFeel;
  isExpanded: boolean;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};
  color: ${(args) => args.isExpanded ? args.lookAndFeel.headerExpandedColor : args.lookAndFeel.headerColor };

  & > span {
    font-weight: bold;
    color: ${(args) => args.lookAndFeel.headerSearchColor };
  }
`

export const AdminUserContentContainer = styled.div<{
  lookAndFeel: AdminUserLookAndFeel;
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.contentGap}px;
  padding-top: ${(args) => args.lookAndFeel.contentPaddings[0]}px;

  border-top: 1px solid ${(args) => args.lookAndFeel.contentDividerColor };

  & > *:last-child {
    width: 80%;
  }
`
