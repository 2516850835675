import styled          from 'styled-components';

import { cssFlexRow,
         themeColors } from 'src/providers/theme/shared';



type IconButtonLookAndFeel = {
  iconColor: string;
  selectedIconColor: string;
  disabledIconColor: string;
  dotColor: string;

  dotPadding: number;

  buttonSize: number;
  iconSize: number;
  dotSize: number;
};

const iconbutton_s48bluered: IconButtonLookAndFeel = {
  iconColor: themeColors.AccentBlue2,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentRed1,

  dotPadding: 5,

  buttonSize: 48,
  iconSize: 24,
  dotSize: 8,
}

const iconbutton_s48redred: IconButtonLookAndFeel = {
  iconColor: themeColors.AccentRed1,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentRed1,

  dotPadding: 5,

  buttonSize: 48,
  iconSize: 24,
  dotSize: 8,
}

const iconbutton_s46bluered: IconButtonLookAndFeel = {
  iconColor: themeColors.AccentBlue2,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentRed1,

  dotPadding: 5,

  buttonSize: 46,
  iconSize: 24,
  dotSize: 8,
}

const iconbutton_s44gray4red: IconButtonLookAndFeel = {
  iconColor: themeColors.SecondaryGray4,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentRed1,

  dotPadding: 5,

  buttonSize: 44,
  iconSize: 24,
  dotSize: 8,
}


const iconbutton_s48whitered: IconButtonLookAndFeel = {
  iconColor: themeColors.PrimaryWhite,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentRed1,

  dotPadding: 5,

  buttonSize: 48,
  iconSize: 24,
  dotSize: 8,
}

const iconbutton_s36redblue: IconButtonLookAndFeel = {
  iconColor: themeColors.AccentRed1,
  selectedIconColor: themeColors.AccentRed1,
  disabledIconColor: themeColors.SecondaryGray5,
  dotColor: themeColors.AccentBlue2,

  dotPadding: 5,

  buttonSize: 36,
  iconSize: 24,
  dotSize: 8,
}

export type IconButtonVariants = 's48bluered' | 's46bluered' | 's48whitered' | 's44gray4red' | 's36redblue' | 's48redred';

export const iconbutton_variants: Map<IconButtonVariants, IconButtonLookAndFeel> = 
  new Map<IconButtonVariants, IconButtonLookAndFeel>([
    ['s48bluered', iconbutton_s48bluered],
    ['s46bluered', iconbutton_s46bluered],
    ['s48whitered', iconbutton_s48whitered],
    ['s44gray4red', iconbutton_s44gray4red],
    ['s36redblue', iconbutton_s36redblue],
    ['s48redred', iconbutton_s48redred],
  ]);

export const Container = styled.button<{
  isDot: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  lookAndFeel: IconButtonLookAndFeel;
}>`
  width: ${(args) => args.lookAndFeel.buttonSize}px;
  height: ${(args) => args.lookAndFeel.buttonSize}px;

  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
  color: ${
    (args) => args.isDisabled ? args.lookAndFeel.disabledIconColor : (args.isSelected ? args.lookAndFeel.selectedIconColor : args.lookAndFeel.iconColor)};

  padding: 0;

  & svg {
    width: ${(args) => args.lookAndFeel.iconSize};
    height: ${(args) => args.lookAndFeel.iconSize};
  }

  &::after {
    position: absolute;
    content: '';
    right: ${(args) => args.lookAndFeel.dotPadding}px;
    top: ${(args) => args.lookAndFeel.dotPadding}px;
    width: ${(args) => args.lookAndFeel.dotSize}px;
    height: ${(args) => args.lookAndFeel.dotSize}px;
    border-radius: 50%;
  
    background-color: ${(args) => args.lookAndFeel.dotColor};
    opacity: ${(args) => args.isDot ? 100 : 0}%;
  }
`;

export const RippleWrapper = styled.div<{
  lookAndFeel: IconButtonLookAndFeel;
}>`
  width: ${(args) => args.lookAndFeel.buttonSize}px;
  height: ${(args) => args.lookAndFeel.buttonSize}px;
`;
