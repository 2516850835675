import styled             from 'styled-components';

import { ButtonVariants } from 'src/components/common/button';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,    
         cssFlexColumn, 
         cssFlexRow     } from 'src/providers/theme/shared';



type EmptyCaseLookAndFeel = {
  gap: number;

  iconHeight: number;
  iconColor: string;
  iconDangerColor: string;
  
  headerTypo: Typo;
  headerColor: string;

  messageTypo: Typo;
  messageColor: string;

  buttonGap: number;
  buttonVariant: ButtonVariants;
}

const emptycase_light: EmptyCaseLookAndFeel = {
  gap: 24,

  iconHeight: 88,
  iconColor: themeColors.PrimarySteel,
  iconDangerColor: themeColors.AccentRed1,

  headerTypo: themeTypos.GRTSKREG_W400_S20_LH28,
  headerColor: themeColors.PrimaryBlue100,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.SecondaryGray9,

  buttonGap: 48,
  buttonVariant: 'h36blueoutline',
}

export type EmptyCaseVariants = 'light';

export const emptycase_variants: Map<EmptyCaseVariants, EmptyCaseLookAndFeel> = 
  new Map<EmptyCaseVariants, EmptyCaseLookAndFeel>([
    ['light', emptycase_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel
}>`
  width: 100%;
  height: 100%;

  ${cssFlexColumn}
`

export const Wrapper = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel
}>`
  ${cssFlexColumn}
  gap: ${(args) => args.lookAndFeel.gap}px;
  align-items: center;
`

export const Icon = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel;
  isDanger: boolean;
}>`
  ${cssFlexRow}
  width: 100%;
  height: ${(args) => args.lookAndFeel.iconHeight}px;
  justify-content: center;
  align-items: center;

  color: ${(args) => args.isDanger ? args.lookAndFeel.iconDangerColor : args.lookAndFeel.iconColor };
`

export const Header = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};
  text-align: center;

  color: ${(args) => args.lookAndFeel.headerColor};
`

export const Message = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  text-align: center;

  color: ${(args) => args.lookAndFeel.messageColor};
`

export const ButtonContainer = styled.div<{
  lookAndFeel: EmptyCaseLookAndFeel
}>`
  ${cssFlexRow}
  width: 70%;
  justify-content: center;

  padding-top: ${(args) => args.lookAndFeel.buttonGap - args.lookAndFeel.gap}px;
`