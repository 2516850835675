import * as React             from 'react';

import cc                     from 'classcat';

import { Container,
         Tab,
         TabSwitchVariants,
         tabswitch_variants } from 'src/components/common/tab-switch/ui';



export type TabItem = {
  id: string;
  label: string;
  prefixIcon?: React.ReactNode;
}

type Props = {
  variant: TabSwitchVariants;
  items: TabItem[];
  value: TabItem;
  isDisabled?: boolean;
  onChange: (value: TabItem) => void;
}
export const TabSwitch: React.FC<Props> = ({
  variant,
  items,
  value,
  isDisabled = false,
  onChange,
}) => {
  const lookAndFeel = tabswitch_variants.get(variant)!;

  const onTabClickHandler = (item: TabItem) => {
    if (!isDisabled)
    {
      onChange(item);
    }
  }

  return (
    <Container lookAndFeel={lookAndFeel}>
      {items.map((item) => (
        <Tab
          key={item.id}
          lookAndFeel={lookAndFeel}
          className={cc({ active: item.id === value.id })}
          onClick={onTabClickHandler.bind(null, item)}
        >
          {item.prefixIcon !== undefined && (
            item.prefixIcon
          )}
          {item.label}
        </Tab>
      ))}
    </Container>
  );
};
