import * as React             from 'react';

import dayjs                  from 'dayjs';

import { Container,
         Label,
         DateLabelVariants,
         datelabel_variants } from 'src/components/common/date-label/ui';



type Props = {
  variant: DateLabelVariants;
  date: Date;
}
export const DateLabel: React.FC<Props> = ({
  variant,
  date,
}) => {
  const lookAndFeel = datelabel_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { lookAndFeel.icon }
      <Label lookAndFeel = { lookAndFeel }>
        { dayjs(date).format('DD.MM.YYYY, HH:mm') }
      </Label>
    </Container>
  );
};
