import * as React        from 'react';

import { Container,
         NoteVariants,
         note_variants } from 'src/components/features/auth/reg-start/note/ui';



type Props = {
  variant: NoteVariants;
  children: React.ReactNode;
}
export const Note: React.FC<Props> = ({
  variant,
  children,
}) => {
  const lookAndFeel = note_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { children }
    </Container>
  );
};
