import * as React                    from 'react';

import Drawer                        from '@mui/material/Drawer';

import { Swiper, 
         SwiperSlide               } from 'swiper/react';
import { Autoplay, 
         Navigation, 
         Swiper as SwiperInterface } from 'swiper';

import 'swiper/css';

import { CloseVector               } from 'src/components/vector/close';
import { ArrowRightWithLineVector  } from 'src/components/vector/arrow-right-with-line';

import { Container,
         Ad,
         AdLabel,
         Padding,
         Full,
         AdsVariants,
         ads_variants              } from 'src/components/features/main/inspection-list/ads/ui';

import * as types                    from 'src/services/api/types';



type Props = {
  variant: AdsVariants;
  items: types.adv.AdvElement[];
  statistics: types.adv.AdvStatistics[];
  paddings: number;
  isPriorityShow: boolean;
  onSwiperClose: () => void;
}
export const Ads: React.FC<Props> = ({
  variant,
  items,
  statistics,
  paddings,
  isPriorityShow,
  onSwiperClose,
}) => {
  const lookAndFeel = ads_variants.get(variant)!;

  const [isSwiperOpened, setIsSwiperOpened] = React.useState<boolean>(false);
  const [initialSlide, setInitialSlide] = React.useState<number>(0);
  const [itemsForSwiper, setItemsForSwiper] = React.useState<types.adv.AdvElement[]>(items);

  const progressValue = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const visibileItems = items.filter((item) => !isPriorityShow || item.isPriority);
    setItemsForSwiper(visibileItems);
    if (isPriorityShow)
    {
      if (visibileItems.length > 0)
      {
        onOpenCarouselHandler(0);
      }
      else
      {
        onSwiperClose();
      }
    }
  }, [items, isPriorityShow, onSwiperClose]);

  const onOpenCarouselHandler = (index: number) => {
    setInitialSlide(index);
    setIsSwiperOpened(true);
  }

  const onCloseCarouselHandler = () => {
    setIsSwiperOpened(false);
    onSwiperClose();
  }

  const onAutoplayTimeLeft = (swiper: SwiperInterface, timeLeft: number, percentage: number ) => {
    if (progressValue.current !== null)
    {
      const oneItemPercent = 100 / itemsForSwiper.length;
      const currentPercent = Math.min(swiper.activeIndex * oneItemPercent + oneItemPercent * (1 - percentage), 100);

      if (currentPercent === 100)
      {
        onCloseCarouselHandler();
      }
      else
      {
        progressValue.current.style.setProperty('--progress', `${Math.min(currentPercent, 100)}%`);
      }
    }
  };

  return (
    <Container lookAndFeel = { lookAndFeel } id = 'ads-list-container'>
      <Padding lookAndFeel = { lookAndFeel } size = { paddings } />
      { items.map((item, index) => (
        <Ad
          key = { item.id }
          lookAndFeel = { lookAndFeel }
          onClick = { () => onOpenCarouselHandler(index) }
          style = { { backgroundImage: `url(${item.__b64Preview})` } }
        >
          <AdLabel  lookAndFeel = { lookAndFeel }>{ item.title }</AdLabel>
        </Ad>
      ))}
      <Padding lookAndFeel = { lookAndFeel } size = { paddings } />
      <Drawer
        anchor = 'bottom'
        open = { isSwiperOpened }
        onClose = { onCloseCarouselHandler }
        sx = {{
          "& .MuiPaper-root": {
            width: {
              xs: '100%',
              sm: 600,
            },
            left: {
              xs: 0,
              sm: 'calc((100% - 600px) / 2)'
            }
          }
        }}
      >
        {isSwiperOpened && (
          <Full lookAndFeel = { lookAndFeel }>
            <Swiper
              initialSlide={initialSlide}
              centeredSlides={true}
              autoplay={{ delay: 5000, disableOnInteraction: false, stopOnLastSlide: true }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
            >
              {itemsForSwiper.map((item) => (
                <SwiperSlide key = { item.id }>
                  <img src = { item.__b64Full } alt = '' />
                  {!item.isStatistic && (
                    <div className = 'acontainer'>
                      <a href = { `https://${item.advLink}` } target = '_blank' rel = 'noreferrer'>
                        { item.isSurvey ? 'Пройти опрос' : 'Подробнее' }
                        <ArrowRightWithLineVector />
                      </a>
                    </div>
                  )}
                  {item.isStatistic && (
                    <div className = 'stat-container'>
                      <div className = 'gradient'></div>
                      <div className = 'data-container'>
                        <div className = 'header'>
                          Статистика<br />
                          <strong>Checksteel</strong>
                        </div>
                        <div className = 'data'>
                          {statistics.map((stat) => (
                            <div className = 'item' key = { stat.statName }>
                              <span>{ stat.statName }</span>
                              <strong>{ stat.statValue }</strong>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {item.isSurvey && (
                    <div className = 'survey-container'>
                      <div className = 'survey-caption'>
                        Опрос
                      </div>
                      <div className = 'survey-name'>
                        { item.title }
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              ))}
              <i className = 'close-button' onClick = { onCloseCarouselHandler }>
                <CloseVector />
              </i>
              <div className = 'progress-bar'>
                <div className = 'progress-line' ref = { progressValue } />
              </div>
            </Swiper>
          </Full>
        )}
      </Drawer>
    </Container>
  );
};
