import styled                 from 'styled-components';

import { IconButtonVariants } from 'src/components/common/icon-button';
import { TypographyVariants } from 'src/components/common/typography';
import { LoadingVariants    } from 'src/components/common/loading';

import { cssFlexRow         } from 'src/providers/theme/shared';



type HeaderLookAndFeel = {
  paddings: number[];

  labelTypographyVariant: TypographyVariants;
  iconButtonVariant: IconButtonVariants;
  loadingVariant: LoadingVariants;
}

const header_light: HeaderLookAndFeel = {
  paddings: [24, 16],

  labelTypographyVariant: 'blackw600s28lh32',
  iconButtonVariant: 's48bluered',
  loadingVariant: 'lightblue24',
}

export type HeaderVariants = 'light';

export const header_variants: Map<HeaderVariants, HeaderLookAndFeel> = 
  new Map<HeaderVariants, HeaderLookAndFeel>([
    ['light', header_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: HeaderLookAndFeel;
}>`
  ${cssFlexRow};
  width: 100%;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;

  position: sticky;
  top: 0;
  z-index: 2;

  & > *:first-child {
    flex-grow: 2;
  }
`
