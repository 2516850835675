import * as React                from 'react';

import { Container,
         BlockCaptionVariants,
         blockcaption_variants } from 'src/components/features/main/common/block-caption/ui';



type Props = {
  variant: BlockCaptionVariants;
  children: React.ReactNode;
}
export const BlockCaption: React.FC<Props> = ({
  variant,
  children,
}) => {
  const lookAndFeel = blockcaption_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      { children }
    </Container>
  );
};
