import styled                     from 'styled-components';

import { LabeledContentVariants } from 'src/components/common/labeled-content';

import { cssFlexColumn, 
         cssFlexRow, 
         themeColors, 
         themeTypos,
         Typo,
         typoAsCSS              } from 'src/providers/theme/shared';



type ItemLookAndFeel = {
  paddings: number[];
  borderColor: string;
  gap: number;

  backgroundColor: string;

  headerGap: number;
  redDotSize: number;
  redDotColor: string;
  headerTypo: Typo;
  headerColor: string;
  favoriteInactiveColor: string;
  favoriteActiveColor: string;

  propertiesTopPadding: number;
  propertiesGap: number;
  propertiesLabeledVariant: LabeledContentVariants;
  propertyTypo: Typo;
  propertyColor: string;
}

const item_light: ItemLookAndFeel = {
  paddings: [12, 16, 16, 16],
  borderColor: themeColors.CardBorderColor,
  gap: 8,

  backgroundColor: themeColors.PrimaryWhite,

  headerGap: 6,
  redDotSize: 10,
  redDotColor: themeColors.AccentRed1,
  headerTypo: themeTypos.GRTSKREG_W600_S15_LH20,
  headerColor: themeColors.PrimaryBlack,
  favoriteInactiveColor: themeColors.SecondaryGray4,
  favoriteActiveColor: themeColors.AccentRed1,

  propertiesTopPadding: 4,
  propertiesGap: 12,
  propertiesLabeledVariant: 'gray5w400s12lh14gap4',
  propertyTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  propertyColor: themeColors.PrimaryBlack,

}

export type ItemVariants = 'light';

export const item_variants: Map<ItemVariants, ItemLookAndFeel> = 
  new Map<ItemVariants, ItemLookAndFeel>([
    ['light', item_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ItemLookAndFeel;
}>`
  ${cssFlexColumn}
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: ItemLookAndFeel;
  isFavorite: boolean;
}>`
  ${cssFlexRow}
  width: 100%;
  align-items: center;
  gap: ${(args) => args.lookAndFeel.headerGap}px;

  & svg {
    color: ${(args) => args.isFavorite ? args.lookAndFeel.favoriteActiveColor : args.lookAndFeel.favoriteInactiveColor};
  }
`

export const RedDot = styled.div<{
  lookAndFeel: ItemLookAndFeel;
}>`
  width: ${(args) => args.lookAndFeel.redDotSize}px;
  height: ${(args) => args.lookAndFeel.redDotSize}px;

  background-color: ${(args) => args.lookAndFeel.redDotColor};
  border-radius: 50%;
`

export const HeaderLabel = styled.div<{
  lookAndFeel: ItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};
  color: ${(args) => args.lookAndFeel.headerColor};
  flex-grow: 2;
`

export const PropertiesContainer = styled.div<{
  lookAndFeel: ItemLookAndFeel;
}>`
  ${cssFlexColumn}
  width: 100%;
  gap: ${(args) => args.lookAndFeel.propertiesGap}px;

  padding-top: ${(args) => args.lookAndFeel.propertiesTopPadding}px;
`

export const PropertyLabel = styled.div<{
  lookAndFeel: ItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.propertyTypo)};
  color: ${(args) => args.lookAndFeel.propertyColor};
`
