import styled             from 'styled-components';

import { cssFlexColumn,
         themeColors, 
         Typo,
         themeTypos,
         typoAsCSS      } from 'src/providers/theme/shared';



type NotificationLookAndFeel = {
  borderColor: string;

  gap: number;

  paddings: number[];
  timeRight: number;
  timeBottom: number;

  headerTypo: Typo;
  headerColor: string;

  messageTypo: Typo;
  messageColor: string;

  timeTypo: Typo;
  timeColor: string;

  backgroundColor: string;
}

const notification_light: NotificationLookAndFeel = {
  borderColor: themeColors.CardBorderColor,

  gap: 8,

  paddings: [16, 40, 16, 16],
  timeRight: 8,
  timeBottom: 4,

  headerTypo: themeTypos.GRTSKREG_W600_S14_LH17,
  headerColor: themeColors.PrimaryBlack,

  messageTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  messageColor: themeColors.PrimaryBlack,

  timeTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  timeColor: themeColors.PrimarySteel,

  backgroundColor: themeColors.PrimaryWhite,
}

export type NotificationVariants = 'light';

export const notification_variants: Map<NotificationVariants, NotificationLookAndFeel> = 
  new Map<NotificationVariants, NotificationLookAndFeel>([
    ['light', notification_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: NotificationLookAndFeel
}>`
  width: 100%;
  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;
  border: 1px solid ${(args) => args.lookAndFeel.borderColor};

  position: relative;
  
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const Content = styled.div<{
  lookAndFeel: NotificationLookAndFeel
}>`
  width: 100%;

  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
`

export const Header = styled.div<{
  lookAndFeel: NotificationLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)}

  color: ${(args) => args.lookAndFeel.headerColor};
`

export const Message = styled.div<{
  lookAndFeel: NotificationLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)}

  color: ${(args) => args.lookAndFeel.messageColor};
`

export const Time = styled.div<{
  lookAndFeel: NotificationLookAndFeel
}>`
  position: absolute;
  right: ${(args) => args.lookAndFeel.timeRight}px;
  bottom: ${(args) => args.lookAndFeel.timeBottom}px;

  ${(args) => typoAsCSS(args.lookAndFeel.timeTypo)}

  color: ${(args) => args.lookAndFeel.timeColor};
`
