import styled,
       { css                } from 'styled-components';

import { PlusVector         } from 'src/components/vector/tree/plus';
import { MinusVector        } from 'src/components/vector/tree/minus';
import { StarOutlineVector  } from 'src/components/vector/star-outline';

import { IconButtonVariants } from 'src/components/common/icon-button';
import { EmptyCaseVariants  } from 'src/components/common/empty-case';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow         } from 'src/providers/theme/shared';



export type TreeLookAndFeel = {
  nodeHeight: number;
  nodeGap: number;

  labelTypo: Typo;
  labelColor: string;
  labelSearchColor: string;

  starNotFavoriteColor: string;
  starFavoriteColor: string;
  starSize: number;

  treePlusFillColor: string;
  treePlusStrokeColor: string;
  treeMinusFillColor: string;
  treeMinusStrokeColor: string;
  treeSubtreePaddings: number[];
  treeBackgroundColor: string;
  treeBorderColor: string;
  treeFavoriteIconButtonVariant: IconButtonVariants;
  treeEmptyCaseVariant: EmptyCaseVariants;
}

const tree_light: TreeLookAndFeel = {
  nodeHeight: 46,
  nodeGap: 14,

  labelTypo: themeTypos.GRTSKREG_W400_S15_LH20,
  labelColor: themeColors.PrimaryBlack,
  labelSearchColor: themeColors.AccentBlue1,

  starNotFavoriteColor: themeColors.SecondaryGray4,
  starFavoriteColor: themeColors.AccentRed1,
  starSize: 20,

  treePlusFillColor: themeColors.SecondaryGray2,
  treePlusStrokeColor: themeColors.AccentBlue2,
  treeMinusFillColor: themeColors.AccentBlue2,
  treeMinusStrokeColor: themeColors.PrimaryWhite,
  treeSubtreePaddings: [0, 0, 0, 30],
  treeBackgroundColor: themeColors.PrimaryWhite,
  treeBorderColor: themeColors.SecondaryGray2,
  treeFavoriteIconButtonVariant: 's46bluered',
  treeEmptyCaseVariant: 'light',
}

export type TreeVariants = 'light';

export const tree_variants: Map<TreeVariants, TreeLookAndFeel> = 
  new Map<TreeVariants, TreeLookAndFeel>([
    ['light', tree_light],
  ]);

export const TreePlusVector = styled(PlusVector)<{
  lookAndFeel: TreeLookAndFeel,
}>`
  color: ${(args) => args.lookAndFeel.treePlusStrokeColor};

  & circle {
    fill: ${(args) => args.lookAndFeel.treePlusFillColor};
  }
`;

export const TreeMinusVector = styled(MinusVector)<{
  lookAndFeel: TreeLookAndFeel,
}>`
  color: ${(args) => args.lookAndFeel.treeMinusStrokeColor};

  & circle {
    fill: ${(args) => args.lookAndFeel.treeMinusFillColor};
  }
`;

export const TreeStarFavoriteVector = styled(StarOutlineVector)<{
  lookAndFeel: TreeLookAndFeel,
}>`
  width: ${(args) => args.lookAndFeel.starSize}px;
  height: ${(args) => args.lookAndFeel.starSize}px;
  color: ${(args) => args.lookAndFeel.starFavoriteColor};
`;

export const TreeStarNotFavoriteVector = styled(StarOutlineVector)<{
  lookAndFeel: TreeLookAndFeel,
}>`
  width: ${(args) => args.lookAndFeel.starSize}px;
  height: ${(args) => args.lookAndFeel.starSize}px;
  color: ${(args) => args.lookAndFeel.starNotFavoriteColor};
`;

export const HeaderContainer = styled.div<{
  lookAndFeel: TreeLookAndFeel;
  isTopLevel: boolean;
  isTopLevelFirst: boolean;
}>`
  ${cssFlexRow}
  height: ${(args) => args.lookAndFeel.nodeHeight}px;
  gap: ${(args) => args.lookAndFeel.nodeGap}px;
  align-items: center;

  ${(args) =>
    args.isTopLevel &&
    !args.isTopLevelFirst &&
    css`
      border-top: 1px solid ${args.lookAndFeel.treeBorderColor};
    `}
`;

export const NodeLabel = styled.span<{
  lookAndFeel: TreeLookAndFeel,
}>`
  flex-grow: 2;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.lookAndFeel.labelColor };

  & span {
    color: ${(args) => args.lookAndFeel.labelSearchColor };
    font-weight: bold;
  }
`

export const TreeContainer = styled.div<{
  lookAndFeel: TreeLookAndFeel,
}>`
  width: 100%;
  height: 100%;
`;