import * as React                  from 'react';

import { PatternFormat           } from 'react-number-format';

import { CloseVector             } from 'src/components/vector/close';

import { IconButton,
         IconButtonVariants      } from 'src/components/common/icon-button';
import { InputBase,
         InputBaseVariants       } from 'src/components/common/input/input-base';
import { Input,
         InputDigitCodeVariants,
         inputdigitcode_variants } from 'src/components/common/input/input-digitcode/ui';

import { PatternFormatValues     } from 'src/common';



const inputBaseVariantMap: Map<InputDigitCodeVariants, InputBaseVariants> = 
  new Map<InputDigitCodeVariants, InputBaseVariants>([
    ['h48light', 'h48light']
  ]);

const iconButtonVariantMap: Map<InputDigitCodeVariants, IconButtonVariants> = 
  new Map<InputDigitCodeVariants, IconButtonVariants>([
    ['h48light', 's46bluered']
  ]);

type Props = {
  variant: InputDigitCodeVariants;
  countDigits: number;
  isError?: boolean;
  isDisabled?: boolean;
  hint?: string;
  value: string;
  onChange: (newValue: string) => void;
}
export const InputDigitCode: React.FC<Props> = ({
  variant,
  countDigits,
  isError = false,
  isDisabled = false,
  hint,
  value,
  onChange,
}) => {
  const lookAndFeel = inputdigitcode_variants.get(variant)!;
  const inputBaseVariant = inputBaseVariantMap.get(variant)!;
  const iconButtonVariant = iconButtonVariantMap.get(variant)!;

  const onChangeHandler = (values: PatternFormatValues) => {
    onChange(values.formattedValue);
  }

  const onClearHandler = () => {
    onChange('_'.repeat(countDigits));
  }

  return (
    <InputBase variant = { inputBaseVariant } isError = { isError } isDisabled = { isDisabled } hint = { hint }>
      <PatternFormat
        format = { '#'.repeat(countDigits) }
        allowEmptyFormatting
        mask = '_'
        customInput = { Input }
        lookAndFeel = { lookAndFeel }
        isError = { isError }
        isDisabled = { isDisabled }
        disabled = { isDisabled }
        value = { value }
        onValueChange = { onChangeHandler }
      />
      { value.length > 0 && value !== '_'.repeat(countDigits) && (
        <IconButton variant = { iconButtonVariant } isTapAllowed = { !isDisabled } isDot = { false } onTap = { onClearHandler }>
          <CloseVector />
        </IconButton>
      )}
    </InputBase>
  );
};
