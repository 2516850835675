import * as React               from 'react';

import { useAlert             } from 'react-alert';

import { Button               } from 'src/components/common/button';
import { Typography           } from 'src/components/common/typography';
import { TwoActionDialog      } from 'src/components/common/two-action-dialog';

import { DefectItem           } from 'src/components/features/inspection/complaint/defect-item';
import { ComplaintEdit        } from 'src/components/features/inspection/complaint/complaint-edit-dialog';

import { Container, 
         List,
         Actions,
         DefectsListVariants,
         defectslist_variants } from 'src/components/features/inspection/complaint/defects-list/ui';

import { Urls                 } from 'src/providers/routing';

import * as types               from 'src/services/api/types';

import { useAppSelector, 
         storeApi,             
         useAppDispatch       } from 'src/store';



type Props = {
  variant: DefectsListVariants;
}
export const DefectsList: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = defectslist_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const defectsGuidsStr = useAppSelector(storeApi.inspection.edit.selectors.selectDefectAllGuidsList);
  const complaintsIsOk = useAppSelector(storeApi.inspection.edit.selectors.selectDefectComplaintsIsOk);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);

  const defectsGuids = React.useMemo(() => defectsGuidsStr === '' ? [] : defectsGuidsStr.split(';'), [defectsGuidsStr]);

  const [errorOpened, setErrorOpened] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');

  const nextText = [types.inspection.InspectionState.EDIT, types.inspection.InspectionState.ERROR].includes(inspectionState) ? 'Завершить изменения' : 'Завершить осмотр';

  const onCompleteHandler = () => {
    dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: true }))
      .unwrap()
      .then(() => {
        alert.success('Осмотр успешно сохранен');
        Urls.MainInspections.build().navigate();
      })
      .catch((error) => {
        if (error.redirectToList)
        {
          alert.error(error.error);
          Urls.MainInspections.build().navigate();
        }
        else
        {
          setErrorOpened(true);
          setErrorText(error.error);
        }
      });
  }

  const onErrorCloseHandler = () => {
    setErrorOpened(false);
    setErrorText('');
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Typography variant = { lookAndFeel.headerTypographyVariant }>Информация о дефекте</Typography>
      <List lookAndFeel = { lookAndFeel }>
        {defectsGuids.map((defectGuid) => (
          <DefectItem
            variant = { lookAndFeel.defectItemVariant }
            defectGuid = { defectGuid }
            key = { defectGuid }
          />
        ))}
      </List>
      <Actions lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.completeButtonVariant }
          label = { nextText }
          isDisabled = { !complaintsIsOk && account.role !== types.auth.AccountRole.ClientAdmin }
          onTap = { onCompleteHandler }
        />
      </Actions>
      <ComplaintEdit variant = { lookAndFeel.complaintEditVariant } />
      <TwoActionDialog
        opened = { errorOpened }
        variant = { lookAndFeel.errorDialog }
        caption = 'Ошибка'
        message = { errorText }
        withoutCancel
        isDanger
        onAction = { onErrorCloseHandler }
      />
    </Container>
  );
};
