import * as React           from 'react';

import { BellVector       } from 'src/components/vector/bell';
import { ProfileVector    } from 'src/components/vector/profile';
import { NavBarLogoVector } from 'src/components/vector/navbar-logo';

import { IconButton       } from 'src/components/common/icon-button';

import { Container,
         Logo,
         NavBarVariants,
         navbar_variants  } from 'src/components/features/main/inspection-list/nav-bar/ui';



type Props = {
  variant: NavBarVariants;
  isUnreadNotifications: boolean;
  onNotificationsTap: () => void;
  onProfileTap: () => void;
}
export const NavBar: React.FC<Props> = ({
  variant,
  isUnreadNotifications,
  onNotificationsTap,
  onProfileTap,
}) => {
  const lookAndFeel = navbar_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <IconButton
        variant = { lookAndFeel.buttonVariant }
        isDot = { isUnreadNotifications }
        isTapAllowed
        onTap = { onNotificationsTap }
      >
        <BellVector />
      </IconButton>
      <Logo lookAndFeel = { lookAndFeel }>
        <NavBarLogoVector />
      </Logo>
      <IconButton
        variant = { lookAndFeel.buttonVariant }
        isTapAllowed
        onTap = { onProfileTap }
      >
        <ProfileVector />
      </IconButton>
    </Container>
  );
};
