import styled                   from 'styled-components';

import { CloseVector          } from 'src/components/vector/close';
import { PlusVector           } from 'src/components/vector/tree/plus';
import { MinusVector          } from 'src/components/vector/tree/minus';

import { IconButtonVariants   } from 'src/components/common/icon-button';
import { EmptyCaseVariants    } from 'src/components/common/empty-case';
import { TypographyVariants   } from 'src/components/common/typography';
import { InputTextboxVariants } from 'src/components/common/input/input-textbox';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn        } from 'src/providers/theme/shared';



export type DefectSelectorLookAndFeel = {
  containerBackgroundColor: string;
  containerPaddings: number[];

  headerHeight: number;
  headerPaddings: number[];
  headerTextColor: string;
  headerTextTypo: Typo;
  headerIconButtonVariant: IconButtonVariants;
  headerTypographyVariant: TypographyVariants;

  searchHeight: number;
  searchPaddings: number[];
  searchInputVariant: InputTextboxVariants;

  treePaddings: number[];
  treePlusFillColor: string;
  treePlusStrokeColor: string;
  treeMinusFillColor: string;
  treeMinusStrokeColor: string;

  favoriteItemHeight: number;
  favoriteItemGap: number;
  favoriteItemTypo: Typo;
  favoriteIconButtonVariant: IconButtonVariants;
  favoriteIconColor: string;
  favoruteBorderColor: string;
  favoriteEmptyCaseVariant: EmptyCaseVariants;
  favoriteLabelColor: string;
  favoriteSearchColor: string;
}

const defectselector_light: DefectSelectorLookAndFeel = {
  containerBackgroundColor: themeColors.PrimaryWhite,
  containerPaddings: [0, 16],

  headerHeight: 60,
  headerPaddings: [0, 5],
  headerTextColor: themeColors.PrimaryBlack,
  headerTextTypo: themeTypos.GRTSKREG_W400_S20_LH24,
  headerIconButtonVariant: 's46bluered',
  headerTypographyVariant: 'blackw400s20lh24',

  searchHeight: 56,
  searchPaddings: [0, 16],
  searchInputVariant: 'h48light',

  treePaddings: [16],
  treePlusFillColor: themeColors.SecondaryGray2,
  treePlusStrokeColor: themeColors.AccentBlue2,
  treeMinusFillColor: themeColors.AccentBlue2,
  treeMinusStrokeColor: themeColors.PrimaryWhite,

  favoriteItemHeight: 46,
  favoriteItemGap: 16,
  favoriteItemTypo: themeTypos.GRTSKREG_W400_S15_LH20,
  favoriteIconButtonVariant: 's46bluered',
  favoriteIconColor: themeColors.AccentRed1,
  favoruteBorderColor: themeColors.SecondaryGray2,
  favoriteEmptyCaseVariant: 'light',
  favoriteLabelColor: themeColors.PrimaryBlack,
  favoriteSearchColor: themeColors.AccentBlue1,
}

export type DefectSelectorVariants = 'light';

export const defectselector_variants: Map<DefectSelectorVariants, DefectSelectorLookAndFeel> = 
  new Map<DefectSelectorVariants, DefectSelectorLookAndFeel>([
    ['light', defectselector_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: 100dvh;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const HeaderContainer = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.headerHeight}px;
  align-items: center;
`

export const SearchContainer = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  flex-shrink: 0;
  width: 100%;
  height: ${(args) => args.lookAndFeel.searchHeight}px;
  align-items: center;

  padding: ${(args) => args.lookAndFeel.searchPaddings[0]}px ${(args) => args.lookAndFeel.searchPaddings[1]}px;

  & > div {
    width: 100%;
  }
`

export const TreeContainer = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  flex-grow: 2;
  overflow-y: auto;

  padding: ${(args) => args.lookAndFeel.treePaddings[0]}px;
`

export const FavoriteContainer = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
  overflow-y: auto;
`

export const FavoriteItem = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.favoriteItemHeight}px;
  gap: ${(args) => args.lookAndFeel.favoriteItemGap}px;
  justify-content: space-between;
  align-items: center;

  color: ${(args) => args.lookAndFeel.favoriteLabelColor};

  border-bottom: 1px solid ${(args) => args.lookAndFeel.favoruteBorderColor};

  & svg {
    color: ${(args) => args.lookAndFeel.favoriteIconColor};
  }
`

export const FavoriteItemLabel = styled.div<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.favoriteItemTypo)}

  & span {
    color: ${(args) => args.lookAndFeel.favoriteSearchColor};
    font-weight: bold;
  }
`

export const TreePlusVector = styled(PlusVector)<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  color: ${(args) => args.lookAndFeel.treePlusStrokeColor};

  & circle {
    fill: ${(args) => args.lookAndFeel.treePlusFillColor};
  }
`;

export const TreeMinusVector = styled(MinusVector)<{
  lookAndFeel: DefectSelectorLookAndFeel,
}>`
  color: ${(args) => args.lookAndFeel.treeMinusStrokeColor};

  & circle {
    fill: ${(args) => args.lookAndFeel.treeMinusFillColor};
  }
`;

export const CloseOutlineVectorBig = styled(CloseVector)`
  width: 16px;
  height: 16px;
`;