import React from 'react';



type Props = {
  className?: string;
}
export const MessageStatusSendVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '18' height = '10' viewBox = '0 0 18 10' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M9.21441 5.5L11.2144 7.5L17.0001 1.5' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M2 4.16667L5.21429 7.5L11 1.5' />
    </svg>
  );
};
