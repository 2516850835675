import React from 'react';



type Props = {
  className?: string;
}
export const CircleCheckVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M16 9.91797L11.0769 14.918L8 11.793'/>
      <path stroke = 'currentColor' strokeWidth = '2' strokeLinecap = 'round' strokeLinejoin = 'round' d = 'M12 21.418C16.9706 21.418 21 17.3885 21 12.418C21 7.44741 16.9706 3.41797 12 3.41797C7.02944 3.41797 3 7.44741 3 12.418C3 17.3885 7.02944 21.418 12 21.418Z'/>
    </svg>
  );
};
