import styled                  from 'styled-components';

import { NavBarVariants      } from 'src/components/features/common/nav-bar';
import { BlockUIVariants     } from 'src/components/common/block-ui';

import { DefectsListVariants } from 'src/components/features/inspection/complaint/defects-list';

import { themeColors, 
         cssFlexColumn       } from 'src/providers/theme/shared';



export type InspectionComplaintLayout = {
  backgroundColor: string;

  paddings: number[];

  navbarVariant: NavBarVariants;
  defectListVariant: DefectsListVariants;
  blockUiVariant: BlockUIVariants;
}

export const layout_light: InspectionComplaintLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [24, 0, 20, 16],

  navbarVariant: 'lightblue',
  defectListVariant: 'light',
  blockUiVariant: 'light',
}

export const layout_dark: InspectionComplaintLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: InspectionComplaintLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: InspectionComplaintLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
  overflow: hidden;
`
