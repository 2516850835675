import styled                     from 'styled-components';

import { ButtonVariants         } from 'src/components/common/button';
import { LabeledContentVariants } from 'src/components/common/labeled-content';
import { TypographyVariants     } from 'src/components/common/typography';

import { cssFlexColumn,
         themeColors            } from 'src/providers/theme/shared';



export type ComplaintDefectItemLookAndFeel = {
  gap: number;

  blockBackgroundColor: string;
  blockBorderColor: string;
  blockPaddings: number[];
  blockGap: number;

  blockLabeledVariant: LabeledContentVariants;
  blockItemTypography: TypographyVariants;

  actionButtonVariant: ButtonVariants;

  hrColor: string;
}

const complaintdefectitem_light: ComplaintDefectItemLookAndFeel = {
  gap: 16,

  blockBackgroundColor: themeColors.PrimaryWhite,
  blockBorderColor: themeColors.SecondaryGray2,
  blockPaddings: [15],
  blockGap: 16,

  blockLabeledVariant: 'gray5w400s12lh14gap4',
  blockItemTypography: 'blackw400s14lh17',

  actionButtonVariant: 'h36blueoutline',

  hrColor: themeColors.SecondaryGray3,
}

export type ComplaintDefectItemVariants = 'light';

export const complaintdefectitem_variants: Map<ComplaintDefectItemVariants, ComplaintDefectItemLookAndFeel> = 
  new Map<ComplaintDefectItemVariants, ComplaintDefectItemLookAndFeel>([
    ['light', complaintdefectitem_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ComplaintDefectItemLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  width: 100%;
  flex-shrink: 0;
`

export const Block = styled.div<{
  lookAndFeel: ComplaintDefectItemLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.blockGap}px;
  background-color: ${(args) => args.lookAndFeel.blockBackgroundColor};
  border: 1px solid ${(args) => args.lookAndFeel.blockBorderColor};
  padding: ${(args) => args.lookAndFeel.blockPaddings[0]}px;
`

export const Hr = styled.div<{
  lookAndFeel: ComplaintDefectItemLookAndFeel
}>`
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`