import styled                    from 'styled-components';

import { BlockUIVariants       } from 'src/components/common/block-ui';

import { NavBarVariants        } from 'src/components/features/common/nav-bar';
import { ToolbarVariants       } from 'src/components/features/main/common/toolbar';
import { BlockCaptionVariants  } from 'src/components/features/main/common/block-caption';

import { InfoItemsListVariants } from 'src/components/features/main/info/info-items-list';

import { themeColors, 
         cssFlexColumn         } from 'src/providers/theme/shared';



export type InfoLayout = {
  backgroundColor: string;

  paddings: number[];

  navbarVariant: NavBarVariants;
  toolbarVariant: ToolbarVariants;
  blockCaptionVariant: BlockCaptionVariants;
  blockUIVariant: BlockUIVariants;
  infoItemsListVariant: InfoItemsListVariants;
}

export const layout_light: InfoLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [0, 16],

  navbarVariant: 'lightblue',
  toolbarVariant: 'light',
  blockCaptionVariant: 'light20t20b',
  blockUIVariant: 'light',
  infoItemsListVariant: 'light',
}

export const layout_dark: InfoLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: InfoLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: InfoLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding: ${(args) => args.layout.paddings[0]}px ${(args) => args.layout.paddings[1]}px;
  overflow-y: scroll;
`
