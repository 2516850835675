import styled                            from 'styled-components';

import { LoadingVariants               } from 'src/components/common/loading';
import { LinkVariants                  } from 'src/components/common/link';
import { ButtonVariants                } from 'src/components/common/button';
import { TwoActionDialogVariants       } from 'src/components/common/two-action-dialog';

import { NavBarVariants                } from 'src/components/features/common/nav-bar';
import { IconListVariants              } from 'src/components/features/main/common/icon-list';

import { VersionVariants               } from 'src/components/features/main/profile/version';
import { ClearDataAcceptDialogVariants } from 'src/components/features/main/profile/clear-data-accept-dialog';

import { themeColors, 
         cssFlexColumn,   
         cssFlexRow                    } from 'src/providers/theme/shared';



export type ProfileLayout = {
  backgroundColor: string;

  navbarVariant: NavBarVariants;
  iconlistVariant: IconListVariants;
  loadingVariant: LoadingVariants;
  versionVariant: VersionVariants;
  linkVariant: LinkVariants;
  buttonVariant: ButtonVariants;
  clearAcceptVariant: ClearDataAcceptDialogVariants;

  paddings: number[];
  beforeLinks: number;
  betweenLinks: number;
  afterLinks: number;

  buttonWidth: number;

  logoutConfirmVariant: TwoActionDialogVariants;
}

export const layout_light: ProfileLayout = {
  backgroundColor: themeColors.PrimaryWhite,

  navbarVariant: 'lightblue',
  iconlistVariant: 'light',
  loadingVariant: 'lightblue24',
  versionVariant: 'light',
  linkVariant: 'blue10',
  buttonVariant: 'h36bluefill',
  clearAcceptVariant: 'light',

  paddings: [20],
  beforeLinks: 40,
  betweenLinks: 20,
  afterLinks: 48,

  buttonWidth: 105,

  logoutConfirmVariant: 'light',
}

export const layout_dark: ProfileLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: ProfileLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`
export const ScreenBody = styled.div<{
  layout: ProfileLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  overflow-y: scroll;
`

export const Footer = styled.div<{
  layout: ProfileLayout,
}>`
  ${cssFlexColumn};
  padding: ${(args) => args.layout.paddings[0]}px;
  padding-top: none;
`

export const Actions = styled.div<{
  layout: ProfileLayout,
}>`
  ${cssFlexRow};
  flex-grow: 2;
  justify-content: space-between;
`

export const ActionButton = styled.div<{
  layout: ProfileLayout,
}>`
  width: ${(args) => args.layout.buttonWidth}px;
`
