import * as React            from 'react';

import cc                    from 'classcat';

import { Container,
         Control,
         LabelText,
         LabelLink,
         CheckboxVariants,
         checkbox_variants } from 'src/components/common/checkbox/ui';



type Props = {
  variant: CheckboxVariants;
  value: boolean;
  label?: string;
  url?: string;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
}
export const Checkbox: React.FC<Props> = ({
  variant,
  value,
  label,
  url,
  isDisabled = false,
  onChange,
}) => {
  const lookAndFeel = checkbox_variants.get(variant)!;

  const onClickHandler = () => {
    if (!isDisabled)
    {
      onChange(!value);
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Control lookAndFeel = { lookAndFeel } className = { cc({ checked: value, disabled: isDisabled }) } onClick = { onClickHandler }>
        <svg width = '17' height = '12' viewBox = '0 0 17 12' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
          <path d = 'M1 4.70117L7.00008 10.7012L16.1539 1.70117' stroke = 'currentColor' strokeWidth = '2' strokeLinejoin = 'bevel'/>
        </svg>
      </Control>
      { label !== undefined && url === undefined &&
        <LabelText lookAndFeel = { lookAndFeel }>
          { label }
        </LabelText>
      }
      { url !== undefined &&
        <LabelLink lookAndFeel = { lookAndFeel } target = '_blank' href = { url }>
          { label }
        </LabelLink>
      }
    </Container>
  );
};
