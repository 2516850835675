import styled             from 'styled-components';

import { SwitchVariants } from 'src/components/common/switch';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow,   
         cssFlexColumn  } from 'src/providers/theme/shared';



type BooleanFlagsListLookAndFeel = {
  itemHeight: number;
  itemGap: number;

  dividerColor: string;

  listGap: number;
  
  iconColor: string;
  iconSize: number;

  labelTypo: Typo;
  labelColor: string;

  switchVariant: SwitchVariants;
}

const booleanflagslist_light: BooleanFlagsListLookAndFeel = {
  itemHeight: 48,
  itemGap: 20,

  dividerColor: themeColors.SecondaryGray3,

  listGap: 4,

  iconColor: themeColors.AccentBlue1,
  iconSize: 24,

  labelTypo: themeTypos.GRTSKREG_W400_S16_LH22,
  labelColor: themeColors.PrimaryBlack,

  switchVariant: 'w40h24',
}

export type BooleanFlagsListVariants = 'light';

export const booleanflagslist_variants: Map<BooleanFlagsListVariants, BooleanFlagsListLookAndFeel> = 
  new Map<BooleanFlagsListVariants, BooleanFlagsListLookAndFeel>([
    ['light', booleanflagslist_light],
  ]);

export const ItemList = styled.div<{
  lookAndFeel: BooleanFlagsListLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.listGap}px;
`

export const Item = styled.div<{
  lookAndFeel: BooleanFlagsListLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.itemHeight}px;
  gap: ${(args) => args.lookAndFeel.itemGap}px;

  align-items: center;
`

export const Label = styled.span<{
  lookAndFeel: BooleanFlagsListLookAndFeel,
}>`
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
`;

export const Icon = styled.span<{
  lookAndFeel: BooleanFlagsListLookAndFeel,
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.iconSize}px;
  height: ${(args) => args.lookAndFeel.iconSize}px;
  justify-content: center;
  align-items: center;

  color: ${(args) => args.lookAndFeel.iconColor};
`;

export const Divider = styled.span<{
  lookAndFeel: BooleanFlagsListLookAndFeel,
}>`
  width: 100%;
  height: 1px;

  background-color: ${(args) => args.lookAndFeel.dividerColor};
`;