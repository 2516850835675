import * as React                     from 'react';

import { useNavigate                } from 'react-router-dom';

import { useTheme                   } from 'styled-components';

import { useAlert                   } from 'react-alert';

import { Labeled                    } from 'src/components/common/labeled';
import { InputPhone                 } from 'src/components/common/input/input-phone';
import { InputTextbox, 
         LatinAlphaNumericUpperCase } from 'src/components/common/input/input-textbox';
import { Button                     } from 'src/components/common/button';
import { VGap                       } from 'src/components/common/flex/vgap';
import { Grow                       } from 'src/components/common/flex/grow';
import { BlockUI                    } from 'src/components/common/block-ui';
import { Link                       } from 'src/components/common/link';

import { NavBar                     } from 'src/components/features/auth/common/nav-bar';
import { Note                       } from 'src/components/features/auth/reg-start/note';

import { RegStartLayout, 
         Screen,
         ScreenBody,
         layout_light,
         layout_dark                } from 'src/containers/auth/reg-start/layout';

import { Urls                       } from 'src/providers/routing';

import Api, { HTTPStatusCodes }       from 'src/services/api';

import { useAppSelector, 
         useAppDispatch,
         AppDispatch,
         storeApi                   } from 'src/store';
import { CheckInviteCodeReject      } from 'src/store/auth/reg-start';

import { AsyncOpStatus              } from 'src/common';



type RegStartScreenElement = { layout: RegStartLayout; dispatch: AppDispatch; }

const Navigation: React.FC<RegStartScreenElement> = ({ layout, dispatch }) => {
  const navigate = useNavigate();

  return (
    <NavBar
      variant = { layout.navbarVariant }
      label = 'Регистрация'
      onBackTap = { () => navigate(-1) }
    />
  )
}

const Phone: React.FC<RegStartScreenElement> = ({ layout, dispatch }) => {
  const phone = useAppSelector(storeApi.auth.regStart.selectors.selectPhone);
  const phoneError = useAppSelector(storeApi.auth.regStart.selectors.selectPhoneError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapForm } />
      <Labeled variant = { layout.labeledVariant } label = 'Ваш номер телефона' isRequired>
        <InputPhone
          variant = { layout.inputPhoneVariant }
          value = { phone }
          isError = { phoneError !== '' }
          hint = { phoneError }
          onChange = { (value) => dispatch(storeApi.auth.regStart.actions.phoneChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regStart.actions.phoneErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Code: React.FC<RegStartScreenElement> = ({ layout, dispatch }) => {
  const code = useAppSelector(storeApi.auth.regStart.selectors.selectInviteCode);
  const codeError = useAppSelector(storeApi.auth.regStart.selectors.selectInviteCodeError);

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Labeled variant = { layout.labeledVariant } label = 'Пригласительный код' isRequired>
        <InputTextbox
          variant = { layout.inputTextboxVariant }
          value = { code }
          isError = { codeError !== '' }
          hint = { codeError }
          maxLength = { 8 }
          valueTransformer = { LatinAlphaNumericUpperCase }
          onChange = { (value) => dispatch(storeApi.auth.regStart.actions.inviteCodeChanged(value)) }
          onFocus = { () => dispatch(storeApi.auth.regStart.actions.inviteCodeErrorFixed()) }
        />
      </Labeled>
    </React.Fragment>
  )
}

const Actions: React.FC<RegStartScreenElement> = ({layout, dispatch }) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [opStatus, setOpStatus] = React.useState<AsyncOpStatus>(AsyncOpStatus.IDLE);
  const formFilled = useAppSelector(storeApi.auth.regStart.selectors.selectFormFilled);
  const phone = useAppSelector(storeApi.auth.regStart.selectors.selectPhone);

  const onRequestCodeTapHandler = () => {
    dispatch(storeApi.auth.regRequestCode.actions.formCleared());
    dispatch(storeApi.auth.regRequestCode.actions.phoneChanged(phone));
    navigate(-2);
    Urls.RegisterRequestCode.build().navigateWithReplace({ backTo: 'reg' });
  }

  const onCheckCodeTapHandler = () => {
    setOpStatus(AsyncOpStatus.BUSY);
    dispatch(storeApi.auth.regStart.async.checkInviteCodeAsync())
      .unwrap()
      .then((result) => {
        setOpStatus(AsyncOpStatus.IDLE);
        dispatch(storeApi.auth.common.actions.authKeyChanged(result.authKey));
        dispatch(storeApi.auth.common.actions.enterpriseNameChanged(result.enterpriseName));
        Urls.SmsCode.build('reg').navigateWithReplace({ backTo: 'reg' });
      })
      .catch((rawError) => {
        const error = rawError as CheckInviteCodeReject;
        if (error.reason === 'api')
        {
          const apiErrorCode = (error.apiError?.statusCode ?? 0) as HTTPStatusCodes;
          if (!Api.isCommonAuthError(apiErrorCode))
          {
            alert.error(error.apiError?.message);

            if (apiErrorCode === HTTPStatusCodes.FailedDependency)
            {
              Urls.Login.build('start').navigateWithReplace();
            }
          }
        }
        setOpStatus(AsyncOpStatus.IDLE);
      });
  }

  return (
    <React.Fragment>
      <VGap size = { layout.gapFormElements } />
      <Note variant = { layout.noteVariant }>
        Пригласительный код можно получить у сотрудника службы технической поддержки или
      </Note>
      <VGap size = { layout.gapButtons } />
      <Button 
        variant = { layout.requestButtonVariant }
        label = 'Запросить код самостоятельно' 
        onTap = { onRequestCodeTapHandler }
      />
      <VGap size = { layout.gapButtons } />
      <Button
        variant = { layout.nextButtonVariant }
        label = 'Далее'
        isDisabled = { !formFilled }
        onTap = { onCheckCodeTapHandler }
      />
      <BlockUI
        variant = { layout.blockuiVariant } 
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = 'Проверка пригласительного кода'
      />
    </React.Fragment>
  )
}

const Contacts: React.FC<RegStartScreenElement> = ({ layout }) => {
  return (
    <React.Fragment>
      <Grow size = { 2 } />
      <Link variant = { layout.linkVariant } isCentered label = 'ukpp@severstal.com' url = 'mailto:ukpp@severstal.com' />
      <VGap size = { layout.gapLabels } />
      <Link variant = { layout.linkVariant } isCentered label = '8 (800) 200-69-39' url = 'tel:88002006939' />
    </React.Fragment>
  )
}

export const RegStartScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!bootOpStarted.current)
    {
      console.log('reg-start push');
      Urls.RegisterStart.pushState({});
    }

    bootOpStarted.current = true;

    const handler = (ev: PopStateEvent) => {
      console.log('reg-start', ev);
      switch (ev.state.usr?.backTo)
      {
        case 'login':
          Urls.Login.build().navigateWithReplace();
          break;
        default:

      }
    }
    window.addEventListener('popstate', handler);

    return () => window.removeEventListener('popstate', handler);
  });

  console.log(window.history);

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <Phone layout = { layout } dispatch = { dispatch } />
        <Code layout = { layout } dispatch = { dispatch } />
        <Actions layout = { layout } dispatch = { dispatch } />
        <Contacts layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
