import React from 'react';



type Props = {
  className?: string;
}
export const InspectionMenuEmailVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M1 11L1 4.26923L1 4H18.1818H19V15.2308V16H1V15.2308V13' />
      <path stroke = 'currentColor' strokeWidth = '1.5' d = 'M15 8L11 11H9L5 8' />
    </svg>
  );
};
