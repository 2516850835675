import styled           from 'styled-components';

import { themeColors,
         Typo,
         themeTypos,
         typoAsCSS    } from 'src/providers/theme/shared';



type NoteLookAndFeel = {
  labelTypo: Typo;
  color: string;
}

const note_light: NoteLookAndFeel = {
  labelTypo: themeTypos.GRTSKREG_W400_S10_LH16,
  color: themeColors.PrimaryBlack,
}

export type NoteVariants = 'light';

export const note_variants: Map<NoteVariants, NoteLookAndFeel> = 
  new Map<NoteVariants, NoteLookAndFeel>([
    ['light', note_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: NoteLookAndFeel,
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
  color: ${(args) => args.lookAndFeel.color};
`
