import styled             from 'styled-components';

import { ButtonVariants } from 'src/components/common/button';

import { themeColors, 
         Typo,
         themeTypos,
         typoAsCSS,      
         cssFlexRow,   
         cssFlexColumn  } from 'src/providers/theme/shared';



type EmailConfirmDialogLookAndFeel = {
  paddings: number[];
  emailPaddings: number[];

  gap: number;
  actionsGap: number;

  headerTypo: Typo;
  headerColor: string;

  labelTypo: Typo;
  labelColor: string;

  cancelVariant: ButtonVariants;
  submitVariant: ButtonVariants;
}

const emailconfirmdialog_light: EmailConfirmDialogLookAndFeel = {
  paddings: [28, 20],
  emailPaddings: [16, 0],

  gap: 20,
  actionsGap: 16,

  headerTypo: themeTypos.GRTSKREG_W600_S18_LH24,
  headerColor: themeColors.AccentRed1,

  labelTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  labelColor: themeColors.PrimaryBlack,

  cancelVariant: 'h48blueoutline',
  submitVariant: 'h48bluefill',
}

export type EmailConfirmDialogVariants = 'light';

export const emailconfirmnote_variants: Map<EmailConfirmDialogVariants, EmailConfirmDialogLookAndFeel> = 
  new Map<EmailConfirmDialogVariants, EmailConfirmDialogLookAndFeel>([
    ['light', emailconfirmdialog_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: EmailConfirmDialogLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.gap}px;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px ${(args) => args.lookAndFeel.paddings[1]}px;
`

export const Header = styled.span<{
  lookAndFeel: EmailConfirmDialogLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.headerTypo)};
  color: ${(args) => args.lookAndFeel.headerColor};
  text-align: center;
`;

export const Label = styled.span<{
  lookAndFeel: EmailConfirmDialogLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};
  text-align: center;

  & i {
    display: block;
    font-weight: bold;
    font-style: normal;
    padding: ${(args) => args.lookAndFeel.emailPaddings[0]}px ${(args) => args.lookAndFeel.emailPaddings[1]}px;
  }
`;

export const ActionButtons = styled.div<{
  lookAndFeel: EmailConfirmDialogLookAndFeel,
}>`
  ${cssFlexRow};
  width: 100%;

  gap: ${(args) => args.lookAndFeel.actionsGap}px;
`