import * as React                       from 'react';

import { Button                       } from 'src/components/common/button';
import { Container, 
         Caption,
         Actions,
         DeleteConfirmDialogVariants,
         deleteconfirmdialog_variants } from 'src/components/features/main/notifications/delete-confirm-dialog/ui';



type Props = {
  variant: DeleteConfirmDialogVariants;
  onAccept: () => void;
  onCancel: () => void;
}
export const DeleteConfirmDialog: React.FC<Props> = ({
  variant,
  onAccept,
  onCancel,
}) => {
  const lookAndFeel = deleteconfirmdialog_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Уверены, что хотите удалить все уведомления? 
      </Caption>
      <Actions lookAndFeel = { lookAndFeel }>
        <Button
          variant = { lookAndFeel.cancelButtonVariant }
          label = 'Отмена'
          onTap = { onCancel }
        />
        <Button
          variant = { lookAndFeel.acceptButtonVariant }
          label = 'Удалить'
          onTap = { onAccept }
        />
      </Actions>
    </Container>
  );
};
