import * as React        from 'react';

import { Container,
         LinkVariants,
         link_variants } from 'src/components/common/link/ui';



type Props = {
  variant: LinkVariants;
  label: string;
  isDisabled?: boolean;
  isCentered?: boolean;
  url?: string;
  onTap?: () => void;
}
export const Link: React.FC<Props> = ({
  variant,
  label,
  isDisabled = false,
  isCentered = false,
  url = '#',
  onTap,
}) => {
  const lookAndFeel = link_variants.get(variant)!;

  const onTapHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onTap !== undefined)
    {
      event.preventDefault();
      onTap();
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel } isDisabled = { isDisabled } isCentered = { isCentered } href = { url } target = '_blank' onClick = { onTapHandler }>
      { label }
    </Container>
  );
};
