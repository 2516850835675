import React from 'react';



type Props = {
  className?: string;
}
export const CircleWarningVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '16' viewBox = '0 0 16 16' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '1.5' strokeLinecap = 'square' fillRule = 'evenodd' clipRule = 'evenodd'  d = 'M8.00065 1.83398C11.4067 1.83398 14.1673 4.59532 14.1673 8.00065C14.1673 11.406 11.4067 14.1673 8.00065 14.1673C4.59532 14.1673 1.83398 11.406 1.83398 8.00065C1.83398 4.59532 4.59532 1.83398 8.00065 1.83398Z' />
      <path stroke = 'currentColor' strokeWidth = '1.5' strokeLinecap = 'square' d = 'M7.9974 5.46973V8.41573' />
      <path stroke = 'currentColor' strokeWidth = '1.5' strokeLinecap = 'square' d = 'M7.99602 10.5306H8.00268' />
    </svg>
  );
};
