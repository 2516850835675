import styled             from 'styled-components';

import { LinkVariants   } from 'src/components/common/link';

import { cssFlexColumn,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type SendAgainLookAndFeel = {
  counterTypo: Typo;

  gap: number;

  counterColor: string;
  counterDisabledColor: string;

  linkVariant: LinkVariants;
}

const sendagain_light: SendAgainLookAndFeel = {
  counterTypo: themeTypos.GRTSKREG_W400_S15_LH23,

  gap: 11,

  counterColor: themeColors.PrimaryBlack,
  counterDisabledColor: themeColors.SecondaryGray4,

  linkVariant: 'blue15',
}

export type SendAgainVariants = 'light';

export const sendagain_variants: Map<SendAgainVariants, SendAgainLookAndFeel> = 
  new Map<SendAgainVariants, SendAgainLookAndFeel>([
    ['light', sendagain_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: SendAgainLookAndFeel,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  align-items: center;
`

export const Counter = styled.span<{
  lookAndFeel: SendAgainLookAndFeel,
  isDisabled: boolean,
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.counterTypo)}
  color: ${(args) => args.isDisabled ? args.lookAndFeel.counterDisabledColor : args.lookAndFeel.counterColor};
`
