import styled                 from 'styled-components';

import { ButtonVariants     } from 'src/components/common/button';
import { EmptyCaseVariants  } from 'src/components/common/empty-case';

import { DefectItemVariants } from 'src/components/features/inspection/edit/defect-item';

import { cssFlexColumn,
         themeColors        } from 'src/providers/theme/shared';



type DefectListFragmentLookAndFeel = {
  gap: number;
  actionsGap: number;
  padding: number[];

  hrColor: string;

  defectItemVariant: DefectItemVariants;
  completeButtonVariant: ButtonVariants;
  dangerEmptyCase: EmptyCaseVariants;
}

const defectlistfragment_light: DefectListFragmentLookAndFeel = {
  gap: 24,
  actionsGap: 20,
  padding: [24, 16, 0, 0],

  hrColor: themeColors.SecondaryGray3,
  
  defectItemVariant: 'light',
  completeButtonVariant: 'h48bluefill',
  dangerEmptyCase: 'light',
}

export type DefectListFragmentVariants = 'light';

export const defectlistfragment_variants: Map<DefectListFragmentVariants, DefectListFragmentLookAndFeel> = 
  new Map<DefectListFragmentVariants, DefectListFragmentLookAndFeel>([
    ['light', defectlistfragment_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: DefectListFragmentLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  flex-grow: 2;
`

export const List = styled.div<{
  lookAndFeel: DefectListFragmentLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  overflow-y: auto;
  flex-grow: 2;
`

export const Actions = styled.div<{
  lookAndFeel: DefectListFragmentLookAndFeel
}>`
  ${cssFlexColumn};
  width: 100%;
  gap: ${(args) => args.lookAndFeel.actionsGap}px;
  padding-top: ${(args) => args.lookAndFeel.padding[0]}px;
  padding-bottom: ${(args) => args.lookAndFeel.padding[0]}px;
  border-top: 1px solid ${(args) => args.lookAndFeel.hrColor};
`