import React from 'react';



type Props = {
  className?: string;
}
export const FilterVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = {className} width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M9 13L10 14V17L13 21H14V14L21 7V3H3V7L7 11' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
