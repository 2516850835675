import React from 'react';



type Props = {
  className?: string;
}
export const MessageActionComebackVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '16' height = '17' viewBox = '0 0 16 17' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M14.0005 3.49976L14.0005 6.49976L13.0005 7.49976L10.0005 7.49976M11.7071 4.95656C9.75032 2.63321 6.28061 2.33601 3.95726 4.29275C1.63391 6.24949 1.33671 9.71919 3.29345 12.0425C5.25019 14.3659 8.7199 14.6631 11.0432 12.7064C11.5829 12.2518 12.0133 11.7157 12.3315 11.1312' />
    </svg>
  );
};
