import * as React                from 'react';

import { useTheme              } from 'styled-components';

import { useAlert              } from 'react-alert';

import { ArrowBackVector       } from 'src/components/vector/arrowback';
import { ProfileVector         } from 'src/components/vector/profile';
import { RefreshVector         } from 'src/components/vector/refresh';
import { CleanVector           } from 'src/components/vector/clean';
import { GearVector            } from 'src/components/vector/gear';
import { HelpVector            } from 'src/components/vector/help';

import { Grow                  } from 'src/components/common/flex/grow';
import { VGap                  } from 'src/components/common/flex/vgap';
import { Link                  } from 'src/components/common/link';
import { Button                } from 'src/components/common/button';
import { Dialog                } from 'src/components/common/dialog';
import { TwoActionDialog       } from 'src/components/common/two-action-dialog';

import { NavBar                } from 'src/components/features/common/nav-bar';
import { IconList,
         IconListElement       } from 'src/components/features/main/common/icon-list';
         
import { Version               } from 'src/components/features/main/profile/version';
import { ClearDataAcceptDialog } from 'src/components/features/main/profile/clear-data-accept-dialog';

import { Screen,
         ScreenBody,
         Footer,
         ActionButton,
         Actions,
         layout_light,
         layout_dark,          } from 'src/containers/main/profile/layout';

import { Urls                  } from 'src/providers/routing';

import Constants                 from 'src/services/constants';
import * as types                from 'src/services/api/types';
import Db                        from 'src/services/db';

import { useAppSelector, 
         useAppDispatch,
         storeApi              } from 'src/store';

import { AsyncOpStatus         } from 'src/common';
import preferences from 'src/services/preferences';



const getRoleStrByCode = (code: string): string => {
  const roleMap = new Map<string, string>([
    ['client', 'Клиент'],
    ['clientAdmin', 'Клиент-админ'],
    ['contractor', 'Подрядчик'],
    ['stpk', 'СТПК']
  ]);

  return roleMap.get(code) ?? 'Неизвестная роль';
}

const { Client, ClientAdmin, Contractor, Stpk } = types.auth.AccountRole;

const allItems: IconListElement[] = [
  {
    code: 'account',
    busy: false,
    icon: <ProfileVector />,
    label: 'Личные данные',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'refers',
    busy: false,
    icon: <RefreshVector />,
    label: 'Обновить справочники',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'clean',
    busy: false,
    icon: <CleanVector />,
    label: 'Очистить данные',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  {
    code: 'admin',
    busy: false,
    icon: <GearVector />,
    label: 'Администрирование',
    availableFor: (account: types.auth.AccountWithTokens) => [ClientAdmin].includes(account.role),
  },
  {
    code: 'help',
    busy: false,
    icon: <HelpVector />,
    label: 'Справка',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  },
  /*
  {
    code: 'update',
    busy: false,
    icon: <UpdateCheckVector />,
    label: 'Проверить обновления',
    availableFor: (account: types.auth.AccountWithTokens) => [Client, ClientAdmin, Contractor, Stpk].includes(account.role),
  }
  */
]

export const ProfileScreen = () => {
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;
  const refersLoadingStatus = useAppSelector(storeApi.main.profile.selectors.selectRefersLoadingStatus);

  const [clearOpened, setClearOpened] = React.useState<boolean>(false);
  const [logoutConfirmOpened, setLogoutConfirmOpened] = React.useState<boolean>(false);

  let roleItems = allItems
    .filter((item) => item.availableFor(account))
    .map((item) => ({ ...item, busy: item.code === 'refers' ? refersLoadingStatus === AsyncOpStatus.BUSY : false }));

  const onIconListItemTap = (item: IconListElement) => {
    switch (item.code)
    {
      case 'help':
        Urls.MainHelp.build().navigate();
        break;
      case 'admin':
        Urls.MainAdmin.build().navigate();
        break;
      case 'account':
        dispatch(storeApi.main.personal.async.copyStateFromAccountAsync())
          .unwrap()
          .then((_) => Urls.MainPersonal.build().navigate())
        break;
      case 'refers':
        dispatch(storeApi.main.profile.async.updateRefersAsync());
        break;
      case 'clean':
        setClearOpened(true);
        break;
    }
  }

  const onLogoutHandler = () => {
    setLogoutConfirmOpened(true);
  }

  const onClearDataCancelHandler = () => {
    setClearOpened(false);
  }

  const onClearDataAcceptHandler = () => {
    Db.clearData(true)
      .then((_) => {
        alert.success('Данные очищены');
        Urls.MainInspections.build().navigate();
      })
      .catch((error) => { console.log(error); alert.error('Во время очистки данных произошла ошибка') });
    setClearOpened(false);
  }

  const onLogoutCancelHandler = () => {
    setLogoutConfirmOpened(false);
  }

  const onLogoutAcceptHandler = () => {
    setLogoutConfirmOpened(false);
    dispatch(storeApi.auth.common.actions.accountChanged(null));
    preferences.removeStoredAccount();
    Db.clearData(true)
      .then((_) => {
        Urls.Login.build().navigate();
      })
      .catch((_) => alert.error('Во время очистки данных произошла ошибка'));
  }

  return (
    <Screen layout = { layout }>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Профиль'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainInspections.build().navigate()
          }
        ]}
      />
      <ScreenBody layout = { layout }>
        <IconList
          variant = { layout.iconlistVariant }
          items = { roleItems }
          onItemTap = { onIconListItemTap }
        />
        <Grow size = { 2 } />
        <Footer layout = { layout }>
          <VGap size = { layout.beforeLinks } />
          <Link 
            variant = { layout.linkVariant }
            label = 'Политика обработки персональных данных'
            url = { `${Constants.API_HOST}api/serv-proxy/official/4/1652280098/soglasie_checksteel.pdf` }
          />
          <VGap size = { layout.betweenLinks } />
          <Link 
            variant = { layout.linkVariant }
            label = 'Правила по работе с приложением'
            url = { `${Constants.API_HOST}api/serv-proxy/official/3/1629980904/pravila_po_rabote_s_prilozheniem_checksteel.pdf` }
          />
          <VGap size = { layout.afterLinks } />
          <Actions layout = { layout }>
            <ActionButton  layout = { layout }>
              <Button variant = { layout.buttonVariant } label = 'Выход' onTap = { onLogoutHandler } />
            </ActionButton>
            <Version
              variant = { layout.versionVariant }
              role = { getRoleStrByCode(account.role) }
              version = { `v. ${Constants.APP_VERSION}` }
            />
          </Actions>
        </Footer>
        <TwoActionDialog
          opened = { logoutConfirmOpened }
          variant = { layout.logoutConfirmVariant }
          caption = 'Выйти из профиля?'
          message = 'Будут удалены все внутренние данные приложения, включая неотправленные на сервер. Вы согласны?'
          actionLabel = 'Выйти'
          isDanger
          onCancel = { onLogoutCancelHandler }
          onAction = { onLogoutAcceptHandler }

        />
        <Dialog variant = { layout.clearAcceptVariant } isOpened = { clearOpened }>
          <ClearDataAcceptDialog
            variant = { layout.clearAcceptVariant }
            onAccept = { onClearDataAcceptHandler }
            onCancel =  { onClearDataCancelHandler }
          />
        </Dialog>
      </ScreenBody>
    </Screen>
  );
};
