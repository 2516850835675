import * as React               from 'react';

import { useAlert             } from 'react-alert';

import { Button               } from 'src/components/common/button';
import { TwoActionDialog      } from 'src/components/common/two-action-dialog';

import { DefectItem           } from 'src/components/features/inspection/edit/defect-item';

import { Container, 
         List,
         Actions,
         TwoColumnActions,
         DefectsListVariants,
         defectslist_variants } from 'src/components/features/inspection/edit/defects-list/ui';

import { Urls                 } from 'src/providers/routing';

import { AccountRole          } from 'src/services/api/types/auth';
import { InspectionState      } from 'src/services/api/types/inspection';

import { useAppSelector, 
         storeApi,                  
         useAppDispatch       } from 'src/store';



type Props = {
  variant: DefectsListVariants;
}
export const DefectsList: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = defectslist_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [errorOpened, setErrorOpened] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount)!;

  const controlButtons = useAppSelector(storeApi.inspection.edit.selectors.selectDefectControlButtonsV2);
  const defectsGuidsStr = useAppSelector(storeApi.inspection.edit.selectors.selectDefectAllGuidsList);
  const inspectionState = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralInspectionState);
  const expandedGuid = useAppSelector(storeApi.inspection.edit.selectors.selectDefectExpandedGuid);
  const isEntryAccounting = useAppSelector(storeApi.inspection.edit.selectors.selectGeneralIsEntryAccounting);

  const defectsGuids = defectsGuidsStr === '' ? [] : defectsGuidsStr.split(';');

  const isContractor = account.role === AccountRole.Contractor;
  const isAddPieceAllowed = !isContractor && (inspectionState !== InspectionState.EDIT);
  const isExpanded = expandedGuid !== '' || isEntryAccounting;

  const onAddDefectTapHandler = () => {
    dispatch(storeApi.inspection.edit.async.addDefectAsync())
      .unwrap()
      .catch((error) => {
        setErrorOpened(true);
        setErrorText(error.error);
      });
  }

  const onAddComplaintTapHandler = () => {
    Urls.InspectionComplaint.build('new').navigate();
  }

  const onGotoComplaintTapHandler = () => {
    switch (controlButtons.gotocomplaint.action)
    {
      case 'goto_complaintlist':
        Urls.InspectionComplaint.build('list').navigate();
        break;
      case 'goto_complaintitem_changetocomplaint':
        dispatch(storeApi.inspection.edit.async.checkDefectsFilledAsync())
          .unwrap()
          .then(() => Urls.InspectionComplaint.build('tocomplaint').navigate())
          .catch((error) => {
            setErrorOpened(true);
            setErrorText(error.error);
          });
        break;
      case 'goto_complaintitem_changetocomplaintfinance':
        dispatch(storeApi.inspection.edit.async.checkDefectsFilledAsync())
          .unwrap()
          .then(() => Urls.InspectionComplaint.build('tocomplaintfinance').navigate())
          .catch((error) => {
            setErrorOpened(true);
            setErrorText(error.error);
          });
        break;
      case 'goto_complaintitem_edit':
        dispatch(storeApi.inspection.edit.async.checkDefectsFilledAsync())
          .unwrap()
          .then(() => Urls.InspectionComplaint.build('edit').navigate())
          .catch((error) => {
            setErrorOpened(true);
            setErrorText(error.error);
          });
        break;
      case 'goto_complaintitem_view':
        Urls.InspectionComplaint.build('view').navigate();
        break;
    }
  }

  const onErrorCloseHandler = () => {
    setErrorOpened(false);
    setErrorText('');
  }

  const onNextHandler = () => {
    switch (controlButtons.next.action)
    {
      case 'complete':
        dispatch(storeApi.inspection.edit.async.saveInspectionAsync({ isComplete: true }))
          .unwrap()
          .then(() => {
            alert.success('Осмотр успешно сохранен');
            Urls.MainInspections.build().navigate();
          })
          .catch((error) => {
            if (error.redirectToList)
            {
              alert.error(error.error);
              Urls.MainInspections.build().navigate();
            }
            else
            {
              setErrorOpened(true);
              setErrorText(error.error);
            }
          });
        break;
      case 'goto_complaintlist':
        dispatch(storeApi.inspection.edit.async.checkDefectsFilledAsync())
          .unwrap()
          .then(() => Urls.InspectionComplaint.build('list').navigate())
          .catch((error) => {
            setErrorOpened(true);
            setErrorText(error.error);
          });
        break;
    }
  }

  const onAddPositionTapHandler = () => {
    dispatch(storeApi.inspection.edit.actions.pieceForEditSelected(null));
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <List lookAndFeel = { lookAndFeel }>
        {defectsGuids.map((defectGuid) => (
          <DefectItem
            variant = { lookAndFeel.defectItemVariant }
            defectGuid = { defectGuid }
            key = { defectGuid }
          />
        ))}
      </List>
      <Actions lookAndFeel = { lookAndFeel }>
        {isAddPieceAllowed && isExpanded && !controlButtons.adddefect.visible && (
          <Button
            variant = { lookAndFeel.strokeButtonH36Variant }
            label = 'Добавить позицию'
            onTap = { onAddPositionTapHandler }
          />
        )}
        {controlButtons.adddefect.visible && (
          <TwoColumnActions lookAndFeel = { lookAndFeel }>
            {isAddPieceAllowed && isExpanded && (
              <Button
                variant = { lookAndFeel.strokeButtonVariant }
                label = 'Добавить позицию'
                onTap = { onAddPositionTapHandler }
              />
            )}
            <Button
              variant = { lookAndFeel.strokeButtonVariant }
              label = { controlButtons.adddefect.label }
              onTap = { onAddDefectTapHandler }
            />
          </TwoColumnActions>
        )}
        {controlButtons.addcomplaint.visible && (
          <Button
            variant = { lookAndFeel.strokeButtonVariant }
            label = { controlButtons.addcomplaint.label }
            onTap = { onAddComplaintTapHandler }
          />
        )}
        {controlButtons.gotocomplaint.visible && (
          <Button
            variant = { lookAndFeel.strokeButtonVariant }
            label = { controlButtons.gotocomplaint.label }
            onTap = { onGotoComplaintTapHandler }
          />
        )}
        {controlButtons.next.visible && (
          <Button
            variant = { lookAndFeel.fillButtonVariant }
            label = { controlButtons.next.label }
            onTap = { onNextHandler }
          />
        )}
      </Actions>
      <TwoActionDialog
        opened = { errorOpened }
        variant = { lookAndFeel.twoActionDialogVariant }
        caption = 'Ошибка'
        message = { errorText }
        withoutCancel
        isDanger
        onAction = { onErrorCloseHandler }
      />
    </Container>
  );
};
