import React from 'react';



type Props = {
  className?: string;
}
export const StarOutlineVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' strokeMiterlimit = '10' d = 'M23 11L21.9487 8.58885H15.7099C15.5117 8.58893 15.3185 8.52618 15.1581 8.4096C14.9977 8.29301 14.8783 8.12859 14.817 7.93989L12.8915 2H11.1085L9.18298 7.93989C9.12167 8.12859 9.00226 8.29301 8.84186 8.4096C8.68146 8.52618 8.48831 8.58893 8.29009 8.58885H2.05199L1.5 10.2887L6.5471 13.9597C6.70748 14.0763 6.82685 14.2407 6.8881 14.4293C6.94934 14.618 6.9493 14.8213 6.888 15.0099L4.96244 20.9498L6.40661 22L11.4537 18.3297C11.614 18.213 11.8071 18.1501 12.0053 18.1501C12.2036 18.1501 12.3967 18.213 12.557 18.3297L17.6041 22L19.0483 20.9498L17.2121 14.7198V14.0811L17.5 13.5L19.5 12.5' />
    </svg>
  );
};
