import styled             from 'styled-components';

import { cssFlexColumn, 
         cssFlexRow,
         Typo,
         themeColors,            
         themeTypos,
         typoAsCSS      } from 'src/providers/theme/shared';



type HistoryItemLookAndFeel = {
  paddings: number[];
  gap: number;

  backgroundColor: string;
  borderColor: string;

  fioTypo: Typo;
  fioColor: string;
  dateTypo: Typo;
  dateColor: string;
  messageTypo: Typo;
  messageColor: string;

  statusSendColor: string;
  statusErrorColor: string;
  statusIconGap: number;
}

const historyitem_light: HistoryItemLookAndFeel = {
  paddings: [16],
  gap: 8,

  backgroundColor: themeColors.PrimaryWhite,
  borderColor: themeColors.CardBorderColor,

  fioTypo: themeTypos.GRTSKREG_W600_S14_LH17,
  fioColor: themeColors.PrimaryBlack,
  dateTypo: themeTypos.GRTSKREG_W400_S10_LH12,
  dateColor: themeColors.AccentBlue1,
  messageTypo: themeTypos.GRTSKREG_W400_S14_LH20,
  messageColor: themeColors.PrimaryBlack,

  statusSendColor: themeColors.AccentBlue2,
  statusErrorColor: themeColors.AccentRed1,
  statusIconGap: 4,
}

export type HistoryItemVariants = 'light';

export const historyitem_variants: Map<HistoryItemVariants, HistoryItemLookAndFeel> = 
  new Map<HistoryItemVariants, HistoryItemLookAndFeel>([
    ['light', historyitem_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: HistoryItemLookAndFeel;
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  width: 100%;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor} !important;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
`;

export const HeaderRow = styled.div<{
  lookAndFeel: HistoryItemLookAndFeel;
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.statusIconGap}px;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderRowFIO = styled.div<{
  lookAndFeel: HistoryItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.fioTypo)};
  color: ${(args) => args.lookAndFeel.fioColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const HeaderRowDateStatus = styled.div<{
  lookAndFeel: HistoryItemLookAndFeel;
  isSend: boolean;
}>`
  ${cssFlexRow};
  align-items: center;
  gap: ${(args) => args.lookAndFeel.statusIconGap}px;

  ${(args) => typoAsCSS(args.lookAndFeel.dateTypo)};
  color: ${(args) => args.lookAndFeel.dateColor};
  white-space: nowrap;

  & svg {
    color: ${(args) => args.isSend ? args.lookAndFeel.statusSendColor : args.lookAndFeel.statusErrorColor };
  }
`;

export const MessageRow = styled.div<{
  lookAndFeel: HistoryItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`;