
import React from 'react';



type Props = {
  className?: string;
}
export const SharingVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '5' cy = '12.418' r = '3' />
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '18' cy = '5.41797' r = '3' />
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '18' cy = '19.418' r = '3' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M10 9.41797L15.5 6.76413' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M15.5 18.0714L7.5 13.7637' />
    </svg>
  );
};
