import React                         from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { ThemeAppProvider          } from 'src/providers/theme';
import { AlertAppProvider          } from 'src/providers/alert';
import { AppRouterProvider         } from 'src/providers/routing';

import { store                     } from 'src/store';

import 'src/assets/fonts/font.css';



function App() {
  return (
    <StoreProvider store={store}>
      <ThemeAppProvider>
        <AlertAppProvider>
          <AppRouterProvider />
        </AlertAppProvider>
      </ThemeAppProvider>
    </StoreProvider>
  );
}

export default App;
