import styled              from 'styled-components';
 
import { LoadingVariants } from 'src/components/common/loading';

import { cssFlexColumn, 
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors,        
         cssFlexRowCC    } from 'src/providers/theme/shared';



type AttachmentPhotoLookAndFeel = {
  paddings: number[];
  height: number;
  gap: number;
  closeSize: number;
  closeTop: number;
  closeRight: number;
  closeColor: string;

  photoAreaHeight: number;
  photoAreaColor: string;

  labelTypo: Typo;
  labelColor: string;

  containerBorderColor: string;
  containerBackgroundColor: string;

  loadingVariant: LoadingVariants;
}

const attachmentphoto_light: AttachmentPhotoLookAndFeel = {
  paddings: [4, 4, 12, 4],
  height: 131,
  gap: 10,
  closeSize: 12,
  closeTop: 12,
  closeRight: 12,
  closeColor: themeColors.PrimaryWhite,

  photoAreaHeight: 76,
  photoAreaColor: themeColors.SecondaryGray1,

  labelTypo: themeTypos.GRTSKREG_W400_S10_LH12,
  labelColor: themeColors.PrimaryBlack,

  containerBorderColor: themeColors.SecondaryGray3,
  containerBackgroundColor: themeColors.PrimaryWhite,

  loadingVariant: 'lightblue24',
}

export type AttachmentPhotoVariants = 'light';

export const attachmentphoto_variants: Map<AttachmentPhotoVariants, AttachmentPhotoLookAndFeel> = 
  new Map<AttachmentPhotoVariants, AttachmentPhotoLookAndFeel>([
    ['light', attachmentphoto_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: AttachmentPhotoLookAndFeel
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  height: ${(args) => args.lookAndFeel.height}px;
  position: relative;

  padding: ${(args) => args.lookAndFeel.paddings[0]}px
           ${(args) => args.lookAndFeel.paddings[1]}px
           ${(args) => args.lookAndFeel.paddings[2]}px
           ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.containerBorderColor};

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};

  & img {
    height: ${(args) => args.lookAndFeel.photoAreaHeight}px;
  }
`

export const PhotoArea = styled.div<{
  lookAndFeel: AttachmentPhotoLookAndFeel
}>`
  ${cssFlexRowCC};
  height: ${(args) => args.lookAndFeel.photoAreaHeight}px;

  background-color: ${(args) => args.lookAndFeel.photoAreaColor};

  & img {
    width: 100%;
    object-fit: cover;
  }
`

export const Label = styled.div<{
  lookAndFeel: AttachmentPhotoLookAndFeel;
}>`
  ${cssFlexRowCC};
  flex-grow: 2;

  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};
  color: ${(args) => args.lookAndFeel.labelColor};

  & > i {
    color: red;
  }
`

export const Close = styled.div<{
  lookAndFeel: AttachmentPhotoLookAndFeel;
}>`
  position: absolute;
  width: ${(args) => args.lookAndFeel.closeSize}px;
  height: ${(args) => args.lookAndFeel.closeSize}px;
  top: ${(args) => args.lookAndFeel.closeTop}px;
  right: ${(args) => args.lookAndFeel.closeRight}px;

  color: ${(args) => args.lookAndFeel.closeColor};
`