import React from 'react';



type Props = {
  className?: string;
}
export const FavoriteVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = {className} width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M4 21.053V22.1641H6.28571L11.4286 16.6085H12.5714L17.7143 22.1641H20V2.16406H18H6H4V18.8307'/>
    </svg>
  );
};
