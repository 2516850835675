import * as React          from 'react';

import { useTheme        } from 'styled-components';

import { useNavigate     } from 'react-router-dom';

import { ArrowBackVector } from 'src/components/vector/arrowback';

import { Checkbox        } from 'src/components/common/checkbox';
import { Button          } from 'src/components/common/button';
import { BlockUI         } from 'src/components/common/block-ui';

import { NavBar          } from 'src/components/features/common/nav-bar';

import { Note            } from 'src/components/features/qmmsg/select-company/note';

import { Screen,
         ScreenBody,
         CompanyList,
         layout_light,
         layout_dark     } from 'src/containers/qmmsg/select-company/layout';

import { Urls            } from 'src/providers/routing';

import { useAppSelector,  
         useAppDispatch,
         storeApi        } from 'src/store';

import { AsyncOpStatus   } from 'src/common';



export const SelectCompanyScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;
  const dispatch = useAppDispatch();

  const bootOpStarted = React.useRef<boolean>(false);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount);
  const companies = useAppSelector(storeApi.qmmsg.selectCompany.selectors.selectCompanies);
  const selectedCompanies = useAppSelector(storeApi.qmmsg.selectCompany.selectors.selectSelectedCompanies);
  const opStatus = useAppSelector(storeApi.qmmsg.selectCompany.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.qmmsg.selectCompany.selectors.selectOpStatusLabel);

  React.useEffect(
    () => {
      if (bootOpStarted.current)
      {
        return;
      }

      bootOpStarted.current = true;
      dispatch(storeApi.qmmsg.selectCompany.async.getCompaniesAsync())
        .then(() => {
        });
    },
    [dispatch]
  );

  return (
    <Screen layout = { layout }>
      <NavBar 
        variant = { layout.navbarVariant }
        label = 'Клиент'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => navigate(-1)
          }
        ]}
      />
      <ScreenBody layout = { layout }>
        <Note variant = { layout.noteVariant } fio = { `${account!.lastName} ${account?.firstName} ${account?.middleName}` } />
        <CompanyList layout = { layout }>
          {companies.map((item) => 
            <Checkbox
              variant = 'h24bluetext'
              value = { selectedCompanies.includes(item.eskCode) }
              label = { item.title }
              onChange = { (_) => dispatch(storeApi.qmmsg.selectCompany.actions.selectedCompaniesChanged(item.eskCode)) }
            />
          )}
        </CompanyList>
        <Button
          variant = { layout.buttonVariant }
          label = 'Продолжить'
          isDisabled = { selectedCompanies.length === 0 }
          onTap = { () => Urls.QMMsgListEsk.build('selectcompany', selectedCompanies.join('-')).navigate() } />
      </ScreenBody>
      <BlockUI
        variant = { layout.blockUiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel } 
      />
    </Screen>
  );
};
