import React                        from 'react';

import { BrowserMultiFormatReader } from '@zxing/browser';

import { Dialog                   } from 'src/components/common/dialog';
import { Link                     } from 'src/components/common/link';

import { Container,
         CancelContainer,
         ScannerVariants,
         scanner_variants         } from 'src/components/features/inspection/edit/scanner/ui';



type Props = {
  variant: ScannerVariants;
  onDecode: (code: string) => void;
  onCancel: () => void;
}
export const Scanner: React.FC<Props> = ({ variant, onDecode, onCancel }) => {
  const lookAndFeel = scanner_variants.get(variant)!;

  const scanner = React.useRef<BrowserMultiFormatReader>(new BrowserMultiFormatReader());

  React.useEffect(() => {
    scanner.current.decodeFromVideoDevice(undefined, 'scanner-video', (result, errors, controls) => {
      if (result)
      {
        controls.stop();
        BrowserMultiFormatReader.releaseAllStreams();
        let code = result.getText();
        while (code.startsWith('0'))
        {
          code = code.substring(1);
        }
        onDecode(code);
      }
    })
  });

  const onCancelHandler = () => {
    BrowserMultiFormatReader.releaseAllStreams();
    onCancel();
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { true }>
      <Container lookAndFeel = { lookAndFeel }>
        <video id = 'scanner-video'></video>
        <CancelContainer lookAndFeel={lookAndFeel}>
          <Link label = 'Отмена' variant = { lookAndFeel.linkVariant } isCentered onTap = { onCancelHandler } />
        </CancelContainer>
      </Container>
    </Dialog>
  );
}
