import * as React          from 'react';

import { Container,
         StatusVariants,
         status_variants } from 'src/components/features/main/inspection-list/status/ui';



type Props = {
  variant: StatusVariants;
  label: string;
  onTap?: () => void;
}
export const Status: React.FC<Props> = ({
  variant,
  label,
  onTap,
}) => {
  const lookAndFeel = status_variants.get(variant)!;

  return (
    <Container
      lookAndFeel = { lookAndFeel }
      isPointer = { onTap !== undefined }
      onClick = { (e) => { e.stopPropagation(); onTap !== undefined && onTap(); } }
    >
      { label }
    </Container>
  );
};
