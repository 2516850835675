import * as React                    from 'react';

import { Switch                    } from 'src/components/common/switch';

import { ItemList,
         Item,
         Label,
         Icon,
         Divider,
         BooleanFlagsListVariants,
         booleanflagslist_variants } from 'src/components/features/main/personal/boolean-flags-list/ui';



export type BooleanFlag = {
  id: string;
  icon?: React.ReactNode;
  label: string;
  value: boolean;
}

type Props = {
  variant: BooleanFlagsListVariants;
  items: BooleanFlag[];
  onToggle: (item: BooleanFlag) => void;
}
export const BooleanFlagsList: React.FC<Props> = ({
  variant,
  items,
  onToggle,
}) => {
  const lookAndFeel = booleanflagslist_variants.get(variant)!;

  return (
    <ItemList lookAndFeel = { lookAndFeel }>
      {items.map((item, index) => (
        <React.Fragment key = { item.id }>
          <Item
            lookAndFeel = { lookAndFeel }
          >
            {item.icon !== undefined && 
              <Icon lookAndFeel = { lookAndFeel }>{ item.icon }</Icon>
            }
            <Label lookAndFeel = { lookAndFeel }>
              { item.label }
            </Label>
            <Switch variant = { lookAndFeel.switchVariant } value = { item.value } onChange = { () => onToggle(item) } />
          </Item>
          {index !== items.length - 1 && (
            <Divider lookAndFeel = { lookAndFeel } />
          )}
        </React.Fragment>
      ))}
    </ItemList>
  );
};
