import styled           from 'styled-components';

import { themeColors,
         Typo,
         typoAsCSS,
         themeTypos,
         cssFlexRow,
         cssFlexColumn   } from 'src/providers/theme/shared';



type BottomSheetLookAndFeel = {
  backColor: string;
  backZIndex: number;

  containerBackgroundColor: string;
  containerPaddings: number[];

  itemHeight: number;
  itemGap: number;

  iconColor: string;
  labelColor: string;
  dangerColor: string;

  labelTypo: Typo;
}

const bottomsheet_light: BottomSheetLookAndFeel = {
  backColor: 'rgba(0, 47, 108, 0.5)',
  backZIndex: 1300,

  containerBackgroundColor: themeColors.PrimaryWhite,
  containerPaddings: [16, 12],

  itemHeight: 44,
  itemGap: 4,

  iconColor: themeColors.AccentBlue2,
  labelColor: themeColors.PrimaryBlack,
  dangerColor: themeColors.AccentRed1,

  labelTypo: themeTypos.GRTSKREG_W400_S14_LH17,
}

export type BottomSheetVariants = 'light';

export const bottomsheet_variants: Map<BottomSheetVariants, BottomSheetLookAndFeel> = 
  new Map<BottomSheetVariants, BottomSheetLookAndFeel>([
    ['light', bottomsheet_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: BottomSheetLookAndFeel,
}>`
  ${cssFlexColumn};
  width: 100%;
  padding: ${(args) => args.lookAndFeel.containerPaddings[0]}px ${(args) => args.lookAndFeel.containerPaddings[1]}px;

  background-color: ${(args) => args.lookAndFeel.containerBackgroundColor};
`

export const Item = styled.div<{
  lookAndFeel: BottomSheetLookAndFeel,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.lookAndFeel.itemGap}px;
  width: 100%;
  height: ${(args) => args.lookAndFeel.itemHeight}px;
  cursor: pointer;
`

export const Icon = styled.div<{
  lookAndFeel: BottomSheetLookAndFeel,
  isDanger: boolean;
}>`
  ${cssFlexRow};
  width: ${(args) => args.lookAndFeel.itemHeight}px;
  height: ${(args) => args.lookAndFeel.itemHeight}px;
  justify-content: center;
  align-items: center;

  color: ${(args) => args.isDanger ? args.lookAndFeel.dangerColor : args.lookAndFeel.iconColor};
`

export const Label = styled.a<{
  lookAndFeel: BottomSheetLookAndFeel;
  isDanger: boolean;
}>`
  ${cssFlexRow};
  align-items: center;
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)};

  color: ${(args) => args.isDanger ? args.lookAndFeel.dangerColor : args.lookAndFeel.labelColor};
`
