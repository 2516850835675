
import React from 'react';



type Props = {
  className?: string;
}
export const ServiceCertCheckVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M9.5 22H1V2.5H5H13H17V7' />
      <line stroke = 'currentColor' strokeWidth = '2' x1 = '4' y1 = '7' x2 = '12' y2 = '7' />
      <line stroke = 'currentColor' strokeWidth = '2' x1 = '4' y1 = '12' x2 = '8' y2 = '12' />
      <line stroke = 'currentColor' strokeWidth = '2' x1 = '4' y1 = '17' x2 = '7' y2 = '17' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M20 20L23 23' />
      <circle stroke = 'currentColor' strokeWidth = '2' cx = '15.5' cy = '15.5' r = '5.5' />
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M13 15L15 17L18 14' />
    </svg>
  );
};
