import styled                     from 'styled-components';

import { LabeledContentVariants } from 'src/components/common/labeled-content';
import { LinkVariants           } from 'src/components/common/link';

import { NavBarVariants         } from 'src/components/features/common/nav-bar';
import { BlockCaptionVariants   } from 'src/components/features/main/common/block-caption';

import { InfoLinkVariants       } from 'src/components/features/main/help/info-link';
import { ContentVariants        } from 'src/components/features/main/help/content';

import { themeColors, 
         cssFlexColumn          } from 'src/providers/theme/shared';



type HelpLayout = {
  backgroundColor: string;

  paddings: number[];

  labeledGap: number;

  navbarVariant: NavBarVariants;
  blockCaptionVariant: BlockCaptionVariants;
  infoLinkVariant: InfoLinkVariants;
  contentVariant: ContentVariants;
  labeledContentVariant: LabeledContentVariants;
  linkVariant: LinkVariants;
}

export const layout_light: HelpLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [0, 16, 24, 16],

  labeledGap: 16,

  navbarVariant: 'lightblue',
  blockCaptionVariant: 'light20t20b',
  infoLinkVariant: 'light',
  contentVariant: 'light',
  labeledContentVariant: 'gray5w400s16lh22gap4',
  linkVariant: 'blue16',
}

export const layout_dark: HelpLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: HelpLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: HelpLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`
