import React from 'react';



type Props = {
  className?: string;
}
export const CalendarVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M3 11H21V6V5H4H3V20V21H21V20V14' stroke = 'currentColor' strokeWidth = '2'/>
      <path d = 'M8 2V8M16 2V8' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
