import * as React                       from 'react';

import { Typography                   } from 'src/components/common/typography';
import { Button                       } from 'src/components/common/button';
import { LabeledContent               } from 'src/components/common/labeled-content';

import { Container,
         Block,
         Hr,
         ComplaintDefectItemVariants,
         complaintdefectitem_variants } from 'src/components/features/inspection/view/complaint-defect-item/ui';

import { ComplaintType                } from 'src/services/api/types/inspection';
import * as types                       from 'src/services/api/types';

import { storeApi,    
         useAppDispatch               } from 'src/store';



type Props = {
  variant: ComplaintDefectItemVariants;
  defect: types.inspection.Defect;
  isLast: boolean;
}
export const ComplaintDefectItem: React.FC<Props> = ({
  variant,
  defect,
  isLast,
}) => {
  const lookAndFeel = complaintdefectitem_variants.get(variant)!;
  const dispatch = useAppDispatch();

  const defectTitle = defect.defectTitle;
  
  const qmetIds = React.useMemo(() => {
    const qmetIds: string[] = [];

    (defect.pieces || []).forEach((piece) => {
      if (!piece.isPiece && piece.qmetId != null && piece.qmetId > 0)
      {
        qmetIds.push(`${piece.qmetId}`);
      }
    })

    return qmetIds.join(', ');
  }, [defect]);

  const pieceIds = React.useMemo(() => {
    const pieceIds: string[] = [];

    (defect.pieces || []).forEach((piece) => {
      if (piece.isPiece && piece.heat != null && piece.piece != null && piece.heat !== '' && piece.piece !== '')
      {
        pieceIds.push(`${piece.heat}/${piece.piece}`);
      }
    })

    return pieceIds.join(', ');
  }, [defect]);
  
  
  const groupAttIds = React.useMemo(() => {
    const groupAttIds: string[] = [];

    (defect.pieces || []).forEach((piece) => {
      if (piece.isPiece && ((piece.groupAtt != null && piece.groupAtt !== '') || (piece.qcNum != null && piece.qcNum !== ''))) 
      {
        if (groupAttIds.length === 0)
        {
          if (piece.heat != null && piece.heat !== '') 
          {
            groupAttIds.push(`${piece.heat}/`);
          }
          if (piece.groupAtt != null && piece.groupAtt !== '' && piece.qcNum != null && piece.qcNum !== '')
          {
            groupAttIds.push(`${piece.groupAtt}/${piece.qcNum}`);
          }
          else
          {
            if (piece.groupAtt != null && piece.groupAtt !== '')
            {
              groupAttIds.push(`${piece.groupAtt}`);
            }
            else
            {
              groupAttIds.push(`${piece.qcNum}`);
            }
          }
        }
      }
    })

    return groupAttIds.join(', ');
  }, [defect]);

  const complaintType = defect.complaint?.typeComplaint ?? null

  const onActionHandler = () => {
    if (defect.complaint !== null)
    {
      dispatch(storeApi.inspection.view.actions.changeComplaintSelected(defect.complaint));
    }
    else
    {
      // todo - показать алерт, что отсутствуют требования/рекламация
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Block lookAndFeel = { lookAndFeel }>
        {defectTitle !== null && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Дефект'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ defectTitle }</Typography>
          </LabeledContent>
        )}
        {qmetIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Идентификационный №'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ qmetIds }</Typography>
          </LabeledContent>
        )}
        {pieceIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Номер рулона/листа/трубы'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ pieceIds }</Typography>
          </LabeledContent>
        )}
        {groupAttIds !== '' && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Партия атт/сертификат'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ groupAttIds }</Typography>
          </LabeledContent>
        )}
        {complaintType !== null && complaintType !== ComplaintType.NONE && (
          <LabeledContent variant = { lookAndFeel.blockLabeledVariant } label = 'Рекламация'>
            <Typography variant = { lookAndFeel.blockItemTypography }>{ complaintType === ComplaintType.COMPLAINT ? 'Рекламация' : 'Замечание' }</Typography>
          </LabeledContent>
        )}
      </Block>
      {complaintType !== null && complaintType !== ComplaintType.NONE && (
        <Button
          variant = { lookAndFeel.actionButtonVariant }
          label = { 'Просмотреть требование' }
          onTap = { onActionHandler }
        />
      )}
      {!isLast && (
        <Hr lookAndFeel = { lookAndFeel } />
      )}
    </Container>
  );
};
