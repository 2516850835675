import * as React                         from 'react';

import { useAlert                       } from 'react-alert';

import { ThreeDotsSpinnerAnimatedVector } from 'src/components/vector/animate/three-dots-spinner';

import { Dialog                         } from 'src/components/common/dialog';
import { Button                         } from 'src/components/common/button';
import { Link                           } from 'src/components/common/link';
import { InputMultiline                 } from 'src/components/common/input/input-multiline';

import { Container, 
         Caption,
         Note,
         Error,
         Spinner,
         AddQuestionDialogVariants,
         addquestiondialog_variants     } from 'src/components/features/qmmsg/detail/add-question-dialog/ui';

import { useAppSelector, 
         useAppDispatch,
         storeApi                       } from 'src/store';



type Props = {
  variant: AddQuestionDialogVariants;
}
export const AddQuestionDialog: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = addquestiondialog_variants.get(variant)!;
  const dispatch = useAppDispatch();
  const alert = useAlert();
  
  const opened = useAppSelector(storeApi.qmmsg.detail.selectors.selectAddQDOpened);
  const message = useAppSelector(storeApi.qmmsg.detail.selectors.selectAddQDMessage);
  const isBusy = useAppSelector(storeApi.qmmsg.detail.selectors.selectAddQDBusy);
  const isError = useAppSelector(storeApi.qmmsg.detail.selectors.selectAddQDError);

  const onSendHandler = () => {
    dispatch(storeApi.qmmsg.detail.async.addQuestionAsync())
      .unwrap()
      .then(() => {
        alert.success('Сообщение отправлено');
      })
      .catch((rawError) => {
      });
  }

  return (
    <Dialog variant = { lookAndFeel.dialogVariant } isOpened = { opened }>
      <Container lookAndFeel = { lookAndFeel }>
        <Caption lookAndFeel = { lookAndFeel }>
          Введите ваш вопрос
        </Caption>
        <Note lookAndFeel = { lookAndFeel }>
          Наш специалист свяжется с вами<br /> по E-mail в течение 24 часов
        </Note>

        <InputMultiline
          variant = { lookAndFeel.inputVariant }
          value = { message }
          isError = { isError }
          onChange = { (value) => dispatch(storeApi.qmmsg.detail.actions.addQDMessageChanged(value)) }
          onFocus = { () => dispatch(storeApi.qmmsg.detail.actions.addQDErrorReset()) }
        />

        {isError &&
          <Error lookAndFeel = { lookAndFeel }>
            Сообщение не отправлено
          </Error>
        }

        {!isBusy &&
          <Button
            variant = { lookAndFeel.sendButtonVariant }
            isDisabled = { message.trim().length === 0 }
            label = { isError ? 'Повторить отправку' : 'Отправить' }
            onTap = { onSendHandler }
          />
        }

        {isBusy && 
          <Spinner lookAndFeel = { lookAndFeel }>
            <ThreeDotsSpinnerAnimatedVector />
          </Spinner>
        }

        <Link
          variant = { lookAndFeel.cancelLinkVarint }
          label = { isBusy ? 'Отправка сообщения' : 'Отмена' }
          isCentered
          isDisabled = { isBusy }
          onTap = { () => !isBusy && dispatch(storeApi.qmmsg.detail.actions.addQDClosed()) }
        />

      </Container>
    </Dialog>
  );
};
