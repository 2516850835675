import { isEmail as isEmailValidate } from 'class-validator';

import Constants                      from 'src/services/constants';
import Util                           from 'src/services/util';



type ValidatorResult = {
  ok: boolean;
  error?: string;
}

type Validator = (value: string, isOptional?: boolean) => ValidatorResult;

export const isInviteCode: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length === 8;

  return {
    ok,
    ...(ok ? {} : { error: 'Пригласительный код должен содержать 8 символов' }),
  }
}

export const isCompany: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length > 2;

  return {
    ok,
    ...(ok ? {} : { error: 'Наименование компании должно содержать не менее 3 символов' }),
  }
}

export const isEmail: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    isEmailValidate(value.trim());

  return {
    ok,
    ...(ok ? {} : { error: 'Некорректный адрес электронной почты' }),
  }
}

export const isInn: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    (value.trim().length > 9 && value.trim().length === Util.selectDigitsFromString(value).length);

  return {
    ok,
    ...(ok ? {} : { error: 'ИНН должно содержать не менее 10 цифр' }),
  }
}

export const isSurname: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length > 2;

  return {
    ok,
    ...(ok ? {} : { error: 'Фамилия должна содержать не менее 3 символов' }),
  }
}

export const isName: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length > 2;

  return {
    ok,
    ...(ok ? {} : { error: 'Имя должно содержать не менее 3 символов' }),
  }
}

export const isPatronymic: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length > 2;

  return {
    ok,
    ...(ok ? {} : { error: 'Отчество должно содержать не менее 3 символов' }),
  }
}

export const isPost: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    value.trim().length > 2;

  return {
    ok,
    ...(ok ? {} : { error: 'Должность должна содержать не менее 3 символов' }),
  }
}

export const isPhone: Validator = (value: string, isOptional = false) => {
  if (isOptional && value.trim().length === 0)
  {
    return { ok: true };
  }

  const number = Util.selectDigitsFromString(value.trim());
  const numberError = Util.getPhoneErrorForValidator(number);

  if (numberError !== '')
  {
    return { ok: false, error: numberError };
  }

  return { ok: true };
}

export const isPassword: Validator = (value: string, isOptional = false) => {
  const ok = 
    (isOptional && value.trim().length === 0)
    ||
    (
      value.trim().length >= Constants.PWD_MIN_LENGTH
      &&
      Util.stringContainsDigit(value.trim())
      &&
      Util.stringContainsUpperCaseChar(value.trim())
    )

  return {
    ok,
    ...(ok ? {} : { error: 'Пароль должен быть не менее 8 символов, содержать минимум одну цифру и букву в верхнем регистре' }),
  }
}