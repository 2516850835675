import styled,
       { keyframes      } from 'styled-components';

import { themeColors,
         cssFlexColumn,
         Typo,
         typoAsCSS,
         themeTypos     } from 'src/providers/theme/shared';



type BlockUILookAndFeel = {
  backColor: string;
  backZIndex: number;

  gap: number;

  spinnerColorPrimary: string;
  spinnerColorSecondary: string;
  spinnerSize: number;

  messageTypo: Typo;
  messageColor: string;

  containerBackgroundColor: string;
}

const blockui_light: BlockUILookAndFeel = {
  backColor: 'rgba(251, 251, 251, 0.75)',
  backZIndex: 1400,

  gap: 20,

  spinnerColorPrimary: themeColors.AccentBlue2,
  spinnerColorSecondary: themeColors.AccentRed1,
  spinnerSize: 48,

  messageTypo: themeTypos.GRTSKREG_W400_S15_LH22,
  messageColor: themeColors.PrimaryBlack,

  containerBackgroundColor: themeColors.PrimaryWhite,
}

export type BlockUIVariants = 'light';

export const blockui_variants: Map<BlockUIVariants, BlockUILookAndFeel> = 
  new Map<BlockUIVariants, BlockUILookAndFeel>([
    ['light', blockui_light],
  ]);

export const Root = styled.div<{
  lookAndFeel: BlockUILookAndFeel
}>`
  position: fixed;
  z-index: ${(args) => args.lookAndFeel.backZIndex};
  width: 100vw;
  height: 100dvh;

  top: 0;
  left: 0;

  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.gap}px;
  justify-content: center;
  align-items: center;

  background-color: ${(args) => args.lookAndFeel.backColor};
  

  opacity: 0;
  left: 9999px;

  &.opened {
    left: 0;
    opacity: 100;
    backdrop-filter: blur(10px);
  }

  transition: opacity 0.3s;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span<{
  lookAndFeel: BlockUILookAndFeel,
}>`
  display: inline-block;
  border-top: 4px solid ${(args) => args.lookAndFeel.spinnerColorPrimary};
  border-right: 4px solid transparent;
  animation: ${spin} 1s linear infinite;
  border-radius: 50%;
  width: ${(args) => args.lookAndFeel.spinnerSize}px;
  height: ${(args) => args.lookAndFeel.spinnerSize}px;
  color: ${(args) => args.lookAndFeel.spinnerColorPrimary};

  &:after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: ${(args) => args.lookAndFeel.spinnerSize}px;
    height: ${(args) => args.lookAndFeel.spinnerSize}px;
    border-radius: 50%;
    border-bottom: 4px solid ${(args) => args.lookAndFeel.spinnerColorSecondary};
    border-left: 4px solid transparent;
  }
`;

export const Message = styled.div<{
  lookAndFeel: BlockUILookAndFeel
}>`
  text-align: center;

  ${(args) => typoAsCSS(args.lookAndFeel.messageTypo)};
  color: ${(args) => args.lookAndFeel.messageColor};
`