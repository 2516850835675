import mainNotifications,
       { mainNotificationsActions,
         mainNotificationsSelectors,
         openSurveyAsync,
         getNotificationsAsync,
         readNotificationsAsync,
         deleteNotificationsAsync      } from 'src/store/main/notifications';

import mainInfo,
       { mainInfoActions,
         mainInfoSelectors,  
         getInfoAsync                  } from 'src/store/main/info';

import mainPersonal,
       { mainPersonalActions,
         mainPersonalSelectors,  
         copyStateFromAccountAsync,
         sendEmailAsync,
         sendSMSCodeAsync,
         checkSMSCodeAsync,
         savePersonalAsync,
         checkAsync                    } from 'src/store/main/personal';

import mainInspectionList,
       { mainInspectionListActions,
         mainInspectionListSelectors, 
         prepareInfoAsync,
         downloadRefersAsync,
         downloadAdvAsync,
         filterInspectionsAsync,
         reportInspectionsAsync,
         reportInspectionsAsLinkAsync,
         revocationAsync               } from 'src/store/main/inspection-list';

import mainProfile,
       { mainProfileActions,
         mainProfileSelectors, 
         updateRefersAsync             } from 'src/store/main/profile';

import mainAdmin,
       { mainAdminActions,
         mainAdminSelectors, 
         getAdminUserListAsync,
         blockAdminUserAsync           } from 'src/store/main/admin';

import mainToken,
       { mainTokenActions,
         mainTokenSelectors, 
         getTokenListAsync,
         emmitRefreshTokenAsync,
         revokeRefreshTokenAsync       } from 'src/store/main/token';

import mainMessages,
       { mainMessagesActions,
         mainMessagesSelectors, 
         getMessagesListAsync,
         addMessageAsync,
         deleteMessageAsync            } from 'src/store/main/messages';

import mainShadeSelection,
       { mainShadeSelectionActions,
         mainShadeSelectionSelectors,
         generateSelectionAsync,
         sendRatingMessageAsync        } from 'src/store/main/shade-selection';



export const main = {
  notifications: {
    actions: mainNotificationsActions,
    selectors: mainNotificationsSelectors,
    async: {
      getNotificationsAsync,
      readNotificationsAsync,
      deleteNotificationsAsync,
      openSurveyAsync,
    }
  },
  profile: {
    actions: mainProfileActions,
    selectors: mainProfileSelectors,
    async: {
      updateRefersAsync,
    }
  },
  admin: {
    actions: mainAdminActions,
    selectors: mainAdminSelectors,
    async: {
      getAdminUserListAsync,
      blockAdminUserAsync,
    }
  },
  token: {
    actions: mainTokenActions,
    selectors: mainTokenSelectors,
    async: {
      getTokenListAsync,
      emmitRefreshTokenAsync,
      revokeRefreshTokenAsync,
    }
  },
  info: {
    actions: mainInfoActions,
    selectors: mainInfoSelectors,
    async: {
      getInfoAsync,
    }
  },
  messages: {
    actions: mainMessagesActions,
    selectors: mainMessagesSelectors,
    async: {
      getMessagesListAsync,
      addMessageAsync,
      deleteMessageAsync,
    }
  },
  personal: {
    actions: mainPersonalActions,
    selectors: mainPersonalSelectors,
    async: {
      copyStateFromAccountAsync,
      sendEmailAsync,
      sendSMSCodeAsync,
      checkSMSCodeAsync,
      savePersonalAsync,
      checkAsync,
    },
  },
  inspectionList: {
    actions: mainInspectionListActions,
    selectors: mainInspectionListSelectors,
    async: {
      prepareInfoAsync,
      filterInspectionsAsync,
      reportInspectionsAsync,
      reportInspectionsAsLinkAsync,
      downloadRefersAsync,
      downloadAdvAsync,
      revocationAsync,
    }
  },
  shadeSelection: {
    actions: mainShadeSelectionActions,
    selectors: mainShadeSelectionSelectors,
    async: {
      generateSelectionAsync,
      sendRatingMessageAsync,
    }
  },
};


export const mainReducers = {
  mainNotifications,
  mainInfo,
  mainPersonal,
  mainInspectionList,
  mainProfile,
  mainAdmin,
  mainToken,
  mainMessages,
  mainShadeSelection,
}