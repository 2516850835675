import * as React        from 'react';

import { useTheme      } from 'styled-components';

import { AppSplash     } from 'src/components/features/auth/splash/app-splash';

import { Screen,
         layout_light,
         layout_dark   } from 'src/containers/auth/splash/layout';

import { Urls          } from 'src/providers/routing';

import Preferences       from 'src/services/preferences';



export const SplashScreen = () => {
  const theme = useTheme();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (bootOpStarted.current)
    {
      return;
    }

    bootOpStarted.current = true;
    setTimeout(() => {
      Preferences.hasEncryptedAccount ?
        Urls.PinEnter.build().navigateWithReplace()
        :
        Urls.Login.build().navigateWithReplace();
    }, 2000);
  });

  return (
    <Screen layout = { layout }>
      <AppSplash variant = { layout.splashVariant } />
    </Screen>
  );
};
