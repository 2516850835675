import * as React                from 'react';

import { useTheme              } from 'styled-components';

import { useAlert              } from 'react-alert';

import { useParams,
         useNavigate           } from 'react-router-dom';

import { ArrowBackVector       } from 'src/components/vector/arrowback';
import { FavoriteVector        } from 'src/components/vector/favorite';
import { HistoryVector         } from 'src/components/vector/history';

import { Typography            } from 'src/components/common/typography';
import { Button                } from 'src/components/common/button';
import { BlockUI               } from 'src/components/common/block-ui';
import { EmptyCaseDanger       } from 'src/components/common/empty-case';
import { TabItem,
         Tabs                  } from 'src/components/common/tabs';
import { VGap                  } from 'src/components/common/flex/vgap';

import { NavBar                } from 'src/components/features/common/nav-bar';

import { StatusInfo            } from 'src/components/features/qmmsg/detail/status-info';
import { StepFlow              } from 'src/components/features/qmmsg/detail/step-flow';
import { DetailInfo            } from 'src/components/features/qmmsg/detail/detail-info';
import { DetailNote            } from 'src/components/features/qmmsg/detail/detail-note';
import { AddQuestionDialog     } from 'src/components/features/qmmsg/detail/add-question-dialog';
import { BackForRevisionDialog } from 'src/components/features/qmmsg/detail/back-for-revision-dialog';
import { RateInline            } from 'src/components/features/qmmsg/detail/rate-inline';
import { RateDialog            } from 'src/components/features/qmmsg/detail/rate-dialog';
import { FeedbackThanksDialog  } from 'src/components/features/qmmsg/detail/feedback-thanks-dialog';

import { DetailLayout,
         Screen,
         ScreenBody,
         ContentPanel,
         TabsPanel,
         QuestionPanel,
         QuestionHistory,
         QuestionAdd,
         Actions,
         ComebackContainer,
         layout_light,
         layout_dark           } from 'src/containers/qmmsg/detail/layout';

import { Urls                  } from 'src/providers/routing';

import Api                       from 'src/services/api';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi              } from 'src/store';

import { ChangeFavoriteReject, 
         DoActionReject        } from 'src/store/qmmsg/detail';

import { AsyncOpStatus         } from 'src/common';



const tabs = [{ id: 'status', label: 'Статус' }, { id: 'detail', label: 'Детализация' }];

type DetailScreenElement = { layout: DetailLayout; dispatch: AppDispatch; }

const Navigation: React.FC<DetailScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const opStatus = useAppSelector(storeApi.qmmsg.detail.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.qmmsg.detail.selectors.selectOpStatusLabel);
  const claim = useAppSelector(storeApi.qmmsg.detail.selectors.selectClaim);
  const isFavorite = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsFavorite);

  const onChangeFavoriteHandler = () => {
    if (claim !== null)
    {
      dispatch(storeApi.qmmsg.detail.async.changeFavoriteAsync({ qmMsgId: claim.qmMsgId, isFavorite: !isFavorite }))
        .unwrap()
        .then(() => {
          alert.success(
            isFavorite ?
              `Вы успешно исключили ${claim?.signMsg === 'complaint' ? 'претензию' : 'уведомление'} из избранного`
              :
              `Вы успешно сохранили ${claim?.signMsg === 'complaint' ? 'претензию' : 'уведомление'} в избранном`
            );
        })
        .catch((rawError) => {
          const error = rawError as ChangeFavoriteReject;
          if (!Api.isCommonAuthError(error.statusCode ?? 0))
          {
            alert.error(error.message);
          }
        });
    }
  }

  const onBackNavigateHandler = () => {
    navigate(-1);
  }

  return (
    <React.Fragment>
      <NavBar 
        variant = { layout.navbarVariant }
        label = { `${claim?.signMsg === 'complaint' ? 'Претензия' : 'Уведомление'} №${claim?.qmMsgNumber ?? ''}` }
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: onBackNavigateHandler
          }
        ]}
        endButtons={[
          {
            id: 'favorite',
            vector: <FavoriteVector />,
            isSelected: isFavorite,
            action: onChangeFavoriteHandler
          },
        ]}
      />
      <BlockUI
        variant = { layout.blockUIVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel } 
      />
    </React.Fragment>
  )
}

const StatusPanel: React.FC<DetailScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();
  const { qmMsgId } = useParams();

  const claim = useAppSelector(storeApi.qmmsg.detail.selectors.selectClaim);
  const stepQM = useAppSelector(storeApi.qmmsg.detail.selectors.selectStepQM);
  const activeTab = useAppSelector(storeApi.qmmsg.detail.selectors.selectActiveTab);

  const isEmptyCaseVisible = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsEmptyCaseVisible);
  const isStepFlowVisible = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsStepFlowVisible);
  const isActionsVisible = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsActionsVisible);
  const isComebackVisible = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsComebackVisible);
  const isRateVisible = useAppSelector(storeApi.qmmsg.detail.selectors.selectIsRateVisible);

  const rate = useAppSelector(storeApi.qmmsg.detail.selectors.selectRate);

  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

  const getRef = React.useCallback((node: HTMLDivElement) => {
    if (node && window.location.hash === '#scrollBottom')
    {
      node.scrollTo({ top: 10000 });
      setContainer(node);
    }
  }, []);

  const onApproveHandler = () => {
    dispatch(storeApi.qmmsg.detail.async.doActionAsync('approved'))
      .unwrap()
      .then(() => {
        alert.success('Подтверждено');
        container?.scrollTo({ top: 10000 });
      })
      .catch((rawError) => {
        const error = rawError as DoActionReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  if (claim === null || activeTab === 'detail')
  {
    return null;
  }

  return (
    <ContentPanel layout = { layout } ref = { getRef }>
      <VGap size = { layout.statusInfoTopPadding } />
      <StatusInfo
        variant = { layout.statusInfoVariant }
        status = { claim.step.title }
        stepId = { claim.step.id }
        timestampExecution = { claim.timestampExecution }
        weight = { `${claim.numberMarriage.quantity} ${claim.numberMarriage.unit}` }
        type = { claim.type.title }
      />
      {isEmptyCaseVisible &&
        <EmptyCaseDanger
          variant = { layout.emptyCaseVariant }
          header = 'Статус по претензии не определен'
          message = 'Для уточнения информации обратитесь к менеджеру единого окна.'
        />
      }
      {isStepFlowVisible &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <StepFlow
            variant = { layout.stepFlowVariant }
            claim = { claim }
            steps = { stepQM }
          />
        </React.Fragment>
      }
      {isActionsVisible &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <Actions layout = { layout }>
            {!claim.isComeback && (
              <Button
                variant = { layout.actionsBackButtonVariant }
                label = 'Вернуть в работу'
                onTap = { () => dispatch(storeApi.qmmsg.detail.actions.backFROpened()) }
              />
            )}
            <Button
              variant = { layout.actionsApproveButtonVariant }
              label = 'Подтвердить'
              onTap = { onApproveHandler }
            />
          </Actions>
        </React.Fragment>
      }
      {isComebackVisible &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <ComebackContainer layout = { layout }>
            <Typography variant = { layout.comebackTypographyVariant }>
              {
                claim.signMsg === 'complaint' ?
                  'Вы вернули претензию в работу'
                  :
                  'Вы вернули уведомление в работу'
              }
            </Typography>
          </ComebackContainer>
        </React.Fragment>
      }
      {isRateVisible &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <RateInline
            variant = { layout.rateInlineVariant }
            rating = { rate - 1 }
            onChangeRating = { (value) => dispatch(storeApi.qmmsg.detail.actions.rateChanged(value + 1)) }
            onSend = { () => dispatch(storeApi.qmmsg.detail.actions.rateOpened()) }
          />
        </React.Fragment>
      }
      <QuestionPanel layout = { layout }>
        <QuestionHistory layout = { layout }>
          <Button
            variant = { layout.questionHistoryButtonVariant }
            onTap = { () => qmMsgId !== undefined && Urls.QMMsgQuestionHistory.build(parseInt(qmMsgId, 10)).navigate() }
            icon = { <HistoryVector /> }
          />
        </QuestionHistory>
        <QuestionAdd layout = { layout }>
          <Button
            variant = { layout.questionAddButtonVariant }
            onTap = { () => dispatch(storeApi.qmmsg.detail.actions.addQDOpened()) }
            label = 'Задать вопрос'
          />
        </QuestionAdd>
      </QuestionPanel>
    </ContentPanel>
  )
}

const DetailPanel: React.FC<DetailScreenElement> = ({ layout, dispatch }) => {
  const claim = useAppSelector(storeApi.qmmsg.detail.selectors.selectClaim);
  const activeTab = useAppSelector(storeApi.qmmsg.detail.selectors.selectActiveTab);

  if (claim === null || activeTab === 'status')
  {
    return null;
  }

  return (
    <ContentPanel layout = { layout }>
      <VGap size = { layout.statusInfoTopPadding } />
      <DetailInfo
        variant = { layout.detailInfoVariant }
        timestampReg = { claim.timestampReg }
        outgoingNum = { claim.outgoingNumber }
        fioExecutor = { claim.fioExecutor }
      />
      {claim.description !== null &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <DetailNote
            variant = { layout.detailNoteVariant }
            header = 'Описание'
            note = { claim.description }
          />
        </React.Fragment>
      }
      {claim.step.note !== null &&
        <React.Fragment>
          <VGap size = { layout.blocksGap } />
          <DetailNote
            variant = { layout.detailNoteVariant }
            header = 'Комментарий к шагу'
            note = { claim.step.note }
          />
        </React.Fragment>
      }
      
    </ContentPanel>
  )
}


export const DetailScreen = () => {
  const theme = useTheme();
  const { qmMsgId } = useParams();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  const bootOpStarted = React.useRef<boolean>(false);

  const activeTab = useAppSelector(storeApi.qmmsg.detail.selectors.selectActiveTab);

  React.useEffect(
    () => {
      if (bootOpStarted.current || qmMsgId === undefined)
      {
        return;
      }

      bootOpStarted.current = true;
      dispatch(storeApi.qmmsg.detail.async.prepareInfoAsync(parseInt(qmMsgId, 10)));
    },
    [dispatch, qmMsgId]
  );

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <TabsPanel  layout = { layout }>
          <Tabs
            variant = 'h30'
            items = { tabs }
            value = { tabs.find((item) => item.id === activeTab)! }
            onChange = { (value: TabItem) => dispatch(storeApi.qmmsg.detail.actions.activeTabChanged(value.id)) }
          />
        </TabsPanel>
        <StatusPanel layout = { layout } dispatch = { dispatch } />
        <DetailPanel layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
      <AddQuestionDialog variant = { layout.addQuestionDialogVariant } />
      <BackForRevisionDialog variant = { layout.backForRevisionDialogVariant } />
      <RateDialog variant = { layout.rateDialogVariant } />
      <FeedbackThanksDialog variant = { layout.feedbackThanksVariant } />
    </Screen>
  );
};
