import React from 'react';



type Props = {
  className?: string;
}
export const ToolbarAddVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M12 2V12M12 12L22 12M12 12H2M12 12C12 15.1481 12 21.5556 12 22'/>
    </svg>
  );
};
