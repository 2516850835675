import styled           from 'styled-components';

import { themeColors, 
         Typo,
         cssFlexRow,
         typoAsCSS,
         themeTypos   } from 'src/providers/theme/shared';



export type StaticValueLookAndFeel = {
  height: number;
  backgroundColor: string;
  padding: number[];
  typo: Typo
  color: string;
}

const staticvalue_light: StaticValueLookAndFeel = {
  height: 48,
  backgroundColor: themeColors.SecondaryGray1,
  padding: [0, 16],
  typo: themeTypos.GRTSKREG_W400_S15_LH20,
  color: themeColors.PrimaryBlack,
}

export type StaticValueVariants = 'light';

export const staticvalue_variants: Map<StaticValueVariants, StaticValueLookAndFeel> = 
  new Map<StaticValueVariants, StaticValueLookAndFeel>([
    ['light', staticvalue_light],
  ]);

export const Container = styled.span<{
  lookAndFeel: StaticValueLookAndFeel
}>`
  ${cssFlexRow};
  align-items: center;
  min-height: ${(args) => args.lookAndFeel.height}px;
  padding: ${(args) => args.lookAndFeel.padding[0]}px ${(args) => args.lookAndFeel.padding[1]}px;
  ${(args) => typoAsCSS(args.lookAndFeel.typo)};
  color: ${(args) => args.lookAndFeel.color};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`
