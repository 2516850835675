import * as React             from 'react';

import { Button             } from 'src/components/common/button';
import { Rating             } from 'src/components/common/rating';
import { LabeledContent     } from 'src/components/common/labeled-content';
import { InputMultiline     } from 'src/components/common/input/input-multiline';
import { Link               } from 'src/components/common/link';

import { Container, 
         Caption,
         Message,
         AppRatingVariants,
         apprating_variants } from 'src/components/features/main/inspection-list/app-rating/ui';



type Props = {
  variant: AppRatingVariants;
  onAccept: (rating: number, note: string) => void;
  onCancel: () => void;
}
export const AppRating: React.FC<Props> = ({
  variant,
  onAccept,
  onCancel,
}) => {
  const lookAndFeel = apprating_variants.get(variant)!;

  const [rating, setRating] = React.useState<number>(-1);
  const [note, setNote] = React.useState<string>('');

  const isDisabled = rating === -1 || (rating < 3 && note.trim().length === 0);

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Caption lookAndFeel = { lookAndFeel }>
        Оценка приложения
      </Caption>
      <Rating
        variant = { lookAndFeel.ratingVariant }
        emptyText = 'Укажите вашу оценку'
        value = { rating }
        onRating = { setRating } 
      />
      <Message lookAndFeel = { lookAndFeel }>
        Спасибо за оценку нашей работы. Ваше мнение очень важно для нас. 
      </Message>
      <LabeledContent variant = { lookAndFeel.labeledVariant } label = 'Комментарий'>
        <InputMultiline
          variant = { lookAndFeel.multilineVariant }
          value = { note }
          onChange = { setNote }
        />
      </LabeledContent>
      <Button
        variant = { lookAndFeel.sendButtonVariant }
        label = 'Отправить'
        isDisabled = { isDisabled }
        // rating + 1 - т.к. rating содержит значение от 0 до 4, а нам нужно от 1 до 5
        onTap = { () => onAccept(rating + 1, note.trim()) }
      />
      <Link
        variant = { lookAndFeel.continueLinkVariant }
        isCentered
        label = 'Продолжить работу'
        onTap = { onCancel }
      />
    </Container>
  );
};
