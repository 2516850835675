import * as React         from 'react';

import { useNavigate,
         Navigate       } from 'react-router-dom';

import { useAlert       } from 'react-alert';

import { BaseUrl        } from 'src/providers/routing/base-url';

import Api                from 'src/services/api';

import { storeApi,
         useAppSelector } from 'src/store';



type Props = {
  children: React.ReactNode;
  isProtectedRoute?: boolean;
}
export const WithInitNavigate: React.FC<Props> = ({
  children,
  isProtectedRoute = false
}) => {
  const navigate = useNavigate();
  const alert = useAlert();
  BaseUrl.navigate = navigate;
  Api.setupAlert(alert);

  const account = useAppSelector(storeApi.auth.common.selectors.selectAccount);

  if (isProtectedRoute && account === null)
  {
    return <Navigate to = '/' replace />
  }

  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  );
};
