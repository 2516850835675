import styled                 from 'styled-components';

import { TypographyVariants } from 'src/components/common/typography';
import { LinkVariants       } from 'src/components/common/link';

import { themeColors        } from 'src/providers/theme/shared';



type NoteLookAndFeel = {
  paddings: number[];
  borderColor: string;
  topograhyVariant: TypographyVariants;
  linkVariant: LinkVariants;
}

const note_light: NoteLookAndFeel = {
  paddings: [16],
  borderColor: themeColors.AccentBlue2,
  topograhyVariant: 'blackw400s16lh22',
  linkVariant: 'blue16',
}

export type NoteVariants = 'light';

export const note_variants: Map<NoteVariants, NoteLookAndFeel> = 
  new Map<NoteVariants, NoteLookAndFeel>([
    ['light', note_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: NoteLookAndFeel;
}>`
  width: 100%;
  padding: ${(args) => args.lookAndFeel.paddings[0]}px;
  text-align: center;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
`
