import styled                            from 'styled-components';

import { ButtonVariants                } from 'src/components/common/button';
import { BlockUIVariants               } from 'src/components/common/block-ui';
import { EmptyCaseVariants             } from 'src/components/common/empty-case';
import { TypographyVariants            } from 'src/components/common/typography';

import { NavBarVariants                } from 'src/components/features/common/nav-bar';

import { StatusInfoVariants            } from 'src/components/features/qmmsg/detail/status-info';
import { StepFlowVariants              } from 'src/components/features/qmmsg/detail/step-flow';
import { DetailInfoVariants            } from 'src/components/features/qmmsg/detail/detail-info';
import { DetailNoteVariants            } from 'src/components/features/qmmsg/detail/detail-note';
import { AddQuestionDialogVariants     } from 'src/components/features/qmmsg/detail/add-question-dialog';
import { BackForRevisionDialogVariants } from 'src/components/features/qmmsg/detail/back-for-revision-dialog';
import { RateInlineVariants            } from 'src/components/features/qmmsg/detail/rate-inline';
import { RateDialogVariants            } from 'src/components/features/qmmsg/detail/rate-dialog';
import { FeedbackThanksDialogVariants  } from 'src/components/features/qmmsg/detail/feedback-thanks-dialog';

import { themeColors, 
         cssFlexColumn,
         cssFlexRow                    } from 'src/providers/theme/shared';



export type DetailLayout = {
  backgroundColor: string;

  paddings: number[];
  statusInfoTopPadding: number;
  questionTopPadding: number;
  questionGap: number;

  blocksGap: number;
  actionsGap: number;

  comebackTypographyVariant: TypographyVariants;

  navbarVariant: NavBarVariants;
  blockUIVariant: BlockUIVariants;
  statusInfoVariant: StatusInfoVariants;
  stepFlowVariant: StepFlowVariants;
  detailInfoVariant: DetailInfoVariants;
  detailNoteVariant: DetailNoteVariants;
  questionAddButtonVariant: ButtonVariants;
  questionHistoryButtonVariant: ButtonVariants;
  addQuestionDialogVariant: AddQuestionDialogVariants;
  backForRevisionDialogVariant: BackForRevisionDialogVariants;
  actionsBackButtonVariant: ButtonVariants;
  actionsApproveButtonVariant: ButtonVariants;
  emptyCaseVariant: EmptyCaseVariants;
  rateInlineVariant: RateInlineVariants;
  rateDialogVariant: RateDialogVariants;
  feedbackThanksVariant: FeedbackThanksDialogVariants;
}

export const layout_light: DetailLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [13, 16, 16, 16],
  statusInfoTopPadding: 20,
  questionTopPadding: 20,
  questionGap: 10,

  blocksGap: 12,
  actionsGap: 10,

  comebackTypographyVariant: 'blue2w400s15lh28',

  navbarVariant: 'lightblue',
  blockUIVariant: 'light',
  statusInfoVariant: 'light',
  stepFlowVariant: 'light',
  detailInfoVariant: 'light',
  detailNoteVariant: 'light',
  questionAddButtonVariant: 'h48bluefill',
  questionHistoryButtonVariant: 'h48blueoutline',
  addQuestionDialogVariant: 'light',
  backForRevisionDialogVariant: 'light',
  actionsBackButtonVariant: 'h36blueoutline',
  actionsApproveButtonVariant: 'h36bluefill',
  emptyCaseVariant: 'light',
  rateInlineVariant: 'light',
  rateDialogVariant: 'light',
  feedbackThanksVariant: 'light',
}

export const layout_dark: DetailLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: DetailLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: DetailLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  overflow: auto;
`

export const TabsPanel = styled.div<{
  layout: DetailLayout,
}>`
  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;
`

export const ContentPanel = styled.div<{
  layout: DetailLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;

  overflow-y: auto;
`

export const QuestionPanel = styled.div<{
  layout: DetailLayout,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.layout.questionGap}px;

  padding-top: ${(args) => args.layout.questionTopPadding}px;
`

export const QuestionHistory = styled.div<{
  layout: DetailLayout,
}>`

`

export const QuestionAdd = styled.div<{
  layout: DetailLayout,
}>`
  flex-grow: 2;
`

export const StatusInfoContainer = styled.div<{
  layout: DetailLayout,
}>`
  padding-top: ${(args) => args.layout.statusInfoTopPadding}px;
`

export const Actions = styled.div<{
  layout: DetailLayout,
}>`
  ${cssFlexRow};
  gap: ${(args) => args.layout.actionsGap}px;
`

export const ComebackContainer = styled.div<{
  layout: DetailLayout,
}>`
  text-align: center;
`