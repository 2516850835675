import * as React             from 'react';

import { BlockCaption       } from 'src/components/features/inspection/common/block-caption';

import { PieceItem          } from 'src/components/features/inspection/view/piece-item';

import { Container, 
         List,
         PieceListVariants,
         piecelist_variants } from 'src/components/features/inspection/view/piece-list/ui';

import * as types             from 'src/services/api/types';



type Props = {
  variant: PieceListVariants;
  pieces: types.inspection.Piece[];
}
export const PieceList: React.FC<Props> = ({
  variant,
  pieces,
}) => {
  const lookAndFeel = piecelist_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <BlockCaption
        variant = { lookAndFeel.blockCaptionVariant }
        label = 'Информация о продукции'
      />
      <List lookAndFeel = { lookAndFeel }>
        {pieces.map((piece) => (
          <PieceItem
            variant = { lookAndFeel.positionItemVariant }
            piece = { piece }
            key = { piece.guid }
          />
        ))}
      </List>
    </Container>
  );
};
