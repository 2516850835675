import * as React              from 'react';

import { LabeledContent      } from 'src/components/common/labeled-content';
import { Typography          } from 'src/components/common/typography';

import { Status,
         StatusVariants      } from 'src/components/features/qmmsg/common/status';

import { Container,
         TwoColumnRow,
         StatusInfoVariants,
         statusinfo_variants } from 'src/components/features/qmmsg/detail/status-info/ui';

import { Nullable            } from 'src/common';



type Props = {
  variant: StatusInfoVariants;
  status: string;
  timestampExecution: Nullable<number>;
  weight: string;
  type: string;
  stepId: number;
}
export const StatusInfo: React.FC<Props> = ({
  variant,
  status,
  timestampExecution,
  weight,
  type,
  stepId,
}) => {
  const lookAndFeel = statusinfo_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Status variant = { getStatus(stepId) } label = { status } />
      <TwoColumnRow lookAndFeel = { lookAndFeel }>
        <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Дата'>
          <Typography variant = 'blackw400s14lh17'>
            { timestampExecution === null ? 'не присвоено' : new Date(timestampExecution).toLocaleDateString() }
          </Typography>
        </LabeledContent>
        <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Кол-во брака'>
          <Typography variant = 'blackw400s14lh17'>
            { weight }
          </Typography>
        </LabeledContent>
      </TwoColumnRow>
      <LabeledContent variant = { lookAndFeel.labeledContentVariant } label = 'Тип обращения'>
        <Typography variant = 'blackw400s14lh17'>
          { type }
        </Typography>
      </LabeledContent>
    </Container>
  );
};

const getStatus = (stepId: number): StatusVariants => {
  if (stepId === 0)
  {
    return 'redoutline';
  }
  else if (stepId === 1 || stepId === 3)
  {
    return 'grayfill';
  }
  else
  {
    return 'blueoutline';
  }
}
