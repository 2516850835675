import styled                       from 'styled-components';

import { SelectModeDialogVariants } from 'src/components/features/inspection/edit/select-mode-dialog';

import { cssFlexRow,
         cssFlexColumn, 
         themeColors,
         Typo,
         themeTypos,
         typoAsCSS                } from 'src/providers/theme/shared';



type ToolbarLookAndFeel = {
  height: number;
  iconlabelgap: number;

  labelTypo: Typo;

  borderColor: string;
  inactiveColor: string;
  activeColor: string;
  addColor: string;
  backgroundColor: string;

  selectModeDialogVariant: SelectModeDialogVariants;
}

const toolbar_light: ToolbarLookAndFeel = {
  height: 81,
  iconlabelgap: 4,

  labelTypo: themeTypos.GRTSKREG_W400_S09_LH20,

  borderColor: themeColors.CardBorderColor,
  inactiveColor: themeColors.PrimarySteel,
  activeColor: themeColors.AccentBlue2,
  addColor: themeColors.AccentRed1,
  backgroundColor: themeColors.Gray1,

  selectModeDialogVariant: 'light',
}

export type ToolbarVariants = 'light';

export const toolbar_variants: Map<ToolbarVariants, ToolbarLookAndFeel> = 
  new Map<ToolbarVariants, ToolbarLookAndFeel>([
    ['light', toolbar_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: ToolbarLookAndFeel
}>`
  width: 100%;
  height: ${(args) => args.lookAndFeel.height}px;
  flex-shrink: 0;

  ${cssFlexRow};
  align-items: center;

  border-top: 1px solid ${(args) => args.lookAndFeel.borderColor};

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const Item = styled.button<{
  lookAndFeel: ToolbarLookAndFeel,
  isActive: boolean,
  isAdd?: boolean,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.iconlabelgap}px;
  flex-grow: 1;
  flex-basis: 20%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;

  color: ${(args) => args.isActive ? args.lookAndFeel.activeColor : args.lookAndFeel.inactiveColor};

  cursor: pointer;

  & svg path {
    color: ${(args) => args.isAdd ? args.lookAndFeel.addColor : (args.isActive ? args.lookAndFeel.activeColor : args.lookAndFeel.inactiveColor)};
  }
`;

export const ItemAdd = styled.button<{
  lookAndFeel: ToolbarLookAndFeel,
  isActive: boolean,
  isAdd?: boolean,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.lookAndFeel.iconlabelgap}px;
  flex-grow: 1;
  flex-basis: 20%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;

  color: ${(args) => args.isActive ? args.lookAndFeel.activeColor : args.lookAndFeel.inactiveColor};

  & svg {
    color: ${(args) => args.lookAndFeel.addColor};
  }
`;

export const Label = styled.span<{
  lookAndFeel: ToolbarLookAndFeel,
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.labelTypo)}
`