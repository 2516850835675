import React from 'react';



type Props = {
  className?: string;
}
export const ArrowBackVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path d = 'M12 2L2.00001 10L2 14L12 22' stroke = 'currentColor' strokeWidth = '2'/>
      <path d = 'M6 12L23 12' stroke = 'currentColor' strokeWidth = '2'/>
    </svg>
  );
};
