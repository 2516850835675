import * as React             from 'react';

import { Link               } from 'src/components/common/link';
import { Container,
         Counter,
         SendAgainVariants,
         sendagain_variants } from 'src/components/common/send-again/ui';

import Constants              from 'src/services/constants';



type Props = {
  variant: SendAgainVariants;
  onSendAgain: () => void;
}
export const SendAgain: React.FC<Props> = ({
  variant,
  onSendAgain,
}) => {
  const lookAndFeel = sendagain_variants.get(variant)!;

  const [counter, setCounter] = React.useState<number>(Constants.SMS_CODE_FREQUENCY - 1);
  const counterTimer = React.useRef<NodeJS.Timer | null>(null);

  const onTapHandler = () => {
    if (counter === 0)
    {
      setCounter(Constants.SMS_CODE_FREQUENCY);
      onSendAgain();
    }
  }

  React.useEffect(() => {
    if (counter > 0)
    {
      counterTimer.current = setTimeout(() => {
        if (counter > 0)
        {
          setCounter((oldCounter) => oldCounter - 1);
        }
        else
        {
          clearTimeout(counterTimer.current!);
          counterTimer.current = null;
        }
      }, 1000);
    }

    return () => {
      if (counterTimer.current !== null)
      {
        clearTimeout(counterTimer.current);
        counterTimer.current = null;
      }
    }
  }, [counter, onSendAgain]);

  return (
    <Container lookAndFeel = { lookAndFeel }>
      <Link variant = { lookAndFeel.linkVariant } label = 'Выслать новый PIN-код' isCentered isDisabled = { counter > 0 } onTap = { onTapHandler } />
      <Counter lookAndFeel = { lookAndFeel } isDisabled = { counter === 0 }>{ `${counter}`.padStart(2, '0') }</Counter>
    </Container>
  );
};
