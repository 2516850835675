import styled             from 'styled-components';

import { ButtonVariants } from 'src/components/common/button';
import { RatingVariants } from 'src/components/common/rating';

import { cssFlexColumn,
         Typo,
         themeTypos,
         typoAsCSS,
         themeColors    } from 'src/providers/theme/shared';



type RateInlineLookAndFeel = {
  paddings: number[];
  borderColor: string;
  backgroundColor: string;

  captionTypo: Typo;
  captionColor: string;

  noteTypo: Typo;
  noteColor: string;

  sendButtonTopPadding: number;

  ratingVariant: RatingVariants;
  sendButtonVariant: ButtonVariants;
}

const rateinline_light: RateInlineLookAndFeel = {
  paddings: [12, 16, 16, 16],
  borderColor: themeColors.CardBorderColor,
  backgroundColor: themeColors.PrimaryWhite,

  captionTypo: themeTypos.GRTSKREG_W600_S15_LH20,
  captionColor: themeColors.PrimaryBlack,

  noteTypo: themeTypos.GRTSKREG_W400_S12_LH18,
  noteColor: themeColors.PrimaryBlack,

  sendButtonTopPadding: 16,

  ratingVariant: 'h46s24',
  sendButtonVariant: 'h36blueoutline',
}

export type RateInlineVariants = 'light';

export const rateinline_variants: Map<RateInlineVariants, RateInlineLookAndFeel> = 
  new Map<RateInlineVariants, RateInlineLookAndFeel>([
    ['light', rateinline_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: RateInlineLookAndFeel
}>`
  ${cssFlexColumn};

  padding-top: ${(args) => args.lookAndFeel.paddings[0]}px;
  padding-right: ${(args) => args.lookAndFeel.paddings[1]}px;
  padding-bottom: ${(args) => args.lookAndFeel.paddings[2]}px;
  padding-left: ${(args) => args.lookAndFeel.paddings[3]}px;

  border: 1px solid ${(args) => args.lookAndFeel.borderColor};
  background-color: ${(args) => args.lookAndFeel.backgroundColor};
`

export const Caption = styled.div<{
  lookAndFeel: RateInlineLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.captionTypo)};
  color: ${(args) => args.lookAndFeel.captionColor};
`

export const Note = styled.div<{
  lookAndFeel: RateInlineLookAndFeel
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.noteTypo)};
  color: ${(args) => args.lookAndFeel.noteColor};
`
