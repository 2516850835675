import styled                      from 'styled-components';

import { CheckboxVariants        } from 'src/components/common/checkbox';
import { IconButtonVariants      } from 'src/components/common/icon-button';
import { TwoActionDialogVariants } from 'src/components/common/two-action-dialog';

import { cssFlexColumn,
         Typo,
         themeTypos,
         cssFlexRowCC,
         typoAsCSS,
         themeColors,        
         cssFlexRow              } from 'src/providers/theme/shared';



type PieceItemLookAndFeel = {
  height: number;
  deleteWidth: number;
  keyvaluePaddings: number[];
  arrowWidth: number;

  containerGap: number;

  backgroundColor: string;
  deleteSelectedBackgroundColor: string;
  itemSelectedBackgroundColor: string;

  keyTypo: Typo;
  keyColor: string;

  valueTypo: Typo;
  valueColor: string;
  valueErrorColor: string;

  hrColor: string;

  deleteConfirmVariant: TwoActionDialogVariants;
  checkboxVariant: CheckboxVariants;
  deleteButtonVariant: IconButtonVariants;
}

const pieceitem_light: PieceItemLookAndFeel = {
  height: 68,
  deleteWidth: 36,
  keyvaluePaddings: [12, 8],
  arrowWidth: 16,

  containerGap: 4,

  backgroundColor: 'transparent',
  deleteSelectedBackgroundColor: themeColors.Red2NA,
  itemSelectedBackgroundColor: themeColors.Blue3NA,

  keyTypo: themeTypos.GRTSKREG_W400_S14_LH17,
  keyColor: themeColors.SecondaryGray5,

  valueTypo: themeTypos.GRTSKREG_W400_S16_LH20,
  valueColor: themeColors.PrimaryBlack,
  valueErrorColor: themeColors.AccentRed1,

  hrColor: themeColors.SecondaryGray3,

  deleteConfirmVariant: 'light',
  checkboxVariant: 'h24bluetext',
  deleteButtonVariant: 's36redblue',
}

export type PieceItemVariants = 'light';

export const pieceitem_variants: Map<PieceItemVariants, PieceItemLookAndFeel> = 
  new Map<PieceItemVariants, PieceItemLookAndFeel>([
    ['light', pieceitem_light],
  ]);

export const Container = styled.div<{
  lookAndFeel: PieceItemLookAndFeel
}>`
  ${cssFlexRow};
  width: 100%;
  height: ${(args) => args.lookAndFeel.height}px;
  gap: ${(args) => args.lookAndFeel.containerGap}px;

  background-color: ${(args) => args.lookAndFeel.backgroundColor};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${(args) => args.lookAndFeel.hrColor};
  }
`

export const DeleteContainer = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
  isSelected: boolean;
}>`
  ${cssFlexRowCC};
  width: ${(args) => args.lookAndFeel.deleteWidth}px;

  background-color: ${(args) => args.isSelected ? args.lookAndFeel.deleteSelectedBackgroundColor : args.lookAndFeel.backgroundColor};

  & > div {
    width: auto;
  }
`

export const ItemContainer = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
  isSelected: boolean;
}>`
  ${cssFlexRow};
  flex-grow: 2;
  justify-content: space-between;

  padding: ${(args) => args.lookAndFeel.keyvaluePaddings[0]}px ${(args) => args.lookAndFeel.keyvaluePaddings[1]}px;

  background-color: ${(args) => args.isSelected ? args.lookAndFeel.itemSelectedBackgroundColor : args.lookAndFeel.backgroundColor};
`

export const ItemKeyValue = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  justify-content: space-between;
`

export const ItemKey = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.keyTypo)};
  color: ${(args) => args.lookAndFeel.keyColor};
`

export const ItemValue = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
  isError: boolean;
}>`
  ${(args) => typoAsCSS(args.lookAndFeel.valueTypo)};
  color: ${(args) => args.isError ? args.lookAndFeel.valueErrorColor : args.lookAndFeel.valueColor};
`

export const ItemArrow = styled.div<{
  lookAndFeel: PieceItemLookAndFeel;
}>`
  ${cssFlexRowCC};
  width: ${(args) => args.lookAndFeel.arrowWidth}px;
`