import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';

import { Nullable           } from 'src/common';



// #region Common

/** Типы проверки второго фактора */
export type TypeTwoFactor = 'login' | 'reg' | 'reset' | 'changePhone';

/** Справочники в дополнительных полях */
export type FieldReferDescriptor = {
  /** ИД справочника */
  id: number;
  /** Название справочника */
  title: string;
  /** true - поиск в справочнике */
  isFind: boolean;
  /** true - значение после выбора может корректироваться рукам */
  isCanEditHand: boolean;
}

/** Дополнительные поля для клиента */
export type FieldDescriptor = {
  /** ИД поля */
  id: number;
  /** Код поля */
  code: string;
  /** Заголовок поля */
  title: string;
  /** Подсказка поля, м.б. null */
  hint: Nullable<string>;
  /** true - необязательное */
  isOptional: boolean;
  /** Размерность поля */
  size: number;
  /** Справочник */
  refer: Nullable<FieldReferDescriptor>;
}

/** Фильтры */
export type FilterDescriptor = {
  /** Код фильтра */
  code: string;
  /** Название фильтра */
  title: string;
  /** ИД пользовательского поля из массива fields на которое распространен фильтр, м.б. null */
  fieldId: Nullable<number>;
  /** Код пользовательского поля из массива fields на которое распространен фильтр, м.б. null. */
  fieldCode: Nullable<string>;
}

/** Пуши */
export type PushDescriptor = {
  /** Код пуша */
  code: string;
  /** Название пуша */
  title: string;
  /** true - подписан */
  isSubscribe: boolean;
}

/** Конфиг */
export type ConfigDescriptor = {
  /** Код настройки конфигурации */
  code: string;
  /** Значение настройки конфигурации */
  value: string;
}

/** Роли пользователя */
export enum AccountRole {
  Client = 'client',
  ClientAdmin = 'clientAdmin',
  Contractor = 'contractor',
  Stpk = 'stpk',
}

/** Аккаунт без токенов */
export type Account = {
  /** ИД пользователя */
  id: number;
  /** Наименование предприятия */
  enterpriseName: string;
  /** Номер телефона */
  phone: number;
  /** Email */
  email: string;
  /** Массив email ответственных */
  emailRepresentative: Array<{ email: string }>;
  /** Email подтвержден */
  isEmailConfirmed: boolean;
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество, м.б. null */
  middleName: Nullable<string>;
  /** Должность */
  post: string;
  /**  Роль: client - клиент, clientAdmin - клиент-админ, contractor - подрядчик, stpk - СТПК. */
  role: AccountRole;
  /** Использовать сбор статистики firebase */
  isFirebaseStatistic: boolean;
  /** Требуется ввод позиций по катанке */
  isWireRod: boolean;
  /** Показывать или нет раздел с токенами для Rest API */
  isRestApi: boolean;
  /** Дополнительные поля для клиента */
  fields: FieldDescriptor[];
  /** Фильтры */
  filters: FilterDescriptor[];
  /** Пуши */
  pushes: PushDescriptor[];
  /** Настройки конфигурации */
  configs: ConfigDescriptor[];
}

export type AccountWithTokens = Account & {
  /** 
   * Время действия смещено на 48 часов что бы избежать блокировки токена во время рабочей смены пользователя, 
   * если текущее время превышает время указанное в параметре, использовать токен не рекомендуется
   */
  expRefresh: number;
  /** refresh-токен */
  jwtRefresh: string;
  /** access-токен (access_token) */
  jwtAccess: string;
}


// #endregion


// #region POST auth/request_invite

export type PostAuthRequestInviteIn = {
  /** ФИО клиента */
  client: string;
  /** Компания */
  company: string;
  /** ИНН */
  inn: string;
  /** Номер телефона */
  phone: Nullable<string>;
  /** Email */
  email: string;
}

export const preparePostAuthRequestInvite
  : PrepareCallHandler<PostAuthRequestInviteIn, void> = (arg: PostAuthRequestInviteIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/request_invite`,
      method: 'POST',
      data: arg,

      pwaLocked: 'none',
      pwaRetry: false,
    }
})

// #endregion


// #region POST auth/key_reg

export type PostAuthKeyRegIn = {
  /** Номер телефона */
  phone: number;
  /** Пригласительный код */
  inviteCode: string;
}

export type PostAuthKeyRegOut = {
  /** Ключ авторизации */
  authKey: string;
  /** Название предприятия */
  enterpriseName: string;
}

export const preparePostAuthKeyReg
  : PrepareCallHandler<PostAuthKeyRegIn, PostAuthKeyRegOut> = (arg: PostAuthKeyRegIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/key_reg`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/registration

export type PostAuthRegistrationIn = {
  /** Ключ авторизации, полученный в эндпоинте auth/2fa_control */
  authKey: string;
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество м.б. null */
  middleName: Nullable<string>;
  /** Должность */
  post: string;
  /** Email */
  email: string;
  /** Пароль */
  password: string;
  /** Пуш-токен */
  pushToken: string;
}

export const preparePostAuthRegistration
  : PrepareCallHandler<PostAuthRegistrationIn, AccountWithTokens> = (arg: PostAuthRegistrationIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/registration`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/2fa

export type PostAuth2faIn = {
  /** М.б. значения: login, reg, reset, changePhone */
  type: TypeTwoFactor;
  /** 
   * Ключ авторизации, полученный в эндпоинте auth/key_login или auth/key_reg 
   * или auth/key_reset_password или profile/key_change_phone в зависимости от type
   */
  authKey: string;
}

export const preparePostAuth2fa
  : PrepareCallHandler<PostAuth2faIn, void> = (arg: PostAuth2faIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/2fa`,
      method: 'POST',
      data: arg,

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/2fa_control

export type PostAuth2faControlIn = {
  /** М.б. значения: login, reg, reset, changePhone */
  type: TypeTwoFactor;
  /** Ключ авторизации */
  authKey: string;
  /** Код из СМС */
  code2fa: number;
}

export type PostAuth2faControlOut = {
  /** Ключ авторизации */
  authKey: string;
}

export const preparePostAuth2faControl
  : PrepareCallHandler<PostAuth2faControlIn, PostAuth2faControlOut> = (arg: PostAuth2faControlIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/2fa_control`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/key_login

export type PostAuthKeyLoginIn = {
  /** Номер телефона */
  phone: number;
  /** Пароль */
  password: string;
}

export type PostAuthKeyLoginOut = {
  /** Ключ авторизации */
  authKey: string;
}

export const preparePostAuthKeyLogin
  : PrepareCallHandler<PostAuthKeyLoginIn, PostAuthKeyLoginOut> = (arg: PostAuthKeyLoginIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/key_login`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/login

export type PostAuthLoginIn = {
  /** Ключ авторизации из POST auth/key_login */
  authKey: string;
  /** Код из СМС */
  code2fa: number;
  /** Пуш-токен */
  pushToken: string;
}

export const preparePostAuthLogin
  : PrepareCallHandler<PostAuthLoginIn, AccountWithTokens> = (arg: PostAuthLoginIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/login`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region GET auth/refresh

export type GetAuthRefreshIn = {
  /** Refresh-токен текущий */
  jwtRefresh: string;
}

export type GetAuthRefreshOut = {
  /** Аутентификационный токен новый */
  jwtAccess: string;
}

export const prepareGetAuthRefresh
  : PrepareCallHandler<GetAuthRefreshIn, GetAuthRefreshOut> = (arg: GetAuthRefreshIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/refresh`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'refresh',
      pwaRetry: false,
    },
})

// #endregion


// #region GET auth/check

export type GetAuthCheckIn = void;

export const prepareGetAuthCheck
  : PrepareCallHandler<GetAuthCheckIn, Account> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/check`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
      // transformResponse: (response) => { console.log(response); return { ...JSON.parse(response), isRestApi: true}}
    },
})

// #endregion


// #region POST auth/key_reset_password

export type PostAuthKeyResetPasswordIn = {
  /** Номер телефона */
  phone: number;
  /** Пароль */
  password: string;
}

export type PostAuthKeyResetPasswordOut = {
  /** Ключ авторизации */
  authKey: string;
}

export const preparePostAuthKeyResetPassword
  : PrepareCallHandler<PostAuthKeyResetPasswordIn, PostAuthKeyResetPasswordOut> = (arg: PostAuthKeyResetPasswordIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/key_reset_password`,
      method: 'POST',
      data: arg,
      responseType: 'json',

      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion


// #region POST auth/change_password

export type PostAuthChangePasswordIn = {
  /** Ключ авторизации из auth/key_reset_password */
  authKey: string;
  /** Код из СМС */
  code2fa: number;
}

export const preparePostAuthChangePassword
  : PrepareCallHandler<PostAuthChangePasswordIn, void> = (arg: PostAuthChangePasswordIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/auth/change_password`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'none',
      pwaRetry: false,
    },
})

// #endregion
