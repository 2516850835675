import React from 'react';



type Props = {
  className?: string;
}
export const LoupeVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '20' height = '20' viewBox = '0 0 20 20' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path fill = 'currentColor' fillRule = 'evenodd' clipRule = 'evenodd' d = 'M3.25 8.875C3.25 5.7684 5.7684 3.25 8.875 3.25C11.9816 3.25 14.5 5.7684 14.5 8.875C14.5 10.4039 13.8911 11.789 12.9008 12.8035L12.8768 12.8281L12.3221 13.3828L12.8525 13.9131L16.9697 18.0303L17.5 18.5607L18.5607 17.5L18.0303 16.9697L14.4156 13.355C15.406 12.1314 16 10.572 16 8.875C16 4.93997 12.81 1.75 8.875 1.75C4.93997 1.75 1.75 4.93997 1.75 8.875C1.75 12.81 4.93997 16 8.875 16H8.8762C9.40961 16 10.2569 16 11.4193 15.7306L11.7948 15.6436L10.5759 14.3719C9.8396 14.4999 9.28593 14.5 8.875 14.5C5.7684 14.5 3.25 11.9816 3.25 8.875Z' />
    </svg>
  );
};
