import React from 'react';



type Props = {
  className?: string;
}
export const ListVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '32' height = '32' viewBox = '0 0 32 32' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M10.4 16H30M10.4 5H30M10.4 27H30M3 16H6.2M3 5H6.2M3 27H6.2' />
    </svg>
  );
};
