import React                         from 'react'

import { transitions,
         positions,
         Provider as AlertProvider } from 'react-alert'

import { Toast                     } from 'src/components/common/toast';



type Props = {
  children: React.ReactNode;
}
export const AlertAppProvider: React.FC<Props> = ({ children }) => {
  return (
    <AlertProvider
      template = { Toast }
      position = { positions.TOP_CENTER }
      timeout = { 5000 }
      offset = '30px'
      transition = { transitions.FADE }
      containerStyle = {{
        placeItems: 'stretch',
        gap: '2px',
        pointerEvents: 'initial',
        zIndex: 9999,
      }}
    >
      { children }
    </AlertProvider>
  );
}
