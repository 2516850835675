import styled          from 'styled-components';

import { cssFlexRow,
         themeColors } from 'src/providers/theme/shared';



type FABLookAndFeel = {
  buttonColor: string;
  iconColor: string;

  buttonSize: number;
  iconSize: number;
};

const fab_s56blue: FABLookAndFeel = {
  buttonColor: themeColors.AccentBlue2,
  iconColor: themeColors.PrimaryWhite,

  buttonSize: 56,
  iconSize: 24,
}

export type FABVariants = 's56blue';

export const fab_variants: Map<FABVariants, FABLookAndFeel> = 
  new Map<FABVariants, FABLookAndFeel>([
    ['s56blue', fab_s56blue],
  ]);

export const Container = styled.button<{
  lookAndFeel: FABLookAndFeel;
}>`
  width: ${(args) => args.lookAndFeel.buttonSize}px;
  height: ${(args) => args.lookAndFeel.buttonSize}px;

  ${cssFlexRow};
  justify-content: center;
  align-items: center;

  border: none;
  background-color: ${(args) => args.lookAndFeel.buttonColor};
  border-radius: 50%;

  padding: 0;

  & svg {
    width: ${(args) => args.lookAndFeel.iconSize};
    height: ${(args) => args.lookAndFeel.iconSize};
    color: ${(args) => args.lookAndFeel.iconColor};
  }
`;

export const RippleWrapper = styled.div<{
  lookAndFeel: FABLookAndFeel;
}>`
  width: ${(args) => args.lookAndFeel.buttonSize}px;
  height: ${(args) => args.lookAndFeel.buttonSize}px;
`;
