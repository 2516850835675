import * as React                   from 'react';
 
import { useAlert                 } from 'react-alert';

import { useTheme                 } from 'styled-components';
 
import { ArrowBackVector          } from 'src/components/vector/arrowback';

import { BlockUI                  } from 'src/components/common/block-ui';
import { EmptyCaseKey             } from 'src/components/common/empty-case';
import { Button                   } from 'src/components/common/button';

import { NavBar                   } from 'src/components/features/common/nav-bar';
import { TokenItem                } from 'src/components/features/main/token/token-item';

import { TokenLayout,
         Screen,
         ScreenBody,
         ItemsContainer,
         layout_light,
         layout_dark              } from 'src/containers/main/token/layout';

import { Urls                     } from 'src/providers/routing';

import Api                          from 'src/services/api';
import Constants                    from 'src/services/constants';
import * as types                   from 'src/services/api/types';

import { AppDispatch,
         useAppSelector, 
         useAppDispatch,
         storeApi                 } from 'src/store';
import { GetTokenListReject,
         EmmitRefreshTokenReject, 
        RevokeRefreshTokenReject  } from 'src/store/main/token';

import { AsyncOpStatus            } from 'src/common';



type TokenScreenElement = { layout: TokenLayout; dispatch: AppDispatch; }

const Navigation: React.FC<TokenScreenElement> = ({ layout, dispatch }) => {
  return (
    <React.Fragment>
      <NavBar
        variant = { layout.navbarVariant }
        label = 'Токен для REST API'
        startButtons={[
          {
            id: 'back',
            vector: <ArrowBackVector />,
            action: () => Urls.MainServices.build().navigate()
          }
        ]}
      />
    </React.Fragment>
  )
}

const ItemList: React.FC<TokenScreenElement> = ({ layout, dispatch }) => {
  const alert = useAlert();

  const count = useAppSelector(storeApi.main.token.selectors.selectListCount);
  const list = useAppSelector(storeApi.main.token.selectors.selectList);
  const opStatus = useAppSelector(storeApi.main.token.selectors.selectOpStatus);
  const opStatusLabel = useAppSelector(storeApi.main.token.selectors.selectOpStatusLabel);

  const getListTaskExecuted = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (getListTaskExecuted.current)
    {
      return;
    }

    getListTaskExecuted.current = true;
    dispatch(storeApi.main.token.async.getTokenListAsync())
      .unwrap()
      .then((result) => {
        getListTaskExecuted.current = false;
      })
      .catch((rawError) => {
        getListTaskExecuted.current = false;
        const error = rawError as GetTokenListReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }, []);

  const onRevokeTokenHandler = (token: types.token.RestToken) => {
    dispatch(storeApi.main.token.async.revokeRefreshTokenAsync(token.token))
      .unwrap()
      .then((result) => {
        alert.success('Токен успешно отозван');
      })
      .catch((rawError) => {
        const error = rawError as RevokeRefreshTokenReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  const onEmmitTokenHandler = () => {
    dispatch(storeApi.main.token.async.emmitRefreshTokenAsync())
      .unwrap()
      .then((result) => {
        alert.success('Токен успешно выпущен');
      })
      .catch((rawError) => {
        const error = rawError as EmmitRefreshTokenReject;
        if (!Api.isCommonAuthError(error.statusCode ?? 0))
        {
          alert.error(error.message);
        }
      });
  }

  return (
    <React.Fragment>
      {count === 0 && (
        <EmptyCaseKey
          variant = { layout.emptyCaseVariant }
          header = 'У вас нет токена'
        />
      )}
      {list.length > 0 &&
        <ItemsContainer layout = { layout }>
          {list.map((item) => (
            <TokenItem
              variant = { layout.tokenItemVariant }
              token = { item }
              onRevoke = { onRevokeTokenHandler }
              onCopy = { () => alert.success('Токен скопирован в буфер обмена') }
            />
          ))}
        </ItemsContainer>
      }
      {opStatus === AsyncOpStatus.IDLE && list.length === 0 && (
        <Button
          variant = { layout.buttonVariant }
          label = 'Получить токен'
          onTap = { onEmmitTokenHandler }
        />
      )}
      {list.length === 1 && list[0].timestampExpire < Date.now() + Constants.REST_TOKEN_RENEW_PERIOD_MS && (
        <Button
          variant = { layout.buttonVariant }
          label = 'Получить новый токен'
          onTap = { onEmmitTokenHandler }
        />
      )}
      <BlockUI
        variant = { layout.blockuiVariant }
        isOpened = { opStatus === AsyncOpStatus.BUSY }
        message = { opStatusLabel }
      />
    </React.Fragment>
  )
}

export const TokenScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const layout = theme.colorScheme === 'light' ? layout_light : layout_dark;

  return (
    <Screen layout = { layout }>
      <Navigation layout = { layout } dispatch = { dispatch } />
      <ScreenBody layout = { layout }>
        <ItemList layout = { layout } dispatch = { dispatch } />
      </ScreenBody>
    </Screen>
  );
};
