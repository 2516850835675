import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region GET config

export type GetGeneralConfigIn = void;

export type GetGeneralConfigOut = {
  INAPP: boolean;
  RATING: boolean;
}

export const prepareGetGeneralConfig
  : PrepareCallHandler<GetGeneralConfigIn, GetGeneralConfigOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/config`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
