import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region Common

export type RestToken = {
  /** refresh token */
  token: string;
  /** timestamp окончания действия токена */
  timestampExpire: number;
}

// #endregion


// #region GET client_api/refresh_tokens

export type GetRefreshTokensIn = void;

export type GetRefreshTokensOut = RestToken[];

export const prepareGetRefreshTokens
  : PrepareCallHandler<GetRefreshTokensIn, GetRefreshTokensOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/client_api/refresh_tokens`,
      method: 'GET',
      responseType: 'json',

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion

// #region POST client_api/emmit_refresh_token

export type PostEmmitRefreshTokenIn = void;

export type PostEmmitRefreshTokenOut = RestToken;

export const preparePostEmmitRefreshToken
  : PrepareCallHandler<void, PostEmmitRefreshTokenOut> = () => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/client_api/emmit_refresh_token`,
      method: 'POST',
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion

// #region DELETE client_api/refresh_tokens/{token}

export type DeleteRefreshTokenIn = {
  /** Token, который нужно удалить */
  token: string;
};

export const prepareDeleteRefreshToken
  : PrepareCallHandler<DeleteRefreshTokenIn, void> = (arg: DeleteRefreshTokenIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/client_api/refresh_tokens/${arg.token}`,
      method: 'DELETE',
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
