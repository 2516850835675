import styled           from 'styled-components';

import { cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS    } from 'src/providers/theme/shared';



type TabsLookAndFeel = {
  typo: Typo;

  activeBorderColor: string;
  activeLabelColor: string;
  inactiveLabelColor: string;

  tabHeight: number;
}

const tabs_h30: TabsLookAndFeel = {
  typo: themeTypos.GRTSKREG_W600_S15_LH20,

  activeBorderColor: themeColors.AccentBlue2,
  activeLabelColor: themeColors.PrimaryBlack,
  inactiveLabelColor: themeColors.PrimarySteel,

  tabHeight: 30,
}

export type TabsVariants = 'h30';

export const tabs_variants: Map<TabsVariants, TabsLookAndFeel> = 
  new Map<TabsVariants, TabsLookAndFeel>([
    ['h30', tabs_h30],
  ]);

export const Container = styled.div<{
  lookAndFeel: TabsLookAndFeel;
}>`
  ${cssFlexRow}

  width: 100%;
  height: ${(args) => args.lookAndFeel.tabHeight}px;
`

export const Tab = styled.div<{
  lookAndFeel: TabsLookAndFeel;
}>`
  ${cssFlexRow}
  
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  width: 100%;
  height: ${(args) => args.lookAndFeel.tabHeight}px;
  
  ${(args) => typoAsCSS(args.lookAndFeel.typo)}

  color: ${(args) => args.lookAndFeel.inactiveLabelColor };
  border-bottom: 2px solid transparent;

  &.active {
    color: ${(args) => args.lookAndFeel.activeLabelColor };
    border-bottom: 2px solid ${(args) => args.lookAndFeel.activeBorderColor };
  }

  white-space: nowrap;

  cursor: pointer;

  transition: border-bottom 0.3s, color 0.3s;
`;
