import styled             from 'styled-components';

import { cssFlexColumn,
         cssFlexRow,
         themeColors,
         themeTypos,
         Typo,
         typoAsCSS      } from 'src/providers/theme/shared';



type RatingLookAndFeel = {
  typo: Typo;

  labelColor: string;
  inactiveStarColor: string;
  activeStarColor: string;

  clickAreaSize: number;
  starSize: number;
  starGap: number;
  height: number;
}

const rating_h46s24: RatingLookAndFeel = {
  typo: themeTypos.GRTSKREG_W400_S15_LH22,

  labelColor: themeColors.AccentRed1,
  inactiveStarColor: themeColors.SecondaryGray5,
  activeStarColor: themeColors.AccentRed1,

  clickAreaSize: 48,
  starSize: 36,
  starGap: 16,
  height: 78,
}

export type RatingVariants = 'h46s24';

export const rating_variants: Map<RatingVariants, RatingLookAndFeel> = 
  new Map<RatingVariants, RatingLookAndFeel>([
    ['h46s24', rating_h46s24],
  ]);

export const Container = styled.div<{
  lookAndFeel: RatingLookAndFeel;
  isHideLabel: boolean;
}>`
  ${cssFlexColumn};

  justify-content: space-between;
  aling-items: center;

  width: 100%;
  height: ${(args) => args.isHideLabel ? args.lookAndFeel.clickAreaSize : args.lookAndFeel.height}px;
`

export const Stars = styled.div<{
  lookAndFeel: RatingLookAndFeel
}>`
  ${cssFlexRow};

  justify-content: center;
  gap: ${(args) => args.lookAndFeel.starGap}px;

  & .react-ripples {
    justify-content: center;
  }
`;

export const Star = styled.div<{
  isActive: boolean;
  lookAndFeel: RatingLookAndFeel;
}>`
  display: flex;
  width: ${(args) => args.lookAndFeel.clickAreaSize}px;
  height: ${(args) => args.lookAndFeel.clickAreaSize}px;

  flex-shrink: 0;

  justify-content: center;
  align-items: center;

  & path {
    fill: ${(args) => args.isActive ? args.lookAndFeel.activeStarColor : 'transparent'};
    stroke: ${(args) => args.isActive ? args.lookAndFeel.activeStarColor : args.lookAndFeel.inactiveStarColor};
    transition: fill 0.3s, stroke 0.3s;
  }
`;

export const Label = styled.span<{
  lookAndFeel: RatingLookAndFeel,
}>`
  ${cssFlexRow};

  justify-content: center;

  ${(args) => typoAsCSS(args.lookAndFeel.typo)}
  color: ${(args) => args.lookAndFeel.labelColor};
`
