import styled                 from 'styled-components';

import { ButtonVariants     } from 'src/components/common/button';
import { BlockUIVariants    } from 'src/components/common/block-ui';
import { EmptyCaseVariants  } from 'src/components/common/empty-case';
import { LoadingVariants    } from 'src/components/common/loading';

import { NavBarVariants     } from 'src/components/features/common/nav-bar';

import { FilterTagsVariants } from 'src/components/features/qmmsg/list/filter-tags';
import { ItemVariants       } from 'src/components/features/qmmsg/list/item';
import { FilterVariants     } from 'src/components/features/qmmsg/list/filter';
import { ReportVariants     } from 'src/components/features/qmmsg/list/report';

import { themeColors, 
         cssFlexColumn      } from 'src/providers/theme/shared';



export type ListLayout = {
  backgroundColor: string;

  paddings: number[];
  blockGap: number;
  listGap: number;
  itemsGap: number;

  pageLoadingHeight: number;
  pageLoadingSize: number;

  navbarVariant: NavBarVariants;
  filterVariant: FilterVariants;
  reportVariant: ReportVariants;
  tagsVariant: FilterTagsVariants;
  itemVariant: ItemVariants;
  buttonVariant: ButtonVariants;

  blockUIVariant: BlockUIVariants;
  emptyCaseVariant: EmptyCaseVariants;
  pageLoadingVariant: LoadingVariants;
}

export const layout_light: ListLayout = {
  backgroundColor: themeColors.BackgroundFillLight,

  paddings: [20, 16, 16, 16],
  blockGap: 20,
  listGap: 12,
  itemsGap: 8,

  pageLoadingHeight: 36,
  pageLoadingSize: 24,

  navbarVariant: 'lightblue',
  filterVariant: 'light',
  reportVariant: 'light',
  tagsVariant: 'light',
  itemVariant: 'light',
  buttonVariant: 'h36blueoutline',

  blockUIVariant: 'light',
  emptyCaseVariant: 'light',
  pageLoadingVariant: 'lightblue24',
}

export const layout_dark: ListLayout = {
  ...layout_light,
}

export const Screen = styled.div<{
  layout: ListLayout,
}>`
  width: 100vw;
  height: 100dvh;

  ${cssFlexColumn};

  background-color: ${(args) => args.layout.backgroundColor};
`

export const ScreenBody = styled.div<{
  layout: ListLayout,
}>`
  ${cssFlexColumn};
  flex-grow: 2;
  gap: ${(args) => args.layout.blockGap}px;

  padding-top: ${(args) => args.layout.paddings[0]}px;
  padding-right: ${(args) => args.layout.paddings[1]}px;
  padding-bottom: ${(args) => args.layout.paddings[2]}px;
  padding-left: ${(args) => args.layout.paddings[3]}px;

  overflow-y: scroll;
`

export const CompanyList = styled.div<{
  layout: ListLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.listGap}px;
`

export const CompanyListItems = styled.div<{
  layout: ListLayout,
}>`
  ${cssFlexColumn};
  gap: ${(args) => args.layout.itemsGap}px;
`

export const PageLoadingContainer = styled.div<{
  layout: ListLayout,
}>`
  ${cssFlexColumn};
  width: 100%;
  height: ${(args) => args.layout.pageLoadingHeight}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`

export const PageLoading = styled.div<{
  layout: ListLayout,
}>`
  width: ${(args) => args.layout.pageLoadingSize}px;
  height: ${(args) => args.layout.pageLoadingSize}px;
`
