import * as React          from 'react';

import { Container,
         Checkbox,
         SwitchVariants,
         switch_variants } from 'src/components/common/switch/ui';

import { voidFunction    } from 'src/common';



type Props = {
  variant: SwitchVariants;
  value: boolean;
  isDisabled?: boolean;
  onChange: (checked: boolean) => void;
}
export const Switch: React.FC<Props> = ({
  variant,
  value,
  isDisabled = false,
  onChange,
}) => {
  const lookAndFeel = switch_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel } onClick = { () => !isDisabled && onChange(!value) }>
      <Checkbox lookAndFeel = { lookAndFeel }>
        <input type = 'checkbox' checked = { value } onChange = { voidFunction } />
        <i></i>
      </Checkbox>
    </Container>
  );
};
