import * as React           from 'react';
 
import { ResultContainer,
         ResultItem,
         ResultHeader,
         ResultPrimary,
         ResultRoll,
         ResultEmpty,
         ResultVariants,
         result_variants  } from 'src/components/features/main/shade-selection/result/ui';

import * as types           from 'src/services/api/types';



type Props = {
  variant: ResultVariants;
  results: types.service.ShadeSelectionItem[];
}
export const Result: React.FC<Props> = ({
  variant,
  results
}) => {
  const lookAndFeel = result_variants.get(variant)!;

  return (
    <ResultContainer lookAndFeel = { lookAndFeel }>
      {results.length > 0 && results.map((result) => (
        <ResultItem 
          lookAndFeel = { lookAndFeel }
          key = {`${result.pieceEtalon}_${result.pieces.map((roll) => roll.piece).join('_')}`}
        >
          <ResultHeader lookAndFeel = { lookAndFeel }>Комбинация допускается</ResultHeader>
          <ResultPrimary lookAndFeel = { lookAndFeel }>{ result.pieceEtalon }</ResultPrimary>
          {result.pieces.map((roll) => (
            <ResultRoll key = { roll.piece } lookAndFeel = { lookAndFeel }>{ roll.piece }</ResultRoll>
          ))}
        </ResultItem>
      ))}
      {results.length === 0 && (
        <ResultEmpty lookAndFeel = { lookAndFeel }>Комбинация не допускается</ResultEmpty>
      )}
    </ResultContainer>
  );
};
