
import React from 'react';



type Props = {
  className?: string;
}
export const FaceIDVector: React.FC<Props> = ({ className }) => {
  return (
    <svg className = { className } width = '24' height = '24' viewBox = '0 0 24 24' fill = 'none' xmlns = 'http://www.w3.org/2000/svg'>
      <path stroke = 'currentColor' strokeWidth = '2' d = 'M7.84615 9.42857H9.40376M14.5961 9.42857H16.1538M8.14286 13.9286C10.0714 16.5 13.9286 16.5 15.8571 13.9286M3 8.78571V5.57143C3 4.15127 4.15127 3 5.57143 3H8.78571M3 15.2143V18.4286C3 19.8487 4.15127 21 5.57143 21H8.78571M15.2143 3H18.4286C19.8487 3 21 4.15127 21 5.57143V8.78571M15.2143 21H18.4286C19.8487 21 21 19.8487 21 18.4286V15.2143' />
    </svg>
  );
};
