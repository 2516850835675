import Constants              from 'src/services/constants';
import { PrepareCallHandler } from 'src/services/api';



// #region POST qc/check

export type PostQCCheckIn = {
  /** Номер сертификата качества */
  qcNum: string
};

export type PostQCCheckOut = void;

export const preparePostQCCheck
  : PrepareCallHandler<PostQCCheckIn, PostQCCheckOut> = (arg: PostQCCheckIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qc/check`,
      method: 'POST',
      data: arg,
      
      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion

// #region GET qc/file/{qcNum}

export type GetQCFileIn = {
  /** Номер сертификата качества */
  qcNum: string;
}

export type GetQCFileOut = {
  /** Файл в формате data url */
  dataUrl: string;
}

export const prepareGetQCFile
  : PrepareCallHandler<GetQCFileIn, GetQCFileOut> = (arg: GetQCFileIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qc/file/${arg.qcNum}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (data) => {
        const typedBlob = new Blob([data], {type: 'application/pdf'});
        return ({
          dataUrl: window.URL.createObjectURL(typedBlob)
        })
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion

// #region GET qc/file/{qcNum}

export type GetRawQCFileIn = {
  /** Номер сертификата качества */
  qcNum: string;
}

export type GetRawQCFileOut = {
  /** Файл в формате data url */
  rawData: Blob;
}

export const prepareGetRawQCFile
  : PrepareCallHandler<GetRawQCFileIn, GetRawQCFileOut> = (arg: GetRawQCFileIn) => ({
    config: {
      url: `api/mobile/${Constants.API_VERSION}/qc/file/${arg.qcNum}`,
      method: 'GET',
      responseType: 'arraybuffer',
      transformResponse: (data) => {
        return { rawData: data};
      },

      pwaLocked: 'access',
      pwaRetry: false,
    },
})

// #endregion
