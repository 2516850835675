import { configureStore,
         ThunkAction,
         Action                } from '@reduxjs/toolkit';
import { TypedUseSelectorHook,
         useDispatch,
         useSelector           } from 'react-redux';

import { auth, 
         authReducers          } from 'src/store/auth';

import { main, 
         mainReducers          } from 'src/store/main';

import { qmmsg, 
         qmmsgReducers         } from 'src/store/qmmsg';

import { inspection, 
         inspectionReducers    } from 'src/store/inspection';



export const store = configureStore({
  reducer: {
    ...authReducers,
    ...mainReducers,
    ...qmmsgReducers,
    ...inspectionReducers,
  },
});

export const storeApi = {
  auth,
  main,
  qmmsg,
  inspection,
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
