import * as React        from 'react';

import { Container,
         Tag,
         Padding,
         TagsVariants,
         tags_variants } from 'src/components/common/tags/ui';



export type TagItem = {
  id: string;
  label: string;
}

type Props = {
  variant: TagsVariants;
  items: TagItem[];
  value: TagItem[];
  allId?: string;
  singleselect?: boolean;
  paddings?: number;
  onChange: (value: TagItem[]) => void;
}
export const Tags: React.FC<Props> = ({
  variant,
  items,
  value,
  allId,
  singleselect = false,
  paddings = 0,
  onChange,
}) => {
  const lookAndFeel = tags_variants.get(variant)!;

  const onTagClickHandler = (item: TagItem) => {
    if (singleselect)
    {
      onChange([item]);
    }
    else
    {
      if (allId !== undefined)
      {
        if (item.id === allId)
        {
          onChange(items.filter((item) => item.id === allId));
        }
        else
        {
          if (value.findIndex((one) => one.id === allId) > -1)
          {
            onChange([item]);
          }
          else
          {
            if (value.length === 1 && value[0].id === item.id)
            {
              onChange(value);
            }
            else
            {
              onChange(
                value.findIndex((one) => one.id === item.id) > -1 ?
                  value.filter((one) => one.id !== item.id)
                  :
                  [...value, item]
              );
            }
          }
        }
      }
      else
      {
        if (value.length === 1 && value[0].id === item.id)
        {
          onChange(value);
        }
        else
        {
          onChange(
            value.findIndex((one) => one.id === item.id) > -1 ?
              value.filter((one) => one.id !== item.id)
              :
              [...value, item]
          );
        }
      }
    }
  }

  return (
    <Container lookAndFeel = { lookAndFeel }>
      {paddings > 0 &&
        <Padding lookAndFeel = { lookAndFeel } size = { paddings } />
      }
      { items.map((item) => (
        <Tag
          key = { item.id }
          lookAndFeel = { lookAndFeel }
          isActive = { value.findIndex((one) => one.id === item.id) > -1 }
          onClick = { onTagClickHandler.bind(null, item) }
        >
          { item.label }
        </Tag>
      ))}
      {paddings > 0 &&
        <Padding lookAndFeel = { lookAndFeel } size = { paddings } />
      }
    </Container>
  );
};
