import * as React             from 'react';

import { Container,
         AppSplashVariants,
         appsplash_variants } from 'src/components/features/auth/splash/app-splash/ui';



type Props = {
  variant: AppSplashVariants;
}
export const AppSplash: React.FC<Props> = ({
  variant,
}) => {
  const lookAndFeel = appsplash_variants.get(variant)!;

  return (
    <Container lookAndFeel = { lookAndFeel }>
      Checksteel
    </Container>
  );
};
